import { Paper, Box, Typography, CardMedia } from '@mui/material';

import { Channel } from '~/features/channels/types';
import colors from '~/services/colors';
import { get, includes } from 'lodash-es';

import { ChannelAddContentButton } from '../ChannelHeader/ChannelAddContentButton';
import ChannelContextMenu from '../ChannelHeader/ChannelContextMenu';
import { ChannelOwnerPill } from '../ChannelHeader/ChannelOwnerPill';

type ChannelHeaderLiteProps = {
  channel: Channel;
};

export function ChannelHeaderLite(props: ChannelHeaderLiteProps) {
  const { channel } = props;

  const channelPermissions = get(channel, 'permissions', []);
  const canAddContent = includes(channelPermissions, 'add_content');
  const canManage = includes(channelPermissions, 'manage');

  const color = get(channel, 'color');
  const owner = get(channel, 'owners[0]');
  const itemsCount = get(channel, 'items_count', 0) as number;

  return (
    <Paper
      square={false}
      sx={{ borderRadius: 2, display: 'flex', position: 'relative', backgroundColor: color }}
    >
      {canManage && (
        <Box position="absolute" right="17px" top="14px">
          <ChannelContextMenu channel={channel} />
        </Box>
      )}
      <Box p="20px" display="flex" gap={5} flex={1}>
        {channel.cover && (
          <CardMedia
            component="img"
            sx={{
              height: 100,
              width: 170,
              objectFit: 'contain',
              borderRadius: '4px',
            }}
            alt={channel.name}
            src={channel.cover}
          />
        )}
        <Box flex={1} display="flex" flexDirection="column">
          <Typography variant="h5" component="h1" color={colors.neutral900} fontWeight="500">
            {channel.name}
          </Typography>

          <Box mt="4px">
            <Typography variant="body2" component="span">
              {itemsCount} {itemsCount === 1 ? 'item' : 'items'}
            </Typography>
          </Box>

          <Box mt="18px" display="flex" justifyContent="space-between" alignItems="center">
            {owner && <ChannelOwnerPill owner={owner} />}

            <Box>{canAddContent && <ChannelAddContentButton size="small" channel={channel} />}</Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
