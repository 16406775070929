import styled from 'styled-components';

import { LEARNING_TYPES } from '~/app/catalog/constants';
import { useContentFeedback, useContentPeople } from '~/app/catalog/hooks';
import { useLatestContentItemAssignment } from '~/common/hooks/useLatestContentItemAssignment';
import { useIsRenderedWithinTrack } from '~/features/tracks/hooks/useIsRenderedWithinTrack';
import BaseContainer from '~/app/navigation/components/BaseContainer';
import BasePeopleContainer from '~/app/shared-cards/components/BasePeopleContainer';
import ContentItemAdditional from '~/app/shared-content-item/components/ContentItemAdditional';
import ContentItemBody from '~/app/shared-content-item/components/ContentItemBody';
import ContentItemHeader from '~/app/shared-content-item/components/ContentItemHeader';
import DescriptionContent from '~/app/shared-content-item/components/DescriptionContent';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import BackgroundPanel from '~/app/shared/components/BackgroundPanel';
import { PageBody, PageContainer } from '~/app/shared/components/DefaultPage';
import Text from '~/app/shared/components/Text';
import { STATUS_DONE } from '~/app/shared/constants';
import { useIsPreviewQueryParam } from '~/app/shared/hooks';
import BaseFeedbackContainer, {
  useBaseFeedbackContainerMode,
} from '~/app/stand-alone-shared/components/FeedbackContainer/BaseFeedbackContainer';
import TaskContextMenu from '~/app/task/components/TaskContextMenu';
import { get } from 'lodash-es';

const ContentItemBackgroundPanel = styled(BackgroundPanel)`
  left: 0;
  top: -40px;
`;

interface TaskDetailProps {
  content: ContentItem;
  isRequired?: boolean;
}

const TaskDetail = (props: TaskDetailProps) => {
  const { content: baseContent, isRequired = true } = props;

  // This is temporary until we completely remove the redux sagas usage.
  // This is necessary because it is possible to have multiple assignments in the same state because of the save/drop behavior.
  const currentAssigment = useLatestContentItemAssignment(baseContent.id);
  const content = {
    ...baseContent,
    user_assignment:
      currentAssigment || get(baseContent, 'user_assignment', get(baseContent, 'assignment')),
  };

  const { showPeople, organizers, maintainers, engagedPeople } = useContentPeople(content);
  const { assignmentsCount } = useContentPeople(content, ['active', 'terminated']);
  const { showFeedback, feedback, feedbackStatus } = useContentFeedback(
    content,
    LEARNING_TYPES.tasks
  );

  const isRenderedWithinTrack = useIsRenderedWithinTrack();

  const feedbackContainerMode = useBaseFeedbackContainerMode({
    showFeedback,
    showOthers: [showPeople],
  });

  const isLoadingFeedback = feedbackStatus !== STATUS_DONE;

  const isPreviewMode = useIsPreviewQueryParam();

  if (!isRenderedWithinTrack) return null;

  return (
    <BaseContainer>
      <ContentItemBackgroundPanel height="120px" />
      <PageContainer columnsWidth="1fr">
        <ContentItemHeader
          content={content}
          maintainers={maintainers}
          contextMenu={
            <TaskContextMenu
              content={content}
              contextMenuExtraProps={{ isTrackItem: isRenderedWithinTrack }}
            />
          }
          clickableName={false}
          isRequired={isRequired}
          isPreviewMode={isPreviewMode}
        />
        <PageBody>
          <ContentItemBody>
            <DescriptionContent content={content} />
            <ContentItemAdditional>
              {showPeople && (
                <BasePeopleContainer
                  contentType={LEARNING_TYPES.tasks}
                  organizers={organizers}
                  maintainers={maintainers}
                  engagedPeople={engagedPeople}
                  engagedPeopleCount={assignmentsCount}
                  engagedPeopleHeading={
                    <Text>
                      {assignmentsCount} {assignmentsCount > 1 ? 'people' : 'person'} engaged
                    </Text>
                  }
                />
              )}
              {showFeedback && (
                <BaseFeedbackContainer
                  content={content}
                  feedbackList={feedback}
                  mode={feedbackContainerMode}
                  isLoading={isLoadingFeedback}
                />
              )}
            </ContentItemAdditional>
          </ContentItemBody>
        </PageBody>
      </PageContainer>
    </BaseContainer>
  );
};

export default TaskDetail;
