import autosize from 'autosize';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { HotKeys } from 'react-hotkeys';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { UserPopover } from '~/app/shared/components/UserAvatar';
import actions from '~/app/entities/actions';
import { userSchema } from '~/app/entities/schema';
import { getDataFromState } from '~/app/entities/utils';
import TextInput from '~/app/inputs/components/TextInput';
import { ENTER_KEY } from '~/app/inputs/constants';
import { getUserImage } from '~/services/utils';
import {
  isEmpty,
  map,
  replace,
  split,
  includes,
  forEach,
  endsWith,
  filter,
  debounce,
} from 'lodash-es';

import UserSelectItem from './UserSelectItem';

import './style.scss';

const InputWrapper = styled.div`
  margin: 10px 0;
`;

const StyledSearchResults = styled.div`
  margin: 0 0 15px;
  display: flex;
  flex-wrap: wrap;
`;

const SearchResults = (props) => {
  const { onFocus, userSearchResultFiltered, handleUserSelected } = props;
  if (isEmpty(userSearchResultFiltered)) return null;

  return (
    <StyledSearchResults onFocus={onFocus}>
      {map(userSearchResultFiltered, (item, i) => (
        <div key={i} className="user-select-styling">
          <UserPopover
            sendEmailCTA
            user={item}
            renderPopoverHoverable={
              <UserSelectItem
                key={i}
                userId={item.id}
                userName={item.display_name}
                userImage={getUserImage(item)}
                onClick={handleUserSelected}
              />
            }
          />
        </div>
      ))}
    </StyledSearchResults>
  );
};

SearchResults.propTypes = {
  onFocus: PropTypes.func,
  userSearchResultFiltered: PropTypes.array,
  handleUserSelected: PropTypes.func,
};

const UserSelectInput = ({
  input: { name: inputName, onChange, onBlur, onFocus },
  meta: { touched, error },
  searchUsers,
  allowSelectEmails,
  deactivateSearch,
  allowMultipleSelection,
  userSearchResult,
  label,
}) => {
  const userSearchInput = useRef(null);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const moveFocusToSearchInput = () => {
    userSearchInput.current?.focus();
    if (userSearchInput.current) {
      userSearchInput.current.value = '';
    }
  };

  useEffect(() => {
    autosize(document.querySelectorAll('textarea'));

    moveFocusToSearchInput();
  }, [userSearchInput.current]);

  const updateSelectedUsers = (users) => {
    setSelectedUsers(users);
    onChange(isEmpty(users) ? null : users);
    onBlur();
  };

  const selectUserByEmail = (email) => {
    if (canSelectMoreUsers()) {
      const newSelecteds = selectedUsers;

      const containsEmail = includes(newSelecteds, email);
      if (!containsEmail) {
        newSelecteds.push({ email });
      }
      updateSelectedUsers(newSelecteds);
      moveFocusToSearchInput();
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      selectUserByEmail(e.target.value);
      e.preventDefault();
    }
  };

  const addUser = (emails) => {
    const newSelecteds = [...selectedUsers];

    forEach(emails, (email) => {
      const containsEmail = includes(newSelecteds, email);
      if (!containsEmail && !isEmpty(email)) {
        newSelecteds.push({ email });
      }
    });

    updateSelectedUsers(newSelecteds);
  };

  const onPaste = (e) => {
    e.preventDefault();
    const commaSeparatedEmailsStr = e.clipboardData.getData('Text');
    const emails = split(replace(commaSeparatedEmailsStr, /\s+/g, ''), /[,;]/g);
    addUser(emails);
  };

  const debouncedSearchUsers = debounce((value) => {
    searchUsers(value);
  }, 500);

  const handleUserSearchInputChange = (e) => {
    const { value } = e.target;
    const newSelecteds = [...selectedUsers];

    if (endsWith(value, ',') && allowSelectEmails) {
      const email = value.slice(0, -1);
      const containsEmail = includes(newSelecteds, email);
      selectUserByEmail(email);
      if (!containsEmail) {
        newSelecteds.push({ email });
        updateSelectedUsers(newSelecteds);
      }
      moveFocusToSearchInput();
    } else if (!deactivateSearch && !isEmpty(value)) {
      debouncedSearchUsers(value);
    }
  };

  const canSelectMoreUsers = () => {
    if (!allowMultipleSelection && selectedUsers.length > 0) return false;

    return true;
  };

  const handleUserSelectAreaClick = (e) => e.preventDefault();

  const handleUserSelected = (userId, userEmail) => {
    if (canSelectMoreUsers()) {
      const newSelecteds = selectedUsers;
      let selected;
      let itemInSelectedList;
      if (!userId) {
        selected = filter(userSearchResult, ['email', userEmail]);
        itemInSelectedList = filter(newSelecteds, ['email', userEmail]);
      } else if (isEmpty(userEmail)) {
        selected = filter(userSearchResult, ['id', userId]);
        itemInSelectedList = filter(newSelecteds, ['id', userId]);
      }

      if (selected && isEmpty(itemInSelectedList)) {
        newSelecteds.push(selected[0]);
        updateSelectedUsers(newSelecteds);
      }
      moveFocusToSearchInput();
    }
  };

  const handleSelectedUserRemove = (userId, userEmail) => {
    const newSelecteds = selectedUsers;
    let index;
    if (!userId) {
      index = map(newSelecteds, 'email').indexOf(userEmail);
    } else if (isEmpty(userEmail)) {
      index = map(newSelecteds, 'id').indexOf(userId);
    }
    newSelecteds.splice(index, 1);

    updateSelectedUsers(newSelecteds);
  };

  const userSearchResultFiltered = filter(
    userSearchResult,
    (user) => !includes(selectedUsers, user)
  );

  return (
    <div className="user-select-input">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        role="listbox"
        tabIndex="0"
        className="user-select-area"
        onClick={handleUserSelectAreaClick}
      >
        {!isEmpty(selectedUsers) && (
          <div className="selected-users-container">
            {map(selectedUsers, (item, i) => (
              <div key={i} className="user-select-styling">
                {item.id ? (
                  <UserPopover
                    user={item}
                    sendEmailCTA
                    renderPopoverHoverable={
                      <UserSelectItem
                        userId={item.id}
                        userName={item.display_name}
                        userImage={item.profile_image}
                        onRemoveClick={handleSelectedUserRemove}
                      />
                    }
                  />
                ) : (
                  <UserSelectItem userEmail={item.email} onRemoveClick={handleSelectedUserRemove} />
                )}
              </div>
            ))}
          </div>
        )}

        <HotKeys
          keyMap={{ deleteLastRecipient: 'backspace' }}
          handlers={{
            deleteLastRecipient: (e) => {
              if (userSearchInput.current.value === '') {
                e.preventDefault();
                handleSelectedUserRemove(selectedUsers.at(-1));
              }
            },
          }}
        >
          <InputWrapper>
            <TextInput
              id={inputName}
              label={label}
              inputRef={userSearchInput}
              onChange={handleUserSearchInputChange}
              onKeyDown={onKeyDown}
              onPaste={onPaste}
            />
          </InputWrapper>
        </HotKeys>

        {!deactivateSearch && (
          <SearchResults
            onFocus={onFocus}
            userSearchResultFiltered={userSearchResultFiltered}
            handleUserSelected={handleUserSelected}
          />
        )}
      </div>
      {touched && error && <span className="form-error-message">{error}</span>}
    </div>
  );
};

UserSelectInput.defaultProps = {
  allowMultipleSelection: true,
  allowSelectEmails: true,
};

UserSelectInput.propTypes = {
  userSearchResult: PropTypes.array,
  input: PropTypes.object,
  meta: PropTypes.object,
  allowMultipleSelection: PropTypes.bool,
  deactivateSearch: PropTypes.bool,
  allowSelectEmails: PropTypes.bool,
  label: PropTypes.string,

  searchUsers: PropTypes.func,
};

const mapStateToProps = (state) => {
  const filterState = getDataFromState('userSelectInput', state, [userSchema]);

  return {
    userSearchResult: filterState.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchUsers: (name) =>
    dispatch(
      actions.userData.retrieveList('userSelectInput', {
        name,
        page_size: 10,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectInput);
