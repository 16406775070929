import { FormGroup, styled } from '@mui/material';

const InlineFormGroup = styled(FormGroup)({
  flexWrap: 'nowrap',
  alignItems: 'stretch',

  '& > :first-of-type': {
    marginRight: 0,
  },
  '& > :not(:first-of-type):not(:last-child)': {
    marginLeft: 0,
    marginRight: 0,
  },
  '& > :last-child': {
    marginLeft: 0,
  },

  '& > :first-of-type fieldset, & > :first-of-type:not(button)': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  '& > :last-child fieldset, & > :last-child': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  '& > :not(:first-of-type) fieldset, & > :not(:first-of-type) & > :not(:last-child) fieldset, & > :not(:last-child)':
    {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
});

export default InlineFormGroup;
