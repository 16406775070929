import * as React from 'react';

import { Assignment, ContentItem } from '~/app/shared-content-item/interfaces';

import { AssignmentActionsCtx } from '../contexts/AssignmentActionsCtx';
import { useContentItemAssignmentActions } from '../hooks/useContentItemAssignmentActions';

type AssignmentActionsProviderProps = {
  content: ContentItem;
  assignment?: Assignment;
  children: React.ReactNode;
};

export function AssignmentActionsProvider(props: AssignmentActionsProviderProps) {
  const { content, assignment, children } = props;

  const assignmentActions = useContentItemAssignmentActions({
    contentItem: content,
    assignment,
  });

  return (
    <AssignmentActionsCtx.Provider value={assignmentActions}>
      {children}
    </AssignmentActionsCtx.Provider>
  );
}
