import PropTypes from 'prop-types';
import React from 'react';

import TextInput from '~/app/inputs/components/TextInput';

const SettingTextAreaField = ({ input: { name, onChange, value }, disabled, ariaLabel }) => (
  <TextInput
    multiline
    rows={3}
    disabled={disabled}
    inputProps={{
      id: name,
      name,
      'aria-label': ariaLabel,
      value: value.value,
      onChange: (event) => onChange({ ...value, value: event.target.value, inherited: false }),
    }}
  />
);

SettingTextAreaField.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default SettingTextAreaField;
