import autosize from 'autosize';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import TextField from '~/app/inputs/components/TextField';
import { DisplayFormErrors } from '~/app/shared';
import Button from '~/app/shared/components/Button';
import { SOCIAL_SHARING_FORM } from '~/app/shared/constants';
import * as formValidations from '~/app/shared/formValidations';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

const SubmitSection = styled.div`
  display: flex;
  justify-content: center;
`;

export class SocialSharingForm extends React.Component {
  componentDidMount() {
    autosize(document.querySelectorAll('textarea'));
  }

  render() {
    const { error, handleSubmit, submitting } = this.props;

    return (
      <form className="invite-users-form" onSubmit={handleSubmit}>
        <DisplayFormErrors errors={error} />
        <Field component="hidden" name="service" />
        <div className="message-section">
          <Field
            component={TextField}
            multiline
            rows={3}
            id="message"
            name="message"
            inputProps={{
              maxLength: 500,
            }}
          />
        </div>
        <SubmitSection>
          <Button type="submit" disabled={submitting}>
            Post
          </Button>
        </SubmitSection>
      </form>
    );
  }
}

SocialSharingForm.propTypes = {
  error: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
};

export default reduxForm({
  form: 'social-sharing',
  onSubmit: onSubmitActions(SOCIAL_SHARING_FORM),
  validate: formValidations.createValidator({
    message: formValidations.required(),
  }),
})(SocialSharingForm);
