// TODO: merge this component with `PeopleContainer` after we add facilitators to events
import styled from 'styled-components';

import { HR } from '~/app/shared';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import colors from '~/services/colors';
import Pill from '~/app/shared/components/Pill';
import Text from '~/app/shared/components/Text';
import { UserPopover, UserAvatarImage } from '~/app/shared/components/UserAvatar';
import UserHighlight from '~/app/shared/components/UserHighlight';
import { compact, filter, includes, isEmpty, isNil, map } from 'lodash-es';

const PeopleContainerWrapper = styled.div<{
  $withFlexBasis: boolean;
  $paperBackground: boolean;
}>`
  flex-direction: column;
  padding: 20px;
  display: flex;
  min-width: 40%;

  ${({ $paperBackground }) =>
    $paperBackground &&
    `
    background-color: ${colors.neutral0};
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  `}

  ${({ $withFlexBasis }) => $withFlexBasis && 'flex: 1;'}

  > * + * {
    margin-top: 20px;
  }
`;

const PeopleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;

  > * + * {
    margin-top: 12px;
  }
`;

const FacilitatorHighlightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;

  > * + * {
    margin-top: 12px;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-left: 8px;
    margin-bottom: 8px;
  }
`;

const FacilitatorSmallerAvatarList = ({ facilitators }: { facilitators: any[] }) => {
  if (!facilitators) return null;
  return (
    <AvatarContainer>
      {map(facilitators.slice(0, 10), (facilitator) => (
        <UserPopover
          sendEmailCTA
          user={facilitator}
          renderPopoverHoverable={<UserAvatarImage user={facilitator} size="small" />}
        />
      ))}
      {facilitators.length > 10 && <Pill label={`+${facilitators.length - 10}`} />}
    </AvatarContainer>
  );
};

export const BasePeopleEngagementContainer = ({
  engagedPeople,
  engagedPeopleCount,
  engagedPeopleMaxAvatarCount = 16,
  engagedPeopleHeading,
}: {
  engagedPeople: any[];
  engagedPeopleCount: number;
  engagedPeopleMaxAvatarCount?: number;
  engagedPeopleHeading: any;
}) => {
  if (engagedPeopleCount <= 0) {
    return (
      <PeopleSection>
        <Text>No one engaged</Text>
        <Text size="h6" color={colors.neutral400}>
          Be the first to engage
        </Text>
      </PeopleSection>
    );
  }

  // Deleted users are included on engagement counts but not on the avatar list.
  const activeEngagedPeople = filter(engagedPeople, (user) => isNil(user?.deleted_at));
  return (
    <PeopleSection>
      {engagedPeopleHeading}
      {!isEmpty(activeEngagedPeople) && (
        <AvatarContainer>
          {map(compact(activeEngagedPeople), (user: any) => (
            <UserPopover
              key={`user-${user.id}`}
              user={user}
              renderPopoverHoverable={<UserAvatarImage user={user} size="small" />}
            />
          ))}
          {engagedPeopleCount > engagedPeopleMaxAvatarCount && (
            <Pill label={`+ ${engagedPeopleCount - engagedPeopleMaxAvatarCount}`} />
          )}
        </AvatarContainer>
      )}
    </PeopleSection>
  );
};

export const BasePeopleFacilitatorContainer = ({
  facilitators,
  facilitatorCount = 6,
  facilitatorHighlightDisplayLabel,
}: {
  facilitators: any[];
  facilitatorCount?: number;
  facilitatorHighlightDisplayLabel: string;
}) => (
  <PeopleSection>
    <Text>
      {facilitatorHighlightDisplayLabel}
      {facilitators.length > 1 && 's'}
    </Text>

    {facilitators.length <= facilitatorCount ? (
      <FacilitatorHighlightContainer>
        {map(facilitators, (facilitator) => (
          <UserHighlight key={facilitator.id} user={facilitator} />
        ))}
      </FacilitatorHighlightContainer>
    ) : (
      <FacilitatorSmallerAvatarList facilitators={facilitators} />
    )}
    <HR />
  </PeopleSection>
);

export interface BasePeopleContainerProps {
  contentType: string;
  organizers: any[];
  maintainers: any[];
  engagedPeople: any[];
  engagedPeopleCount: number;
  engagedPeopleHeading: any;
  withFlexBasis?: boolean;
  paperBackground?: boolean;
}

const BasePeopleContainer = ({
  contentType,
  organizers,
  maintainers,
  engagedPeople,
  engagedPeopleCount,
  engagedPeopleHeading,
  withFlexBasis = true,
  paperBackground = true,
}: BasePeopleContainerProps) => {
  const facilitatorHighlightDisplayLabel = includes(
    [LEARNING_TYPES.events, LEARNING_TYPES.event_types],
    contentType
  )
    ? 'Presenter'
    : 'Maintainer';

  const userPillDisplayLabel = 'Organizer';

  if (isEmpty(organizers) && isEmpty(maintainers) && engagedPeopleCount === 0) return null;

  return (
    <PeopleContainerWrapper $withFlexBasis={withFlexBasis} $paperBackground={paperBackground}>
      <Text medium>People</Text>

      {!isEmpty(maintainers) && (
        <BasePeopleFacilitatorContainer
          facilitators={maintainers}
          facilitatorHighlightDisplayLabel={facilitatorHighlightDisplayLabel}
        />
      )}

      {!isEmpty(organizers) && (
        <BasePeopleFacilitatorContainer
          facilitators={organizers}
          facilitatorCount={10}
          facilitatorHighlightDisplayLabel={userPillDisplayLabel}
        />
      )}

      <BasePeopleEngagementContainer
        engagedPeople={engagedPeople}
        engagedPeopleHeading={engagedPeopleHeading}
        engagedPeopleCount={engagedPeopleCount}
      />
    </PeopleContainerWrapper>
  );
};

export default BasePeopleContainer;
