import { get, includes, isNil } from 'lodash-es';
import { tint } from 'polished';
import * as React from 'react';
import { Card, styled } from '@mui/material';

import AssessmentCompletionModal from '~/app/assessments/components/AssessmentCompletionModal';
import { Assessment } from '~/app/assessments/interfaces';
import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import {
  CONTENT_TYPES,
  LEARNING_TYPES,
  LEARNING_TYPE_ICONS_MAPPING,
} from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import ContentItemContainer from '~/app/content-items/components/ContentItemContainer';
import TrackCompletionModal from '~/features/tracks/components/TrackCompletionModal';
import { TrackPrimaryActionButton } from '~/features/tracks/components/TrackPrimaryActionButton';
import { useIsRenderedWithinTrack } from '~/features/tracks/hooks/useIsRenderedWithinTrack';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import BreadCrumbs from '~/app/shared-content-item/components/BreadCrumbs';
import ContentItemBackgroundPanel from '~/app/shared-content-item/components/ContentItemBackgroundPanel';
import ContentItemHeader from '~/app/shared-content-item/components/ContentItemHeader';
import { PageBody, PageContainer } from '~/app/shared/components/DefaultPage';
import Icon from '~/app/shared/components/Icon';
import PageTitle from '~/app/shared/components/PageTitle';
import { useEncodedCurrentRoute } from '~/app/shared/hooks';
import {
  CONTENT_ITEM_PERMISSIONS,
  EDIT_TRACK_PERMISSION,
  MANAGE_TRACK_ASSIGNMENT_PERMISSION,
} from '~/app/shared/permissions';
import { ScheduledTrack, Track } from '~/app/tracks/interfaces';
import { Box, Chip } from '@mui/material';
import { SecondaryActionButton } from './SecondaryActionButton/SecondaryActionButton';
import TrackContextMenu from './TrackContextMenu';
import { TrackDetailBody } from './TrackDetailBody';

interface TrackChipProps {
  track: Track;
}

const TrackChip = ({ track }: TrackChipProps) => {
  if (!track) return null;

  return (
    <Box marginTop={-2.5} marginBottom={2.5}>
      <Chip
        clickable
        component="a"
        href={mapRoute('trackDetails', {
          public_id_and_slug: track.public_id_and_slug,
        })}
        size="small"
        icon={
          <Icon
            name={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.tracks]}
            width={12}
            height={12}
            color={colors.emphasis700}
          />
        }
        label={track.name}
        sx={{
          backgroundColor: colors.emphasis200,
          color: colors.emphasis700,
          '&:hover': {
            color: colors.emphasis700,
            backgroundColor: tint(0.2, colors.emphasis200),
          },
        }}
      />
    </Box>
  );
};

const StackedCard = styled(Card)({
  position: 'relative',
  backgroundColor: colors.neutral0,
  marginTop: '-5px',
  height: '10px',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
});

interface TrackDetailPageProps {
  assessment?: Assessment;
  scheduledTrack?: ScheduledTrack;
  track?: Track;
  showBreadcrumbs?: boolean;
  isRequired?: boolean;
}

const TrackDetailPage = (props: TrackDetailPageProps) => {
  const { assessment, scheduledTrack, track, showBreadcrumbs, isRequired } = props;

  const isRenderedWithinTrack = useIsRenderedWithinTrack();

  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const currentRoute = useEncodedCurrentRoute();

  const [showCompletionModal, setShowCompletionModal] = React.useState(true);

  const handleCompletionModalClose = () => {
    setShowCompletionModal(false);
  };

  const content = assessment || scheduledTrack || track;

  if (isNil(content)) return null;

  const renderBreadcrumbs = () => {
    // Does not show breadcrumbs if content is a track item. See usages on TrackItemRenderer.
    if (isRenderedWithinTrack || !showBreadcrumbs) return null;

    return <BreadCrumbs content={content} margin="-20px 0 20px" />;
  };

  const canSeeSettings =
    includes(content.permissions, EDIT_TRACK_PERMISSION) ||
    includes(content.permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canManageAssignment =
    includes(content.permissions, MANAGE_TRACK_ASSIGNMENT_PERMISSION) ||
    includes(content.permissions, CONTENT_ITEM_PERMISSIONS.assign);
  const extraContent = {
    canSeeSettings,
    canManageAssignment,
    contentTypeRoute: contentTypeRoutes[content.content_type],
    currentRoute,
  };

  const isAssessment = get(content, 'content_type') === CONTENT_TYPES.assessment;

  const assignment = get(content, 'user_assignment');
  const isAwaitingFeedback = get(assignment, 'awaiting_feedback', false);
  const isCompleted = get(assignment, 'state') === ASSIGNMENT_STATES.completed;

  const shouldDisplayCompletionModal = isCompleted && isAwaitingFeedback && showCompletionModal;

  const clickableName = content.is_inline ? false : isRenderedWithinTrack;

  return (
    <ContentItemContainer content={content} isRenderedWithinTrack={isRenderedWithinTrack}>
      <ContentItemBackgroundPanel height="120px" />
      <PageTitle title={content.name} />

      <PageContainer columnsWidth="1fr">
        <Box>
          {scheduledTrack && <TrackChip track={(content as ScheduledTrack).original_track} />}
          {renderBreadcrumbs()}

          <Box position="relative">
            <ContentItemHeader
              content={content}
              contextMenu={
                <TrackContextMenu content={content} contextMenuExtraProps={extraContent} />
              }
              primaryActionButton={TrackPrimaryActionButton}
              secondaryActionButton={SecondaryActionButton}
              clickableName={clickableName}
              isRequired={isRequired}
            />

            <Box position="absolute" width="100%" zIndex={1}>
              <StackedCard
                sx={{
                  zIndex: 1,
                  width: 'calc(100% - 6px)',
                }}
              />
              <StackedCard
                sx={{
                  width: 'calc(100% - 12px)',
                }}
              />
            </Box>
          </Box>
        </Box>

        <PageBody>
          <TrackDetailBody track={content} />
        </PageBody>
      </PageContainer>

      {shouldDisplayCompletionModal && (
        <>
          {isAssessment && (
            <AssessmentCompletionModal
              assessment={content as Assessment}
              handleClose={handleCompletionModalClose}
            />
          )}
          {!isAssessment && (
            <TrackCompletionModal track={content} handleClose={handleCompletionModalClose} />
          )}
        </>
      )}
    </ContentItemContainer>
  );
};

export default TrackDetailPage;
