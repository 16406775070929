import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { endsWith, filter, map } from 'lodash-es';

import NotificationContextBlock from './NotificationContextBlock/NotificationContextBlock';
import SettingContextInnerLevelBlock from './SettingContextInnerLevelBlock';
import SettingContextMidLevelBlock from './SettingContextMidLevelBlock';
import SettingContextTopLevelBlock from './SettingContextTopLevelBlock';
import SettingValueBlock from './SettingValueBlock';

const SettingContextBaseBlockList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SettingContextBaseBlock = ({ form, settingName, setting, depth, defaultOpen }) => {
  const isContext = setting.type === 'context';

  const isSettingContextTopLevelBlock = isContext && depth === 0;
  const isSettingContextMidLevelBlock = isContext && depth === 1;
  const isInnerContextBlock = isContext && depth > 1;
  const isNotificationBlock = endsWith(settingName, '_notification');

  const renderSettingContextBaseBlocks = (setting, depth) => {
    const children = Object.entries(setting.items || []);

    const accessibleItems = filter(children, (item) => item[1]?.access !== 'hidden');

    return (
      <SettingContextBaseBlockList>
        {map(accessibleItems, ([key, value]) => (
          <SettingContextBaseBlock
            form={form}
            key={key}
            settingName={key}
            setting={value}
            depth={depth + 1}
          />
        ))}
      </SettingContextBaseBlockList>
    );
  };

  if (isSettingContextTopLevelBlock) {
    return (
      <SettingContextTopLevelBlock settingLabel={setting.label}>
        {renderSettingContextBaseBlocks(setting, depth)}
      </SettingContextTopLevelBlock>
    );
  }

  if (isNotificationBlock) {
    return (
      <NotificationContextBlock
        settingName={settingName}
        setting={setting}
        defaultOpen={defaultOpen}
      >
        {renderSettingContextBaseBlocks(setting, depth)}
      </NotificationContextBlock>
    );
  }

  if (isSettingContextMidLevelBlock) {
    return (
      <SettingContextMidLevelBlock
        settingName={settingName}
        setting={setting}
        defaultOpen={defaultOpen}
      >
        {renderSettingContextBaseBlocks(setting, depth)}
      </SettingContextMidLevelBlock>
    );
  }

  if (isInnerContextBlock) {
    return (
      <SettingContextInnerLevelBlock setting={setting}>
        {renderSettingContextBaseBlocks(setting, depth)}
      </SettingContextInnerLevelBlock>
    );
  }

  return <SettingValueBlock settingName={settingName} setting={setting} />;
};

SettingContextBaseBlock.defaultProps = {
  defaultOpen: true,
};

SettingContextBaseBlock.propTypes = {
  form: PropTypes.string,
  settingName: PropTypes.string,
  setting: PropTypes.object,
  depth: PropTypes.number,
  defaultOpen: PropTypes.bool,
};

export default SettingContextBaseBlock;
