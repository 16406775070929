import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import HelpLink from '~/app/shared/components/HelpLink';
import Text from '~/app/shared/components/Text';

import Pill from '../Pill';

const InfoPanel = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 16px 20px;

  > * + * {
    margin-top: 12px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 8px;
  }
`;

/*
  FIXME: This component's abstraction is too tight. We should have more flexbility about the
    HC article placing and copy.
 */
const SectionInfoPanel = ({
  title,
  infoText,
  HCArticleURL,
  infoBox,
  blockHelpLink,
  showBetaPill,
  renderRightSection,
}) => {
  return (
    <InfoPanel>
      <HeaderWrapper>
        <TitleWrapper>
          <Text size="h2" bold block>
            {title}
          </Text>
          {showBetaPill && <Pill label="Beta Version" size="small" />}
        </TitleWrapper>
        {renderRightSection?.()}
      </HeaderWrapper>
      <div>
        {/* div required due to margin not being applied to inline elements */}
        <Text size="h5" color={colors.neutral600}>
          {infoText}{' '}
        </Text>
        {HCArticleURL && (
          <HelpLink url={HCArticleURL} block={blockHelpLink}>
            Learn more
          </HelpLink>
        )}
      </div>
      {infoBox}
    </InfoPanel>
  );
};

SectionInfoPanel.propTypes = {
  title: PropTypes.string,
  infoText: PropTypes.string,
  HCArticleURL: PropTypes.string,
  showBetaPill: PropTypes.bool,
  infoBox: PropTypes.element,
  renderRightSection: PropTypes.func,
  blockHelpLink: PropTypes.bool,
};

export default SectionInfoPanel;
