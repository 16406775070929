import queryString from 'query-string';

import { RQLFilter, RQLFilterObject } from '~/app/shared/components/types';
import {
  isNil,
  isArray,
  map,
  includes,
  get,
  keys,
  values,
  omit,
  toString,
  has,
  constant,
  identity,
  filter,
  head,
  size,
  find,
} from 'lodash-es';
import rql from '~/vendor/rql';

type QueryStringFieldValue = undefined | null | string | number | (string | number | null)[];

const queryStringToRQL = (data: QueryStringFieldValue) => {
  if (isNil(data)) return null;
  if (isArray(data)) return { $in: map(data, toString) };
  return { $eq: data };
};

// You can assume the 'df' prefix as Django Filters
interface TranslatorFactoryProps {
  translationMap?: Record<string, string>; // { rqlFilterName: dfFilterName }  Optional in cases where the names of the filters are the same in both frameworks
  defaultOrdering: string;
  orderingOptions?: Record<string, string>; // { dfOrderingValue: rqlOrderingValue }  Optional in cases where the value are the same in both frameworks
  processingMap?: Record<string, (value: QueryStringFieldValue) => RQLFilter>; // Define a function to be used instead of queryStringToRQL to specific fields
  postProcessing?: (rqlObject: any) => RQLFilterObject;
}

// Return a function that translates a string in the format expected by Django Filters into an RQL expression.
export const translatorFactory = ({
  translationMap,
  defaultOrdering,
  orderingOptions,
  processingMap,
  postProcessing = identity,
}: TranslatorFactoryProps) => {
  return (search: string): string => {
    // Check if it is already an RQL expression
    if (
      !includes(search, 'eq(') &&
      !includes(search, 'gt(') &&
      !includes(search, 'lt(') &&
      !includes(search, 'in(') &&
      !includes(search, 'ordering(') &&
      !includes(search, 'range(')
    ) {
      const filters = queryString.parse(search);
      const rqlObject = {
        $and: [
          // { type: 'learning_types', category: 'main_topics' } =>
          // [{ type: queryStringToRQL(get(filters, 'learning_types')) }, { category: queryStringToRQL(get(filters, 'main_topics')) }]
          ...map(keys(translationMap), (filterName: string) => ({
            [filterName]: queryStringToRQL(get(filters, get(translationMap, filterName, ''))),
          })),
          // All other filters which have the same name in both frameworks
          ...map(keys(omit(filters, [...values(translationMap), 'o'])), (oldFilterName: string) => {
            if (has(processingMap, oldFilterName)) {
              return get(
                processingMap,
                oldFilterName,
                constant({})
              )(get(filters, oldFilterName, null));
            }
            return {
              [oldFilterName]: queryStringToRQL(get(filters, oldFilterName)),
            };
          }),
        ],
        // Also translate the ordering
        $ordering: orderingOptions
          ? get(orderingOptions, get(filters, 'o', '') as string, defaultOrdering)
          : get(filters, 'o', defaultOrdering),
      };
      return rql(postProcessing(rqlObject));
    }
    return search;
  };
};

export const convertPeriodFilterToRQLFactory = (newFilterName: string) => {
  return (value?: string) => {
    if (value === 'upcoming') return { [newFilterName]: { $gt: '-PT0H' } };
    if (value === 'previous') return { [newFilterName]: { $lt: '-PT0H' } };
    return {};
  };
};

export const joinMultipleDatesToRangeFactory = (newFilterName: string) => {
  return (rqlObject: RQLFilterObject) => {
    const filters = get(rqlObject, '$and', []);
    const filtersToJoin = filter(
      filters,
      (filter: RQLFilter) => head(keys(filter)) === newFilterName
    );
    const filterValues = map(
      filtersToJoin,
      (filter: RQLFilter) => filter[head(keys(filter)) as string]
    );
    // Handle the date from the deprecated upcoming/previous filters
    const upcomingOrPreviousFilterValue = find(
      filterValues,
      (value) => get(value, '$gt', get(value, '$lt')) === '-PT0H'
    );

    if (size(filterValues) <= 1) return rqlObject;

    const newFilter = {
      [newFilterName]: upcomingOrPreviousFilterValue ?? {
        $range: {
          min: get(
            find(filterValues, (value) => head(keys(value)) === '$gt'),
            '$gt'
          ),
          max: get(
            find(filterValues, (value) => head(keys(value)) === '$lt'),
            '$lt'
          ),
        },
      },
    };

    const otherFilters = filter(
      filters,
      (filter: RQLFilter) => head(keys(filter)) !== newFilterName
    );

    return { ...rqlObject, $and: [...otherFilters, newFilter] } as RQLFilterObject;
  };
};
