import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Icon from '~/app/shared/components/Icon';

import Option from '../Option';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: 100%;
    justify-content: unset;
    height: 25px;
    margin: 0;
    border: none;
    &:hover {
      border: none;
      box-shadow: unset;
    }
  }
`;

const OptionIcon = styled(Icon)`
  margin-right: 6px;
`;

const ImageUpload = (props) => {
  const { toggleModal } = props;

  return (
    <ButtonWrapper aria-label="rdw-image-control" title="Image">
      <Option onClick={toggleModal}>
        <OptionIcon height="15" width="15" name="image" />
        Image
      </Option>
    </ButtonWrapper>
  );
};

ImageUpload.propTypes = {
  toggleModal: PropTypes.func,
};

export default ImageUpload;
