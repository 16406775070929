import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import actions from '~/app/entities/actions';
import { eventSchema } from '~/app/entities/schema';
import { getDataFromState } from '~/app/entities/utils';
import EventCardList from '~/app/event-list/components/EventCardList';
import {
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
} from '~/app/event-shared/constants';
import { allowSelfCheckin } from '~/app/event-shared/services';
import BaseContainer from '~/app/navigation/components/BaseContainer';
import PageHeader from '~/app/shared/components/PageHeader';
import { API_DATE_FORMAT } from '~/app/shared/constants';
import { filter } from 'lodash-es';

class Scene extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      didCallFetchEvent: false,
    };
  }

  componentDidMount = () => {
    this.fetchEventList();
  };

  componentDidUpdate = () => {
    this.fetchEventList();
  };

  fetchEventList = () => {
    const { currentUser, fetchEventList } = this.props;
    const { didCallFetchEvent } = this.state;

    if (!didCallFetchEvent && currentUser && currentUser.id) {
      fetchEventList(currentUser.id);
      this.setState({ didCallFetchEvent: true });
    }
  };

  render = () => {
    const { eventList, eventListStatus, eventListMoreLink, fetchNextPage } = this.props;

    const checkinEvents = filter(eventList, (e) => allowSelfCheckin(e));

    return (
      <BaseContainer>
        <PageHeader subText="Please check into events that you attended recently or are attending right now.">
          Event Check-In
        </PageHeader>
        <EventCardList
          noEventsMessage="No checkins available"
          eventList={checkinEvents}
          eventListStatus={eventListStatus}
          eventListMoreLink={eventListMoreLink}
          fetchNextPage={fetchNextPage}
        />
      </BaseContainer>
    );
  };
}

Scene.propTypes = {
  currentUser: PropTypes.object,

  fetchEventList: PropTypes.func,
  eventList: PropTypes.array,
  eventListStatus: PropTypes.string,
  eventListMoreLink: PropTypes.string,
  fetchNextPage: PropTypes.func,
};

const mapStateToProps = (state) => {
  const requestState = getDataFromState('eventsToCheckin', state, [eventSchema]);
  return {
    currentUser: state.user.currentUser,

    eventList: requestState.data,
    eventListStatus: requestState.status,
    eventListMoreLink: requestState.nextPage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchEventList: (userId) =>
    dispatch(
      actions.event.retrieveList('eventsToCheckin', {
        engaged_users: [userId],
        engagement_type: [ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE],
        starts_after: [moment().subtract(1, 'days').format(API_DATE_FORMAT)],
        starts_before: [moment().add(1, 'days').format(API_DATE_FORMAT)],
      })
    ),
  fetchNextPage: (url) => dispatch(actions.event.retrieveListLoadMore('eventsToCheckin', url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scene);
