import {
  Widget,
  WidgetContent,
  WidgetContentList,
  WidgetContentListItem,
  WidgetLinkAction,
} from '~/common/components/widget';
import { mapRoute } from '~/services/requests';
import { get, map, size } from 'lodash-es';

import { EventsWidgetLoadingContent, EventWidgetRow } from '../components/BaseEventsAsyncWidget';
import { useEventsWidgetQuery } from '../hooks/useEventsWidgetQuery';

type TopEventsWidgetProps = {
  hideOnEmpty?: boolean;
};

export function TopEventsWidget(props: TopEventsWidgetProps) {
  const { hideOnEmpty = false } = props;

  const search = 'gt(start_time,-PT0H)&ordering(-total_assignments)';

  const { data, isLoading } = useEventsWidgetQuery(search);

  const results = get(data, 'results', []);
  const resultsTotal = get(data, 'total', 0);
  const displayResults = !isLoading && size(results) > 0;

  const viewAllUrl = `${mapRoute('eventsList')}?${search}`;

  if (hideOnEmpty && (isLoading || !displayResults)) {
    return null;
  }

  return (
    <Widget
      title="Most Engaged Events"
      tooltip="Events with the highest attendance count"
      action={
        resultsTotal > 3 ? (
          <WidgetLinkAction label={`View all ${resultsTotal}`} url={viewAllUrl} />
        ) : null
      }
    >
      <WidgetContent>
        <WidgetContentList>
          {displayResults ? (
            map(results, (row) => (
              <WidgetContentListItem key={row.id}>
                <EventWidgetRow event={row} />
              </WidgetContentListItem>
            ))
          ) : (
            <EventsWidgetLoadingContent animate={isLoading} />
          )}
        </WidgetContentList>
      </WidgetContent>
    </Widget>
  );
}
