import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import * as schema from '~/app/entities/schema';
import { sessionEnrollmentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { visibilityFilter } from '~/app/groups/constants';
import SearchInput from '~/app/inputs/components/SearchInput';
import SessionEveryoneList from '~/app/program/components/SessionEveryoneList';
import SessionGroupList from '~/app/program/components/SessionGroupList/SessionGroupList';
import { getSessionsAvailableCount } from '~/app/program/services';
import colors from '~/services/colors';
import propTypes from '~/services/prop-types';
import Text from '~/app/shared/components/Text';
import { useCurrentUser, useDebounce } from '~/app/shared/hooks';

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 600px;
`;

const ControlsArea = styled.div`
  flex: 0 0 72px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchField = styled.div`
  flex: 1 1 300px;
`;

const StyledHR = styled.hr`
  color: gray;
  width: 100%;
  margin: 0px;
`;

const RosterSectionWrapper = styled.section`
  border-bottom: 1px solid ${colors.neutral100};
`;

const RosterSectionTitle = styled.div`
  margin: 0;
  padding: 12px 20px;
  padding-bottom: 0;
  text-transform: uppercase;
  font-size: 14px;
  color: ${colors.neutral400};
`;

const RosterSection = ({ title, showIf, children }) =>
  showIf && (
    <RosterSectionWrapper>
      <RosterSectionTitle>{title}</RosterSectionTitle>
      {children}
    </RosterSectionWrapper>
  );

RosterSection.propTypes = {
  title: PropTypes.string,
  showIf: PropTypes.bool,
  children: propTypes.anyChildren,
};

const USERS_PAGE_SIZE = 15;
const GROUPS_PAGE_SIZE = 10;

const SessionRosterEveryone = ({ session }) => {
  const [searchText, setSearchText] = useState('');
  const debounceSearchText = useDebounce(searchText, 500);
  const currentUser = useCurrentUser();

  const [getUsers, { data: users, status: usersStatus, count: usersCount }] = useEntities(
    actions.userData.retrieveList,
    null,
    {
      schema: [schema.userSchema],
    }
  );

  const [getPublicGroups, { data: publicGroups, count: publicGroupsCount }] = useEntities(
    actions.groups.retrieveList,
    null,
    {
      schema: [schema.groupSchema],
    }
  );

  const [getPrivateGroups, { data: privateGroups, count: privateGroupsCount }] = useEntities(
    actions.groups.retrieveList,
    null,
    {
      schema: [schema.groupSchema],
    }
  );

  const [getEnrollments] = useEntities(actions.sessionEnrollment.retrieveList, null, {
    schema: [sessionEnrollmentSchema],
  });

  useEffect(() => {
    getUsers({
      q: debounceSearchText,
      o: 'name',
      page_size: USERS_PAGE_SIZE,
    });
    getPublicGroups({
      q: debounceSearchText,
      o: 'name',
      visibility: visibilityFilter.public,
      page_size: GROUPS_PAGE_SIZE,
    });
    getPrivateGroups({
      q: debounceSearchText,
      o: 'name',
      visibility: visibilityFilter.private,
      owner: currentUser.id,
      page_size: GROUPS_PAGE_SIZE,
    });
  }, [debounceSearchText]);

  const handleOnSearchTextChange = (value) => setSearchText(value);

  const isSessionAvailable =
    session.attendance_limit === 0 || getSessionsAvailableCount(session) > 0;

  return (
    <ContentContainer>
      <ControlsArea>
        <SearchField>
          <SearchInput
            placeholder="Search a person or group"
            value={searchText}
            onChange={handleOnSearchTextChange}
            disabled={!isSessionAvailable}
          />
          {!isSessionAvailable && (
            <Text block color={colors.neutral400}>
              Sorry, this session is fully booked
            </Text>
          )}
        </SearchField>
      </ControlsArea>
      <StyledHR />
      <React.Fragment>
        <RosterSection title="People" showIf={usersCount > 0}>
          <SessionEveryoneList
            session={session}
            users={users}
            usersStatus={usersStatus}
            isSessionAvailable={isSessionAvailable}
            refreshEnrollments={() => getEnrollments({ session: session.public_id })}
          />
        </RosterSection>

        <RosterSection title="Private Groups" showIf={privateGroupsCount > 0}>
          <SessionGroupList
            session={session}
            groups={privateGroups}
            groupsTotalCount={privateGroupsCount}
            refreshEnrollments={() => getEnrollments({ session: session.public_id })}
          />
        </RosterSection>

        <RosterSection title="Public Groups" showIf={publicGroupsCount > 0}>
          <SessionGroupList
            session={session}
            groups={publicGroups}
            groupsTotalCount={publicGroupsCount}
            refreshEnrollments={() => getEnrollments({ session: session.public_id })}
          />
        </RosterSection>
      </React.Fragment>
    </ContentContainer>
  );
};

SessionRosterEveryone.propTypes = {
  session: PropTypes.object,
};

export default SessionRosterEveryone;
