import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Clicker from '~/app/shared/components/Clicker';
import Icon from '~/app/shared/components/Icon';

const variantMapping = {
  neutral: {
    backgroundColor: colors.neutral0,
    borderColor: colors.neutral200,
    highlightColor: colors.neutral400,
  },
  alert: {
    backgroundColor: colors.alert100,
    borderColor: colors.alert600,
    highlightColor: colors.alert600,
  },
  news: {
    backgroundColor: colors.success100,
    borderColor: colors.success600,
    highlightColor: colors.success600,
  },
  critical: {
    backgroundColor: colors.error100,
    borderColor: colors.error600,
    highlightColor: colors.error600,
  },
};

const BannerWrapper = styled.article`
  display: flex;
  align-items: center;
  background-color: ${({ variant }) => variantMapping[variant]?.backgroundColor ?? colors.neutral0};
  border: 1px solid ${({ variant }) => variantMapping[variant]?.borderColor ?? colors.neutral0};
  min-height: 50px;
  padding: 16px 20px;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  a {
    ${({ variant }) => `color: ${variantMapping[variant]?.highlightColor}`};

    &:hover,
    &:active,
    &:focus {
      ${({ variant }) => `color: ${variantMapping[variant]?.highlightColor}`};
      text-decoration: underline;
    }
  }
`;

const BannerIconWrapper = styled.div`
  margin-right: 20px;
`;

const BannerDescriptionContainer = styled.div`
  flex-grow: 1;
`;

const Banner = ({ children, handleClose, variant, renderIcon }) => {
  return (
    <BannerWrapper variant={variant}>
      {renderIcon && <BannerIconWrapper>{renderIcon}</BannerIconWrapper>}
      <BannerDescriptionContainer>{children}</BannerDescriptionContainer>
      <Clicker onClick={handleClose}>
        <Icon name="close" color={colors.neutral900} width={10} height={10} />
      </Clicker>
    </BannerWrapper>
  );
};

Banner.propTypes = {
  children: PropTypes.any,
  handleClose: PropTypes.func,
  variant: PropTypes.string,
  renderIcon: PropTypes.any,
};

export default Banner;
