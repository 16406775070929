import {
  Box,
  CardMedia as MUICardMedia,
  CardActionArea as MUICardActionArea,
  CardActionAreaProps,
  Chip,
  Icon as MUIIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { ElementType } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { ValueOf } from 'type-fest';

import {
  ASSIGNMENT_STATES,
  ASSIGNMENT_STATES_LABELS,
  ASSIGNMENT_STATES_ICONS,
  ASSIGNMENT_STATES_COLORS,
} from '~/app/assignments/constants';
import colors from '~/services/colors';
import NewIcon from '~/app/shared/components/Icon/Icons/new.svg';
import { get } from 'lodash-es';

import { Assignment, ContentItem } from '~/app/shared-content-item/interfaces';

const CardMedia = styled(MUICardMedia)(() => ({
  height: '165px',
}));

const CardActionArea = styled(MUICardActionArea, {
  name: 'ContentItemCard',
  slot: 'cover',
})<CardActionAreaProps & { component: ElementType; to: string }>`
  position: relative;
`;

type AssignmentStatusVariant = Extract<
  ValueOf<typeof ASSIGNMENT_STATES>,
  'in_progress' | 'completed'
>;

type AssignmentStatusPillProps = {
  state: AssignmentStatusVariant;
};

function AssignmentStatusPill(props: AssignmentStatusPillProps) {
  const { state } = props;

  const bg = ASSIGNMENT_STATES_COLORS[state];
  const Icon = ASSIGNMENT_STATES_ICONS[state] as typeof MUIIcon;
  const label = ASSIGNMENT_STATES_LABELS[state];

  return (
    <Chip
      icon={<Icon sx={{ color: `${colors.neutral0} !important` }} />}
      label={label}
      size="small"
      sx={{
        backgroundColor: bg,
        color: colors.neutral0,
      }}
    />
  );
}

type ContentItemCardCoverProps = CardActionAreaProps & {
  contentItem: ContentItem;
  userAssignment?: Assignment;
  route: string;
};

export function ContentItemCardCover(props: ContentItemCardCoverProps) {
  const { contentItem, route, userAssignment, ...restCardActionAreaProps } = props;

  const coverImg = contentItem.cover_url ?? contentItem.cover ?? contentItem.default_cover;
  const createdAt = get(contentItem, 'created', get(contentItem, 'created_at'));

  const now = moment();
  const isNew = now.diff(createdAt, 'days') < 30;

  const assignmentState = get(userAssignment, 'state');
  const shouldDisplayAssignmentState =
    assignmentState === ASSIGNMENT_STATES.in_progress ||
    assignmentState === ASSIGNMENT_STATES.completed;

  return (
    <CardActionArea disableRipple component={RouterLink} to={route} {...restCardActionAreaProps}>
      <CardMedia image={coverImg} />

      {shouldDisplayAssignmentState && (
        <Box position="absolute" top="12px" left="16px">
          <AssignmentStatusPill state={assignmentState as AssignmentStatusVariant} />
        </Box>
      )}

      {isNew && (
        <Box position="absolute" top="0px" right="0px">
          <NewIcon />
        </Box>
      )}
    </CardActionArea>
  );
}
