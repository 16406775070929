import PropTypes from 'prop-types';
import React from 'react';

import { displayDatetime } from '~/services/datetime';
import Modal from '~/app/shared/components/Modal';

import SessionAttendeeEnrollForm from './SessionAttendeeEnrollForm';

const SessionAttendeeEnrollModal = ({ session, onFormSubmitFinish, handleClose }) => {
  const title = `${session.program_name || session.name} - ${displayDatetime(
    session.starts_at_tz_aware,
    session.timezone
  )}`;

  return (
    <Modal title={title} handleClose={handleClose} width={600}>
      <SessionAttendeeEnrollForm
        session={session}
        onSubmitSuccess={onFormSubmitFinish}
        onSubmitFailure={onFormSubmitFinish}
      />
    </Modal>
  );
};

SessionAttendeeEnrollModal.propTypes = {
  session: PropTypes.object,
  onFormSubmitFinish: PropTypes.func,
  handleClose: PropTypes.func,
};

export default SessionAttendeeEnrollModal;
