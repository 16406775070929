import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';

import PeopleSegmentSelectField from '~/app/inputs/components/PeopleSegmentSelectField';
import { DASHBOARD_TYPES_URL_MAPPING } from '~/scenes/Dashboard/constants';
import { mapRoute } from '~/services/requests';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import ButtonLink from '~/app/shared/components/ButtonLink';
import { FormFieldGroup } from '~/app/shared/components/Form';
import InfoBox from '~/app/shared/components/InfoBox';
import InfoText from '~/app/shared/components/InfoText';
import Form from '~/app/shared/components/OldForm';
import Text from '~/app/shared/components/Text';

const validateRequired = Form.validations.required();

export const ConditionSection = () => {
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [affectedPeople, setAffectedPeople] = useState(0);

  const fetchUsers = async (expression) => {
    const pathname = ApiURLs['api_users:users_rql']();
    const url = `${pathname}?${expression}&page_size=1`;

    return fetchURL(url).then((response) => {
      const { count } = response.data;
      setAffectedPeople(count || 0);
      return response;
    });
  };

  useEffect(() => {
    if (!selectedSegment) return;
    fetchUsers(selectedSegment.expression);
  }, [selectedSegment]);

  return (
    <FormFieldGroup>
      <Field
        name="segment_id"
        label="People Segment"
        required
        component={PeopleSegmentSelectField}
        validate={[validateRequired]}
        setSelectedSegment={setSelectedSegment}
      />
      <InfoText
        top={4}
        content={
          <Text>
            Select the segment for this rule.{' '}
            <ButtonLink route={mapRoute('dashboardPeople')} target="_blank">
              Create a new segment.
            </ButtonLink>
          </Text>
        }
      />
      {selectedSegment && affectedPeople > 0 && (
        <InfoBox
          content={`${affectedPeople} ${
            affectedPeople === 1 ? 'person' : 'people'
          } in this segment`}
          actionText="View Segment"
          route={mapRoute(DASHBOARD_TYPES_URL_MAPPING[selectedSegment.content_type], {
            public_id: selectedSegment.public_id,
          })}
          target="_blank"
          hideIcon
        />
      )}
    </FormFieldGroup>
  );
};
