import {
  inputToRQL,
  inputToRQLSingle,
  rqlToInput,
} from '~/app/backoffice/components/Dashboard/Filters/utils';
import { RQLComp, RQLList } from '~/app/shared/components/types';
import { noop, toString } from 'lodash-es';

import Select, { InputValue, Option } from '../Select/Select';

interface RQLSelectProps {
  label?: string;
  value?: RQLComp | RQLList | null;
  onChange?: (newValue: RQLComp | RQLList | null) => void;
  multiple?: boolean;
  sortOptions?: boolean;
  sortByOption?: ((option: Option) => string) | string;
  options?: Option[];
  getOptionLabel?: (option: Option) => string;
  queryName?: string; // Used to fetch the options
  queryParams?: Record<string, string | number | boolean>; // Extra params to be used to fetch the options
  fetchQueryOnce?: boolean; // Useful for queries that return all data in the first request. E.g. title and department endpoints.
  limitTags?: number;
  width?: number | string;
}

const RQLSelect = ({
  label,
  value = null,
  onChange = noop,
  multiple = false,
  options = [],
  sortOptions = false,
  sortByOption = 'name',
  getOptionLabel = (option: Option) => option.name || toString(option.value),
  queryName = '',
  queryParams = {},
  fetchQueryOnce = false,
  limitTags = 1,
  width = 300,
}: RQLSelectProps) => {
  const handleNewValue = multiple ? inputToRQL : inputToRQLSingle;
  return (
    <Select
      label={label}
      value={rqlToInput(value)}
      onChange={(newValue: InputValue) =>
        onChange(handleNewValue(newValue) as RQLComp | RQLList | null)
      }
      multiple={multiple}
      options={options}
      sortOptions={sortOptions}
      sortByOption={sortByOption}
      getOptionLabel={getOptionLabel}
      queryName={queryName}
      queryParams={queryParams}
      fetchQueryOnce={fetchQueryOnce}
      limitTags={limitTags}
      width={width}
    />
  );
};

export default RQLSelect;
