import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import colors from '~/services/colors';
import { isEmpty, isObject, map, toString } from 'lodash-es';

interface HeadProps {
  columns: string[];
}

interface BodyProps {
  rows: any[];
}

interface TableDisplayProps {
  rows: any[];
  columns: string[];
}

const parseItem = (item) => {
  if (isObject(item)) {
    return JSON.stringify(item);
  }
  return toString(item);
};

const Head = (props: HeadProps) => {
  const { columns } = props;

  return (
    <TableHead sx={{ borderBottom: `1px solid ${colors.neutral200}` }}>
      <TableRow>
        {map(columns, (column) => (
          <TableCell
            key={column}
            sx={{
              padding: '12px 16px 12px 16px',
              fontWeight: 'bold',
            }}
          >
            {column}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const Body = (props: BodyProps) => {
  const { rows } = props;

  return (
    <TableBody>
      {map(rows, (row, index) => (
        <TableRow key={index}>
          {map(row, (item, itemIndex) => (
            <TableCell
              key={itemIndex}
              sx={{
                padding: '4px 16px 4px 16px',
                borderBottom: 'none', // No bottom border
              }}
            >
              {parseItem(item)}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

const TableDisplay = (props: TableDisplayProps) => {
  const { rows, columns } = props;

  if (isEmpty(rows)) {
    return null;
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: '100%',
        botderRadius: '4px',
        marginTop: '8px',
        backgroundColor: colors.neutral50,
        border: `1px solid ${colors.neutral200}`,
        borderRadius: '4px',
        boxShadow: 'none',
      }}
    >
      <Table>
        <Head columns={columns} />
        <Body rows={rows} />
      </Table>
    </TableContainer>
  );
};

export default TableDisplay;
