import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  MENTORSHIP_REQUEST_STATUS_OPEN,
  MENTORSHIP_REQUEST_STATUS_DISMISSED,
  MENTORSHIP_REQUEST_STATUS_ACCEPTED,
  MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR,
  MENTORSHIP_REQUEST_KIND_SESSION_REQUEST,
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE,
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR,
} from '~/app/program/constants';
import { useMentorshipRequestActions } from '~/app/program/hooks';
import { Button } from '~/app/shared';
import ButtonLink from '~/app/shared/components/ButtonLink';
import { includes } from 'lodash-es';

import BaseMentorshipRequestCard from './BaseMentorshipRequestCard';

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  // button height
  min-height: 32px;
`;

const MESSAGE_MAP = {
  [MENTORSHIP_REQUEST_STATUS_OPEN]: {
    [MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR]: 'They offered to mentor you',
    [MENTORSHIP_REQUEST_KIND_SESSION_REQUEST]: 'They requested a session with you',
  },
  [MENTORSHIP_REQUEST_STATUS_ACCEPTED]: {
    [MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR]: 'You accepted their offer to mentor',
    [MENTORSHIP_REQUEST_KIND_SESSION_REQUEST]: 'You accepted their session request',
    [MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR]: 'You accepted this suggestion',
    [MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE]: 'You accepted this suggestion',
  },
  [MENTORSHIP_REQUEST_STATUS_DISMISSED]: {
    [MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR]: 'You dismissed their offer to mentor',
    [MENTORSHIP_REQUEST_KIND_SESSION_REQUEST]: 'You dismissed their session request',
    [MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR]: 'You dismissed this suggestion',
    [MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE]: 'You dismissed this suggestion',
  },
};

const MentorshipRequestReceivedCard = ({ mentorshipRequest }) => {
  const { sender, status, kind } = mentorshipRequest;
  const { dismiss, reviewRoute, suggestedReviewRoute, scheduleRequestedSessionRoute, reopen } =
    useMentorshipRequestActions(mentorshipRequest);

  const isMentorshipSuggestion = includes(
    [MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE, MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR],
    kind
  );

  return (
    <BaseMentorshipRequestCard
      pillIcon="incoming"
      user={sender}
      mentorshipRequest={mentorshipRequest}
      statusMessage={MESSAGE_MAP[status][kind]}
      renderActions={
        <>
          {status === MENTORSHIP_REQUEST_STATUS_DISMISSED && (
            <ActionsContainer>
              <ButtonLink variant="success" onClick={reopen}>
                {kind === MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR && 'Reopen Offer'}
                {kind === MENTORSHIP_REQUEST_KIND_SESSION_REQUEST && 'Reopen Request'}
                {kind === MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE && 'Reopen Suggestion'}
                {kind === MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR && 'Reopen Suggestion'}
              </ButtonLink>
            </ActionsContainer>
          )}
          {status === MENTORSHIP_REQUEST_STATUS_OPEN && (
            <ActionsContainer>
              <ButtonLink variant="error" onClick={dismiss}>
                Dismiss
              </ButtonLink>
              {kind === MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR && (
                <Button route={reviewRoute}>View Profile</Button>
              )}

              {isMentorshipSuggestion && <Button route={suggestedReviewRoute}>View Profile</Button>}

              {kind === MENTORSHIP_REQUEST_KIND_SESSION_REQUEST && (
                <Button route={scheduleRequestedSessionRoute}>Schedule</Button>
              )}
            </ActionsContainer>
          )}
        </>
      }
    />
  );
};

MentorshipRequestReceivedCard.propTypes = {
  mentorshipRequest: PropTypes.object,
};

export default MentorshipRequestReceivedCard;
