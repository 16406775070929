import { ChevronRight, KeyboardTab as ExitIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useIsPreviewQueryParam } from '~/app/shared/hooks';

import { useTrackContentConsumptionCtx } from '../contexts/TrackContentConsumptionCtx';
import { useTrackContentNavigationCtx } from '../contexts/TrackContentNavigationCtx';

type TrackContentAdvanceActionProps = {
  contentItem?: ContentItem;
};

export function TrackContentAdvanceAction(props: TrackContentAdvanceActionProps) {
  const { contentItem } = props;

  const { currentTrackNode } = useTrackContentConsumptionCtx();
  const {
    current: currentNavigation,
    onAdvance,
    onExitCurrentTrack,
  } = useTrackContentNavigationCtx();

  const isPreviewMode = useIsPreviewQueryParam();

  const trackAssignment = currentTrackNode.track.user_assignment;
  const isCurrentTrackCompleted = trackAssignment?.state === ASSIGNMENT_STATES.completed;

  const isLastPage = currentNavigation.page === currentNavigation.totalPages;
  const isCurrentItemCompleted =
    contentItem?.user_assignment?.state === ASSIGNMENT_STATES.completed;
  const displayAsPrimaryAction = isCurrentItemCompleted || isPreviewMode;

  if (!isLastPage) {
    return (
      <Button
        variant={displayAsPrimaryAction ? 'contained' : 'outlined'}
        endIcon={<ChevronRight />}
        onClick={onAdvance}
        disabled={isLastPage}
      >
        Next
      </Button>
    );
  }

  return (
    <Button
      variant={displayAsPrimaryAction ? 'contained' : 'outlined'}
      endIcon={<ExitIcon />}
      onClick={onExitCurrentTrack}
    >
      {isCurrentTrackCompleted ? 'Finish' : 'Exit'}
    </Button>
  );
}
