import PropTypes from 'prop-types';
import React from 'react';
import Linkify from 'react-linkify';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Pill from '~/app/shared/components/Pill';
import OutsideLearningHoursBadge from '~/app/event-shared/components/OutsideLearningHoursBadge';
import { componentDecorator } from '~/app/event-shared/services';
import colors from '~/services/colors';
import { formatDuration } from '~/services/datetime';
import { ApiURLs } from '~/services/requests-base';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Datetime from '~/app/shared/components/Datetime';
import { OldIcon } from '~/app/shared/components/Icon';
import { useToggles } from '~/app/shared/hooks';
import { isEmpty, map } from 'lodash-es';

const StyledIcon = styled(OldIcon)`
  color: ${colors.neutral600};
  margin-right: 5px;
`;

const TimeslotDescriptionLines = styled.div`
  color: ${colors.neutral600};
  font-weight: 500;
`;

const JoinButtonWrapper = styled.div`
  margin-left: 8px;
`;

const SingleDateTimeContainer = styled.div`
  display: flex;
  color: ${colors.neutral600};
`;

const SingleTimeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
`;

const DatetimeContainer = styled.div`
  color: ${colors.neutral600};
  font-weight: 700;
  display: flex;
`;

const StyledTimeslotsList = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const TimeslotListItem = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid ${colors.neutral200};
  &:last-child {
    border-bottom: none;
  }
`;

const TimeslotDescription = styled.div`
  font-family: Roboto;
  font-weight: 500;
  text-align: left;
  color: ${colors.neutral900};
  margin-top: 5px;
  width: 100%;
`;

const PillWrapper = styled.div`
  margin-top: 4px;
`;

const DetailsTimeslotsList = (props) => {
  const {
    timeslots,
    locationName,
    locationTimezone,
    eventIsOnline,
    userTimezone,
    viewMode,
    userIsEnrolled,
  } = props;

  const toggles = useToggles();
  const { toggle_allow_auto_checkin_on_joining_vc: toggleAllowAutoCheckinOnJoiningVC } = toggles;

  let videoConferenceLink;
  if (!isEmpty(timeslots)) {
    videoConferenceLink = ApiURLs.video_conference_redirect({ pk: timeslots[0].id });
  }

  return timeslots.length === 1 ? (
    <SingleTimeInfoContainer>
      <SingleDateTimeContainer>
        <StyledIcon name="calendar-blank" size="small" />
        <Datetime.Range
          start={timeslots[0].starts_at_tz_aware}
          end={timeslots[0].ends_at_tz_aware}
          timezone={locationTimezone}
          displayTimezone={userTimezone}
          isOnline={eventIsOnline}
        />
        {userIsEnrolled && eventIsOnline && timeslots.length === 1 && (
          <JoinButtonWrapper>
            <ButtonLink
              url={
                toggleAllowAutoCheckinOnJoiningVC ? videoConferenceLink : timeslots[0].watch_link
              }
              target="_blank"
            >
              Join Online
            </ButtonLink>
          </JoinButtonWrapper>
        )}
        {!timeslots[0].is_inside_learning_hours && <OutsideLearningHoursBadge />}
      </SingleDateTimeContainer>
      <PillWrapper>
        <Pill label={formatDuration(timeslots[0].duration)} />
      </PillWrapper>
    </SingleTimeInfoContainer>
  ) : (
    <StyledTimeslotsList viewMode={viewMode} timeslotsCount={timeslots.length}>
      {map(timeslots, (slot) => {
        if (isEmpty(slot)) {
          return null;
        }

        const secondLine = `${
          slot.rooms_info.length > 0 ? `${slot.rooms_info} @ ` : ''
        }${locationName}`;
        const thirdLine = slot.extra_info;
        videoConferenceLink = ApiURLs.video_conference_redirect({ pk: slot.id });

        return (
          <TimeslotListItem timeslotsCount={timeslots.length} key={slot.id}>
            <TimeslotDescription>
              <DatetimeContainer>
                <Datetime.Range
                  start={slot.starts_at_tz_aware}
                  end={slot.ends_at_tz_aware}
                  timezone={locationTimezone}
                  displayTimezone={userTimezone}
                  isOnline={eventIsOnline}
                />
                {userIsEnrolled && slot.watch_link && (
                  <JoinButtonWrapper>
                    <ButtonLink
                      url={
                        toggleAllowAutoCheckinOnJoiningVC ? videoConferenceLink : slot.watch_link
                      }
                      target="_blank"
                    >
                      Join Online
                    </ButtonLink>
                  </JoinButtonWrapper>
                )}
                {!slot.is_inside_learning_hours && <OutsideLearningHoursBadge />}
              </DatetimeContainer>
              {viewMode === 'details' && secondLine && (
                <TimeslotDescriptionLines>{secondLine}</TimeslotDescriptionLines>
              )}
              {viewMode === 'details' && thirdLine && (
                <TimeslotDescriptionLines>
                  <Linkify componentDecorator={componentDecorator}>{thirdLine}</Linkify>
                </TimeslotDescriptionLines>
              )}
              <TimeslotDescriptionLines>
                <PillWrapper>
                  <Pill label={formatDuration(slot.duration)} />
                </PillWrapper>
              </TimeslotDescriptionLines>
            </TimeslotDescription>
          </TimeslotListItem>
        );
      })}
    </StyledTimeslotsList>
  );
};

DetailsTimeslotsList.propTypes = {
  timeslots: PropTypes.array,
  locationName: PropTypes.string,
  locationTimezone: PropTypes.string,
  eventIsOnline: PropTypes.bool,
  userIsEnrolled: PropTypes.bool,
  userTimezone: PropTypes.string,
  viewMode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userTimezone: state.user.currentUser.timezone,
});

export default connect(mapStateToProps, null)(DetailsTimeslotsList);
