import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import AccordionRadioField from '~/app/inputs/components/AccordionRadioField';
import TextField from '~/app/inputs/components/TextField';
import UserSelectField from '~/app/inputs/components/UserSelectField';
import {
  ATTENDANCE_TYPE_MULTIPLE_ATTENDEES,
  ATTENDANCE_TYPE_SINGLE_ATTENDEE,
} from '~/app/program/constants';
import * as formValidations from '~/app/shared/formValidations';
import { useFormSelector } from '~/app/shared/hooks';

const validateRequired = formValidations.required();
const validateAttendanceMultipleMin = formValidations.minLength(1, {
  message: 'Must select at least 1 attendee',
});

const MultipleAttendees = ({ formKey, input, meta, multipleAttendeesLabel, ...props }) => {
  const in_the_past = useFormSelector(formKey, 'in_the_past');

  return (
    <AccordionRadioField
      input={input}
      meta={meta}
      value={ATTENDANCE_TYPE_MULTIPLE_ATTENDEES}
      label={`Multiple ${multipleAttendeesLabel}`}
      {...props}
    >
      <div className="row">
        <div className="col-sm-12">
          {in_the_past ? (
            <Field
              name="attendance_multiple_ids"
              label="Attendees"
              component={UserSelectField}
              multiple
              limitTags={-1}
              validate={[validateAttendanceMultipleMin]}
            />
          ) : (
            <Field
              name="attendance_limit_multiple"
              label="Session Cap"
              component={TextField}
              type="number"
              placeholder="Unlimited"
            />
          )}
        </div>
      </div>
    </AccordionRadioField>
  );
};

MultipleAttendees.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  multipleAttendeesLabel: PropTypes.string,
  formKey: PropTypes.string,
};

const SingleAttendee = ({ formKey, input, meta, singleAttendeeLabel, ...props }) => {
  const in_the_past = useFormSelector(formKey, 'in_the_past');
  const isSingleAttendance =
    useFormSelector(formKey, 'attendance_type') === ATTENDANCE_TYPE_SINGLE_ATTENDEE;

  const isRequired = in_the_past && isSingleAttendance;

  return (
    <AccordionRadioField
      input={input}
      meta={meta}
      value={ATTENDANCE_TYPE_SINGLE_ATTENDEE}
      label="1:1 Session"
      {...props}
    >
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="attendee_id"
            label={`${singleAttendeeLabel} ${in_the_past ? '' : '(optional)'}`}
            component={UserSelectField}
            required={isRequired}
            validate={isRequired ? [validateRequired] : undefined}
          />
        </div>
      </div>
    </AccordionRadioField>
  );
};

SingleAttendee.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  singleAttendeeLabel: PropTypes.string,
  formKey: PropTypes.string,
};

export { SingleAttendee, MultipleAttendees };
