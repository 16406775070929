import { FilterDef } from '~/app/filters/components/RQLFilterBar/RQLFilterBar';
import { getFlexibleFiltersDef, getOrderedFiltersDefinition } from '~/app/filters/utils';
import { MENTOR_AVAILABILITY_OPTIONS } from '~/app/mentorship/constants';
import { RQLFilter } from '~/app/shared/components/types';
import { useCurrentUser, useFiltersOrder } from '~/app/shared/hooks';
import { map, get, toString, split, reduce, trim } from 'lodash-es';
import { useMediaQuery } from '@mui/material';

interface MentorshipFiltersDefinitionProps {
  filters: RQLFilter | null;
  updateFilter: (newFilter: RQLFilter) => void;
}

interface MentorshipFiltersDefinition {
  filters: FilterDef[];
  moreFilters: FilterDef[];
}
export const useMentorshipMentorFiltersDefinition = ({
  filters,
  updateFilter,
}: MentorshipFiltersDefinitionProps): MentorshipFiltersDefinition => {
  const currentUser = useCurrentUser();
  const { filter_order_mentors: labelAvailableMentorFilters } = useFiltersOrder();
  const isScreenMdDown = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  // TODO: default mentors order includes all flexible tags but mentor page doesn't show the mentee prefs? How?
  const defaultFiltersOrder = labelAvailableMentorFilters
    ? map(split(labelAvailableMentorFilters, ', '), trim)
    : [];
  const flexFilters = getFlexibleFiltersDef({
    filters,
    updateFilter,
    currentUser,
    toggleTypes: ['toggle_mentorship'],
  });

  const flexFilterDef = reduce(
    flexFilters,
    (acc, filterDef) => ({ ...acc, [toString(filterDef.filterName)]: filterDef }),
    {}
  );
  const allFilters: Record<string, FilterDef> = {
    SearchBar: {
      type: 'search_bar',
      placeholder: 'Search by keyword...',
      value: get(filters, 'q', null),
      onChange: (newValue) => updateFilter({ q: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
      },
    },
    'Available sessions': {
      type: 'select',
      label: 'Available Sessions',
      value: get(filters, 'available', null),
      options: MENTOR_AVAILABILITY_OPTIONS,
      onChange: (newValue) => updateFilter({ available: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
        lg: 2,
      },
    },
    Title: {
      type: 'model_select',
      label: 'Title',
      value: get(filters, 'title', null),
      multiple: true,
      sortOptions: true,
      queryName: 'users.titles',
      onChange: (newValue) => updateFilter({ title: newValue }),
      gridProps: {
        xs: 2,
      },
    },
    Department: {
      type: 'search_bar',
      label: 'Department',
      placeholder: 'Search by Department...',
      value: get(filters, 'department', null),
      onChange: (newValue) => updateFilter({ department: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
        lg: 2,
      },
    },
    Location: {
      type: 'model_select',
      label: 'Location',
      value: get(filters, 'location', null),
      multiple: true,
      sortOptions: true,
      queryName: 'locations',
      queryParams: { o: 'name' },
      onChange: (newValue) => updateFilter({ location: newValue }),
    },
    Groups: {
      type: 'model_select',
      label: 'Groups',
      value: get(filters, 'groups', null),
      multiple: true,
      sortOptions: true,
      queryName: 'groups',
      queryParams: { o: 'name' },
      onChange: (newValue) => updateFilter({ groups: newValue }),
    },
    ...flexFilterDef,
  };

  return getOrderedFiltersDefinition({
    currentUser,
    filterOrderName: 'filter_order_mentors',
    defaultFiltersOrder,
    allFilters,
    filterWidth: '100%',
    numberOfFiltersShownOutside: isScreenMdDown ? 0 : 2,
  });
};

export const useMentorshipMenteeFiltersDefinition = ({
  filters,
  updateFilter,
}: MentorshipFiltersDefinitionProps): MentorshipFiltersDefinition => {
  const currentUser = useCurrentUser();
  const { filter_order_mentees: labelAvailableMenteeFilters } = useFiltersOrder();
  const isScreenMdDown = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const defaultFiltersOrder = labelAvailableMenteeFilters
    ? map(split(labelAvailableMenteeFilters, ', '), trim)
    : [];
  const flexFilters = getFlexibleFiltersDef({
    filters,
    updateFilter,
    currentUser,
    toggleTypes: ['toggle_mentorship'],
  });

  const flexFilterDef = reduce(
    flexFilters,
    (acc, filterDef) => ({ ...acc, [toString(filterDef.filterName)]: filterDef }),
    {}
  );
  const allFilters: Record<string, FilterDef> = {
    SearchBar: {
      type: 'search_bar',
      placeholder: 'Search by keyword...',
      value: get(filters, 'q', null),
      onChange: (newValue) => updateFilter({ q: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
      },
    },
    Title: {
      type: 'model_select',
      label: 'Title',
      value: get(filters, 'title', null),
      multiple: true,
      sortOptions: true,
      queryName: 'users.titles',
      onChange: (newValue) => updateFilter({ title: newValue }),
      gridProps: {
        xs: 2,
      },
    },
    Department: {
      type: 'search_bar',
      label: 'Department',
      placeholder: 'Search by Department...',
      value: get(filters, 'department', null),
      onChange: (newValue) => updateFilter({ department: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
        lg: 2,
      },
    },
    Location: {
      type: 'model_select',
      label: 'Location',
      value: get(filters, 'location', null),
      multiple: true,
      sortOptions: true,
      queryName: 'locations',
      queryParams: { o: 'name' },
      onChange: (newValue) => updateFilter({ location: newValue }),
    },
    Groups: {
      type: 'model_select',
      label: 'Groups',
      value: get(filters, 'groups', null),
      multiple: true,
      sortOptions: true,
      queryName: 'groups',
      queryParams: { o: 'name' },
      onChange: (newValue) => updateFilter({ groups: newValue }),
    },
    ...flexFilterDef,
  };

  return getOrderedFiltersDefinition({
    currentUser,
    filterOrderName: 'filter_order_mentees',
    defaultFiltersOrder,
    allFilters,
    filterWidth: '100%',
    numberOfFiltersShownOutside: isScreenMdDown ? 0 : 2,
  });
};

export const useMentorshipSessionFiltersDefinition = ({
  filters,
  updateFilter,
}: MentorshipFiltersDefinitionProps): MentorshipFiltersDefinition => {
  const currentUser = useCurrentUser();
  const { filter_order_mentors: labelAvailableMentorFilters } = useFiltersOrder();
  const isScreenMdDown = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  // TODO: default mentors order includes all flexible tags but mentor page doesn't show the mentee prefs? How?
  const defaultFiltersOrder = labelAvailableMentorFilters
    ? map(split(labelAvailableMentorFilters, ', '), trim)
    : [];
  const flexFilters = getFlexibleFiltersDef({
    filters,
    updateFilter,
    currentUser,
    toggleTypes: ['toggle_mentorship'],
  });

  const flexFilterDef = reduce(
    flexFilters,
    (acc, filterDef) => ({ ...acc, [toString(filterDef.filterName)]: filterDef }),
    {}
  );
  const allFilters: Record<string, FilterDef> = {
    SearchBar: {
      type: 'search_bar',
      placeholder: 'Search by keyword...',
      value: get(filters, 'q', null),
      onChange: (newValue) => updateFilter({ q: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
      },
    },
    'Available sessions': {
      type: 'select',
      label: 'Available Sessions',
      value: get(filters, 'available', null),
      options: MENTOR_AVAILABILITY_OPTIONS,
      onChange: (newValue) => updateFilter({ available: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
        lg: 2,
      },
    },
    Title: {
      type: 'model_select',
      label: 'Title',
      value: get(filters, 'title', null),
      multiple: true,
      sortOptions: true,
      queryName: 'users.titles',
      onChange: (newValue) => updateFilter({ title: newValue }),
      gridProps: {
        xs: 2,
      },
    },
    Department: {
      type: 'search_bar',
      label: 'Department',
      placeholder: 'Search by Department...',
      value: get(filters, 'department', null),
      onChange: (newValue) => updateFilter({ department: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
        lg: 2,
      },
    },
    Location: {
      type: 'model_select',
      label: 'Location',
      value: get(filters, 'location', null),
      multiple: true,
      sortOptions: true,
      queryName: 'locations',
      queryParams: { o: 'name' },
      onChange: (newValue) => updateFilter({ location: newValue }),
    },
    Groups: {
      type: 'model_select',
      label: 'Groups',
      value: get(filters, 'groups', null),
      multiple: true,
      sortOptions: true,
      queryName: 'groups',
      queryParams: { o: 'name' },
      onChange: (newValue) => updateFilter({ groups: newValue }),
    },
    ...flexFilterDef,
  };

  return getOrderedFiltersDefinition({
    currentUser,
    filterOrderName: 'filter_order_mentors',
    defaultFiltersOrder,
    allFilters,
    filterWidth: '100%',
    numberOfFiltersShownOutside: isScreenMdDown ? 0 : 2,
  });
};
