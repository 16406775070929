import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Datetime from '~/app/shared/components/Datetime';
import { find, forEach, map, size } from 'lodash-es';

import MonthDaysDisplay from './MonthDaysDisplay';

const Wrapper = styled.div`
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MonthBoxes = styled.div`
  text-align: center;
`;

const MonthBox = styled.div`
  display: inline-block;
  margin: 0 15px;
  vertical-align: baseline;
`;

const TimeSpan = styled.span`
  display: block;
  max-height: 22px;
  overflow: hidden;
  margin: 4px 0 0 0;
`;

const Location = styled.span`
  display: block;
  max-height: 22px;
  overflow: hidden;
  font-size: 15px;
  margin: 4px 0 0 0;
`;

class EventDateLocation extends React.Component {
  getMaxDisplayDays() {
    const { timeslots, maxDisplayDays } = this.props;
    return maxDisplayDays || size(timeslots);
  }

  getDateInformation() {
    const MONTH_NAME_FORMAT = 'MMM';
    const MONTH_NUMBER = 'M';
    const DAY_FORMAT = 'D';
    const { timeslots } = this.props;

    const slots = timeslots || [];
    const entries = [];
    const slotsSubset = slots.slice(0, this.getMaxDisplayDays());

    forEach(slotsSubset, (slot) => {
      const date = moment(slot.starts_at_tz_aware);

      const day = date.format(DAY_FORMAT);
      const month = date.format(MONTH_NAME_FORMAT);
      const monthNumber = date.format(MONTH_NUMBER);
      let entry = find(entries, { monthNumber });
      if (!entry) {
        entry = {
          month,
          monthNumber,
          days: [],
        };
        entries.push(entry);
      }
      entry.days.push(day);
    });

    return entries;
  }

  hasMoreTimeslotsThanAllowedToDisplay() {
    const { timeslots, maxDisplayDays } = this.props;
    return timeslots.length > maxDisplayDays;
  }

  render() {
    const { eventStartsAt, eventEndsAt, displayTimezone, locationName, theme } = this.props;

    const dateInformation = this.getDateInformation();

    return (
      <Wrapper>
        <MonthBoxes>
          {map(dateInformation, (item, i) => (
            <MonthBox key={i}>
              <MonthDaysDisplay monthName={item.month} days={item.days} theme={theme} />
            </MonthBox>
          ))}
          {this.hasMoreTimeslotsThanAllowedToDisplay() && (
            <MonthBox>
              <i className="mdi mdi-dots-horizontal" aria-hidden="true" />
            </MonthBox>
          )}
        </MonthBoxes>
        <TimeSpan>
          <Datetime.TimeRange start={eventStartsAt} end={eventEndsAt} timezone={displayTimezone} />
        </TimeSpan>
        <Location>{locationName}</Location>
      </Wrapper>
    );
  }
}

EventDateLocation.propTypes = {
  timeslots: PropTypes.array,
  eventStartsAt: PropTypes.object,
  eventEndsAt: PropTypes.object,
  displayTimezone: PropTypes.string,
  locationName: PropTypes.string,
  theme: PropTypes.string,
  maxDisplayDays: PropTypes.number,
};

EventDateLocation.Wrapper = Wrapper;
EventDateLocation.MonthBoxes = MonthBoxes;
EventDateLocation.MonthBox = MonthBox;
EventDateLocation.TimeSpan = TimeSpan;
EventDateLocation.Location = Location;
EventDateLocation.MonthDaysDisplay = MonthDaysDisplay;

export default EventDateLocation;
