import { useComposableTrackCtx } from '~/features/tracks/contexts/ComposableTrackCtx';
import colors from '~/services/colors';
import { TrackItemCardList } from '~/app/tracks/components/TrackItemCardList';
import { Track, trackTypes } from '~/app/tracks/interfaces';
import { size } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Box } from '@mui/material';

interface SectionlessTrackItemSummaryProps {
  track: Track;
}

export const SectionlessTrackItemSummary = ({ track }: SectionlessTrackItemSummaryProps) => {
  const { content_type, public_id, user_assignment } = track;
  const pageSize = 9;

  const { filterTrackItems, getSectionOrItemIndex } = useComposableTrackCtx();
  const trackItems = filterTrackItems(public_id);

  return (
    <Box marginY={2.5}>
      <Box marginBottom={2}>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Summary</Typography>
        <Typography sx={{ fontSize: 14, color: colors.neutral500 }}>
          {size(trackItems)} {size(trackItems) === 1 ? 'item' : 'items'}
        </Typography>
      </Box>
      <TrackItemCardList
        items={trackItems}
        getTrackItemIndex={getSectionOrItemIndex}
        pageSize={pageSize}
        isSummary
        trackType={content_type as trackTypes}
        isPreview={!user_assignment}
      />
    </Box>
  );
};
