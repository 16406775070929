import PropTypes from 'prop-types';
import React from 'react';

import actions from '~/app/entities/actions';
import {
  userIsHost as currentUserIsHost,
  userIsAttendee as currentUserIsAttendee,
} from '~/app/program/services';
import FeedbackModal from '~/app/shared-cards/components/FeedbackModal';
import { useCurrentUser } from '~/app/shared/hooks';
import { get, isNil } from 'lodash-es';

import ReadOnlyModal from './ReadOnlyModal';

export const SessionFeedbackModal = ({ readOnly, session, userEnrollment, handleClose }) => {
  const currentUser = useCurrentUser();

  if (readOnly) {
    return <ReadOnlyModal session={session} handleClose={handleClose} />;
  }

  const getFeedbackInfo = () => {
    const isMentorshipSession = isNil(get(session, 'program_id'));
    const userIsHost = currentUserIsHost(session, currentUser);
    const userIsAttendee = currentUserIsAttendee(session, currentUser);

    let user;
    let feedbackText;
    let feedbackRating;

    // Attendee rates session
    if (userIsAttendee) {
      user = get(userEnrollment, 'attendee');
      feedbackText = get(userEnrollment, 'feedback_text');
      feedbackRating = get(userEnrollment, 'feedback_rating');
    }

    // Mentor rates session
    if (isMentorshipSession && userIsHost) {
      user = get(session, 'attendees.0');
      feedbackText = get(session, 'host_feedback_text');
      feedbackRating = get(session, 'host_feedback_rating');
    }

    return { user, feedbackText, feedbackRating };
  };

  const { id } = session;
  const { user, feedbackText, feedbackRating } = getFeedbackInfo();

  return (
    <FeedbackModal
      id={id}
      user={user}
      feedbackText={feedbackText}
      feedbackRating={feedbackRating}
      handleClose={handleClose}
      onSubmitActionName={actions.sessionEnrollment.feedbackUpdate.toString()}
      fieldLabels={{
        rating: 'Rate this session',
        feedback: 'Give private feedback',
      }}
      fieldPlaceholders={{
        feedback: 'How was your experience in this session?',
      }}
    />
  );
};

SessionFeedbackModal.propTypes = {
  readOnly: PropTypes.bool,
  session: PropTypes.object,
  userEnrollment: PropTypes.object,

  handleClose: PropTypes.func,
};

export default SessionFeedbackModal;
