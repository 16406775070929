import PropTypes from 'prop-types';
import React from 'react';

import { getSettingHashKey } from '~/app/settings/services';
import { FormSectionContainer } from '~/app/shared/components/Form';

const SettingContextMidLevelBlock = ({ settingName, setting, defaultOpen, children }) => {
  const hashKey = getSettingHashKey(settingName);
  return (
    <FormSectionContainer
      key={hashKey}
      id={hashKey}
      title={setting.label}
      description={setting.description}
      defaultOpen={defaultOpen}
    >
      {children}
    </FormSectionContainer>
  );
};

SettingContextMidLevelBlock.propTypes = {
  settingName: PropTypes.string,
  setting: PropTypes.object,
  defaultOpen: PropTypes.bool,
  children: PropTypes.node,
};

export default SettingContextMidLevelBlock;
