import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Text from '~/app/shared/components/Text';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Ellipse = styled.div`
  background-color: ${({ color }) => color};
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  border-radius: 50%;
  margin: 8px 8px 0px 8px;
  align-self: center;
`;

const InfoWrapper = styled.div`
  margin-top: 8px;
`;

const SubTitle = ({ title, department }) => {
  return (
    <Container>
      {title && (
        <InfoWrapper>
          <Text block medium size="h3" color={colors.neutral500} ellipsisOnOverflow>
            {title}
          </Text>
        </InfoWrapper>
      )}
      {title && department && <Ellipse color={colors.neutral500} width={4} height={4} />}
      {department && (
        <InfoWrapper>
          <Text block medium size="h3" color={colors.neutral500} ellipsisOnOverflow>
            {department}
          </Text>
        </InfoWrapper>
      )}
    </Container>
  );
};

SubTitle.propTypes = {
  title: PropTypes.string,
  department: PropTypes.string,
};

export default SubTitle;
