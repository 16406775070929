import {
  Widget,
  WidgetContent,
  WidgetContentList,
  WidgetContentListItem,
  WidgetLinkAction,
} from '~/common/components/widget';
import { mapRoute } from '~/services/requests';
import { get, map, size } from 'lodash-es';

import { UsersWidgetRow, UsersWidgetLoadingContent } from '../components/BaseUsersAsyncWidget';
import { useUsersWidgetQuery } from '../hooks/useUsersWidgetQuery';

type TopExpertsWidgetProps = {
  hideOnEmpty?: boolean;
};

export function TopExpertsWidget(props: TopExpertsWidgetProps) {
  const { hideOnEmpty = false } = props;

  const search = 'ordering(-unique_people_impacted_count)';

  const { data, isLoading } = useUsersWidgetQuery(search);

  const results = get(data, 'results', []);
  const resultsTotal = get(data, 'total', 0);
  const displayResults = !isLoading && size(results) > 0;

  const viewAllUrl = `${mapRoute('peopleList')}?${search}`;

  if (hideOnEmpty && (isLoading || !displayResults)) {
    return null;
  }

  return (
    <Widget
      title="Top Experts"
      tooltip="Experts who created Content with the highest consumption rate"
      action={
        resultsTotal > 3 ? (
          <WidgetLinkAction label={`View all ${resultsTotal}`} url={viewAllUrl} />
        ) : null
      }
    >
      <WidgetContent>
        <WidgetContentList>
          {displayResults ? (
            map(results, (row) => (
              <WidgetContentListItem key={row.id}>
                <UsersWidgetRow user={row} />
              </WidgetContentListItem>
            ))
          ) : (
            <UsersWidgetLoadingContent animate={isLoading} />
          )}
        </WidgetContentList>
      </WidgetContent>
    </Widget>
  );
}
