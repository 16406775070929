import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Clicker from '~/app/shared/components/Clicker';
import Icon from '~/app/shared/components/Icon';
import Text from '~/app/shared/components/Text';

const Header = styled(Clicker)`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ isOpen }) => (isOpen ? '16px' : '0')};
  width: 100%;
`;

const SurveySummaryWrapper = styled.div`
  position: relative;
  flex-basis: 55%;

  &::before {
    content: '';
    height: 44px;
    width: 1px;
    background-color: ${colors.neutral400};
    position: absolute;
    top: 0;
    left: -25px;
  }
`;

const NewSurveyBoxHeader = ({ isOpen, hasError, handleOnClick, survey }) => {
  return (
    <Header isOpen={isOpen} onClick={handleOnClick}>
      {isOpen && (
        <Text size="h4" medium>
          Survey
        </Text>
      )}
      {!isOpen && !survey && (
        <Text size="h4" medium color={colors.error600}>
          Select a Survey Template...
        </Text>
      )}
      {!isOpen && survey && (
        <>
          <Text size="h4" medium color={hasError ? colors.error600 : null}>
            {survey.title}
          </Text>
          <SurveySummaryWrapper>
            <Text size="h4" block>
              <strong>Survey Opens</strong> {survey.opens}
            </Text>
            <Text size="h4">
              <strong>Survey Closes</strong> {survey.closes}
            </Text>
          </SurveySummaryWrapper>
        </>
      )}
      <Icon name={isOpen ? 'up' : 'down'} width={16} height={16} color={colors.neutral900} />
    </Header>
  );
};

NewSurveyBoxHeader.propTypes = {
  isOpen: PropTypes.bool,
  hasError: PropTypes.bool,
  handleOnClick: PropTypes.func,
  survey: PropTypes.object,
};

export default NewSurveyBoxHeader;
