import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import actions from '~/app/entities/actions';
import { eventSchema } from '~/app/entities/schema';
import { getDataFromState } from '~/app/entities/utils';
import PosterCard from '~/app/event-list/components/PosterCard';
import { getUrlParser } from '~/services/utils';
import { STATUS_LOADING, STATUS_DONE, API_DATE_FORMAT } from '~/app/shared/constants';
import userActions from '~/app/users/actions';

import './style.scss';

const MIN_NUMBER_OF_DAYS = 10;
const MAX_NUMBER_OF_DAYS = 45;
const EVENT_CARROUSEL_REFRESH = 10 * 1000;
const EVENT_LIST_REFRESH = 30 * 60 * 1000;

export function setFilterDateRange(filters, units, value) {
  const filtersCopy = { ...filters };
  filtersCopy.starts_after = [moment().format(API_DATE_FORMAT)];
  filtersCopy.starts_before = [moment().add(value, units).format(API_DATE_FORMAT)];

  return filtersCopy;
}

export class Poster extends React.Component {
  constructor(props) {
    super(props);
    this.numberOfDays = MIN_NUMBER_OF_DAYS;
    this.state = {
      posterToken: window.POSTER_TOKEN,
      displayUrl: window.DISPLAY_URL,
      index: 0,
    };
  }

  componentDidMount() {
    const { fetchCurrentUser } = this.props;

    this.reloadEvents();
    fetchCurrentUser();

    setInterval(() => {
      this.setState((state) => ({
        index: state.index + 1,
      }));
    }, EVENT_CARROUSEL_REFRESH);

    setInterval(() => {
      this.reloadEvents();
    }, EVENT_LIST_REFRESH);
  }

  componentDidUpdate = (prevProps) => {
    const { eventListStatus, eventList } = this.props;

    if (prevProps.eventListStatus === STATUS_LOADING && eventListStatus === STATUS_DONE) {
      if (this.numberOfDays < MAX_NUMBER_OF_DAYS && eventList.length < 3) {
        this.numberOfDays += 1;
        this.reloadEvents();
      } else if (
        this.numberOfDays > MIN_NUMBER_OF_DAYS &&
        prevProps.eventList.length > 3 &&
        eventList.length > 3
      ) {
        this.numberOfDays -= 1;
        this.reloadEvents();
      }
    }
  };

  getBottomUrl() {
    const { displayUrl } = this.state;
    return displayUrl || getUrlParser(window.location.href).hostname;
  }

  reloadEvents() {
    const { fetchEventList } = this.props;
    const { posterToken } = this.state;

    fetchEventList(setFilterDateRange({ postertoken: posterToken }, 'days', this.numberOfDays));
  }

  render() {
    const { eventList } = this.props;
    const { index } = this.state;

    if (!eventList) {
      return <div />;
    }

    const numberOfEvents = eventList.length;
    let firstIndex = index;

    if (numberOfEvents <= 3) {
      firstIndex = 0;
    }

    return (
      <React.Fragment>
        <div key="1" className="poster">
          {numberOfEvents > 0 && (
            <div className="card-column">
              <PosterCard event={eventList[firstIndex % eventList.length]} />
            </div>
          )}
          {numberOfEvents > 1 && (
            <div className="card-column">
              <PosterCard event={eventList[(firstIndex + 1) % eventList.length]} />
            </div>
          )}
          {numberOfEvents > 2 && (
            <div className="card-column">
              <PosterCard event={eventList[(firstIndex + 2) % eventList.length]} />
            </div>
          )}
        </div>
        <a key="2" className="poster-bottom-url" href={`https://${this.getBottomUrl()}`}>
          {this.getBottomUrl()}
        </a>
      </React.Fragment>
    );
  }
}

Poster.propTypes = {
  eventList: PropTypes.array,
  eventListStatus: PropTypes.string,
  fetchEventList: PropTypes.func,
  fetchCurrentUser: PropTypes.func,
};

Poster.defaultProps = {
  eventList: [],
};

const mapStateToProps = (state) => {
  const requestState = getDataFromState('profilePresentingEvents', state, [eventSchema]);

  return {
    eventList: requestState.data,
    eventListStatus: requestState.status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchEventList: (selectedFilters) =>
    dispatch(
      actions.event.retrieveList('profilePresentingEvents', { ...selectedFilters, page_size: 9 })
    ),
  fetchCurrentUser: () => {
    dispatch(userActions.currentUserRequestSubmit());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Poster);
