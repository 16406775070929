import {
  displayDatetime,
  formatDatetimeRangeToDisplay,
  displayDatetimeRange,
  displayDate,
  displayTime,
  displayTimeRange,
  formatMonthDate,
  formatTimeslot,
} from '~/services/datetime';

const Datetime = (props) => {
  const { datetime, timezone, displayTimezone, isOnline, includeWeekDay, includeYear } = props;
  return displayDatetime(datetime, timezone, displayTimezone, isOnline, {
    includeWeekDay,
    includeYear,
  });
};

const DateComponent = (props) => {
  const { datetime, timezone, displayTimezone, isOnline, includeWeekDay, includeYear } = props;

  return displayDate(datetime, timezone, displayTimezone, isOnline, {
    includeWeekDay,
    includeYear,
  });
};

const MonthDateComponent = (props) => {
  const { datetime } = props;

  return formatMonthDate(datetime);
};

const TimeslotComponent = (props) => {
  const { startsAt, endsAt } = props;

  return formatTimeslot(startsAt, endsAt);
};

const TimeComponent = (props) => {
  const { datetime, timezone, displayTimezone, isOnline } = props;

  return displayTime(datetime, timezone, displayTimezone, isOnline);
};

const Range = (props) => {
  const { children, start, end, timezone, displayTimezone, includeWeekDay, isOnline } = props;

  if (children) {
    return formatDatetimeRangeToDisplay(
      start,
      end,
      timezone,
      displayTimezone,
      includeWeekDay,
      isOnline,
      children
    );
  }

  return displayDatetimeRange(start, end, timezone, displayTimezone, includeWeekDay, isOnline);
};

const TimeRange = (props) => {
  const { start, end, timezone, displayTimezone, isOnline } = props;

  return displayTimeRange(start, end, timezone, displayTimezone, isOnline);
};

Datetime.Date = DateComponent;
Datetime.Time = TimeComponent;
Datetime.Range = Range;
Datetime.TimeRange = TimeRange;
Datetime.MonthDate = MonthDateComponent;
Datetime.Timeslot = TimeslotComponent;

export default Datetime;
