import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Fields, reduxForm } from 'redux-form';
import styled from 'styled-components';

import actions from '~/app/event-shared/actions';
import EmailTagsInput from '~/app/inputs/components/EmailTagsInput';
import InputLabel from '~/app/inputs/components/InputLabel';
import PeopleGroupsMultiselectField from '~/app/inputs/components/PeopleGroupsMultiselectField';
import TextField from '~/app/inputs/components/TextField';
import { DisplayFormErrors } from '~/app/shared';
import Button from '~/app/shared/components/Button';
import Form from '~/app/shared/components/OldForm';
import Text from '~/app/shared/components/Text';
import * as formValidations from '~/app/shared/formValidations';
import { get, map } from 'lodash-es';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

const ONE_USER_REQUIRED_MESSAGE = 'You must select at least one user to invite.';

const SubmitArea = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

const TipText = styled(Text)`
  margin-top: 0;
  margin-bottom: 20px;
`;

export const InviteUsersForm = (props) => {
  const { error, handleSubmit, submitting, initialValues } = props;
  const [currentText, setCurrentText] = useState(get(initialValues, 'invite_message', ''));

  return (
    <StyledForm onSubmit={handleSubmit}>
      <DisplayFormErrors errors={error} />
      <Field component="input" type="hidden" name="public_id" />
      <React.Fragment>
        <Fields
          label="Invite people or groups"
          names={['groups_ids', 'people_ids']}
          component={PeopleGroupsMultiselectField}
          showGroups
          showUsers
          allowSelectEmails
        />
        <InputLabel>Invite by email</InputLabel>
        <Fields
          names={['emails']}
          component={EmailTagsInput}
          placeholder="You can paste multiple emails"
          tagLimit={50}
        />
        <TipText size="h6" block>
          (Tip: you can add a comma-separated list of emails to invite in bulk.)
        </TipText>
      </React.Fragment>
      <div>
        <Field
          name="invite_message"
          label="Message"
          component={TextField}
          multiline
          rows={4}
          inputProps={{
            maxLength: 140,
            onChange: (event) => {
              setCurrentText(event.target.value);
            },
          }}
          helperText={`${currentText.length}/140 characters`}
        />
      </div>
      <SubmitArea>
        <Button type="submit" disabled={submitting} size="large">
          Send Invites
        </Button>
      </SubmitArea>
    </StyledForm>
  );
};

InviteUsersForm.propTypes = {
  handleSubmit: PropTypes.func,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
};

const transform = (values) => {
  const emails = map(values.emails, ({ email }) => email);

  return {
    public_id: values.public_id,
    users_to_invite: values.people_ids,
    groups_to_invite: values.groups_ids,
    users_emails_to_invite: emails,
    invite_message: values.invite_message,
  };
};

export default reduxForm({
  form: 'user-invite',
  onSubmit: onSubmitActions(actions.inviteToEventForm.toString(), transform),
  validate: formValidations.createValidator({
    invited_users: [
      formValidations.required({ message: ONE_USER_REQUIRED_MESSAGE }),
      formValidations.minLength(1, { message: ONE_USER_REQUIRED_MESSAGE }),
    ],
  }),
})(InviteUsersForm);
