import PropTypes from 'prop-types';
import React from 'react';

import { LEARNING_TYPES } from '~/app/catalog/constants';
import { useLearningTypeLabels } from '~/app/catalog/hooks';
import Text from '~/app/shared/components/Text';
import { toLower } from 'lodash-es';

import BaseTaskConverterModal from './BaseTaskConverterModal';

const ConvertToTaskModal = ({ content, handleClose }) => {
  const learningTypeLabels = useLearningTypeLabels();
  const labelTask = learningTypeLabels[LEARNING_TYPES.tasks];
  const labelArticle = learningTypeLabels[LEARNING_TYPES.articles];

  return (
    <BaseTaskConverterModal
      content={content}
      handleClose={handleClose}
      title={`Convert to ${labelTask}`}
      modalBodyContent={
        <>
          <Text block>
            You&#39;re about to convert &quot;{content.name}&quot; from an {toLower(labelArticle)}{' '}
            to a {toLower(labelTask)}.
          </Text>
          <Text block>
            {labelTask} can only be accessed from within tracks and are not listed on the catalog.
          </Text>
          <Text block>
            A {toLower(labelTask)} can be converted back into an {toLower(labelArticle)}.
          </Text>
        </>
      }
    />
  );
};

ConvertToTaskModal.propTypes = {
  content: PropTypes.object,
  handleClose: PropTypes.func,
};

export default ConvertToTaskModal;
