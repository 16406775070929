import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { map, includes } from 'lodash-es';
import { ButtonGroup } from '@mui/material';
import SegmentedControlsItem from './SegmentedControlsItem';

const SegmentedControls = ({ items, disabled, value, onChange, fullWidth, sx }) => {
  const isSelected = (item) => {
    return includes(value, item.value);
  };

  const isTablet = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <ButtonGroup color="primary" fullWidth={fullWidth} sx={sx}>
      {map(items, (item) => {
        const ItemComponent = (
          <SegmentedControlsItem
            key={item.value}
            item={item}
            onClick={onChange}
            isSelected={isSelected(item)}
            disabled={disabled}
          />
        );

        return item.hideOnMobile ? isTablet && ItemComponent : ItemComponent;
      })}
    </ButtonGroup>
  );
};

SegmentedControls.propTypes = {
  fullWidth: false,
};

SegmentedControls.propTypes = {
  items: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  sx: PropTypes.object,
};

export default SegmentedControls;
