import PropTypes from 'prop-types';
import React from 'react';

import { map, reject, get } from 'lodash-es';

import FilterBarHeader from './FilterBarHeader';
import FilterBarSearchWidget from './FilterBarSearchWidget';
import FilterBarWidget from './FilterBarWidget';

const FilterBar = ({ widgets, onChange, selecteds, closeSidebar, showSearchWidget, param }) => {
  const allowedWidgets = reject(widgets, (w) => get(w, 'hidesWhen', false) || w === null);

  return (
    <>
      <FilterBarHeader closeSidebar={closeSidebar} />
      {showSearchWidget && (
        <FilterBarSearchWidget selecteds={selecteds} onChange={onChange} param={param} />
      )}

      {map(allowedWidgets, (widget, i) => (
        <FilterBarWidget title={widget.title} key={i} icon={widget.icon} isOpen={widget?.isOpen}>
          {React.cloneElement(widget.component, { selecteds, onChange })}
        </FilterBarWidget>
      ))}
    </>
  );
};

FilterBar.defaultProps = {
  showSearchWidget: true,
};

FilterBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  selecteds: PropTypes.object,
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      hidesWhen: PropTypes.bool,
      component: PropTypes.node,
    })
  ),
  closeSidebar: PropTypes.func,
  showSearchWidget: PropTypes.bool,
  param: PropTypes.string,
};

export default FilterBar;
