import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { ApiURLs, fetchURL } from '~/services/requests-base';

export type SurveyRelationshipEmailsStatus = 'answered' | 'unanswered';

async function fetchSurveyRelationshipEmails(id: number, status: SurveyRelationshipEmailsStatus) {
  const base = ApiURLs['api_surveys:survey_relationship_emails'](id);
  const url = `${base}?status=${status}`;

  const { data } = await fetchURL(url);
  return data;
}

export const surveys = createQueryKeys('surveys', {
  surveyRelationshipEmails: (id: number, status: SurveyRelationshipEmailsStatus) => ({
    queryKey: [id, status],
    queryFn: () => fetchSurveyRelationshipEmails(id, status),
  }),
});

export type SurveysQueryKeys = inferQueryKeys<typeof surveys>;
