import type { ValueOf } from 'type-fest';

import { LEARNING_TYPES, LEARNING_TYPE_TO_CONTENT_TYPE_MAPPING } from '~/app/catalog/constants';
import { getDefaultCatalogFiltersQuerystring } from '~/app/catalog/utils';
import { useFetchDataList } from '~/app/data-fetching/hooks';
import { queries } from '~/queries';
import { mapRoute } from '~/services/requests';
import { OptimalContentItemCard } from '~/app/shared/components/Card';
import HorizontalCardList from '~/app/shared/components/HorizontalCardList';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { filter, isEmpty, join, map } from 'lodash-es';

interface Props {
  sectionTitle?: string;
  learningTypes?: ValueOf<typeof LEARNING_TYPES>[];
  pageSize?: number;
  status?: 'published' | 'archived';
}

/**
 * Renders a horizontal list of cards from the specified learning types.
 *
 * This list is responsive, showing more or less elements depending on the viewport width.
 *
 * @param sectionTitle Title rendered above the list
 * @param learningTypes List of learning types to be included in the request.
 *   An empty list means that all types will be included.
 * @param pageSize Max amount of items to be retrieved/shown in the list.
 *   Depending on the viewport width, not all items may be rendered
 */
const ContentItemHorizontalCardList = ({
  sectionTitle,
  learningTypes = [],
  pageSize = 6,
  status = 'published',
}: Props) => {
  const {
    data,
    count,
    status: requestStatus,
  } = useFetchDataList({
    ...queries.content_items.list(
      getDefaultCatalogFiltersQuerystring({
        pageSize,
        status,
        selectedContentTypes: map(
          learningTypes,
          (learningType) => LEARNING_TYPE_TO_CONTENT_TYPE_MAPPING[learningType]
        ),
      })
    ),
  });

  if (requestStatus === STATUS_LOADING) return <Loading />;

  if (requestStatus !== STATUS_DONE) return null;

  const viewAllUrlParams = join(
    filter(
      [
        'o=relevance',
        'status=published',
        `${join(
          map(learningTypes, (learningType) => `learning_types=${learningType}`),
          '&'
        )}`,
      ],
      (entry) => !isEmpty(entry)
    ),
    '&'
  );

  return (
    <HorizontalCardList
      sectionTitle={sectionTitle}
      items={data}
      renderItem={(item) => (
        <OptimalContentItemCard key={`${item.content_type}_${item.id}`} contentItem={item} />
      )}
      totalItemsCount={count}
      viewAllLabel={`View All ${count > 1 && count}`}
      viewAllUrl={`${mapRoute('unifiedCatalogList')}?${viewAllUrlParams}`}
    />
  );
};

export default ContentItemHorizontalCardList;
