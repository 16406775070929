import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from '~/services/colors';
import Clicker from '~/app/shared/components/Clicker';
import Icon from '~/app/shared/components/Icon';
import { omit, pick, keys } from 'lodash-es';

// TODO: Add remaining VARIANT_MAPPING for icon when there are specs of it

// TODO: Add remaining VARIANT_MAPPING for icon when there are specs of it
const VARIANT_MAPPING = {
  primary: `
      color: ${colors.action700};

      svg path {
        fill: ${colors.action700};
      }

      &:hover, &:focus {
        color: ${colors.action800};
        text-decoration: underline;

        svg path {
          fill: ${colors.action800};
        }
      }
    `,
  success: `
    color: ${colors.success600};

    svg path {
        fill: ${colors.success600};
      }

    &:hover, &:focus {
      text-decoration: underline;
    }
  `,
  error: `
      color: ${colors.error600};

      svg path {
        fill: ${colors.error600};
      }

      &:hover, &:focus {
        text-decoration: underline;
        color: ${colors.error700};

        svg path {
          fill: ${colors.error700};
        }
      }
    `,
  dark: `
    color: ${colors.neutral900};

    svg path {
        fill: ${colors.neutral900};
      }

    &:hover, &:focus {
      text-decoration: none;
    }
  `,
  neutral: `
    color: ${colors.neutral600};

    svg path {
      fill: ${colors.neutral600};
    }

    &:hover, &:focus {
      color: ${colors.action600};

      svg path {
        fill: ${colors.action600};
      }
    }
  `,
  disabled: `
    color: ${colors.neutral400};

    svg path {
      fill: ${colors.neutral400};
    }
  `,
};

// TODO: Add remaining SIZE_MAPPING for icon when there are specs of it
const SIZE_MAPPING = {
  large: `
    font-size: 16px;
  `,
  medium: `
    font-size: 14px;
    font-weight: bold;

    svg {
      width: 12px;
    }
  `,
  small: `
    font-size: 12px;
    font-weight: 500;
  `,
};

const ButtonLinkWrapper = ({ route, url, disabled, ...rest }) => {
  const anchorProps = pick(rest, ['children', 'className', 'alt', 'target', 'aria-label']);
  if (route) {
    return <Link {...(!disabled && { to: route })} {...anchorProps} />;
  }
  if (url) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={disabled ? null : url} {...anchorProps} />;
  }

  const buttonProps = omit(rest, ['variant']);
  return <Clicker disabled={disabled} {...buttonProps} />;
};

ButtonLinkWrapper.propTypes = {
  route: PropTypes.string,
  url: PropTypes.string,
  disabled: PropTypes.bool,
};

const StyledButtonLink = styled(ButtonLinkWrapper)`
  && {
    display: ${({ display }) => display || 'inline'};
    ${({ disabled, variant }) =>
      disabled ? VARIANT_MAPPING['disabled'] : VARIANT_MAPPING[variant]};
    ${({ size }) => SIZE_MAPPING[size]};
    ${({ bold }) => bold && `font-weight: 500`};

    ${({ iconPlacement }) =>
      iconPlacement === 'left' &&
      `
      ${Icon} {
        margin-right: 4px;
      }
    `};
    ${({ iconPlacement }) =>
      iconPlacement === 'right' &&
      `
      ${Icon} {
        margin-left: 4px;
      }
    `};
  }
`;

const ButtonLink = ({ ...props }) => {
  const { variant, children, route, icon, iconPlacement, ...remainingProps } = props;
  return (
    <StyledButtonLink
      variant={variant}
      route={route}
      iconPlacement={iconPlacement}
      {...remainingProps}
    >
      {icon && iconPlacement === 'left' && <Icon name={icon} noTransition width={12} height={12} />}
      {children}
      {icon && iconPlacement === 'right' && (
        <Icon name={icon} noTransition width={12} height={12} />
      )}
    </StyledButtonLink>
  );
};

ButtonLink.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.string,
  route: PropTypes.string,
  size: PropTypes.oneOf(keys(SIZE_MAPPING)),
  icon: PropTypes.string,
  iconPlacement: PropTypes.oneOf(['left', 'right']),
};

ButtonLink.defaultProps = {
  variant: 'primary',
  size: 'medium',
  iconPlacement: 'left',
};

export default ButtonLink;
