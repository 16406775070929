import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import UserProfileInitials from '~/app/shared/components/UserAvatar/UserProfileInitials';
import { mapRoute } from '~/services/requests';
import Pill from '~/app/shared/components/Pill';
import { UserPopover } from '~/app/shared/components/UserAvatar';

const Container = styled.div`
  margin-top: 15px;
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 8px;
`;

const Pills = ({
  userId,
  location,
  location_text,
  workingSince,
  timezone,
  manager,
  directs,
  teammates,
}) => {
  let formattedDate = null;

  if (workingSince) {
    if (timezone) {
      formattedDate = moment.tz(workingSince, 'UTC').tz(timezone, true).format('MMMM YYYY');
    } else {
      formattedDate = moment(workingSince).format('MMMM YYYY');
    }
  }

  const peopleList = `${mapRoute('peopleList')}?o=name`;

  return (
    <Container>
      {location && (
        <Link to={`${peopleList}&location=${location.id}`} aria-label="See people at this location">
          <Pill icon="location" label={location.display_name} />
        </Link>
      )}
      {!location && location_text && (
        <Link
          to={`${peopleList}&location_text=${location_text}`}
          aria-label="See people at this location"
        >
          <Pill icon="location" label={location_text} />
        </Link>
      )}
      {formattedDate && <Pill icon="calendar" label={`Working since ${formattedDate}`} />}
      {manager && (
        <UserPopover
          user={manager}
          renderPopoverHoverable={
            <Link to={mapRoute('userProfile', { id: manager.id })} aria-label="View profile">
              <Pill
                imagePlaceHolder={<UserProfileInitials userName={manager.display_name} />}
                imageSrc={manager.profile_image}
                label={`${manager.display_name} (Manager)`}
                showLabelOnTooltip={false}
              />
            </Link>
          }
        />
      )}
      {directs > 0 && (
        <Link to={`${peopleList}&manager=${userId}`} aria-label="View directs">
          <Pill icon="persons" label={`${directs} direct${directs > 1 ? 's' : ''}`} />{' '}
        </Link>
      )}
      {teammates > 0 && (
        <Link to={`${peopleList}&teammate=${userId}`} aria-label="View teammates">
          <Pill icon="persons" label={`${teammates} teammate${teammates > 1 ? 's' : ''}`} />
        </Link>
      )}
    </Container>
  );
};

Pills.propTypes = {
  userId: PropTypes.number,
  location: PropTypes.object,
  location_text: PropTypes.string,
  workingSince: PropTypes.string,
  timezone: PropTypes.string,
  manager: PropTypes.object,
  directs: PropTypes.number,
  teammates: PropTypes.number,
};

export default Pills;
