import moment from 'moment';
import { useState } from 'react';

import {
  INTERVAL_PAGINATION_SETTINGS,
  MONTH_FORMAT,
  QUARTER_FORMAT,
  YEAR_FORMAT,
} from '~/app/charts/constants';
import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import { API_DATETIME_FORMAT, STATUS_DONE } from '~/app/shared/constants';
import {
  range,
  map,
  max,
  findIndex,
  isEmpty,
  noop,
  get,
  join,
  size,
  constant,
  concat,
} from 'lodash-es';

const PAGE_SIZE = 50;

export const chartAttendanceTypeOptions = [
  {
    name: 'In Person',
    value: 'local',
  },
  {
    name: 'Online',
    value: 'online',
  },
];

export const chartVisibilityOptions = [
  {
    name: 'Hidden',
    value: 'hidden',
  },
  {
    name: 'Not Hidden',
    value: 'not_hidden',
  },
];

export const enrollmentPreStatusOptions = [
  { name: 'Going', value: 'going' },
  { name: 'Going Online', value: 'going_online' },
];

export const enrollmentPostStatusOptions = [
  { name: 'Attended', value: 'attended' },
  { name: 'Unanswered', value: 'unanswered' },
];

/* This function fill the date gaps in the chart data */
export const fillWithEmptyData = (startDate, endDate, data, interval, options) => {
  if (size(data) === 0) return [];

  const formatMap = options?.formatMap ?? {
    month: [MONTH_FORMAT, 1, 'month'],
    quarter: [QUARTER_FORMAT, 3, 'months'],
    year: [YEAR_FORMAT, 1, 'year'],
  };
  const startDateFormatted = startDate && moment.utc(startDate[0], API_DATETIME_FORMAT);

  const format = formatMap[interval];
  const current = startDateFormatted ?? moment.utc(data[0][0], API_DATETIME_FORMAT);

  const fillWithEmpty = (newData, currentIndex, currentDate) => {
    if (currentIndex === size(data)) return newData;
    const [intervalStartDatetime, ...dataArray] = data[currentIndex];
    const zeroArray = map(range(size(dataArray)), constant(0));
    if (
      moment.utc(intervalStartDatetime, API_DATETIME_FORMAT).format(format[0]) ===
      currentDate.format(format[0])
    ) {
      const value = [[currentDate.format(format[0]), ...dataArray]];
      const nextDate = currentDate.add(format[1], format[2]);
      return fillWithEmpty(concat(newData, value), currentIndex + 1, nextDate);
    }
    const zero = [[currentDate.format(format[0]), ...zeroArray]];
    const nextDate = currentDate.add(format[1], format[2]);
    return fillWithEmpty(concat(newData, zero), currentIndex, nextDate);
  };
  return fillWithEmpty([], 0, current);
};

// Filter builders
export const buildTextFilter = (title, param) => ({
  type: 'TEXT',
  title,
  param,
});

export const buildListFilter = (
  title,
  param,
  options,
  fetchOptions = noop,
  { clearWhenSelectingAll = true } = {}
) => ({
  type: 'LIST',
  title,
  param,
  options,
  fetchOptions,
  clearWhenSelectingAll,
});

export const buildDateFilter = (title, param) => ({
  type: 'DATE',
  title,
  startParam: `${param}_after`,
  endParam: `${param}_before`,
});

// Hooks
const useChartFilterOptions = ({ action, getExtraFetchParams = noop, optionsFormatter = null }) => {
  const [data, setData] = useState([]);

  const [fetch] = useEntities(action, ({ status, data: { results, count } }) => {
    if (status === STATUS_DONE) {
      const resultsSize = size(results);
      let options = optionsFormatter ? optionsFormatter(results) : results;

      if (count > resultsSize) {
        options = [
          ...options,
          {
            name: `and ${count - resultsSize} more`,
            value: '',
            isDisabled: true,
          },
        ];
      }

      setData(options);
    }
  });

  const fetchData = (queryParams = {}) => {
    const includedIds = get(queryParams, 'include_ids', []);
    const q = get(queryParams, 'q', '');

    fetch(
      {
        q,
        include_ids: join(includedIds, ','),
        page_size: PAGE_SIZE + size(includedIds),
        view_mode: 'filter_options',
        o: 'name',
        ...getExtraFetchParams(includedIds),
      },
      { skipSchema: true }
    );
  };

  return [data, fetchData];
};

export const useChartFilterLocationOptions = () =>
  useChartFilterOptions({ action: actions.location.retrieveList });

export const useChartFilterGroupOptions = () =>
  useChartFilterOptions({ action: actions.groups.retrieveList });

export const useChartFilterEventTypeOptions = () =>
  useChartFilterOptions({ action: actions.eventType.retrieveList });

export const useChartFilterUserOptions = () =>
  useChartFilterOptions({
    action: actions.userData.retrieveList,
    optionsFormatter: (options) =>
      map(options, (option) => ({ name: option.display_name, value: option.value })),
  });

export const useChartFilterProgramOptions = () =>
  useChartFilterOptions({
    action: actions.program.retrieveList,
    getExtraFetchParams: (includedIds) => ({
      include_ids: undefined,
      include_public_ids: join(includedIds, ','),
    }),
  });

export const getHighestIntervalValue = (intervalData) => {
  if (isEmpty(intervalData)) return 0;
  // Get the highest value across all intervals data

  // eslint-disable-next-line no-unused-vars
  const highestsFromIntervals = map(intervalData, ([_, ...intervalValues]) => max(intervalValues));
  return max(highestsFromIntervals);
};

export const getCurrentDatePageOffset = (intervalData, interval) => {
  if (isEmpty(intervalData) || !interval) {
    return 0;
  }

  const now = moment.utc().toDate();

  // Find the index of the current month, quarter or year
  let currentDateIndex = findIndex(
    intervalData,
    (data) =>
      data[0] ===
      moment.utc(now, API_DATETIME_FORMAT).format(INTERVAL_PAGINATION_SETTINGS[interval].format)
  );

  // If current date not present, assume the last date is the current one
  if (currentDateIndex === -1) {
    currentDateIndex = intervalData.length - 1;
  }

  // The "- 1" is to consider the dataset starting from 0
  const pageSize = INTERVAL_PAGINATION_SETTINGS[interval].pageSize - 1;

  // This handles the case where the current date index does not fit in the right most column
  return Math.max(currentDateIndex - pageSize, 0);
};

export const getLastPageItemIndex = (intervalData, interval, currentPage) => {
  const { pageSize } = INTERVAL_PAGINATION_SETTINGS[interval];

  if (!intervalData || !interval) {
    return currentPage;
  }

  return Math.min(currentPage + pageSize, intervalData.length);
};

export const getLastPageLabelItemIndex = (data, currentPage, pageSize = 6) => {
  if (!data) return currentPage;
  return Math.min(currentPage + pageSize, data.length);
};

export const sortBy = (array, index, order) => {
  const sortIndex = Number(index);
  if (order) {
    return array.sort((a, b) => b[sortIndex] - a[sortIndex]);
  }
  return array.sort((a, b) => a[sortIndex] - b[sortIndex]);
};
