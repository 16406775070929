import { useEffect } from 'react';

import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { PersistentlyDismissableInfoBox } from '~/app/shared/components/PersistentlyDismissableInfoBox';
import Text from '~/app/shared/components/Text';
import { useCurrentUser } from '~/app/shared/hooks';
import { Grid, Paper, Stack } from '@mui/material';
import { ConsumedContentWidget } from './EngagementWidgets/ConsumedContentWidget/ConsumedContentWidget';
import { ContentCompletionByTagsWidget } from './EngagementWidgets/ContentCompletionByTagsWidget';
import { RecentActivityWidget } from './EngagementWidgets/RecentActivityWidget';
import { UpcomingContentWidget } from './EngagementWidgets/UpcomingContentWidget';

const AlertMessage = () => (
  <>
    <Text size="h4" display="block" medium>
      New to Engagement Profile?
    </Text>
    <Text>
      <a
        href="https://help.plusplus.app/en/articles/6953462-how-to-manage-your-plusplus-profile#h_23fad401ee"
        target="_blank"
        style={{ textDecoration: 'underline' }}
        rel="noreferrer"
      >
        Learn more about this page
      </a>
      .
    </Text>
  </>
);

export const Engagement = () => {
  const { trackActivity } = useMetrics();

  const { id: currentUserId } = useCurrentUser();

  useEffect(() => {
    trackActivity(METRICS_ACTIVITIES.ENGAGEMENT_VIEW, {});
  }, []);

  return (
    <>
      <Grid item xs={4} sm={8} md={12}>
        <PersistentlyDismissableInfoBox
          localStorageKey={`closedUserEngagementInfoTip-${currentUserId}`}
          content={<AlertMessage />}
          actionText="Dismiss"
          type="success"
        />
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <Stack spacing={4} direction="column">
          <Paper elevation={2}>
            <UpcomingContentWidget />
          </Paper>
          <Paper elevation={2}>
            <ContentCompletionByTagsWidget />
          </Paper>
          <Paper elevation={2}>
            <RecentActivityWidget />
          </Paper>
          <Paper elevation={2}>
            <ConsumedContentWidget />
          </Paper>
        </Stack>
      </Grid>
    </>
  );
};
