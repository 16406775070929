import { useQueryClient } from '@tanstack/react-query';

import { Assignment, ContentItem } from '~/app/shared-content-item/interfaces';
import { AssignmentActionsCtx } from '~/features/contentitems/contexts/AssignmentActionsCtx';
import {
  useContentItemAssignmentActions,
  AssignmentActionType,
} from '~/features/contentitems/hooks/useContentItemAssignmentActions';
import { queries } from '~/queries';

import { useTrackContentConsumptionCtx } from '../contexts/TrackContentConsumptionCtx';

type TrackItemAssignmentActionsProviderProps = {
  contentItem: ContentItem;
  assignment: Assignment;
  children: React.ReactNode;
};

export function TrackItemAssignmentActionsProvider(props: TrackItemAssignmentActionsProviderProps) {
  const { contentItem, assignment, children } = props;

  const { currentTrackNode } = useTrackContentConsumptionCtx();

  const queryClient = useQueryClient();

  const handleAssignmentActionSuccess = (action: AssignmentActionType) => {
    if (action === 'rate' || action === 'acknowledgeCompletion') {
      return;
    }

    const trackPublicId = currentTrackNode.track.public_id;
    queryClient.invalidateQueries({
      queryKey: queries.content_items.details(trackPublicId).queryKey,
    });
  };

  const assignmentActions = useContentItemAssignmentActions({
    contentItem,
    assignment,
    onSuccess: handleAssignmentActionSuccess,
  });

  return (
    <AssignmentActionsCtx.Provider value={assignmentActions}>
      {children}
    </AssignmentActionsCtx.Provider>
  );
}
