import React from 'react';

import { WidgetPermissionFunc, permissions } from '~/common/utils/widgetPermissions';
import {
  ContentIOwnWidget,
  AssignedContentWidget,
  MySavedContentWidget,
} from '~/features/contentitems/widgets';
import { MyUpcomingEventsWidget, EventsIOwnWidget } from '~/features/events/widgets';
import { MyTeamWidget, MyDirectsWidget, MyExpertsWidget } from '~/features/users/widgets';

type MyHubWidgetListItem = {
  name: string;
  component: React.ComponentType<any>;
  permissions?: WidgetPermissionFunc[];
};

export const allMyHubWidgets: MyHubWidgetListItem[] = [
  {
    name: 'my_upcoming_events',
    component: MyUpcomingEventsWidget,
  },
  {
    name: 'assigned_content',
    component: AssignedContentWidget,
  },
  {
    name: 'events_i_run',
    component: EventsIOwnWidget,
    permissions: [permissions.canCreateEvent],
  },
  {
    name: 'my_saved_content',
    component: MySavedContentWidget,
  },
  {
    name: 'content_i_own',
    component: ContentIOwnWidget,
    permissions: [permissions.canCreateContent],
  },
  {
    name: 'my_team',
    component: MyTeamWidget,
    permissions: [permissions.hasTeam],
  },
  {
    name: 'my_directs',
    component: MyDirectsWidget,
    permissions: [permissions.hasDirects],
  },
  {
    name: 'my_experts',
    component: MyExpertsWidget,
  },
];
