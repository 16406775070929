import * as React from 'react';

import { useCurrentUser, useModuleToggles, useToggles } from '~/app/shared/hooks';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import * as permissionConstants from '~/app/shared/permissions';
import { includes, some } from 'lodash-es';

type ContentCreationCanPermissions = {
  canCreateEventType: boolean;
  canCreateProgram: boolean;
  canCreateMentorshipProgram: boolean;
  canCreateTrack: boolean;
  canCreateArticle: boolean;
  canCreateCodelab: boolean;
  canCreateCourse: boolean;
  canCreateVideo: boolean;
  canCreateAssessment: boolean;
  canCreateLinkedContent: boolean;
};

export function useContentCreationPermissions() {
  const {
    permissions,
    scorm_cloud_integration_toggle: toggleCourses,
    catalog_sections_order: catalogSectionsOrder,
  } = useCurrentUser();
  const { programs: togglePrograms, tracks: toggleSnippets } = useModuleToggles();
  const { toggle_mentorship_programs: toggleMentorshipPrograms } = useToggles();

  const canPermissions: ContentCreationCanPermissions = React.useMemo(() => {
    const canCreateEventType = includes(
      permissions,
      permissionConstants.CREATE_EVENT_TYPE_PERMISSION
    );
    const canCreateProgram =
      (togglePrograms || includes(catalogSectionsOrder, LEARNING_TYPES.programs)) &&
      includes(permissions, permissionConstants.CREATE_PROGRAM_PERMISSION);
    const canCreateMentorshipProgram =
      toggleMentorshipPrograms &&
      includes(permissions, permissionConstants.CREATE_PROGRAM_PERMISSION);
    const canCreateTrack =
      (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.tracks)) &&
      includes(permissions, permissionConstants.CREATE_TRACK_PERMISSION);
    const canCreateArticle =
      (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.articles)) &&
      includes(permissions, permissionConstants.CREATE_ARTICLE_PERMISSION);
    const canCreateCodelab =
      includes(catalogSectionsOrder, LEARNING_TYPES.codelabs) &&
      includes(permissions, permissionConstants.CREATE_CODELAB_PERMISSION);
    const canCreateCourse =
      toggleCourses &&
      includes(catalogSectionsOrder, LEARNING_TYPES.courses) &&
      includes(permissions, permissionConstants.CREATE_COURSE_PERMISSION);
    const canCreateVideo =
      (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.videos)) &&
      includes(permissions, permissionConstants.CREATE_VIDEO_PERMISSION);
    const canCreateAssessment =
      includes(catalogSectionsOrder, LEARNING_TYPES.assessments) &&
      includes(permissions, permissionConstants.CREATE_ASSESSMENT_PERMISSION);
    const canCreateLinkedContent =
      includes(catalogSectionsOrder, LEARNING_TYPES.linkedcontent) &&
      includes(permissions, permissionConstants.CREATE_LINKEDCONTENT_PERMISSION);

    return {
      canCreateEventType,
      canCreateProgram,
      canCreateMentorshipProgram,
      canCreateTrack,
      canCreateArticle,
      canCreateCodelab,
      canCreateCourse,
      canCreateVideo,
      canCreateAssessment,
      canCreateLinkedContent,
    };
  }, [
    permissions,
    toggleCourses,
    togglePrograms,
    toggleSnippets,
    toggleMentorshipPrograms,
    catalogSectionsOrder,
  ]);

  const canCreateSomeContent = some(canPermissions);

  return {
    permissions: canPermissions,
    canCreateAny: canCreateSomeContent,
  };
}
