import {
  isEmpty,
  isNil,
  filter,
  head,
  keys,
  last,
  map,
  size,
  split,
  trim,
  isArray,
} from 'lodash-es';

// Trim split values in cases when user pastes values separated by comma and space
// a,b,c => ['a', 'b', 'c']

// Trim split values in cases when user pastes values separated by comma and space
// a,b,c => ['a', 'b', 'c']
export const getInOutValue = (value) =>
  filter(
    map(split(value, ','), (v) => trim(v)),
    (v) => !isEmpty(v)
  );

// {$in: [1, 2] } => [1, 2]
export const rqlToInput = (filter) => {
  if (isNil(filter)) return [];

  // List selectors only supports 3 operators: eq, in or out
  const op = head(keys(filter));
  if (op === '$eq') return isEmpty(filter[op]) ? [] : [filter[op]];
  return filter[op] || [];
};

// [1, 2] => {$in: [1, 2] }
export const inputToRQL = (items) => {
  if (size(items) === 0) return null;
  if (size(items) === 1) return { $eq: items[0] };
  return { $in: items };
};

// [2] => {$eq: 2}
// 'value' => {$eq: 'value'}
export const inputToRQLSingle = (items) => {
  if (size(items) === 0) return null;
  if (isArray(items)) return { $eq: last(items) };
  return { $eq: items };
};
