import { COURSE_REGISTRATION_RESULTS } from '~/app/course/interfaces';
import { VerifiedOutlinedIcon, WarningAmberOutlinedIcon } from '~/vendor/mui-icons';

// COURSE STATUSES

// COURSE STATUSES
export const COURSE_STATUS_ERROR = 'error';
export const COURSE_STATUS_PENDING = 'pending';
export const COURSE_STATUS_DRAFT = 'draft';
export const COURSE_STATUS_PUBLISHED = 'published';

// REGISTRATION STATUSES
export const UNREGISTERED_STATUS = 'UNREGISTERED_STATUS';
export const REGISTERED_STATUS = 'REGISTERED_STATUS';

export const COURSE_REGISTRATION_RESULTS_LABELS = {
  [COURSE_REGISTRATION_RESULTS.unknown]: 'Unknown',
  [COURSE_REGISTRATION_RESULTS.failed]: 'Failed',
  [COURSE_REGISTRATION_RESULTS.passed]: 'Passed',
};

export const COURSE_REGISTRATION_RESULTS_ICONS = {
  [COURSE_REGISTRATION_RESULTS.failed]: WarningAmberOutlinedIcon,
  [COURSE_REGISTRATION_RESULTS.passed]: VerifiedOutlinedIcon,
};
