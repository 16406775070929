import { useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import TextInput from '~/app/inputs/components/TextInput';
import { toast } from '~/app/notifications/components/NotificationCenter';
import {
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE,
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR,
} from '~/app/program/constants';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import { FormFieldGroup } from '~/app/shared/components/Form';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import { User } from '~/app/shared/components/types';
import UserAvatar from '~/app/shared/components/UserAvatar';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from '~/app/shared/constants';
import { Typography } from '~/common/components/Typography';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const asyncValidate = (values, _, props) => {
  const url = ApiURLs['api_users:user']({ pk: values.suggested_id });
  return fetchURL(url).then(({ data: user }) => {
    const { suggestionKind } = props;
    const errorMessage = 'The selected person is not open for mentorship.';

    if (suggestionKind === MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR && !user.has_mentor_profile) {
      // eslint-disable-next-line no-throw-literal
      throw { suggested_id: errorMessage };
    }

    if (suggestionKind === MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE && !user.has_mentee_profile) {
      // eslint-disable-next-line no-throw-literal
      throw { suggested_id: errorMessage };
    }

    return {};
  });
};

interface StateProps {
  suggestedId: number;
}

interface ConfirmationStepModalProps {
  dialogTitle?: string;
  receiver: User;
  suggestedUser: User;
  suggestionKind: string;
  invalid: boolean;
  handleBack?: () => void;
  handleClose: () => void;
  onRequestSent: () => void;
  asyncValidating: boolean;
  isManagerSuggestion: boolean;
}

const ConfirmationStepModal = ({
  dialogTitle,
  receiver,
  suggestedUser,
  suggestionKind,
  onRequestSent,
  invalid,
  asyncValidating,
  handleBack,
  handleClose,
  isManagerSuggestion = false,
  suggestedId,
}: ConfirmationStepModalProps & StateProps) => {
  const mentorOrMentee =
    suggestionKind === MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE ? 'mentee' : 'mentor';

  const [note, setNote] = useState('');
  const [notifySuggested, setNotifySuggested] = useState(false);
  const { trackActivity } = useMetrics();

  const [requestMentorship, { status: sendMentorshipSuggestionStatus }] = useEntities(
    actions.mentorshipRequest.create,
    ({ status, error }) => {
      if (status === STATUS_DONE) {
        const trackData = {
          receiver: receiver.id,
          suggested: suggestedId,
          kind: suggestionKind,
          messageLength: note ? note.length : 0,
        };

        trackActivity(METRICS_ACTIVITIES.MENTORSHIP_SUGGEST, trackData);

        if (isManagerSuggestion) {
          trackActivity(METRICS_ACTIVITIES.MANAGER_MENTORSHIP_SUGGEST, trackData);
        }

        toast.success('Mentorship suggestion sent!', 'User will be notified of your suggestion.');
        onRequestSent();
      }

      if (status === STATUS_ERROR) {
        let errorMessage;
        if (error?.non_field_errors?.length > 0) {
          [errorMessage] = error.non_field_errors;
        }
        toast.error('Request failed!', errorMessage);
      }
    }
  );

  const handleSendSuggestion = () => {
    if (!suggestedId) {
      return;
    }

    requestMentorship({
      note,
      suggested_id: suggestedId,
      receiver_id: receiver.id,
      kind: suggestionKind,
      is_suggested_notified: notifySuggested,
    });
  };

  return (
    <Modal
      title={dialogTitle || `Suggest a ${mentorOrMentee}`}
      handleClose={handleClose}
      width={500}
      handleBack={handleBack}
    >
      <ModalBody>
        <ModalContent>
          <Stack spacing={1} mb={1}>
            <Typography>
              You are suggesting a {mentorOrMentee} for {receiver.name}
            </Typography>
            <Stack spacing={1} direction="row">
              <UserAvatar user={suggestedUser} showPopover />
              <Typography>{suggestedUser.name}</Typography>
            </Stack>
          </Stack>
          <FormFieldGroup>
            <TextInput
              multiline
              label="Note (Optional)"
              rows={4}
              inputProps={{
                maxLength: 255,
                value: note,
                onChange: (event) => setNote(event.target.value),
              }}
              placeholder="Leave a note explaining the suggestion."
              helperText={`${note.length}/255 characters`}
            />
          </FormFieldGroup>
          <FormControlLabel
            control={<Checkbox onChange={() => setNotifySuggested(!notifySuggested)} />}
            label={<Typography>Also notify {suggestedUser.name}</Typography>}
          />
        </ModalContent>
      </ModalBody>
      <ModalFooter variant="buttons" justifyContent="flexEnd">
        <ModalFooterButton onClick={handleClose} color="error">
          Cancel
        </ModalFooterButton>
        <ModalFooterButton
          disabled={
            !suggestedId ||
            asyncValidating ||
            invalid ||
            sendMentorshipSuggestionStatus === STATUS_LOADING
          }
          onClick={handleSendSuggestion}
        >
          Send suggestion
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

const formSelector = formValueSelector('suggestMentorshipForm');

const mapStateToProps = (state): any => {
  const suggestedId = formSelector(state, 'suggested_id');

  return {
    suggestedId,
  };
};

const connector: any = connect<ConfirmationStepModalProps & StateProps>(mapStateToProps)(
  reduxForm({ form: 'suggestMentorshipForm', asyncValidate, asyncChangeFields: ['suggested_id'] })(
    ConfirmationStepModal
  )
);

export default connector;
