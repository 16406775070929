import { map, compact, head, forEach, isNil, keys } from 'lodash-es';

const isEmpty = (value) => isNil(value) || value === '';
const join = (rules) => (value, data, props) =>
  head(compact(map(rules, (rule) => rule(value, data, props))));

export function createValidator(rules) {
  return (data, props) => {
    const errors = {};
    forEach(keys(rules), (key) => {
      // concat enables both functions and arrays of functions
      const rule = join([rules[key]].flat());
      const error = rule(data[key], data, props);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

export function required(options = {}) {
  return (value) => {
    if (isEmpty(value)) {
      if (options.message) {
        return options.message;
      }
      return 'This field is required.';
    }
  };
}

export function minLength(min, options = {}) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      if (options.message) {
        return options.message;
      }
      return `Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }
  };
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }
}

export function match(field) {
  return (value, data) => {
    if (data && value !== data[field]) {
      return 'Do not match';
    }
  };
}

export const email = (email) => {
  const emailRegEx = /\S+@\S+\.\S+/;
  return emailRegEx.test(email);
};

export const url = (value) =>
  value && !/^(http:\/\/\.|https:\/\/\.|http:\/\/|https:\/\/)/gm.test(value)
    ? 'Invalid URL, please provide a URL starting with http:// or https://'
    : undefined;
