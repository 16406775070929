import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

import { Assessment } from '~/app/assessments/interfaces';
import Loading from '~/app/shared/components/Loading';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import { DEFAULT_DETAIL_PAGE_STALE_TIME } from '~/common/api/constants';
import { ComposableTrackProvider } from '~/features/tracks/contexts/ComposableTrackCtx';
import { queries } from '~/queries';
import Page404 from '~/scenes/404';
import TrackPageContainer from '~/scenes/TrackPage/TrackPageContainer';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { usePublicIdFromURL, useQueryParams } from '~/app/shared/hooks';

const AssessmentDetail = () => {
  const { publicId } = usePublicIdFromURL();
  const { trackActivity } = useMetrics();

  const { page: pageParam } = useQueryParams();
  const page = pageParam ? Number(pageParam) : 0;

  const { data: assessment, status } = useQuery<any, any, Assessment>({
    ...queries.content_items.details(publicId || '', 'detail'),
    staleTime: DEFAULT_DETAIL_PAGE_STALE_TIME,
  });

  React.useEffect(() => {
    if (status === 'success' && page === 0) {
      trackActivity(METRICS_ACTIVITIES.CONTENT_ITEM_VIEW, {
        contentItemId: assessment.id,
        contentItemType: assessment.content_type,
      });
    }
  }, [status, assessment, page]);

  if (status === 'error') {
    return <Page404 />;
  }

  if (status === 'loading') {
    return <Loading />;
  }

  return (
    <ComposableTrackProvider track={assessment}>
      <TrackPageContainer track={assessment} entityType={LEARNING_TYPES.assessments} />
    </ComposableTrackProvider>
  );
};

export default AssessmentDetail;
