import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';

import { LEARNING_TYPES } from '~/app/catalog/constants';
import { mapRoute } from '~/services/requests';
import { useLabels, useOriginRoute, useSetDetailedObject } from '~/app/shared/hooks';
import { toLower, get, isNil } from 'lodash-es';

import ScheduledTrackFormPage from './ScheduledTrackFormPage';

const ScheduledTrackCreate = () => {
  const history = useHistory();
  const { label_track: labelTrack, label_track_plural: labelTrackPlural } = useLabels();
  const preForm = get(history, 'location.state.scheduledTrack', null);
  // Use state here is necessary because the location state is cleared if the user clicks on a section in the sidebar
  const [track, setTrack] = useState<any>(null);
  const trackName = get(track, 'name', '');
  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.tracks
  }&o=upcoming`;
  const trackRoute = mapRoute('trackDetails', {
    public_id_and_slug: get(track, 'public_id_and_slug', '...'),
  });
  const backRoute = useOriginRoute(catalogRoute);

  const updateTrack = useCallback(() => {
    if (isNil(track) && !isNil(preForm)) {
      setTrack(get(preForm, 'track', ''));
    }
  }, [track, preForm, setTrack]);

  useSetDetailedObject({ id: null }, 'scheduled_tracks', []);

  useEffect(() => {
    updateTrack();
  }, [updateTrack]);

  return (
    <ScheduledTrackFormPage
      pageTitle={`Create new Scheduled ${labelTrack}`}
      topBarActionName={`Creating Scheduled ${labelTrack}`}
      backRoute={backRoute}
      breadcrumbsItemList={[
        {
          label: labelTrackPlural,
          link: catalogRoute,
        },
        {
          label: trackName,
          link: trackRoute,
        },
        {
          label: `New scheduled ${toLower(labelTrack)}`,
        },
      ]}
    />
  );
};

export default ScheduledTrackCreate;
