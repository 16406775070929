import { useState } from 'react';

import {
  CONTENT_TYPES,
  LEARNING_TYPE_ICONS_MAPPING,
  LEARNING_TYPES,
} from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import { ContentItemContextMenu } from '~/app/content-items/components/interfaces';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import DropDownMenu from '~/app/shared/components/DropDownMenu';
import { useLabels } from '~/app/shared/hooks';
import { CONTENT_ITEM_PERMISSIONS } from '~/app/shared/permissions';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import { ConvertToArticleModal } from '~/app/task/components/TaskConverterModal';
import { includes } from 'lodash-es';

const TaskContextMenu = ({ content, contextMenuExtraProps }: ContentItemContextMenu) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConvertToArticleModal, setShowConvertToArticleModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const { label_article: labelArticle } = useLabels();

  const { isTrackItem } = contextMenuExtraProps;
  const { public_id: publicId, public_id_and_slug: publicIdAndSlug, permissions } = content;

  const canManage = includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canAssign = includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);

  if (!canManage && !canAssign) {
    return null;
  }

  return (
    <>
      <DropDownMenu icon="elipsis" fontSize={20} buttonAriaLabel="Settings">
        <>
          {canManage && (
            <DropDownMenu.Item
              route={contentTypeRoutes[CONTENT_TYPES.task].edit({
                public_id: publicId,
              })}
              title="Edit"
              icon="pencil"
            />
          )}
          {isTrackItem && (
            <DropDownMenu.Item
              onClick={() => setShowConvertToArticleModal(true)}
              title={`Convert to ${labelArticle}`}
              icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.articles]}
            />
          )}
          {canAssign && (
            <DropDownMenu.Item
              route={mapRoute('contentItemManageAssignments', {
                public_id_and_slug: publicIdAndSlug,
              })}
              title="View Roster"
              icon="group"
            />
          )}
          {canManage && (
            <DropDownMenu.Item
              onClick={() => setShowDeleteModal(true)}
              title="Delete"
              icon="delete"
              color={colors.error600}
              textColor={colors.error600}
            />
          )}
          {canAssign && (
            <DropDownMenu.Item onClick={() => setShowLogModal(true)} title="View Logs" icon="log" />
          )}
        </>
      </DropDownMenu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}
      {showConvertToArticleModal && (
        <ConvertToArticleModal
          content={content}
          handleClose={() => setShowConvertToArticleModal(false)}
        />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </>
  );
};

export default TaskContextMenu;
