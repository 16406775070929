import { useState } from 'react';

import { MENTEE_PREFERENCE_TAG_TYPE, MENTOR_PREFERENCE_TAG_TYPE } from '~/app/mentorship/constants';
import { MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE } from '~/app/program/constants';
import { FetchFilterOptions, User } from '~/app/shared/components/types';
import PeoplePicker from '~/app/users/components/PeoplePicker';

import ConfirmationStepModal from './ConfirmationStepModal';

interface SuggestMentorshipModalProps {
  receiver: User;
  handleClose: () => void;
  suggestionKind: string;
  isManagerSuggestion?: boolean;
}

const SuggestMentorshipModal = ({
  receiver,
  handleClose,
  suggestionKind,
  isManagerSuggestion = false,
}: SuggestMentorshipModalProps) => {
  const [step, setStep] = useState(0);
  const [suggestedUser, setSuggestedUser] = useState<User | null>(null);
  const mentorOrMentee =
    suggestionKind === MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE ? 'mentee' : 'mentor';

  const initialFiltersConfig: Record<string, any>[] =
    suggestionKind === MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE
      ? [{ name: null }, { [MENTEE_PREFERENCE_TAG_TYPE]: null }]
      : [{ name: null }, { [MENTOR_PREFERENCE_TAG_TYPE]: null }];

  const fetchItemsOptions: Record<string, any> =
    suggestionKind === MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE
      ? { has_mentee_profile: { $eq: 'true' } }
      : { has_mentor_profile: { $eq: 'true' } };

  const fetchFiltersOptions: FetchFilterOptions =
    suggestionKind === MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE
      ? { filters_to_exclude: ['has_mentee_profile'] }
      : { filters_to_exclude: ['has_mentor_profile'] };

  const helpText = `Select a ${mentorOrMentee} to suggest. You can write notes on the next step.`;

  return (
    <>
      {step === 0 && (
        <PeoplePicker
          dialogTitle={`Suggest a ${mentorOrMentee} (1/2)`}
          helpText={helpText}
          fetchItemsOptions={fetchItemsOptions}
          initialFiltersConfig={initialFiltersConfig}
          fetchFiltersOptions={fetchFiltersOptions}
          actionButtonLabel="Next..."
          actionButtonCallback={({ selectedItems: [selectedSuggestedUser] }) => {
            setSuggestedUser(selectedSuggestedUser);
            setStep(1);
          }}
          handleClose={handleClose}
        />
      )}
      {step === 1 && (
        <ConfirmationStepModal
          dialogTitle={`Suggest a ${mentorOrMentee} (2/2)`}
          receiver={receiver}
          suggestedUser={suggestedUser}
          initialValues={{ suggested_id: suggestedUser?.id }}
          suggestionKind={suggestionKind}
          handleClose={handleClose}
          handleBack={() => setStep(0)}
          onRequestSent={handleClose}
          isManagerSuggestion={isManagerSuggestion}
        />
      )}
    </>
  );
};

export default SuggestMentorshipModal;
