import { useCallback, useEffect } from 'react';

import ManageAssignments from '~/app/content-items/components/ManageAssignments/ManageAssignments';
import { CONTENT_ITEM_VIEW_MODES } from '~/app/content-items/constants';
import actions from '~/app/entities/actions';
import { contentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import Page404 from '~/scenes/404';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import Loading from '~/app/shared/components/Loading';
import { HTTP_404_NOT_FOUND, STATUS_DONE } from '~/app/shared/constants';
import { usePublicIdFromURL } from '~/app/shared/hooks';

import ListAssignments from './ListAssignments';

const ContentItemAssignments = () => {
  const { publicId } = usePublicIdFromURL();
  const { trackActivity } = useMetrics();
  const [fetchData, { data: contentItem, status: fetchContentItemStatus, statusCode }] =
    useEntities(
      actions.content.retrieveDetails,
      ({ status }) => {
        if (status === STATUS_DONE) {
          trackActivity(METRICS_ACTIVITIES.CONTENT_ITEM_ASSIGNMENTS_VIEW, {
            contentItemId: contentItem.id,
            contentType: contentItem.content_type,
          });
        }
      },
      { schema: contentSchema }
    );

  const fetchContentItem = useCallback(
    () => fetchData(publicId, { view_mode: CONTENT_ITEM_VIEW_MODES.manage_assignments }),
    [fetchData, publicId]
  );

  useEffect(() => {
    fetchContentItem();
  }, [fetchContentItem]);

  if (statusCode === HTTP_404_NOT_FOUND) return <Page404 />;
  if (fetchContentItemStatus !== STATUS_DONE) return <Loading />;
  return <ManageAssignments contentItem={contentItem} ListComponent={ListAssignments} />;
};

export default ContentItemAssignments;
