import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'redux-form';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import colors from '~/services/colors';
import HR from '~/app/shared/components/HR/HR';
import InfoBox from '~/app/shared/components/InfoBox';
import { ContentSurveyContext } from '~/app/surveys/components/NewSurvey/ContentSurveyContext';
import NewExternalSurveyField from '~/app/surveys/components/NewSurvey/NewExternalSurveyField';
import NewInternalSurveyField from '~/app/surveys/components/NewSurvey/NewInternalSurveyField';
import { values } from 'lodash-es';

const SurveysSection = ({ formName, contentType, assignmentsCount, isEditing }) => {
  const isEventType = contentType === CONTENT_TYPES.eventtype;
  return (
    <ContentSurveyContext.Provider value={contentType}>
      {isEventType && (
        <InfoBox content="Organizers will be able to override their survey preference for their events." />
      )}
      <FieldArray
        formName={formName}
        name="survey_relationships"
        assignmentsCount={assignmentsCount}
        isEditing={isEditing}
        component={NewInternalSurveyField}
      />
      <HR color={colors.neutral200} margin="20px -20px" />
      <NewExternalSurveyField />
    </ContentSurveyContext.Provider>
  );
};

SurveysSection.propTypes = {
  formName: PropTypes.string,
  assignmentsCount: PropTypes.number,
  isEditing: PropTypes.bool,
  contentType: PropTypes.oneOf(values(CONTENT_TYPES)),
};

export default SurveysSection;
