import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import PropTypes from 'prop-types';
import React from 'react';

import { noop } from 'lodash-es';

const StaticDateRangeInput = (props) => {
  return <StaticDateRangePicker {...props} renderInput={noop} />;
};

StaticDateRangeInput.defaultProps = {
  showToolbar: false,
};

StaticDateRangeInput.propTypes = {
  showToolbar: PropTypes.bool,
};

export default StaticDateRangeInput;
