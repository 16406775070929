import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Loading from '~/app/shared/components/Loading';
import Modal, { ModalBody } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { map, size } from 'lodash-es';

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SurveyModal = ({
  entityLabel,
  isLoading,
  surveys,
  surveysCount,
  handleClose,
  renderItem,
  renderTopButton,
}) => {
  const hasSurveys = Boolean(surveysCount);

  return (
    <Modal
      height="480px"
      width={610}
      overflow="initial"
      title="Manage Surveys"
      handleClose={handleClose}
    >
      <ModalBody>
        <Body>
          {isLoading && <Loading />}

          {!isLoading && !hasSurveys && (
            <Text size="h4">No surveys attached to this {entityLabel} yet.</Text>
          )}

          {!isLoading && hasSurveys && (
            <>
              <TopItemsContainer>
                <Text size="h5" medium color={colors.neutral900}>
                  {surveysCount} {surveysCount > 1 ? 'surveys' : 'survey'} attached to this{' '}
                  {entityLabel}
                </Text>
                {renderTopButton && renderTopButton()}
              </TopItemsContainer>
              {map(surveys, (sr, i) =>
                renderItem(sr, size(surveys) > 1 && i === size(surveys) - 1)
              )}
            </>
          )}
        </Body>
      </ModalBody>
    </Modal>
  );
};

SurveyModal.defaultProps = {
  surveys: [],
  isLoading: false,
  surveysCount: 0,
};

SurveyModal.propTypes = {
  entityLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  surveys: PropTypes.arrayOf(PropTypes.object),
  surveysCount: PropTypes.number,
  renderItem: PropTypes.func,
  renderTopButton: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
};

export default SurveyModal;
