import { MutationOptions, useMutation } from '@tanstack/react-query';

import { waitForAsyncJobCompletion } from '~/common/utils/asyncJobs';
import { apiPost, apiPatch, apiPut, apiDelete } from '~/services/requests';
import { get } from 'lodash-es';
import {
  CompleteAssignmentBody,
  CreateAssignmentBody,
  DropAssignmentBody,
  RateAssignmentBody,
  UpdateAssignmentBody,
} from '../types';

type BulkAddContentItemsToChannelAsyncJobParams = {
  channel: number;
  content_items: number[];
};

export function useBulkAddContentItemsToChannelAsyncJob() {
  return useMutation({
    mutationFn: async (data: BulkAddContentItemsToChannelAsyncJobParams) => {
      const { data: response } = await apiPost('contentItemsApi:bulkAddChannel', {}, data);

      const asyncJobId = get(response, 'async_job_id');

      if (!asyncJobId) {
        throw new Error('Async job ID not found in response data');
      }

      await waitForAsyncJobCompletion(asyncJobId);

      return response;
    },
  });
}

export function useBulkArchiveContentItemsAsyncJob() {
  return useMutation({
    mutationFn: async (data: { content_items: number[] }) => {
      const { data: response } = await apiPost('contentItemsApi:bulkArchive', {}, data);

      const asyncJobId = get(response, 'async_job_id');

      if (!asyncJobId) {
        throw new Error('Async job ID not found in response data');
      }

      await waitForAsyncJobCompletion(asyncJobId);

      return response;
    },
  });
}

type BaseMutationOpts = MutationOptions<any, any, any, any>;

const createAssignmentMutationOpts = {
  mutationFn: async (body: CreateAssignmentBody) => {
    const { data: response } = await apiPost('assignments_api:list_create', null, {
      ...body,
    });

    return response;
  },
} satisfies BaseMutationOpts;

const updateAssignmentMutationOpts = {
  mutationFn: async (body: UpdateAssignmentBody) => {
    const { data: response } = await apiPatch('assignments_api:retrieve_update_destroy', body.id, {
      ...body,
    });

    return response;
  },
} satisfies BaseMutationOpts;

const dropAssignmentMutationOpts = {
  mutationFn: async (body: DropAssignmentBody) => {
    const { data: response } = await apiPatch('assignments_api:drop', body.id, {
      force: get(body, 'force', false),
      reason: get(body, 'reason', ''),
    });

    return response;
  },
} satisfies BaseMutationOpts;

const exemptAssignmentMutationOpts = {
  mutationFn: async (id: number) => {
    const { data: response } = await apiPut('assignments_api:exemption', id);
    return response;
  },
} satisfies BaseMutationOpts;

const undoExemptionAssignmentMutationOpts = {
  mutationFn: async (id: number) => {
    const { data: response } = await apiDelete('assignments_api:exemption', id);
    return response;
  },
} satisfies BaseMutationOpts;

const completeAssignmentMutationOpts = {
  mutationFn: async (body: CompleteAssignmentBody) => {
    const { data: response } = await apiPut('assignments_api:completion', body.id, {
      rating: get(body, 'rating'),
      feedback: get(body, 'feedback'),
    });
    return response;
  },
} satisfies BaseMutationOpts;

const undoCompletionAssignmentMutationOpts = {
  mutationFn: async (id: number) => {
    const { data: response } = await apiDelete('assignments_api:completion', id);
    return response;
  },
} satisfies BaseMutationOpts;

const rateAssignmentMutationOpts = {
  mutationFn: async (body: RateAssignmentBody) => {
    const { data: response } = await apiPut('assignments_api:rating', body.id, {
      rating: get(body, 'rating', 5),
      feedback: get(body, 'feedback', ''),
    });
    return response;
  },
} satisfies BaseMutationOpts;

const acknowledgeCompletionAssignmentMutationOpts = {
  mutationFn: async (id: number) => {
    const { data: response } = await apiPut('assignments_api:completion_acknowledgement', id);
    return response;
  },
} satisfies BaseMutationOpts;

export const contentAssignmentsMutations = {
  create: createAssignmentMutationOpts,
  update: updateAssignmentMutationOpts,
  drop: dropAssignmentMutationOpts,
  exempt: exemptAssignmentMutationOpts,
  undoExemption: undoExemptionAssignmentMutationOpts,
  complete: completeAssignmentMutationOpts,
  undoCompletion: undoCompletionAssignmentMutationOpts,
  rate: rateAssignmentMutationOpts,
  acknowledgeCompletion: acknowledgeCompletionAssignmentMutationOpts,
};
