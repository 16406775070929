import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import { mentorshipRequestSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import SegmentedControlsInput from '~/app/inputs/components/SegmentedControlsInput';
import SelectField from '~/app/inputs/components/SelectField';
import {
  MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR,
  MENTORSHIP_REQUEST_KIND_SESSION_REQUEST,
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR,
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE,
  MENTORSHIP_REQUEST_RECEIVED,
  MENTORSHIP_REQUEST_SENT,
  MENTORSHIP_REQUEST_STATUS_ACCEPTED,
  MENTORSHIP_REQUEST_STATUS_DISMISSED,
  MENTORSHIP_REQUEST_STATUS_OPEN,
} from '~/app/program/constants';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import Button from '~/app/shared/components/Button';
import MentorshipRequestReceivedCard from '~/app/shared/components/Card/MentorshipRequestReceivedCard';
import MentorshipRequestSentCard from '~/app/shared/components/Card/MentorshipRequestSentCard';
import HR from '~/app/shared/components/HR';
import Loading from '~/app/shared/components/Loading';
import MediaPoint from '~/app/shared/components/MediaPoint';
import Text from '~/app/shared/components/Text';
import { STATUS_LOADING, STATUS_LOADING_MORE } from '~/app/shared/constants';
import { useCurrentUser, useRouteFilters } from '~/app/shared/hooks';
import { map } from 'lodash-es';

const Container = styled.div`
  background-color: ${colors.neutral0};
  border-radius: 8px;
  margin: 0 0 24px;
`;

const FilterGroup = styled.div`
  ${({ width }) => width && `width: ${width}px;`}

  > * + * {
    margin-top: 4px;
  }
`;

const Header = styled.div`
  padding: 16px 20px;
  display: flex;

  gap: 16px;

  > *:last-child {
    margin-left: auto;
  }
`;

const Content = styled.div`
  padding: 16px 20px;
`;

const CardsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;

  margin: 20px 0 0;
  gap: 20px;

  ${MediaPoint.Tablet} {
    justify-content: flex-start;
  }
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const MENTORSHIP_REQUESTS_PAGE_SIZE = 12;

const MentorshipRequestList = ({ viewMode }) => {
  const [
    fetchMentorshipRequests,
    { data: mentorshipRequests, status: mentorshipRequestsStatus, count: mentorshipRequestsCount },
    loadMoreMentorshipRequests,
  ] = useEntities(actions.mentorshipRequest.retrieveList, null, {
    schema: [mentorshipRequestSchema],
    loadMoreAction: actions.mentorshipRequest.retrieveListLoadMore,
  });

  const [currentFilters, setCurrentFilters, hasLoadedRouteFilters] = useRouteFilters({
    notListAttrs: ['kind', 'status', 'o'],
  });
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (!hasLoadedRouteFilters) return;

    const filters = {
      ...currentFilters,
    };

    if (viewMode === MENTORSHIP_REQUEST_SENT) {
      filters.sender = currentUser.id;

      // Clear status filter from request
      delete filters.status;
    } else if (viewMode === MENTORSHIP_REQUEST_RECEIVED) {
      filters.receiver = currentUser.id;
    }

    fetchMentorshipRequests({
      ...filters,
      o: filters.o ?? 'most_recent',
      page_size: MENTORSHIP_REQUESTS_PAGE_SIZE,
    });
  }, [currentFilters]);

  const isLoading = mentorshipRequestsStatus === STATUS_LOADING;
  const isLoadingMore = mentorshipRequestsStatus === STATUS_LOADING_MORE;
  const hasMentorshipRequestsToLoad =
    !isLoading && mentorshipRequestsCount > mentorshipRequests?.length;

  return (
    <Container>
      <Header>
        <FilterGroup>
          <SegmentedControlsInput
            items={[
              {
                name: 'Sent',
                value: MENTORSHIP_REQUEST_SENT,
                route: mapRoute('myMentorshipRequestsSent'),
              },
              {
                name: 'Received',
                value: MENTORSHIP_REQUEST_RECEIVED,
                route: mapRoute('myMentorshipRequestsReceived'),
              },
            ]}
            value={viewMode}
          />
        </FilterGroup>
        <FilterGroup width={180}>
          <SelectField
            label="Request"
            options={[
              { label: 'Any', value: '' },
              { label: 'Session Request', value: MENTORSHIP_REQUEST_KIND_SESSION_REQUEST },
              { label: 'Offer to Mentor', value: MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR },
              { label: 'Suggest a Mentor', value: MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR },
              { label: 'Suggest a Mentee', value: MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE },
            ]}
            input={{
              value: currentFilters.kind ?? '',
              onChange: (value) => {
                setCurrentFilters({ ...currentFilters, kind: value });
              },
              name: 'request_type',
            }}
          />
        </FilterGroup>
        {viewMode === MENTORSHIP_REQUEST_RECEIVED && (
          <FilterGroup width={160}>
            <SelectField
              label="Status"
              options={[
                { label: 'Any', value: '' },
                { label: 'Pending', value: MENTORSHIP_REQUEST_STATUS_OPEN },
                { label: 'Accepted', value: MENTORSHIP_REQUEST_STATUS_ACCEPTED },
                { label: 'Dismissed', value: MENTORSHIP_REQUEST_STATUS_DISMISSED },
              ]}
              input={{
                value: currentFilters.status ?? '',
                onChange: (value) => {
                  setCurrentFilters({ ...currentFilters, status: value });
                },
                name: 'status',
              }}
            />
          </FilterGroup>
        )}
        <FilterGroup width={200}>
          <SelectField
            label="Sort by"
            options={[
              { label: 'Newest - Oldest', value: 'most_recent' },
              { label: 'Oldest - Newest', value: 'least_recent' },
            ]}
            input={{
              value: currentFilters.o ?? 'most_recent',
              onChange: (value) => {
                setCurrentFilters({ ...currentFilters, o: value });
              },
              name: 'sort_by',
            }}
          />
        </FilterGroup>
      </Header>

      <HR height={2} color={colors.neutral200} />

      {isLoading && (
        <Content>
          <Loading />
        </Content>
      )}

      {!isLoading && (
        <Content>
          <Text size="h5" color={colors.neutral600}>
            {mentorshipRequestsCount === 0
              ? 'No requests'
              : mentorshipRequestsCount === 1
                ? '1 request'
                : `${mentorshipRequestsCount} requests`}
          </Text>
          <CardsList>
            {viewMode === MENTORSHIP_REQUEST_SENT &&
              map(mentorshipRequests, (mentorshipRequest) => (
                <MentorshipRequestSentCard
                  key={mentorshipRequest.id}
                  mentorshipRequest={mentorshipRequest}
                />
              ))}{' '}
            {viewMode === MENTORSHIP_REQUEST_RECEIVED &&
              map(mentorshipRequests, (mentorshipRequest) => (
                <MentorshipRequestReceivedCard
                  key={mentorshipRequest.id}
                  mentorshipRequest={mentorshipRequest}
                />
              ))}
          </CardsList>

          {hasMentorshipRequestsToLoad && (
            <>
              {isLoadingMore && <Loading />}
              {!isLoadingMore && (
                <LoadMoreWrapper>
                  <Button size="small" onClick={loadMoreMentorshipRequests}>
                    Load More
                  </Button>
                </LoadMoreWrapper>
              )}
            </>
          )}
        </Content>
      )}
    </Container>
  );
};

MentorshipRequestList.propTypes = {
  viewMode: PropTypes.string,
};

export default MentorshipRequestList;
