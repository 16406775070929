import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import SearchInput from '~/app/inputs/components/SearchInput';
import { useDebounce } from '~/app/shared/hooks';

const TextFilter = ({ selecteds, param, onChange, placeholder, disabled }) => {
  const filterValue = selecteds[param] ?? '';
  const [inputChanged, setInputChanged] = useState(false);
  const [currentText, setCurrentText] = useState('');

  const debouncedValue = useDebounce(currentText, 500);

  useEffect(() => {
    if (filterValue !== currentText) {
      setCurrentText(filterValue);
    }
  }, [filterValue]);

  useEffect(() => {
    // This is needed so the effect is only activated by input changes, but not param changes
    if (inputChanged && filterValue !== debouncedValue) {
      onChange({
        ...selecteds,
        [param]: debouncedValue,
      });
    }
  }, [debouncedValue]);

  const handleChange = (newText) => {
    setCurrentText(newText);
    setInputChanged(true);
  };

  return (
    <SearchInput
      placeholder={placeholder}
      value={currentText}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

TextFilter.propTypes = {
  param: PropTypes.string,
  placeholder: PropTypes.string,
  selecteds: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TextFilter;
