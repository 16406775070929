import React from 'react';

import { Question } from '~/app/assessments/interfaces';
import BaseContainer from '~/app/navigation/components/BaseContainer';
import { PageContainer } from '~/app/shared/components/DefaultPage';
import ContentDescription from '~/app/stand-alone-shared/components/ContentDescription';
import { Paper, Stack } from '@mui/material';

interface MultipleChoiceQuestionDetailProps {
  question: Question;
  children: React.ReactNode;
}

export const QuestionDetail = ({ question, children }: MultipleChoiceQuestionDetailProps) => {
  // TODO implement skip warning in the next PR

  return (
    <BaseContainer full>
      <PageContainer columnsWidth="1fr">
        <Paper elevation={1} sx={{ padding: 2.5 }}>
          <Stack>
            {question.content_body && (
              <ContentDescription description={question.content_body} noPadding />
            )}
            {children}
          </Stack>
        </Paper>
      </PageContainer>
    </BaseContainer>
  );
};
