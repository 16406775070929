import { blockRenderMap as baseBlockRenderMap } from 'draftjs-utils';
import React from 'react';

import ConfluencePageRenderer from '~/app/inputs/components/LazyTextEditor/renderers/ConfluencePageRenderer';
import { Wrapped } from '~/app/inputs/components/LazyTextEditor/renderers/Wrapped';
import { includes } from 'lodash-es';

import { entityType } from '../constants';

import EmbeddedLinkRenderer from './EmbeddedLinkRenderer';
import EventTypeRenderer from './EventTypeRenderer';
import GoogleDriveRenderer from './GoogleDriveRenderer';
import VideoRenderer from './VideoRenderer';

export function getBlockRenderMap(configs) {
  const { readOnly, preview } = configs;

  const rendererMap = baseBlockRenderMap.merge({
    atomic: {
      element: 'div',
    },
  });

  if (!readOnly || preview) return baseBlockRenderMap;

  const wrappedTypes = [
    'unstyled',
    'header-one',
    'header-two',
    'header-three',
    'header-four',
    'header-five',
    'header-six',
    'ordered-list-item',
    'unordered-list-item',
  ];

  return rendererMap.map((v, k) => {
    if (!includes(wrappedTypes, k)) return v;

    return {
      ...v,
      wrapper: <Wrapped type={k} />,
    };
  });
}

export function getBlockRenderFunc(config) {
  const { getEditorState } = config;

  return (block) => {
    if (block.getType() !== 'atomic') return null;

    const contentState = getEditorState().getCurrentContent();
    const entity = contentState.getEntity(block.getEntityAt(0));
    if (entity?.type === entityType.VIDEO) {
      return {
        component: VideoRenderer,
        editable: false,
        props: {
          ...entity.data,
        },
      };
    }

    if (entity?.type === entityType.EMBEDDED_LINK) {
      return {
        component: EmbeddedLinkRenderer,
        editable: false,
        props: {
          ...config,
          ...entity.data,
        },
      };
    }

    if (entity?.type === entityType.EVENT_TYPE) {
      return {
        component: EventTypeRenderer,
        editable: false,
        props: {
          ...config,
          ...entity.data,
        },
      };
    }

    if (entity?.type === entityType.GOOGLE_DRIVE) {
      return {
        component: GoogleDriveRenderer,
        editable: false,
        props: {
          ...config,
          ...entity.data,
        },
      };
    }
    if (entity && entity.type === entityType.CONFLUENCE_LINK) {
      return {
        component: ConfluencePageRenderer,
        editable: false,
        props: {
          ...entity.data,
        },
      };
    }

    return null;
  };
}
