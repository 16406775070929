import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Provider } from 'react-redux';

import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import store from '~/store';
import { isFunction } from 'lodash-es';

function addBodyClass() {
  document.body.classList.add('react-confirm-alert-body-element');
}

function removeElementReconfirm() {
  const target = document.getElementById('react-confirm-alert');
  unmountComponentAtNode(target);
  target.remove();
}

function removeBodyClass() {
  document.body.classList.remove('react-confirm-alert-body-element');
}

function unmountAlert() {
  removeBodyClass();
  removeElementReconfirm();
}

const SINGLE_BUTTON_DEFAULT_WIDTH = 200;
const DOUBLE_BUTTON_DEFAULT_WIDTH = 132;

const ConfirmAlert = ({
  // required
  title,
  content,
  onConfirm,

  // optional
  onClose,
  onSecondaryAction,

  height,
  minBodyHeight,
  maxBodyHeight,
  width,
  overflow,

  closeOnBackdropClick,
  closeOnEsc,

  confirmLabel,
  isDangerAction,

  secondaryActionLabel,
  hideSecondaryAction,
  isDangerSecondaryAction,

  buttonWidth,
  initialAlertState,
}) => {
  const alertState = useState(initialAlertState);

  const handleClose = () => {
    const onCloseReturn = onClose && isFunction(onClose) && onClose();

    if (onCloseReturn && onCloseReturn.then) {
      onCloseReturn.then(unmountAlert);
    } else {
      unmountAlert();
    }
  };

  return (
    <Provider store={store}>
      <Modal
        title={title}
        height={height}
        minBodyHeight={minBodyHeight}
        maxBodyHeight={maxBodyHeight}
        width={width}
        overflow={overflow}
        closeOnBackdropClick={closeOnBackdropClick}
        closeOnEsc={closeOnEsc}
        handleClose={() => handleClose()}
      >
        <ModalBody>{content({ state: alertState, closeAlert: unmountAlert })}</ModalBody>
        <ModalFooter variant="buttons">
          {!hideSecondaryAction && (
            <ModalFooterButton
              color={isDangerSecondaryAction ? 'error' : 'secondary'}
              onClick={
                onSecondaryAction
                  ? () => {
                      onSecondaryAction(alertState[0]);
                      unmountAlert();
                    }
                  : () => handleClose()
              }
              size="small"
              width={buttonWidth || DOUBLE_BUTTON_DEFAULT_WIDTH}
            >
              {secondaryActionLabel || 'Cancel'}
            </ModalFooterButton>
          )}
          <ModalFooterButton
            color={isDangerAction ? 'error' : 'primary'}
            onClick={() => {
              onConfirm(alertState[0]);
              unmountAlert();
            }}
            size="small"
            width={
              buttonWidth ||
              (hideSecondaryAction ? SINGLE_BUTTON_DEFAULT_WIDTH : DOUBLE_BUTTON_DEFAULT_WIDTH)
            }
          >
            {confirmLabel}
          </ModalFooterButton>
        </ModalFooter>
      </Modal>
    </Provider>
  );
};

ConfirmAlert.propTypes = {
  // required
  title: PropTypes.string.isRequired,
  content: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,

  // optional
  onClose: PropTypes.func,
  onSecondaryAction: PropTypes.func,

  height: PropTypes.string,
  minBodyHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxBodyHeight: PropTypes.string,
  width: PropTypes.number,
  overflow: PropTypes.string,

  closeOnBackdropClick: PropTypes.bool,
  closeOnEsc: PropTypes.bool,

  confirmLabel: PropTypes.string,
  isDangerAction: PropTypes.bool,

  secondaryActionLabel: PropTypes.string,
  hideSecondaryAction: PropTypes.bool,
  isDangerSecondaryAction: PropTypes.bool,

  buttonWidth: PropTypes.number,
  initialAlertState: PropTypes.any,
};

ConfirmAlert.defaultProps = {
  minBodyHeight: 150,
  width: 500,
  overflow: 'auto',

  closeOnBackdropClick: true,
  closeOnEsc: true,

  confirmLabel: 'Confirm',
  isDangerAction: false,

  secondaryActionLabel: 'Cancel',
  hideSecondaryAction: false,
  isDangerSecondaryAction: false,

  buttonWidth: 0,
  initialAlertState: null,
};

function createAlertElement(options) {
  let divTarget = document.getElementById('react-confirm-alert');
  if (divTarget) {
    render(<ConfirmAlert {...options} />, divTarget);
  } else {
    divTarget = document.createElement('div');
    divTarget.id = 'react-confirm-alert';
    // Supressed because append is not supported in IE11
    // eslint-disable-next-line unicorn/prefer-node-append
    document.body.append(divTarget);
    render(<ConfirmAlert {...options} />, divTarget);
  }
}

function confirmAlert(options) {
  addBodyClass();
  createAlertElement(options);
}

export default confirmAlert;
