import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Icon from '~/app/shared/components/Icon';
import Text from '~/app/shared/components/Text';

const InfoBoxContainer = styled.div`
  ${({ type }) => {
    switch (type) {
      case 'success': {
        return `
        background-color: ${colors.success100};
        border: 1px solid ${colors.success400};
        color: ${colors.success600};
        `;
      }
      case 'warning': {
        return `
        background-color: ${colors.alert100};
        border: 1px solid ${colors.alert400};
        color: ${colors.alert600};

        `;
      }
      case 'error': {
        return `
        background-color: ${colors.error100};
        border: 1px solid ${colors.error400};
        color: ${colors.error600};
        `;
      }
      default: {
        return `
        background-color: ${colors.neutral100};
        border: 1px solid ${colors.neutral400};
        color: ${colors.neutral600};
        `;
      }
    }
  }};

  margin: ${({ margin }) => margin || '10px 0'};
  padding: ${({ padding }) => padding || '8px 16px'};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  border-radius: 8px;
  display: flex;
  align-items: center;

  a {
    color: ${colors.action600};
  }

  > * + * {
    margin-left: 12px;
  }
`;

const ContentText = styled(Text)`
  flex-grow: 1;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InfoBox = ({
  type,
  content,
  margin,
  padding,
  fontSize,
  className,
  hideIcon,
  onClick,
  route,
  target,
  actionText,
}) => {
  const getIconProps = () => {
    if (type === 'success') {
      return {
        name: 'info',
        color: colors.success400,
      };
    }
    if (type === 'warning') {
      return {
        name: 'exclamation',
        color: colors.alert400,
      };
    }
    if (type === 'error') {
      return {
        name: 'exclamation',
        color: colors.error400,
      };
    }
    return {
      name: 'info',
      color: colors.neutral400,
    };
  };

  const iconProp = getIconProps();
  return (
    <InfoBoxContainer
      type={type}
      margin={margin}
      padding={padding}
      fontSize={fontSize}
      className={className}
    >
      {!hideIcon && (
        <IconWrapper>
          <Icon name={iconProp.name} color={iconProp.color} width={16} height={16} />
        </IconWrapper>
      )}
      <ContentText>{content}</ContentText>
      {actionText && (
        <ButtonLink onClick={onClick} route={route} target={target}>
          <Text bold color={!type ? colors.action600 : null}>
            {actionText}
          </Text>
        </ButtonLink>
      )}
    </InfoBoxContainer>
  );
};

InfoBox.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  content: PropTypes.any,
  margin: PropTypes.string,
  padding: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
  hideIcon: PropTypes.bool,
  onClick: PropTypes.func,
  route: PropTypes.string,
  target: PropTypes.string,
  actionText: PropTypes.string,
};

export default InfoBox;
