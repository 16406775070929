import { useMutation } from '@tanstack/react-query';

import { ContentItem } from '~/app/shared-content-item/interfaces';
import { apiPost } from '~/services/requests';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import { join } from 'lodash-es';

import { MutationOptions } from '../utils';

export const addMentorToProgram = async (
  public_id: string,
  user_id: number,
  program_id: number
) => {
  const body = { user_id: user_id, mentorship_program_id: program_id };
  const { data: response } = await apiPost(
    'api_coaching:mentorship_mentors_filter',
    { public_id },
    body
  );
  return response;
};

export const bulkAddMentorToProgram = async (
  public_id: string,
  user_rql: string,
  program_id: number,
  reason?: string
) => {
  const body = { user_rql: user_rql, mentorship_program_id: program_id, reason: reason };
  const { data: response } = await apiPost(
    'api_coaching:mentorship_mentors_bulk',
    { public_id },
    body
  );
  return response;
};

export const bulkRemoveMentorFromProgram = async (
  public_id: string,
  user_ids: number[],
  program_id: number
) => {
  const url =
    ApiURLs['api_coaching:mentorship_mentors_bulk']({ public_id }) +
    `?user_ids=${join(user_ids, ',')}&mentorship_program_id=${program_id}`;
  const { data: response } = await fetchURL(url, { method: 'DELETE' });
  return response;
};

export const addMenteeToProgram = async (
  public_id: string,
  user_id: number,
  program_id: number
) => {
  const body = { user_id: user_id, mentorship_program_id: program_id };
  const { data: response } = await apiPost(
    'api_coaching:mentorship_mentees_filter',
    { public_id },
    body
  );
  return response;
};

export const bulkAddMenteeToProgram = async (
  public_id: string,
  user_rql: string,
  program_id: number,
  reason?: string
) => {
  const body = { user_rql: user_rql, mentorship_program_id: program_id, reason: reason };
  const { data: response } = await apiPost(
    'api_coaching:mentorship_mentees_bulk',
    { public_id },
    body
  );
  return response;
};

export const bulkRemoveMenteeFromProgram = async (
  public_id: string,
  user_ids: number[],
  program_id: number
) => {
  const url =
    ApiURLs['api_coaching:mentorship_mentees_bulk']({ public_id }) +
    `?user_ids=${join(user_ids, ',')}&mentorship_program_id=${program_id}`;
  const { data: response } = await fetchURL(url, { method: 'DELETE' });
  return response;
};

export const useBulkAddMentorMutation = (program: ContentItem, onEvents: MutationOptions = {}) => {
  const { public_id, id: programId } = program;
  return useMutation({
    mutationFn: ({ userRql, reason }: { userRql: string; reason: string }) => {
      return bulkAddMentorToProgram(public_id, userRql, programId, reason);
    },
    ...onEvents,
  });
};

export const useBulkDropMentorMutation = (program: ContentItem, onEvents: MutationOptions = {}) => {
  const { public_id, id: programId } = program;
  return useMutation({
    mutationFn: ({ user_ids }: { user_ids: number[] }) => {
      return bulkRemoveMentorFromProgram(public_id, user_ids, programId);
    },
    ...onEvents,
  });
};

export const useBulkAddMenteeMutation = (program: ContentItem, onEvents: MutationOptions = {}) => {
  const { public_id, id: programId } = program;
  return useMutation({
    mutationFn: ({ userRql, reason }: { userRql: string; reason: string }) => {
      return bulkAddMenteeToProgram(public_id, userRql, programId, reason);
    },
    ...onEvents,
  });
};

export const useBulkDropMenteeMutation = (program: ContentItem, onEvents: MutationOptions = {}) => {
  const { public_id, id: programId } = program;
  return useMutation({
    mutationFn: ({ user_ids }: { user_ids: number[] }) => {
      return bulkRemoveMenteeFromProgram(public_id, user_ids, programId);
    },
    ...onEvents,
  });
};
