import PropTypes from 'prop-types';
import React from 'react';

import InlineFormGroup from '~/app/inputs/components/InlineFormGroup';
import { Button } from '~/app/shared';
import colors from '~/services/colors';
import { Tooltip } from '@mui/material';
import { RemoveCircleOutlineIcon } from '~/vendor/mui-icons';

const BaseFilter = ({ label, renderInput, handleRemoveFilter, param, inputWidth }) => {
  // The field has a fixed width of 380px, 252 for the input and 28 for the remove button
  return (
    <InlineFormGroup
      row
      sx={{ '& > :first-of-type': { width: inputWidth }, '& > :last-child': { width: '28px' } }}
    >
      {renderInput()}
      {handleRemoveFilter && (
        <Tooltip title={`Remove filter ${label}`} arrow>
          <Button
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              paddingRight: 0.5,
              paddingLeft: 0.5,
              minWidth: 0,
              borderWidth: 1,
              '&:hover, &:active, &:focus': {
                borderWidth: 1,
              },
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: colors.neutral100,
              width: 28,
            }}
            color="neutral"
            onClick={() => handleRemoveFilter(param)}
            aria-label={`Remove filter ${label}`}
          >
            <RemoveCircleOutlineIcon fontSize="small" />
          </Button>
        </Tooltip>
      )}
    </InlineFormGroup>
  );
};

BaseFilter.defaultProps = {
  inputWidth: '352px',
};

BaseFilter.propTypes = {
  label: PropTypes.string.isRequired,
  renderInput: PropTypes.func.isRequired,
  handleRemoveFilter: PropTypes.func,
  param: PropTypes.string,
  inputWidth: PropTypes.string,
};

export default BaseFilter;
