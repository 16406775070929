import { floor, filter } from 'lodash-es';

export const PROGRAM_LONG_DESCRIPTION_BLOCKS_MIN_SIZE = 3;
export const SCROLL_BAR_WIDTH = 17;
export const SESSION_GROUP_WIDTH = 360;

export const getGroupsPerPage = (width) => floor(width / SESSION_GROUP_WIDTH) || 1;

export const getLimitedLongDescriptionBlocks = (blocks) => {
  return filter(blocks, (_, i) => i < PROGRAM_LONG_DESCRIPTION_BLOCKS_MIN_SIZE);
};
