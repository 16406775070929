import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Button from '~/app/shared/components/Button';
import { isNil } from 'lodash-es';

import ButtonsContainer from './ButtonsContainer';

// we need this because this button turns into an anchor, so it loses its size
const EditSessionButton = styled(Button)`
  text-align: center;
  width: 18%;
`;

export const EnrolledSessionArea = (props) => {
  const {
    userIsHost,
    userIsAdmin,
    handleOpenViewDetailsModal,
    editSessionRoute,
    sessionIsBooked,
    userEnrollment,
    handleEnrollmentModalOpen,
  } = props;

  const renderSecondButton = () => {
    if (userIsHost && !sessionIsBooked) {
      return (
        <EditSessionButton fullWidth route={editSessionRoute} centerLinkText>
          Edit Session
        </EditSessionButton>
      );
    }

    if (!userEnrollment && (userIsHost || userIsAdmin)) {
      return (
        <Button size="small" fullWidth onClick={handleEnrollmentModalOpen}>
          Enroll
        </Button>
      );
    }
    return null;
  };

  const secondButton = renderSecondButton();

  return (
    <ButtonsContainer noPadding singleButton={isNil(secondButton)}>
      <Button size="small" color="primary" onClick={handleOpenViewDetailsModal}>
        View Details
      </Button>
      {secondButton}
    </ButtonsContainer>
  );
};

EnrolledSessionArea.propTypes = {
  userIsAdmin: PropTypes.bool,
  userIsHost: PropTypes.bool,
  handleOpenViewDetailsModal: PropTypes.func,
  editSessionRoute: PropTypes.string,
  sessionIsBooked: PropTypes.bool,
  userEnrollment: PropTypes.object,
  handleEnrollmentModalOpen: PropTypes.func,
};

export default EnrolledSessionArea;
