import { LEARNING_TYPES } from '~/app/catalog/constants';
import { useContentPeople, useContentFeedback } from '~/app/catalog/hooks';
import { useLatestContentItemAssignment } from '~/common/hooks/useLatestContentItemAssignment';
import ContentItemContainer from '~/app/content-items/components/ContentItemContainer';
import { useIsRenderedWithinTrack } from '~/features/tracks/hooks/useIsRenderedWithinTrack';
import BasePeopleContainer from '~/app/shared-cards/components/BasePeopleContainer';
import BreadCrumbs from '~/app/shared-content-item/components/BreadCrumbs';
import ContentItemAdditional from '~/app/shared-content-item/components/ContentItemAdditional';
import ContentItemBackgroundPanel from '~/app/shared-content-item/components/ContentItemBackgroundPanel';
import ContentItemBody from '~/app/shared-content-item/components/ContentItemBody';
import ContentItemHeader from '~/app/shared-content-item/components/ContentItemHeader';
import DescriptionContent from '~/app/shared-content-item/components/DescriptionContent';
import ResourceContainer from '~/app/shared-content-item/components/ResourceContainer';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { PageContainer, PageBody } from '~/app/shared/components/DefaultPage';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import Text from '~/app/shared/components/Text';
import { STATUS_DONE } from '~/app/shared/constants';
import { useResource } from '~/app/shared/hooks';
import BaseFeedbackContainer, {
  useBaseFeedbackContainerMode,
} from '~/app/stand-alone-shared/components/FeedbackContainer/BaseFeedbackContainer';
import { get } from 'lodash-es';

import CodelabContextMenu from '../CodelabContextMenu';
import PrimaryActionButton from '../PrimaryActionButton';

interface CodelabDetailPageProps {
  content: ContentItem;
  refreshContent?: CallableFunction;
  isRequired?: boolean;
}

export const CodelabDetailPage = (props: CodelabDetailPageProps) => {
  const { content: baseContent, refreshContent, isRequired } = props;

  const isRenderedWithinTrack = useIsRenderedWithinTrack();

  // This is temporary until we completely remove the redux sagas usage.
  // This is necessary because it is possible to have multiple assignments in the same state because of the save/drop behavior.
  const currentAssignment = useLatestContentItemAssignment(baseContent.id);
  const content = {
    ...baseContent,
    user_assignment:
      currentAssignment || get(baseContent, 'user_assignment', get(baseContent, 'assignment')),
  };

  const { showResource } = useResource(content, LEARNING_TYPES.codelabs);
  const { showPeople, organizers, maintainers, engagedPeople } = useContentPeople(content);
  const { engagedPeopleCount } = useContentPeople(content, ['active', 'terminated']);
  const { showFeedback, feedback, feedbackStatus } = useContentFeedback(
    content,
    LEARNING_TYPES.codelabs
  );

  const feedbackContainerMode = useBaseFeedbackContainerMode({
    showFeedback,
    showOthers: [showResource, showPeople],
  });

  const isLoadingFeedback = feedbackStatus !== STATUS_DONE;

  const { is_inline: isInline } = content;
  const clickableName = isInline ? false : isRenderedWithinTrack;

  return (
    <ContentItemContainer content={content} isRenderedWithinTrack={isRenderedWithinTrack}>
      {!isRenderedWithinTrack && <PageTitle title={content.name} />}
      <ContentItemBackgroundPanel height="120px" $isTrackItem={isRenderedWithinTrack} />
      {!isRenderedWithinTrack && <BreadCrumbs content={content} />}
      <PageContainer columnsWidth="1fr">
        <ContentItemHeader
          maintainers={maintainers}
          content={content}
          contextMenu={
            <CodelabContextMenu
              content={content}
              refreshContent={refreshContent}
              contextMenuExtraProps={{ isTrackItem: isRenderedWithinTrack }}
            />
          }
          primaryActionButton={PrimaryActionButton}
          clickableName={clickableName}
          isRequired={isRequired}
        />
        <PageBody>
          <ContentItemBody>
            <DescriptionContent content={content} />
            <ContentItemAdditional>
              {showResource && <ResourceContainer content={content} />}
              {showPeople && (
                <BasePeopleContainer
                  contentType={LEARNING_TYPES.articles}
                  organizers={organizers}
                  maintainers={maintainers}
                  engagedPeople={engagedPeople}
                  engagedPeopleCount={engagedPeopleCount}
                  engagedPeopleHeading={
                    <Text>
                      {engagedPeopleCount} {engagedPeopleCount > 1 ? 'people' : 'person'} engaged
                    </Text>
                  }
                />
              )}
              {showFeedback && (
                <BaseFeedbackContainer
                  content={content}
                  feedbackList={feedback}
                  mode={feedbackContainerMode}
                  isLoading={isLoadingFeedback}
                />
              )}
            </ContentItemAdditional>
          </ContentItemBody>
        </PageBody>
      </PageContainer>
    </ContentItemContainer>
  );
};

export default CodelabDetailPage;
