import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import CheckboxInput from '~/app/inputs/components/SquareCheckboxInput';
import TextInput from '~/app/inputs/components/TextInput';
import surveyProptypes from '~/app/surveys/types';
import { includes, map, get, without } from 'lodash-es';

const MultipleSelectionOtherContainer = styled.div`
  margin-top: 16px;

  > * + * {
    margin-top: 20px;
  }
`;

const OtherInput = styled(TextInput)`
  max-width: 320px;
`;

const OtherOptionContainer = styled.div`
  display: flex;
  margin-top: 14px;

  ${OtherInput} {
    ${({ checked }) => !checked && 'visibility: hidden'};
    margin-left: 16px;
  }
`;

const SurveyQuestionMultipleSelectionOther = ({ input, question }) => {
  const { options } = question;

  const [otherChecked, setOtherChecked] = useState(false);
  const [other, setOther] = useState({
    isOther: true,
    value: '',
  });
  const [selected, setSelected] = useState({
    isOther: false,
    value: [],
  });

  useEffect(() => {
    const newValue = otherChecked
      ? { isOther: true, value: [...selected.value, other.value] }
      : selected;
    input.onChange(newValue);
  }, [selected, other, otherChecked]);

  const isChecked = (option) => includes(selected.value, option);

  const handleInputChange = (option) => {
    if (isChecked(option)) {
      setSelected({ ...selected, value: without(selected.value, option) });
    } else {
      setSelected({ ...selected, value: [...selected.value, option] });
    }
  };

  const handleOtherCheckboxChange = () => {
    setOtherChecked((c) => !c);
    setOther({ ...other, value: '' });
  };

  return (
    <MultipleSelectionOtherContainer>
      {map(options, (option) => (
        <CheckboxInput
          key={option}
          id={`multiple-other-${input.name}-${option}`}
          label={option}
          input={{
            ...input,
            value: get(selected, 'value', ''),
          }}
          checked={isChecked(option)}
          onChange={() => handleInputChange(option)}
          onBlur={() => input.onBlur()}
        />
      ))}
      <OtherOptionContainer checked={otherChecked}>
        <CheckboxInput
          label="Other"
          checked={otherChecked}
          onChange={handleOtherCheckboxChange}
          onFocus={() => input.onBlur()}
        />
        <OtherInput
          value={get(other, 'value', '')}
          onChange={(e) => setOther({ ...other, value: e.target.value })}
        />
      </OtherOptionContainer>
    </MultipleSelectionOtherContainer>
  );
};

SurveyQuestionMultipleSelectionOther.propTypes = {
  input: PropTypes.object,
  question: surveyProptypes.surveyQuestion,
};

export default SurveyQuestionMultipleSelectionOther;
