import PropTypes from 'prop-types';
import React from 'react';
import { Field, FieldArray } from 'redux-form';
import styled from 'styled-components';

import ContentItemInputInfoBox from '~/app/shared-content-item/components/ContentItemInputInfoBox';
import { FormFieldGroup } from '~/app/shared/components/Form';
import ResourceLinkField from '~/app/shared/components/NewContentForm/fields/ResourceLinkField';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import { useLearningTypeLabels } from '~/app/catalog/hooks';
import InputLabel from '~/app/inputs/components/InputLabel';
import ProgramSelectField from '~/app/inputs/components/ProgramSelectField';
import TextField from '~/app/inputs/components/TextField';
import colors from '~/services/colors';
import HR from '~/app/shared/components/HR';
import Form from '~/app/shared/components/OldForm';
import { useCurrentUser } from '~/app/shared/hooks';
import { isEmpty } from 'lodash-es';

const FieldWrap = styled.div`
  margin-bottom: 20px;
`;

const validateLength = Form.validations.maxLength(80);
const validateNotAllowedCharacters = (value) => {
  if (value && !isEmpty(value.match(/[^a-z0-9_-]/g))) {
    return 'This field must only contain lowercase letters, numbers, hyphens and underscores.';
  }
};

const ResourcesSection = ({ formName, selectedOfficeHour, usePublicId, showSlackResource }) => {
  const learningTypeLabels = useLearningTypeLabels();
  const { is_slack_integration_active: isSlackIntegrationActive } = useCurrentUser();

  return (
    <FormFieldGroup>
      <FieldWrap>
        <Field
          name="office_hour_id"
          label={learningTypeLabels[LEARNING_TYPES.programs]}
          component={ProgramSelectField}
          usePublicId={usePublicId}
        />
        {selectedOfficeHour && (
          <ContentItemInputInfoBox
            content={selectedOfficeHour}
            upcoming={selectedOfficeHour.upcoming_sessions_count}
            upcomingType="session"
            actionText="View details"
          />
        )}
      </FieldWrap>
      {showSlackResource && isSlackIntegrationActive && (
        <>
          <HR color={colors.neutral200} margin="20px -20px" />
          <FieldWrap>
            <InputLabel>Slack Channel</InputLabel>
            <Field
              name="slack_channel"
              component={TextField}
              validate={[validateLength, validateNotAllowedCharacters]}
            />
          </FieldWrap>
        </>
      )}
      <HR color={colors.neutral200} margin="20px -20px" />
      <FieldWrap>
        <InputLabel>Links</InputLabel>
        <FieldArray formName={formName} name="resources" component={ResourceLinkField} />
      </FieldWrap>
    </FormFieldGroup>
  );
};

ResourcesSection.propTypes = {
  formName: PropTypes.string,
  selectedOfficeHour: PropTypes.object,
  usePublicId: PropTypes.bool,
  showSlackResource: PropTypes.bool,
};

ResourcesSection.defaultProps = {
  usePublicId: true,
  showSlackResource: false,
};

export default ResourcesSection;
