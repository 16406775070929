import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const ArrowIcon = ({ width, height, fill, orientation }) => {
  const d = 'M-4.633 7L10 21.645 24.633 7 26 8.374 10 24.402-6 8.374z';

  let transform;
  switch (orientation) {
    case 'left': {
      transform = 'rotate(90 10 15.701)';
      break;
    }
    case 'up': {
      transform = 'rotate(180 10 15.701)';
      break;
    }
    case 'right': {
      transform = 'rotate(270 10 15.701)';
      break;
    }
    default: {
      transform = null;
    }
  }

  return (
    <SvgIcon
      d={d}
      fill={fill}
      width={width}
      height={height}
      viewBox="0 0 20 32"
      transform={transform}
    />
  );
};

ArrowIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  orientation: PropTypes.string,
};

export default ArrowIcon;
