import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import InputLabel from '~/app/inputs/components/InputLabel';
import SelectField from '~/app/inputs/components/SelectField';
import TextInput from '~/app/inputs/components/TextInput';
import { ATTENDANCE_METHOD_LOCAL, ATTENDANCE_METHOD_ONLINE } from '~/app/program/constants';
import { ProgramContext } from '~/scenes/ProgramSessionsFilter/ProgramContext';
import colors from '~/services/colors';
import Datetime from '~/app/shared/components/Datetime';
import Icon from '~/app/shared/components/Icon';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { useLabels } from '~/app/shared/hooks';
import { filter, get, isEmpty, join, map, trim, split } from 'lodash-es';

const ModalContainer = styled.div`
  font-size: 15px;
`;

export const ModalSection = styled.div`
  margin-bottom: 20px;
  position: relative;
  left: 0;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ModalForm = styled.div`
  margin-top: 10px;
  position: relative;
  left: 0;
`;

const Error = styled.div`
  color: ${colors.error600};
  margin-top: 10px;
`;

const SessionEnrollmentModal = ({ currentUser, session, handleClose, handleSubmitModal }) => {
  const program = useContext(ProgramContext);
  const { is_calendar_integration_active: isCalendarIntegrationActive, timezone: userTimezone } =
    currentUser;

  const sessionTimezone = get(session, 'timezone');
  const allowsOnline = get(session, 'allows_online');

  const [agenda, setAgenda] = useState('');
  const [topic, setTopic] = useState(null);
  const [agendaError, setAgendaError] = useState(false);

  const onButtonClick = (selectedAttendanceMethod) => {
    if (agenda || topic) {
      handleSubmitModal(selectedAttendanceMethod, join(filter([topic, agenda]), ': '));
      handleClose();
    } else {
      setAgendaError(true);
    }
  };

  const {
    label_mentorship_sessions_creator_title_single: mentorshipHostLabel,
    label_program_sessions_creator_title_single: programSessionHostLabel,
    label_program: labelProgram,
    label_mentorship_program: labelMentorshipProgram,
    label_mentorship_session_agenda_prompt: labelMentorshipSessionAgendaPrompt,
    label_office_hours_session_agenda_prompt: labelOfficeHourSessionAgendaPrompt,
  } = useLabels();

  const programTitle = get(session, 'program_name');
  const coachName = get(session, 'host.name');
  const startsAt = get(session, 'starts_at_tz_aware');
  const locationName = get(session, 'location.name');
  const room = get(session, 'room');

  const roomText = room ? ` @ ${room}` : '';
  let locationText = `${locationName}${roomText}`;
  const isMentorshipSession = (session) => {
    if (!session.program_id || session.program_type == 'mentorship_program') return true;
    return false;
  };

  const sessionProgramLabel = isMentorshipSession(session) ? labelMentorshipProgram : labelProgram;

  if (!session.allows_local && session.allows_online) {
    locationText = 'Online';
  }

  const hostLabel = isMentorshipSession(session) ? mentorshipHostLabel : programSessionHostLabel;
  const toOption = (value) => {
    value = trim(value);
    return { value: value, label: value };
  };

  const sessionTopicsCSVString = get(program, 'session_topics', '');
  const sessionTopics = map(filter(split(sessionTopicsCSVString, ',')), toOption);

  return (
    <Modal title="Book Session" handleClose={handleClose} width={600}>
      <ModalBody>
        <ModalContainer>
          <ModalSection>
            <InputLabel>
              You are about to book a session.{' '}
              {isCalendarIntegrationActive &&
                'Once you enroll an invite will be sent automatically to your calendar.'}
            </InputLabel>
          </ModalSection>

          <ModalSection>
            <InputLabel>{hostLabel}:</InputLabel>
            <Text block size="h4">
              {coachName}
            </Text>
          </ModalSection>

          {session.program_id && (
            <ModalSection>
              <InputLabel>{sessionProgramLabel}:</InputLabel>
              <Text block size="h4">
                {programTitle}
              </Text>
            </ModalSection>
          )}

          <ModalSection>
            <InputLabel>Date and Time:</InputLabel>
            <Text block size="h4">
              <Datetime
                datetime={startsAt}
                timezone={sessionTimezone}
                displayTimezone={userTimezone}
                isOnline={allowsOnline}
                includeWeekDay
              />
            </Text>
          </ModalSection>

          <ModalSection>
            <InputLabel>Location:</InputLabel>
            <Text block size="h4">
              {locationText}
            </Text>
          </ModalSection>

          {session.allows_local && !isEmpty(session.extra_info) && (
            <ModalSection>
              <InputLabel>Extra Info:</InputLabel>
              <Text block size="h4">
                {session.extra_info}
              </Text>
            </ModalSection>
          )}
          {!isEmpty(sessionTopics) && (
            <ModalSection>
              <InputLabel>Topics:</InputLabel>
              <SelectField
                options={sessionTopics}
                input={{
                  onChange: (value) => {
                    setTopic(value);
                    setAgendaError(false);
                  },
                  error: !agenda && agendaError,
                }}
              />
            </ModalSection>
          )}
          {(isEmpty(sessionTopics) || topic) && (
            <ModalSection>
              <InputLabel>
                {session.program_id
                  ? labelOfficeHourSessionAgendaPrompt
                  : labelMentorshipSessionAgendaPrompt}
              </InputLabel>

              <ModalForm>
                <TextInput
                  multiline
                  rows={3}
                  placeholder={`Share confidentially with your ${hostLabel}`}
                  value={agenda}
                  onChange={(event) => setAgenda(event.target.value)}
                  error={!agenda && agendaError}
                />
                {!agenda && agendaError && <Error>You cannot enroll without an agenda.</Error>}
              </ModalForm>
            </ModalSection>
          )}
        </ModalContainer>
      </ModalBody>
      <ModalFooter variant="buttons">
        {session.allows_online && (
          <ModalFooterButton
            startIcon={<Icon name="online" />}
            onClick={() => onButtonClick(ATTENDANCE_METHOD_ONLINE)}
          >
            &nbsp;Enroll
          </ModalFooterButton>
        )}
        {session.allows_local && (
          <ModalFooterButton
            startIcon={<Icon name="location" />}
            onClick={() => onButtonClick(ATTENDANCE_METHOD_LOCAL)}
          >
            &nbsp;Enroll
          </ModalFooterButton>
        )}
      </ModalFooter>
    </Modal>
  );
};

SessionEnrollmentModal.propTypes = {
  handleClose: PropTypes.func,

  session: PropTypes.object,
  currentUser: PropTypes.object,

  handleSubmitModal: PropTypes.func,
};

export default SessionEnrollmentModal;
