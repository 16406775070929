import { map } from 'lodash-es';
import { Chip, Stack } from '@mui/material';

export type ShortcutConfig = {
  label: string;
  value: { startDate: string; endDate: string };
};

interface DateRangeShortcutsProps {
  shortcuts?: ShortcutConfig[];
  onChange: (value: { startDate: string; endDate: string }) => void;
}

export const UPCOMING_SHORTCUT: ShortcutConfig = {
  label: 'Upcoming',
  value: { startDate: '-PT0H', endDate: '' },
};

export const PAST_SHORTCUT: ShortcutConfig = {
  label: 'Past',
  value: { startDate: '', endDate: '-PT0H' },
};

const DateRangeShortcuts = (props: DateRangeShortcutsProps) => {
  const { shortcuts = [UPCOMING_SHORTCUT, PAST_SHORTCUT], onChange } = props;

  return (
    <Stack direction="row" spacing={1}>
      {map(shortcuts, (shortcut) => (
        <Chip
          key={shortcut.label}
          label={shortcut.label}
          size="medium"
          onClick={() => onChange(shortcut.value)}
        />
      ))}
    </Stack>
  );
};

export default DateRangeShortcuts;
