import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import { useFormMeta, useFormSelector, useFormSyncErrors } from '~/app/shared/hooks';
import { isEmpty } from 'lodash-es';

import ResourceLinkBoxContent from './ResourceLinkBoxContent';
import ResourceLinkBoxHeader from './ResourceLinkBoxHeader';

const Box = styled.div`
  border: 1px solid ${({ hasError }) => (hasError ? colors.error600 : colors.neutral200)};
  border-radius: 4px;
  margin: 20px 0 26px;
  padding: 16px;

  ${({ isOpen }) => isOpen && 'box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);'}
`;

const ResourceLinkBox = ({
  formName,
  resource,
  index,
  handleOnRemove,
  fields,
  lastOpenedBox,
  setLastOpenedBox,
}) => {
  const isOpen = index === lastOpenedBox;

  const [link, setLink] = useState(null);
  const [hasError, setHasError] = useState(false);

  const resourcesErrors = useFormSyncErrors(formName, 'resources');
  const resourcesMeta = useFormMeta(formName, 'resources');

  useEffect(() => {
    const link = fields.get(index);

    if (link && (link.name || link.link)) {
      setLinkSummary(link);
      setLastOpenedBox(-1);
    }
  }, []);

  useEffect(() => {
    if (!link || isOpen) return;

    setLinkSummary(link);
  }, [isOpen]);

  useEffect(() => {
    if (hasError && !isOpen) return;

    if (
      isEmpty(resourcesErrors) ||
      isEmpty(resourcesErrors[index]) ||
      isEmpty(resourcesMeta) ||
      isEmpty(resourcesMeta[index])
    ) {
      setHasError(false);
    }
  }, [resourcesErrors]);

  const handleOnOpenBox = () => {
    setLastOpenedBox(isOpen ? -1 : index);
  };

  const setLinkSummary = (link) => {
    if (!link) {
      setLink(null);
      return;
    }
    setLink(link);
  };

  const resources = useFormSelector(formName, 'resources');

  return (
    <Box isOpen={isOpen} hasError={hasError}>
      <ResourceLinkBoxHeader
        isOpen={isOpen}
        hasError={hasError}
        handleOnClick={handleOnOpenBox}
        link={resources[index]}
      />
      {isOpen && <ResourceLinkBoxContent resource={resource} handleOnRemove={handleOnRemove} />}
    </Box>
  );
};

ResourceLinkBox.propTypes = {
  formName: PropTypes.string,
  resource: PropTypes.string,
  index: PropTypes.number,
  handleOnRemove: PropTypes.func,
  fields: PropTypes.object,
  lastOpenedBox: PropTypes.number,
  setLastOpenedBox: PropTypes.func,
};

export default ResourceLinkBox;
