import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import NewEventModal from '~/app/event-shared/components/NewEventModal';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import { ApiURLs } from '~/services/requests-base';
import DropDownMenu from '~/app/shared/components/DropDownMenu';
import MediaPoint from '~/app/shared/components/MediaPoint';
import UserAvatar from '~/app/shared/components/UserAvatar';
import * as permissionConstants from '~/app/shared/permissions';
import { get, includes, split, isEmpty } from 'lodash-es';

const badgeSize = '15px';

const ProfileMenuUsername = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 10px 4px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: -0.4px;
  color: ${colors.neutral600};

  > span {
    display: none;
  }

  ${MediaPoint.Tablet} {
    > span {
      display: inline;
    }
  }
`;

const StyledUserAvatar = styled(UserAvatar)`
  margin-left: 6px;
`;

const NotificationBadge = styled.div`
  display: inline-block;
  background-color: ${colors.emphasis600};
  padding: 0px;
  border-radius: 50%;
  font-weight: 800;
  color: ${colors.neutral0};
  text-align: center;
  vertical-align: middle;
  z-index: 99999;
`;

const FeedbackBadge = styled(NotificationBadge)`
  ${(props) =>
    ({
      external: css`
        width: ${badgeSize};
        height: ${badgeSize};
        line-height: ${badgeSize};
        position: absolute;
        top: 2px;
        left: 0px;
        margin: 0px;
        font-size: 10px;
      `,
      internal: css`
        width: ${badgeSize};
        height: ${badgeSize};
        line-height: ${badgeSize};
        font-size: 9px;
        margin-bottom: 4px;
      `,
    })[props.location]}
`;

const MenuDivider = styled.li`
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: ${colors.neutral200};
`;

const UppercaseText = styled.span`
  font-size: 12px;
  text-transform: uppercase;
`;

const ProfileMenuItem = styled(DropDownMenu.Item)`
  min-width: 180px;
  font-weight: normal !important;
`;

const UppercaseDropDownMenuItem = (props) => {
  const { children, title, ...rest } = props;

  return (
    <ProfileMenuItem fontSize={12} {...rest}>
      <UppercaseText>{title || children}</UppercaseText>
    </ProfileMenuItem>
  );
};

UppercaseDropDownMenuItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const ProfileMenu = ({
  currentUser,
  logoutLink,
  toggleSnippets,
  togglePrograms,
  toggleSessions,
  toggleMenteePreferences,
  permissions,
  toggleMentors,
  mentorshipLabel,
  labelProgram,
  labelEventType,
  labelTrack,
  labelMentorship,
}) => {
  const [showNewEventModal, setShowNewEventModal] = useState(false);

  let missingFeedbackCount = 0;
  if (currentUser) {
    missingFeedbackCount = currentUser.missing_feedback_count;
  }

  return (
    <DropDownMenu
      buttonContent={
        <>
          {missingFeedbackCount ? (
            <FeedbackBadge location="external">
              {missingFeedbackCount > 9 ? '9+' : missingFeedbackCount}
            </FeedbackBadge>
          ) : null}
          <Box display="inline-flex" gap="4px" alignItems="center">
            <StyledUserAvatar
              className="profile-menu-user-avatar menu-item-icon"
              user={currentUser}
              size="small"
              disableProfileLink
            />
            <ProfileMenuUsername>
              <span>{split(currentUser.name, ' ')[0]}</span>
              <KeyboardArrowDownIcon fontSize="small" />
            </ProfileMenuUsername>
          </Box>
        </>
      }
    >
      <ProfileMenuItem
        key={101}
        route={mapRoute('userProfile', {
          id: `${currentUser.id}`,
        })}
        title="My Profile"
      />

      <ProfileMenuItem key={89} route={mapRoute('myEvents')} title="My Events" />

      {toggleSessions && (
        <ProfileMenuItem key={11} route={mapRoute('mySessions')} title="My Sessions" />
      )}

      {toggleMentors && toggleMenteePreferences && (
        <ProfileMenuItem
          key={42}
          route={mapRoute('myMentorshipRequestsReceived')}
          title={`My ${labelMentorship} Messages`}
        />
      )}

      <ProfileMenuItem key={78} route={mapRoute('myLearningContent')} title="My Content" />

      {includes(permissions, permissionConstants.CREATE_GROUP_PERMISSION) && (
        <ProfileMenuItem key={79} route={mapRoute('myGroups')} title="My Groups" />
      )}

      <ProfileMenuItem key={98} route={mapRoute('checkin')} title="Event Check-In" />

      <ProfileMenuItem key={12} route={mapRoute('feedback')}>
        Event Feedback{' '}
        {missingFeedbackCount > 0 && (
          <FeedbackBadge location="internal">
            {missingFeedbackCount > 9 ? '9+' : missingFeedbackCount}
          </FeedbackBadge>
        )}
      </ProfileMenuItem>

      {currentUser.toggles && currentUser.toggles.toggle_alerts && (
        <ProfileMenuItem key={13} url={mapRoute('alertsList')}>
          Alerts
        </ProfileMenuItem>
      )}

      {!isEmpty(permissions) && <MenuDivider key={24} />}

      {includes(permissions, permissionConstants.CREATE_EVENT_PERMISSION) && (
        <ProfileMenuItem key={30} onClick={() => setShowNewEventModal(true)} title="+ Add Event" />
      )}

      {includes(permissions, permissionConstants.CREATE_EVENT_TYPE_PERMISSION) && (
        <ProfileMenuItem
          key={31}
          url={mapRoute('eventTypeCreate')}
          title={`+ Add ${labelEventType}`}
        />
      )}

      {toggleSnippets && includes(permissions, permissionConstants.CREATE_TRACK_PERMISSION) && (
        <ProfileMenuItem key={33} route={mapRoute('trackCreate')} title={`+ Add ${labelTrack}`} />
      )}

      {togglePrograms && includes(permissions, permissionConstants.CREATE_PROGRAM_PERMISSION) && (
        <ProfileMenuItem
          key={34}
          route={mapRoute('programCreate')}
          title={`+ Add ${labelProgram}`}
        />
      )}

      {togglePrograms &&
        includes(permissions, permissionConstants.CREATE_PROGRAM_SESSION_PERMISSION) && (
          <ProfileMenuItem
            key={112}
            route={mapRoute('sessionCreate')}
            title={`+ Add ${labelProgram} Session`}
          />
        )}

      {toggleMentors &&
        includes(permissions, permissionConstants.CREATE_MENTORSHIP_SESSION_PERMISSION) && (
          <ProfileMenuItem
            key={113}
            route={mapRoute('sessionMentorshipCreate')}
            title={`+ Add ${mentorshipLabel} Session`}
          />
        )}

      {includes(permissions, permissionConstants.IS_ORGANIZER_PERMISSION) && [
        <MenuDivider key={50} />,
        <UppercaseDropDownMenuItem key={51} route={mapRoute('dashboard')} title="Dashboard" />,
        <UppercaseDropDownMenuItem key={52} route={mapRoute('charts')} title="Charts" />,
        ...(includes(permissions, permissionConstants.IS_ADMIN_PERMISSION)
          ? [
              <UppercaseDropDownMenuItem
                key={54}
                route={mapRoute('settings', { context: 'site' })}
                title="Settings"
              />,
            ]
          : []),
      ]}

      <MenuDivider key={55} />

      <UppercaseDropDownMenuItem key={105} url={logoutLink} title="Logout" />
      {showNewEventModal && <NewEventModal handleClose={() => setShowNewEventModal(false)} />}
    </DropDownMenu>
  );
};

ProfileMenu.propTypes = {
  currentUser: PropTypes.object,
  logoutLink: PropTypes.string,

  permissions: PropTypes.array,

  toggleSnippets: PropTypes.bool,
  togglePrograms: PropTypes.bool,
  toggleMentors: PropTypes.bool,
  toggleSessions: PropTypes.bool,

  toggleMenteePreferences: PropTypes.bool,

  mentorshipLabel: PropTypes.string,

  labelEventType: PropTypes.string,
  labelProgram: PropTypes.string,
  labelTrack: PropTypes.string,
  labelMentorship: PropTypes.string,
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  permissions: get(state, 'user.currentUser.permissions', []),

  feedbackLink: ApiURLs['attendance-feedback'](),
  logoutLink: ApiURLs.logout(),

  toggleSnippets: get(state, 'user.currentUser.module_toggles.tracks', false),
  togglePrograms: get(state, 'user.currentUser.module_toggles.programs', false),
  toggleMentors: get(state, 'user.currentUser.module_toggles.mentorship', false),
  toggleSessions: get(state, 'user.currentUser.module_toggles.sessions', false),

  toggleMenteePreferences: get(state, 'user.currentUser.toggles.toggle_mentee_preferences'),

  mentorshipLabel: get(state, 'user.currentUser.labels.label_mentorship_tab', ''),

  labelProgram: get(state, 'user.currentUser.labels.label_program'),
  labelEventType: get(state, 'user.currentUser.labels.label_event_type'),
  labelTrack: get(state, 'user.currentUser.labels.label_track'),
  labelMentorship: get(state, 'user.currentUser.labels.label_mentorship_tab'),
});

export default connect(mapStateToProps)(ProfileMenu);
