import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import TextField from '~/app/inputs/components/TextField';
import Form from '~/app/shared/components/OldForm';
import UserAvatar from '~/app/shared/components/UserAvatar';
import { required } from '~/app/shared/formValidations';
import { pick } from 'lodash-es';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

const fieldRequired = required();

const AvatarContainer = styled.div`
  width: 40px;
  margin-right: 12px;
  display: inline-block;
  vertical-align: top;
`;

const FieldsContainer = styled.div`
  display: inline-block;
  width: calc(100% - 44px);

  & > div {
    margin: 0;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
  margin-top: 10px;
`;

const SubmitArea = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0px 20px 10px 20px;
  button {
    margin: 0px 10px 0px 10px;
  }
`;

const InputArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 0px 20px;
`;

class TestimonialForm extends React.Component {
  onSubmit = () => {
    const { actionName, handleSubmit, editingTestimonial, receiverId } = this.props;
    return handleSubmit(
      onSubmitActions(actionName, (values) => ({
        ...(editingTestimonial && { id: editingTestimonial.id }),
        key: 'testimonialForm',
        body: {
          ...pick(values, ['text']),
          receiver_id: receiverId,
        },
      }))
    );
  };

  render = () => {
    const { invalid, error, submitting, currentUser, receiverName, handleClose } = this.props;

    return (
      <StyledForm
        {...this.props}
        margin="10"
        onSubmit={this.onSubmit()}
        renderButtons={() => (
          <SubmitArea>
            <Form.DeleteButton type="button" onClick={handleClose} size="small">
              Discard
            </Form.DeleteButton>
            <Form.SubmitButton size="small">Share</Form.SubmitButton>
          </SubmitArea>
        )}
      >
        <Form.ErrorContainer invalid={invalid} error={error} submitting={submitting} />
        <InputArea>
          <AvatarContainer>
            <UserAvatar size="medium" hasBorder={false} user={currentUser} />
          </AvatarContainer>

          <FieldsContainer>
            <Field
              component={TextField}
              name="text"
              placeholder={`What do you have to say about ${receiverName}?`}
              multiline
              rows={3}
              validate={fieldRequired}
            />
          </FieldsContainer>
        </InputArea>
        <hr />
      </StyledForm>
    );
  };
}

TestimonialForm.propTypes = {
  invalid: PropTypes.bool,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  actionName: PropTypes.string,
  editingTestimonial: PropTypes.object,
  handleClose: PropTypes.func,
  receiverId: PropTypes.number,
  receiverName: PropTypes.string,
  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = () => ({});

const ConnectedTestimonialForm = connect(mapStateToProps, mapDispatchToProps)(TestimonialForm);

export default reduxForm({
  form: 'testimonialForm',
  onSubmitSuccess: (result, dispatch, props) => {
    const { onSuccessCallback } = props;
    onSuccessCallback();
  },
  onSubmitFail: () => {
    window.scrollTo(0, 0);
  },
})(ConnectedTestimonialForm);
