import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import EnrollButton from '~/app/program/components/SessionAttendeeList/EnrollButton';
import colors from '~/services/colors';
import UserAvatar from '~/app/shared/components/UserAvatar';

const ListItemContainer = styled.li`
  display: flex;
  justify-content: space-between;
  background: ${colors.neutral0};
  border-bottom: 1px solid ${colors.neutral100};
  padding: 12px 20px;
  position: relative;
  clear: both;
  overflow: hidden;

  &:last-child {
    overflow: hidden;
    border-bottom: none;
  }
`;

const ListUserContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: calc(100% - 228.5px);
`;

const StyledUserAvatar = styled(UserAvatar)`
  float: left;
  margin-right: 8px;
`;

const ListUserTexts = styled.div`
  display: inline-block;
  padding: 6px 0;
  max-width: 100%;
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;

const ListUserName = styled.span`
  font-size: 16px;
  line-height: 1.25;
  color: ${colors.neutral600};

  span {
    color: ${colors.neutral900};
  }
`;

const ListUserEmail = styled.span`
  display: block;
  font-size: 12px;
  line-height: 1.67;
  color: ${colors.neutral600};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 100%;
`;

const ActionButtons = styled.div`
  float: right;
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 16px;
  }
`;

export const ListItem = ({ session, user, onUserEnroll, isSessionAvailable }) => (
  <ListItemContainer>
    <ListUserContainer>
      <StyledUserAvatar size="medium" user={user} showPopover sendEmailCTA />
      <ListUserTexts>
        <ListUserName>{user.name}</ListUserName>
        {user.email && (
          <ListUserEmail alt={user.email}>
            <a href={`mailto:${user.email}`} target="_blank" rel="noopener noreferrer">
              {user.email}
            </a>
          </ListUserEmail>
        )}
      </ListUserTexts>
    </ListUserContainer>
    {isSessionAvailable && (
      <ActionButtons>
        <EnrollButton session={session} attendee={user} onUserEnroll={onUserEnroll} />
      </ActionButtons>
    )}
  </ListItemContainer>
);

ListItem.propTypes = {
  session: PropTypes.object,
  user: PropTypes.object,
  onUserEnroll: PropTypes.func,
  isSessionAvailable: PropTypes.bool,
};

export default ListItem;
