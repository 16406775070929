import { ReactNode } from 'react';

import confirmAlert from '~/services/confirm-alert';
import { Typography } from '~/common/components/Typography';

const handleRedirect = (redirect_location: string) => {
  // history.push does not work here because it does not refresh the page.
  // eslint-disable-next-line lodash/prefer-lodash-method
  return () => window.location.replace(redirect_location);
};

interface PermissionDeniedRedirectAlertProps {
  redirect_location?: string;
  title?: string;
  content?: ReactNode;
}

export const permissionDeniedRedirectAlert = ({
  redirect_location = '/',
  title = 'Permission denied',
  content = 'You do not have permission to access this content, so you will be redirected to the home page.',
}: PermissionDeniedRedirectAlertProps = {}) => {
  confirmAlert({
    title,
    content: () => <Typography variant="body1">{content}</Typography>,
    onConfirm: handleRedirect(redirect_location),
    onClose: handleRedirect(redirect_location),
    confirmLabel: 'Confirm',
    isDangerAction: true,
    hideSecondaryAction: true,
    closeOnBackdropClick: false,
    closeOnEsc: false,
  });
};
