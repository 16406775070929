import { ContentItem } from '~/app/shared-content-item/interfaces';
import { TableSelectionMode } from '~/app/shared/components/constants';
import { ActionCallback } from '~/app/shared/components/types';
import PeoplePicker from '~/app/users/components/PeoplePicker';
import { isNil, get, size, capitalize } from 'lodash-es';

import { ASSIGNMENT_FILTERS } from './constants';

interface SelectAssigneesModalProps {
  content: ContentItem;
  assignFilter: ASSIGNMENT_FILTERS;
  assignLabel?: string;
  isReAssign: boolean;
  handleClose: () => void;
  handleBack?: () => void;
  handleCancel?: () => void;
  actionButtonCallback: ActionCallback;
  defaultOrdering?: string;
  cancelLabel?: string;
}

const PAGE_SIZE = 5;
const ASSIGN_CACHE_KEY = 'selected_assigness_to_assign';
const REASSIGN_CACHE_KEY = 'selected_assigness_to_reassign';

const SelectAssigneesModal = ({
  content,
  assignFilter,
  assignLabel = 'Assign',
  isReAssign = false,
  handleClose,
  handleBack,
  handleCancel,
  actionButtonCallback,
  defaultOrdering = 'name',
  cancelLabel = 'Cancel',
}: SelectAssigneesModalProps) => {
  const fetchFiltersOptions = {
    initial_filters: ['name', 'email', 'title'],
    exclude_filters: ['eligible_for_assignment', 'eligible_for_reassignment'],
  };
  const fetchItemsOptions = {
    view_mode: 'roster',
    [assignFilter]: content.public_id,
  };
  const initialFiltersConfig = [{ name: null }, { email: null }, { title: null }];
  const assignTitle = capitalize(`${isReAssign ? 'Re/' : ''}${assignLabel}`);

  return (
    <PeoplePicker
      dialogTitle={`${assignTitle} - ${get(content, 'name', '')}`}
      defaultOrdering={defaultOrdering}
      pageSize={PAGE_SIZE}
      fetchItemsOptions={fetchItemsOptions}
      fetchFiltersOptions={fetchFiltersOptions}
      initialFiltersConfig={initialFiltersConfig}
      actionButtonLabel="Next"
      handleClose={handleClose}
      handleBack={handleBack}
      handleCancel={handleCancel}
      actionButtonCallback={actionButtonCallback}
      actionButtonIsDisabled={
        ({ selectedItems, rowCount, selectAll }) =>
          isNil(rowCount) || rowCount === 0 || (size(selectedItems) === 0 && !selectAll) // No data or none selected
      }
      enableSelectAll
      selectionMode={TableSelectionMode.multiple}
      cacheKey={isReAssign ? REASSIGN_CACHE_KEY : ASSIGN_CACHE_KEY}
      cancelLabel={cancelLabel}
    />
  );
};

export default SelectAssigneesModal;
