import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import OldSvgIcon from '~/app/shared/components/SvgIcon';

const Icon = styled.div`
  display: inline-flex;
`;

const OpenCloseIcon = ({ open }) => (
  <Icon
    tabIndex="-1"
    style={{
      transition: 'all .2s ease',
      transform: open ? 'rotate(180deg)' : null,
    }}
  >
    <OldSvgIcon.FatArrow fill={!open ? colors.neutral200 : null} width={14} height={14} />
  </Icon>
);

OpenCloseIcon.propTypes = {
  open: PropTypes.bool,
};

export default OpenCloseIcon;
