import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { EDIT_PROGRAM_PERMISSION } from '~/app/shared/permissions';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import actions from '~/app/entities/actions';
import { mentorshipProgramSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { permissionDeniedRedirectAlert } from '~/services/permission-denied-redirect-alert';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import { STATUS_LOADING, STATUS_DONE } from '~/app/shared/constants';
import {
  useOriginRoute,
  useSetDetailedObject,
  useLabels,
  usePublicIdFromURL,
} from '~/app/shared/hooks';
import { includes, toLower } from 'lodash-es';

import MentorshipProgramFormPage from './MentorshipProgramFormPage';

const MentorshipProgramEdit = () => {
  const { public_id_and_slug: publicIdAndSlug } = useParams();
  const [userCanSee, setUserCanSee] = useState(false);
  const { publicId } = usePublicIdFromURL();
  const {
    label_mentorship_program: labelProgram,
    label_mentorship_program_plural: labelProgramPlural,
  } = useLabels();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.mentorship_programs
  }&o=upcoming`;

  const programDetailRoute = mapRoute('mentorshipProgramDetails', {
    public_id_and_slug: publicIdAndSlug,
  });
  const backRoute = useOriginRoute(programDetailRoute);

  const [fetchProgram, { data: program, status }] = useEntities(
    actions.mentorshipProgram.retrieveDetails,
    ({ data: program, status }) => {
      if (status === STATUS_DONE) {
        if (includes(program.permissions, EDIT_PROGRAM_PERMISSION)) {
          setUserCanSee(true);
        } else {
          permissionDeniedRedirectAlert();
        }
      }
    },
    {
      schema: mentorshipProgramSchema,
    }
  );

  useEffect(() => {
    fetchProgram(publicId);
  }, []);

  useSetDetailedObject({ id: publicIdAndSlug }, 'programs', [publicIdAndSlug]);

  if (status === STATUS_LOADING || !userCanSee) return <Loading />;

  const initialValuesParams = {
    program: {
      ...program,
      attendance_limit: program.attendance_limit === 0 ? '' : program.attendance_limit,
    },
  };

  return (
    <MentorshipProgramFormPage
      pageTitle={`Edit ${labelProgram}`}
      topBarActionName={`Editing ${labelProgram}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelProgramPlural,
          link: catalogRoute,
        },
        {
          label: program.name,
          link: programDetailRoute,
        },
        {
          label: `Edit ${toLower(labelProgram)}`,
        },
      ]}
    />
  );
};

export default MentorshipProgramEdit;
