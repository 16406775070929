import * as React from 'react';
import { Box, Button } from '@mui/material';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { VisibilityIcon } from '~/vendor/mui-icons';

import { useComposableTrackCtx } from '../contexts/ComposableTrackCtx';
import { useTrackContentConsumptionCtx } from '../contexts/TrackContentConsumptionCtx';
import { useTrackStartContent } from '../hooks/useTrackStartContent';

import { TrackContentAdvanceAction } from './TrackContentAdvanceAction';
import { launchCourse } from '~/app/course/services';
import { to } from '~/common/helpers';
import { previewCodelabRequest } from '~/app/codelab/services';

type TrackItemPreviewActionsProps = {
  contentItem: ContentItem;
};

export function TrackItemPreviewActions(props: TrackItemPreviewActionsProps) {
  const { contentItem } = props;

  const { getSectionOrItemIndex, filterTrackItems } = useComposableTrackCtx();
  const { currentTrackItemContentType: contentType } = useTrackContentConsumptionCtx();
  const onStartTrackContent = useTrackStartContent();

  const [isLaunchingPreview, setIsLaunchingPreview] = React.useState(false);

  const handlePreviewTrack = () => {
    const trackItems = filterTrackItems(contentItem.public_id);
    const firstItem = trackItems[0];
    const firstItemIdx = getSectionOrItemIndex(
      contentItem.public_id,
      firstItem.content_item.public_id
    );

    onStartTrackContent({
      page: firstItemIdx + 1,
      preview: true,
    });
  };

  const handlePreviewCourse = async () => {
    setIsLaunchingPreview(true);

    await to(
      launchCourse({
        courseId: contentItem.public_id,
        isLaunchedInsideTrack: true,
        showPreview: true,
      })
    );

    setIsLaunchingPreview(false);
  };

  const handlePreviewCodelab = async () => {
    setIsLaunchingPreview(true);
    await to(previewCodelabRequest(contentItem.id));
    setIsLaunchingPreview(false);
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      {contentType === CONTENT_TYPES.course && (
        <Button
          onClick={handlePreviewCourse}
          startIcon={<VisibilityIcon />}
          disabled={isLaunchingPreview}
        >
          Preview
        </Button>
      )}

      {contentType === CONTENT_TYPES.codelab && (
        <Button
          onClick={handlePreviewCodelab}
          startIcon={<VisibilityIcon />}
          disabled={isLaunchingPreview}
        >
          Preview
        </Button>
      )}

      {contentType === CONTENT_TYPES.track && (
        <Button onClick={handlePreviewTrack} startIcon={<VisibilityIcon />}>
          Preview
        </Button>
      )}

      <TrackContentAdvanceAction contentItem={contentItem} />
    </Box>
  );
}
