import { useCurrentUser } from '~/app/shared/hooks';
import {
  Widget,
  WidgetContent,
  WidgetContentList,
  WidgetContentListItem,
  WidgetLinkAction,
} from '~/common/components/widget';
import { mapRoute } from '~/services/requests';
import { get, map, size } from 'lodash-es';
import { grey } from '@mui/material/colors';
import { Typography } from '~/common/components/Typography';
import { UsersWidgetRow, UsersWidgetLoadingContent } from '../components/BaseUsersAsyncWidget';
import { useUsersWidgetQuery } from '../hooks/useUsersWidgetQuery';

type MyDirectsWidgetActionsProps = {
  total: number;
  search: string;
};

function MyDirectsWidgetActions(props: MyDirectsWidgetActionsProps) {
  const { total, search } = props;

  const viewAllUrl = `${mapRoute('peopleList')}?${search}`;
  const manageUrl = `${mapRoute('myTeam')}`;
  const canViewAll = total > 3;

  return (
    <>
      <WidgetLinkAction label="Manage" url={manageUrl} arrow={!canViewAll} />
      {canViewAll && (
        <>
          <Typography color={grey[400]} sx={{ margin: '0px 10px' }}>
            {' '}
            |{' '}
          </Typography>
          <WidgetLinkAction label={'View All ' + total} url={viewAllUrl} />
        </>
      )}
    </>
  );
}

type MyDirectsWidgetProps = {
  hideOnEmpty?: boolean;
};

export function MyDirectsWidget(props: MyDirectsWidgetProps) {
  const { hideOnEmpty = false } = props;

  const currentUser = useCurrentUser();
  const search = `eq(org_lead,${get(currentUser, 'id')})&ordering(name)`;

  const { data, isLoading } = useUsersWidgetQuery(search);

  const results = get(data, 'results', []);
  const resultsTotal = get(data, 'total', 0);
  const displayResults = !isLoading && size(results) > 0;

  if (hideOnEmpty && (isLoading || !displayResults)) {
    return null;
  }

  return (
    <Widget
      title="My Directs"
      tooltip="People who report to me"
      action={!isLoading ? <MyDirectsWidgetActions total={resultsTotal} search={search} /> : null}
    >
      <WidgetContent>
        <WidgetContentList>
          {displayResults ? (
            map(results, (row) => (
              <WidgetContentListItem key={row.id}>
                <UsersWidgetRow user={row} />
              </WidgetContentListItem>
            ))
          ) : (
            <UsersWidgetLoadingContent animate={isLoading} />
          )}
        </WidgetContentList>
      </WidgetContent>
    </Widget>
  );
}
