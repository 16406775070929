import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useUrlHashState } from '~/common/hooks/useUrlHashState';
import colors from '~/services/colors';
import Clicker from '~/app/shared/components/Clicker';
import Icon from '~/app/shared/components/Icon';
import Text from '~/app/shared/components/Text';
import { Stack } from '@mui/material';
import ReactMarkdown from '~/vendor/react-markdown';

const TruncableReactMarkdown = styled(ReactMarkdown)`
  color: ${colors.neutral600};
  ${({ ellipsisOnOverflow }) =>
    ellipsisOnOverflow &&
    `> p {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `};
`;

const Header = styled(Clicker)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
`;

const Content = styled.div`
  padding: 0 20px 20px;
`;

const Actions = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const FormSectionContainer = ({ id, defaultOpen, title, description, children, actions }) => {
  const [isOpen, setOpen] = useState(defaultOpen);
  const [urlHash] = useUrlHashState();

  useEffect(() => {
    if (urlHash === id) {
      setOpen(true);
    }
  }, [urlHash, id]);

  return (
    <Container id={id}>
      <Stack spacing={1} px={2.5} py={2}>
        <Header onClick={() => setOpen((c) => !c)}>
          <Text size="h3" bold>
            {title}
          </Text>
          <Actions>
            {actions}
            {isOpen ? (
              <Icon name="up" width={16} height={16} color={colors.neutral900} />
            ) : (
              <Icon name="down" width={16} height={16} color={colors.neutral900} />
            )}
          </Actions>
        </Header>
        {Boolean(description) && (
          <Text>
            <TruncableReactMarkdown ellipsisOnOverflow={!isOpen} linkTarget="_blank">
              {description}
            </TruncableReactMarkdown>
          </Text>
        )}
      </Stack>
      {isOpen && <Content>{children}</Content>}
    </Container>
  );
};

FormSectionContainer.propTypes = {
  id: PropTypes.string,
  defaultOpen: PropTypes.bool,
  actions: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};

export default FormSectionContainer;
