import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import RecurrentDatesField, {
  validateRecurrentDates,
} from '~/app/inputs/components/RecurrentDatesField';
import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import Datetime from '~/app/shared/components/Datetime';
import HelpLink from '~/app/shared/components/HelpLink';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { get, map, pick, isEmpty } from 'lodash-es';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

const formName = 'sessionBulkCopyForm';

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SessionsContainer = styled.div`
  margin: 20px 0;
  max-height: 180px;
  overflow: auto;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HelpLinkWrapper = styled.div`
  margin-bottom: 20px;
`;

const selector = formValueSelector(formName);

const SessionBulkDuplicateModal = (props) => {
  const { session, handleClose, handleSubmit } = props;
  const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);

  const recurringDates = useSelector((state) => selector(state, 'recurring_dates'));

  const sessionStartsAt = moment(get(session, 'starts_at_tz_aware')).tz(get(session, 'timezone'));

  const startsAt = sessionStartsAt.set({
    hour: sessionStartsAt.get('hour'),
    minute: sessionStartsAt.get('minute'),
    second: sessionStartsAt.get('second'),
  });

  return (
    <Modal
      title="Bulk Duplicate"
      handleClose={handleClose}
      width={window.innerWidth > 500 ? 500 : null}
      minBodyHeight={200}
      /* We need this because of the Date popup v */
      overflow="visible"
    >
      <form>
        {!showRecurrenceModal ? (
          <ModalContainer>
            <ModalBody>
              <FieldsContainer>
                <Text block size="h4" color={colors.neutral900}>
                  You are bulk duplicating{' '}
                  <Text bold size="h4" color={colors.neutral900}>
                    {session.program_name || session.name}
                  </Text>
                </Text>
                <HelpLinkWrapper>
                  <HelpLink url="https://help.plusplus.app/en/articles/5506010-making-multiple-copies">
                    Learn more about Bulk Duplicates
                  </HelpLink>
                </HelpLinkWrapper>
                <Field
                  name="recurring_dates"
                  component={RecurrentDatesField}
                  startsAt={startsAt}
                  baseItemTime={{
                    hour: sessionStartsAt.get('hour'),
                    minute: sessionStartsAt.get('minute'),
                    second: sessionStartsAt.get('second'),
                  }}
                  locationId={session.location_id}
                  duration={session.duration}
                  itemLabel="session"
                  validate={[validateRecurrentDates]}
                  timezone={get(session, 'timezone')}
                />
              </FieldsContainer>
            </ModalBody>
            <ModalFooter variant="buttons">
              <ModalFooterButton type="button" color="secondary" onClick={handleClose}>
                Back
              </ModalFooterButton>
              <ModalFooterButton
                type="button"
                disabled={isEmpty(recurringDates)}
                onClick={() => setShowRecurrenceModal(true)}
              >
                Next...
              </ModalFooterButton>
            </ModalFooter>
          </ModalContainer>
        ) : (
          <ModalContainer>
            <ModalBody>
              <Text block size="h4" color={colors.neutral900}>
                You are bulk duplicating{' '}
                <Text bold size="h4" color={colors.neutral900}>
                  {session.program_name || session.name}
                </Text>
                . Here is the list of {recurringDates.length} sessions you will be creating:
              </Text>

              <SessionsContainer>
                <ul>
                  {map(recurringDates, (dateInfo) => {
                    const startsAt = get(dateInfo, 'starts_at_tz_aware');
                    return (
                      <li key={startsAt}>
                        <Text block size="h4" color={colors.neutral900}>
                          <Datetime
                            key={startsAt}
                            datetime={startsAt}
                            timezone={dateInfo.timezone}
                          />
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              </SessionsContainer>
              <Text block size="h4" color={colors.neutral900}>
                Each session will be editable individually.
              </Text>
            </ModalBody>
            <ModalFooter variant="buttons">
              <ModalFooterButton
                type="button"
                color="secondary"
                onClick={() => setShowRecurrenceModal(false)}
              >
                Back
              </ModalFooterButton>
              <ModalFooterButton type="button" onClick={() => handleSubmit()}>
                Save
              </ModalFooterButton>
            </ModalFooter>
          </ModalContainer>
        )}
      </form>
    </Modal>
  );
};

const ConnectedSessionBulkCopyModal = reduxForm({
  form: formName,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: (values, dispatch, { session }) => {
    return onSubmitActions(actions.session.bulkCopy.toString(), (values) => {
      const payload = {
        ...pick(values, ['recurring_dates']),
      };

      return {
        key: formName,
        body: { ...payload, session_id: session.id },
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result, dispatch, { handleConfirm }) => {
    handleConfirm?.();
    toast.success('Sessions copied successfully.');
    window.location.reload();
  },
  onSubmitFail: () => {
    toast.error('Submission failed, fix errors and try again.');
  },
})(SessionBulkDuplicateModal);

SessionBulkDuplicateModal.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  session: PropTypes.object,
};

export default ConnectedSessionBulkCopyModal;
