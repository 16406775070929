import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { MENTOR_PREFERENCE_TAG_TYPE, MENTEE_PREFERENCE_TAG_TYPE } from '~/app/mentorship/constants';
import colors from '~/services/colors';
import Pill from '~/app/shared/components/Pill';
import Text from '~/app/shared/components/Text';
import { useCurrentUser, useModuleToggles } from '~/app/shared/hooks';
import ContentDescription from '~/app/stand-alone-shared/components/ContentDescription';
import { getTagType, buildFlexibleTagsFieldNameList } from '~/app/topics/services';
import { map, isEmpty, isEqual, includes, filter } from 'lodash-es';
import { Typography } from '~/common/components/Typography';

const UserBioContainer = styled.div`
  margin: 25px 0 0;
  white-space: pre-wrap;
`;

const SkillsPillsContainer = styled.div`
  margin-top: 15px;
`;

const PillsContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 8px;
`;

const Label = styled.div`
  color: ${colors.neutral600};
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const UserInfo = ({ profileData }) => {
  const { skills, bio, searchable_bio } = profileData;

  const currentUser = useCurrentUser();
  const { custom_tags: customTags } = currentUser;

  const { mentorship: toggleMentorship, programs: toggleCoaching } = useModuleToggles();

  const toggleTypes = [
    ...(toggleMentorship ? ['toggle_mentorship'] : []),
    ...(toggleCoaching ? ['toggle_coaching'] : []),
  ];

  const flexibleFiltersWithoutMentorshipPreferences = filter(
    buildFlexibleTagsFieldNameList(currentUser, toggleTypes),
    (flexibleTagName) =>
      !includes([MENTOR_PREFERENCE_TAG_TYPE, MENTEE_PREFERENCE_TAG_TYPE], flexibleTagName)
  );

  const formattedSkills = Object.fromEntries(
    flexibleFiltersWithoutMentorshipPreferences.map((tagName) => [tagName, []])
  );

  if (!isEmpty(skills)) {
    map(skills, (skill) => {
      const skillType = getTagType(currentUser, skill);
      // Won't list tags that are not a flexible filter as to maintain consistency
      // with the filtering of mentorship
      if (includes(flexibleFiltersWithoutMentorshipPreferences, skillType)) {
        formattedSkills[skillType].push(skill);
      }
    });
  }
  const isUser = isEqual(profileData.id, currentUser.id);

  return (
    <div>
      <UserBioContainer>
        <Typography variant="h6" gutterBottom>
          Bio
        </Typography>
        <Text size="h4" block align="left">
          {searchable_bio && <ContentDescription description={bio} noPadding />}
          {!searchable_bio && isUser && (
            <p>
              Click the <i>Edit</i> button above to fill out your bio and let others get to know you
              better!
            </p>
          )}
        </Text>
      </UserBioContainer>
      <SkillsPillsContainer>
        {map(
          formattedSkills,
          (tags, tagType) =>
            !isEmpty(tags) && (
              <>
                <Label>
                  {
                    customTags.flexible_filter_tags?.filter(
                      (flexibleTag) => flexibleTag.filter_field_name === tagType
                    )[0]?.label
                  }
                </Label>
                <PillsContainer>
                  {map(tags, (tag, i) => (
                    <Pill key={i} icon="tag" label={tag} variant="lightGray" size="medium" />
                  ))}
                </PillsContainer>
              </>
            )
        )}
      </SkillsPillsContainer>
    </div>
  );
};

UserInfo.propTypes = {
  profileData: PropTypes.object,
};

export default UserInfo;
