import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { UNASSIGNED_STATUS } from '~/app/catalog/constants';
import { assignmentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { toast } from '~/app/notifications/components/NotificationCenter';
import SplitButton from '~/app/shared/components/SplitButton';
import { STATUS_ERROR } from '~/app/shared/constants';
import { useLabels } from '~/app/shared/hooks';
import actions from '~/app/entities/actions';
import { ENROLLMENT_STATUS_NOT_GOING } from '~/app/event-shared/constants';
import { getEventInterval } from '~/app/event-shared/services';
import { getErrorMessageFromResponse } from '~/services/requests';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import { isNil, isEmpty, toLower } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Box, Stack } from '@mui/material';
import ScheduleTrackEventReselect from './ScheduleTrackEventReselect';

const ModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 16px;
  }
`;

const UnenrollScheduledTrackEventModal = ({
  show,
  event,
  userTimezone,
  updateEnrollmentStatus,
  handleClose,
  scheduledTrackName,
  scheduledTrackId,
}) => {
  const { label_track: labelTrack } = useLabels();
  const scheduledTrackLabel = `scheduled ${toLower(labelTrack)}`;

  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventsCount, setEventsCount] = useState(0);
  const [updateAssignment] = useEntities(
    actions.contentAssignment.update,
    (response) => {
      if (response.status === STATUS_ERROR) {
        toast.error(
          `Error dropping from ${scheduledTrackLabel}`,
          getErrorMessageFromResponse(response)
        );
      }
    },
    assignmentSchema
  );

  if (!show) {
    return null;
  }

  const handleSwitchEvents = () => {
    updateEnrollmentStatus(ENROLLMENT_STATUS_NOT_GOING, { new_event_id: selectedEvent.public_id });
    handleClose();
  };

  const handleDropEvent = () => {
    updateEnrollmentStatus(ENROLLMENT_STATUS_NOT_GOING, {
      force: true,
      force_drop_even_if_scheduled_track: true,
    });
    handleClose();
  };

  if (isEmpty(event) || isEmpty(scheduledTrackName)) return null;

  const interval = getEventInterval(event, userTimezone);
  if (isNil(interval)) {
    return null;
  }

  const handleOnSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const handleDropScheduledTrack = () => {
    updateAssignment(scheduledTrackId, 'self', {
      assignment_status: UNASSIGNED_STATUS,
      force_drop_track_item: true,
    });
    handleDropEvent();
  };

  return (
    <Modal
      title="Drop or switch event"
      width={window.innerWidth > 768 ? 700 : 'auto'}
      maxWidth={700}
      handleClose={handleClose}
    >
      <ModalBody scrollable>
        <ModalBodyWrapper>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <>
              <Typography>
                This event is required to complete <b>{scheduledTrackName}</b> which you are
                enrolled at.
              </Typography>
              {eventsCount > 0 && (
                <Typography>
                  You may choose an alternative schedule for <b>{event.event_type?.name}</b>:
                </Typography>
              )}
            </>
          </Stack>
          <ScheduleTrackEventReselect
            event={event}
            setEventsCount={setEventsCount}
            onSelectEvent={handleOnSelectEvent}
          />
        </ModalBodyWrapper>
      </ModalBody>

      <ModalFooter variant="buttons">
        <Box>
          <ModalFooterButton
            color="neutral"
            variant="text"
            type="button"
            onClick={handleClose}
            alt="Cancel"
          >
            Cancel
          </ModalFooterButton>
        </Box>
        <Box sx={{ width: '100%', justifyContent: 'right', display: 'flex', gap: '12px' }}>
          <ModalFooterButton
            disabled={isEmpty(selectedEvent)}
            type="button"
            onClick={handleSwitchEvents}
            alt="Switch events"
          >
            Switch
          </ModalFooterButton>
          <SplitButton
            color="error"
            onClick={handleDropEvent}
            label="Drop from event"
            size="small"
            secondaryActionOptionList={[
              {
                color: 'error',
                size: 'small',
                label: `Drop from ${scheduledTrackLabel}`,
                onClick: handleDropScheduledTrack,
              },
            ]}
          />
        </Box>
      </ModalFooter>
    </Modal>
  );
};

UnenrollScheduledTrackEventModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,

  scheduledTrackId: PropTypes.number,
  scheduledTrackName: PropTypes.string,
  event: PropTypes.object,
  userTimezone: PropTypes.string,

  updateEnrollmentStatus: PropTypes.func,
};

export default UnenrollScheduledTrackEventModal;
