import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import colors from '~/services/colors';
import { EmbeddedVideoPlayer } from '~/services/videos';

const VideoPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: dashed 2px ${colors.action600};
  border-radius: 5px;
  width: 100%;
  height: 150px;
  padding: 1em;
`;

const PreviewText = styled.span`
  color: ${colors.neutral200};
`;

const PreviewWarning = styled.span`
  color: ${colors.error600};
`;

const VideoPreview = ({
  videoSource,
  onVideoDurationUpdate,
  height,
  width,
  warningMessage,
  previewMessage,
  isEmbeddedVideoFile,
}) => {
  React.useEffect(() => {
    if (onVideoDurationUpdate) {
      onVideoDurationUpdate(0); // reset duration on any videoSource change
    }
  }, [videoSource]);

  const handleVideoDuration = (duration) => {
    onVideoDurationUpdate(duration); // set duration on successful video load
  };

  const handleInvalidVideoLink = () => {
    onVideoDurationUpdate(0); // reset duration of unsuccessful video load (limited)
  };

  if (!videoSource) {
    return (
      <VideoPlaceholder>
        <PreviewText>
          {previewMessage || (
            <>
              Once you specify a valid <em>Video URL</em> from a supported video source, you will
              see a preview of it here. At that point we will automatically update its duration
              (below).
            </>
          )}
        </PreviewText>
      </VideoPlaceholder>
    );
  }
  if (!ReactPlayer.canPlay(videoSource) && !isEmbeddedVideoFile) {
    return (
      <VideoPlaceholder>
        <PreviewWarning>
          {warningMessage || (
            <>
              The specified <em>Video URL</em> points to an unsupported video source. Please check
              the URL to try again. Once a valid video is detected, you will see a preview of it
              here, and we will automatically update its duration (below).
            </>
          )}
        </PreviewWarning>
      </VideoPlaceholder>
    );
  }
  if (isEmbeddedVideoFile) {
    return <EmbeddedVideoPlayer url={videoSource} width={width} height={height} />;
  }
  return (
    <ReactPlayer
      url={videoSource}
      width={width}
      height={height}
      controls
      onDuration={onVideoDurationUpdate && handleVideoDuration}
      onError={onVideoDurationUpdate && handleInvalidVideoLink} // only invoked for unsupported video sources
    />
  );
};

VideoPreview.defaultProps = {
  height: '300px',
  width: '100%',
};

VideoPreview.propTypes = {
  videoSource: PropTypes.string,
  onVideoDurationUpdate: PropTypes.func,
  height: PropTypes.string,
  width: PropTypes.string,
  warningMessage: PropTypes.string,
  previewMessage: PropTypes.string,
  isEmbeddedVideoFile: PropTypes.bool,
};

export default VideoPreview;
