import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { getEventInterval } from '~/app/event-shared/services';
import InfoBox from '~/app/shared/components/InfoBox';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { useLabels } from '~/app/shared/hooks';
import { isNil, isEmpty, trim } from 'lodash-es';

import EventDateLocation from '../EventDateLocation';

const ModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 16px;
  }
`;

const RegistrationModal = ({
  show,
  status,
  event,
  userTimezone,
  updateEnrollmentStatus,
  handleClose,
}) => {
  const handleEnrollClick = () => {
    updateEnrollmentStatus(status);
    handleClose();
  };

  const { label_cta_additional_enrollment_instructions: additionalEnrollmentInstructionsCta } =
    useLabels();

  if (isEmpty(event)) return null;

  const interval = getEventInterval(event, userTimezone);
  if (isNil(interval)) {
    return null;
  }
  const { startsAt, endsAt, timezone } = interval;

  const buttonText = event.external_link ? additionalEnrollmentInstructionsCta : 'Enroll';

  if (!show) {
    return null;
  }

  return (
    <Modal title="Enroll" width={window.innerWidth > 768 ? 600 : 'auto'} handleClose={handleClose}>
      <ModalBody>
        <ModalBodyWrapper>
          <EventDateLocation
            timeslots={event.timeslots}
            eventStartsAt={startsAt}
            eventEndsAt={endsAt}
            displayTimezone={timezone}
            locationName={event.location_name}
          />
          <Text size="h4">
            Your enrollment is not confirmed until you’ve completed the final step.
          </Text>
          {!isEmpty(trim(event.external_link_description)) && (
            <InfoBox content={event.external_link_description} />
          )}
        </ModalBodyWrapper>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton type="button" onClick={handleEnrollClick} alt={buttonText}>
          {buttonText}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

RegistrationModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,

  event: PropTypes.object,
  userTimezone: PropTypes.string,

  status: PropTypes.string,
  updateEnrollmentStatus: PropTypes.func,
};

export default RegistrationModal;
