import colors from '~/services/colors';
import { FullTrackSection } from '~/app/tracks/interfaces';
import { getTrackSectionProgress } from '~/app/tracks/services';
import { isNil } from 'lodash-es';
import { CircularProgress } from '@mui/material';
import { CheckCircleIcon } from '~/vendor/mui-icons';

interface TrackSectionCircularProgressProps {
  section: FullTrackSection;
  userIsAssignedToTrack: boolean;
}

export const TrackSectionCircularProgress = ({
  section,
  userIsAssignedToTrack,
}: TrackSectionCircularProgressProps) => {
  const sectionProgress = getTrackSectionProgress(section);

  if (!userIsAssignedToTrack || isNil(sectionProgress)) {
    return null;
  }

  if (sectionProgress === 100) {
    return <CheckCircleIcon sx={{ color: colors.success600 }} />;
  }

  if (sectionProgress > 0) {
    return <CircularProgress variant="determinate" size={18} value={sectionProgress} />;
  }

  return null;
};
