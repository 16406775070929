import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { LEARNING_TYPES } from '~/app/catalog/constants';
import { PublicIdParam } from '~/common/types';
import actions from '~/app/entities/actions';
import { contentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import Page404 from '~/scenes/404';
import { permissionDeniedRedirectAlert } from '~/services/permission-denied-redirect-alert';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE, HTTP_404_NOT_FOUND, STATUS_LOADING } from '~/app/shared/constants';
import { useOriginRoute, useSetDetailedObject, useLabels } from '~/app/shared/hooks';
import { CONTENT_ITEM_PERMISSIONS } from '~/app/shared/permissions';
import { includes, toLower } from 'lodash-es';

import ScheduledTrackFormPage from './ScheduledTrackFormPage';

const ScheduledTrackEdit = () => {
  const { label_track: labelTrack, label_track_plural: labelTrackPlural } = useLabels();
  const { public_id: publicId } = useParams<PublicIdParam>();

  const [userCanSee, setUserCanSee] = useState(false);

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.tracks
  }&o=upcoming`;
  const scheduledTrackDetailRoute = mapRoute('scheduledTrackDetails', {
    public_id_and_slug: publicId,
  });
  const backRoute = useOriginRoute(scheduledTrackDetailRoute);

  const [fetchScheduledTrack, { data: scheduledTrack, status, statusCode }] = useEntities(
    actions.content.retrieveDetails,
    ({ data: scheduledTrack, status }) => {
      if (status === STATUS_DONE) {
        if (includes(scheduledTrack.permissions, CONTENT_ITEM_PERMISSIONS.manage)) {
          setUserCanSee(true);
        } else {
          permissionDeniedRedirectAlert();
        }
      }
    },
    {
      schema: contentSchema,
    }
  );

  useSetDetailedObject({ id: publicId }, 'scheduled_tracks', [publicId]);

  useEffect(() => {
    fetchScheduledTrack(publicId, { view_mode: 'detail' });
  }, [fetchScheduledTrack, publicId]);

  if (statusCode === HTTP_404_NOT_FOUND) return <Page404 />;
  if (status === STATUS_LOADING || !userCanSee) return <Loading />;
  return (
    <ScheduledTrackFormPage
      pageTitle={`Edit Scheduled ${labelTrack}`}
      topBarActionName={`Editing Scheduled ${labelTrack}`}
      backRoute={backRoute}
      initialValuesParams={scheduledTrack}
      breadcrumbsItemList={[
        {
          label: labelTrackPlural,
          link: catalogRoute,
        },
        {
          label: scheduledTrack.name,
          link: scheduledTrackDetailRoute,
        },
        {
          label: `Edit scheduled ${toLower(labelTrack)}`,
        },
      ]}
    />
  );
};

export default ScheduledTrackEdit;
