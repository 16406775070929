import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';

const StyledFieldError = styled.div`
  color: ${colors.error600};
  font-size: 14px;
  margin-top: 4px;
`;

const FieldError = ({ meta: { touched, error, submitFailed } }) => {
  if (!error) {
    return null;
  }

  if (!touched && !submitFailed) {
    return null;
  }

  return <StyledFieldError>{error}</StyledFieldError>;
};

FieldError.propTypes = {
  meta: PropTypes.object,
};

export default FieldError;
