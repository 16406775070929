import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Clicker from '~/app/shared/components/Clicker';
import Icon from '~/app/shared/components/Icon';

const colorTypes = {
  primary: {
    color: colors.action600,
    iconHoverColor: colors.action600TextColor,
  },
  error: {
    color: colors.error600,
    iconHoverColor: colors.error600TextColor,
  },
};

const SquaredClicker = styled(Clicker)`
  display: flex;
  justify-content: center;
  padding: 6px;
  border-radius: 2px;
  border: solid 2px ${({ type }) => colorTypes[type].color};
  transition: 0.2s all linear;

  svg path {
    fill: ${({ type }) => colorTypes[type].color};
  }

  &[disabled] {
    border-color: ${colors.neutral500};
    cursor: not-allowed;

    svg path {
      fill: ${colors.neutral500};
    }
  }

  &:hover,
  &:focus {
    background-color: ${({ type, disabled }) =>
      disabled ? 'transparent' : colorTypes[type].color};

    svg path {
      fill: ${({ type, disabled }) =>
        disabled ? colors.neutral500 : colorTypes[type].iconHoverColor};
    }
  }
`;

const SquareIconButton = ({ title, iconName, onClick, type, width, height, disabled }) => {
  return (
    <SquaredClicker title={title} onClick={onClick} type={type} disabled={disabled}>
      <Icon name={iconName} width={width} height={height} />
    </SquaredClicker>
  );
};

SquareIconButton.defaultProps = {
  type: 'primary',
};

SquareIconButton.propTypes = {
  title: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'error']),
};

export default SquareIconButton;
