import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Icon from '~/app/shared/components/Icon';
import Text from '~/app/shared/components/Text';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #f3e8fd;
  border-radius: 16px;
  vertical-align: middle;

  ${({ marginLeft }) => marginLeft && `margin-left: 6px;`}
`;

const StyledText = styled(Text)`
  display: none;
  padding-right: 8px;
  font-weight: 500;
`;

const StyledIcon = styled(Icon)`
  &:hover ~ ${StyledText} {
    display: inline-block;
  }
`;

const OutsideLearningHoursBadge = ({ marginLeft }) => {
  return (
    <Container marginLeft={marginLeft}>
      <StyledIcon name="moon" height={23} width={23} color="#681da8" />
      <StyledText>Outside learning hours</StyledText>
    </Container>
  );
};

OutsideLearningHoursBadge.propTypes = {
  marginLeft: PropTypes.bool,
};

OutsideLearningHoursBadge.defaultProps = {
  marginLeft: true,
};

export default OutsideLearningHoursBadge;
