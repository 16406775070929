import { CONTENT_TYPES } from '~/app/catalog/constants';
import rql from '~/vendor/rql';

interface DefaultCatalogFiltersQuerystringProps {
  selectedContentTypes?: string[];
  pageSize?: number;
  status?: 'published' | 'archived';
}

export const getDefaultCatalogFiltersQuerystring = ({
  selectedContentTypes = [],
  pageSize = 24,
  status,
}: DefaultCatalogFiltersQuerystringProps): string => {
  return rql({
    is_inline: false,
    page_size: pageSize,
    view_mode: 'catalog',
    status,
    content_type: {
      $in: selectedContentTypes,
      $out: [
        CONTENT_TYPES.event,
        CONTENT_TYPES.task,
        CONTENT_TYPES.scheduled_track,
        CONTENT_TYPES.question,
        CONTENT_TYPES.text_question,
        CONTENT_TYPES.multiple_choice_question,
      ],
    },
  });
};
