import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Announcement from '~/app/announcements/components/Announcement';
import actions from '~/app/entities/actions';
import { announcementsSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { getClosedAnnouncements } from '~/services/closed-announcements';
import { STATUS_DONE } from '~/app/shared/constants';
import { useCurrentUser } from '~/app/shared/hooks';
import { filter, includes, isEmpty } from 'lodash-es';

const AnnouncementsWrapper = styled.div`
  width: 100%;
`;

const Announcements = () => {
  const [fetchAnnouncements, { data: announcements, status: fetchAnnouncementsStatus }] =
    useEntities(actions.announcements.retrieveList, null, {
      schema: [announcementsSchema],
    });

  const currentUser = useCurrentUser();

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const [closedAnnouncements, setClosedAnnouncements] = useState([]);

  useEffect(() => {
    setClosedAnnouncements(getClosedAnnouncements(currentUser.id));
  }, [currentUser.id]);

  const notClosedAnnouncements = filter(
    announcements,
    (announcement) => !includes(closedAnnouncements, announcement.id)
  );

  return (
    <AnnouncementsWrapper>
      {currentUser.id &&
        fetchAnnouncementsStatus === STATUS_DONE &&
        !isEmpty(notClosedAnnouncements) && (
          <Announcement
            key={notClosedAnnouncements[0].id}
            announcement={notClosedAnnouncements[0]}
            refreshAnnouncements={() => {
              setClosedAnnouncements(getClosedAnnouncements(currentUser.id));
            }}
          />
        )}
    </AnnouncementsWrapper>
  );
};

export default Announcements;
