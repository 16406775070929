import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SearchInput from '~/app/inputs/components/SearchInput';
import MediaPoint from '~/app/shared/components/MediaPoint';
import { debounce, get, isEqual } from 'lodash-es';

const FilterPageSearchWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  float: left;

  i {
    position: absolute;
    left: 0;
    top: 0;
    padding: 8px;
    height: 32px;
    width: 32px;
    font-size: 16px;
    line-height: 1.1;
  }

  ${MediaPoint.Tablet} {
    width: 400px;
  }

  ${MediaPoint.DesktopLg} {
    width: 583px;
  }

  ${MediaPoint.DesktopXl} {
    width: 797px;
  }
`;

class OldFilterPageSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentText: '',
    };

    this.debouncedOnChange = debounce(props.onChange, 700);
  }

  componentDidUpdate = (prevProps) => {
    const { selecteds, param } = this.props;
    const filterHasChanged = !isEqual(get(selecteds, param), get(prevProps.selecteds, param));
    if (filterHasChanged) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentText: get(selecteds, param, '') });
    }
  };

  handleChange = (newText) => {
    const { selecteds, param } = this.props;

    this.setState({ currentText: newText });

    this.debouncedOnChange({
      ...selecteds,
      [param]: newText,
    });
  };

  clearField = () => {
    const { selecteds, param, onChange } = this.props;

    this.setState({ currentText: '' });

    onChange({
      ...selecteds,
      [param]: '',
    });
  };

  render() {
    const { placeholder, disabled } = this.props;
    const { currentText } = this.state;
    return (
      <FilterPageSearchWrapper>
        <SearchInput
          onChange={this.handleChange}
          placeholder={placeholder}
          value={currentText}
          disabled={disabled}
        />
      </FilterPageSearchWrapper>
    );
  }
}

OldFilterPageSearch.propTypes = {
  param: PropTypes.string,
  placeholder: PropTypes.string,
  selecteds: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default OldFilterPageSearch;
