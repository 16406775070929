import PropTypes from 'prop-types';

import BaseFeedbackContainer from '~/app/stand-alone-shared/components/FeedbackContainer/BaseFeedbackContainer';

export const Reviews = ({ title, feedbackRatings, feedbackList }) => {
  return (
    <BaseFeedbackContainer
      mode="compact"
      containerTitle={title}
      content={feedbackRatings}
      feedbackList={feedbackList}
      fillContainer
    />
  );
};

Reviews.propTypes = {
  title: PropTypes.string,
  feedbackRatings: PropTypes.object,
  feedbackList: PropTypes.array,
};

Reviews.defaultProps = {
  title: 'Feedback',
};
