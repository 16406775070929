import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import MUITypography from '@mui/material/Typography';
import React, { useState } from 'react';

import { useEllipsisCheck } from '~/app/shared/hooks';

interface CollapsableTypographyProps extends React.ComponentProps<typeof MUITypography> {
  collapsedSize?: number;
  ellipsisProps?: {
    lineHeight?: string;
    overflow?: string;
    maxHeight?: string;
    noWrap?: boolean;
  };
}

const CollapsableTypography = ({
  collapsedSize = 80,
  ellipsisProps = {},
  ...props
}: CollapsableTypographyProps) => {
  const { hasEllipsis, nodeRef } = useEllipsisCheck({ multipleLines: true });

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [typographyEllipsisProps, setTypographyEllipsisProps] = useState(ellipsisProps);

  const boxSx =
    !isCollapsed && hasEllipsis
      ? {
          display: 'flex',
          alignItems: 'end',
          maskImage: 'linear-gradient(to bottom, black 50%, transparent)',
        }
      : {};

  return (
    <>
      <Collapse
        in={isCollapsed}
        collapsedSize={collapsedSize}
        onEnter={() => setTypographyEllipsisProps({})}
        onExited={() => setTypographyEllipsisProps(ellipsisProps)}
      >
        <Box sx={boxSx}>
          <MUITypography ref={nodeRef} {...props} {...typographyEllipsisProps} />
        </Box>
      </Collapse>
      {hasEllipsis && (
        <Button size="small" onClick={() => setIsCollapsed(!isCollapsed)} sx={{ marginTop: -2 }}>
          {isCollapsed ? 'View less' : 'View more'}
        </Button>
      )}
    </>
  );
};

export interface TypographyProps extends React.ComponentProps<typeof MUITypography> {
  sx?: SxProps;
  maxLines?: number;
  lineHeight?: number;
  lineHeightUnity?: string;
  collapsable?: boolean;
  collapsedSize?: number;
  component?: any;
}

export const Typography = ({
  sx,
  noWrap,
  maxLines,
  component,
  lineHeight = 1.43,
  lineHeightUnity = 'em',
  collapsable = false,
  collapsedSize = 80,
  ...props
}: TypographyProps) => {
  const [extraProps, addEllipsis] =
    noWrap && maxLines
      ? [
          {
            lineHeight: lineHeight + lineHeightUnity,
            overflow: 'hidden',
            maxHeight: lineHeight * maxLines + lineHeightUnity,
          },
          true,
        ]
      : [{ noWrap }, false];

  if (!addEllipsis && !collapsable) {
    return <MUITypography sx={sx} component={component} {...props} {...extraProps} />;
  }

  if (collapsable) {
    return (
      <CollapsableTypography
        sx={sx}
        ellipsisProps={extraProps}
        collapsedSize={collapsedSize}
        {...props}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'end',
        maskImage: 'linear-gradient(to bottom, black 50%, transparent)',
      }}
    >
      <MUITypography sx={sx} component={component} {...props} {...extraProps} />
    </Box>
  );
};
