import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AVATAR_SIZES } from '~/app/shared/constants';
import { mapRoute } from '~/services/requests';
import UserBadge from '~/app/shared/components/UserBadge';
import { useToggles } from '~/app/shared/hooks';
import { get, keys } from 'lodash-es';

import ProfileImage from './ProfileImage';

const AvatarImageContainer = styled.div`
  display: inline-block;
  border-radius: 100%;
  min-width: ${(props) => props.size || 56}px;
  height: ${(props) => props.size || 56}px;
  vertical-align: middle;
  position: relative;
`;

const StyledUserBadge = styled(UserBadge)`
  position: absolute;
  top: ${(props) => props.badgeTop || 75}%;
  left: ${(props) => props.badgeLeft || 75}%;
`;

const UserAvatarImage = ({
  user,
  badgeLeft,
  badgeSize,
  badgeTop,
  className,
  disableProfileLink,
  hasBorder,
  imgGrayscale,
  showBadge,
  size,
}) => {
  const { toggle_user_badges: toggleBadge } = useToggles();

  const imageSrc = user.profile_image;
  const userName = user.display_name;
  const badgeSrc = get(user, 'badges.0.image', null);
  const route = user.id ? mapRoute('userProfile', { id: user.id }) : null;

  const LinkComponent = !disableProfileLink ? Link : React.Fragment;

  const getAvatarSize = () => {
    return AVATAR_SIZES[size] ?? AVATAR_SIZES.small;
  };
  return (
    <AvatarImageContainer className={className} size={getAvatarSize()}>
      <LinkComponent {...(!disableProfileLink && { to: route })}>
        <ProfileImage
          imageSrc={imageSrc}
          imgGrayscale={imgGrayscale}
          title={userName}
          alt={userName}
          userName={userName}
          hasBorder={hasBorder}
          size={size}
        />

        {showBadge && toggleBadge && (
          <StyledUserBadge
            image={badgeSrc}
            badgeTop={badgeTop}
            badgeLeft={badgeLeft}
            size={badgeSize}
          />
        )}
      </LinkComponent>
    </AvatarImageContainer>
  );
};

UserAvatarImage.defaultProps = {
  disableProfileLink: false,
};

UserAvatarImage.propTypes = {
  user: PropTypes.object,

  badgeLeft: PropTypes.number,
  badgeSize: PropTypes.string,
  badgeTop: PropTypes.number,
  className: PropTypes.string,
  disableProfileLink: PropTypes.bool,
  hasBorder: PropTypes.bool,
  imgGrayscale: PropTypes.bool,
  showBadge: PropTypes.bool,
  size: PropTypes.oneOf(keys(AVATAR_SIZES)),
};

export default UserAvatarImage;
