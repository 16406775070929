/* eslint-disable promise/always-return */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SessionCardButtons from '~/app/program/components/SessionCardButtons';
import SessionModalManager, {
  useSessionModalManagerState,
} from '~/app/program/components/SessionModalManager';
import { useSessionEnrollmentManager } from '~/app/program/services';
import colors from '~/services/colors';
import { useCurrentUser } from '~/app/shared/hooks';
import { get, isEmpty } from 'lodash-es';

import SessionInfo from './SessionInfo';
import SessionSettings from './SessionSettings';

const SessionDetailContainer = styled.div`
  padding: 12px 16px;
  position: relative;
  display: flex;
  background-color: ${colors.neutral0};
  flex-direction: column;
  justify-content: space-between;
  height: inherit;

  & + & {
    border-top: 1px solid ${colors.neutral100};
  }

  &:hover {
    background-color: ${colors.neutral50};
  }
`;

export const SessionDetail = ({
  onEnrollmentDelete,
  session,
  showDate,
  showProgramText,
  componentName,
}) => {
  const currentUser = useCurrentUser();
  const {
    modalManagerState,
    modalManagerSetters,
    openDetailsModal,
    openRosterModal,
    openFeedbackModal,
  } = useSessionModalManagerState();

  const { submitEnrollment, dropSession } = useSessionEnrollmentManager(session, {
    onDelete: onEnrollmentDelete,
  });

  const sessionIsBooked = !isEmpty(get(session, 'attendees'));

  const sessionAttendanceLimit = session.attendance_limit;
  const sessionEnrollmentsCount = session.session_enrollments_count;
  const sessionSpotsLeft = sessionAttendanceLimit - sessionEnrollmentsCount;

  return (
    <SessionDetailContainer>
      <div>
        <SessionInfo
          session={session}
          currentUser={currentUser}
          showDate={showDate}
          showProgramText={showProgramText}
          sessionIsBooked={sessionIsBooked}
          hasProgram={Boolean(session.program_id)}
          sessionAttendanceLimit={sessionAttendanceLimit}
          sessionEnrollmentsCount={sessionEnrollmentsCount}
          sessionSpotsLeft={sessionSpotsLeft}
          componentName={componentName}
        />

        <SessionSettings
          session={session}
          onDeleteSession={dropSession}
          onShowRoster={openRosterModal}
          onClickOpenDetails={openDetailsModal}
        />
      </div>

      <div>
        <SessionCardButtons
          session={session}
          submitEnrollment={submitEnrollment}
          sessionIsBooked={sessionIsBooked}
          sessionSpotsLeft={sessionSpotsLeft}
          onClickOpenDetails={openDetailsModal}
          onClickOpenFeedback={openFeedbackModal}
        />
      </div>

      <SessionModalManager
        session={session}
        state={modalManagerState}
        setters={modalManagerSetters}
        handleDropSession={dropSession}
      />
    </SessionDetailContainer>
  );
};

SessionDetail.propTypes = {
  session: PropTypes.object,
  showDate: PropTypes.bool,
  showProgramText: PropTypes.bool,
  onEnrollmentDelete: PropTypes.func,
  componentName: PropTypes.string,
};

SessionDetail.defaultProps = {
  showDate: false,
  showProgramText: false,
};

export default SessionDetail;
