import PropTypes from 'prop-types';
import React from 'react';

import { toast } from '~/app/notifications/components/NotificationCenter';
import { PUBLIC_REPORT_URLS } from '~/scenes/Dashboard/constants';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import { getUrlParser } from '~/services/utils';
import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import { useCopyToClipboard } from '~/app/shared/hooks';
import { has } from 'lodash-es';

const ShareSegmentButton = ({ segment }) => {
  const { public_id, content_type } = segment;
  const { copyToClipboard: originalCopyToClipboard } = useCopyToClipboard(5000);
  const { trackActivity } = useMetrics();

  const trackReportSharing = (publicId, contentType) => {
    trackActivity(METRICS_ACTIVITIES.REPORT_SHARED, {
      segmentPublicId: publicId,
      contentType: contentType,
    });
  };

  const handleCopyToClipboard = (publicId, contentType) => {
    if (!has(PUBLIC_REPORT_URLS, contentType)) {
      toast.error('This content type is not supported for sharing');
      return;
    }

    const url = PUBLIC_REPORT_URLS[contentType];
    const finalUrl = mapRoute(url, { public_id: publicId });
    const host = getUrlParser(window.location.href).host;

    originalCopyToClipboard(`${host}${finalUrl}`);
    trackReportSharing(publicId, contentType);

    toast.success('Link copied to clipboard');
  };

  return (
    <Button
      variant="text"
      startIcon={<Icon name="share" />}
      aria-label="Share"
      onClick={() => handleCopyToClipboard(public_id, content_type)}
    >
      Share
    </Button>
  );
};

ShareSegmentButton.propTypes = {
  segment: PropTypes.object,
};

export default ShareSegmentButton;
