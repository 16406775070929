import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { eventSchema, sessionSchema, userProfileSchema } from '~/app/entities/schema';
import { getDataFromState, useEntities } from '~/app/entities/utils';
import UserContentBlock from '~/app/users/components/UserContentBlock';
import { IdParam } from '~/common/types';
import actions from '~/app/entities/actions';
import { RESTAPIRequest } from '~/types/generic-types';
import { Grid } from '@mui/material';
import UserDetails from './UserDetails';
import UserTabs from './UserTabs';
import ContactInfo from './UserTabs/ContactInfo';

interface UserProfileRequest extends RESTAPIRequest {
  data: Record<string, unknown>;
}

export const Profile = () => {
  const { id: userId } = useParams<IdParam>();
  const { data: profileData }: UserProfileRequest = useSelector(
    (state) =>
      getDataFromState(`userProfile${userId}`, state, userProfileSchema) as UserProfileRequest
  );

  const [fetchSessionMentorship, { data: sessionsList }] = useEntities(
    actions.sessionMentorship.retrieveList,
    null,
    { schema: [sessionSchema] }
  );

  const [fetchUpcomingEvents, { data: presentingEventsList }] = useEntities(
    actions.event.retrieveList,
    null,
    { schema: [eventSchema] }
  );

  const [fetchPastEvents, { data: presentedEventsList }] = useEntities(
    actions.event.retrieveList,
    null,
    { schema: [eventSchema] }
  );

  const fetchPresentingEvents = () => {
    fetchUpcomingEvents(
      {
        presenters: [userId],
        period: 'upcoming',
        o: 'date',
        page_size: 1000, // use max page size, after all we're using fetchAll: true
        view_mode: 'lite',
      },
      { fetchAll: true }
    );
  };

  const fetchPresentedEvents = () => {
    fetchPastEvents(
      {
        presenters: [userId],
        period: 'previous',
        o: '-date',
        page_size: 1000, // use max page size, after all we're using fetchAll: true
        view_mode: 'lite',
      },
      { fetchAll: true }
    );
  };

  const fetchMentorshipSessions = () => {
    fetchSessionMentorship(
      {
        host: userId,
        available: true,
        page_size: 1000, // use max page size, after all we're using fetchAll: true
      },
      { fetchAll: true }
    );
  };

  const handleEnrollmentDelete = () => {
    fetchMentorshipSessions();
  };

  useEffect(() => {
    fetchPresentingEvents();
    fetchMentorshipSessions();
    fetchPresentedEvents();
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid item xs={4} sm={4} md={8}>
        <UserDetails profileData={profileData} />
      </Grid>
      <Grid item xs={4} sm={4} md={4}>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={8} md={12}>
            <UserContentBlock sx={{ padding: 0, marginBottom: 0 }}>
              <ContactInfo profileData={profileData} />
            </UserContentBlock>
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <UserContentBlock sx={{ padding: 0 }}>
              <UserTabs
                sessions={sessionsList}
                events={presentingEventsList}
                pastEvents={presentedEventsList}
                profileData={profileData}
                onEnrollmentDelete={handleEnrollmentDelete}
              />
            </UserContentBlock>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
