import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import actions from '~/app/entities/actions';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { scrollToTop } from '~/services/utils';
import SurveyQuestionField from '~/app/surveys/components/SurveyQuestionField';
import { SurveyQuestionKind } from '~/app/surveys/constants';
import surveyProptypes from '~/app/surveys/types';
import {
  find,
  forEach,
  isEmpty,
  isNil,
  map,
  mapKeys,
  mapValues,
  isInteger,
  includes,
  get,
} from 'lodash-es';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

// From https://codereview.stackexchange.com/a/115887

// From https://codereview.stackexchange.com/a/115887
const getIdfromFieldKey = (key) => map(key.match(/\d+/g), Number)[0];

const validate = (values, { questions }) => {
  const errors = {};

  const requiredAnswerText = 'This answer is required';
  const otherOptionAnswerText = 'Please specify an answer for the other option.';

  forEach(values, (value, key) => {
    const fieldId = getIdfromFieldKey(key);
    const question = find(questions, ['id', fieldId]);

    // isOther bool is used to trigger the validation when the other option is checked and its related text is empty
    const questionHasOtherValue = get(value, 'isOther');
    const questionWithOtherValue = get(value, 'value');

    if (question.is_required) {
      if (
        question.kind === SurveyQuestionKind.SINGLE_CHOICE_AND_OTHER &&
        isEmpty(questionWithOtherValue)
      ) {
        errors[key] = requiredAnswerText;
      }

      if (
        question.kind === SurveyQuestionKind.MULTIPLE_CHOICE_AND_OTHER &&
        (isEmpty(questionWithOtherValue) || includes(questionWithOtherValue, ''))
      ) {
        errors[key] = requiredAnswerText;
      }

      if ((!isInteger(value) && isEmpty(value)) || isNil(value)) {
        errors[key] = requiredAnswerText;
      }
    } else {
      if (
        question.kind === SurveyQuestionKind.SINGLE_CHOICE_AND_OTHER &&
        questionHasOtherValue &&
        isEmpty(questionWithOtherValue)
      ) {
        errors[key] = otherOptionAnswerText;
      }

      if (
        question.kind === SurveyQuestionKind.MULTIPLE_CHOICE_AND_OTHER &&
        questionHasOtherValue &&
        (isEmpty(questionWithOtherValue) || includes(questionWithOtherValue, ''))
      ) {
        errors[key] = otherOptionAnswerText;
      }
    }
  });

  return errors;
};

const transform =
  ({ surveyRelationshipId }) =>
  (values) => {
    let answers = mapKeys(values, (value, key) => getIdfromFieldKey(key));
    answers = mapValues(answers, (value) => {
      // If question has other option, get the actual answer value from the object
      if (isNil(value) || isNil(value.isOther)) {
        return value;
      }
      return get(value, 'value');
    });
    const payload = {
      answers,
    };

    return {
      key: 'surveyAnswerForm',
      id: surveyRelationshipId,
      body: payload,
    };
  };

const SurveyAnswerForm = ({ questions }) => (
  <div>
    {map(questions, (question) => (
      <Field
        key={question.id}
        name={`question-${question.id}`}
        question={question}
        component={SurveyQuestionField}
      />
    ))}
  </div>
);

SurveyAnswerForm.propTypes = {
  questions: PropTypes.arrayOf(surveyProptypes.surveyQuestion),
};

export default reduxForm({
  form: 'surveyAnswerForm',
  onSubmit: (values, dispatch, props) =>
    onSubmitActions(actions.userSurvey.create.toString(), transform(props))(values, dispatch),
  onSubmitSuccess: (_result, dispatch, { onFormSubmitSuccess }) => {
    onFormSubmitSuccess();
    toast.success('Survey successfully answered!');
  },
  onSubmitFail: () => {
    toast.error('Submission failed, fix errors and try again.');
    scrollToTop();
  },
  validate,
})(SurveyAnswerForm);
