import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import DocsModal from '~/app/shared-modals/components/DocsModal';
import Text from '~/app/shared/components/Text';

const ContentBody = styled.div`
  > * + * {
    margin-top: 8px;
  }
`;

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  background-color: ${colors.neutral50};
  border: 1px solid ${colors.neutral200};
  a {
    color: ${colors.action600};
    &:hover {
      color: ${colors.action700};
      text-decoration: underline;
    }
  }
`;

const ShareHTML5Video = () => {
  return (
    <ContentBody>
      <Text color={colors.neutral600} block>
        The HTML5-compatible video file needs to be hosted on your or a 3rd-party server. We
        recommend exporting the video as streaming-friendly H.264 (AVC) MP4 file for best browser
        compatibility.
      </Text>
      <Text color={colors.neutral600} block>
        Please make sure the video is accessible to the people in your organization.
      </Text>
      <InfoBox>
        <Text size="h5" color={colors.neutral900}>
          <strong>Need more info?</strong> Please visit&nbsp;
          <a href="https://en.wikipedia.org/wiki/HTML5_video#Browser_support">
            HTML5 video - Wikipedia
          </a>
        </Text>
      </InfoBox>
    </ContentBody>
  );
};

const ShareThirdrdPartyVideo = () => {
  return (
    <ContentBody>
      <Text color={colors.neutral600} block>
        To share a video from a 3rd party service, such as YouTube, Vimeo, Wistia, or Vidyard simply
        copy and paste the video URL from your browser bar. We will take care of the rest.
      </Text>
    </ContentBody>
  );
};

const SharePartOfYTVideo = () => {
  return (
    <ContentBody>
      <Text color={colors.neutral600} block>
        Access the YouTube Video URL, click &quot;Share&quot;, select &quot;Start at&quot; to define
        the video start time. Click &quot;Copy&quot; and paste it on the &quot;Video URL&quot;
        field.
      </Text>
      <InfoBox>
        <Text size="h5" color={colors.neutral900}>
          <strong>Need more info?</strong>&nbsp;Please visit&nbsp;
          <a href="https://www.lifewire.com/youtube-link-to-specific-time-3486675">
            How to Share a Specific Time in a YouTube Video
          </a>
        </Text>
      </InfoBox>
    </ContentBody>
  );
};

const EmbeddedVideoLimitedCapabilitiesNote = () => {
  return (
    <Text color={colors.neutral600} block>
      Note that the following capabilities will not be available:
      <ol>
        <li>Auto-detecting video duration on creation.</li>
        <li>Auto-assigning on playback.</li>
        <li>Auto-resuming on return.</li>
        <li>Auto-completing on playback past certain threshold.</li>
      </ol>
    </Text>
  );
};

const ShareGoogleDriveVideo = () => {
  return (
    <ContentBody>
      <Text color={colors.neutral600} block>
        To share a video from Google Drive, follow these steps:
        <ol>
          <li>Make it shareable</li>
          <li>
            Click on the context-menu (kebab in the top-right corner) and click on &quot;Open in new
            window&quot;
          </li>
          <li>Copy the URL of the resulting page</li>
        </ol>
      </Text>
      <EmbeddedVideoLimitedCapabilitiesNote />
    </ContentBody>
  );
};

const SharePanoptoVideo = () => {
  return (
    <ContentBody>
      <Text color={colors.neutral600} block>
        To share a video from Panopto, follow these steps:
        <ol>
          <li>
            In your Panopto library, select &quot;Share&quot; below the video you wish to embed
          </li>
          <li>At the bottom of the window, select the &quot;Embed&quot; button</li>
          <li>When the Embed icon is selected, select &quot;Copy Embed Code&quot;</li>
          <li>
            In your PlusPlus video form, paste the embed code into the &quot;Video URL&quot; field
            (this will automatically extract the URL)
          </li>
        </ol>
        <p>
          For more info, please see Panopto&apos;s{' '}
          <a href="https://support.panopto.com/s/article/Embed-a-Video">
            How to Embed a Video into a Webpage
          </a>{' '}
          article.
        </p>
      </Text>
      <EmbeddedVideoLimitedCapabilitiesNote />
    </ContentBody>
  );
};

const ShareDescriptVideo = () => {
  return (
    <ContentBody>
      <Text color={colors.neutral600} block>
        To share a video from Descript, follow these steps:
        <ol>
          <li>On your Descript video page, click on &quot;Share&quot; button</li>
          <li>Click on the three dots to access the &quot;More options&quot; context menu</li>
          <li>Click on the &quot;Copy embed code&quot;</li>
          <li>
            In your PlusPlus video form, paste the embed code into the &quot;Video URL&quot; field
            (this will automatically extract the URL)
          </li>
        </ol>
      </Text>
      <EmbeddedVideoLimitedCapabilitiesNote />
    </ContentBody>
  );
};

const ShareMicrosoftStreamVideo = () => {
  return (
    <ContentBody>
      <Text color={colors.neutral600} block>
        To share a video from Microsoft Stream, follow these steps:
        <ol>
          <li>On your video page, click on &quot;Share&quot; button</li>
          <li>Click on the &quot;Embed&quot; tab</li>
          <li>Next to &quot;Copy embed code&quot; click on &quot;Copy&quot;</li>
          <li>
            In your PlusPlus video form, paste the embed code into the &quot;Video URL&quot; field
            (this will automatically extract the URL)
          </li>
        </ol>
      </Text>
      <EmbeddedVideoLimitedCapabilitiesNote />
    </ContentBody>
  );
};

const ShareLoomVideo = () => {
  return (
    <ContentBody>
      <Text color={colors.neutral600} block>
        To share a Loom video, follow these steps:
        <ol>
          <li>
            From your Loom video&apos;s share page, click &quot;Share&quot; above the video and
            click &quot;Embed&quot;
          </li>
          <li>Choose the &quot;Responsive size&quot; option</li>
          <li>Click on &quot;Copy embed code&quot; button</li>
          <li>
            In your PlusPlus video form, paste the embed code into the &quot;Video URL&quot; field
            (this will automatically extract the URL)
          </li>
        </ol>
        <p>
          For more info, please see Loom&apos;s{' '}
          <a href="https://support.loom.com/hc/en-us/articles/360002208317-How-to-embed-your-video-into-a-webpage-">
            How to embed your video into a webpage
          </a>{' '}
          article.
        </p>
      </Text>
      <EmbeddedVideoLimitedCapabilitiesNote />
    </ContentBody>
  );
};

const ShareOtherVideo = () => {
  return (
    <ContentBody>
      <Text color={colors.neutral600} block>
        To allow other video sources, you can (ask your admin to) configure &quot;Allowed video URLs
        (pattern)&quot; via <a href="/a/system-settings/content/#videos">video settings</a>. Once
        your new source has been allowed, copy and paste your embeddable video URL in the
        &quot;Video URL&quot; field.
      </Text>
      <EmbeddedVideoLimitedCapabilitiesNote />
    </ContentBody>
  );
};

const getFAQItems = () => [
  {
    index: '01',
    title: `How to embed an HTML5-compatible video?`,
    content: <ShareHTML5Video />,
  },
  {
    index: '02',
    title: `How to embed a video from a 3rd-party service?`,
    content: <ShareThirdrdPartyVideo />,
  },
  {
    index: '03',
    title: `How to embed a YouTube video at a specific offset?`,
    content: <SharePartOfYTVideo />,
  },
  {
    index: '04',
    title: `How to embed a Google Drive video?`,
    content: <ShareGoogleDriveVideo />,
  },
  {
    index: '05',
    title: `How to embed a Panopto video?`,
    content: <SharePanoptoVideo />,
  },
  {
    index: '06',
    title: `How to embed a Descript video?`,
    content: <ShareDescriptVideo />,
  },
  {
    index: '07',
    title: `How to embed a Microsoft Stream video?`,
    content: <ShareMicrosoftStreamVideo />,
  },
  {
    index: '08',
    title: `How to embed a Loom video?`,
    content: <ShareLoomVideo />,
  },
  {
    index: '09',
    title: `How to allow and embed videos from other sources?`,
    content: <ShareOtherVideo />,
  },
];

const VideoEmbedDocsModal = ({ handleClose, height }) => {
  return (
    <DocsModal
      title="How to embed videos"
      height={height}
      handleClose={handleClose}
      FAQItems={getFAQItems()}
    />
  );
};

VideoEmbedDocsModal.propTypes = {
  handleClose: PropTypes.func,
  height: PropTypes.string,
};

export default VideoEmbedDocsModal;
