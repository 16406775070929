import { useRef, useState } from 'react';

import { RQLComp, RQLDateRange, RQLList } from '~/app/shared/components/types';
import { map, size, filter, get, isNil, isEmpty } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import {
  Badge,
  BadgeProps,
  Box,
  Button,
  ClickAwayListener,
  Grid,
  GridProps,
  Grow,
  Paper,
  Popper,
  styled,
} from '@mui/material';
import { FilterListOutlinedIcon } from '~/vendor/mui-icons';

import RQLCheckbox from '../inputs/RQLCheckbox';
import RQLDateRangePicker from '../inputs/RQLDateRangePicker';
import RQLSearchBar from '../inputs/RQLSearchBar/RQLSearchBar';
import RQLSelect from '../inputs/RQLSelect';
import { Option } from '../inputs/Select/Select';
import { ShortcutConfig } from '~/app/inputs/components/FlexibleDateRangeInput/DateRangeShortcuts';

export interface FilterDef {
  type: 'search_bar' | 'select' | 'model_select' | 'boolean' | 'date_range';
  filterName?: string;
  label?: string;
  placeholder?: string;
  value: RQLComp | RQLList | RQLDateRange | null;
  onChange?: (newValue: RQLComp | RQLList | RQLDateRange | null) => void;
  options?: Option[];
  getOptionLabel?: (option: Option) => string;
  multiple?: boolean;
  sortOptions?: boolean;
  sortByOption?: ((option: Option) => string) | string;
  // Used to fetch the options, if the query key is not defined in the name, the "list" value will be used.
  // E.g.: tags => tags.list | users.departments => users.departments
  queryName?: string;
  // Extra params to be used to fetch the options. You could need to set an ordering value or another extra filtering. E.g. has_directs for teammate filter
  queryParams?: Record<string, string | number | boolean>;
  // Useful for queries that return all data in the first request. E.g. title and department endpoints.
  fetchQueryOnce?: boolean;
  width?: number | string;
  gridProps?: GridProps;
  extraProps?: Record<string, unknown>;
}

interface MoreFiltersProps {
  filters: FilterDef[];
  onClearAll?: () => void;
}

interface RQLFilterBarProps {
  filters: FilterDef[];
  moreFilters?: FilterDef[];
  onClearAll?: () => void;
  children?: React.ReactNode;
}

type FilterWrapperProps = GridProps & {
  isInsideGrid?: boolean;
  children: React.ReactNode;
};

function FilterWrapper(props: FilterWrapperProps) {
  const { isInsideGrid = false, children, ...restGridProps } = props;

  if (!isInsideGrid) {
    return <Box>{children}</Box>;
  }

  return (
    <Grid item sx={{ display: 'flex', alignItems: 'flex-start' }} {...restGridProps}>
      {children}
    </Grid>
  );
}

type RenderFilterProps = {
  filter: FilterDef;
  isInsideGrid?: boolean;
};

function RenderFilter(props: RenderFilterProps) {
  const { filter, isInsideGrid } = props;

  let filterComponent: React.ReactNode | null = null;

  if (filter.type === 'date_range') {
    filterComponent = (
      <RQLDateRangePicker
        value={filter.value as RQLDateRange | null}
        label={filter.label}
        width={filter.width}
        onChange={filter.onChange}
        shortcuts={get(filter, 'extraProps.shortcuts', []) as ShortcutConfig[]}
      />
    );
  }

  if (filter.type === 'boolean') {
    filterComponent = (
      <RQLCheckbox
        value={filter.value as RQLComp | null}
        label={filter.label}
        onChange={filter.onChange}
        maxWidth={filter.width}
      />
    );
  }

  if (filter.type === 'search_bar') {
    filterComponent = (
      <RQLSearchBar
        label={filter.label}
        placeholder={filter.placeholder}
        value={filter.value as RQLComp | null}
        onChange={filter.onChange}
        width={filter.width}
      />
    );
  }

  if (filter.type === 'select') {
    filterComponent = (
      <RQLSelect
        label={filter.label}
        value={filter.value as RQLComp | RQLList | null}
        onChange={filter.onChange}
        options={filter.options}
        multiple={filter.multiple}
        sortOptions={filter.sortOptions}
        sortByOption={filter.sortByOption}
        getOptionLabel={filter.getOptionLabel}
        width={filter.width}
      />
    );
  }

  if (filter.type === 'model_select') {
    filterComponent = (
      <RQLSelect
        label={filter.label}
        value={filter.value as RQLComp | RQLList | null}
        onChange={filter.onChange}
        multiple={filter.multiple}
        sortOptions={filter.sortOptions}
        sortByOption={filter.sortByOption}
        queryName={filter.queryName}
        queryParams={filter.queryParams}
        fetchQueryOnce={filter.fetchQueryOnce}
        getOptionLabel={filter.getOptionLabel}
        width={filter.width}
      />
    );
  }

  return (
    <FilterWrapper isInsideGrid={isInsideGrid} {...filter.gridProps}>
      {filterComponent}
    </FilterWrapper>
  );
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: -16,
    top: 13,
    padding: '0 4px',
  },
}));

const MoreFilters = ({ filters, onClearAll }: MoreFiltersProps) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef?.current?.contains(event.target as Node | null)) return;
    setOpen(false);
  };
  const id = open ? 'more-filters-popper' : undefined;
  const count = size(
    filter(filters, (item) => !isNil(get(item, 'value')) && !isEmpty(get(item, 'value')))
  );
  return (
    <>
      <Button
        ref={anchorRef}
        sx={{
          maxHeight: 40,
          minWidth: count > 0 ? 160 : 140,
          justifyContent: 'flex-start',
          height: '100%',
        }}
        aria-describedby={id}
        variant="outlined"
        onClick={() => setOpen(!open)}
        startIcon={<FilterListOutlinedIcon />}
        key="more-filters-button"
      >
        <StyledBadge badgeContent={count} color="primary">
          More Filters
        </StyledBadge>
      </Button>
      {!open && (
        <Box
          // This will force the filters to be present on the page to force the TanStack query cache sync between the components.
          // As this component could render filters that use the backend data to list options and these options are necessary to
          // render information in different places (pills), forcing the presence in the page will avoid re-fetching the data in
          // all other places where this data is necessary.
          sx={{ display: 'none' }}
          key="hidden-filters"
        >
          {map(filters, (filter, idx) => (
            <RenderFilter key={`${filter.type}-${idx}`} filter={filter} />
          ))}
        </Box>
      )}
      {/* Using Popper because it's less intrusive to the user experience than Popover. */}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        transition
        disablePortal
        sx={{ zIndex: 99 }} // A higher value is set to ensure that the popper will appear above other components.
        key="more-filters-popper"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
                  <Typography variant="subtitle2">More Filters</Typography>

                  {map(filters, (filter, idx) => (
                    <RenderFilter key={`${filter.type}-${idx}`} filter={filter} />
                  ))}

                  {onClearAll && (
                    <Button
                      variant="text"
                      sx={{ width: 90, alignSelf: 'flex-end' }}
                      onClick={onClearAll}
                    >
                      Clear all
                    </Button>
                  )}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const RQLFilterBar = (props: RQLFilterBarProps) => {
  const { filters, moreFilters, onClearAll, children } = props;

  return (
    <Grid container spacing={2} pt={0.5} alignItems="stretch">
      {map(filters, (filter, idx) => (
        <RenderFilter key={`${filter.type}-${filter.label}-${idx}`} filter={filter} isInsideGrid />
      ))}

      {moreFilters && !isEmpty(moreFilters) && (
        <Grid item xs="auto">
          <MoreFilters filters={moreFilters} onClearAll={onClearAll} />
        </Grid>
      )}

      {children && (
        <Grid item xs="auto">
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default RQLFilterBar;
