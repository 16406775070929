import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import {
  LEARNING_TYPES,
  LEARNING_TYPE_ICONS_MAPPING,
  CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING,
} from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import RestrictedIcons from '~/app/shared/components/RestrictedIcons';
import { ADMIN_ROLE_KEY, ORGANIZER_ROLE_KEY } from '~/app/shared/constants';
import { useCurrentUser } from '~/app/shared/hooks';
import ContentDescription from '~/app/stand-alone-shared/components/ContentDescription';
import { isEmpty, isNil, get, includes, filter, map, isString, forEach, reduce } from 'lodash-es';

import BaseCard from './BaseCard';

function removeLinks(contentString) {
  if (!contentString || !isString(contentString) || !includes(contentString, 'atomic')) {
    return contentString; // Return early!
  }

  try {
    let content;
    content = JSON.parse(contentString);
    const { blocks, entityMap } = content;

    // Filter out any blocks that are of type 'atomic' (usually used for media like videos)
    const filteredBlocks = filter(blocks, (block) => block.type !== 'atomic');

    // Create a set of entity keys to keep
    const keptEntityKeys = new Set();
    forEach(filteredBlocks, (block) => {
      forEach(block.entityRanges, (range) => keptEntityKeys.add(range.key.toString()));
    });

    const filteredEntityMap = reduce(
      Object.entries(entityMap),
      (acc, entity, key) => {
        if (keptEntityKeys.has(key) && entity.type !== 'LINK') {
          acc[key] = entity;
        }
        return acc;
      },
      {}
    );

    const newContent = {
      ...content,
      blocks: filteredBlocks,
      entityMap: filteredEntityMap,
    };

    // We need to use stringify because the ContentDescription component expects a string
    return JSON.stringify(newContent);
  } catch {
    return contentString;
  }
}

const LEARNING_TYPE_ORGANIZER_NAMING_ROLE = {
  [LEARNING_TYPES.event_types]: 'main_organizer',
  [LEARNING_TYPES.programs]: null,
  [LEARNING_TYPES.tracks]: 'author',
  [LEARNING_TYPES.articles]: 'author',
  [LEARNING_TYPES.courses]: 'maintainer',
  [LEARNING_TYPES.linkedcontent]: 'maintainer',
  [LEARNING_TYPES.videos]: null,
};

const ContentItemCard = ({
  contentItem,
  isHeaderContentDisabled,
  headerContent,
  description,
  isFinished,
  finishedTypeLabel,
  finishedProps,
  additionalFooterContent,
  disableAllClicks,
}) => {
  const now = moment();
  const isNew = now.diff(contentItem.created ?? contentItem.created_at, 'days') < 30;

  const currentUser = useCurrentUser();
  const allowedRoles = [ADMIN_ROLE_KEY, ORGANIZER_ROLE_KEY];
  const canSeeContentIsHidden = includes(allowedRoles, get(currentUser, 'role'));
  const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[contentItem.content_type];
  const route = useContentTypeRoutes()[contentItem.content_type]?.details;

  const getRatingProps = () => {
    const rating =
      contentItem.avg_feedback_rating ||
      contentItem.average_feedback_rating ||
      contentItem.feedback_rating ||
      0;
    if (rating > 0) {
      return {
        ratingData: Math.round(rating * 10) / 10,
      };
    }
    return null;
  };

  const getFinishedProps = () => {
    return (
      finishedProps || {
        singleText: 'person engaged',
        multipleText: 'people engaged',
        amount: get(contentItem, 'assignments_count', get(contentItem, 'total_assignments')),
      }
    );
  };

  const getOwners = () => {
    const role = LEARNING_TYPE_ORGANIZER_NAMING_ROLE[learningType];

    const main_role = role
      ? filter(contentItem.facilitators, ['role', role])
      : contentItem.facilitators;

    const co_role = role ? filter(contentItem.facilitators, ['role', `co_${role}`]) : [];

    return map([...main_role, ...co_role], 'user');
  };

  return (
    <BaseCard
      icon={LEARNING_TYPE_ICONS_MAPPING[learningType]}
      cover={contentItem.cover || contentItem.default_cover || contentItem.cover_url}
      isHeaderContentDisabled={isHeaderContentDisabled}
      headerContent={headerContent}
      isNew={isNew}
      type={learningType}
      route={route(contentItem)}
      owners={getOwners()}
      title={
        canSeeContentIsHidden ? (
          <>
            <RestrictedIcons
              isPrivate={contentItem.is_hidden}
              hasGroups={!isEmpty(contentItem.groups)}
              size="25px"
            />{' '}
            {contentItem.name}
          </>
        ) : (
          contentItem.name
        )
      }
      duration={contentItem.duration}
      tags={contentItem.tags}
      description={
        description || (
          <ContentDescription description={removeLinks(contentItem.content_body)} noPadding />
        )
      }
      ratingProps={getRatingProps()}
      disableAllClicks={disableAllClicks}
      ariaLabel={contentItem.name}
      isArchived={contentItem.is_archived || Boolean(contentItem.archival)}
      isFinished={
        isFinished ||
        !isNil(contentItem?.assignment?.completed_datetime) ||
        !isNil(get(contentItem, 'user_assignment.completion.completed_at'))
      }
      finishedTypeLabel={finishedTypeLabel}
      finishedProps={getFinishedProps()}
      additionalFooterContent={additionalFooterContent}
    />
  );
};

ContentItemCard.defaultProps = {
  finishedTypeLabel: 'Completed',
};

ContentItemCard.propTypes = {
  contentItem: PropTypes.object,
  isHeaderContentDisabled: PropTypes.bool,
  headerContent: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  description: PropTypes.node,
  isFinished: PropTypes.bool,
  finishedTypeLabel: PropTypes.string,
  finishedProps: PropTypes.object,
  additionalFooterContent: PropTypes.node,
  disableAllClicks: PropTypes.bool,
};

export { ContentItemCard };
