import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { useMentorshipRequestActions } from '~/app/program/hooks';
import {
  MENTORSHIP_REQUEST_KIND_LABEL_MAP,
  MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR,
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE,
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR,
  MENTORSHIP_REQUEST_STATUS_ACCEPTED,
  MENTORSHIP_REQUEST_STATUS_DISMISSED,
} from '~/app/program/constants';
import colors from '~/services/colors';
import MentorshipRequestViewMoreModal from '~/app/shared-modals/components/MentorshipRequestViewMoreModal';
import Icon from '~/app/shared/components/Icon';
import Pill from '~/app/shared/components/Pill';
import Text from '~/app/shared/components/Text';
import UserHighlight from '~/app/shared/components/UserHighlight';
import ViewMoreCta from '~/app/shared/components/ViewMoreCta';
import { DATE_FORMAT } from '~/app/shared/constants';
import { includes } from 'lodash-es';

import { MentorshipSuggestionModal } from './MentorshipSuggestionModal';
import { MentorshipSuggestionPill } from './MentorshipSuggestionPill';

const Card = styled.div`
  border-radius: 8px;
  padding: 12px;
  flex: 0 0 293px;

  display: flex;
  flex-direction: column;

  border: 1px solid ${colors.neutral200};

  ${({ isCompleted }) => isCompleted && `background-color: ${colors.neutral50};`}

  > * + * {
    margin-top: 12px;
  }
`;

const PillsSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;

  border-radius: 12px;
  padding: 6px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;

  min-height: 24px; // matches icon container size, to keep alignment
`;

const Filler = styled.div`
  margin: 0;
  flex: 1 0 auto;
`;

const MentorshipRequestStatusMessage = ({ mentorshipRequest, children }) => {
  if (!children) {
    return null;
  }

  return (
    <MessageContainer>
      {mentorshipRequest.status === MENTORSHIP_REQUEST_STATUS_ACCEPTED && (
        <IconContainer backgroundColor={colors.success200}>
          <Icon name="check" height={12} width={12} color={colors.success700} />
        </IconContainer>
      )}
      {mentorshipRequest.status === MENTORSHIP_REQUEST_STATUS_DISMISSED && (
        <IconContainer backgroundColor={colors.neutral200}>
          <Icon name="close" height={12} width={12} color={colors.neutral600} />
        </IconContainer>
      )}
      <Text
        size="h5"
        block
        color={
          mentorshipRequest.status === MENTORSHIP_REQUEST_STATUS_ACCEPTED
            ? colors.success700
            : colors.neutral600
        }
      >
        {children}
      </Text>
    </MessageContainer>
  );
};

MentorshipRequestStatusMessage.propTypes = {
  mentorshipRequest: PropTypes.object,
  children: PropTypes.string,
};

const BaseMentorshipRequestCard = ({
  user,
  pillIcon,
  mentorshipRequest,
  statusMessage,
  renderActions,
}) => {
  const [showViewMoreModal, setShowViewMoreModal] = useState(false);
  const [showSuggestedModal, setShowSuggestedModal] = useState(false);
  const { dismiss } = useMentorshipRequestActions(mentorshipRequest);

  const isCompleted = includes(
    [MENTORSHIP_REQUEST_STATUS_ACCEPTED, MENTORSHIP_REQUEST_STATUS_DISMISSED],
    mentorshipRequest.status
  );

  const isSuggestion = ({ kind, suggested }) =>
    suggested &&
    includes(
      [MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE, MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR],
      kind
    );

  const handleSuggestionDismiss = () => {
    setShowSuggestedModal(false);
    dismiss();
  };

  const getSuggestionLabel = () => {
    if (!mentorshipRequest.suggested) {
      return null;
    }

    return mentorshipRequest.kind === MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE
      ? 'Suggested mentee'
      : 'Suggested mentor';
  };

  return (
    <Card isCompleted={isCompleted}>
      <PillsSection>
        <Pill
          icon={pillIcon}
          label={MENTORSHIP_REQUEST_KIND_LABEL_MAP[mentorshipRequest.kind]}
          variant={
            mentorshipRequest.kind === MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR
              ? 'action'
              : 'emphasis'
          }
        />
        <Pill
          icon="calendar"
          label={moment(mentorshipRequest.modified).format(DATE_FORMAT)}
          variant="lighterGrayWithDarkerTextColor"
        />
      </PillsSection>

      <UserHighlight user={user} nameColor={isCompleted ? colors.neutral500 : colors.neutral900} />

      <MentorshipRequestStatusMessage mentorshipRequest={mentorshipRequest}>
        {statusMessage}
      </MentorshipRequestStatusMessage>
      {isSuggestion(mentorshipRequest) && (
        <MentorshipSuggestionPill
          mentorshipRequest={mentorshipRequest}
          suggestionLabel={`${getSuggestionLabel()}:`}
          onSuggestionClicked={() => setShowSuggestedModal(true)}
        />
      )}
      {mentorshipRequest.note && (
        <ViewMoreCta
          backgroundFadeColor={isCompleted ? colors.neutral50 : colors.neutral0}
          multipleLines
          onClick={() => setShowViewMoreModal(true)}
        >
          {({ nodeRef }) => (
            <Text ref={nodeRef} size="h5" color={colors.neutral500} ellipsisOnLine={2}>
              {mentorshipRequest.note}
            </Text>
          )}
        </ViewMoreCta>
      )}
      <Filler />
      {renderActions}
      {showViewMoreModal && (
        <MentorshipRequestViewMoreModal
          user={user}
          mentorshipRequest={mentorshipRequest}
          statusMessage={statusMessage}
          handleClose={() => setShowViewMoreModal(false)}
        />
      )}
      {showSuggestedModal && (
        <MentorshipSuggestionModal
          mentorshipRequest={mentorshipRequest}
          onClose={() => setShowSuggestedModal(false)}
          isCompleted={isCompleted}
          onDismiss={handleSuggestionDismiss}
          title={getSuggestionLabel()}
        />
      )}
    </Card>
  );
};

BaseMentorshipRequestCard.propTypes = {
  user: PropTypes.object,
  pillIcon: PropTypes.string,
  mentorshipRequest: PropTypes.object,
  renderActions: PropTypes.node,
  statusMessage: PropTypes.string,
};

export default BaseMentorshipRequestCard;
