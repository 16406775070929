import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Autocomplete from '~/app/inputs/components/Autocomplete';
import { Item } from '~/app/inputs/components/ListSelector';
import { usePrevious } from '~/app/shared/hooks';
import {
  difference,
  map,
  find,
  isEmpty,
  includes,
  noop,
  without,
  filter,
  isArray,
} from 'lodash-es';

const FilterOptionsQueryList = ({
  disabled,
  selecteds,
  onChange,
  options,
  allItemName,
  permanentItems,
  searchPlaceholder,
  singleSelect,
  onAllClick,
  checkIsEmpty,
  fetchOptions,
}) => {
  const previousSelecteds = usePrevious(selecteds);
  useEffect(() => {
    const hasChanges = !isEmpty(difference(selecteds, previousSelecteds));

    if (!hasChanges || !fetchOptions) return;

    fetchOptions({ q: '', include_ids: selecteds });
  }, [selecteds]);

  // If has fetchOptions, the request result can be empty if there is no match.
  if (!fetchOptions && isEmpty(options)) return null;

  const optionValues = map(options, 'value');
  const selectedsWithoutPermanents = difference(selecteds, map(permanentItems, 'value'));
  const validSelectedsWithoutPermanents = filter(selectedsWithoutPermanents, (selected) =>
    includes(optionValues, selected)
  );

  const handleSearchChange = fetchOptions
    ? ({ q, includeValues }) => {
        // The includeValues here can be a single value or a list of values and all filters that
        // are using this component are expecting the include_ids to be always an array
        const includeIds = isEmpty(includeValues)
          ? undefined
          : isArray(includeValues)
            ? includeValues
            : [includeValues];
        fetchOptions({ q, include_ids: includeIds });
      }
    : noop;

  const handleRemoveItem = (itemValue) => {
    if (includes(selecteds, itemValue)) {
      onChange(without(selecteds, itemValue));
    }
  };

  const handleAddItem = (itemValue) => {
    if (!includes(selecteds, itemValue)) {
      if (singleSelect) {
        onChange([itemValue]);
      } else {
        onChange([...selecteds, itemValue]);
      }
    }
  };

  const handleChangeItem = (itemValue) => {
    if (includes(selecteds, itemValue)) {
      handleRemoveItem(itemValue);
    } else {
      handleAddItem(itemValue);
    }
  };

  const handleAllClick = () => {
    if (isEmpty(selecteds)) {
      return;
    }
    if (onAllClick) {
      onAllClick(selecteds, onChange);
    } else {
      onChange([]);
    }
  };

  const emptyChecker = checkIsEmpty || isEmpty;

  return (
    <React.Fragment>
      {allItemName && (
        <Item
          item={{ name: allItemName, value: '' }}
          isChecked={emptyChecker(selecteds)}
          onClick={handleAllClick}
          disabled={disabled}
          notRemovable
          limitWidth={false}
          borderless
        />
      )}
      {map(permanentItems, (item) => (
        <Item
          key={item.value}
          item={item}
          isChecked={includes(selecteds, item.value)}
          onClick={handleChangeItem}
          disabled={disabled}
          limitWidth={false}
          borderless
        />
      ))}
      {map(validSelectedsWithoutPermanents, (itemValue) => (
        <Item
          key={itemValue}
          item={find(options, ['value', itemValue])}
          onClick={handleRemoveItem}
          disabled={disabled}
          isChecked
          limitWidth={false}
          borderless
        />
      ))}
      <Autocomplete
        value={selecteds}
        disabled={disabled}
        options={map(options, ({ value, name, isDisabled }) => ({
          value,
          label: name,
          disabled: Boolean(isDisabled),
        }))}
        onChange={onChange}
        fetchOptions={fetchOptions ? handleSearchChange : undefined}
        placeholder={searchPlaceholder}
        multiple
        filterSelectedOptions
        disableClearable
        renderTags={() => <div />}
      />
    </React.Fragment>
  );
};

export default FilterOptionsQueryList;

FilterOptionsQueryList.propTypes = {
  allItemName: PropTypes.any,
  disabled: PropTypes.any,
  onChange: PropTypes.any,
  options: PropTypes.any,
  permanentItems: PropTypes.any,
  selecteds: PropTypes.any,
  searchPlaceholder: PropTypes.string,
  singleSelect: PropTypes.bool,
  checkIsEmpty: PropTypes.func,
  onAllClick: PropTypes.func,
  fetchOptions: PropTypes.func,
};

FilterOptionsQueryList.defaultProps = {
  onChange: noop,
  searchPlaceholder: '',
  singleSelect: false,
};
