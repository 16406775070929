import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { createUserLoad } from '~/app/data-fetching/queries/user_loads';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { User } from '~/app/shared/components/types';
import ConfirmationModal from '~/app/shared/components/ConfirmationModal';
import { useCurrentUser } from '~/app/shared/hooks';
import { IS_ADMIN_PERMISSION, IS_SUPERUSER_PERMISSION } from '~/app/shared/permissions';
import { includes } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

interface Props {
  user: User;
  handleClose: () => void;
}

const UserSyncConfirmationModal = ({ user, handleClose }: Props) => {
  const currentUser = useCurrentUser();

  const canUseActions =
    includes(currentUser.permissions, IS_SUPERUSER_PERMISSION) ||
    includes(currentUser.permissions, IS_ADMIN_PERMISSION);

  const [persistUpdates, setPersistUpdates] = useState(false);
  const [persistSkippedUserRecords, setPersistSkippedUserRecords] = useState(false);

  const requestSync = useMutation({
    // TODO merge user load confirmation dialogs https://app.asana.com/0/0/1206821514038214/f
    mutationFn: () => createUserLoad({ user_id: user.id, force_dry_run: !persistUpdates }),
    onError: (response: any) => {
      if (response?.detail) {
        toast.error(response.detail);
      }
      handleClose();
    },
    onSuccess: (response) => {
      toast.success(response);
      handleClose();
    },
  });

  if (!canUseActions) return null;

  return (
    <ConfirmationModal
      open
      onConfirmClick={() => {
        requestSync.mutate();
      }}
      handleClose={handleClose}
      deleteModalTitle="Confirmation"
      confirmText="Sync user"
      confirmButtonColor="primary"
    >
      <Typography sx={{ pb: 1 }}>
        This action will manually run a new People Integration Sync to retrieve data to update data
        for theis user. Please review the options below before proceeding.
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          checked={persistUpdates}
          onChange={(_, value) => setPersistUpdates(value)}
          label={
            <>
              <Typography>Use received data to update the user</Typography>
              <Typography variant="caption">
                {' '}
                If toggled off, received user record will not be used to create/terminate this user
                nor to update any existing attribute.
              </Typography>
            </>
          }
        />
        <FormControlLabel
          disabled
          control={<Checkbox />}
          checked={persistSkippedUserRecords}
          onChange={(_, value) => setPersistSkippedUserRecords(value)}
          label="Persist logs from skipped updates (Coming soon!)"
        />
      </FormGroup>
      <Typography variant="subtitle2" sx={{ pb: 1 }}>
        Please note that automated rules will not be executed after this operation.
      </Typography>
    </ConfirmationModal>
  );
};

export default UserSyncConfirmationModal;
