import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { useFormPreventTransition, useFormSelector } from '~/app/shared/hooks';
import InputLabel from '~/app/inputs/components/InputLabel';
import RatingField from '~/app/inputs/components/RatingField';
import TextField from '~/app/inputs/components/TextField';
import colors from '~/services/colors';
import Button from '~/app/shared/components/Button';
import Clicker from '~/app/shared/components/Clicker';
import Form from '~/app/shared/components/OldForm';
import { omit, isNil } from 'lodash-es';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

const FieldsContainer = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;
`;

const RatingSection = styled.div`
  font-size: 31px;
  margin-bottom: 15px;
`;

const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHR = styled.hr`
  color: gray;
  width: 100%;
  margin: 0px;
`;

const SubmitSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

const StyledClicker = styled(Clicker)`
  margin-right: 20px;
  font-weight: bold;
  font-size: 14px;
`;

const FormContainer = styled.div`
  padding-top: 16px;
`;

export const FeedbackForm = ({
  form,
  onSubmitFunc,
  actionName,
  feedbackIsPublic,
  fieldLabels,
  fieldPlaceholders,
  handleSubmit,
  handleDismiss,
  submitting,
  schema,
  formMarginBottom,
}) => {
  const feedbackRating = useFormSelector(form, 'feedback_rating');
  const feedbackText = useFormSelector(form, 'feedback_text');

  useFormPreventTransition(form);

  const handleOnSubmit = () => {
    // If there is a function to be executed on the submit, that function will be executed instead of Saga's request.
    if (!isNil(onSubmitFunc)) {
      return handleSubmit(() => onSubmitFunc({ rating: feedbackRating, feedback: feedbackText }));
    }
    return handleSubmit(
      onSubmitActions(actionName, (values) => ({
        id: values.id,
        key: null,
        body: omit({ ...values, contentSchema: schema || null }, ['id']),
      }))
    );
  };

  return (
    <FormContainer>
      <Form margin={formMarginBottom} onSubmit={handleOnSubmit()}>
        <FieldsContainer>
          <RatingContainer>
            <InputLabel>{fieldLabels.rating ?? 'Feedback Rating'}</InputLabel>
            <RatingSection>
              <Field name="feedback_rating" id="feedback-rating-input" component={RatingField} />
            </RatingSection>
          </RatingContainer>

          <Field
            multiline
            label={fieldLabels.feedback ?? 'Feedback Text'}
            rows={2}
            component={TextField}
            id="feedback_text"
            name="feedback_text"
            className="text-input"
            placeholder={fieldPlaceholders.feedback ?? 'How was your experience?'}
            autoFocus
          />
        </FieldsContainer>
        <StyledHR />
        <SubmitSection>
          {Boolean(handleDismiss) && (
            <StyledClicker color={colors.action700} onClick={handleDismiss}>
              Dismiss
            </StyledClicker>
          )}
          <Button type="submit" disabled={submitting} size="small">
            {feedbackIsPublic ? 'Send Public Feedback' : 'Send Feedback'}
          </Button>
        </SubmitSection>
      </Form>
    </FormContainer>
  );
};

FeedbackForm.propTypes = {
  form: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleDismiss: PropTypes.func,
  submitting: PropTypes.bool,
  onSubmitFunc: PropTypes.func,
  actionName: PropTypes.string,
  feedbackIsPublic: PropTypes.bool,
  schema: PropTypes.object,
  formMarginBottom: PropTypes.number,

  fieldLabels: PropTypes.object,
  fieldPlaceholders: PropTypes.object,
};

FeedbackForm.defaultProps = {
  fieldLabels: {},
  fieldPlaceholders: {},
};

export default reduxForm({
  form: 'feedback',
})(FeedbackForm);
