/**
 * This file exports the current theme available colors dictionary.
 * It also exports the suggested colors to be used by font colors
 * used over the backgrounds.
 * eg: if a background uses colors.emphasis100, the suggested font color
 * to be used in texts over this background is colors.emphasis100TextColor.
 */

import { getContrast } from 'polished';

import { reduce } from 'lodash-es';

const defaultTheme = {
  neutral0: '#FFFFFF',
  neutral50: '#FAFAFA',
  neutral100: '#F0F0F0',
  neutral200: '#D5D5D5',
  neutral400: '#ADADAD',
  neutral500: '#808080',
  neutral600: '#5E5E5E',
  neutral700: '#3D3D3D',
  neutral900: '#222222',

  success100: '#CFF3E7',
  success200: '#92DFC6',
  success400: '#59C8A4',
  success600: '#23AD81',
  success700: '#137D63',
  success800: '#08483D',

  alert100: '#F7EBDA',
  alert200: '#EBC080',
  alert400: '#CF8933',
  alert600: '#A26821',
  alert700: '#623F15',
  alert800: '#3B270F',

  error100: '#F3D7D5',
  error200: '#E9A19C',
  error400: '#D45950',
  error600: '#B5291F',
  error700: '#811F18',
  error800: '#3E1B19',

  rating200: '#FFDF88',
  rating600: '#FCB315',
  rating700: '#B07F06',

  learningArticle200: '#F2D7E5',
  learningArticle600: '#C54282',
  learningArticle700: '#76274E',

  learningAssessment200: '#b39ddb',
  learningAssessment600: '#5e35b1',
  learningAssessment700: '#512da8',

  learningTask200: '#D0D5F7',
  learningTask600: '#3E4DAD',
  learningTask700: '#152064',

  learningCodelab200: '#F9DEC7',
  learningCodelab600: '#F58C32',
  learningCodelab700: '#A54500',

  learningCourse200: '#CDEAFE',
  learningCourse600: '#196FBB',
  learningCourse700: '#055286',

  learningEventType200: '#CCE5E6',
  learningEventType600: '#207E82',
  learningEventType700: '#005e61',

  learningProgram200: '#EADCF5',
  learningProgram600: '#905CB8',
  learningProgram700: '#5b3a74',

  learningLinkedContent200: '#DCEDC8',
  learningLinkedContent600: '#7CB342',
  learningLinkedContent700: '#689F38',

  learningMentorshipProgram200: '#B2EBF2', // TODO: why doesn't cyan[100] work?
  learningMentorshipProgram600: '#A9D8DE', // TODO: This color is not in figma
  learningMentorshipProgram700: '#006064',

  learningQuestion200: '#eeeeee',
  learningQuestion600: '#757575',
  learningQuestion700: '#616161',

  learningTrack200: '#EED8E7',
  learningTrack600: '#952370',
  learningTrack700: '#7b0051',

  learningVideo200: '#F3D7D6',
  learningVideo600: '#B5291F',
  learningVideo700: '#811f18',

  channel700: '#F57C00',
  channel600: '#FFA000',
  channel200: '#FFD180',

  green200: '#A5D6A7',

  red100: '#FECDD2',

  lightActionActive: '#0000008A',
} as const;

const customThemes = {
  plusplus: {
    action100: '#CFF3E7',
    action200: '#92DFC6',
    action400: '#59C8A4',
    action600: '#23AD81',
    action700: '#137D63',
    action800: '#08483D',

    emphasis100: '#EADCF5',
    emphasis200: '#C7A7DF',
    emphasis400: '#A779CA',
    emphasis600: '#905CB8',
    emphasis700: '#5B3A74',
    emphasis800: '#372842',
  },

  marqueta: {
    action100: '#D7D3ED',
    action200: '#B5AAFF',
    action400: '#7161E5',
    action600: '#2A206A',
    action700: '#0a263a',
    action800: '#00111f',

    emphasis100: '#F0FAF6',
    emphasis200: '#1FDF95',
    emphasis400: '#1CC283',
    emphasis600: '#318F6B',
    emphasis700: '#325C4C',
    emphasis800: '#2C3330',
  },
  gotham: {
    action100: '#E3E3E3',
    action200: '#C9C9C9',
    action400: '#3D3D3D',
    action600: '#252525',
    action700: '#131313',
    action800: '#000000',

    emphasis100: '#F3D7D5',
    emphasis200: '#E9A19C',
    emphasis400: '#D45950',
    emphasis600: '#B5291F',
    emphasis700: '#811F18',
    emphasis800: '#3E1B19',
  },
  atlas: {
    action100: '#C1D9F1',
    action200: '#8BBAE8',
    action400: '#3380CC',
    action600: '#004182',
    action700: '#003263',
    action800: '#062340',

    emphasis100: '#C1D9F1',
    emphasis200: '#8BBAE8',
    emphasis400: '#3380CC',
    emphasis600: '#004182',
    emphasis700: '#003263',
    emphasis800: '#062340',
  },
  electric_blue: {
    action100: '#CCEEFE',
    action200: '#88D6F8',
    action400: '#44BBEC',
    action600: '#009EDB',
    action700: '#00649F',
    action800: '#00315C',

    emphasis100: '#CCEEFE',
    emphasis200: '#88D6F8',
    emphasis400: '#44BBEC',
    emphasis600: '#009EDB',
    emphasis700: '#00649F',
    emphasis800: '#00315C',
  },
  trailblazer: {
    action100: '#EEF5FE',
    action200: '#88D6F8',
    action400: '#44BBEC',
    action600: '#0176D3',
    action700: '#0176D3',
    action800: '#00315C',

    emphasis100: '#0176D3',
    emphasis200: '#EAF5FE',
    emphasis400: '#44BBEC',
    emphasis600: '#032D60',
    emphasis700: '#0B5CAB',
    emphasis800: '#00315C',
  },
  blue_ribbon: {
    action100: '#CCD7FF',
    action200: '#8CA4FF',
    action400: '#5274FE',
    action600: '#1D48F4',
    action700: '#0C20B0',
    action800: '#020761',

    emphasis100: '#EACCFF',
    emphasis200: '#CE8CFF',
    emphasis400: '#B152FE',
    emphasis600: '#941DF4',
    emphasis700: '#730CB0',
    emphasis800: '#480261',
  },
  polaris: {
    action100: '#D0D5F7',
    action200: '#A3ACEB',
    action400: '#6D7CDE',
    action600: '#3E4DAD',
    action700: '#27358D',
    action800: '#152064',

    emphasis100: '#CDEAFE',
    emphasis200: '#8BCCF9',
    emphasis400: '#248FD6',
    emphasis600: '#006FBB',
    emphasis700: '#055286',
    emphasis800: '#013152',
  },
  kazan: {
    action100: '#CCE5E6',
    action200: '#99CBCD',
    action400: '#66B2B4',
    action600: '#007E82',
    action700: '#005E61',
    action800: '#003E40',

    emphasis100: '#EED8E7',
    emphasis200: '#D6A3C6',
    emphasis400: '#BD65A1',
    emphasis600: '#952370',
    emphasis700: '#7B0051',
    emphasis800: '#4F0C38',
  },
  simple_red: {
    action100: '#FFD5D8',
    action200: '#FEA1A6',
    action400: '#F87279',
    action600: '#EE464F',
    action700: '#AB2D2A',
    action800: '#611B14',

    emphasis100: '#FFD5D8',
    emphasis200: '#FEA1A6',
    emphasis400: '#F87279',
    emphasis600: '#EE464F',
    emphasis700: '#AB2D2A',
    emphasis800: '#611B14',
  },
  driven: {
    action100: '#D3D4D4',
    action200: '#999A9B',
    action400: '#5F6162',
    action600: '#252729',
    action700: '#131515',
    action800: '#000000',

    emphasis100: '#F6CCD1',
    emphasis200: '#E78B97',
    emphasis400: '#D34F5F',
    emphasis600: '#BB162B',
    emphasis700: '#870910',
    emphasis800: '#4E0302',
  },
  mono: {
    action100: '#D3D4D4',
    action200: '#999A9B',
    action400: '#5F6162',
    action600: '#252729',
    action700: '#131515',
    action800: '#000000',

    emphasis100: '#D3D4D4',
    emphasis200: '#999A9B',
    emphasis400: '#5F6162',
    emphasis600: '#252729',
    emphasis700: '#131515',
    emphasis800: '#000000',
  },
} as const;

// Regulates the automatic text color treshold.
// Higher values means that lighter background colors will more likely have a light text color
const textColorContrastRatioTreshold = 3;

export const getContrastTextColor =
  (currentThemeColors: typeof defaultTheme) => (backgroundColor: string) => {
    const lighterColor = currentThemeColors.neutral0;
    const darkerColor = currentThemeColors.neutral900;

    const lighterContrastRatio = getContrast(backgroundColor, lighterColor);
    const darkerContrastRatio = getContrast(backgroundColor, darkerColor);

    if (lighterContrastRatio + textColorContrastRatioTreshold > darkerContrastRatio) {
      return lighterColor;
    }

    return darkerColor;
  };

export const getThemeColors = (selectedTheme: keyof typeof customThemes) => {
  if (!customThemes[selectedTheme]) {
    selectedTheme = 'plusplus';
  }

  window.CUSTOMER_THEME = selectedTheme;

  const selectedThemeColors = { ...defaultTheme, ...customThemes[selectedTheme] };

  const getThemeContrastTextColor = getContrastTextColor(selectedThemeColors);
  const selectedThemeFontColors = reduce(
    Object.entries(selectedThemeColors),
    (accumulator, [key, value]) => ({
      ...accumulator,
      [`${key}TextColor`]: getThemeContrastTextColor(value),
    }),
    {} as {
      [key in keyof typeof selectedThemeColors as `${key}TextColor`]: ReturnType<
        typeof getThemeContrastTextColor
      >;
    }
  );

  return {
    ...selectedThemeColors,
    ...selectedThemeFontColors,
  };
};

const colors = getThemeColors(window.CUSTOMER_THEME);

export default colors;
