import { call, put, takeLatest } from 'redux-saga/effects';

import { ApiURLs, fetchURL } from '~/services/requests-base';

import actions from './actions';

export function* userAlertList() {
  const url = ApiURLs['api_notifications:alert-list']();

  try {
    const response = yield call(fetchURL, url);

    yield put(actions.userAlertListFetchRequestSuccess(response.data));
  } catch (error) {
    yield put(actions.userAlertListFetchRequestFailure({ _error: error.data }));
  }
}

export function* userAlertCreate(action) {
  const { payload } = action;

  const url = ApiURLs['api_notifications:alert-list']();

  const options = action.options || {};
  options.method = 'POST';
  options.body = JSON.stringify(payload);

  try {
    const response = yield call(fetchURL, url, options);

    yield put(actions.userAlertCreateRequestSuccess(response.data));

    yield put(actions.userAlertListFetchRequestSubmit());
  } catch (error) {
    yield put(actions.userAlertCreateRequestFailure({ _error: error.data }));
  }
}

export function* userAlertDelete(action) {
  const alertId = action.payload.id;
  const url = ApiURLs['api_notifications:alert-detail'](alertId);

  const options = action.options || {};
  options.method = 'DELETE';

  try {
    yield call(fetchURL, url, options);

    yield put(actions.userAlertDeleteRequestSuccess());

    yield put(actions.userAlertListFetchRequestSubmit());
  } catch (error) {
    yield put(actions.userAlertDeleteRequestFailure({ _error: error.data }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.userAlertListFetchRequestSubmit.toString(), userAlertList);
  yield takeLatest(actions.userAlertCreateRequestSubmit.toString(), userAlertCreate);
  yield takeLatest(actions.userAlertDeleteRequestSubmit.toString(), userAlertDelete);
}
