import { useQuery } from '@tanstack/react-query';

import Loading from '~/app/shared/components/Loading';
import { usePublicIdFromURL } from '~/app/shared/hooks';
import LiteHeader from '~/app/content-items/components/LiteHeader';
import { queries } from '~/queries';
import { RQLFiltersProvider } from '~/app/rosters/RQLFiltersContext';
import Page404 from '~/scenes/404';
import colors from '~/services/colors';
import { permissionDeniedRedirectAlert } from '~/services/permission-denied-redirect-alert';
import PageTitle from '~/app/shared/components/PageTitle';
import {
  CONTENT_ITEM_PERMISSIONS,
  CREATE_MENTORSHIP_SESSION_FOR_OTHERS_PERMISSION,
} from '~/app/shared/permissions';
import { includes } from 'lodash-es';
import { Box } from '@mui/material';
import ListProgramMentees from './ListProgramMentees';

interface ManageProgramMenteeProps {
  titleLabel?: string;
}

const ManageProgramMentees = ({ titleLabel = 'Manage mentees' }: ManageProgramMenteeProps) => {
  const { publicId } = usePublicIdFromURL();
  const {
    data: mentorshipProgram,
    isLoading,
    isError,
  } = useQuery(queries.mentorship_program.detail(publicId));

  if (isError) return <Page404 />;

  if (isLoading) return <Loading />;

  if (
    !includes(mentorshipProgram.permissions, CONTENT_ITEM_PERMISSIONS.assign) &&
    !includes(mentorshipProgram.permissions, CREATE_MENTORSHIP_SESSION_FOR_OTHERS_PERMISSION)
  ) {
    permissionDeniedRedirectAlert();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
        width: '100%',
        height: '100%',
        backgroundImage: `linear-gradient(to bottom, ${colors.emphasis600} 0% 360px, transparent 360px 100%)`,
        pb: 2,
      }}
    >
      <PageTitle title={`${mentorshipProgram.name} - ${titleLabel}`} />
      <LiteHeader content={mentorshipProgram} />
      <RQLFiltersProvider>
        <ListProgramMentees contentItem={mentorshipProgram} />
      </RQLFiltersProvider>
    </Box>
  );
};

export default ManageProgramMentees;
