import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { listQueryKeyFactory, detailQueryKeyFactory } from '~/app/data-fetching/utils';

export const codelabs = createQueryKeys('codelabs', {
  list: listQueryKeyFactory({ endpointName: 'codelabs_api:list_create' }),
  detail: detailQueryKeyFactory({ endpointName: 'content_items_api:retrieve' }),
});

export type CodelabsQueryKeys = inferQueryKeys<typeof codelabs>;
