import { tint } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { LEARNING_TYPE_COLOR_MAPPING, LEARNING_TYPES } from '~/app/catalog/constants';
import colors from '~/services/colors';
import Icon from '~/app/shared/components/Icon';
import Text from '~/app/shared/components/Text';
import { Tooltip } from '~/app/shared/components/Tooltip';
import { useEllipsisCheck, useTooltipUID } from '~/app/shared/hooks';
import { isObject, isString } from 'lodash-es';
import { Icon as MUIIcon } from '@mui/material';

export type SizeType = 'small' | 'medium' | 'large';

const sizeMapping = {
  large: {
    height: '32px',
    padding: 12,
    iconSize: 16,
  },
  medium: {
    height: '24px',
    padding: 8,
    iconSize: 12,
  },
  small: {
    height: '20px',
    padding: 6,
    iconSize: '10px',
  },
};

const getTextSize = (size) => {
  if (size === 'large') {
    return 'h4';
  }
  if (size === 'small') {
    return 'h6';
  }
  return 'h5';
};

const variantMapping = {
  darkGray: {
    backgroundColor: colors.neutral900,
    color: colors.neutral0,
    hoverBackgroundColor: tint(0.2, colors.neutral900),
  },
  lightGray: {
    backgroundColor: colors.neutral200,
    color: colors.neutral700,
    hoverBackgroundColor: tint(0.2, colors.neutral200),
  },
  lighterGray: {
    backgroundColor: colors.neutral100,
    color: colors.neutral600,
    hoverBackgroundColor: tint(0.2, colors.neutral100),
  },
  lighterGrayWithDarkerTextColor: {
    backgroundColor: colors.neutral100,
    color: colors.neutral600,
    hoverBackgroundColor: tint(0.2, colors.neutral100),
  },
  transparent: {
    color: colors.neutral400,
  },
  primary: {
    backgroundColor: colors.emphasis600,
    color: colors.emphasis600TextColor,
    hoverBackgroundColor: tint(0.2, colors.emphasis600),
  },
  action: {
    backgroundColor: colors.action200,
    color: colors.action700,
    hoverBackgroundColor: tint(0.2, colors.action200),
  },
  emphasis: {
    backgroundColor: colors.emphasis200,
    color: colors.emphasis700,
    hoverBackgroundColor: tint(0.2, colors.emphasis200),
  },
  outlined: {
    color: colors.neutral0,
    borderColor: colors.neutral0,
    hoverBorderColor: colors.neutral100,
    hoverBackgroundColor: colors.neutral100,
    hoverColor: colors.emphasis600,
  },
  selected: {
    color: colors.neutral900,
    borderColor: colors.neutral0,
    backgroundColor: colors.neutral0,
    hoverBorderColor: colors.neutral100,
    hoverBackgroundColor: colors.neutral100,
    hoverColor: colors.emphasis600,
  },
  success200: {
    backgroundColor: colors.success200,
    color: colors.success800,
  },
  success600: {
    backgroundColor: colors.success600,
    color: colors.neutral0,
    hoverBackgroundColor: tint(0.2, colors.success600),
  },
  rating200: {
    backgroundColor: colors.rating200,
    color: colors.rating700,
  },
  error200: {
    backgroundColor: colors.error200,
    color: colors.error800,
  },
  neutral200: {
    backgroundColor: colors.neutral200,
    color: colors.neutral700,
  },
  alert200: {
    backgroundColor: colors.alert200,
    color: colors.neutral600,
  },
  warning200: {
    backgroundColor: colors.rating200,
    color: colors.neutral600,
  },
  neutral100: {
    backgroundColor: colors.neutral100,
    color: colors.neutral900,
  },
  [`learningType${LEARNING_TYPES.event_types}`]: {
    color: colors.neutral0,
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.event_types].color700,
  },
  [`learningType${LEARNING_TYPES.programs}`]: {
    color: colors.neutral0,
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.programs].color700,
  },
  [`learningType${LEARNING_TYPES.mentorship_programs}`]: {
    color: colors.neutral0,
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.mentorship_programs].color700,
  },
  [`learningType${LEARNING_TYPES.linkedcontent}`]: {
    color: colors.neutral0,
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.linkedcontent].color700,
  },
  [`learningType${LEARNING_TYPES.tracks}`]: {
    color: colors.neutral0,
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.tracks].color700,
  },
  [`learningType${LEARNING_TYPES.articles}`]: {
    color: colors.neutral0,
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.articles].color700,
  },
  [`learningType${LEARNING_TYPES.codelabs}`]: {
    color: colors.neutral0,
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.codelabs].color700,
  },
  [`learningType${LEARNING_TYPES.courses}`]: {
    color: colors.neutral0,
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.courses].color700,
  },
  [`learningType${LEARNING_TYPES.videos}`]: {
    color: colors.neutral0,
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.videos].color700,
  },
  [`learningType${LEARNING_TYPES.tasks}`]: {
    color: colors.neutral0,
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.tasks].color700,
  },
  [`learningType${LEARNING_TYPES.assessments}`]: {
    color: colors.neutral0,
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.assessments].color700,
  },
  [`lightLearningType${LEARNING_TYPES.events}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.event_types].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.event_types].color600,
  },
  [`lightLearningType${LEARNING_TYPES.event_types}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.event_types].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.event_types].color600,
  },
  [`lightLearningType${LEARNING_TYPES.programs}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.programs].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.programs].color600,
  },
  [`lightLearningType${LEARNING_TYPES.linkedcontent}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.linkedcontent].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.linkedcontent].color600,
  },
  [`lightLearningType${LEARNING_TYPES.mentorship_programs}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.mentorship_programs].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.mentorship_programs].color600,
  },
  [`lightLearningType${LEARNING_TYPES.tracks}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.tracks].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.tracks].color600,
  },
  [`lightLearningType${LEARNING_TYPES.scheduled_tracks}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.scheduled_tracks].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.tracks].color600,
  },
  [`lightLearningType${LEARNING_TYPES.articles}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.articles].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.articles].color600,
  },
  [`lightLearningType${LEARNING_TYPES.assessments}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.assessments].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.assessments].color600,
  },
  [`lightLearningType${LEARNING_TYPES.codelabs}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.codelabs].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.codelabs].color600,
  },
  [`lightLearningType${LEARNING_TYPES.courses}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.courses].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.courses].color600,
  },
  [`lightLearningType${LEARNING_TYPES.videos}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.videos].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.videos].color600,
  },
  [`lightLearningType${LEARNING_TYPES.tasks}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.tasks].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.tasks].color600,
  },
  [`lightLearningType${LEARNING_TYPES.text_questions}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.text_questions].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.text_questions].color600,
  },
  [`lightLearningType${LEARNING_TYPES.multiple_choice_questions}`]: {
    backgroundColor: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.multiple_choice_questions].color200,
    color: LEARNING_TYPE_COLOR_MAPPING[LEARNING_TYPES.multiple_choice_questions].color600,
  },
};

const getBorder = ({ variant }) =>
  variantMapping[variant].borderColor && `border: 1px solid ${variantMapping[variant].borderColor}`;

const getHoveredBackgroundColor = ({
  isClickable,
  isEditable,
  variant,
}: {
  isClickable?: boolean;
  isEditable?: boolean;
  variant: string;
}) =>
  isClickable &&
  !isEditable &&
  `background-color: ${variantMapping[variant].hoverBackgroundColor};`;

const getHoveredBorderColor = ({
  isClickable,
  isEditable,
  variant,
}: {
  isClickable?: boolean;
  isEditable?: boolean;
  variant: string;
}) =>
  isClickable &&
  !isEditable &&
  variantMapping[variant].hoverBorderColor &&
  `
    border-color: ${variantMapping[variant].hoverBorderColor};
  `;

const getHoveredColor = ({
  isClickable,
  isEditable,
  variant,
}: {
  isClickable?: boolean;
  isEditable?: boolean;
  variant: string;
}) =>
  isClickable &&
  !isEditable &&
  variantMapping[variant].hoverColor &&
  `
    color: ${variantMapping[variant].hoverColor};
  `;

export interface PillContainerProps {
  variant: string;
  size: string;
  round: boolean;
  hasImage?: boolean;
  imagePlaceHolder?: React.ReactNode;
  isClickable?: boolean;
  isEditable?: boolean;
}

export const PillContainer = styled.div<PillContainerProps>`
  display: flex;
  align-items: center;
  border-radius: 16px;

  color: ${({ variant }) => variantMapping[variant].color};
  background-color: ${({ variant }) => variantMapping[variant].backgroundColor};
  ${getBorder};

  height: ${({ size }) => sizeMapping[size].height};
  padding: ${({ size, round }) =>
    round ? sizeMapping[size].padding - 2 : sizeMapping[size].padding}px;
  width: ${({ size, round }) => (round ? sizeMapping[size].height : 'fit-content')};

  ${({ size }) => size === 'small' && 'font-weight: 500;'};
  ${({ hasImage, imagePlaceHolder }) => (hasImage || imagePlaceHolder) && 'padding-left: 0'};
  ${({ isClickable }) => isClickable && 'cursor: pointer'};
  ${({ isEditable }) => isEditable && 'padding-right: 0px; cursor: default;'};
  ${({ variant }) => variant === 'transparent' && 'padding-right: 0px'};

  &:hover {
    ${getHoveredBackgroundColor};
    ${getHoveredBorderColor};
    ${getHoveredColor};
  }
`;

const TextContainer = styled.div<{ maxWidth?: number | string }>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`};
`;

const ImageContainer = styled.div<{ size: SizeType }>`
  height: ${({ size }) => sizeMapping[size].height};
  width: ${({ size }) => sizeMapping[size].height};
  margin-right: 6px;
`;

export const IconContainer = styled.div<{
  iconMarginRight?: number | string;
  size?: SizeType;
}>`
  display: flex;
  margin-right: ${({ iconMarginRight }) => iconMarginRight || '8px'};

  ${({ size }) => size === 'small' && 'margin-right: 4px;'};
`;

export const EditableContainer = styled.div<{ size?: SizeType }>`
  display: flex;
  cursor: pointer;
  padding: 6px;
  margin: 0 4px 0 4px;
  border-radius: 10px;

  ${({ size }) => size === 'small' && 'padding: 4px; margin: 0 2px 0 2px;'};
`;

const Image = styled.div<{ imageSrc: string }>`
  background-image: ${({ imageSrc }) => `url('${imageSrc}')`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  background-color: ${colors.action700};
  color: ${colors.action700TextColor};
  height: 100%;
  width: 100%;
`;

interface PillContentProps {
  imagePlaceHolder: React.ReactNode;
  imageSrc?: string;
  label: string;
  icon?: string | typeof MUIIcon;
  labelMaxWidth?: number;
  size: SizeType;
  onEdit?: () => void;
  variant: string;
  iconMarginRight?: string;
  toolTip?: string;
  showLabelOnTooltip?: boolean;
}

export const PillContent = ({
  imagePlaceHolder,
  imageSrc,
  label,
  icon,
  labelMaxWidth,
  size,
  onEdit,
  variant,
  iconMarginRight,
  toolTip,
  showLabelOnTooltip,
}: PillContentProps) => {
  const { hasEllipsis, nodeRef } = useEllipsisCheck();
  const labelTooltip = useTooltipUID();

  return (
    <React.Fragment>
      {icon && isString(icon) && (
        <IconContainer size={size} iconMarginRight={iconMarginRight}>
          <Icon
            name={icon}
            height={sizeMapping[size].iconSize ?? 18}
            width={sizeMapping[size].iconSize ?? 18}
            color={variantMapping[variant].color ?? colors.neutral900}
          />
        </IconContainer>
      )}
      {icon && isObject(icon) && (
        <IconContainer size={size} iconMarginRight={iconMarginRight}>
          <MUIIcon component={icon as typeof MUIIcon} sx={{ fontSize: 16 }} />
        </IconContainer>
      )}
      {imageSrc && (
        <ImageContainer size={size}>
          <Image imageSrc={imageSrc} />
        </ImageContainer>
      )}
      {!imageSrc && Boolean(imagePlaceHolder) && (
        <ImageContainer size={size}>{imagePlaceHolder}</ImageContainer>
      )}
      <TextContainer maxWidth={labelMaxWidth}>
        <Text
          {...labelTooltip.targetProps}
          ref={nodeRef}
          lineHeight={20}
          ellipsisOnOverflow
          size={getTextSize(size)}
        >
          {label}
        </Text>
      </TextContainer>
      {onEdit && (
        <EditableContainer size={size} onClick={onEdit} aria-label="Remove Pill">
          <Icon
            name="close"
            height={size === 'small' ? 6 : 8}
            width={8}
            color={variantMapping[variant].color ?? colors.neutral900}
          />
        </EditableContainer>
      )}
      {!toolTip && showLabelOnTooltip && (
        <Tooltip id={labelTooltip.uid} maxWidth={282} hide={!hasEllipsis}>
          {label}
        </Tooltip>
      )}
      {toolTip && (
        <Tooltip id={labelTooltip.uid} maxWidth={282}>
          {toolTip}
        </Tooltip>
      )}
    </React.Fragment>
  );
};

export interface PillProps {
  icon?: string | typeof MUIIcon;
  imageSrc?: string;
  imagePlaceHolder?: React.ReactNode;
  label: string;
  labelMaxWidth?: number;
  onClick?: () => void;
  onEdit?: () => void;
  size?: SizeType;
  variant?: string;
  iconMarginRight?: string;
  toolTip?: string;
  round?: boolean;
}

export const Pill = ({
  icon,
  imagePlaceHolder,
  imageSrc,
  label,
  labelMaxWidth,
  onClick,
  onEdit,
  iconMarginRight,
  toolTip,
  size = 'medium',
  variant = 'lightGray',
  round = false,
  ...pillProps
}: PillProps) => (
  <PillContainer
    size={size}
    onClick={onClick}
    variant={variant}
    isClickable={Boolean(onClick)}
    isEditable={Boolean(onEdit)}
    hasImage={Boolean(imageSrc)}
    imagePlaceHolder={imagePlaceHolder}
    round={round}
    {...pillProps}
  >
    <PillContent
      imageSrc={imageSrc}
      imagePlaceHolder={imagePlaceHolder}
      label={label}
      icon={icon}
      labelMaxWidth={labelMaxWidth}
      onEdit={onEdit}
      size={size}
      variant={variant}
      iconMarginRight={iconMarginRight}
      toolTip={toolTip}
    />
  </PillContainer>
);

export default Pill;
