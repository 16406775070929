import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import { getDataFromState } from '~/app/entities/utils';
import SelectField from '~/app/inputs/components/SelectField';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import { generateGuid } from '~/services/utils';
import Text from '~/app/shared/components/Text';
import {
  STATUS_DONE,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
} from '~/app/shared/constants';
import { map, sortBy, filter, isEqual, isEmpty, get, includes, find } from 'lodash-es';

const SelectFieldWrapper = styled.div`
  margin-bottom: 4px;
`;

const inputGetter = (fields) => (key) => {
  return fields[key].input;
};

class CalendarSingleResourceFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingAvailability: false,
      fingerPrint: [],
      roomsLoaded: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { locationId, roomsRequestStatus } = this.props;
    const { fingerPrint, roomsLoaded } = this.state;

    const newFingerPrint = [
      this.getFieldInput('starts_at').value,
      this.getFieldInput('duration').value,
      this.getFieldInput('room').value,
      this.getFieldInput('google_calendar_calendar_owner').value,
      locationId,
    ];

    if (!isEqual(newFingerPrint, fingerPrint)) {
      this.refreshRoomAvailability();
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({ fingerPrint: newFingerPrint });
    }

    /* Refresh room availability when rooms load first time */
    if (
      prevProps.roomsRequestStatus === STATUS_LOADING &&
      roomsRequestStatus === STATUS_DONE &&
      !roomsLoaded
    ) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({ roomsLoaded: true });
      this.refreshRoomAvailability();
    }

    // Waits for the id to be filled before requesting rooms
    if (roomsRequestStatus === STATUS_NOT_REQUESTED && this.getFieldInput('id').value) {
      this.handleFetchRooms(this.getFieldInput('id').value);
    }
  };

  getFieldInput = (key) => {
    const { nameMapping, names, ...fields } = this.props;
    return inputGetter(fields)(key);
  };

  getUnavailableRooms = () => {
    const {
      roomAvailability: { availability },
    } = this.props;

    const notAvailable = map(filter(availability, ['available', false]), 'email');
    return notAvailable;
  };

  refreshRoomAvailability = () => {
    const { rooms, checkRoomAvailability, locationId } = this.props;
    const roomData = find(rooms, [
      'resource_id',
      this.getFieldInput('google_calendar_resource_id').value,
    ]);

    if (!roomData) return;

    this.setState({ checkingAvailability: true });

    checkRoomAvailability(this.getFieldInput('id').value, {
      rooms_emails: [roomData.email],
      starts_at: this.getFieldInput('starts_at').value,
      duration: this.getFieldInput('duration').value,
      location_id: locationId,
    });
  };

  handleCalendarRoomChange = (values) => {
    const { rooms } = this.props;

    const roomsInfoInput = this.getFieldInput('room');

    const calendarResourceIdInput = this.getFieldInput('google_calendar_resource_id');
    const calendarResourceEmailInput = this.getFieldInput('google_calendar_resource_email');

    if (!values || isEmpty(values)) {
      roomsInfoInput.onChange('');
      calendarResourceIdInput.onChange('');
      calendarResourceEmailInput.onChange('');
      return;
    }

    const roomData = find(rooms, ['resource_id', values]);

    if (!roomData) return;

    calendarResourceIdInput.onChange(roomData.resource_id);
    calendarResourceEmailInput.onChange(roomData.email);
    roomsInfoInput.onChange(roomData.name);
  };

  handleSwitchCalendar = () => {
    const { currentUser } = this.props;

    const idInput = this.getFieldInput('id');
    const calendarOwnerInput = this.getFieldInput('google_calendar_calendar_owner');
    const calendarResourceIdInput = this.getFieldInput('google_calendar_resource_id');
    const calendarResourceEmailInput = this.getFieldInput('google_calendar_resource_email');
    const roomsInfoInput = this.getFieldInput('room');

    const newId = generateGuid();
    idInput.onChange(newId);
    calendarOwnerInput.onChange(currentUser);
    calendarResourceIdInput.onChange('');
    calendarResourceEmailInput.onChange('');
    roomsInfoInput.onChange('');

    this.handleFetchRooms(newId);
  };

  handleFetchRooms = (id) => {
    const { fetchRooms } = this.props;
    fetchRooms(id, { next: window.location.href });
  };

  render = () => {
    const {
      locationId,
      rooms,
      currentUser,
      roomAvailability,
      roomAvailabilityStatus,
      idFieldName,
      roomsRequestStatus,
      label,
    } = this.props;
    const { checkingAvailability } = this.state;

    const calendarOwnerInput = this.getFieldInput('google_calendar_calendar_owner');
    const inputValue = this.getFieldInput('google_calendar_resource_id').value;
    const unavailableRooms = this.getUnavailableRooms();

    const loadingAvailability =
      !includes([STATUS_DONE, STATUS_ERROR], roomAvailabilityStatus) && checkingAvailability;

    return (
      <React.Fragment>
        <SelectFieldWrapper>
          <SelectField
            label={label}
            key={JSON.stringify(calendarOwnerInput.value)}
            placeholder={
              locationId
                ? 'Type to search for a room'
                : 'Please, select a location before picking a room'
            }
            options={map(rooms, (room) => {
              const available = !includes(unavailableRooms, room.email);
              return {
                label: room.name,
                value: room.resource_id,
                available,
              };
            })}
            isLoading={roomsRequestStatus === STATUS_LOADING}
            disabled={!calendarOwnerInput.value || !locationId}
            input={{
              value: inputValue,
              onChange: this.handleCalendarRoomChange,
            }}
            clearable
          />
        </SelectFieldWrapper>
        <Text color={colors.neutral600} display="block">
          Google Calendar Event managed by:&nbsp;
          {(() => {
            if (calendarOwnerInput.value.id === currentUser.id) {
              return 'You';
            }
            if (calendarOwnerInput.value) {
              return (
                <span>
                  <a href={mapRoute('userProfile', { id: calendarOwnerInput.value.id })}>
                    {calendarOwnerInput.value.name}
                  </a>
                </span>
              );
            }

            return <span>PlusPlus</span>;
          })()}
        </Text>
        {loadingAvailability && (
          <Text color="#025a83" display="inline-block">
            Checking room availability, please wait...
          </Text>
        )}
        {!loadingAvailability &&
          !isEmpty(inputValue) &&
          get(roomAvailability, idFieldName) === `${this.getFieldInput('id').value}` && (
            <React.Fragment>
              {/* eslint-disable-next-line no-nested-ternary */}
              {roomAvailabilityStatus === STATUS_DONE ? (
                isEmpty(unavailableRooms) ? (
                  <Text color={colors.emphasis600} display="inline-block">
                    The room is available for the chosen date and time.
                  </Text>
                ) : (
                  <Text color={colors.error600} display="inline-block">
                    The room is not available for the chosen date and time.
                  </Text>
                )
              ) : (
                <Text color={colors.error600} display="inline-block">
                  {roomAvailability.error}
                </Text>
              )}
            </React.Fragment>
          )}
      </React.Fragment>
    );
  };
}

CalendarSingleResourceFields.propTypes = {
  names: PropTypes.array,

  nameMapping: PropTypes.object,
  locationId: PropTypes.number,
  currentUser: PropTypes.object,
  idFieldName: PropTypes.string,
  label: PropTypes.string,

  rooms: PropTypes.array,
  fetchRooms: PropTypes.func,
  roomAvailability: PropTypes.object,
  checkRoomAvailability: PropTypes.func,
  roomAvailabilityStatus: PropTypes.string,
  roomsRequestStatus: PropTypes.string,
};

/*
  I'm calling props "fields" because redux-form injects the fields to props in a flat manner
  redux-form does the magic and already return the fields flat, even if the
    component is inside a FormSection

   Ex: actual field name: additional_sessions[0].room
       shape of props: { room: '' }
       You can assert this by printing the "names" prop when this component is inside a FormSection
 */
const mapStateToProps = (state, fields) => {
  const getInput = inputGetter(fields);
  const roomsRequestState = getDataFromState(`${getInput('id').value}Rooms`, state);
  const availabilityRequestState = getDataFromState(`${getInput('id').value}Availability`, state);

  return {
    rooms: sortBy(map(roomsRequestState.data), 'name'),
    roomsRequestStatus: roomsRequestState.status,
    roomAvailability: availabilityRequestState.error || availabilityRequestState.data,
    roomAvailabilityStatus: availabilityRequestState.status,
    currentUser: state.user.currentUser,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  fetchRooms: (id, queryString) =>
    dispatch(
      actions.googleCalendar.retrieveRooms(`${id}Rooms`, {
        [props.idFieldName]: id,
        ...queryString,
      })
    ),
  checkRoomAvailability: (id, queryString) =>
    dispatch(
      actions.googleCalendar.checkRoomAvailability(`${id}Availability`, {
        [props.idFieldName]: id,
        ...queryString,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSingleResourceFields);
