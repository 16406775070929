import MUIAvatar, { AvatarProps as MUIAvatarProps } from '@mui/material/Avatar';

export interface AvatarProps extends MUIAvatarProps {
  imgGrayscale?: boolean;
}

export const Avatar = ({ imgGrayscale, sx, ...props }: AvatarProps) => {
  const appendSx = imgGrayscale ? { filter: 'grayscale(100%)' } : {};
  return <MUIAvatar sx={{ ...sx, ...appendSx }} {...props} />;
};
