import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { LEARNING_TYPES } from '~/app/catalog/constants';
import actions from '~/app/entities/actions';
import { sessionSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { permissionDeniedRedirectAlert } from '~/services/permission-denied-redirect-alert';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE } from '~/app/shared/constants';
import { useLabels, useOriginRoute, useSetDetailedObject } from '~/app/shared/hooks';
import { toLower } from 'lodash-es';

import SessionProgramFormPage from './SessionProgramFormPage';

const SessionProgramEdit = () => {
  const { public_id: sessionPublicId } = useParams();
  const { label_program: labelProgram } = useLabels();

  const [fetchSession, { data: session, status: fetchSessionStatus }] = useEntities(
    actions.sessionProgram.retrieveDetails,
    ({ statusCode }) => {
      if (statusCode === 403) {
        permissionDeniedRedirectAlert();
      }
    },
    {
      schema: sessionSchema,
    }
  );

  useEffect(() => {
    fetchSession(sessionPublicId);
  }, []);

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.programs
  }&o=upcoming`;
  const backRoute = useOriginRoute(catalogRoute);

  useSetDetailedObject({ id: null }, 'programs', []);

  if (fetchSessionStatus !== STATUS_DONE) return <Loading />;
  const initialValuesParams = { session };

  return (
    <SessionProgramFormPage
      pageTitle={`Edit ${labelProgram} Session`}
      topBarActionName="Editing Session"
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelProgram,
          link: catalogRoute,
        },
        {
          label: `Edit ${toLower(labelProgram)} session`,
        },
      ]}
    />
  );
};

export default SessionProgramEdit;
