import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import ContentTopbarPage from '~/app/shared-layouts/ContentTopbarPage';
import ContentSidebarItem from '~/app/shared-layouts/ContentTopbarPage/ContentSidebarItem';
import { useCurrentUser } from '~/app/shared/hooks';
import { IS_ADMIN_PERMISSION } from '~/app/shared/permissions';
import { includes } from 'lodash-es';

const Content = styled.div`
  margin: 0 auto;
  padding: 20px 0 40px;

  > * + * {
    margin-top: 20px;
  }
`;

const SidebarFooter = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  border-top: 1px solid ${colors.neutral200};
`;

export interface ManagerPortalPageProps {
  renderTopbar: React.ReactElement;
  children: React.ReactElement;
}

const ManagerPortalPage = ({ renderTopbar, children }: ManagerPortalPageProps) => {
  const currentUser = useCurrentUser();
  const { permissions } = currentUser;
  const history = useHistory();

  const { pathname } = useLocation();

  const isAdmin = includes(permissions, IS_ADMIN_PERMISSION);

  return (
    <ContentTopbarPage
      hideOpenSidebarButton
      renderTopbar={renderTopbar}
      renderSidebarContent={() => (
        <div>
          <ContentSidebarItem
            label="My Directs"
            icon="person"
            key="my-directs"
            selected={includes('/a/my-team/', pathname)}
            onClick={() => history.push(mapRoute('myTeam'))}
          />
          <ContentSidebarItem
            label="Directs assignments"
            icon="person"
            key="directs-assignments"
            selected={includes('/a/team-assignments/', pathname)}
            onClick={() => history.push(mapRoute('teamAssignments'))}
          />
        </div>
      )}
      renderSidebarFooter={() => (
        <SidebarFooter>
          {isAdmin && (
            <ContentSidebarItem
              key="settings"
              icon="settings"
              label="Settings"
              iconColor={colors.neutral500}
              labelColor={colors.neutral500}
              onClick={() => history.push(mapRoute('settings', { context: 'site' }))}
            />
          )}
          <ContentSidebarItem
            key="info"
            icon="info"
            label="Help & Support"
            iconColor={colors.neutral500}
            labelColor={colors.neutral500}
            onClick={() => window.open('https://plusplus.co/support/', '_blank')?.focus()}
          />
        </SidebarFooter>
      )}
    >
      <Content>{children}</Content>
    </ContentTopbarPage>
  );
};

export default ManagerPortalPage;
