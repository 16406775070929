import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { EDIT_ARTICLE_PERMISSION } from '~/app/shared/permissions';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import actions from '~/app/entities/actions';
import { articleSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { permissionDeniedRedirectAlert } from '~/services/permission-denied-redirect-alert';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { useOriginRoute, useSetDetailedObject, useLabels } from '~/app/shared/hooks';
import { toLower } from 'lodash-es';

import ArticleFormPage from './ArticleFormPage';

const ArticleEdit = () => {
  const { public_id: publicId } = useParams();
  const { label_article: labelArticle, label_article_plural: labelArticlePlural } = useLabels();

  const [userCanSee, setUserCanSee] = useState(false);

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.articles
  }&o=upcoming`;
  const articleDetailRoute = mapRoute('standAloneArticleDetail', { public_id_and_slug: publicId });
  const backRoute = useOriginRoute(articleDetailRoute);

  const [fetchArticle, { data: article, status }] = useEntities(
    actions.article.retrieveDetails,
    ({ data: article, status }) => {
      if (status === STATUS_DONE) {
        if (article.permissions?.includes(EDIT_ARTICLE_PERMISSION)) {
          setUserCanSee(true);
        } else {
          permissionDeniedRedirectAlert();
        }
      }
    },
    {
      schema: articleSchema,
    }
  );

  useEffect(() => {
    fetchArticle(publicId);
  }, []);

  useSetDetailedObject({ id: publicId }, 'articles', [publicId]);

  if (status === STATUS_LOADING || !userCanSee) return <Loading />;

  const initialValuesParams = { article };

  return (
    <ArticleFormPage
      pageTitle={`Edit ${labelArticle}`}
      topBarActionName={`Editing ${labelArticle}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelArticlePlural,
          link: catalogRoute,
        },
        {
          label: article.name,
          link: articleDetailRoute,
        },
        {
          label: `Edit ${toLower(labelArticle)}`,
        },
      ]}
    />
  );
};

export default ArticleEdit;
