import { LEARNING_TYPES } from '~/app/catalog/constants';
import {
  useCurrentUser,
  useModuleToggles,
  useToggles,
  useQueryParams,
  useHasChannelPermission,
} from '~/app/shared/hooks';
import * as permissionConstants from '~/app/shared/permissions';
import { includes } from 'lodash-es';

export function useCreateLearningPermissions() {
  const {
    permissions,
    scorm_cloud_integration_toggle: toggleCourses,
    catalog_sections_order: catalogSectionsOrder,
  } = useCurrentUser();

  const { programs: togglePrograms, tracks: toggleSnippets } = useModuleToggles();
  const { toggle_mentorship_programs: toggleMentorshipPrograms } = useToggles();
  const { fixed, learning_types: selectedLearningTypes } = useQueryParams();

  const isNotInFixedViewMode = !fixed;

  const canCreateEventType =
    includes(permissions, permissionConstants.CREATE_EVENT_TYPE_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.event_types));
  const canCreateProgram =
    (togglePrograms || includes(catalogSectionsOrder, LEARNING_TYPES.programs)) &&
    includes(permissions, permissionConstants.CREATE_PROGRAM_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.programs));
  const canCreateMentorshipProgram =
    toggleMentorshipPrograms &&
    includes(permissions, permissionConstants.CREATE_PROGRAM_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.mentorship_programs));
  const canCreateTrack =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.tracks)) &&
    includes(permissions, permissionConstants.CREATE_TRACK_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.tracks));
  const canCreateArticle =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.articles)) &&
    includes(permissions, permissionConstants.CREATE_ARTICLE_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.articles));
  const canCreateCodelab =
    includes(catalogSectionsOrder, LEARNING_TYPES.codelabs) &&
    includes(permissions, permissionConstants.CREATE_CODELAB_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.codelabs));
  const canCreateCourses =
    toggleCourses &&
    includes(catalogSectionsOrder, LEARNING_TYPES.courses) &&
    includes(permissions, permissionConstants.CREATE_COURSE_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.courses));
  const canCreateVideo =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.videos)) &&
    includes(permissions, permissionConstants.CREATE_VIDEO_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.videos));
  const canCreateAssessment =
    includes(catalogSectionsOrder, LEARNING_TYPES.assessments) &&
    includes(permissions, permissionConstants.CREATE_ASSESSMENT_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.assessments));
  const canCreateLinkedContent =
    includes(catalogSectionsOrder, LEARNING_TYPES.linkedcontent) &&
    includes(permissions, permissionConstants.CREATE_LINKEDCONTENT_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.linkedcontent));
  const canCreateChannel = useHasChannelPermission(permissionConstants.CREATE_CHANNEL_PERMISSION);

  const canCreateAnyLearningType =
    canCreateEventType ||
    canCreateProgram ||
    canCreateMentorshipProgram ||
    canCreateTrack ||
    canCreateArticle ||
    canCreateCodelab ||
    canCreateCourses ||
    canCreateVideo ||
    canCreateAssessment ||
    canCreateLinkedContent ||
    canCreateChannel;

  const allPermissions = {
    canCreateEventType,
    canCreateProgram,
    canCreateMentorshipProgram,
    canCreateTrack,
    canCreateArticle,
    canCreateCodelab,
    canCreateCourses,
    canCreateVideo,
    canCreateAssessment,
    canCreateLinkedContent,
    canCreateChannel,
  };

  return [canCreateAnyLearningType, allPermissions];
}
