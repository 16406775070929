import { Card, CardProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import colors from '~/services/colors';

export const ContentItemCardRoot = styled(
  (cardProps: CardProps) => <Card variant="outlined" {...cardProps} />,
  {
    name: 'ContentItemCard',
    slot: 'root',
  }
)(() => ({
  height: '480px',
  width: '312px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.neutral0,
  border: '1px solid',
  borderColor: colors.neutral200,
  borderRadius: '8px',
}));
