import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { useEncodedCurrentRoute } from '~/app/shared/hooks';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import Text from '~/app/shared/components/Text';

import UserAvatarImage from './UserAvatarImage';

const UserBubbleContainer = styled.div`
  width: 356px;
  padding: 16px;
`;

const BubbleAvatarImage = styled(UserAvatarImage)`
  margin-right: 8px;
`;

const UserInfoWrapper = styled.div`
  max-width: 230px;

  ${Text} {
    margin-bottom: 2px;

    &:first-of-type {
      margin-bottom: 4px;
    }

    &:last-of-type {
      margin-bottom: 16px;
    }
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  min-height: 110px;
`;

const CTAContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const UserBubble = ({
  badgeLeft,
  badgeSize,
  badgeTop,
  className,
  disableProfileLink,
  imgGrayscale,
  sendEmailCTA,
  showBadge,
  showEmail,
  user,
}) => {
  const {
    id,
    display_name: displayName,
    email,
    title,
    department,
    working_since: workingSince,
    location,
  } = user;

  const currentRoute = useEncodedCurrentRoute();

  const titleText = `${title} ${department && ` • ${department}`} `;
  const workingSinceText = `Employee since ${moment.utc(workingSince).format('ll')}`;
  const locationText = location?.name;

  return (
    <UserBubbleContainer>
      <ProfileContainer>
        <BubbleAvatarImage
          hasBorder={false}
          size="large"
          badgeLeft={badgeLeft}
          badgeSize={badgeSize}
          badgeTop={badgeTop}
          className={className}
          disableProfileLink={disableProfileLink}
          imgGrayscale={imgGrayscale}
          showBadge={showBadge}
          user={user}
        />

        <UserInfoWrapper>
          <Text block medium size="h3" color={colors.neutral900}>
            {displayName}
          </Text>

          {showEmail && email && (
            <Text block size="h6" color={colors.neutral600}>
              {email}
            </Text>
          )}

          {title && (
            <Text block size="h5" color={colors.neutral600}>
              {titleText}
            </Text>
          )}

          {workingSince && (
            <Text block size="h5" color={colors.neutral600}>
              {workingSinceText}
            </Text>
          )}

          {location && (
            <Text block size="h5" color={colors.neutral600}>
              <Icon noTransition name="location" width={12} height={12} color={colors.neutral600} />{' '}
              {locationText}
            </Text>
          )}
        </UserInfoWrapper>
      </ProfileContainer>

      <CTAContainer>
        {sendEmailCTA && email && (
          <Button fullWidth size="small" color="secondary" href={`mailto: ${email}`}>
            Send Email
          </Button>
        )}
        <Button
          fullWidth
          size="small"
          color="primary"
          route={`${mapRoute('userProfile', { id })}?origin=${currentRoute}`}
        >
          View Profile
        </Button>
      </CTAContainer>
    </UserBubbleContainer>
  );
};

UserBubble.propTypes = {
  user: PropTypes.object,
  sendEmailCTA: PropTypes.bool,
  showEmail: PropTypes.bool,

  showBadge: PropTypes.bool,

  badgeLeft: PropTypes.number,
  badgeSize: PropTypes.string,
  badgeTop: PropTypes.number,
  className: PropTypes.string,
  disableProfileLink: PropTypes.bool,
  imgGrayscale: PropTypes.bool,
};

export default UserBubble;
