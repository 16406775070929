import moment from 'moment-timezone';

import { endsWith } from 'lodash-es';

// The calendar component requires Date objects to work. This workaround is needed
// because the moment.toDate() method ignores the object's timezone, always using
// the machine's local timezone and we want to keep the user's timezone.

// The calendar component requires Date objects to work. This workaround is needed
// because the moment.toDate() method ignores the object's timezone, always using
// the machine's local timezone and we want to keep the user's timezone.
export const parseMomentToDate = (momentObj: moment.Moment) =>
  new Date(momentObj.format('YYYY-MM-DD HH:mm'));

/**
 * Returns a tuple response from a promise.
 * The first element would be an error if the promise threw.
 * The second element is the promise response
 *
 * @param promise
 * @returns
 */
export function to<T, E = Error>(promise: Promise<T>) {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[E, undefined]>((error) => [error, undefined]);
}

/**
 * Returns a possesive version of the input string
 *
 * possess("Sam") => "Sam's"
 * possess("Carlos") => "Carlos'"
 */
export function possess(s: string) {
  if (!s) return '';
  if (endsWith(s, 's')) return `${s}'`;
  return `${s}'s`;
}
