import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import FieldError from '~/app/inputs/components/FieldError';
import SessionEnrollmentMethodsFieldWrapper from '~/app/program/components/SessionFormShared/SessionEnrollmentMethodsFieldWrapper';
import {
  MultipleAttendees,
  SingleAttendee,
} from '~/app/program/components/SessionFormShared/SessionFormSharedAttendances';
import { FormFieldGroup } from '~/app/shared/components/Form';
import InfoText from '~/app/shared/components/InfoText';
import * as formValidations from '~/app/shared/formValidations';
import { useCurrentUser, useFormMeta, useLabels } from '~/app/shared/hooks';
import { get } from 'lodash-es';

const validateRequired = formValidations.required();

const AttendanceTypeGroup = styled(FormFieldGroup)`
  display: flex;
  justify-content: space-between;
  align-items: start;

  > * + * {
    margin-top: 0;
    margin-left: 16px;
  }
`;

const AttendanceTypeContainer = ({ form, touch }) => {
  const {
    label_program_sessions_attendee_title_single: singleSessionAttendeeTitle,
    label_program_sessions_attendee_title_plural: multipleSessionAttendeeTitle,
  } = useLabels();

  const attendanceTypeMeta = useFormMeta(form, 'attendance_type', {});

  useEffect(() => {
    if (!get(attendanceTypeMeta, 'touched')) {
      touch('attendance_type');
    }
  }, [attendanceTypeMeta]);

  return (
    <>
      <InfoText top={0} bottom={8} content="Select the session type below." />
      <Field name="attendance_type" component={FieldError} />
      <AttendanceTypeGroup>
        <Field
          name="attendance_type"
          component={SingleAttendee}
          singleAttendeeLabel={singleSessionAttendeeTitle}
          isNewForm
          formKey={form}
        />
        <Field
          name="attendance_type"
          component={MultipleAttendees}
          multipleAttendeesLabel={multipleSessionAttendeeTitle}
          isNewForm
          formKey={form}
        />
      </AttendanceTypeGroup>
    </>
  );
};

AttendanceTypeContainer.propTypes = {
  form: PropTypes.string,
  touch: PropTypes.func,
};

const EnrollmentMethodsContainer = ({ form, touch, sessionAllowsOnline, locationId }) => {
  const { use_calendar_resources_for_sessions: bookCalendarRooms } = useCurrentUser();

  const enrollmentMethodsMeta = useFormMeta(form, 'enrollment_methods', {});

  useEffect(() => {
    if (!get(enrollmentMethodsMeta, 'touched')) {
      touch('enrollment_methods');
    }
  }, [enrollmentMethodsMeta]);

  const meetingUrlValidators = useMemo(() => {
    if (!sessionAllowsOnline) return;
    if (!bookCalendarRooms) {
      return [formValidations.url, validateRequired];
    }
    return [formValidations.url];
  }, [sessionAllowsOnline, bookCalendarRooms]);

  return (
    <>
      <InfoText bottom={8} content="Select the enrollment methods for this session below." />
      <Field name="enrollment_methods" component={FieldError} />

      <SessionEnrollmentMethodsFieldWrapper
        bookCalendarRooms={bookCalendarRooms}
        locationId={locationId}
        meetingUrlValidators={meetingUrlValidators}
        isNewForm
      />
    </>
  );
};

EnrollmentMethodsContainer.propTypes = {
  form: PropTypes.string,
  touch: PropTypes.func,
  sessionAllowsOnline: PropTypes.bool,
  locationId: PropTypes.number,
};

const SessionEnrollmentCapSection = ({ fieldsValues, form, touch, isMentorshipSession }) => {
  return (
    <>
      {!isMentorshipSession && <AttendanceTypeContainer form={form} touch={touch} />}
      <EnrollmentMethodsContainer
        form={form}
        touch={touch}
        sessionAllowsOnline={fieldsValues.allows_online}
        locationId={fieldsValues.location_id}
      />
    </>
  );
};

SessionEnrollmentCapSection.propTypes = {
  fieldsValues: PropTypes.object,
  form: PropTypes.string,
  touch: PropTypes.func,
  isMentorshipSession: PropTypes.bool,
};

export default SessionEnrollmentCapSection;
