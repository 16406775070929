import PropTypes from 'prop-types';
import React from 'react';

import { CreateFormTopBar, EditFormTopBar } from '~/app/shared/components/FormTopBar';

const FormTopBar = ({
  isEdit,
  actionName,
  title,
  backRoute,
  loading,
  onSaveAndDuplicateHandler,
  onSaveAndBulkDuplicateHandler,
  onSaveAndApplyHandler,
  disableSave,
}) => {
  const TopBarComponent = isEdit ? EditFormTopBar : CreateFormTopBar;
  return (
    <TopBarComponent
      action={actionName}
      disableSave={disableSave}
      title={title || 'Untitled'}
      backRoute={backRoute}
      loading={loading}
      onSaveAndDuplicateHandler={onSaveAndDuplicateHandler}
      onSaveAndBulkDuplicateHandler={onSaveAndBulkDuplicateHandler}
      onSaveAndApplyHandler={onSaveAndApplyHandler}
    />
  );
};

FormTopBar.propTypes = {
  isEdit: PropTypes.bool,
  actionName: PropTypes.string,
  title: PropTypes.string,
  backRoute: PropTypes.string,
  loading: PropTypes.bool,
  disableSave: PropTypes.bool,
  onSaveAndDuplicateHandler: PropTypes.func,
  onSaveAndBulkDuplicateHandler: PropTypes.func,
  onSaveAndApplyHandler: PropTypes.func,
};

FormTopBar.defaultProps = {
  isEdit: false,
  loading: false,
};

export default FormTopBar;
