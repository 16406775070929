import { useSortable, verticalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Text from '~/app/shared/components/Text';
import { useEllipsisCheck } from '~/app/shared/hooks';
import { TrackItem } from '~/app/tracks/interfaces';
import { map } from 'lodash-es';
import { Box, IconButton, Tooltip } from '@mui/material';
import { DragIndicatorIcon, KeyboardArrowRightIcon } from '~/vendor/mui-icons';

interface SectionSidebarItemProps {
  id: string;
  label: string;
  onReorder?: () => void;
  renderTrackItem: (item: TrackItem) => void;
  trackItems: TrackItem[];
}

interface SortableElementProps {
  transform?: string;
  transition?: string;
}

const SortableElement = styled.div<SortableElementProps>`
  transform: ${({ transform }) => transform};
  transition: ${({ transition }) => transition};
`;

const SectionSidebarItem = ({
  id,
  label,
  onReorder,
  renderTrackItem,
  trackItems = [],
}: SectionSidebarItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { nodeRef, hasEllipsis } = useEllipsisCheck({ multipleLines: true });

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  return (
    <SortableElement
      ref={setNodeRef}
      transform={CSS.Transform.toString(transform)}
      transition={transition}
    >
      <Box key={id} id={id} border={`1px solid ${colors.neutral200}`} borderRadius="2px">
        <Box
          aria-label="Button"
          display="flex"
          alignItems="center"
          padding="8px 8px 8px 0"
          color={colors.neutral900}
          borderBottom={`1px solid ${colors.neutral200}`}
        >
          {onReorder && (
            <Box {...attributes} {...listeners}>
              <IconButton size="small" sx={{ color: colors.neutral200 }}>
                <DragIndicatorIcon cursor="grab" />
              </IconButton>
            </Box>
          )}

          <Box display="flex" flex={1} alignItems="center" gap="8px" color={colors.neutral900}>
            <IconButton
              size="small"
              sx={{
                cursor: 'pointer',
                color: colors.neutral200,
                transform: isExpanded ? 'rotate(90deg)' : 'inherit',
              }}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
            <Tooltip title={label} disableHoverListener={!hasEllipsis} arrow>
              <Text size="h5" ellipsisOnLine={1} ref={nodeRef}>
                {label}
              </Text>
            </Tooltip>
          </Box>
        </Box>

        <SortableContext
          items={map(trackItems, 'content_item.public_id')}
          strategy={verticalListSortingStrategy}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="4px"
            padding="4px"
            sx={{
              backgroundColor: colors.neutral50,
              ...(isExpanded && { minHeight: '70px' }),
            }}
          >
            {isExpanded && map(trackItems, (trackItem) => renderTrackItem(trackItem))}
          </Box>
        </SortableContext>
      </Box>
    </SortableElement>
  );
};

export default SectionSidebarItem;
