import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { mapRoute } from '~/services/requests';
import Pill from '~/app/shared/components/Pill';
import { UserPopover } from '~/app/shared/components/UserAvatar';
import UserProfileInitials from '~/app/shared/components/UserAvatar/UserProfileInitials';
import { filter, map, size, take } from 'lodash-es';

const PillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  gap: 8px;
`;

const UserPill = ({ user, pillLabelMaxWidth }) => {
  return (
    <Pill
      variant="lighterGray"
      imageSrc={user.profile_image}
      label={user.display_name}
      labelMaxWidth={pillLabelMaxWidth}
      key={user.display_name}
      showLabelOnTooltip={false}
      imagePlaceHolder={<UserProfileInitials userName={user.name} />}
    />
  );
};

UserPill.propTypes = {
  user: PropTypes.object,
  pillLabelMaxWidth: PropTypes.number,
};

const UserSmallerPillList = ({
  users,
  maxUsers,
  labelMaxLength,
  showPersonalizedMaxLength,
  isCard,
}) => {
  if (!users) return null;

  // Removes invalid users that might get here without an ID.
  // https://app.asana.com/0/981225105302110/1202377405155834
  const presentableUsers = filter(users, (user) => Boolean(user?.id));

  const defaultLabelMaxLength = 110;
  const usersCount = size(presentableUsers);
  const hasMore = usersCount > maxUsers;

  const pillLabelMaxWidth =
    hasMore || showPersonalizedMaxLength ? labelMaxLength : defaultLabelMaxLength;

  return (
    <PillsContainer>
      {map(take(presentableUsers, maxUsers), (user, index) => {
        if (isCard) {
          return (
            <Link
              key={`${index}-${user.id}`}
              to={mapRoute('userProfile', { id: user.id })}
              alt={`Go to ${user.display_name} profile page`}
            >
              <UserPill user={user} pillLabelMaxWidth={pillLabelMaxWidth} />
            </Link>
          );
        }

        return (
          <UserPopover
            key={`${index}-${user.id}`}
            showPopover={false}
            sendEmailCTA
            user={user}
            renderPopoverHoverable={<UserPill user={user} pillLabelMaxWidth={pillLabelMaxWidth} />}
          />
        );
      })}
      {hasMore && <Pill variant="lighterGray" label={`+${usersCount - maxUsers}`} />}
    </PillsContainer>
  );
};

UserSmallerPillList.propTypes = {
  users: PropTypes.array,
  maxUsers: PropTypes.number,
  labelMaxLength: PropTypes.number,
  showPersonalizedMaxLength: PropTypes.bool,
  isCard: PropTypes.bool,
};

UserSmallerPillList.defaultProps = {
  showPersonalizedMaxLength: false,
};

export default UserSmallerPillList;
