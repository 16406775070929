import moment from 'moment';

import { isEmpty, isNil, map, orderBy, find } from 'lodash-es';

export const validateOverlappingTimeslots = (timeslots) => {
  const sortedTimeslots = orderBy(timeslots, ['starts_at'], ['asc']);

  const errors = map(sortedTimeslots, (timeslot, index) => {
    const timeslotB = sortedTimeslots[index + 1];
    if (!timeslotB) return null;

    const aStartsAt = moment(timeslot.starts_at);
    const aEndsAt = moment(timeslot.starts_at).add(timeslot.duration);

    const bStartsAt = moment(timeslotB.starts_at);
    const bEndsAt = moment(timeslotB.starts_at).add(timeslotB.duration);

    const latestStartsAt = moment.max([aStartsAt, bStartsAt]);
    const earliestEndsAt = moment.min([aEndsAt, bEndsAt]);

    if (earliestEndsAt - latestStartsAt > 0) {
      return 'Timeslots cannot happen at the same time of another.';
    }

    return null;
  });

  return find(errors, Boolean);
};

export const validateTimeslots = (timeslots) => {
  const requiredErrorMessage = 'This field is required.';

  const requiredErrors = map(timeslots, (timeslot) => ({
    starts_at: isNil(timeslot.starts_at) ? requiredErrorMessage : null,
    duration: isNil(timeslot.duration) || isEmpty(timeslot.duration) ? requiredErrorMessage : null,
  }));

  const hasStartsAtErrors = requiredErrors.some((error) => error.starts_at);
  const hasDurationErrors = requiredErrors.some((error) => error.duration);

  const overlappingTimeslotsError = !hasStartsAtErrors &&
    !hasDurationErrors && { _error: validateOverlappingTimeslots(timeslots) };

  return { ...requiredErrors, ...overlappingTimeslotsError };
};
