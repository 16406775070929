import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import propTypes from '~/services/prop-types';
import Loading from '~/app/shared/components/Loading';
import { STATUS_LOADING, STATUS_NOT_REQUESTED } from '~/app/shared/constants';
import { startsWith } from 'lodash-es';

const PageWrapper = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Django pages does not need the loading
  const isLoadingIndicatorEnabled = !startsWith(currentPath, '/backoffice');

  const userStatus = useSelector((state) => state.user.currentUserStatus);

  if (
    isLoadingIndicatorEnabled &&
    (userStatus === STATUS_NOT_REQUESTED || userStatus === STATUS_LOADING)
  ) {
    return <Loading />;
  }

  return children;
};

PageWrapper.propTypes = {
  children: propTypes.anyChildren,
};

export default PageWrapper;
