import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import Text from '~/app/shared/components/Text';
import UserAvatar, { UserPopover } from '~/app/shared/components/UserAvatar';
import { useLabels, useCurrentUser } from '~/app/shared/hooks';
import { get, find, toLower } from 'lodash-es';

const UsersInfoContainer = styled.div`
  display: block;
  margin-bottom: 12px;
`;

const Avatar = styled(UserAvatar)`
  position: relative;
  display: inline-block;
`;
const SecondAvatar = styled(UserAvatar)`
  position: relative;
  display: inline-block;
  margin-left: -10px;
`;

export const UserTexts = styled(Text)`
  ${({ noMarginLeft }) => !noMarginLeft && `margin-left: 8px;`}
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  max-width: 50%;

  a {
    color: ${colors.action600};
  }
`;

const UsersInfo = ({
  session,
  currentUserIsHost,
  componentName,
  sessionAttendanceLimit,
  sessionEnrollmentsCount,
}) => {
  const {
    label_mentorship_sessions_creator_title_single: mentorshipSessionCreatorLabel,
    label_program_sessions_creator_title_single: programSessionCreatorLabel,
    label_mentorship_sessions_attendee_title_single: mentorshipSessionAttendeeLabel,
    label_program_sessions_attendee_title_single: programSessionAttendeeLabel,
  } = useLabels();

  const currentUser = useCurrentUser();

  const host = currentUserIsHost ? currentUser : session.host;
  const attendee = currentUserIsHost
    ? get(session, 'attendees.0', false)
    : find(session.attendees, { id: currentUser.id });

  let hasCapacityToMultipleAttendees = true;
  if (componentName === 'hostingSessions' && sessionAttendanceLimit === 1) {
    hasCapacityToMultipleAttendees = false;
  }

  let card = null;

  if (componentName === 'hostingSessions') {
    if (attendee) {
      if (hasCapacityToMultipleAttendees) {
        card = (
          <>
            <Avatar user={attendee} hasBorder={false} size="small" />
            {session.enrollments.length > 1 && (
              <SecondAvatar user={session.enrollments[1].attendee} hasBorder={false} size="small" />
            )}
            {session.enrollments.length > 2 && (
              <SecondAvatar user={session.enrollments[2].attendee} hasBorder={false} size="small" />
            )}
            <UserTexts medium primary size="h5">
              <Link to={mapRoute('userProfile', { id: attendee.id })}>{attendee.name} </Link>
            </UserTexts>
            <UserTexts medium primary size="h5">
              {sessionEnrollmentsCount > 1 && `+${sessionEnrollmentsCount - 1} more`}
            </UserTexts>
          </>
        );
      } else {
        card = (
          <>
            <Avatar user={attendee} hasBorder={false} size="small" />
            <UserTexts medium primary size="h5">
              <Link to={mapRoute('userProfile', { id: attendee.id })}>{attendee.name}</Link>
            </UserTexts>
          </>
        );
      }
    } else {
      card = (
        <UserTexts medium primary size="h5" noMarginLeft>
          No{' '}
          {session.program_id
            ? toLower(programSessionAttendeeLabel)
            : toLower(mentorshipSessionAttendeeLabel)}{' '}
          yet
        </UserTexts>
      );
    }
  } else {
    card = (
      <>
        <Avatar user={host} hasBorder={false} size="small" />
        <UserTexts medium primary size="h5">
          <Link to={mapRoute('userProfile', { id: host.id })}>
            {session.program_id ? programSessionCreatorLabel : mentorshipSessionCreatorLabel}:{' '}
            {host.name}
          </Link>
        </UserTexts>
      </>
    );
  }

  return (
    <UsersInfoContainer>
      <UserPopover
        showPopover
        sendEmailCTA
        user={componentName === 'hostingSessions' ? attendee || host : host}
        renderPopoverHoverable={card}
      />
    </UsersInfoContainer>
  );
};

UsersInfo.propTypes = {
  session: PropTypes.object,
  currentUserIsHost: PropTypes.bool,
  componentName: PropTypes.string,
  sessionAttendanceLimit: PropTypes.number,
  sessionEnrollmentsCount: PropTypes.number,
};

export default UsersInfo;
