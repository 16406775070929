import { isUndefined } from 'lodash-es';

export function isLocalStorageAvailable() {
  const { localStorage } = window;
  if (!isUndefined(localStorage)) {
    try {
      localStorage.setItem('feature_test', 'yes');
      if (localStorage.getItem('feature_test') === 'yes') {
        localStorage.removeItem('feature_test');
        return true;
      }
      return false;
    } catch {
      return false;
    }
  } else {
    return false;
  }
}
