import queryString from 'query-string';
import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { listQueryKeyFactory } from '~/app/data-fetching/utils';
import { ApiURLs, fetchURL } from '~/services/requests-base';

type ChannelQueryParams = {
  view_mode: string;
  page_size: number;
  q?: string;
};

const fetchChannel = async (slug: string, viewMode?: string) => {
  let url = ApiURLs['channels_api:retrieve_update_destroy']({ slug });

  if (viewMode) {
    url += `?view_mode=${viewMode}`;
  }

  const { data: response } = await fetchURL(url);
  return response;
};

const filters = async (params: ChannelQueryParams) => {
  const baseUrl = ApiURLs['channels_api:list_create']();
  const url = `${baseUrl}?${queryString.stringify(params)}`;

  const { data: response } = await fetchURL(url);
  return response;
};

export const channels = createQueryKeys('channels', {
  list: listQueryKeyFactory({ endpointName: 'channels_api:list_create' }),
  detail: (slug: string, viewMode?: string) => ({
    queryKey: [slug, viewMode],
    queryFn: () => fetchChannel(slug, viewMode),
  }),
  list_themes: listQueryKeyFactory({ endpointName: 'channels_api:list_themes' }),
  filters: (params: ChannelQueryParams) => ({
    queryKey: [params],
    queryFn: () => filters(params),
  }),
});

export type SegmentsQueryKeys = inferQueryKeys<typeof channels>;
