import PropTypes from 'prop-types';
import React from 'react';

import Modal, { ModalBody } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';

export const MessageModal = ({ title, message, show, handleClose }) => {
  if (!show) {
    return null;
  }

  return (
    <Modal title={title} width={window.innerWidth > 768 ? 600 : 'auto'} handleClose={handleClose}>
      <ModalBody>
        <Text size="h4">{message}</Text>
      </ModalBody>
    </Modal>
  );
};

MessageModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,

  show: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default MessageModal;
