import actions from '~/app/entities/actions';
import { mentorshipRequestSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { toast } from '~/app/notifications/components/NotificationCenter';
import {
  MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR,
  MENTORSHIP_REQUEST_KIND_SESSION_REQUEST,
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE,
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR,
  MENTORSHIP_REQUEST_STATUS_DISMISSED,
  MENTORSHIP_REQUEST_STATUS_OPEN,
} from '~/app/program/constants';
import { mapRoute, getErrorMessageFromResponse } from '~/services/requests';
import { STATUS_DONE, STATUS_ERROR } from '~/app/shared/constants';
import { useEncodedCurrentRoute } from '~/app/shared/hooks';
import { noop } from 'lodash-es';

const MESSAGE_MAP = {
  [MENTORSHIP_REQUEST_STATUS_OPEN]: {
    [MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR]: 'Mentorship request successfully reopened.',
    [MENTORSHIP_REQUEST_KIND_SESSION_REQUEST]: 'Session request successfully reopened.',
    [MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE]: 'Mentee suggestion successfully reopened.',
    [MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR]: 'Mentor suggestion successfully reopened.',
  },
  [MENTORSHIP_REQUEST_STATUS_DISMISSED]: {
    [MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR]: 'Mentorship request successfully dismissed.',
    [MENTORSHIP_REQUEST_KIND_SESSION_REQUEST]: 'Session request successfully dismissed.',
    [MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE]: 'Mentee suggestion successfully dismissed.',
    [MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR]: 'Mentor suggestion successfully dismissed.',
  },
};

export const useMentorshipRequestActions = (mentorshipRequest = {}, { onChange = noop } = {}) => {
  const currentRoute = useEncodedCurrentRoute();

  const [updateMentorshipRequest] = useEntities(
    actions.mentorshipRequest.updateSubmit,
    (response) => {
      const { status, error, data } = response;

      if (status === STATUS_DONE) {
        onChange();

        const message = MESSAGE_MAP[data.status][data.kind];

        if (message) {
          toast.success(message);
        }
      }
      if (status === STATUS_ERROR) {
        const errorMessage = error || getErrorMessageFromResponse(response);
        toast.error('Error', errorMessage);
      }
    },
    {
      schema: mentorshipRequestSchema,
    }
  );

  const reviewRoute = mentorshipRequest.sender?.id
    ? mapRoute('userProfile', { id: mentorshipRequest.sender?.id })
    : '';

  const suggestedReviewRoute = mentorshipRequest.suggested?.id
    ? mapRoute('userProfile', { id: mentorshipRequest.suggested?.id })
    : '';

  const scheduleRequestedSessionRoute = `${mapRoute('sessionMentorshipCreate')}?host=${
    mentorshipRequest.receiver?.id
  }&attendee=${mentorshipRequest.sender?.id}&origin=${currentRoute}`;

  const dismiss = () => {
    const { id } = mentorshipRequest;
    updateMentorshipRequest(id, { status_for_write: MENTORSHIP_REQUEST_STATUS_DISMISSED });
  };

  const reopen = () => {
    const { id } = mentorshipRequest;
    updateMentorshipRequest(id, { status_for_write: MENTORSHIP_REQUEST_STATUS_OPEN });
  };

  return { reviewRoute, suggestedReviewRoute, dismiss, reopen, scheduleRequestedSessionRoute };
};
