import React, { Suspense } from 'react';

import { lazyWithRetry } from '~/services/utils';
import LazyErrorBoundary from '~/app/shared/components/LazyErrorBoundary';
import Loading from '~/app/shared/components/Loading';

const ReadOnlyTextEditor = lazyWithRetry(
  () => import(/* webpackChunkName: "ReadOnlyTextEditor" */ './ReadOnlyTextEditor')
);

const LazyReadOnlyTextEditor = (props) => (
  <LazyErrorBoundary>
    <Suspense fallback={<Loading />}>
      <ReadOnlyTextEditor {...props} />
    </Suspense>
  </LazyErrorBoundary>
);

export default React.memo(LazyReadOnlyTextEditor);
