import Modal, { ModalFooter } from '~/app/shared/components/Modal';
import { ADMIN_ROLE_KEY } from '~/app/shared/constants';
import { useCurrentUser } from '~/app/shared/hooks';
import colors from '~/services/colors';
import Datetime from '~/app/shared/components/Datetime';
import Pill from '~/app/shared/components/Pill';
import { map } from 'lodash-es';
import { Avatar } from '~/common/components/Avatar';
import { Typography } from '~/common/components/Typography';
import { Box, Button, Stack } from '@mui/material';
import { TestimonialsMenu } from './TestimonialsMenu';
import { ITestimonial } from './types';
import { WriteTestimonialButton } from './WriteTestimonialButton';

interface TestimonialsModalProps {
  testimonials: ITestimonial[];
  receiverId: number;
  receiverName: string;
  handleClose: () => void;
  reloadTestimonials: () => void;
  loadMoreTestimonials: () => void;
  nextPage: string | null;
}

interface TestimonialItemProps {
  testimonial: ITestimonial;
  userTimezone: any;
  showMenu: boolean;
}

const TestimonialItem = ({
  testimonial: { id, sender, text, created, is_hidden: isHidden },
  userTimezone,
  showMenu,
}: TestimonialItemProps) => {
  const props = isHidden ? { color: colors.neutral400 } : {};

  return (
    <Box p={2} data-testid="testimonial_list_item">
      <Stack direction="row" spacing={2}>
        <Avatar src={sender.profile_image} alt={sender.name} imgGrayscale={isHidden} />
        <Box sx={{ width: 544, overflow: 'auto' }}>
          <Box display="flex">
            {isHidden && (
              <>
                <Box paddingTop="2px">
                  <Pill label="Hidden" size="small" data-testid="testimonial_hidden_pill" />
                </Box>
                &nbsp;
              </>
            )}
            <Typography variant="body1" {...props}>
              {sender.name}
            </Typography>
          </Box>
          <Typography variant="body2" fontSize={12} paragraph {...props}>
            {text}
          </Typography>
          <Typography variant="body2" color={colors.neutral400}>
            <Datetime datetime={created} displayTimezone={userTimezone} timezone="UTC" isOnline />
          </Typography>
        </Box>
        {showMenu && (
          <Box height="16px" data-testid="testimonial_menu">
            <TestimonialsMenu testimonialId={id} isHidden={isHidden} />
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export const TestimonialsModal = ({
  testimonials,
  receiverId,
  receiverName,
  loadMoreTestimonials,
  reloadTestimonials,
  nextPage,
  ...props
}: TestimonialsModalProps) => {
  const { id: currentUserId, timezone: userTimezone, role: currentUserRole } = useCurrentUser();
  const canWriteATestimonial = receiverId !== currentUserId;
  const showMenu = currentUserRole === ADMIN_ROLE_KEY || receiverId === currentUserId;

  return (
    <Modal
      {...props}
      width={window.innerWidth > 680 ? 680 : null}
      title={`Testimonials - ${receiverName}`}
    >
      <Box sx={{ maxHeight: 685, overflowY: 'scroll' }} data-testid="testimonials_list_modal">
        <Stack>
          {map(testimonials, (testimonial) => {
            const { id } = testimonial;
            return <TestimonialItem key={id} {...{ testimonial, userTimezone, showMenu }} />;
          })}
          {nextPage && (
            <Box width="100%" p={1} display="flex" justifyContent="center">
              <Button
                variant="outlined"
                sx={{ color: colors.success600 }}
                onClick={loadMoreTestimonials}
              >
                Load more
              </Button>
            </Box>
          )}
        </Stack>
      </Box>
      {canWriteATestimonial && (
        <ModalFooter variant="buttons">
          <WriteTestimonialButton
            receiverId={receiverId}
            receiverName={receiverName}
            onSuccessCallback={reloadTestimonials}
          />
        </ModalFooter>
      )}
    </Modal>
  );
};
