import { useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { join, size as _size, map } from 'lodash-es';
import { Chip, Stack, Tooltip } from '@mui/material';

const ExtraTag = ({ extraTags }: { extraTags: string[] }) => {
  const extraPillLabel = `+${extraTags.length}`;
  const extraPillToolTip = join(extraTags, ', ');

  return (
    <Tooltip key={extraPillToolTip} title={extraPillToolTip}>
      <Chip label={extraPillLabel} size="medium" />
    </Tooltip>
  );
};

export interface TagListProps {
  tags: string[];
  size?: 'small' | 'medium';
  getTagUrl?: (url: string) => string;
  rows?: number;
}

export const TagList = ({ tags, size = 'medium', getTagUrl, rows = 2 }: TagListProps) => {
  const [data, setData] = useState({
    tagList: tags,
    extraTags: [] as string[],
  });
  const { tagList, extraTags } = data;
  const rowSize = 40; // 32px for Chip, 8px for gap

  const tagContainerRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (tagContainerRef.current) {
      const hasOverflowingChildren =
        tagContainerRef.current.offsetHeight < tagContainerRef.current.scrollHeight ||
        tagContainerRef.current.offsetWidth < tagContainerRef.current.scrollWidth;

      if (hasOverflowingChildren) {
        setData(({ tagList, extraTags }) => {
          const extraChild = tagList.pop();
          if (extraChild) {
            extraTags.unshift(extraChild);
          }
          return {
            tagList,
            extraTags,
          };
        });
      }
    }
  }, [
    data,
    tagContainerRef.current?.offsetHeight,
    tagContainerRef.current?.scrollHeight,
    tagContainerRef.current?.offsetWidth,
    tagContainerRef.current?.scrollWidth,
  ]);

  const chipWithTooltip = (tag: string, key?: string) => (
    <Tooltip key={key} title={tag}>
      <Chip label={tag} size={size} sx={{ maxWidth: '90px', textOverflow: 'ellipsis' }} />
    </Tooltip>
  );

  const Tags = map(tagList, (tag) => {
    if (getTagUrl) {
      return (
        <Link key={tag} to={getTagUrl(tag)}>
          {chipWithTooltip(tag)}
        </Link>
      );
    }
    return chipWithTooltip(tag, tag);
  });

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      rowGap="8px"
      gap="8px"
      maxHeight={`${rows * rowSize}px`}
      overflow="hidden"
      ref={tagContainerRef}
    >
      {Tags}
      {_size(extraTags) > 0 && <ExtraTag extraTags={extraTags} />}
    </Stack>
  );
};

export default TagList;
