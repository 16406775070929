import { mergeQueryKeys, inferQueryKeyStore } from '@lukemorales/query-key-factory';

import { assessments } from '~/app/data-fetching/queries/assessments';
import { assignments } from '~/app/data-fetching/queries/assignments';
import { bulk_user_loads } from '~/app/data-fetching/queries/bulk_user_loads';
import { catalog } from '~/app/data-fetching/queries/catalog';
import { groups } from '~/app/data-fetching/queries/groups';
import { linkedcontents } from '~/app/data-fetching/queries/linkedcontents';
import { locations } from '~/app/data-fetching/queries/locations';
import { logs } from '~/app/data-fetching/queries/logs';
import { mentorship_queries } from '~/app/data-fetching/queries/mentorship';
import { old_users } from '~/app/data-fetching/queries/old_users';
import { programs } from '~/app/data-fetching/queries/programs';
import { tags } from '~/app/data-fetching/queries/tags';
import { track_items } from '~/app/data-fetching/queries/track_items';
import { user_loads } from '~/app/data-fetching/queries/user_loads';
import { articles } from '~/features/articles/api/queries';
import { badges } from '~/features/badges/api/queries';
import { channels } from '~/features/channels/api/queries';
import { codelabs } from '~/features/codelabs/api/queries';
import { content_items } from '~/features/contentitems/api/queries';
import { courses } from '~/features/courses/api/queries';
import { event_types } from '~/features/event_types/api/queries';
import { events } from '~/features/events/api/queries';
import { my_upcoming } from '~/features/my_upcoming/api/queries';
import { segments } from '~/features/segments/api/queries';
import { surveys } from '~/features/surveys/api/queries';
import { tasks } from '~/features/tasks/api/queries';
import { tracks } from '~/features/tracks/api/queries';
import { users } from '~/features/users/api/queries';
import { videos } from '~/features/videos/api/queries';

// Add all queries from various domains to this global list

// Add all queries from various domains to this global list

export const queries = mergeQueryKeys(
  articles,
  assessments,
  assignments,
  badges,
  bulk_user_loads,
  codelabs,
  content_items,
  courses,
  event_types,
  events,
  groups,
  linkedcontents,
  locations,
  logs,
  mentorship_queries,
  my_upcoming,
  old_users,
  programs,
  surveys,
  tags,
  tasks,
  track_items,
  tracks,
  user_loads,
  users,
  videos,
  catalog,
  segments,
  channels
);

export type QueryKeys = inferQueryKeyStore<typeof queries>;
