import { Widget, WidgetContent, WidgetContentList } from '~/common/components/widget';
import { ContentItemWidgetLoadingContent } from '~/features/contentitems/components/BaseContentItemAsyncWidget';

type EmptyWidgetProps = {
  isLoading: boolean;
};

export function EmptyWidget(props: EmptyWidgetProps) {
  const { isLoading } = props;

  return (
    <Widget title="">
      <WidgetContent>
        <WidgetContentList>
          <ContentItemWidgetLoadingContent animate={isLoading} />
        </WidgetContentList>
      </WidgetContent>
    </Widget>
  );
}
