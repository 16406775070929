import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Pill from '~/app/shared/components/Pill';

const StreamingTag = (props) => {
  const { labelLiveStreamed } = props;
  return <Pill variant="primary" size="small" label={labelLiveStreamed} icon="online" />;
};

StreamingTag.propTypes = {
  labelLiveStreamed: PropTypes.string,
};

const mapStateToProps = (state) => ({
  labelLiveStreamed: state.user.currentUser.labels.label_live_streamed,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StreamingTag);
