import moment from 'moment';

import {
  ASSIGNMENT_STATES,
  BULK_ASYNC_JOB_MESSAGES,
  BULK_ASYNC_JOB_MESSAGES_LABELS,
} from '~/app/assignments/constants';
import { Assignment, ContentItem } from '~/app/shared-content-item/interfaces';
import { includes, get, isNil, map } from 'lodash-es';

export const canBeDropped = (assignment?: Assignment): boolean => {
  if (isNil(assignment)) return false;
  return includes(
    [ASSIGNMENT_STATES.not_started, ASSIGNMENT_STATES.in_progress, ASSIGNMENT_STATES.exempted],
    get(assignment, 'state')
  );
};

const suggestDate = (duration?: string | null) =>
  duration ? moment().add(moment.duration(duration)) : null;

export const getSuggestedDueAt = (content: ContentItem) => suggestDate(content.time_to_complete);
export const getSuggestedExpiresAt = (content: ContentItem) => suggestDate(content.time_to_retake);

interface BulkAsyncJobResponse {
  async_job_id: string;
  asyncJobOutputParams: any;
}

export const getBulkAsyncJobErrors = (bulkAsyncJobResponse: BulkAsyncJobResponse) => {
  const errors = get(bulkAsyncJobResponse, 'asyncJobOutputParams.errors');
  return map(errors, (error, index) => ({
    id: index,
    message: get(
      BULK_ASYNC_JOB_MESSAGES_LABELS,
      get(error, 'message', BULK_ASYNC_JOB_MESSAGES.exception_raised)
    ),
    user_id: get(error, 'params.user.id'),
    user_name: get(error, 'params.user.name'),
    content_item_public_id: get(error, 'params.content_item.public_id'),
    content_item_name: get(error, 'params.content_item.name'),
  }));
};
