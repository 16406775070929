import Pill from '~/app/shared/components/Pill';
import { styled } from '@mui/material';
import { SizeType } from '../Pill/Pill';

const StyledPill = styled(Pill)`
  > * {
    margin-right: 4px;
  }
`;

interface ArchivedPillProps {
  variant?: string;
  size?: SizeType;
}

const ArchivedPill = ({ variant = 'lightGray', size = 'large' }: ArchivedPillProps) => {
  return <StyledPill icon="archived" variant={variant} label="Archived" size={size} />;
};

export default ArchivedPill;
