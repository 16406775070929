import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { verticalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';

import { CONTENT_TYPES, CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING } from '~/app/catalog/constants';
import { useLearningTypeLabels } from '~/app/catalog/hooks';
import colors from '~/services/colors';
import * as scheduledTracksService from '~/services/scheduled-tracks';
import Text from '~/app/shared/components/Text';
import { useLabels } from '~/app/shared/hooks';
import { FullTrackSection, TrackItem, TrackSection, trackTypes } from '~/app/tracks/interfaces.js';
import { isEmpty, map, filter, forEach, toLower, isNil } from 'lodash-es';
import { Box } from '@mui/material';
import { ArchiveIcon } from '~/vendor/mui-icons';

import SectionSidebarItem from './SectionSidebarItem';
import TrackItemSidebarItem from './TrackItemSidebarItem';

interface TrackItemListProps {
  trackType: trackTypes;
  items: TrackItem[];
  trackSections: TrackSection[];
  trackStartsAt?: string;
  trackEndsAt?: string;
  onReorder: () => void;
}

const TrackItemList = (props: TrackItemListProps) => {
  const { trackType, items, trackSections, trackStartsAt, trackEndsAt, onReorder } = props;
  const { label_question_plural: labelQuestionPlural } = useLabels();
  const sensors = useSensors(useSensor(PointerSensor));

  const learningTypeLabels = useLearningTypeLabels();

  if (isEmpty(items) && isEmpty(trackSections)) {
    return (
      <Box display="flex" alignItems="center" gap="8px" padding="8px 12px">
        <Text size="h4" color={colors.neutral600}>
          No {trackType === CONTENT_TYPES.assessment ? toLower(labelQuestionPlural) : 'content'} yet
        </Text>
      </Box>
    );
  }

  // This is a little bit different from what we got from `useTrackSectionsAndItems`
  // because we need the index on the input value.
  const itemIndexOnInputMapping: Record<string, number> = {};
  forEach(items, ({ content_item: contentItem }, index) => {
    itemIndexOnInputMapping[contentItem.public_id] = index;
  });

  // Calculate the question number for assessment questions
  // Archived questions aren't counted
  let questionNumber = 1;
  const itemIndexToQuestionNumberMapping: Record<number, number> = {};
  forEach(items, ({ archival }, index) => {
    if (!archival) {
      itemIndexToQuestionNumberMapping[index] = questionNumber++;
    } else {
      itemIndexToQuestionNumberMapping[index] = -1;
    }
  });

  const getTrackItemTitle = (trackItem: TrackItem, index: number) => {
    if (trackType !== CONTENT_TYPES.assessment) return;

    if (trackItem.archival) {
      return (
        <>
          <ArchiveIcon sx={{ fontSize: '12px', marginRight: '2px' }} /> Archived
        </>
      );
    }

    return <>Question {itemIndexToQuestionNumberMapping[index]}</>;
  };

  const getTrackItemSublabel = (trackItem: TrackItem) => {
    if (trackType === CONTENT_TYPES.assessment) return;

    const {
      content_item: { content_type: contentType, humanizedDate },
    } = trackItem;

    if (contentType === CONTENT_TYPES.eventtype) {
      return 'Not Scheduled';
    }

    if (contentType === CONTENT_TYPES.event && !isNil(humanizedDate)) {
      return `${humanizedDate}`;
    }
  };

  const getTrackItemTypeLabel = (trackItem: TrackItem) => {
    if (trackType === CONTENT_TYPES.assessment) return;
    const {
      content_item: { content_type: contentType },
    } = trackItem;
    const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[contentType];
    const learningTypeLabel = learningTypeLabels[learningType];

    return learningTypeLabel;
  };

  const renderTrackContentItem = (trackItem: TrackItem) => {
    const {
      content_item: { name, public_id: publicId },
    } = trackItem;

    let warningText = '';

    if (trackType === 'scheduled_track') {
      const isItemInChronologicalOrder = scheduledTracksService.checkIfItemIsInChronologicalOrder(
        trackItem,
        items
      );

      const isItemInTrackDateRange = scheduledTracksService.checkIfItemIsInDateRange(
        trackItem,
        trackStartsAt,
        trackEndsAt
      );

      if (!isItemInChronologicalOrder) {
        warningText = 'The date is out of chronological order';
      } else if (!isItemInTrackDateRange) {
        warningText = 'The date is out of the track date range';
      }
    }

    const itemIndex: number = itemIndexOnInputMapping[publicId];

    return (
      <TrackItemSidebarItem
        key={publicId}
        id={publicId}
        label={name}
        title={getTrackItemTitle(trackItem, itemIndex)}
        subLabel={getTrackItemSublabel(trackItem)}
        contentTypeLabel={getTrackItemTypeLabel(trackItem)}
        warningText={warningText}
        trackItem={trackItem}
        onReorder={onReorder}
        dropDownButtonAriaLabel="Track Item Options"
      />
    );
  };

  const renderTrackItemSection = (trackSection: FullTrackSection) => {
    const trackSectionItems = filter(items, (item) => item.section === trackSection.id);

    return (
      <SectionSidebarItem
        key={trackSection.id}
        id={trackSection.id}
        label={trackSection.name}
        trackItems={trackSectionItems}
        onReorder={onReorder}
        renderTrackItem={(trackItem) => renderTrackContentItem(trackItem)}
      />
    );
  };

  return (
    <DndContext sensors={sensors} onDragEnd={onReorder}>
      {isEmpty(trackSections) ? (
        <SortableContext
          items={map(items, 'content_item.public_id')}
          strategy={verticalListSortingStrategy}
        >
          {map(items, renderTrackContentItem)}
        </SortableContext>
      ) : (
        <SortableContext
          items={map(trackSections, 'id') as string[]}
          strategy={verticalListSortingStrategy}
        >
          {map(trackSections, renderTrackItemSection)}
        </SortableContext>
      )}
    </DndContext>
  );
};

export default TrackItemList;
