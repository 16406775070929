import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import ImageField from '~/app/inputs/components/ImageField';
import InputLabel from '~/app/inputs/components/InputLabel';

import CoverFieldHelpText from './CoverFieldHelpText';

const FieldDetails = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 0;
  }
`;

const Label = styled(InputLabel)`
  margin-left: 16px;
`;

const CoverImageField = (props) => {
  const { helpText, allowedExtensions } = props;

  const [error, setError] = useState('');

  return (
    <>
      <ImageField
        emptyMessage="No cover image selected"
        imageWidth="312px"
        imageHeight="175px"
        allowedExtensions={allowedExtensions}
        setError={setError}
        {...props}
      />
      <FieldDetails>
        <Label>Cover</Label>
        <CoverFieldHelpText helpText={helpText} error={error} />
      </FieldDetails>
    </>
  );
};

CoverImageField.propTypes = {
  input: PropTypes.object,
  filePath: PropTypes.string,
  helpText: PropTypes.object,
  allowedExtensions: PropTypes.object,
};

export default CoverImageField;
