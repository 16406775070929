import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useLabels } from '~/app/shared/hooks';
import { grey } from '@mui/material/colors';
import { Box, Chip, SxProps, Tooltip } from '@mui/material';
import { DvrIcon, EditAttributesIcon } from '~/vendor/mui-icons';

interface VisibilityChipProps {
  content: ContentItem;
  showIcon?: boolean;
  showLabel?: boolean;
  showBgColor?: boolean;
  bg_color?: string;
  sx?: SxProps;
  size?: 'small' | 'medium';
}

const VisibilityChip = ({
  content,
  showIcon = false,
  showLabel = true,
  showBgColor = true,
  bg_color = grey[200],
  size = 'medium',
  sx = {},
}: VisibilityChipProps) => {
  const { label_track_plural: labelTrackPlural } = useLabels();
  const { is_inline: isInline } = content;

  const title = isInline
    ? `This item can be only found and consumed within ${labelTrackPlural}, and it inherits the ${labelTrackPlural} owners.`
    : `This item can be found and consumed both within and outside of ${labelTrackPlural}.`;
  const label = isInline ? 'Inline' : 'Standalone';
  const Icon = isInline ? DvrIcon : EditAttributesIcon;

  if (!showIcon && !showLabel) return null;

  return (
    <Tooltip title={title}>
      {/* Chip adds an extra space in case there is only an icon and no label */}
      {showIcon && !showLabel ? (
        <Box
          display="inline-flex"
          justifyContent="center"
          alignItems="center"
          paddingX="2px"
          borderRadius="4px"
          {...(showBgColor ? { bgcolor: bg_color } : {})}
        >
          <Icon color="action" sx={{ fontSize: size === 'small' ? '18px' : '24px' }} />
        </Box>
      ) : (
        <Chip
          {...(showLabel && { label: label })}
          {...(showIcon && { icon: <Icon /> })}
          sx={{ borderRadius: '4px', ...(showBgColor ? { bgcolor: bg_color } : {}), ...sx }}
          size={size}
        />
      )}
    </Tooltip>
  );
};

export default VisibilityChip;
