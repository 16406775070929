import { convertFromRaw } from 'draft-js';

import { CONTENT_TYPES, CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING } from '~/app/catalog/constants';
import { useLearningTypeLabels } from '~/app/catalog/hooks';
import colors from '~/services/colors';
import { formatDuration } from '~/services/datetime';
import VisibilityChip from '~/app/shared-content-item/components/VisibilityChip';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import CenteredDiv from '~/app/shared/components/CenteredDiv';
import { TrackItem, trackTypes } from '~/app/tracks/interfaces.js';
import { getTrackItemBorderStyle } from '~/app/tracks/services';
import { includes, isEmpty, isNil, get } from 'lodash-es';
import { grey } from '@mui/material/colors';
import { Typography } from '~/common/components/Typography';
import { Box, Card, CardContent, CardHeader, CardMedia } from '@mui/material';
import { RuleOutlinedIcon, ShortTextOutlinedIcon, StarIcon } from '~/vendor/mui-icons';

import OptionalChip from '../OptionalChip';

interface TrackItemCardProps {
  trackType: trackTypes;
  item: TrackItem;
  actionButtons?: React.ReactNode;
}

interface SubTitleProps {
  content: ContentItem;
  isRequired?: boolean;
  showVisibilityChip?: boolean;
}

interface DescriptionProps {
  contentBody: string;
}

const SubTitle = ({ content, isRequired = true, showVisibilityChip = false }: SubTitleProps) => {
  const { average_feedback_rating: feedbackRating, content_type: contentType, duration } = content;

  const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[contentType];
  const labels = useLearningTypeLabels();

  const textSx = { fontSize: 14, color: colors.neutral700 };
  const itemDuration = formatDuration(duration);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, alignItems: 'center' }}>
      <Typography sx={textSx}>{labels[learningType]}</Typography>
      {!isEmpty(itemDuration) && (
        <>
          <Typography sx={textSx}>&#8226;</Typography>
          <Typography sx={textSx}>{itemDuration}</Typography>
        </>
      )}
      {!isNil(feedbackRating) && feedbackRating > 0 && (
        <>
          <Typography sx={textSx}>&#8226;</Typography>
          <StarIcon fontSize="small" sx={{ color: colors.rating600 }} />
          <Typography sx={textSx}>{Math.round(feedbackRating * 10) / 10}</Typography>
        </>
      )}
      {!isRequired && <OptionalChip size="small" sx={{ height: '20px' }} />}
      {showVisibilityChip && (
        <VisibilityChip
          content={content}
          size="small"
          sx={{ height: '20px' }}
          showBgColor={false}
        />
      )}
    </Box>
  );
};

const Description = ({ contentBody }: DescriptionProps) => {
  const getPlainTextContentBody = () => {
    try {
      return convertFromRaw(JSON.parse(contentBody)).getPlainText(' ');
    } catch {
      return contentBody;
    }
  };

  return (
    <Typography
      variant="body2"
      sx={{
        color: colors.neutral500,
        overflow: 'hidden',
        overflowWrap: 'anywhere',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
      }}
    >
      {getPlainTextContentBody()}
    </Typography>
  );
};

const getQuestionIcon = (contentType: string): React.ComponentType | null => {
  if (contentType === CONTENT_TYPES.multiple_choice_question) {
    return RuleOutlinedIcon;
  }
  if (contentType === CONTENT_TYPES.text_question) {
    return ShortTextOutlinedIcon;
  }
  return null;
};

export const TrackItemCard = ({ trackType, item, actionButtons }: TrackItemCardProps) => {
  const { content_item: contentItem } = item;
  // default_cover is used in the old framework, cover_url is used in CA 2.0
  const coverUrl = contentItem.cover || contentItem.default_cover || contentItem.cover_url;
  const showVisibilityChip = trackType !== CONTENT_TYPES.assessment;
  const showDescription = !includes(item.content_item.content_type, 'question');
  const Icon = getQuestionIcon(item.content_item.content_type);

  const isQuestion = !isNil(Icon);

  const cardExtraProps = isQuestion ? {} : { borderStyle: 'none', borderRadius: '0px' };
  const contentExtraProps = isQuestion
    ? {}
    : {
        height: '114px', // same height of the image
        borderColor: grey['400'],
        borderRadius: '4px',
        ...getTrackItemBorderStyle(item),
        // To connect the box with the image in the left side
        ...(coverUrl
          ? {
              borderLeftStyle: 'none',
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
              paddingLeft: '0px',
            }
          : {}),
      };

  const coverImageWidth = 196;

  return (
    <Card
      key={contentItem.public_id}
      variant="outlined"
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        ...cardExtraProps,
      }}
    >
      {coverUrl && <CardMedia image={coverUrl} sx={{ minWidth: coverImageWidth, height: 114 }} />}
      {Icon && (
        <CenteredDiv
          width="40px"
          height="40px"
          borderRadius="4px"
          bgcolor={colors.neutral100}
          margin="16px"
          marginRight={0}
        >
          <Icon />
        </CenteredDiv>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: `calc(100% - ${coverUrl ? coverImageWidth : 0}px)`,
          ...contentExtraProps,
        }}
      >
        <CardHeader
          title={<Typography fontWeight="bold">{contentItem.name}</Typography>}
          subheader={
            <SubTitle
              content={contentItem}
              isRequired={get(item, 'is_required', true)}
              showVisibilityChip={showVisibilityChip}
            />
          }
          action={actionButtons}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: '12px 16px 4px 16px',
          }}
        />
        <CardContent
          sx={{ width: '100%', padding: '0px 16px 0px 16px', '&:last-child': { paddingBottom: 0 } }}
        >
          {showDescription && <Description contentBody={contentItem.content_body} />}
        </CardContent>
      </Box>
    </Card>
  );
};
