import { FC, useContext, useRef, useState } from 'react';

import Pill from '~/app/shared/components/Pill';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import colors from '~/services/colors';
import { HR } from '~/app/shared';
import KebabMenu, { KebabMenuItem, HandlesClose } from '~/app/shared/components/KebabMenu';
import { useToggles } from '~/app/shared/hooks';
import {
  NoTrackSection,
  FullTrackSection,
  TrackSection,
  TrackItem,
  trackTypes,
} from '~/app/tracks/interfaces';
import { getTrackItemBorderStyle } from '~/app/tracks/services';
import { every, find, get, includes, isEmpty, map } from 'lodash-es';
import { grey } from '@mui/material/colors';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import {
  EventOutlined,
  SwapHorizOutlinedIcon,
  AddCircleOutlineIcon,
  CalendarMonthIcon,
  DeleteIcon,
  DvrIcon,
  EditAttributesIcon,
  EditIcon,
  OpenInNewOutlinedIcon,
  OptionalOutlineIcon,
  RemoveCircleOutlineIcon,
  RequiredOutlineIcon,
} from '~/vendor/mui-icons';

import { AssessmentQuestionModalsContext } from '../TrackForm';

import { SectionFormModal } from './SectionFormModal';
import { TrackItemCard } from './TrackItemCard';
import { TrackItemFormHeader } from './TrackItemFormHeader';

interface IndexedTrackItem {
  item: TrackItem;
  index: number;
}
interface TrackItemContextMenuProps {
  contentType: string;
  publicId: string;
  publicIdAndSlug?: string;
  isRequired: boolean;
  isInline?: boolean;
  trackType: trackTypes;
  disableDeleteButton?: boolean;
  handleToggleIsRequired: () => void;
  handleToggleIsInline: () => void;
  handleRemoveItem: () => void;
  handleDeleteItem: () => void;
  handleChangeDueDate: () => void;
  handleScheduleEvent: () => void;
  handleSwapEvent: () => void;
  showContentModal: () => void;
}

const TrackItemContextMenu = ({
  contentType,
  publicId,
  publicIdAndSlug,
  isRequired = true,
  isInline = false,
  handleToggleIsRequired,
  handleToggleIsInline,
  handleRemoveItem,
  handleDeleteItem,
  handleChangeDueDate,
  handleScheduleEvent,
  handleSwapEvent,
  showContentModal,
  trackType,
  disableDeleteButton = false,
}: TrackItemContextMenuProps) => {
  const { dev_toggle_content_assignments_2_0: devToggleContentAssignment2 } = useToggles();
  const contentTypeRoutes = useContentTypeRoutes();
  const contextMenuRef = useRef<HandlesClose>(null);

  const callAndClose = (callable: () => void) => (e) => {
    contextMenuRef?.current?.handleClose(e);
    callable();
  };

  if (trackType === CONTENT_TYPES.assessment) {
    return (
      <KebabMenu ref={contextMenuRef}>
        <KebabMenuItem
          actionText="Edit"
          ActionIcon={EditIcon}
          color={colors.neutral600}
          onClick={callAndClose(showContentModal)}
        />
        <HR />
        <KebabMenuItem
          actionText="Delete"
          color={colors.error600}
          ActionIcon={DeleteIcon}
          disabled={disableDeleteButton}
          onClick={callAndClose(handleDeleteItem)}
        />
      </KebabMenu>
    );
  }

  return (
    <KebabMenu ref={contextMenuRef}>
      <KebabMenuItem
        actionText="Open Page"
        ActionIcon={OpenInNewOutlinedIcon}
        color={colors.neutral600}
        variant="link"
        to={contentTypeRoutes[contentType].details?.({
          public_id_and_slug: publicIdAndSlug,
        })}
        targetBlank
      />
      <KebabMenuItem
        variant="link"
        actionText="Edit"
        to={contentTypeRoutes[contentType].edit({
          public_id: publicId,
          public_id_and_slug: publicIdAndSlug,
        })}
        ActionIcon={EditIcon}
        color={colors.neutral600}
        // targetBlank is used here because otherwise the user would lose the change if it had not been saved yet.
        targetBlank
      />
      {devToggleContentAssignment2 && (
        <KebabMenuItem
          actionText={`${isRequired ? 'Set as Optional' : 'Set as Required'}`}
          ActionIcon={isRequired ? OptionalOutlineIcon : RequiredOutlineIcon}
          color={colors.neutral600}
          onClick={callAndClose(handleToggleIsRequired)}
        />
      )}
      {!includes([CONTENT_TYPES.task, CONTENT_TYPES.event], contentType) && (
        <KebabMenuItem
          actionText={`${isInline ? 'Set as Standalone' : 'Set as Inline'}`}
          ActionIcon={isInline ? EditAttributesIcon : DvrIcon}
          color={colors.neutral600}
          onClick={callAndClose(handleToggleIsInline)}
        />
      )}
      {trackType === CONTENT_TYPES.scheduled_track &&
        handleChangeDueDate &&
        contentType !== CONTENT_TYPES.event && (
          <KebabMenuItem
            actionText="Change due date"
            ActionIcon={CalendarMonthIcon}
            color={colors.neutral600}
            onClick={callAndClose(handleChangeDueDate)}
          />
        )}
      {trackType === CONTENT_TYPES.scheduled_track &&
        handleScheduleEvent &&
        contentType === CONTENT_TYPES.eventtype && (
          <KebabMenuItem
            actionText="Schedule"
            ActionIcon={EventOutlined}
            color={colors.neutral600}
            onClick={callAndClose(handleScheduleEvent)}
          />
        )}
      {trackType === CONTENT_TYPES.scheduled_track &&
        handleSwapEvent &&
        contentType === CONTENT_TYPES.event && (
          <KebabMenuItem
            actionText="Swap Event"
            ActionIcon={SwapHorizOutlinedIcon}
            color={colors.neutral600}
            onClick={callAndClose(handleSwapEvent)}
          />
        )}
      <KebabMenuItem
        actionText="Remove"
        ActionIcon={RemoveCircleOutlineIcon}
        color={colors.error600}
        onClick={() => handleRemoveItem()}
      />
    </KebabMenu>
  );
};

export interface TrackItemsSectionHeaderContextMenuProps {
  setSectionFormModal: (state: boolean) => void;
  handleRemoveItemsFromSection: () => void;
  handleRemoveSection: () => void;
  handleToggleIsInline: ({ isInline }: { isInline: boolean }) => void;
  hasItems?: boolean;
  showDestructiveTrackItemsActions?: boolean;
}

const TrackItemsSectionHeaderContextMenu = ({
  setSectionFormModal,
  handleRemoveItemsFromSection,
  handleRemoveSection,
  handleToggleIsInline,
  hasItems,
  showDestructiveTrackItemsActions = true,
}: TrackItemsSectionHeaderContextMenuProps) => {
  const contextMenuRef = useRef<HandlesClose>(null);

  return (
    <KebabMenu ref={contextMenuRef}>
      <KebabMenuItem
        onClick={(e) => {
          contextMenuRef?.current?.handleClose(e);
          setSectionFormModal(true);
        }}
        ActionIcon={EditIcon}
        actionText="Edit section details"
      />
      <KebabMenuItem
        onClick={(e) => {
          contextMenuRef?.current?.handleClose(e);
          handleToggleIsInline({ isInline: false });
        }}
        ActionIcon={EditAttributesIcon}
        actionText="Set all section as Standalone"
      />
      <KebabMenuItem
        onClick={(e) => {
          contextMenuRef?.current?.handleClose(e);
          handleToggleIsInline({ isInline: true });
        }}
        ActionIcon={DvrIcon}
        actionText="Set all section as Inline"
      />
      {showDestructiveTrackItemsActions && hasItems && (
        <KebabMenuItem
          onClick={(e) => {
            contextMenuRef?.current?.handleClose(e);
            handleRemoveItemsFromSection();
          }}
          color={colors.error600}
          ActionIcon={RemoveCircleOutlineIcon}
          actionText="Remove all items in this section"
        />
      )}
      {!hasItems && (
        <KebabMenuItem
          onClick={(e) => {
            contextMenuRef?.current?.handleClose(e);
            handleRemoveSection();
          }}
          color={colors.error600}
          ActionIcon={DeleteIcon}
          actionText="Remove this section"
        />
      )}
      {showDestructiveTrackItemsActions && hasItems && (
        <KebabMenuItem
          onClick={(e) => {
            contextMenuRef?.current?.handleClose(e);
            handleRemoveSection();
          }}
          color={colors.error600}
          ActionIcon={DeleteIcon}
          actionText="Remove this section and all items in it"
        />
      )}
    </KebabMenu>
  );
};

export interface TrackItemHeaderActionsProps {
  hasItems?: boolean;
  showDestructiveTrackItemsActions?: boolean;
  setSectionFormModal: (state: boolean) => void;
  handleRemoveItemsFromSection: () => void;
  handleRemoveSection: () => void;
  handleToggleIsInline: ({
    trackItem,
    section,
    isInline,
  }: {
    trackItem?: TrackItem;
    section?: TrackSection;
    isInline?: boolean;
  }) => void;
  handleAddContentClick: (event) => void;
}

const TrackItemHeaderActions = ({
  hasItems,
  setSectionFormModal,
  handleRemoveItemsFromSection,
  handleRemoveSection,
  handleToggleIsInline,
  handleAddContentClick,
  showDestructiveTrackItemsActions = true,
}: TrackItemHeaderActionsProps) => {
  return (
    <>
      <IconButton sx={{ color: 'primary.dark' }} onClick={(event) => handleAddContentClick(event)}>
        <AddCircleOutlineIcon />
      </IconButton>

      <TrackItemsSectionHeaderContextMenu
        {...{
          setSectionFormModal,
          handleRemoveItemsFromSection,
          handleRemoveSection,
          handleToggleIsInline,
          hasItems,
          showDestructiveTrackItemsActions,
        }}
      />
    </>
  );
};

interface BaseSectionProps {
  trackType: trackTypes;
  isEdit?: boolean;

  handleAddContentClick: (event) => void;
  handleToggleIsRequired: (item: TrackItem) => void;
  handleToggleIsInline: ({
    trackItem,
    section,
    isInline,
  }: {
    trackItem?: TrackItem;
    section?: TrackSection;
    isInline?: boolean;
  }) => void;

  handleRemoveItem: (item: TrackItem, doToast?: boolean) => void;
  handleSetEventToSchedule: (track_item: IndexedTrackItem) => void;
  handleSetItemToChangeAssignmentDueDate: (track_item: IndexedTrackItem) => void;
  handleSetEventToSwap: (track_item: IndexedTrackItem) => void;
}

interface NoSectionProps extends BaseSectionProps {
  section: NoTrackSection;
  trackItemsIds: string[] | number[];
  hasFullSection: false;

  handleAddTrackItems?: (session: TrackSection, items: any) => void;
}

interface FullSectionProps extends BaseSectionProps {
  section: FullTrackSection;
  trackItemsIds: string[] | number[];
  hasFullSection: true;

  handleAddTrackItems: (session: TrackSection, items: TrackItem[]) => void;
  handleEditSection: (session: Omit<TrackSection, 'items'>) => void;
  handleRemoveSection: (session: Omit<TrackSection, 'items'>) => void;
  handleRemoveItemsFromSection: (section: TrackSection) => void;
}

export const TrackSectionContainer: FC<NoSectionProps | FullSectionProps> = ({
  trackType,
  handleAddContentClick,
  handleToggleIsRequired,
  handleToggleIsInline,
  handleRemoveItem,
  handleSetEventToSchedule,
  handleSetItemToChangeAssignmentDueDate,
  handleSetEventToSwap,
  ...props
}: NoSectionProps | FullSectionProps) => {
  const [showSectionFormModal, setSectionFormModal] = useState(false);
  const { hasFullSection, section } = props;
  const hasItems = section.items.length > 0;
  const sectionIsOptional = isEmpty(section.items)
    ? false
    : every(section.items, { is_required: false });
  const extraStyle = sectionIsOptional
    ? { ...getTrackItemBorderStyle(get(section.items, 0)), borderColor: grey['400'] }
    : { padding: '2px' };

  const {
    showContentModal,
    showDeleteModal,
  }: {
    showContentModal: (type: string, options?: Record<string, any>) => void;
    showDeleteModal: (modalContent: any, onDelete?: () => void) => void;
  } = useContext(AssessmentQuestionModalsContext);

  if (isEmpty(section.items) && !hasFullSection) {
    return <div />;
  }

  const hasQuestionWithAnswer =
    hasFullSection &&
    !isEmpty(
      find(section.items, (trackItem) => {
        return get(trackItem, 'content_item.completed_assignments_count', 0) > 0;
      })
    );

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        borderRadius: '8px',
        ...extraStyle,
      }}
    >
      {props.hasFullSection && (
        <TrackItemFormHeader
          title={props.section.name}
          subtitle={props.section.description}
          useDraftSubtitle
          actionButtons={
            <TrackItemHeaderActions
              handleRemoveItemsFromSection={() => props.handleRemoveItemsFromSection(props.section)}
              handleRemoveSection={() => props.handleRemoveSection(props.section)}
              handleToggleIsInline={({ isInline }) =>
                handleToggleIsInline({
                  section: props.section,
                  isInline: isInline,
                })
              }
              {...{
                setSectionFormModal,
                handleAddContentClick,
                hasItems,
                showDestructiveTrackItemsActions: !hasQuestionWithAnswer,
              }}
            />
          }
        />
      )}
      {!isEmpty(section.items) && (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={1}
          sx={{
            paddingTop: hasFullSection ? 0 : 2.5,
            paddingBottom: 2.5,
            paddingX: 2.5,
          }}
        >
          {map(section.items, (item, index) => {
            const {
              content_item: {
                content_type: contentType,
                public_id_and_slug: publicIdAndSlug,
                public_id: publicId,
                completed_assignments_count: completedAssignmentsCount,
              },
            } = item;
            index = item.order; // index now is global order, not order in section
            return (
              <TrackItemCard
                key={publicId}
                trackType={trackType}
                item={item}
                actionButtons={
                  <Box sx={{ display: 'flex' }}>
                    {contentType === CONTENT_TYPES.eventtype && trackType != 'track' && (
                      <Pill
                        variant="warning200"
                        round={false}
                        icon="calendar"
                        label="Not Scheduled"
                      />
                    )}
                    <TrackItemContextMenu
                      {...{
                        contentType,
                        publicId,
                        publicIdAndSlug,
                        trackType,
                        isRequired: get(item, 'is_required', true),
                        isInline: item.content_item.is_inline,
                        handleToggleIsRequired: () => handleToggleIsRequired(item),
                        handleToggleIsInline: () => handleToggleIsInline({ trackItem: item }),
                        handleRemoveItem: () => handleRemoveItem(item),
                        handleDeleteItem: () =>
                          showDeleteModal(item.content_item, () => handleRemoveItem(item, false)),
                        handleChangeDueDate: () => {
                          handleSetItemToChangeAssignmentDueDate({ item, index });
                        },
                        handleScheduleEvent: () => {
                          handleSetEventToSchedule({ item, index });
                        },
                        handleSwapEvent: () => {
                          handleSetEventToSwap({ item, index });
                        },
                        showContentModal: () => {
                          showContentModal(contentType, { initialValues: item });
                        },
                        disableDeleteButton: completedAssignmentsCount >= 1,
                      }}
                    />
                  </Box>
                }
              />
            );
          })}
        </Stack>
      )}

      {hasFullSection && showSectionFormModal && (
        <SectionFormModal
          section={section}
          handleEditSection={(props as FullSectionProps).handleEditSection}
          handleClose={() => {
            setSectionFormModal(false);
          }}
        />
      )}
    </Paper>
  );
};
