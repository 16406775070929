import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import UserContentBlock from '~/app/users/components/UserContentBlock';
import MediaPoint from '~/app/shared/components/MediaPoint';
import UserAvatar from '~/app/shared/components/UserAvatar';

import Pills from './Pills';
import SubTitle from './SubTitle';
import Title from './Title';

const Container = styled.div`
  display: flex;
  width: inherit;

  ${MediaPoint.MobileOnly} {
    display: block;
  }
`;

const UserAvatarContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 25px;
  flex: 0 1 50px;

  @media (max-width: 600px) {
    width: 120px;
    display: block;
    margin: -44px auto 20px auto;
  }
`;

const UserInfoContainer = styled.div`
  display: inline-block;
  flex: 0 1 auto;
  width: 100%;
`;

const UserHeader = ({ profileData }) => {
  const {
    id,
    department,
    title,
    working_since: workingSince,
    location,
    location_text,
    timezone,
    manager,
    direct_count: directs,
    teammate_count: teammates,
  } = profileData;

  return (
    <UserContentBlock sx={{ padding: 2.5, marginBottom: 1 }}>
      <Container>
        <UserAvatarContainer>
          <UserAvatar size="extra-large" hasBorder={false} user={profileData} showBadge />
        </UserAvatarContainer>
        <UserInfoContainer>
          <Title profileData={profileData} />
          <SubTitle title={title} department={department} />
          <Pills
            userId={id}
            location={location}
            location_text={location_text}
            workingSince={workingSince}
            timezone={timezone}
            manager={manager}
            directs={directs}
            teammates={teammates}
          />
        </UserInfoContainer>
      </Container>
    </UserContentBlock>
  );
};

UserHeader.propTypes = {
  profileData: PropTypes.object,
};

export default UserHeader;
