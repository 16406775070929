import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Pill from '~/app/shared/components/Pill';
import Text from '~/app/shared/components/Text';
import { Tooltip } from '~/app/shared/components/Tooltip';
import { useEllipsisCheck, useTooltipUID } from '~/app/shared/hooks';

const TextContainer = styled.div`
  max-width: 95%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4px;
  max-width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 12px;
  border: 1px solid ${colors.neutral200};
  border-radius: 8px;
`;

const SlackChanelContainer = ({ slack_channel }) => {
  const labelTooltip = useTooltipUID();
  const { hasEllipsis, nodeRef } = useEllipsisCheck();

  return (
    <Container>
      <Header>
        <Title>
          <Pill
            icon="slack"
            size="medium"
            iconMarginRight="0px"
            variant="lighterGrayWithDarkerTextColor"
            round
          />
          <TextContainer>
            <ButtonLink url={slack_channel.url} variant="neutral" target="_blank">
              <Text {...labelTooltip.targetProps} ellipsisOnOverflow ref={nodeRef}>
                #{slack_channel.name}
              </Text>
              <Tooltip id={labelTooltip.uid} maxWidth={282} hide={!hasEllipsis}>
                #{slack_channel.name}
              </Tooltip>
            </ButtonLink>
          </TextContainer>
        </Title>
      </Header>
      <Text color={colors.neutral600}>Join the conversation channel on Slack!</Text>
    </Container>
  );
};

SlackChanelContainer.propTypes = {
  slack_channel: PropTypes.object,
};

export default SlackChanelContainer;
