import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Text from '~/app/shared/components/Text';

const StyledText = styled(Text)`
  margin: 8px 4px 0 8px;
`;

const OperatorLabel = ({ name }) => (
  <StyledText size="h5" color={colors.neutral500}>
    {name}
  </StyledText>
);

OperatorLabel.propTypes = {
  name: PropTypes.string.isRequired,
};

export default OperatorLabel;
