import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import DashboardCompositeFilter from '~/app/inputs/components/DashboardCompositeFilter';
import TextInput from '~/app/inputs/components/TextInput';
import { usePrevious } from '~/app/shared/hooks';
import { noop } from 'lodash-es';

const CompositeTextInput = ({ label, options, selection, value, onChange, ...props }) => {
  const [localSelection, setLocalSelection] = useState(selection);
  const [localValue, setLocalValue] = useState(value);
  const prevValue = usePrevious(value);
  const prevSelection = usePrevious(selection);

  useEffect(() => {
    if (localSelection !== selection || localValue !== value) {
      onChange({
        selection: localSelection,
        value: localValue,
      });
    }
  }, [localSelection, localValue]);

  // The prev* vars are references, so it is not necessary to add them to the dependency array
  // Both "useEffects" are to handle changes in the value due to an external force, such as
  // changing from one segment to another, but it should only happen if the current value is
  // different from the rendered previously.
  useEffect(() => {
    if (prevValue !== value && value !== localValue) {
      setLocalValue(value);
    }
  }, [value, localValue]);

  useEffect(() => {
    if (prevSelection !== selection && selection !== localSelection) {
      setLocalSelection(selection);
    }
  }, [selection, localSelection]);

  return (
    <DashboardCompositeFilter
      value={localSelection}
      onChange={(newValue) => setLocalSelection(newValue?.value || '')}
      options={options}
    >
      {({ InputProps }) => (
        <TextInput
          {...props}
          label={label}
          fullWidth={false}
          value={localValue}
          onChange={(e) => setLocalValue(e.target.value)}
          InputProps={{
            ...InputProps,
          }}
        />
      )}
    </DashboardCompositeFilter>
  );
};

CompositeTextInput.defaultProps = {
  options: [],
  onChange: noop,
};

CompositeTextInput.propTypes = {
  label: PropTypes.node,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string,
  selection: PropTypes.any,
};

export default CompositeTextInput;
