import React from 'react';

import BaseContainer from '~/app/navigation/components/BaseContainer';
import PageTitle from '~/app/shared/components/PageTitle';

import MyEvents from './MyEvents';

const MyEventsContainer = () => (
  <React.Fragment>
    <PageTitle title="My Events" />
    <BaseContainer>
      <MyEvents />
    </BaseContainer>
  </React.Fragment>
);

export default MyEventsContainer;
