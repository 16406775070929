import styled from 'styled-components';

import MediaPoint from '~/app/shared/components/MediaPoint';

export const PageContainer = styled.div<{ columnsWidth: string }>`
  width: 100%;
  margin: 40px auto;
  display: grid;
  row-gap: 24px;
  grid-template-columns: ${({ columnsWidth = 'auto' }) => columnsWidth};
  grid-template-rows: auto;
  grid-template-areas:
    'header'
    'body';

  ${MediaPoint.DesktopSm} {
    justify-content: center;
  }
`;
