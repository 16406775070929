import { useInfiniteQuery } from '@tanstack/react-query';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { UPCOMING_SHORTCUT } from '~/app/inputs/components/FlexibleDateRangeInput/DateRangeShortcuts';
import { LEARNING_TYPE_ICONS_MAPPING, LEARNING_TYPES } from '~/app/catalog/constants';
import ContentTypePill from '~/app/content-items/components/ContentTypePill';
import { useGetAllEventFiltersDefinition } from '~/app/events/hooks';
import RQLFilterBar from '~/app/filters/components/RQLFilterBar';
import RadioInput from '~/app/inputs/components/RadioInput';
import { queries } from '~/queries';
import colors from '~/services/colors';
import { displayDatetime, formatDuration } from '~/services/datetime';
import { mapRoute } from '~/services/requests';
import ButtonLink from '~/app/shared/components/ButtonLink';
import CoverImage from '~/app/shared/components/CoverImage';
import Icon from '~/app/shared/components/Icon';
import InfoBox from '~/app/shared/components/InfoBox';
import Loading from '~/app/shared/components/Loading';
import Pill from '~/app/shared/components/Pill';
import Text from '~/app/shared/components/Text';
import { Tooltip } from '~/app/shared/components/Tooltip';
import { ACCESS_LEVEL_MAPPING } from '~/app/shared/constants';
import { useCurrentUser, useLabels, useLocalStorage, useTooltipUID } from '~/app/shared/hooks';
import { getColorByType } from '~/app/shared/services';
import { get, isNil, map, pick, toLower, size, values, concat } from 'lodash-es';
import { Box, Stack } from '@mui/material';
import rql from '~/vendor/rql';

const UPCOMING_DATE_FILTER_VALUE = '-PT0H';

export const ScheduleTrackEventSelect = ({ event, inputValue, onChange }) => {
  const tooltip = useTooltipUID();

  const {
    access_level: accessLevel,
    cover,
    default_cover,
    duration,
    is_online: isOnline,
    location,
    name,
    public_id,
    tags,
    timezone,
    local_time: utcDatetime,
  } = event;

  const learningType = LEARNING_TYPES.events;
  const locationName = isOnline ? 'Online' : location?.name;
  const locationIcon = isOnline ? 'online' : 'location';
  const isEventInThePast = moment(utcDatetime).isBefore(moment());
  const isSelected = inputValue === event.public_id;

  return (
    <Box
      key={event.public_id}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        p: '12px',
        borderRadius: '4px',
        overflow: 'hidden',

        ...(isSelected && {
          backgroundColor: isEventInThePast ? colors.alert100 : colors.action100,
        }),
      }}
    >
      <RadioInput
        key={public_id}
        name={public_id}
        value={public_id}
        inputValue={inputValue}
        onChange={() => onChange(event)}
        fullSize={false}
        checkedBgColor={colors.action600}
      />
      <CoverImage
        hasBorder
        imageUrl={cover || default_cover}
        width="100px"
        height="56px"
        defaultBgColor={getColorByType(learningType, 600)}
        renderDefaultIcon={() => (
          <Icon
            name={LEARNING_TYPE_ICONS_MAPPING[learningType]}
            width={20}
            height={20}
            color={colors.neutral900}
          />
        )}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '4px',
          }}
        >
          <ContentTypePill item={event} size="medium" />
          <Text medium size="h4" color={colors.neutral900}>
            {name}
          </Text>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '4px',
            width: '460px',
            height: '24px',
            overflow: 'hidden',
            maskImage: 'linear-gradient(90deg, black 95%, transparent)',
          }}
        >
          <Pill
            icon={ACCESS_LEVEL_MAPPING[accessLevel].icon}
            size="medium"
            variant={ACCESS_LEVEL_MAPPING[accessLevel].variant}
            {...tooltip.targetProps}
            round
          />
          <Tooltip id={tooltip.uid}>{ACCESS_LEVEL_MAPPING[accessLevel].tooltipText}</Tooltip>

          <Text size="h5" color={colors.neutral700}>
            <Icon name="calendar" width={12} height={12} color={colors.neutral600} />{' '}
            {displayDatetime(utcDatetime, timezone)} • {formatDuration(duration)} •{' '}
            <Icon name={locationIcon} width={12} height={12} color={colors.neutral600} />{' '}
            {locationName}{' '}
          </Text>

          {map(tags, (tag) => (
            <Pill variant="lighterGray" label={tag} key={`event-pill-${public_id}-${tag}`} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

function getDateFromSelectedEvent(selectedEvent) {
  if (!selectedEvent) {
    return null;
  }

  return moment(selectedEvent.local_time).add(-1, 'day').format('YYYY-MM-DD');
}

ScheduleTrackEventSelect.propTypes = {
  event: PropTypes.object,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
};

const ScheduleTrackEventSelectContainer = ({
  trackPublicId,
  eventType,
  selectedEvent,
  onSelectEvent,
  isSwappingEvent,
}) => {
  const currentUser = useCurrentUser();

  const endDateOfSelectedEvent = getDateFromSelectedEvent(selectedEvent);
  const [selectEvent, setSelectEvent] = useState(selectedEvent);
  const [filters, setFilters] = useState(() => {
    if (endDateOfSelectedEvent == null) {
      return {
        start_time: { $gt: UPCOMING_DATE_FILTER_VALUE },
        end_time: null,
      };
    }

    return {
      start_time: {
        $gt: endDateOfSelectedEvent,
      },
      end_time: null,
    };
  });

  const { label_event_type: labelEventType, label_track: labelTrack } = useLabels();

  const eventTypeId = get(eventType, 'id');

  const eventsRqlSearch = rql({
    view_mode: 'full',
    event_type: { $eq: eventTypeId },
    $ordering: 'start_time',
    ...filters,
  });

  const {
    data,
    isLoading,
    refetch: refreshEvents,
  } = useInfiniteQuery({
    ...queries.events.list(eventsRqlSearch),
    staleTime: 60 * 1000 * 5, // 5 minutes
    enabled: eventTypeId != null,
  });

  const events = concat([], ...map(get(data, 'pages', []), 'results'));
  const eventsCount = get(data, 'pages.0.count', 0);

  const [closedInfo, setClosedInfo] = useLocalStorage(
    `closedScheduleTrackInfoTip-${currentUser.id}-${trackPublicId}`
  );

  const resetFilters = () => {
    if (endDateOfSelectedEvent) {
      setFilters({ start_time: { $gt: endDateOfSelectedEvent } });
    } else {
      setFilters({ start_time: { $gt: UPCOMING_DATE_FILTER_VALUE } });
    }
  };

  useEffect(() => {
    refreshEvents();
  }, [filters]);

  const handleSelectEvent = (event) => {
    setSelectEvent(event);
    onSelectEvent(event, filters);
  };

  const filtersDefs = values(
    pick(
      useGetAllEventFiltersDefinition({
        filters,
        updateFilter: (newValue) => setFilters({ ...filters, ...newValue }),
      }),
      ['Location', 'Ownership', 'Dates']
    )
  );

  const barFiltersDefs = map(filtersDefs, (filterDef) => {
    const updatedFilterDef = {
      ...filterDef,
      width: '100%',
      gridProps: { ...filterDef.sx, sx: { flex: 1 } },
    };

    if (filterDef.label === 'Dates') {
      const shortcuts = [];

      shortcuts.push(UPCOMING_SHORTCUT);

      return {
        ...updatedFilterDef,
        extraProps: {
          shortcuts,
        },
      };
    }

    return updatedFilterDef;
  });

  if (isNil(eventType)) return null;

  const hasFilters =
    size(filters.location) > 0 ||
    size(filters.facilitators) > 0 ||
    (filters.start_time !== null && filters.start_time != { $gt: UPCOMING_DATE_FILTER_VALUE });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: '20px 16px 16px 16px',
        position: 'sticky',
        top: '0px',
      }}
    >
      {!isSwappingEvent && !closedInfo && (
        <InfoBox
          margin="0 0 20px 0"
          content={`To schedule this ${toLower(
            labelTrack
          )}, you need to select an event for every ${toLower(labelEventType)}.`}
          actionText="Dismiss"
          onClick={() => setClosedInfo(true)}
          type="info"
        />
      )}

      <Box
        sx={{
          backgroundColor: `${colors.neutral50}`,
          flexGrow: 1,
          border: `1px solid ${colors.neutral200}`,
          borderRadius: 4,
          p: 2,
          mb: 2,
          display: 'flex',
          gap: 4,
          flexDirection: 'row',
        }}
      >
        <RQLFilterBar filters={barFiltersDefs} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text ellipsisOnOverflow size="h5" color={colors.neutral500}>
          Select an event for: <b>{eventType.name}</b>
        </Text>

        <ButtonLink
          target="_blank"
          variant="primary"
          icon="external"
          iconPlacement="right"
          route={mapRoute('eventNewFromTemplate', { public_id: eventType.public_id })}
        >
          Schedule a new event
        </ButtonLink>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ButtonLink
          target="_blank"
          variant="primary"
          icon="loading"
          iconPlacement="left"
          onClick={refreshEvents}
        >
          Refresh events list
        </ButtonLink>
      </Box>

      {/* TODO: Add filters */}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        {isLoading && <Loading />}

        {!isLoading && eventsCount === 0 && (
          <Stack spacing={1} height="160px" justifyContent="center" alignItems="center">
            <Text size="h4" bold>
              No upcoming events.
            </Text>
            <Stack spacing={0.5} alignItems="center">
              {hasFilters ? (
                <>
                  <Text size="p">Try changing the filters to find upcoming events or</Text>
                  <ButtonLink target="_blank" variant="primary" onClick={resetFilters}>
                    Reset filters
                  </ButtonLink>
                </>
              ) : (
                <>
                  <Text size="p">Try scheduling a new event</Text>
                  <ButtonLink
                    target="_blank"
                    variant="primary"
                    icon="external"
                    iconPlacement="right"
                    route={mapRoute('eventNewFromTemplate', { public_id: eventType.public_id })}
                  >
                    Schedule a new event
                  </ButtonLink>
                </>
              )}
            </Stack>
          </Stack>
        )}

        {!isLoading &&
          map(events, (event) => {
            return (
              <ScheduleTrackEventSelect
                key={`event-option-${event.public_id}`}
                event={{ ...event, section: eventType.section }}
                inputValue={selectEvent?.public_id}
                onChange={handleSelectEvent}
              />
            );
          })}
      </Box>
    </Box>
  );
};

ScheduleTrackEventSelectContainer.propTypes = {
  trackPublicId: PropTypes.string,
  eventType: PropTypes.object,
  selectedEvent: PropTypes.object,
  onSelectEvent: PropTypes.func,
  isSwappingEvent: PropTypes.bool,
};

ScheduleTrackEventSelectContainer.defaultProps = {
  isSwappingEvent: false,
};

export default ScheduleTrackEventSelectContainer;
