import PropTypes from 'prop-types';
import React from 'react';

import colors from '~/services/colors';
import { includes } from 'lodash-es';
import rgbHex from '~/vendor/rgb-hex';

const SvgIcon = ({ width, height, viewBox, fill, d, transform }) => {
  const hexFill = includes(fill, 'rgb') ? `#${rgbHex(fill)}` : fill;
  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={viewBox || `0 0 ${width} ${height}`}>
      <path fill={hexFill} fillRule="evenodd" d={d} transform={transform} />
    </svg>
  );
};

SvgIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
  d: PropTypes.string,
  transform: PropTypes.string,
};

SvgIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: colors.neutral900,
  transform: null,
};

export default SvgIcon;
