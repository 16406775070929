/* eslint-disable react/prop-types */
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  SvgIconProps,
} from '@mui/material';
import { MoreVertIcon } from '~/vendor/mui-icons';

export interface BaseKebabMenuItemProps {
  ActionIcon?: typeof SvgIcon | ((props: SvgIconProps) => JSX.Element);
  actionText: string;
  actionSubtext?: string;
  color?: string;
  disabled?: boolean;
}

interface KebabMenuItemProps extends BaseKebabMenuItemProps {
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  variant?: 'default';
}

interface KebabMenuItemLinkProps extends BaseKebabMenuItemProps {
  to: string;
  targetBlank?: boolean;
  variant: 'link';
}

export const KebabMenuItem: React.FC<KebabMenuItemProps> & React.FC<KebabMenuItemLinkProps> = ({
  ActionIcon,
  actionText,
  actionSubtext,
  color,
  disabled = false,
  ...props
}: KebabMenuItemProps | KebabMenuItemLinkProps) => {
  const InternalMenuItem = (menuProps: { children: React.ReactNode }) =>
    props.variant === 'link' ? (
      <MenuItem
        component={RouterLink}
        to={props.to}
        target={props.targetBlank ? '_blank' : '_self'}
        disabled={disabled}
        {...menuProps}
      />
    ) : (
      <MenuItem onClick={props.onClick} disabled={disabled} {...menuProps} />
    );

  return (
    <InternalMenuItem>
      {ActionIcon && (
        <ListItemIcon>
          <ActionIcon fontSize="small" htmlColor={color} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={actionText}
        secondary={actionSubtext}
        primaryTypographyProps={{ color }}
        secondaryTypographyProps={{ marginLeft: '8px' }}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      />
    </InternalMenuItem>
  );
};

export interface HandlesClose {
  handleClose: React.MouseEventHandler<HTMLLIElement>;
}

interface KebabMenuProps {
  children: React.ReactNode;
}

const KebabMenu = ({ children }: KebabMenuProps, ref: React.Ref<HandlesClose>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useImperativeHandle(ref, () => ({
    handleClose,
  }));

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ padding: 0 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </>
  );
};

export default forwardRef(KebabMenu);
