import React from 'react';

import Loading from '~/app/shared/components/Loading';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import { useCreateContentFromChannelParams } from '~/features/channels/hooks/useCreateContentFromChannelParams';
import { mapRoute } from '~/services/requests';
import { useParentSettings } from '~/app/settings/hooks';
import {
  useOriginRoute,
  useSetDetailedObject,
  useLabels,
  useCurrentUser,
} from '~/app/shared/hooks';
import { get, toLower } from 'lodash-es';

import ProgramFormPage from './ProgramFormPage';

const ProgramCreate = () => {
  const currentUser = useCurrentUser();
  const { label_program: labelProgram, label_program_plural: labelProgramPlural } = useLabels();

  const {
    isFromChannel,
    isLoading: isLoadingChannel,
    channelId,
  } = useCreateContentFromChannelParams();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.programs
  }&o=upcoming`;
  const backRoute = useOriginRoute(catalogRoute);
  const parentSettingsFlat = useParentSettings('office_hours_program/form', 'flat');

  useSetDetailedObject({ id: null }, 'programs', []);

  if (isFromChannel && isLoadingChannel) {
    return <Loading />;
  }

  const initialValuesParams = {
    settings: parentSettingsFlat,
  };

  if (isFromChannel) {
    initialValuesParams.defaultChannelId = channelId;
  } else {
    initialValuesParams.defaultChannelId = get(currentUser, 'default_channel_id');
  }

  return (
    <ProgramFormPage
      pageTitle={`Create ${labelProgram}`}
      topBarActionName={`Creating ${labelProgram}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelProgramPlural,
          link: catalogRoute,
        },
        {
          label: `New ${toLower(labelProgram)}`,
        },
      ]}
    />
  );
};

export default ProgramCreate;
