import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Option from '../Option';
import Dropdown from '../Dropdown';
import DropdownOption from '../DropdownOption';

import './style.scss';

class EmbedLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      linkTarget: '',
      linkTitle: '',
      linkTargetOption: '_self',
    };

    this.removeLink = this.removeLink.bind(this);
    this.addLink = this.addLink.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.signalExpandShowModal = this.signalExpandShowModal.bind(this);
    this.forceExpandAndShowModal = this.forceExpandAndShowModal.bind(this);
    this.stopPropagation = this.stopPropagation.bind(this);
    this.renderAddLinkModal = this.renderAddLinkModal.bind(this);
    this.renderInDropDown = this.renderInDropDown.bind(this);
    this.renderInFlatList = this.renderInFlatList.bind(this);
  }

  componentWillReceiveProps(props) {
    if (this.props.expanded && !props.expanded) {
      this.setState({
        showModal: false,
        linkTarget: '',
        linkTitle: '',
      });
    }
  }

  removeLink() {
    const { onChange } = this.props;
    onChange('unlink');
  }

  addLink() {
    const { onChange } = this.props;
    const { linkTitle, linkTarget, linkTargetOption } = this.state;
    onChange('link', linkTitle, linkTarget, linkTargetOption);
  }

  updateValue(event) {
    this.setState({
      [`${event.target.name}`]: event.target.value,
    });
  }

  hideModal() {
    this.setState({
      showModal: false,
    });
  }

  signalExpandShowModal() {
    const {
      onExpandEvent,
      currentState: { link, selectionText },
    } = this.props;
    const { linkTargetOption } = this.state;
    onExpandEvent();
    this.setState({
      showModal: true,
      linkTarget: (link && link.target) || '',
      linkTargetOption: (link && link.targetOption) || linkTargetOption,
      linkTitle: (link && link.title) || selectionText,
    });
  }

  forceExpandAndShowModal() {
    const {
      doExpand,
      currentState: { link, selectionText },
    } = this.props;
    const { linkTargetOption } = this.state;
    doExpand();
    this.setState({
      showModal: true,
      linkTarget: link && link.target,
      linkTargetOption: (link && link.targetOption) || linkTargetOption,
      linkTitle: (link && link.title) || selectionText,
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  renderAddLinkModal() {
    const {
      config: { popupClassName },
      doCollapse,
      translations,
    } = this.props;
    const { linkTitle, linkTarget } = this.state;
    return (
      <div
        tabIndex="0"
        className={classNames('rdw-link-modal', popupClassName)}
        onClick={this.stopPropagation}
      >
        <label className="rdw-link-modal-label" htmlFor="linkTitle">
          Link&apos;s Text
        </label>
        <input
          id="linkTitle"
          className="rdw-link-modal-input"
          onChange={this.updateValue}
          onBlur={this.updateValue}
          name="linkTitle"
          value={linkTitle}
        />
        <label className="rdw-link-modal-label" htmlFor="linkTarget">
          Link&apos;s URL
        </label>
        <input
          id="linkTarget"
          className="rdw-link-modal-input"
          onChange={this.updateValue}
          onBlur={this.updateValue}
          name="linkTarget"
          value={linkTarget}
        />
        <span className="rdw-link-modal-buttonsection">
          <button
            className="rdw-link-modal-btn"
            onClick={this.addLink}
            disabled={!linkTarget || !linkTitle}
          >
            {translations['generic.add']}
          </button>
          <button className="rdw-link-modal-btn" onClick={doCollapse}>
            {translations['generic.cancel']}
          </button>
        </span>
      </div>
    );
  }

  renderInFlatList() {
    const {
      config: { options, link, unlink, className },
      currentState,
      expanded,
      translations,
    } = this.props;
    const { showModal } = this.state;
    /* eslint-disable jsx-a11y/role-supports-aria-props */
    return (
      <div className={classNames('rdw-link-wrapper', className)} aria-label="rdw-link-control">
        {options.includes('link') && (
          <Option
            value="unordered-list-item"
            className={classNames(link.className)}
            onClick={this.signalExpandShowModal}
            aria-haspopup="true"
            aria-expanded={showModal}
            title={link.title || translations['components.controls.link.link']}
          >
            <img src={link.icon} alt="" />
          </Option>
        )}
        {options.includes('unlink') && (
          <Option
            disabled={!currentState.link}
            value="ordered-list-item"
            className={classNames(unlink.className)}
            onClick={this.removeLink}
            title={unlink.title || translations['components.controls.link.unlink']}
          >
            <img src={unlink.icon} alt="" />
          </Option>
        )}
        {expanded && showModal && this.renderAddLinkModal()}
      </div>
    );
    /* eslint-enable jsx-a11y/role-supports-aria-props */
  }

  renderInDropDown() {
    const {
      expanded,
      onExpandEvent,
      doCollapse,
      doExpand,
      onChange,
      config,
      currentState,
      translations,
    } = this.props;
    const { options, link, unlink, className, dropdownClassName, title } = config;
    const { showModal } = this.state;
    return (
      <div
        className="rdw-link-wrapper"
        aria-haspopup="true"
        aria-label="rdw-link-control"
        aria-expanded={expanded}
        title={title}
      >
        <Dropdown
          className={classNames('rdw-link-dropdown', className)}
          optionWrapperClassName={classNames(dropdownClassName)}
          onChange={onChange}
          expanded={expanded && !showModal}
          doExpand={doExpand}
          doCollapse={doCollapse}
          onExpandEvent={onExpandEvent}
        >
          <img src={config[config.options[0]].icon} alt="" />
          {options.includes('link') && (
            <DropdownOption
              onClick={this.forceExpandAndShowModal}
              className={classNames('rdw-link-dropdownoption', link.className)}
              title={link.title || translations['components.controls.link.link']}
            >
              <img src={link.icon} alt="" />
            </DropdownOption>
          )}
          {options.includes('unlink') && (
            <DropdownOption
              onClick={this.removeLink}
              disabled={!currentState.link}
              className={classNames('rdw-link-dropdownoption', unlink.className)}
              title={unlink.title || translations['components.controls.link.unlink']}
            >
              <img src={unlink.icon} alt="" />
            </DropdownOption>
          )}
        </Dropdown>
        {expanded && showModal ? this.renderAddLinkModal() : undefined}
      </div>
    );
  }

  render() {
    const {
      config: { inDropdown },
    } = this.props;
    if (inDropdown) {
      return this.renderInDropDown();
    }
    return this.renderInFlatList();
  }
}

EmbedLink.propTypes = {
  expanded: PropTypes.bool,
  doExpand: PropTypes.func,
  doCollapse: PropTypes.func,
  onExpandEvent: PropTypes.func,
  config: PropTypes.object,
  onChange: PropTypes.func,
  currentState: PropTypes.object,
  translations: PropTypes.object,
};

export default EmbedLink;
