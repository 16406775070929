import { LEARNING_TYPE_ICONS_MAPPING, LEARNING_TYPE_COLOR_MAPPING } from '~/app/catalog/constants';
import { useLearningTypeLabels } from '~/app/catalog/hooks';

export function useLearningTypeCardVariant(learningType: string) {
  const learningTypeLabels = useLearningTypeLabels();

  const colors = LEARNING_TYPE_COLOR_MAPPING[learningType];
  const icon = LEARNING_TYPE_ICONS_MAPPING[learningType];

  return {
    colors,
    icon,
    label: learningTypeLabels[learningType],
  };
}
