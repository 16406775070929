import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { withRouter, Link } from 'react-router-dom';
import { Field, Fields, reduxForm, formValueSelector } from 'redux-form';
import styled from 'styled-components';

import entitiesAction from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import CheckboxField from '~/app/inputs/components/CheckboxField';
import CoverFieldHelpText from '~/app/inputs/components/CoverImageField/CoverFieldHelpText';
import GoogleCalendarSelectField from '~/app/inputs/components/GoogleCalendarSelectField';
import InputLabel from '~/app/inputs/components/InputLabel';
import TextEditorField, { TextEditorContainer } from '~/app/inputs/components/OldTextEditorField';
import ProfileImageField from '~/app/inputs/components/ProfileImageField';
import SelectField from '~/app/inputs/components/SelectField';
import SingleDatePickerField from '~/app/inputs/components/SingleDatePickerField';
import TagTypesInputFields from '~/app/inputs/components/TagTypesInputFields';
import TextField from '~/app/inputs/components/TextField';
import TimeField from '~/app/inputs/components/TimeField';
import TimezoneField from '~/app/inputs/components/TimezoneField';
import UserSelectField from '~/app/inputs/components/UserSelectField';
import { MENTOR_PREFERENCE_TAG_TYPE, MENTEE_PREFERENCE_TAG_TYPE } from '~/app/mentorship/constants';
import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import Button from '~/app/shared/components/Button';
import Clicker from '~/app/shared/components/Clicker';
import { FormFieldGroup } from '~/app/shared/components/Form';
import Icon from '~/app/shared/components/Icon';
import InfoBox from '~/app/shared/components/InfoBox';
import InfoText from '~/app/shared/components/InfoText';
import Modal, { ModalBody, ModalFooter } from '~/app/shared/components/Modal';
import NewContentForm from '~/app/shared/components/NewContentForm';
import Form from '~/app/shared/components/OldForm';
import Pill from '~/app/shared/components/Pill';
import Text from '~/app/shared/components/Text';
import { Tooltip } from '~/app/shared/components/Tooltip';
import {
  ORGANIZER_ROLE_KEY,
  ADMIN_ROLE_KEY,
  STATUS_ERROR,
  STATUS_DONE,
} from '~/app/shared/constants';
import Copy from '~/app/shared/Copy';
import {
  useOriginRoute,
  useTooltipUID,
  useLabels,
  useToggles,
  useFormPreventTransition,
} from '~/app/shared/hooks';
import {
  sortCustomTags,
  buildCustomTopicsList,
  buildFlexibleTagsFieldNameList,
} from '~/app/topics/services';
import { pick, isEmpty, map, includes, get, toLower, filter } from 'lodash-es';
import { Stack, Tooltip as MuiTooltip } from '@mui/material';
import { DownloadIcon } from '~/vendor/mui-icons';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

import AccountRoleDocsModal from './AccountRoleDocsModal';
import LinkedInPublicProfileDataDialogPreview from './LinkedInPublicProfileDataDialogPreview';
import RefreshTokenModal from './RefreshTokenModal';

const validateRequired = Form.validations.required();

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const CoverFieldHelpTextWrapper = styled.div`
  height: 70px;
`;

const ReadOnlyText = styled.pre`
  padding: 10px 8px 4px 8px;
`;

const ModalBodyWrapper = styled(ModalBody)`
  align-items: center;
`;

const AccountRoleLabelStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DocsButtonContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const DocsButtonContainer = styled(Clicker)`
  color: ${colors.action600};
  gap: 5px;
  &:hover {
    text-decoration: underline;
    color: ${colors.action700};
  }
`;

const LearningHoursBlock = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const ReadOnlyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ReadOnlyTextIconContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const UserForm = (props) => {
  const {
    currentUser,
    handleSubmit,
    change,
    fieldValues,
    invalid,
    error,
    submitting,
    allowPickingDefaultCalendar,
    authorizedCalendar,
    initialValues,
    form,
  } = props;

  const [showDocsModal, setShowDocsModal] = useState(false);
  const [imageError, setImageError] = useState('');
  const profileRoute = `${mapRoute('userProfile', { id: `${fieldValues.id}` })}`;
  const backRoute = useOriginRoute(profileRoute);
  const [modalDelete, setModalDelete] = useState(false);

  const [showRefreshTokenModal, setShowRefreshTokenModal] = useState(false);
  const [currentApiToken, setCurrentApiToken] = useState(initialValues.graphql_token);

  const [refreshToken] = useEntities(
    entitiesAction.graphql.refreshToken,
    ({ data, status, error }) => {
      if (status === STATUS_DONE) {
        setShowRefreshTokenModal(false);
        setCurrentApiToken(data);
        toast.success('Token refreshed successfully');
      }

      if (status === STATUS_ERROR) {
        toast.error('Error', error?.error);
      }
    }
  );

  const {
    toggle_allow_generic_link_embedding_on_snippet: toggleAllowGenericLinkEmbeddingOnSnippet,
    toggle_user_profile_enrichment: toggleUserProfileEnrichment,
  } = useToggles();

  const isUserEditingOwnProfile = currentUser.id === fieldValues.id;

  const handleAppendToEditor = (url) => {
    change('bio', { type: 'link_embed', content: url });
  };

  const updateBioField = (rawData) => {
    const { contentBlocks, entityMap } = htmlToDraft(renderToStaticMarkup(rawData));
    const content = {
      blocks: contentBlocks,
      entityMap,
    };
    change('bio', { type: 'refresh_content', content: content });
    toast.success('Description updated successfully. Changes will be persisted once you hit Save.');
  };

  useFormPreventTransition(form);
  const [showEnrichProfileModal, setShowEnrichProfileModal] = useState(false);

  const history = useHistory();

  const { trackActivity } = useMetrics();

  const handleStatusChange = ({ status }) => {
    if (status === STATUS_DONE) {
      trackActivity(METRICS_ACTIVITIES.ENTITY_DELETE, {
        entityType: 'user',
        entityId: fieldValues.id,
      });
      if (isUserEditingOwnProfile) {
        history.push(mapRoute('logout'));
      } else {
        history.push(mapRoute('dashboardPeople'));
        toast.success('User deleted successfully');
      }
    } else if (status === STATUS_ERROR) {
      toast.error('Deleting accounts is temporarily disabled. Please come back in a few minutes.');
    }
  };

  const [deleteUser] = useEntities(entitiesAction.userData.remove, handleStatusChange);

  const handleDeleteClick = () => {
    deleteUser(fieldValues.id);
    setModalDelete(false);
  };

  const {
    label_user_profile_sessions_title: labelSessions,
    label_mentorship_tab: labelMentorship,
    label_cta_mentee_preferences_tip_blurb: labelCtaMenteePreferencesTipBlurb,
    label_cta_mentor_preferences_tip_blurb: labelCtaMentorPreferencesTipBlurb,
  } = useLabels();
  const getRoleOptions = () => {
    const options = [
      {
        label: 'Regular',
        value: 'regular',
      },
      {
        label: 'External',
        value: 'external',
      },
      {
        label: 'Organizer',
        value: 'organizer',
      },
    ];

    if (currentUser.role === ADMIN_ROLE_KEY) {
      options.push({
        label: 'Admin',
        value: 'admin',
      });
    }

    return options;
  };

  const getToggleTypes = () => {
    const toggles = [];
    if (get(currentUser, 'module_toggles.mentorship')) toggles.push('toggle_mentorship');
    if (get(currentUser, 'module_toggles.programs')) toggles.push('toggle_coaching');
    return toggles;
  };

  const toggleTypes = getToggleTypes();

  const handleOnSubmit = () => {
    return handleSubmit(
      onSubmitActions(entitiesAction.userData.update.toString(), (values) => {
        const payload = pick(values, [
          'id',
          'role',
          'name',
          'linkedin_username',
          'profile_image',
          'timezone',
          'is_timezone_automatic',
          'settings',
          'default_google_calendar_id',
          'department',
          'title',
          'location_text',
          'working_since',
          'bio',
          'skills',
          'is_mentor',
          'learning_hours_start_time',
          'learning_hours_end_time',
          'has_mentor_profile',
          'has_mentee_profile',
          'manager_id',
          'executive_assistant_id',
        ]);

        payload.learning_hours_start_time = moment
          .utc(payload.learning_hours_start_time)
          .format('HH:mm:ss');
        payload.learning_hours_end_time = moment
          .utc(payload.learning_hours_end_time)
          .format('HH:mm:ss');

        const customTopicsList = buildCustomTopicsList({
          user: currentUser,
          toggleTypes,
          flexibleTags: pick(
            values,
            map(
              currentUser?.custom_tags.flexible_filter_tags,
              (flexibleTag) => flexibleTag.filter_field_name
            )
          ),
        });

        payload.skills = customTopicsList;

        if (isEmpty(payload.default_google_calendar_id)) {
          payload.default_google_calendar_id = '';
        }
        return {
          id: values.id,
          key: 'userEdit',
          body: payload,
        };
      })
    );
  };

  const flexibleFiltersWithoutMentorshipPreferences = filter(
    buildFlexibleTagsFieldNameList(currentUser, toggleTypes),
    (flexibleTagName) =>
      !includes([MENTOR_PREFERENCE_TAG_TYPE, MENTEE_PREFERENCE_TAG_TYPE], flexibleTagName)
  );

  const labelInternalGroups = get(currentUser, 'labels.label_internal_groups');
  const toggleEditProfile = get(currentUser, 'toggles.toggle_edit_profile');
  const toggleBlockProfileEdit = get(currentUser, 'toggles.toggle_block_profile_edit');
  const anyoneCanCreateMentorshipSession = get(currentUser, 'anyone_can_create_mentorship_session');
  const toggleMentorPreferences = get(currentUser, 'toggles.toggle_mentor_preferences');
  const toggleMenteePreferences = get(currentUser, 'toggles.toggle_mentee_preferences');

  const showMentorPreferences =
    toggleMentorPreferences || initialValues.is_mentor || anyoneCanCreateMentorshipSession;
  const showMenteePreferences = toggleMenteePreferences;

  const deleteButtonTooltip = useTooltipUID();
  const submitButtonTooltip = useTooltipUID();

  const toggleSlackNotifications = get(currentUser, 'toggles.toggle_slack_notifications');

  const customTagsInitialValues = sortCustomTags(currentUser, initialValues, 'skills');

  for (const [key, value] of Object.entries(customTagsInitialValues)) {
    initialValues[key] = value;
  }

  initialValues.learning_hours_start_time = moment.utc(
    initialValues.learning_hours_start_time,
    'hh:mm:ss'
  );
  initialValues.learning_hours_end_time = moment.utc(
    initialValues.learning_hours_end_time,
    'hh:mm:ss'
  );

  const profileSectionsList = [
    {
      id: 'personal-info',
      label: `Personal Info`,
      icon: 'person',
      section: (
        <>
          <FormFieldGroup>
            {toggleBlockProfileEdit && (
              <InfoBox
                type="danger"
                fontSize="14px"
                content="Profile editing and Deleting accounts are temporarily disabled. Please come back in a few minutes."
              />
            )}
            <ProfileContainer>
              <Field
                name="profile_image"
                component={ProfileImageField}
                filePath="user_images"
                imageHeight="172px"
                imageWidth="172px"
                setError={setImageError}
                userName={fieldValues.name}
              />
              <CoverFieldHelpTextWrapper>
                <CoverFieldHelpText
                  top={16}
                  left={0}
                  helpText={
                    <>
                      <Text size="h6">Supported formats: JPG, PNG, GIF</Text>
                      <Text size="h6">Maximum file size: 2MB.</Text>
                      <Text size="h6">Minimum image size: 128 x 128px.</Text>
                    </>
                  }
                  error={imageError}
                />
              </CoverFieldHelpTextWrapper>
            </ProfileContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              name="name"
              label="Full Name"
              required
              component={TextField}
              validate={[validateRequired]}
              disabled={!toggleEditProfile}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <Field name="email" label="Email" component={TextField} disabled />
          </FormFieldGroup>
          {toggleUserProfileEnrichment && (
            <FormFieldGroup>
              <Stack direction="horizontal" gap={1}>
                <Field name="linkedin_username" label="LinkedIn Username" component={TextField} />{' '}
                {isUserEditingOwnProfile && (
                  <MuiTooltip title="If you provide a Linkedin profile username, you can fetch data from it and add it to your description. Please note that the profile must be public.">
                    <div>
                      <Button
                        onClick={() => setShowEnrichProfileModal(true)}
                        disabled={!fieldValues.linkedin_username}
                        sx={{ minWidth: '230px', maxHeight: 40, mt: 1 }}
                        startIcon={<DownloadIcon />}
                        type="button"
                      >
                        Fetch public profile data
                      </Button>
                    </div>
                  </MuiTooltip>
                )}
              </Stack>
            </FormFieldGroup>
          )}
          <FormFieldGroup>
            <Field name="title" label="Title" component={TextField} disabled={!toggleEditProfile} />
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              name="department"
              label="Department"
              component={TextField}
              disabled={!toggleEditProfile}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              name="location_text"
              label="Location"
              component={TextField}
              disabled={!toggleEditProfile}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              name="working_since"
              label="Working at the company since"
              component={SingleDatePickerField}
              disabled={!toggleEditProfile}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              name="manager_id"
              label="Manager"
              component={UserSelectField}
              disabled={!toggleEditProfile}
            />
          </FormFieldGroup>
          {currentUser.role === ADMIN_ROLE_KEY && (
            <FormFieldGroup>
              <Field
                name="executive_assistant_id"
                label="Executive Assistant"
                component={UserSelectField}
                disabled={!toggleEditProfile}
              />
            </FormFieldGroup>
          )}
          <FormFieldGroup>
            <InputLabel htmlFor="bio">Description</InputLabel>
            <TextEditorContainer>
              <Field
                name="bio"
                component={TextEditorField}
                allowGenericLinks
                allowImageButton
                allowWebsiteButton={toggleAllowGenericLinkEmbeddingOnSnippet}
                handleAppendToEditor={handleAppendToEditor}
              />
            </TextEditorContainer>
          </FormFieldGroup>

          {!isEmpty(flexibleFiltersWithoutMentorshipPreferences) && (
            <FormFieldGroup>
              <InputLabel sx={{ 'margin-bottom': '16px' }}>Profile Tags</InputLabel>
              <Fields
                useNewFormFieldLabel
                names={[
                  ...buildFlexibleTagsFieldNameList(
                    currentUser,
                    toggleTypes,
                    false,
                    flexibleFiltersWithoutMentorshipPreferences
                  ),
                ]}
                component={TagTypesInputFields}
                currentUser={currentUser}
                toggleTypes={toggleTypes}
                strictFlexibleTagsList={false}
                allowedTagTypes={flexibleFiltersWithoutMentorshipPreferences}
                hideMainTopics
                hideTags
              />
            </FormFieldGroup>
          )}
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    ...(showMenteePreferences || showMentorPreferences
      ? [
          {
            id: 'mentorship-preferences',
            label: `Mentorship Preferences`,
            icon: 'group',
            section: (
              <>
                {showMentorPreferences && (
                  <FormFieldGroup>
                    <Field
                      name="has_mentor_profile"
                      component={CheckboxField}
                      label="Mentor profile"
                      infoText={labelCtaMentorPreferencesTipBlurb}
                      labelSize="h5"
                    />
                    <Fields
                      useNewFormFieldLabel
                      names={[MENTOR_PREFERENCE_TAG_TYPE]}
                      component={TagTypesInputFields}
                      currentUser={currentUser}
                      toggleTypes={toggleTypes}
                      strictFlexibleTagsList={false}
                      allowedTagTypes={[MENTOR_PREFERENCE_TAG_TYPE]}
                      disabled={!fieldValues.has_mentor_profile}
                      hideMainTopics
                      hideTags
                    />
                  </FormFieldGroup>
                )}
                {showMenteePreferences && (
                  <FormFieldGroup>
                    <Field
                      name="has_mentee_profile"
                      component={CheckboxField}
                      label="Mentee profile"
                      infoText={labelCtaMenteePreferencesTipBlurb}
                      labelSize="h5"
                    />
                    <Fields
                      useNewFormFieldLabel
                      names={[MENTEE_PREFERENCE_TAG_TYPE]}
                      component={TagTypesInputFields}
                      currentUser={currentUser}
                      toggleTypes={toggleTypes}
                      strictFlexibleTagsList={false}
                      allowedTagTypes={[MENTEE_PREFERENCE_TAG_TYPE]}
                      disabled={!fieldValues.has_mentee_profile}
                      hideMainTopics
                      hideTags
                    />
                  </FormFieldGroup>
                )}
              </>
            ),
            sectionProps: {
              defaultOpen: true,
            },
          },
        ]
      : []),
    {
      id: 'general-preferences',
      label: `General Preferences`,
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <Field
              name="is_timezone_automatic"
              component={CheckboxField}
              label="Automatically set timezone"
              infoText="If enabled, your timezone will be set automatically based on browser info every time you log in."
              labelSize="h5"
            />
          </FormFieldGroup>

          {!fieldValues.is_timezone_automatic && (
            <FormFieldGroup>
              <InputLabel>Timezone</InputLabel>
              <Field name="timezone" component={TimezoneField} />
            </FormFieldGroup>
          )}

          <FormFieldGroup>
            <Text size="h4">Learning Hours</Text>
            <InfoText
              top={4}
              bottom={16}
              content={`Learning Hours are a way of taking advantage of globally-available online events and ${toLower(
                labelSessions
              )}. These settings control the "Friendly to My Time" filter available on Events and ${labelMentorship} pages.`}
            />
            <LearningHoursBlock>
              <Field name="learning_hours_start_time" component={TimeField} label="Start time" />
              <Field name="learning_hours_end_time" component={TimeField} label="End time" />
            </LearningHoursBlock>
          </FormFieldGroup>
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'notification-preferences',
      label: `Notification Preferences`,
      icon: 'mail',
      section: (
        <>
          <FormFieldGroup>
            <Field
              name="settings.receive_event_notifications"
              component={CheckboxField}
              label="Receive non-essential email notifications"
              infoText="If enabled, we will send you email reminders related to your activities (event enrollment, session enrollment, schedule updates)."
              labelSize="h5"
            />
          </FormFieldGroup>

          {toggleSlackNotifications && (
            <FormFieldGroup>
              <Field
                name="settings.toggle_slack_user_notifications"
                component={CheckboxField}
                label="Receive Slack notifications"
                infoText="If enabled, we will send you slack messages related to your activities (event enrollment, session enrollment, schedule updates)."
                labelSize="h5"
              />
            </FormFieldGroup>
          )}
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'additional-info',
      label: `Additional Information`,
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <InputLabel>{labelInternalGroups}</InputLabel>
            <InfoText
              top={4}
              bottom={4}
              content={
                `${labelInternalGroups} are synced from other company tools and can` +
                ' be used to grant and restrict access to areas of the system.'
              }
            />
            <Text size="h4">
              {isEmpty(fieldValues.groups) ? (
                `You are currently not registered in any ${labelInternalGroups}.`
              ) : (
                <GroupContainer>
                  {map(fieldValues.groups, (group) => (
                    <Link to={mapRoute('myGroups')}>
                      <Pill
                        key={group.name}
                        size="medium"
                        label={group.name}
                        variant="neutral200"
                      />
                    </Link>
                  ))}
                </GroupContainer>
              )}
            </Text>
          </FormFieldGroup>
          {allowPickingDefaultCalendar && isUserEditingOwnProfile && (
            <FormFieldGroup>
              <InputLabel>Default Google Calendar</InputLabel>
              {authorizedCalendar ? (
                <React.Fragment>
                  <Field name="default_google_calendar_id" component={GoogleCalendarSelectField} />
                  <InfoText
                    top={4}
                    content="Events and sessions that you create in the system will be added to this calendar."
                  />
                </React.Fragment>
              ) : (
                <a href={`${mapRoute('calendarAuthRedirect')}?next=${window.location.href}`}>
                  Authorize Google Calendar to select a default calendar.
                </a>
              )}
            </FormFieldGroup>
          )}
          <FormFieldGroup>
            <InputLabel>ICS URL</InputLabel>
            <InfoText
              top={4}
              bottom={4}
              content="The URL below can be added to Google Calendar if you want to view your events there. On Google Calendar, under ‘Other calendars', choose ‘Add by URL’. Then copy/paste the URL."
            />
            <ReadOnlyText>
              <ReadOnlyTextContainer>
                {fieldValues.ics_url}
                <ReadOnlyTextIconContent>
                  {fieldValues.ics_url && <Copy content={fieldValues.ics_url} showLabel={false} />}
                </ReadOnlyTextIconContent>
              </ReadOnlyTextContainer>
            </ReadOnlyText>
          </FormFieldGroup>

          {isUserEditingOwnProfile && includes([ADMIN_ROLE_KEY], currentUser.role) && (
            <FormFieldGroup>
              <InputLabel>GraphQL Token</InputLabel>
              <InfoText
                top={4}
                bottom={4}
                content={
                  <>
                    The system supports fetching majority of the content and user data via the
                    industry-standard <a href="https://en.wikipedia.org/wiki/GraphQL">GraphQL</a>{' '}
                    API. <br />
                    To explore this API using an interactive browser or to see the reference
                    documentation, go to <a href={`${mapRoute('graphQl')}`}>/graphql</a>. <br />
                    In order to set up a API-based integration, you&apos;ll need the API token. See
                    below.{' '}
                    <a href="https://help.plusplus.app/en/articles/4215996-how-to-use-the-plusplus-graphql-api">
                      Learn more
                    </a>
                    .
                  </>
                }
              />
              <ReadOnlyText>
                <ReadOnlyTextContainer>
                  {currentApiToken}
                  <ReadOnlyTextIconContent>
                    {currentApiToken && <Copy content={currentApiToken} showLabel={false} />}
                  </ReadOnlyTextIconContent>
                </ReadOnlyTextContainer>
              </ReadOnlyText>
              <Button
                color="secondary"
                type="button"
                onClick={() => setShowRefreshTokenModal(true)}
              >
                Refresh
              </Button>
              {showRefreshTokenModal && (
                <RefreshTokenModal
                  integrationName="GraphQL"
                  handleRefresh={refreshToken}
                  handleClose={() => setShowRefreshTokenModal(false)}
                />
              )}
            </FormFieldGroup>
          )}
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    ...(includes([ORGANIZER_ROLE_KEY, ADMIN_ROLE_KEY], currentUser.role)
      ? [
          {
            id: 'admin-settings',
            label: `Admin Settings`,
            icon: 'lock',
            section: (
              <>
                <FormFieldGroup>
                  <AccountRoleLabelStyled>
                    <DocsButtonContainerWrapper>
                      <DocsButtonContainer type="button" onClick={() => setShowDocsModal(true)}>
                        <Icon name="support" height={12} width={12} color={colors.action600} />
                        <Text size="h5">How account role works</Text>
                      </DocsButtonContainer>
                    </DocsButtonContainerWrapper>
                    {showDocsModal && (
                      <AccountRoleDocsModal handleClose={() => setShowDocsModal(false)} />
                    )}
                  </AccountRoleLabelStyled>
                  <Field
                    name="role"
                    label="Account Role"
                    component={SelectField}
                    options={getRoleOptions()}
                  />
                </FormFieldGroup>

                {currentUser.role === ADMIN_ROLE_KEY && (
                  <FormFieldGroup>
                    <Field
                      name="is_mentor"
                      component={CheckboxField}
                      label={`${fieldValues.name ?? 'User'} is a mentor`}
                      infoText="If enabled, this person will be able to create mentorship sessions to be booked by other users, and develop a mentor-mentee relationship with them."
                      labelSize="h5"
                    />
                  </FormFieldGroup>
                )}

                <FormFieldGroup>
                  <InputLabel>Delete Account</InputLabel>
                  <InfoText
                    top={4}
                    bottom={4}
                    content={`This action is permanent and cannot be undone, your account will be permanently deleted from ${currentUser?.customer?.name}. If you are facing any issues please contact support.`}
                  />

                  <Tooltip id={deleteButtonTooltip.uid} hide={!toggleBlockProfileEdit}>
                    Deleting accounts is temporarily disabled. Please try again in a few minutes.
                  </Tooltip>
                  <Tooltip id={submitButtonTooltip.uid} hide={!toggleBlockProfileEdit}>
                    Profile editing is temporarily disabled. Please try again in a few minutes.
                  </Tooltip>
                </FormFieldGroup>
                <FormFieldGroup>
                  {fieldValues.id && (
                    <div {...deleteButtonTooltip.targetProps}>
                      <Form.DeleteButton
                        disabled={toggleBlockProfileEdit}
                        onClick={() => {
                          setModalDelete(true);
                        }}
                      >
                        Delete Account
                      </Form.DeleteButton>
                    </div>
                  )}

                  {modalDelete && (
                    <Modal
                      title="Delete Account"
                      onHide={() => {
                        setModalDelete(false);
                      }}
                      handleClose={() => {
                        setModalDelete(false);
                      }}
                      width={window.innerWidth > 600 ? 600 : null}
                      // Using innerWidth here was intentional
                      height="160px"
                      overflow="hidden"
                    >
                      <ModalBodyWrapper>
                        You&#39;re about to delete the user <b>{fieldValues.name}</b>. Are you sure
                        you want to do this?
                      </ModalBodyWrapper>
                      <ModalFooter variant="buttons">
                        <Button
                          onClick={() => {
                            setModalDelete(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="error"
                          onClick={() => {
                            handleDeleteClick();
                          }}
                        >
                          Delete
                        </Button>
                      </ModalFooter>
                    </Modal>
                  )}
                </FormFieldGroup>
              </>
            ),
            sectionProps: {
              defaultOpen: true,
            },
          },
        ]
      : []),
  ];

  const advancedSettingsList = [];

  return (
    <>
      <NewContentForm
        invalid={invalid}
        handleSubmit={handleOnSubmit()}
        disableSave={toggleBlockProfileEdit}
        error={error}
        submitting={submitting}
        backRoute={backRoute}
        contentTitle={fieldValues.name}
        topBarActionName="Editing Profile"
        breadcrumbsItemList={[
          {
            label: 'Profile',
            link: backRoute,
          },
          {
            label: 'Edit Profile',
          },
        ]}
        isEdit
        advancedSettingsList={advancedSettingsList}
        contentSectionsList={profileSectionsList}
      />
      {showEnrichProfileModal && (
        <LinkedInPublicProfileDataDialogPreview
          username={fieldValues.linkedin_username}
          handleClose={(data) => {
            if (data) {
              updateBioField(data);
            }
            setShowEnrichProfileModal(false);
          }}
        />
      )}
    </>
  );
};

const formSelector = formValueSelector('userForm');

UserForm.propTypes = {
  form: PropTypes.string,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  invalid: PropTypes.bool,
  error: PropTypes.object,
  submitting: PropTypes.bool,

  fieldValues: PropTypes.object,

  currentUser: PropTypes.object,
  authorizedCalendar: PropTypes.bool,
  allowPickingDefaultCalendar: PropTypes.bool,
  initialValues: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: get(state, 'user.currentUser'),
  authorizedCalendar: get(state, 'user.currentUser.authorized_google_calendar'),
  allowPickingDefaultCalendar: get(state, 'user.currentUser.allow_picking_default_calendar'),
  fieldValues: {
    id: formSelector(state, 'id'),
    name: formSelector(state, 'name'),
    linkedin_username: formSelector(state, 'linkedin_username'),
    groups: formSelector(state, 'groups'),
    ics_url: formSelector(state, 'ics_url'),
    is_mentor: formSelector(state, 'is_mentor'),
    is_timezone_automatic: formSelector(state, 'is_timezone_automatic'),
    has_mentor_profile: formSelector(state, 'has_mentor_profile'),
    has_mentee_profile: formSelector(state, 'has_mentee_profile'),
  },
});

const RouterConnectedUserForm = withRouter(
  reduxForm({
    form: 'userForm',
    validate: (values) => {
      let learningHoursStartTime = values.learning_hours_start_time;
      let learningHoursEndTime = values.learning_hours_end_time;

      if (learningHoursStartTime && !moment.isMoment(learningHoursStartTime)) {
        learningHoursStartTime = moment.utc(learningHoursStartTime);
      }
      if (learningHoursEndTime && !moment.isMoment(learningHoursEndTime)) {
        learningHoursEndTime = moment.utc(learningHoursEndTime);
      }

      const errors = {};

      if (learningHoursStartTime && learningHoursStartTime.isAfter(learningHoursEndTime)) {
        errors.learning_hours_start_time = 'Start time must be before end time';
      }

      return errors;
    },
    onSubmitSuccess: (result, dispatch, { location, history, handleUpdateSuccess }) => {
      const args = queryString.parse(location.search);
      const { id } = result;

      handleUpdateSuccess();

      if (get(args, 'origin')) {
        history.push(args.origin);
      } else {
        history.push(mapRoute('userProfile', { id }));
      }
    },
    onSubmitFail: () => {
      // eslint-disable-next-line no-alert
      alert('Submission failed, see errors above');
    },
  })(UserForm)
);

export default connect(mapStateToProps)(RouterConnectedUserForm);
