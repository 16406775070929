import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import structure from 'redux-form/lib/structure/plain';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import { getDataFromState } from '~/app/entities/utils';
import colors from '~/services/colors';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import { STATUS_DONE, STATUS_ERROR } from '~/app/shared/constants';
import {
  filter,
  forEach,
  get,
  includes,
  isEmpty,
  isEqual,
  keyBy,
  map,
  noop,
  sortBy,
  unionBy,
} from 'lodash-es';

import AutocompleteField from '../AutocompleteField';

const CheckingAvailability = styled.div`
  color: #025a83;
`;

const RoomAvailabilityTrue = styled.div`
  color: #23b688;
`;

const RoomAvailabilityFalse = styled.div`
  color: ${colors.error600};
`;

const getInputMap = (names, fields) => {
  const mapping = {};
  forEach(names, (n) => {
    mapping[n] = structure.getIn(fields, n);
  });
  return mapping;
};

const fieldGetter = (nameMapping, names, fields) => (key) => {
  const mapping = getInputMap(names, fields);
  return mapping[nameMapping[key]];
};

const CalendarMultipleResourcesFields = (props) => {
  const {
    locationId,
    roomAvailability,
    roomAvailabilityStatus,
    idFieldName,
    label,

    checkRoomAvailability,
  } = props;
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [options, setOptions] = useState([]);
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const [fingerPrint, setFingerPrint] = useState([]);
  const [retrievedRooms, setRetrievedRooms] = useState({});

  const getField = (key) => {
    const { nameMapping, names, ...fields } = props;
    return fieldGetter(nameMapping, names, fields)(key);
  };

  const getFieldInput = (key) => {
    const field = getField(key);
    return field.input;
  };

  const getFieldMeta = (key) => {
    const field = getField(key);
    return field.meta;
  };

  const getUnavailableRooms = () => {
    const { availability } = roomAvailability;
    return map(filter(availability, ['available', false]), 'email');
  };

  const calendarOwnerInput = getFieldInput('resourceOwner');
  const roomsInput = getFieldInput('rooms');
  const unavailableRooms = getUnavailableRooms();

  const inputValues = map(roomsInput.value, 'resource_id');

  useEffect(() => {
    const newFingerPrint = [
      getFieldInput('startsAt').value,
      getFieldInput('duration').value,
      map(getFieldInput('rooms').value, 'resource_id'),
      getFieldInput('resourceOwner').value,
      locationId,
    ];

    if (!isEqual(newFingerPrint, fingerPrint)) {
      refreshRoomAvailability();
      setFingerPrint(newFingerPrint);
    }
  });

  const getRoomDataList = (ids) =>
    map(ids, (id) => get(retrievedRooms, id, { resource_id: id, name: id }));

  const refreshRoomAvailability = () => {
    const resourceIds = map(getFieldInput('rooms').value, 'resource_id');
    const roomDataList = getRoomDataList(resourceIds);

    if (isEmpty(roomDataList)) return;

    setCheckingAvailability(true);

    checkRoomAvailability(getFieldInput('id').value, {
      rooms_emails: map(roomDataList, 'email'),
      starts_at: getFieldInput('startsAt').value,
      duration: getFieldInput('duration').value,
      location_id: locationId,
    });
  };

  const handleCalendarRoomChange = (values) => {
    const roomsInfoInput = getFieldInput('roomsInfo');
    const roomsInput = getFieldInput('rooms');

    if (!values || isEmpty(values)) {
      roomsInfoInput.onChange('');
      roomsInput.onChange([]);
      return;
    }

    const roomDataList = getRoomDataList(values);

    if (isEmpty(roomDataList)) return;

    roomsInfoInput.onChange(map(roomDataList, 'name'));
    roomsInput.onChange(roomDataList);
  };

  const handleFetchOptions = ({ q }, id) => {
    const queryStringData = {
      [idFieldName]: id,
      q: q || undefined,
      resource_ids: inputValues || undefined,
      next: window.location.href,
      limit: 20,
    };

    let url = `${ApiURLs['api_integrations:rooms']()}?`;
    url += queryString.stringify(queryStringData);
    setLoadingOptions(true);

    return fetchURL(url)
      .then((response) => {
        setLoadingOptions(false);
        return response;
      })
      .then((response) => {
        const fetchedRooms = keyBy(response.data, 'resource_id');
        setRetrievedRooms((rr) => ({
          ...rr,
          ...fetchedRooms,
        }));
        return response;
      })
      .then((response) =>
        setOptions(
          sortBy(
            map(response.data, (room) => {
              // FIXME "available" value is not working as expected, probably something
              //  related to async logic
              const available = !includes(unavailableRooms, room.email);
              return {
                label: room.name,
                value: room.resource_id,
                available,
              };
            }),
            'name'
          )
        )
      )
      .catch(() => {
        setLoadingOptions(false);
      });
  };

  const loadingAvailability =
    !includes([STATUS_DONE, STATUS_ERROR], roomAvailabilityStatus) && checkingAvailability;

  const roomAvailabilityErrorMessage =
    "Hmm, odd. We couldn't confirm if the rooms are available. Consider manually checking for potential room conflicts.";

  /* The list of rooms is provided by the Google Calendar service and sometimes this service
   * may not be available or it is very slow, which makes the options take time to load or
   * not load completely. This causes the input, even with values, to show an empty field,
   * thus confusing the user. This function ensures that the options will be displayed even
   * under these atypical circumstances (even if only the room ID is shown).
   */
  const ensureOptionsConsistency = (options) => {
    // inputValues: ["76781580559", "15436472858"]
    // options: [{ "label": "SF Office-1-Open Space (16)", "value": "15436472858", "available": true }]
    const fallbackOptions = map(inputValues, (value) => ({ value, label: value, available: true }));
    return unionBy(options, fallbackOptions, 'value');
  };

  return (
    <div>
      <AutocompleteField
        label={label}
        placeholder={
          locationId
            ? 'Start typing to search for a room'
            : 'Select a location before picking a room'
        }
        options={ensureOptionsConsistency(options)}
        loading={loadingOptions}
        fetchOptions={(query) => {
          handleFetchOptions(query, getFieldInput('id').value);
        }}
        disabled={!calendarOwnerInput.value || !locationId}
        input={{
          value: inputValues,
          onChange: handleCalendarRoomChange,
          onBlur: noop,
        }}
        meta={getFieldMeta('rooms')}
        multiple
      />
      {loadingAvailability && (
        <CheckingAvailability>Checking room availability, please wait...</CheckingAvailability>
      )}
      {!loadingAvailability && !isEmpty(roomsInput.value) && (
        <React.Fragment>
          {/* eslint-disable-next-line no-nested-ternary */}
          {get(roomAvailability, idFieldName) === `${getFieldInput('id').value}` &&
          roomAvailabilityStatus === STATUS_DONE ? (
            isEmpty(unavailableRooms) ? (
              <RoomAvailabilityTrue>
                The selected rooms are available for the chosen date and time.
              </RoomAvailabilityTrue>
            ) : (
              <RoomAvailabilityFalse>
                One or more selected rooms are not available for the chosen date and time.
              </RoomAvailabilityFalse>
            )
          ) : null}
          {roomAvailabilityStatus === STATUS_ERROR && (
            <RoomAvailabilityFalse>{roomAvailabilityErrorMessage}</RoomAvailabilityFalse>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

CalendarMultipleResourcesFields.propTypes = {
  names: PropTypes.array,

  nameMapping: PropTypes.object,
  locationId: PropTypes.number,
  currentUser: PropTypes.object,
  idFieldName: PropTypes.string,
  label: PropTypes.string,

  fetchRooms: PropTypes.func,
  roomAvailability: PropTypes.object,
  checkRoomAvailability: PropTypes.func,
  roomAvailabilityStatus: PropTypes.string,
};

const mapStateToProps = (state, { nameMapping, names, ...fields }) => {
  const idField = fieldGetter(nameMapping, names, fields)('id');
  const availabilityRequestState = getDataFromState(`${idField.input.value}Availability`, state);

  return {
    roomAvailability: availabilityRequestState.error || availabilityRequestState.data,
    roomAvailabilityStatus: availabilityRequestState.status,
    currentUser: get(state, 'user.currentUser'),
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  checkRoomAvailability: (id, queryStringStr) =>
    dispatch(
      actions.googleCalendar.checkRoomAvailability(`${id}Availability`, {
        [props.idFieldName]: id,
        ...queryStringStr,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarMultipleResourcesFields);
