import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { AVATAR_SIZES } from '~/app/shared/constants';
import colors from '~/services/colors';
import { first, keys, last, toUpper, split } from 'lodash-es';

const getImageSize = (size) => {
  return AVATAR_SIZES[size] ?? AVATAR_SIZES.xs;
};

const ProfileInitialsContent = styled.div`
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor || colors.action700};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.action700TextColor};
  font-size: ${({ size }) => size / 2}px;
`;

const isEmoji = (str) => {
  const regex = /^(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)$/u;
  return regex.test(str);
};

export const getFirstLetter = (word) => {
  if (isEmoji(word)) {
    return '';
  }

  return toUpper(word.charAt(0));
};

// this is default theme plusplus colors
const colorsScale = [
  '#A779CA',
  '#905CB8',
  '#5B3A74',
  '#372842',
  '#59C8A4',
  '#23AD81',
  '#137D63',
  '#08483D',
];

const getColorForInitial = (firstLetter) => {
  if (!firstLetter) {
    return colorsScale[0];
  }
  const colorIndex = firstLetter.charCodeAt(0) % colorsScale.length;
  return colorsScale[colorIndex];
};

const UserProfileInitials = ({ userName, size }) => {
  if (!userName) {
    return null;
  }
  const userNamesDivided = split(userName, ' ');
  const initialUserFirstName = getFirstLetter(first(userNamesDivided));

  const initialUserLastName =
    userNamesDivided.length > 1 ? getFirstLetter(last(userNamesDivided)) : '';

  const initialsUserName = `${initialUserFirstName}${initialUserLastName}`;
  const backgroundColor = getColorForInitial(initialUserFirstName);

  return (
    <ProfileInitialsContent size={getImageSize(size)} backgroundColor={backgroundColor}>
      {initialsUserName}
    </ProfileInitialsContent>
  );
};

UserProfileInitials.propTypes = {
  userName: PropTypes.string,
  size: PropTypes.oneOf(keys(AVATAR_SIZES)),
};

export default UserProfileInitials;
