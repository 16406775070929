import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import {
  COURSE_STATUS_DRAFT,
  COURSE_STATUS_ERROR,
  COURSE_STATUS_PUBLISHED,
} from '~/app/course/constants';
import actions from '~/app/entities/actions';
import { courseSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import CourseUploadField from '~/app/inputs/components/CourseUploadField';
import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Clicker from '~/app/shared/components/Clicker';
import Icon from '~/app/shared/components/Icon';
import InfoBox from '~/app/shared/components/InfoBox';
import Loading from '~/app/shared/components/Loading';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from '~/app/shared/constants';
import { useInterval, usePrevious, useSetDetailedObject, useCurrentUser } from '~/app/shared/hooks';

const FileUploadWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.p``;

const ModalContent = styled.div`
  position: relative;
  max-height: 60vh;
  overflow: auto;
  display: flex;
`;

const ImportLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImportLoadingPlaceholderWrapper = styled.div`
  width: ${({ width }) => width || '440px'};
  height: ${({ height }) => height || '160px'};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImportLoadingPlaceholder = styled.div`
  background-color: transparent;
  border: solid 2px ${colors.action600};
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  & > * {
    z-index: 2;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    z-index: 1;
    animation: fill 2s ease infinite;

    @keyframes fill {
      0% {
        height: 0;
        background-color: ${colors.action200};
      }

      70% {
        height: 100%;
        background-color: ${colors.action200};
      }

      95% {
        height: 100%;
        background-color: transparent;
      }

      100% {
        height: 0;
      }
    }
  }
`;

const SIX_SECONDS = 6000;

const CourseImportFormModal = (props) => {
  const { handleClose, action, courseIdUpdate, handleUploadCompleted, isModalForm, fromChannel } =
    props;

  // ScormCloud does not recommend going over 1GB, so if we need to increase it in the future
  // it may be good to check alternatives
  const { scorm_cloud_file_size_upload_limit_in_mb: fileSizeLimitInMB } = useCurrentUser();
  const fileSizeLimitInBytes = (fileSizeLimitInMB | 900) * 1024 * 1024;

  const history = useHistory();

  const [courseFile, setCourseFile] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const [uploadCourse, { data: uploadedCourse, status: uploadCourseStatus }] = useEntities(
    actions.course.upload,
    null
  );

  const [fetchCourse, { data: fetchedCourse, status: fetchCourseStatus }] = useEntities(
    actions.course.retrieveDetails,
    null,
    {
      schema: courseSchema,
    }
  );

  useSetDetailedObject({ id: null }, 'courses', []);

  const {
    public_id: publicId,
    public_id_and_slug: publicIdAndSlug,
    latest_sync: latestSync,
    status: courseStatus,
  } = fetchedCourse;

  const previousLatestSync = usePrevious(latestSync);
  let uploadCompleted = Boolean(latestSync);

  if (courseIdUpdate) {
    uploadCompleted =
      uploadCompleted && Boolean(previousLatestSync) && previousLatestSync !== latestSync;
  }
  useEffect(() => {
    if (
      (uploadCourseStatus === STATUS_LOADING || fetchCourseStatus === STATUS_LOADING) &&
      !isUploading
    ) {
      setIsUploading(true);
    } else if (
      uploadCourseStatus === STATUS_DONE &&
      fetchCourseStatus !== STATUS_LOADING &&
      !uploadCompleted
    ) {
      fetchCourse(uploadedCourse.public_id);
      setIsUploading(true);
    } else if (uploadCourseStatus === STATUS_ERROR) {
      setIsUploading(false);
      toast.error('Course upload failed. Please select a valid course file.');
    }
  }, [uploadCourseStatus]);

  useInterval(() => {
    if (!uploadCompleted && publicId && fetchCourseStatus !== STATUS_LOADING) {
      fetchCourse(publicId);
    }
  }, SIX_SECONDS);

  useEffect(() => {
    if (
      uploadCourseStatus === STATUS_DONE &&
      fetchCourseStatus === STATUS_DONE &&
      uploadCompleted
    ) {
      setIsUploading(false);
    }
  }, [uploadCourseStatus, fetchCourseStatus]);

  useEffect(() => {
    if ((uploadCompleted && courseStatus === COURSE_STATUS_PUBLISHED) || isModalForm) {
      handleUploadCompleted(publicIdAndSlug);
    }

    if (uploadCompleted && courseStatus === COURSE_STATUS_DRAFT && !isModalForm) {
      const queryParams = {
        is_publishing: true,
      };

      if (fromChannel) {
        queryParams.channel = fromChannel.slug;
      }

      const queryString = qs.stringify(queryParams);

      const route = mapRoute('courseEdit', { public_id_and_slug: publicIdAndSlug });
      history.push(`${route}?${queryString}`);
    }
  }, [uploadCompleted, fromChannel]);

  const handleUploadCourse = () => {
    const payload = {
      course_file: courseFile,
    };

    if (courseIdUpdate) {
      payload.id = courseIdUpdate;
    }

    if (fromChannel) {
      payload.channel_id = fromChannel.id;
    }

    uploadCourse(payload);
  };

  const courseHelpArticleUrl =
    'https://help.plusplus.app/en/articles/5204590-import-elearning-course';

  const SCORMProgressHelpArticleUrl =
    'https://support.scorm.com/hc/en-us/articles/5452990463771-Recording-Progress-Measure-Percentage-Complete';

  return (
    <Modal
      title={isUploading ? `Importing Course file` : `${action} Course`}
      height={isUploading ? '300px' : '440px'}
      handleClose={handleClose}
      width={500}
    >
      <ModalBody>
        <ModalContent>
          <FileUploadWrapper>
            {!isUploading && (
              <>
                <Field
                  name="course_file"
                  component={CourseUploadField}
                  filePath="course_files"
                  acceptedFileExtensions=".zip,.pdf"
                  fileSizeLimit={fileSizeLimitInBytes}
                  showEditButton
                  hasUploadError={courseStatus === COURSE_STATUS_ERROR}
                  onChange={(value) => setCourseFile(value)}
                  helpText={
                    <>
                      <TextWrapper>
                        <Text size="h6">
                          Supported formats: SCORM 1.2, SCORM 2004, AICC, xAPI, cmi5, and PDF
                        </Text>
                      </TextWrapper>
                      <TextWrapper>
                        <Text size="h6">
                          Maximum file size: {fileSizeLimitInMB} MB. Note that SCORM Cloud limits{' '}
                          the file size to 100 MB for trial accounts.
                        </Text>
                      </TextWrapper>
                      <TextWrapper>
                        <Text size="h6">
                          Progress support is only available in SCORM 2004 4th edition and cmi5 with
                          the use of a progress extension. See{' '}
                          <ButtonLink
                            size="small"
                            url={SCORMProgressHelpArticleUrl}
                            target="_blank"
                          >
                            this SCORM article
                          </ButtonLink>{' '}
                          for more details.
                        </Text>
                      </TextWrapper>
                    </>
                  }
                />
                <InfoBox
                  content={
                    <>
                      First time performing an import?{' '}
                      <ButtonLink url={courseHelpArticleUrl} target="_blank">
                        Start here
                      </ButtonLink>{' '}
                      for a complete step-by-step guide.
                    </>
                  }
                  margin="16px 0 0 0"
                />
              </>
            )}
            {isUploading && (
              <ImportLoadingWrapper>
                <ImportLoadingPlaceholderWrapper>
                  <ImportLoadingPlaceholder>
                    <Icon name="doc" height={30} width={22} color={colors.action600} />
                    <Text bold color={colors.action600}>
                      Importing your course may take a few minutes
                    </Text>
                  </ImportLoadingPlaceholder>
                </ImportLoadingPlaceholderWrapper>
                <InfoBox
                  content={
                    <>
                      It is safe to close this modal. You will receive a notification once your
                      course has been imported.{' '}
                      <Clicker
                        onClick={handleClose}
                        display="inline-block"
                        color={colors.action600}
                      >
                        <Text bold>Back to Catalog</Text>.
                      </Clicker>
                    </>
                  }
                  margin="16px 0 0 0"
                />
              </ImportLoadingWrapper>
            )}
          </FileUploadWrapper>
        </ModalContent>
      </ModalBody>
      {!isUploading && (
        <ModalFooter variant="buttons" justifyContent="spaceBetween">
          <ModalFooterButton color="error" type="button" onClick={handleClose}>
            Cancel
          </ModalFooterButton>
          {isUploading && <Loading hasMargin={false} />}
          {!isUploading && (
            <ModalFooterButton type="button" disabled={!courseFile} onClick={handleUploadCourse}>
              Import
            </ModalFooterButton>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

CourseImportFormModal.propTypes = {
  handleClose: PropTypes.func,
  action: PropTypes.string,
  courseIdUpdate: PropTypes.number,
  handleUploadCompleted: PropTypes.func,
  isModalForm: PropTypes.bool,
  fromChannel: PropTypes.object,
};

export default reduxForm({ form: 'CourseImportFormModal' })(CourseImportFormModal);
