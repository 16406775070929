import PropTypes from 'prop-types';
import React from 'react';

import DatePickerInput from '~/app/inputs/components/DatePickerInput';
import colors from '~/services/colors';
import Text from '~/app/shared/components/Text';
import { noop } from 'lodash-es';
import { Stack } from '@mui/material';

const ExactDateRangeInput = (props) => {
  const { startDate, endDate, onChange } = props;

  const handleDateChange = (startDate, endDate) => {
    onChange({
      startDate,
      endDate,
    });
  };

  const handleChangeStartDate = (date) => {
    const newStartDate = date && date.isValid() ? date : undefined;
    handleDateChange(newStartDate, endDate);
  };

  const handleChangeEndDate = (date) => {
    const newEndDate = date && date.isValid() ? date : undefined;
    handleDateChange(startDate, newEndDate);
  };

  return (
    <Stack spacing={2}>
      <Stack>
        <Text size="h5" color={colors.neutral900}>
          From
        </Text>
        <DatePickerInput
          placeholder="From"
          value={startDate || null}
          onChange={handleChangeStartDate}
          clearable
          maxDate={endDate}
        />
      </Stack>
      <Stack>
        <Text size="h5" color={colors.neutral900}>
          Until
        </Text>
        <DatePickerInput
          placeholder="Until"
          value={endDate || null}
          onChange={noop}
          onDateChange={handleChangeEndDate}
          clearable
          minDate={startDate}
        />
      </Stack>
    </Stack>
  );
};

ExactDateRangeInput.defaultProps = {
  onChange: noop,
};

ExactDateRangeInput.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func,
};

export default ExactDateRangeInput;
