import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Text from '~/app/shared/components/Text';
import { SurveyQuestionKindInitialValueMap } from '~/app/surveys/constants';
import surveyProptypes from '~/app/surveys/types';
import { forEach } from 'lodash-es';

import SurveyAnswerForm from './SurveyAnswerForm';

const RequiredCopy = styled.div`
  margin-top: 12px;
  color: ${colors.error600};
`;

const SuccessMessage = styled.div`
  margin-top: 8px;
`;

const getInitialValues = (surveyRelationship) => {
  const { questions } = surveyRelationship;
  const values = {};

  forEach(questions, (question) => {
    values[`question-${question.id}`] = SurveyQuestionKindInitialValueMap[question.kind];
  });

  return values;
};

const SurveyAnswerContent = ({ surveyRelationship, showSuccess, onSuccess, showSurveyPreview }) => {
  const {
    survey,
    is_available_for_answer: isAvailable,
    is_answered_by_user: isAnswered,
  } = surveyRelationship;
  const initialValues = getInitialValues(surveyRelationship);

  if (!isAvailable && !showSurveyPreview) {
    return <Text>This survey is not available to be answered.</Text>;
  }

  if (isAnswered) {
    return <Text>Ops! Looks like this survey was already answered with your account.</Text>;
  }

  if (showSuccess) {
    return (
      <React.Fragment>
        <Text size="h3" color={colors.neutral600}>
          Thanks for answering!
        </Text>
        <SuccessMessage>
          <Text>Your response has been successfully recorded.</Text>
        </SuccessMessage>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Text>{survey.description}</Text>
      <RequiredCopy>* Required answers</RequiredCopy>
      <SurveyAnswerForm
        initialValues={initialValues}
        questions={surveyRelationship.questions}
        surveyRelationshipId={surveyRelationship.id}
        onFormSubmitSuccess={onSuccess}
      />
    </React.Fragment>
  );
};

SurveyAnswerContent.propTypes = {
  surveyRelationship: surveyProptypes.surveyRelationship,
  showSuccess: PropTypes.bool,
  onSuccess: PropTypes.func,
  showSurveyPreview: PropTypes.bool,
};

export default SurveyAnswerContent;
