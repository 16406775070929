import StarIcon from '@mui/icons-material/Star';
import { Typography, Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import colors from '~/services/colors';

const RatingContainer = styled(Box, {
  name: 'ContentItemCard',
  slot: 'rating',
})`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${colors.rating700};
`;

type ContentItemCardRatingProps = BoxProps & {
  rating: number;
};

export function ContentItemCardRating(props: ContentItemCardRatingProps) {
  const { rating, ...restBoxProps } = props;

  return (
    <RatingContainer {...restBoxProps}>
      <StarIcon sx={{ fontSize: 12, color: colors.rating600 }} />
      <Typography variant="body2" component="span">
        {rating}
      </Typography>
    </RatingContainer>
  );
}
