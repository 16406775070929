import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { TIME_FORMAT } from '~/app/shared/constants';

import Autocomplete from '../Autocomplete';

export const INVALID_TIME_MESSAGE = 'Please enter a valid time. e.g.: 02:00 PM';

function generateTimeOptions() {
  const options = [];

  for (let hour = 0; hour < 24; hour += 1) {
    for (let minute = 0; minute < 60; minute += 15) {
      const time = moment({ hour, minute }).format('h:mm A');
      options.push({ label: time, value: time });
    }
  }

  return options;
}

const timeOptions = generateTimeOptions();

const TimeInput = ({
  currentValue,
  error,
  inputId,
  searchPlaceholder,
  handleTimeChange,
  ...rest
}) => {
  return (
    <Autocomplete
      {...rest}
      id={inputId}
      name={inputId}
      value={currentValue && currentValue.isValid() ? currentValue.format(TIME_FORMAT) : null}
      options={timeOptions}
      error={error}
      placeholder={searchPlaceholder}
      onChange={handleTimeChange}
      disableClearable
    />
  );
};

TimeInput.propTypes = {
  currentValue: PropTypes.object,
  error: PropTypes.bool,
  inputId: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  handleTimeChange: PropTypes.func,
};

export default TimeInput;
