import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import * as permissionConstants from '~/app/shared/permissions';
import { BulkAddUsersButton } from '~/app/users';

function Container(props) {
  const { permissions } = props;

  if (!permissions.includes(permissionConstants.IS_ORGANIZER_PERMISSION)) {
    return null;
  }

  return (
    <div>
      <BulkAddUsersButton />
    </div>
  );
}

Container.propTypes = {
  permissions: PropTypes.array,
};

const mapStateToProps = (state) => ({
  permissions: state.user.currentUser.permissions,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
