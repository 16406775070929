import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import Form from '~/app/shared/components/OldForm';
import { useCurrentUser } from '~/app/shared/hooks';
import { checkTagHasLabelAndItems, buildFlexibleTagsList } from '~/app/topics/services';
import { isEmpty, get, filter, map } from 'lodash-es';

import TopicsInputField from '../TopicsInputField';

const TagTypesInputFields = (props) => {
  const {
    toggleTypes,
    hideMainTopics,
    hideTags,
    fieldLabels,
    strictFlexibleTagsList,
    allowedTagTypes,
    disabled,
  } = props;

  const currentUser = useCurrentUser();
  const { custom_tags: customTags } = currentUser;

  const filteredFlexibleTagList = filter(
    buildFlexibleTagsList(currentUser, toggleTypes, strictFlexibleTagsList, allowedTagTypes),
    (flexible_tag) => checkTagHasLabelAndItems(flexible_tag)
  );

  return (
    <>
      {!isEmpty(get(customTags, 'main_topics.items')) && !hideMainTopics && (
        <Form.FieldGroup>
          <Field
            label={fieldLabels?.topics ?? 'Categories'}
            name="topics"
            tagType="main_topics"
            component={TopicsInputField}
            disabled={disabled}
          />
        </Form.FieldGroup>
      )}
      {!hideTags && (
        <Form.FieldGroup>
          <Field
            name="tags"
            label={fieldLabels?.tags ?? 'Tags'}
            component={TopicsInputField}
            disabled={disabled}
            creatable
          />
        </Form.FieldGroup>
      )}
      {map(filteredFlexibleTagList, (flexible_tag) => (
        <Form.FieldGroup key={flexible_tag.filter_field_name}>
          <Field
            name={flexible_tag.filter_field_name}
            label={flexible_tag.label}
            input={get(props, `${flexible_tag.filter_field_name}.input`)}
            tagType={flexible_tag.filter_field_name}
            component={TopicsInputField}
            disabled={disabled}
          />
        </Form.FieldGroup>
      ))}
    </>
  );
};

TagTypesInputFields.propTypes = {
  toggleTypes: PropTypes.arrayOf(PropTypes.string),
  hideMainTopics: PropTypes.bool,
  hideTags: PropTypes.bool,
  fieldLabels: PropTypes.object,
  strictFlexibleTagsList: PropTypes.bool,
  allowedTagTypes: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

TagTypesInputFields.defaultProps = {
  toggleTypes: [],
  strictFlexibleTagsList: true,
  allowedTagTypes: [],
  disabled: false,
};

export default TagTypesInputFields;
