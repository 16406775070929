import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Clicker from '~/app/shared/components/Clicker';
import Icon from '~/app/shared/components/Icon';
import { noop } from 'lodash-es';

const StyledClicker = styled(Clicker)`
  &:hover {
    svg path {
      fill: ${({ hoverColor }) => hoverColor};
    }
  }
`;

const IconButton = ({
  cursor,
  title,
  onClick,
  iconName,
  color,
  hoverColor,
  width,
  height,
  disabled,
}) => {
  return (
    <StyledClicker
      cursor={cursor}
      title={title}
      onClick={onClick}
      hoverColor={hoverColor}
      disabled={disabled}
    >
      <Icon name={iconName} width={width} height={height} color={color} />
    </StyledClicker>
  );
};

IconButton.propTypes = {
  title: PropTypes.string,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  cursor: PropTypes.string,
  hoverColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  disabled: PropTypes.bool,
};

IconButton.defaultProps = {
  hoverColor: colors.emphasis600,
  onClick: noop,
  width: 23,
  height: 23,
  disabled: false,
};

export default IconButton;
