import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { OldFilterPageHeader } from '~/app/navigation/components/OldFilterPage';
import actions from '~/app/entities/actions';
import { groupSchema } from '~/app/entities/schema';
import { getDataFromState } from '~/app/entities/utils';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import Loading from '~/app/shared/components/Loading';
import MediaPoint from '~/app/shared/components/MediaPoint';
import PageTitle from '~/app/shared/components/PageTitle';
import { STATUS_LOADING } from '~/app/shared/constants';
import * as permissionConstants from '~/app/shared/permissions';
import { includes } from 'lodash-es';

import MyGroups from './MyGroups';
import { GroupCardSizeWithMargins } from './styles';

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${MediaPoint.Tablet} {
    width: calc((${GroupCardSizeWithMargins}) * 2);
  }

  ${MediaPoint.DesktopSm} {
    width: calc((${GroupCardSizeWithMargins}) * 3);
  }
`;

const MyGroupsContainer = ({
  fetchGroups,
  groups,
  groupsCount,
  groupsRequestStatus,
  permissions,
}) => {
  const [filtersSelected, setFiltersSelected] = useState({ visibility: ['owned'], o: 'name' });
  const { trackActivity } = useMetrics();

  useEffect(() => {
    fetchGroups(filtersSelected);
    trackActivity(METRICS_ACTIVITIES.MY_GROUPS_VIEW);
  }, [filtersSelected]);

  const updateSelectedFilters = (newFilters) => {
    setFiltersSelected({ ...filtersSelected, ...newFilters });
  };

  return (
    <React.Fragment>
      <PageTitle title="My Groups" />
      <OldFilterPageHeader
        title="My Groups"
        searchParam="q"
        searchPlaceholder="Find a group..."
        searchOnChange={updateSelectedFilters}
        actions={[
          <React.Fragment key="add-group-button">
            {includes(permissions, permissionConstants.CREATE_GROUP_PERMISSION) && (
              <Button
                startIcon={<Icon name="plus" />}
                size="small"
                route={`${mapRoute('groupCreate')}?origin=${mapRoute('myGroups')}`}
              >
                New Group
              </Button>
            )}
          </React.Fragment>,
        ]}
      />
      <Container>
        {groupsRequestStatus === STATUS_LOADING ? (
          <Loading />
        ) : (
          <MyGroups
            groups={groups}
            groupsCount={groupsCount}
            filtersSelected={filtersSelected}
            updateSelectedFilters={updateSelectedFilters}
          />
        )}
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const groupsRequestState = getDataFromState('myGroups', state, [groupSchema]);
  return {
    groups: groupsRequestState.data,
    groupsCount: groupsRequestState.count,
    groupsRequestStatus: groupsRequestState.status,
    permissions: state.user.currentUser.permissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchGroups: (filters) => dispatch(actions.groups.retrieveList('myGroups', filters)),
});

MyGroupsContainer.propTypes = {
  groups: PropTypes.array,
  groupsCount: PropTypes.number,
  groupsRequestStatus: PropTypes.string,
  fetchGroups: PropTypes.func,
  permissions: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyGroupsContainer);
