import { Box } from '@mui/material';

export interface EmptyMessageProps {
  message?: string;
}

const EmptyMessage = ({ message = 'No records found...' }: EmptyMessageProps) => {
  return <Box sx={{ marginTop: '50px', textAlign: 'center', fontSize: 20 }}>{message}</Box>;
};

export default EmptyMessage;
