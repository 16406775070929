import { findIndex, get, has } from 'lodash-es';

import { ASSIGNMENT_STATES } from '~/app/assignments/constants';

import { ScheduledTrack, TrackItem, TrackSection, TrackSectionOrItemNode } from '../types';

export function isItemTrackSection(item: TrackSectionOrItemNode): item is TrackSection {
  return !has(item, 'content_item');
}

export function getFirstUncompletedTrackItem(trackItems: TrackItem[]) {
  const idx = findIndex(trackItems, (item) => {
    const itemAssignment = item.content_item.user_assignment ?? item.content_item.assignment;
    return itemAssignment?.state !== ASSIGNMENT_STATES.completed;
  });

  if (idx < 0) {
    return null;
  }

  return trackItems[idx];
}

export function hasScheduledTrackSpots(scheduledTrack: ScheduledTrack) {
  const assignmentsCount = get(scheduledTrack, 'assignments_count', 0);
  // The scheduled track detail endpoint returns 'total_assignments' instead of 'assignments_count'
  const totalAssignmentsCount = get(scheduledTrack, 'total_assignments', 0);
  const numberOfAssignments = assignmentsCount || totalAssignmentsCount;

  const capacity = get(scheduledTrack, 'capacity', 0);
  return capacity === 0 || capacity - numberOfAssignments > 0;
}
