import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Dashboard from '~/app/backoffice/components/Dashboard';
import DashboardTopBar from '~/app/backoffice/components/Dashboard/DashboardTopBar';
import RQLDashboard from '~/app/backoffice/components/Dashboard/RQLDashboard';
import { useRQLRouteFilters } from '~/app/backoffice/hooks';
import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import { queries } from '~/queries';
import Page404 from '~/scenes/404';
import {
  alphabeticalAscendingText,
  alphabeticalDescendingText,
  DASHBOARD_PEOPLE,
} from '~/scenes/Dashboard/constants';
import colors from '~/services/colors';
import { ApiURLs } from '~/services/requests-base';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { usePublicIdFromURL } from '~/app/shared/hooks';
import { map } from 'lodash-es';

const PAGE_SIZE = 15;

type PeopleSectionProps = {
  segmentPublicId: string;
  filterObj: any;
  updateOrdering: any;
  updatePagination: any;
};

const TextArea = styled.div`
  color: ${colors.neutral600};
`;

const PeopleSection = (props: PeopleSectionProps) => {
  const { segmentPublicId, filterObj, updateOrdering, updatePagination } = props;

  const queryParams = useLocation();
  const searchParams = queryParams.search;

  const { data, isLoading, isError, refetch } = useQuery(
    queries.segments.people(segmentPublicId, searchParams)
  );

  useEffect(() => {
    refetch();
  }, [segmentPublicId, searchParams, refetch]);

  if (isError) return <Page404 />;

  if (isLoading) return <Loading />;

  if (!data) return null;

  const { count, results: users } = data;

  return (
    <RQLDashboard
      dashboardName={DASHBOARD_PEOPLE}
      rows={users}
      pageSize={PAGE_SIZE}
      totalCount={count}
      requestStatus={isLoading ? STATUS_LOADING : STATUS_DONE}
      refreshDashboard={queries.segments.report}
      filterObj={filterObj}
      updateOrdering={updateOrdering}
      updatePagination={updatePagination}
    >
      <Dashboard.Column
        size="20"
        header={
          <Dashboard.Header
            title="Name"
            orderingKey="name"
            ascendingText={alphabeticalAscendingText}
            descendingText={alphabeticalDescendingText}
          />
        }
      >
        {({ row }) => <Dashboard.UserCell data={row} showAvatar highlighted />}
      </Dashboard.Column>
      <Dashboard.Column
        size="18"
        header={
          <Dashboard.Header
            title="Email"
            orderingKey="email"
            ascendingText={alphabeticalAscendingText}
            descendingText={alphabeticalDescendingText}
          />
        }
      >
        {({ row }) => <TextArea>{row.email}</TextArea>}
      </Dashboard.Column>
      <Dashboard.Column
        size="10"
        header={
          <Dashboard.Header
            title="Role"
            orderingKey="role.name"
            ascendingText={alphabeticalAscendingText}
            descendingText={alphabeticalDescendingText}
          />
        }
      >
        {({ row }) => (
          <TextArea>
            {row.role}
            {row.is_mentor ? ', mentor' : null}
          </TextArea>
        )}
      </Dashboard.Column>
      <Dashboard.Column
        size="11"
        header={
          <Dashboard.Header
            title="Department"
            orderingKey="department"
            ascendingText={alphabeticalAscendingText}
            descendingText={alphabeticalDescendingText}
          />
        }
      >
        {({ row }) => {
          if (row.department) {
            return <TextArea>{row.department}</TextArea>;
          }
          return '';
        }}
      </Dashboard.Column>
      <Dashboard.Column
        size="13"
        header={
          <Dashboard.Header
            title="Title"
            orderingKey="title"
            ascendingText={alphabeticalAscendingText}
            descendingText={alphabeticalDescendingText}
          />
        }
      >
        {({ row }) => {
          if (row.title) {
            return <TextArea>{row.title}</TextArea>;
          }
          return '';
        }}
      </Dashboard.Column>
      <Dashboard.Column
        size="11"
        header={<Dashboard.Header title="Hire Date" orderingKey="working_since" />}
      >
        {({ row }) => row.working_since && <Dashboard.MonthDateCell date={row.working_since} />}
      </Dashboard.Column>
      <Dashboard.Column
        size="14"
        header={
          <Dashboard.Header
            title="Location"
            orderingKey="location_text"
            ascendingText={alphabeticalAscendingText}
            descendingText={alphabeticalDescendingText}
          />
        }
      >
        {({ row }) => (
          <Dashboard.LocationCell
            data={{ location: row.location, allowsLocal: true, location_text: row.location_text }}
          />
        )}
      </Dashboard.Column>
    </RQLDashboard>
  );
};

const buildExportUrl = (publicId: string) => {
  return ApiURLs['api_segments:people-export'](publicId);
};

const PeopleReport = () => {
  const { publicId: segmentPublicId } = usePublicIdFromURL();

  const {
    data: segment,
    isLoading,
    isError,
  } = useQuery(queries.segments.report(segmentPublicId || ''));

  const [fetchFilters, { data: filters, status: filterStatus }] = useEntities(
    actions.userData.retrieveFilters,
    null
  );

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  const { filterObj, updateOrdering, updatePagination } = useRQLRouteFilters(
    'name',
    map(filters, (item) => item.filter),
    PAGE_SIZE
  );

  if (isError) return <Page404 />;

  if (isLoading || filterStatus === STATUS_LOADING) return <Loading />;

  if (!segment) return null;

  return (
    <>
      <DashboardTopBar title={segment.name} subTitle={segment.description}>
        <Dashboard.ExportCsvButton
          dashboardName="Users"
          buttonUrl={buildExportUrl(segment.public_id)}
        />
      </DashboardTopBar>
      <PeopleSection
        segmentPublicId={segment.public_id}
        filterObj={filterObj}
        updateOrdering={updateOrdering}
        updatePagination={updatePagination}
      />
    </>
  );
};

export default PeopleReport;
