import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router';

import { toast } from '~/app/notifications/components/NotificationCenter';
import { queries } from '~/queries';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import { useCurrentUser, useLabels } from '~/app/shared/hooks';
import { has } from 'lodash-es';

import { useChannelRoutes } from '../hooks/useChannelRoutes';

import ChannelForm from './ChannelForm';
import { User } from '~/app/shared/components/types';
import { Channel } from '../types';

type ChannelFormPageProps = {
  pageTitle: string;
  topBarActionName: string;
  backRoute: string;
  breadcrumbsItemList: object[];
  initialValuesParams: any;
  onSubmit: any;
};

type InitialValuesParams = {
  currentUser: User;
  channel?: Channel;
};

const getInitialValues = (params: InitialValuesParams) => {
  const { currentUser, channel } = params;

  let initialValues = {} as Record<string, any>;

  if (channel) {
    const { color: _, theme: channelTheme, ...restChannel } = channel;

    initialValues = {
      ...restChannel,
    };

    if (channelTheme) {
      initialValues.theme_id = channelTheme.id;
    }
  }

  // Owners
  if (!has(initialValues, 'owners_ids')) {
    initialValues.owners_ids = [currentUser.id];
  }

  if (!has(initialValues, 'theme_id')) {
    initialValues.theme_id = null;
  }

  if (!has(initialValues, 'is_hidden')) {
    initialValues.is_hidden = false;
  }

  if (!has(initialValues, 'settings.non_owner_can_create_content')) {
    initialValues.settings = { non_owner_can_create_content: { value: false, default: false } };
  }

  return { ...initialValues };
};

const ChannelFormPage = (props: ChannelFormPageProps) => {
  const {
    pageTitle,
    topBarActionName,
    backRoute,
    onSubmit,
    breadcrumbsItemList,
    initialValuesParams,
    initialValuesParams: { channel },
  } = props;

  const history = useHistory();
  const currentUser = useCurrentUser();

  const queryClient = useQueryClient();

  const { label_channel: labelChannel } = useLabels();

  const initialValues = getInitialValues({
    currentUser,
    ...initialValuesParams,
  });

  const isEdit = Boolean(channel);
  const channelId = channel?.id;
  const form = `Channel${isEdit ? 'Edit' : 'Create'}Form`;
  const { detail: channelDetailRoute } = useChannelRoutes();
  const onSubmitHandler = async (values) => {
    const { slug: channelSlug } = await onSubmit.mutateAsync(values);
    return channelSlug;
  };

  const invalidatechnnelSelector = () => {
    const queryKey = queries.channels.list('view_mode=lite').queryKey;
    queryClient.invalidateQueries({ queryKey });
  };

  const onSubmitSuccessHandler = (slug, isEdit) => {
    invalidatechnnelSelector();

    toast.success(`${labelChannel} ${isEdit ? 'updated' : 'created'} successfully`);
    history.push(channelDetailRoute({ slug }));
  };

  return (
    <>
      <PageTitle title={pageTitle} />
      <ChannelForm
        form={form}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        channelId={channelId}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        onSubmitHandler={onSubmitHandler}
        onSubmitSuccessHandler={onSubmitSuccessHandler}
        settingsContext="channel/form"
      />
    </>
  );
};

export default ChannelFormPage;
