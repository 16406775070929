import React, { useState } from 'react';
import { useHistory } from 'react-router';

import actions from '~/app/entities/actions';
import { csvImportSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import EventTypeCSVImportForm from '~/app/event-types/components/EventTypeCSVImportForm/EventTypeCSVImportForm';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { STATUS_DONE } from '~/app/shared/constants';
import { useCurrentUser, useOriginRoute } from '~/app/shared/hooks';
import { map } from 'lodash-es';

const EventTypeBulkImport = () => {
  const history = useHistory();
  const currentUser = useCurrentUser();
  const redirectionRoute = useOriginRoute(currentUser.home_page_url_path);

  const [csvImport, setCsvImport] = useState(null);
  const [error, setError] = useState(null);

  const [fetchCsvImport] = useEntities(
    actions.csvImport.retrieveDetails,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        setCsvImport(data);
      }
    },
    { schema: csvImportSchema }
  );

  const onFormSubmitSuccess = (result) => {
    const { csv_import_id: csvImportId, asyncJobOutputParams } = result;
    const { errors } = asyncJobOutputParams;

    const successMsg = `The CSV file was ${csvImport ? 'imported' : 'pre-imported'} successfully!`;
    const errorMsg = `The CSV file could not be ${csvImport ? 'imported' : 'pre-imported'}.`;

    if (errors) {
      map(errors, (backError) => {
        const { exception } = backError.params;
        setError({
          ...error,
          error1: `${backError.message} ${exception || ''}`,
        });
      });
      toast.error(errorMsg);
      return;
    }

    toast.success(successMsg);

    if (!csvImport) {
      fetchCsvImport(csvImportId);
      return;
    }

    history.push(redirectionRoute);
  };

  const onFormSubmitFailure = () => {
    toast.error('Something went wrong.');
  };

  return (
    <EventTypeCSVImportForm
      redirectionRoute={redirectionRoute}
      onSubmitSuccess={onFormSubmitSuccess}
      onSubmitFail={onFormSubmitFailure}
      asyncJobError={error}
      csvImport={csvImport}
    />
  );
};

export default EventTypeBulkImport;
