import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import RequestEventModal from '~/app/event-types/components/RequestEventModal';
import Button from '~/app/shared/components/Button';
import Pill from '~/app/shared/components/Pill';
import { get } from 'lodash-es';

const Wrapper = styled.div`
  display: inherit;
  align-items: center;
  gap: 5px;

  > * + * {
    margin-left: 8px;
  }
`;

export const FullCapacityActionButtons = ({ event }) => {
  const { event_type: eventType } = event;

  const toggleRequestEvent = get(event, 'settings.toggle_request_event.value', false);
  const toggleAskForTeamSize = get(
    event,
    'settings.toggle_ask_for_team_size_request_an_event.value',
    false
  );

  return (
    <Wrapper>
      <Pill variant="lightGray" label="No spots left" />
      {toggleRequestEvent && eventType && (
        <RequestEventModal
          eventType={eventType}
          toggleAskForTeamSize={toggleAskForTeamSize}
          renderButton={({ handleModalOpen }) => (
            <Button onClick={handleModalOpen}>Request an event</Button>
          )}
        />
      )}
    </Wrapper>
  );
};

FullCapacityActionButtons.propTypes = {
  event: PropTypes.object,
};
