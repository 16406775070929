import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  PREFERRED_EVENT_ATTENDANCE_METHOD_LOCAL,
  PREFERRED_EVENT_ATTENDANCE_METHOD_ONLINE,
  PREFERRED_EVENT_ATTENDANCE_METHOD_NEITHER,
} from '~/app/event-shared/constants';
import InputLabel from '~/app/inputs/components/InputLabel';
import RadioInput from '~/app/inputs/components/RadioInput';
import TextInput from '~/app/inputs/components/TextInput';
import colors from '~/services/colors';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import { STATUS_DONE, STATUS_ERROR } from '~/app/shared/constants';
import { isEmpty, isEqual, isNull, map, isNil } from 'lodash-es';

const SectionContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const LabelContainer = styled.div`
  margin-bottom: 4px;
`;

const ErrorMessage = styled.div`
  color: #ac224f;
  margin: 5px auto;
`;

const ResponseMessage = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const SuccessMessage = styled(ResponseMessage)`
  color: ${colors.emphasis600};
`;

const FailMessage = styled(ResponseMessage)`
  color: #ac224f;
`;

const RequestEventModal = ({
  eventType,
  handleEventRequest,
  onSubmitSuccess,
  requestEventStatus,

  toggleAskAboutTeamSize,

  handleClose,
}) => {
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [requested, setRequested] = useState(false);
  const [error, setError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [preferredAttendanceMethod, setPreferredAttendanceMethod] = useState(null);

  useEffect(() => {
    if (requested && isEqual(requestEventStatus, STATUS_DONE)) {
      onSubmitSuccess();
      handleClose();
    }
  }, [requestEventStatus]);

  const handleSubmit = () => {
    const { id } = eventType;

    if (isEmpty(description) || isNull(quantity) || isNil(preferredAttendanceMethod)) {
      setError(true);
      return;
    }

    if (description.length > 200) {
      setValidationError(true);
      return;
    }

    setRequested(true);
    handleEventRequest(id, description, quantity, preferredAttendanceMethod);
  };

  const handleUpdateMessage = (event) => setDescription(event.target.value);

  const handlePreferenceOptionChange = (e) => setPreferredAttendanceMethod(e);

  const requestSucceeded = isEqual(requestEventStatus, STATUS_DONE);
  const requestFailed = isEqual(requestEventStatus, STATUS_ERROR);

  const preferredAttendanceMethodOptions = [
    { label: 'In-Person', value: PREFERRED_EVENT_ATTENDANCE_METHOD_LOCAL },
    { label: 'Online', value: PREFERRED_EVENT_ATTENDANCE_METHOD_ONLINE },
    { label: 'No preference', value: PREFERRED_EVENT_ATTENDANCE_METHOD_NEITHER },
  ];

  return (
    <Modal title="Request a New Event" handleClose={handleClose} width={520}>
      <ModalBody>
        <SectionContainer>
          <TextInput
            label="Tell us more about this request"
            multiline
            rows={3}
            inputProps={{
              name: 'message',
              maxLength: 200,
              value: description,
              onChange: handleUpdateMessage,
            }}
            helperText={`${description.length}/200 characters`}
            error={error && !description}
          />
          {error && !description && <ErrorMessage>This field is required.</ErrorMessage>}
          {validationError && description.length > 200 && (
            <ErrorMessage>Your request shouldn&apos;t be longer than 200 characters.</ErrorMessage>
          )}
        </SectionContainer>
        {toggleAskAboutTeamSize && (
          <SectionContainer>
            <TextInput
              label="Apart from you, how many people would be attending the event?"
              type="number"
              inputProps={{
                min: 0,
              }}
              error={error && isEmpty(quantity) && toggleAskAboutTeamSize}
              value={quantity}
              onChange={(event) => setQuantity(event.target.value)}
            />
            {error && isEmpty(quantity) && toggleAskAboutTeamSize && (
              <ErrorMessage>This field is required.</ErrorMessage>
            )}
          </SectionContainer>
        )}
        <SectionContainer>
          <LabelContainer>
            <InputLabel>How would you like to attend?</InputLabel>
          </LabelContainer>
          {map(preferredAttendanceMethodOptions, (option) => (
            <RadioInput
              key={option.value}
              value={option.value}
              label={option.label}
              name="preferred_attendance_method"
              inputValue={preferredAttendanceMethod}
              onChange={handlePreferenceOptionChange}
            />
          ))}
          {error && isNil(preferredAttendanceMethod) && (
            <ErrorMessage>This field is required.</ErrorMessage>
          )}
        </SectionContainer>
        {requested && requestSucceeded && (
          <SuccessMessage>
            Event requested with success, the organizer will contact you soon.
          </SuccessMessage>
        )}
        {requested && requestFailed && (
          <FailMessage>
            There was an error while requesting the event. Please contact the system admin.
          </FailMessage>
        )}
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleSubmit}>Request</ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

RequestEventModal.propTypes = {
  eventType: PropTypes.object,
  handleEventRequest: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  requestEventStatus: PropTypes.string,

  toggleAskAboutTeamSize: PropTypes.bool,

  handleClose: PropTypes.func,
};

export default RequestEventModal;
