import moment from 'moment';

import colors from '~/services/colors';
import { formatDate } from '~/services/datetime';
import Text from '~/app/shared/components/Text';
import { isNil } from 'lodash-es';

interface AvailableSessionsTextProps {
  availableSessionsCount?: number;
  nextSession?: string;
}

const AvailableSessionsText = ({
  availableSessionsCount,
  nextSession,
}: AvailableSessionsTextProps) => {
  if (availableSessionsCount === 0 || isNil(availableSessionsCount)) {
    return <Text color={colors.neutral400}>No Sessions</Text>;
  }

  const upcomingDate = formatDate(moment(nextSession));

  if (availableSessionsCount === 1) {
    return <Text color={colors.neutral900}>{upcomingDate}</Text>;
  }

  return (
    <>
      <Text color={colors.neutral900}>Available:&nbsp;</Text>
      <Text color={colors.emphasis600}>{upcomingDate}</Text>
    </>
  );
};

export default AvailableSessionsText;
