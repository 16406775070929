import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import StreamingTag from '~/app/event-shared/components/StreamingTag';
import { getEarliestTimeslot } from '~/app/event-shared/services';
import colors from '~/services/colors';
import Datetime from '~/app/shared/components/Datetime';
import { map } from 'lodash-es';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px 12px 20px;
`;

const EventTitle = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.4px;
  color: ${colors.emphasis600};
  overflow: hidden;
  max-height: 38px;
`;

export const Presenter = styled.span`
  display: block;
  font-size: 14px;
  color: ${colors.emphasis600};
  margin-bottom: 4px;
`;

const Icon = styled.i`
  color: ${colors.neutral500};
  font-size: 14px;
  margin-right: 4px;
`;

const Info = styled.span`
  font-size: 14px;
  color: ${colors.neutral500};
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 4px;
`;

const TagWrapper = styled.div`
  margin-top: 8px;
`;

export const EventItemHorizontal = (props) => {
  const { event, userTimezone, singleCreatorTitle, multipleCreatorsTitle, hideOnlineTag } = props;

  const earliestTimeslot = getEarliestTimeslot(event);

  const presenters = event.presenters.slice(0, 2);

  return (
    <Wrapper>
      <EventTitle>{event.name}</EventTitle>
      <Presenter>
        {event.presenters.length > 1 &&
          `${multipleCreatorsTitle}: ${map(presenters, (p) => ` ${p.name}`)} ${
            event.presenters.length > 2 ? ` and +${event.presenters.length - 2}` : ''
          }
        `}
        {event.presenters.length === 1 && `${singleCreatorTitle}: ${event.presenters[0].name}`}
      </Presenter>
      <InfoWrapper>
        <Icon className="mdi mdi-map-marker" />
        <Info>
          {event.timeslots[0].rooms_info}
          {event.timeslots[0].rooms_info ? ' @ ' : ''}
          {event.location_name}
        </Info>
      </InfoWrapper>
      <InfoWrapper>
        <Icon className="mdi mdi-calendar-blank" />
        <Info>
          {!event.has_passed ? (
            <Datetime
              datetime={earliestTimeslot.starts_at_tz_aware}
              timezone={event.timezone}
              displayTimezone={userTimezone}
              isOnline={event.is_online}
            />
          ) : (
            <span>Event Passed</span>
          )}
        </Info>
      </InfoWrapper>
      {!hideOnlineTag && !event.has_passed && event.is_online && (
        <TagWrapper>
          <StreamingTag />
        </TagWrapper>
      )}
    </Wrapper>
  );
};

EventItemHorizontal.propTypes = {
  event: PropTypes.object,
  userTimezone: PropTypes.string,
  singleCreatorTitle: PropTypes.string,
  multipleCreatorsTitle: PropTypes.string,
  hideOnlineTag: PropTypes.bool,
};

EventItemHorizontal.defaultProps = {
  hideOnlineTag: false,
};

export default EventItemHorizontal;
