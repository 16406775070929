import styled from 'styled-components';

import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { useAssignmentActionsCtx } from '~/features/contentitems/contexts/AssignmentActionsCtx';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import Button from '~/app/shared/components/Button';
import Pill from '~/app/shared/components/Pill';
import { get, toNumber, isNil } from 'lodash-es';
import { CheckIcon } from '~/vendor/mui-icons';

const Container = styled.div`
  min-height: 40px; // Large Button height
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
`;

interface PrimaryActionButtonProps {
  content: ContentItem;
}

const PrimaryActionButton = (props: PrimaryActionButtonProps) => {
  const { content } = props;

  const { is_completable: isCompletable } = content;
  const assignment = content?.user_assignment || content?.assignment;

  const assignmentState = get(assignment, 'state');
  const { create, complete, isLoading } = useAssignmentActionsCtx();

  if (!isCompletable || assignmentState === ASSIGNMENT_STATES.completed) {
    return null;
  }

  const fractionWatchedForCompletion = toNumber(get(content, 'fraction_watched_for_completion', 0));

  if (fractionWatchedForCompletion > 0) {
    return (
      <Container>
        <Pill
          icon="play"
          iconMarginRight="4px"
          label="Watch to Complete"
          variant="lighterGrayWithDarkerTextColor"
          size="medium"
        />
      </Container>
    );
  }

  const onMarkCompleteClick =
    isNil(assignment) || assignmentState === ASSIGNMENT_STATES.dropped
      ? () => create({ is_completed: true })
      : complete;

  return (
    <Container>
      <Button
        variant="contained"
        startIcon={<CheckIcon />}
        fullWidth
        onClick={() => onMarkCompleteClick()}
        disabled={isLoading}
      >
        Mark Complete
      </Button>
    </Container>
  );
};

export default PrimaryActionButton;
