import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import TextField from '~/app/inputs/components/TextField';
import { getConfluencePageProps } from '~/services/embed';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Form from '~/app/shared/components/OldForm';
import Text from '~/app/shared/components/Text';
import { trim } from 'lodash-es';

import ConfluenceViewPage from '../ConfluenceDocUpload/ConfluenceViewPage';

const ConfluenceDocsURLLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const validateRequired = Form.validations.required();

const validateUrl = (confluenceViewPageProps) => {
  if (!confluenceViewPageProps) {
    return 'Please enter a valid Confluence page or article URL.';
  }
};

const ConfluenceDocModal = ({
  handleClose,
  confluenceViewPageProps,
  handleSubmit,
  invalid,
  pristine,
}) => {
  const embedHelpArticleLink =
    'https://help.plusplus.app/en/articles/6082524-how-to-embed-media-in-an-article';

  return (
    <Modal width={1000} handleClose={handleClose} title="Insert Confluence Doc">
      <ModalBody>
        <Form.FieldGroup>
          <ConfluenceDocsURLLabelContainer>
            <ButtonLinkWrapper>
              <ButtonLink url={embedHelpArticleLink} target="_blank">
                <Text size="h5">How to embed media</Text>
              </ButtonLink>
            </ButtonLinkWrapper>
          </ConfluenceDocsURLLabelContainer>
          <Field
            label="Published Confluence Doc Url"
            placeholder="Insert URL"
            name="confluence_doc_url"
            component={TextField}
            validate={[validateRequired, validateUrl]}
          />
        </Form.FieldGroup>
        {confluenceViewPageProps && <ConfluenceViewPage blockProps={confluenceViewPageProps} />}
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose} color="error" size="large">
          Cancel
        </ModalFooterButton>
        <ModalFooterButton onClick={handleSubmit} size="large" disabled={invalid || pristine}>
          Insert
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

ConfluenceDocModal.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,

  confluenceViewPageProps: PropTypes.object,

  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
};

const ConfluenceDocModalForm = reduxForm({
  form: 'confluenceDoc',
  onSubmit: (values, dispatch, { confluenceDocUrl, handleInsertClick }) => {
    handleInsertClick(confluenceDocUrl);
  },
})(ConfluenceDocModal);

const formSelector = formValueSelector('confluenceDoc');
const mapStateToProps = (state) => {
  const confluenceDocUrl = formSelector(state, 'confluence_doc_url');
  const parsedUrl = trim(confluenceDocUrl);
  return {
    confluenceDocUrl: parsedUrl,
    confluenceViewPageProps: getConfluencePageProps(parsedUrl),
  };
};

export default connect(mapStateToProps)(ConfluenceDocModalForm);
