import { arrayMove } from '@dnd-kit/sortable';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { toast } from '~/app/notifications/components/NotificationCenter';
import TrackItemList from '~/app/tracks/components/TrackItemList';
import { findIndex, filter, find } from 'lodash-es';

const SectionTrackItemFields = (props) => {
  const {
    trackType,
    track_items: { input: trackItemsInput },
    sections: { input: sectionsInput },
    trackStartsAt,
    trackEndsAt,
  } = props;

  // Used in Undo action
  const [removedItemMap, setRemovedItemMap] = useState({});
  const [restoreItem, setRestoreItem] = useState(null);

  const handleAddAtIndex = (item, index) => {
    const items = [...trackItemsInput.value];

    items.splice(index, 0, item);
    trackItemsInput.onChange(items);
  };

  useEffect(() => {
    if (!restoreItem) {
      return;
    }

    const { item, index } = removedItemMap[restoreItem] ?? {};

    if (!item) {
      return;
    }

    if (find(trackItemsInput.value, item)) {
      return;
    }

    handleAddAtIndex(item, index);
    setRestoreItem(null);
  }, [restoreItem]);

  const handleRemove = (item, index, doToast = true) => {
    const {
      content_item: { public_id, name },
    } = item;
    const items = trackItemsInput.value;

    const updatedItems = filter(items, (_, i) => i !== index);
    trackItemsInput.onChange(updatedItems);

    // Store the item with its index, so it can be restored later by useEffect
    setRemovedItemMap((s) => ({ ...s, [public_id]: { item, index } }));

    if (doToast) {
      toast.success(
        `"${name}" removed from Track.`,
        'This change only takes effect after you publish.',
        {
          buttonLabel: 'Undo',
          buttonClickCallback: () => setRestoreItem(public_id),
          closeOnClick: true,
        }
      );
    }
  };

  const reorderArray = (event, input, key, newSection = null) => {
    const { active, over } = event;
    const { value, onChange } = input;

    const activeIndex = findIndex(value, [key, active.id]);
    const overIndex = findIndex(value, [key, over.id]);

    const reorderedArray = arrayMove(value, activeIndex, overIndex);
    if (newSection) {
      const activeIndexAfterReorder = findIndex(reorderedArray, [key, active.id]);
      reorderedArray[activeIndexAfterReorder] = {
        ...reorderedArray[activeIndexAfterReorder],
        section: newSection,
      };
    }
    onChange(reorderedArray);
  };

  const handleReorder = (event) => {
    const { active, over } = event;

    const activeIsSection = find(sectionsInput.value, ['id', active.id]);
    const activeIsItem = find(trackItemsInput.value, ['content_item.public_id', active.id]);
    const overIsSection = find(sectionsInput.value, ['id', over.id]);
    const overIsItem = find(trackItemsInput.value, ['content_item.public_id', over.id]);

    // Reorder sections
    if (activeIsSection && active.id !== over.id) {
      reorderArray(event, sectionsInput, 'id');
    }

    // Reorder items inside and across sections
    if (activeIsItem && active.id !== over.id) {
      const newSection = overIsSection ? overIsSection.id : overIsItem ? overIsItem?.section : null;
      reorderArray(event, trackItemsInput, 'content_item.public_id', newSection);
    }
  };

  return (
    <TrackItemList
      trackType={trackType}
      items={trackItemsInput.value}
      trackSections={sectionsInput.value}
      editable
      onRemove={handleRemove}
      onReorder={handleReorder}
      trackStartsAt={trackStartsAt}
      trackEndsAt={trackEndsAt}
    />
  );
};

SectionTrackItemFields.defaultProps = {
  trackType: CONTENT_TYPES.track,
};

SectionTrackItemFields.propTypes = {
  trackType: PropTypes.oneOf([
    CONTENT_TYPES.track,
    CONTENT_TYPES.scheduled_track,
    CONTENT_TYPES.assessment,
  ]),
  track_items: PropTypes.object,
  sections: PropTypes.object,
  trackStartsAt: PropTypes.string,
  trackEndsAt: PropTypes.string,
};

export default SectionTrackItemFields;
