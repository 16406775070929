import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { useParentSettings } from '~/app/settings/hooks';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import { useCreateContentFromChannelParams } from '~/features/channels/hooks/useCreateContentFromChannelParams';
import { queries } from '~/queries';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import { useOriginRoute, useQueryParams, useSetDetailedObject } from '~/app/shared/hooks';
import { isEmpty, toLower } from 'lodash-es';

import VideoFormPage from './VideoFormPage';

const labelVideo = 'Video';
const labelVideoPlural = 'Videos';

const VideoCreate = () => {
  const parentSettingsFlat = useParentSettings('video/form', 'flat');

  const { cloned: duplicatedVideoId } = useQueryParams();
  const isDuplicating = Boolean(duplicatedVideoId);

  const {
    isFromChannel,
    isLoading: isLoadingChannelData,
    channelId,
  } = useCreateContentFromChannelParams();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.videos
  }&o=upcoming`;

  const backRoute = useOriginRoute(catalogRoute);

  const { data: clonedVideo, isLoading: isLoadingVideoToClone } = useQuery({
    ...queries.videos.detail(duplicatedVideoId || '', 'detail'),
    retry: 0,
    enabled: isDuplicating,
  });

  useSetDetailedObject({ id: null }, 'videos', []);

  if ((isDuplicating && isLoadingVideoToClone) || (isFromChannel && isLoadingChannelData)) {
    return <Loading />;
  }

  const initialValuesParams = {};

  if (duplicatedVideoId) {
    initialValuesParams.clonedVideo = clonedVideo;
  } else if (!isEmpty(parentSettingsFlat)) {
    initialValuesParams.defaultSettings = parentSettingsFlat;
  }

  if (isFromChannel) {
    initialValuesParams.defaultChannelId = channelId;
  }

  return (
    <VideoFormPage
      pageTitle={`${isDuplicating ? 'Duplicate' : 'Create'} ${labelVideo}`}
      topBarActionName={`${isDuplicating ? 'Duplicating' : 'Creating'} ${labelVideo}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelVideoPlural,
          link: catalogRoute,
        },
        {
          label: `New ${toLower(labelVideo)}`,
        },
      ]}
    />
  );
};

export default VideoCreate;
