import { useRef } from 'react';

import { testimonialSchema } from '~/app/entities/schema';
import KebabMenu, { KebabMenuItem, HandlesClose } from '~/app/shared/components/KebabMenu';
import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import { VisibilityOffIcon, VisibilityIcon } from '~/vendor/mui-icons';

interface TestimonialsMenuProps {
  testimonialId: number;
  isHidden: boolean;
}

export const TestimonialsMenu = ({ testimonialId, isHidden }: TestimonialsMenuProps) => {
  const actionText = isHidden ? 'Show testimonial' : 'Hide testimonial';
  const ActionIcon = isHidden ? VisibilityIcon : VisibilityOffIcon;
  const menuRef = useRef<HandlesClose>(null);

  const [updateHiddenStatus] = useEntities(actions.testimonial.updateHiddenStatus, null, {
    schema: [testimonialSchema],
  });

  const changeTestimonialHiddenStatus = (e) => {
    menuRef?.current?.handleClose(e);
    updateHiddenStatus(testimonialId, { is_hidden: !isHidden });
  };

  return (
    <KebabMenu ref={menuRef}>
      <KebabMenuItem
        onClick={changeTestimonialHiddenStatus}
        ActionIcon={ActionIcon}
        actionText={actionText}
      />
    </KebabMenu>
  );
};
