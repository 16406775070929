import { all } from 'redux-saga/effects';

import alertsAppSagas from '~/app/alerts/sagas';
import backofficeSagas from '~/app/backoffice/sagas';
import entitiesSagas from '~/app/entities/sagas';
import eventSharedAppSagas from '~/app/event-shared/sagas';
import eventAppSagas from '~/app/event/sagas';
import sharedAppSagas from '~/app/shared/sagas';
import usersAppSagas from '~/app/users/sagas';
import { addFormSubmitSagaTo } from '~/vendor/redux-form-submit-saga';

function* rootSaga() {
  yield all([
    entitiesSagas(),
    eventAppSagas(),
    eventSharedAppSagas(),
    usersAppSagas(),
    sharedAppSagas(),
    alertsAppSagas(),
    backofficeSagas(),
  ]);
}

export default addFormSubmitSagaTo(rootSaga);
