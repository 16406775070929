import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import TagsInput from '~/app/inputs/components/TagsInput';
import Form from '~/app/shared/components/OldForm';
import { filter, isEmpty, map, trim } from 'lodash-es';

const EmailTagsInput = ({
  labelField,
  placeholder,
  emails: emailsField,
  emailsValidationMap,
  handleTagDeletion,
  tagLimit,
}) => {
  const [emailTags, setEmailTags] = useState([]);

  useEffect(() => {
    emailsField.input.onChange(emailTags);
  }, [emailTags]);

  const handleDeletion = (i) => {
    setEmailTags(filter(emailTags, (_, index) => i !== index));
    if (handleTagDeletion && emailTags[i]) {
      handleTagDeletion(emailTags[i]);
    }
  };

  const handleAddition = (email) => {
    const newEmail = { id: email, [labelField]: trim(email) };
    if (!Form.validations.email(newEmail[labelField])) {
      newEmail.isValid = false;
    }
    setEmailTags((prevEmails) => [...prevEmails, newEmail]);
  };

  const validatedEmailTags = isEmpty(emailsValidationMap)
    ? emailTags
    : map(emailTags, (emailTag) => ({
        ...emailTag,
        isValid: emailsValidationMap[emailTag[labelField]],
      }));

  return (
    <TagsInput
      tags={validatedEmailTags}
      labelField={labelField}
      handleDeletion={handleDeletion}
      handleAddition={handleAddition}
      placeholder={emailTags.length === 0 ? placeholder : null}
      tagLimit={tagLimit}
    />
  );
};

EmailTagsInput.defaultProps = {
  labelField: 'email',
  emailsValidationMap: {},
};

EmailTagsInput.propTypes = {
  labelField: PropTypes.string,
  placeholder: PropTypes.string,
  emailsValidationMap: PropTypes.object,
  emails: PropTypes.object,
  handleTagDeletion: PropTypes.func,
  tagLimit: PropTypes.number,
};

export default EmailTagsInput;
