import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import { eventSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import {
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
} from '~/app/event-shared/constants';
import SelectField from '~/app/inputs/components/SelectField';
import colors from '~/services/colors';
import { displayDatetime, formatDuration } from '~/services/datetime';
import Loading from '~/app/shared/components/Loading';
import { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { STATUS_DONE } from '~/app/shared/constants';
import { useLabels, useCurrentUser } from '~/app/shared/hooks';
import { forEach, get, toLower, map, values, countBy } from 'lodash-es';
import {
  Box,
  Chip,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { LocationOnIcon, EventIcon } from '~/vendor/mui-icons.tsx';

const ChipContainer = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 4px;
`;

const ScheduledTrackEnrollModalContent = ({
  content,
  handleAssign,
  count,
  isBulk,
  tableMinWidth,
}) => {
  const { label_track: labelTrack } = useLabels();
  const [eventsMethods, setEventsMethods] = useState({});
  const { timezone: userTimezone } = useCurrentUser();
  const [isProcessingEnrollment, setIsProcessingEnrollment] = useState(false);

  const [fetchEvents, { data: events, status: eventsStatus }] = useEntities(
    actions.scheduledTrack.retrieveTrackEventList,
    ({ data, status }) => {
      if (status === STATUS_DONE) {
        const methods = {};
        forEach(data, (event) => {
          methods[event.public_id] = event.is_online
            ? ENROLLMENT_STATUS_GOING_ONLINE
            : ENROLLMENT_STATUS_GOING;
        });
        setEventsMethods(methods);
      }
    },
    {
      schema: [eventSchema],
    }
  );

  useEffect(() => {
    fetchEvents(content.public_id, {});
  }, []);

  const isLoading = eventsStatus !== STATUS_DONE;
  const methodsCount = countBy(values(eventsMethods), (value) => value);

  const eventMethodSelect = (event) => {
    if (event.is_online && event.is_local) {
      return (
        <SelectField
          options={[
            { value: ENROLLMENT_STATUS_GOING_ONLINE, label: 'Online' },
            { value: ENROLLMENT_STATUS_GOING, label: 'In Person' },
          ]}
          input={{
            value: eventsMethods[event.public_id] || ENROLLMENT_STATUS_GOING_ONLINE,
            onChange: (value) => setEventsMethods({ ...eventsMethods, [event.public_id]: value }),
          }}
        />
      );
    }
    return event.is_online ? 'Online' : 'In Person';
  };

  const eventPlural = (value) => `event${value > 1 ? 's' : ''}`;

  const methodsCountMessage = () => {
    const prefix = isBulk ? 'to' : 'You will';
    const local = get(methodsCount, ENROLLMENT_STATUS_GOING, 0);
    const online = get(methodsCount, ENROLLMENT_STATUS_GOING_ONLINE, 0);
    if (local === 0) return `${prefix} be enrolled to ${online} online ${eventPlural(online)}`;
    if (online === 0) return `${prefix} be enrolled to ${local} in person ${eventPlural(local)}`;
    return `${prefix} be enrolled to ${local} in person ${eventPlural(
      local
    )} and ${online} online ${eventPlural(online)}`;
  };

  const helpText = isBulk
    ? `When enrolling people to the ${toLower(
        labelTrack
      )}, they will be automatically enrolled to its events. Take a moment to review.`
    : `When enrolling to this ${toLower(
        labelTrack
      )}, you will also be automatically enrolled to all its events. Take a moment to review.`;

  return (
    <>
      <ModalBody fullSize scrollable>
        {isLoading && <Loading />}
        {!isLoading && (
          <Stack direction="column" spacing={0}>
            <Box m={2}>
              <Text>{helpText}</Text>
            </Box>

            <Box m={2} mt={0}>
              <Box mt={0} mb={1}>
                {isBulk && <Text color={colors.neutral700}>{`${count} people`} </Text>}
                <Text color={colors.neutral500}>{`${methodsCountMessage()}`} </Text>
              </Box>
              <TableContainer
                component={Paper}
                sx={{ border: 1, borderColor: colors.neutral200, borderBottom: 0 }}
                elevation={0}
              >
                <Table sx={{ minWidth: tableMinWidth }} size="small" aria-label="events table">
                  <TableBody>
                    {map(events, (event) => (
                      <TableRow key={`${event.public_id_and_slug}`}>
                        <TableCell align="left" sx={{ width: '75%', padding: 2 }}>
                          <Text ellipsisOnLine={2}>{event.name}</Text>
                          <ChipContainer>
                            <Chip
                              icon={<EventIcon />}
                              size="small"
                              label={displayDatetime(event.starts_at_tz_aware, userTimezone)}
                            />
                            <Chip size="small" label={formatDuration(event.duration)} selected />
                            {event.is_local && (
                              <Chip
                                icon={<LocationOnIcon />}
                                size="small"
                                label={event.location.name}
                              />
                            )}
                          </ChipContainer>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '25%', padding: 2 }}>
                          {eventMethodSelect(event)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        )}
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton
          sx={{ width: 80, height: 32 }}
          disabled={isProcessingEnrollment}
          onClick={() => {
            setIsProcessingEnrollment(true);
            return handleAssign({ eventEnrollmentOptions: eventsMethods });
          }}
        >
          {isProcessingEnrollment ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
        </ModalFooterButton>
      </ModalFooter>
    </>
  );
};

ScheduledTrackEnrollModalContent.propTypes = {
  content: PropTypes.object.isRequired,
  handleAssign: PropTypes.func.isRequired,
  count: PropTypes.number,
  isBulk: PropTypes.bool,
  tableMinWidth: PropTypes.number,
};

ScheduledTrackEnrollModalContent.defaultProps = {
  isBulk: false,
  tableMinWidth: 570,
};

export default ScheduledTrackEnrollModalContent;
