import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import UserProfileInitials from '~/app/shared/components/UserAvatar/UserProfileInitials';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import { mapRoute } from '~/services/requests';
import { getUserImage, slugify } from '~/services/utils';
import Pill from '~/app/shared/components/Pill';
import Text from '~/app/shared/components/Text';
import ContentTypeContainer from '~/app/stand-alone-shared/components/ContentTypeContainer';
import { getTagType } from '~/app/topics/services';
import { map, isEmpty, isNil } from 'lodash-es';

const BasicInfoContainer = styled.div`
  padding: 20px 20px 0;
`;

const TagsContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 8px;
`;

const Owners = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px 0 0 0;

  > * + * {
    margin-top: 6px;
    align-self: flex-start;
  }
`;

const BasicInfo = ({ eventType, tagList, user }) => {
  const getTopicIcon = (type) => {
    if (type === 'topics') {
      return 'hashtag';
    }
    return 'tag';
  };

  const getRouteUrl = (tag, type) => {
    return `${mapRoute('unifiedCatalogList')}?learning_types=${
      LEARNING_TYPES.event_types
    }&o=newest&${type}=${slugify(tag)}`;
  };

  const hasOrganizer = !isNil(eventType.organizer);
  const hasCoorganizers = !isEmpty(eventType.co_organizers);
  return (
    <BasicInfoContainer>
      <ContentTypeContainer contentType={LEARNING_TYPES.event_types} />
      <TagsContainer>
        {map(tagList, (tag) => {
          const type = getTagType(user, tag);
          return (
            <Link key={tag} to={getRouteUrl(tag, type)}>
              <Pill icon={getTopicIcon(type)} label={tag} variant="lightGray" size="medium" />
            </Link>
          );
        })}
      </TagsContainer>

      {(hasOrganizer || hasCoorganizers) && (
        <Owners>
          <Text medium>Organizers and Co-organizers</Text>
          <TagsContainer>
            {hasOrganizer && (
              <Link to={`/users/${eventType.organizer.id}/`}>
                <Pill
                  label={eventType.organizer.name}
                  imageSrc={getUserImage(eventType.organizer)}
                  imagePlaceHolder={<UserProfileInitials userName={eventType.organizer.name} />}
                  variant="darkGray"
                  size="medium"
                />
              </Link>
            )}
            {hasCoorganizers &&
              map(eventType.co_organizers, (coOrganizer) => (
                <Link to={`/users/${coOrganizer.id}/`}>
                  <Pill
                    label={coOrganizer.name}
                    imageSrc={getUserImage(coOrganizer)}
                    imagePlaceHolder={<UserProfileInitials userName={coOrganizer.name} />}
                    variant="darkGray"
                    size="medium"
                  />
                </Link>
              ))}
          </TagsContainer>
        </Owners>
      )}
    </BasicInfoContainer>
  );
};

BasicInfo.propTypes = {
  eventType: PropTypes.shape({
    organizer: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    organizers: PropTypes.array,
    co_organizers: PropTypes.array,
  }),
  tagList: PropTypes.array,
  user: PropTypes.object,
};

export default BasicInfo;
