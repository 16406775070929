import { LEARNING_TYPES, LEARNING_TYPE_ICONS_MAPPING } from '~/app/catalog/constants';
import colors from '~/services/colors';
import { merge } from 'lodash-es';

export const alphabeticalAscendingText = '(A‑Z)'; // this is a non-breaking hyphen
export const alphabeticalDescendingText = '(Z‑A)'; // this is a non-breaking hyphen

export const DASHBOARD_TYPES = merge(LEARNING_TYPES, {
  events_enrollments: 'events_enrollments',
  program_sessions_enrollments: 'program_sessions_enrollments',
  analytics: 'analytics',
  mentorship: 'mentorship',
  assessments: 'assessments',
  assessment_assignments: 'assessment_assignments',
  assignments: 'assignments',
  people: 'people',
  bulk_user_loads: 'bulk_user_loads',
  user_loads: 'user_loads',
  groups: 'groups',
  surveys: 'surveys',
  locations: 'locations',
  automated_rules: 'automated_rules',
  scheduled_tracks: 'scheduled_tracks',
  content_items: 'content_items',
  logs: 'logs',
  active_users: 'active_users',
  badges: 'badges',
});

export const DASHBOARD_TYPE_ICONS_MAPPING = merge(LEARNING_TYPE_ICONS_MAPPING, {
  [DASHBOARD_TYPES.events_enrollments]: 'ticket',
  [DASHBOARD_TYPES.program_sessions_enrollments]: 'ticket',
  [DASHBOARD_TYPES.analytics]: 'chart',
  [DASHBOARD_TYPES.mentorship]: 'calendar',
  [DASHBOARD_TYPES.assignments]: 'assignment',
  [DASHBOARD_TYPES.assessment]: 'survey',
  [DASHBOARD_TYPES.assessment_assignments]: 'survey',
  [DASHBOARD_TYPES.people]: 'person',
  [DASHBOARD_TYPES.bulk_user_loads]: 'person',
  [DASHBOARD_TYPES.user_loads]: 'person',
  [DASHBOARD_TYPES.groups]: 'group',
  [DASHBOARD_TYPES.surveys]: 'survey',
  [DASHBOARD_TYPES.locations]: 'location',
  [DASHBOARD_TYPES.automated_rules]: 'flash',
  [DASHBOARD_TYPES.scheduled_tracks]: 'scheduled-track',
  [DASHBOARD_TYPES.content_items]: 'content-item',
  [DASHBOARD_TYPES.logs]: 'log',
  [DASHBOARD_TYPES.active_users]: 'active_users',
  [DASHBOARD_TYPES.badges]: 'star',
});

// Dashboard Names, these constants are used to track the user activity
export const DASHBOARD_EVENTS = 'events';
export const DASHBOARD_EVENT_TYPES = 'event_types';
export const DASHBOARD_EVENT_ENROLLMENTS = 'event_enrollments';
export const DASHBOARD_PROGRAM_SESSIONS = 'program_sessions';
export const DASHBOARD_PROGRAM_SESSIONS_ENROLLMENTS = 'program_sessions_enrollments';
export const DASHBOARD_MENTORSHIP_SESSIONS = 'mentorship_sessions';
export const DASHBOARD_ASSESSMENTS = 'assessments';
export const DASHBOARD_ASSESSMENT_ASSIGNMENTS = 'assessment_assignments';
export const DASHBOARD_ASSIGNMENTS = 'assignments';
export const DASHBOARD_PEOPLE = 'users';
export const DASHBOARD_GROUPS = 'groups';
export const DASHBOARD_SURVEYS = 'surveys';
export const DASHBOARD_SURVEY_RESPONSES = 'surveys_responses';
export const DASHBOARD_LOCATIONS = 'locations';
export const DASHBOARD_AUTOMATED_RULES = 'automated_rules';
export const DASHBOARD_SCHEDULED_TRACKS = 'scheduled_tracks';
export const DASHBOARD_CONTENT_ITEMS = 'content_items';
export const DASHBOARD_LOGS = 'logs';
export const DASHBOARD_PEOPLE_REPORT = 'people';
export const DASHBOARD_EVENT_ENROLLMENTS_REPORT = 'events_enrollments';
export const DASHBOARD_ACTIVE_USERS = 'active_users';
export const DASHBOARD_BADGES = 'badges';

export const DASHBOARD_TYPES_URL_MAPPING = {
  [DASHBOARD_TYPES.events]: 'dashboardEvents',
  [DASHBOARD_TYPES.event_types]: 'dashboardEventTypes',
  [DASHBOARD_TYPES.events_enrollments]: 'dashboardEnrollments',
  [DASHBOARD_TYPES.programs]: 'dashboardPrograms',
  [DASHBOARD_TYPES.program_sessions_enrollments]: 'dashboardProgramSessionEnrollments',
  [DASHBOARD_TYPES.mentorship]: 'dashboardMentorships',
  [DASHBOARD_TYPES.assessments]: 'dashboardAssessments',
  [DASHBOARD_TYPES.assessment_assignments]: 'dashboardAssessmentAssignments',
  [DASHBOARD_TYPES.assignments]: 'dashboardAssignments',
  [DASHBOARD_TYPES.people]: 'dashboardPeople',
  [DASHBOARD_TYPES.groups]: 'dashboardGroups',
  [DASHBOARD_TYPES.surveys]: 'dashboardSurveys',
  [DASHBOARD_TYPES.locations]: 'dashboardLocations',
  [DASHBOARD_TYPES.scheduled_tracks]: 'dashboardScheduledTracks',
  [DASHBOARD_TYPES.content_items]: 'dashboardContentItems',
  [DASHBOARD_TYPES.logs]: 'dashboardLogs',
  [DASHBOARD_TYPES.active_users]: 'dashboardActiveUsers',
};

export const DASHBOARD_TYPES_REVERSE_URL_MAPPING = {
  events: DASHBOARD_TYPES.events,
  'event-types': DASHBOARD_TYPES.event_types,
  enrollments: DASHBOARD_TYPES.events_enrollments,
  programs: DASHBOARD_TYPES.programs,
  'program-session-enrollments': DASHBOARD_TYPES.program_sessions_enrollments,
  mentorships: DASHBOARD_TYPES.mentorship,
  assignments: DASHBOARD_TYPES.assignments,
  assessments: DASHBOARD_TYPES.assessments,
  'assessment-assignments': DASHBOARD_TYPES.assessment_assignments,
  people: DASHBOARD_TYPES.people,
  groups: DASHBOARD_TYPES.groups,
  surveys: DASHBOARD_TYPES.surveys,
  locations: DASHBOARD_TYPES.locations,
  'scheduled-tracks': DASHBOARD_TYPES.scheduled_tracks,
  'content-items': DASHBOARD_TYPES.content_items,
  logs: DASHBOARD_TYPES.logs,
  'active-users': DASHBOARD_TYPES.active_users,
};

export const DASHBOARD_AUTOMATED_RULES_STATUSES = {
  ok: { color: colors.success400, text: 'Ok', message: 'Its last run had no failures' },
  failed: {
    color: colors.error600,
    text: 'Failed',
    message: 'Its last run had no successes and at least one failure',
  },
  running: { color: colors.success700, text: 'Running', message: `It's currently running` },
  pending: { color: colors.neutral200, text: 'Pending', message: `It's never been run` },
  warning: {
    color: colors.alert400,
    text: 'Warning',
    message:
      'Its last run encountered a logical issue with the rule - e.g. deleted content item - or no users matched the rule.',
  },
  partially_failed: {
    color: colors.error200,
    text: 'Partially Failed',
    message: 'Its last run had no successes and at least one failure',
  },
};

export const DASHBOARD_CONTENT_ITEMS_TYPES =
  'out(content_type,(event,eventtype,program,officehour_program,mentorship_program,scheduled_track,question,text_question,multiple_choice_question))';

export const PUBLIC_REPORT_URLS = {
  [DASHBOARD_EVENTS]: 'eventReportSegment',
  [DASHBOARD_ASSIGNMENTS]: 'assignmentReportSegment',
  [DASHBOARD_PEOPLE_REPORT]: 'peopleReportSegment',
  [DASHBOARD_EVENT_ENROLLMENTS_REPORT]: 'eventEnrollmentReportSegment',
};

export const MONTHS_MAPPING = [
  { name: 'January', value: 1 },
  { name: 'February', value: 2 },
  { name: 'March', value: 3 },
  { name: 'April', value: 4 },
  { name: 'May', value: 5 },
  { name: 'June', value: 6 },
  { name: 'July', value: 7 },
  { name: 'August', value: 8 },
  { name: 'September', value: 9 },
  { name: 'October', value: 10 },
  { name: 'November', value: 11 },
  { name: 'December', value: 12 },
];
