import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { ContentItemContextMenu } from '~/app/content-items/components/interfaces';
import { queries } from '~/queries';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import ContentSurveyModal from '~/app/shared/components/ContentSurveyModal';
import DropDownMenu from '~/app/shared/components/DropDownMenu';
import { useToggles } from '~/app/shared/hooks';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import ScheduleTrackModal from '~/app/tracks/components/ScheduleTrackModal';
import TrackItemCompletionStatsModal from '~/app/tracks/components/TrackItemCompletionStatsModal';

import TrackArchiveUnarchiveConfirmationModal from './TrackArchiveUnarchiveConfirmationModal';

const TrackContextMenu = (props: ContentItemContextMenu) => {
  const { content, contextMenuExtraProps } = props;

  const { public_id: publicId, content_type: contentType } = content;
  const isArchived = content.is_archived || Boolean(content.archival);
  const isTrack = contentType === CONTENT_TYPES.track;
  const isScheduleTrack = contentType === CONTENT_TYPES.scheduled_track;
  const isAssessment = contentType === CONTENT_TYPES.assessment;
  const { canSeeSettings, canManageAssignment, contentTypeRoute, currentRoute } =
    contextMenuExtraProps ?? {};

  const [showArchiveUnarchiveModal, setShowArchiveUnarchiveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showCompletionStatsModal, setShowCompletionStatsModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const queryClient = useQueryClient();

  const handleCloseArchiveUnarchiveModal = (shouldRefreshContent: boolean) => {
    if (shouldRefreshContent) {
      queryClient.invalidateQueries({
        queryKey: queries.content_items.details(publicId, 'detail').queryKey,
      });
    }

    setShowArchiveUnarchiveModal(false);
  };

  const { toggle_scheduled_tracks: toggleScheduledTracks } = useToggles();

  return (
    <>
      {canSeeSettings && (
        <DropDownMenu icon="elipsis" fontSize={16} buttonAriaLabel="Settings">
          <>
            {contentTypeRoute && (
              <DropDownMenu.Item
                route={contentTypeRoute.edit({ public_id: publicId })}
                title="Edit"
                icon="pencil"
              />
            )}
            {isTrack && toggleScheduledTracks && (
              <div data-testid="ScheduleMenuItem">
                <DropDownMenu.Item
                  onClick={() => setShowScheduleModal(true)}
                  title="Schedule"
                  icon="add"
                />
              </div>
            )}
            {isTrack && (
              <DropDownMenu.Item
                route={`${mapRoute('trackCreate')}?cloned=${publicId}&origin=${currentRoute}`}
                title="Duplicate"
                icon="clone"
              />
            )}
            {canManageAssignment && (
              <>
                <DropDownMenu.Item
                  route={mapRoute('contentItemManageAssignments', {
                    public_id_and_slug: content.public_id_and_slug,
                  })}
                  title="View Roster"
                  icon="group"
                />
                <DropDownMenu.Item
                  onClick={() => setShowCompletionStatsModal(true)}
                  title="View Insights"
                  icon="checkbox-round"
                />
              </>
            )}
            {!isAssessment && (
              <DropDownMenu.Item
                onClick={() => setShowSurveyModal(true)}
                title="Manage Surveys"
                icon="survey"
              />
            )}
            <DropDownMenu.Separator />
            {!isArchived && (
              <DropDownMenu.Item
                onClick={() => setShowArchiveUnarchiveModal(true)}
                title={isScheduleTrack ? 'Cancel...' : 'Archive...'}
                icon="archive"
              />
            )}
            {isArchived && (
              <>
                <DropDownMenu.Item
                  onClick={() => setShowArchiveUnarchiveModal(true)}
                  title="Restore..."
                  icon="unarchive"
                />
                <DropDownMenu.Item
                  onClick={() => setShowDeleteModal(true)}
                  title="Delete..."
                  icon="delete"
                  color={colors.error600}
                  textColor={colors.error600}
                />
              </>
            )}
            {canManageAssignment && (
              <DropDownMenu.Item
                onClick={() => setShowLogModal(true)}
                title="View Logs"
                icon="log"
              />
            )}
          </>
        </DropDownMenu>
      )}

      {/* Modals */}
      {showArchiveUnarchiveModal && (
        <TrackArchiveUnarchiveConfirmationModal
          track={content}
          handleClose={({ shouldRefreshContent }) => {
            handleCloseArchiveUnarchiveModal(shouldRefreshContent);
          }}
        />
      )}

      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}

      {showSurveyModal && (
        <ContentSurveyModal content={content} handleClose={() => setShowSurveyModal(false)} />
      )}

      {showCompletionStatsModal && (
        <TrackItemCompletionStatsModal
          track={content}
          handleClose={() => setShowCompletionStatsModal(false)}
        />
      )}

      {showScheduleModal && (
        <ScheduleTrackModal track={content} handleClose={() => setShowScheduleModal(false)} />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </>
  );
};

export default TrackContextMenu;
