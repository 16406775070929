import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { withResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';

import SessionsGroup from '~/app/program/components/SessionsGroup';
import MediaPoint from '~/app/shared/components/MediaPoint';
import PageArrow from '~/app/shared/components/PageArrow';
import { ArrowContainer } from '~/app/shared/components/PageArrow/PageArrow';
import { API_DATE_FORMAT, MONTH_DAY_DISPLAY_FORMAT } from '~/app/shared/constants';
import { map, groupBy, keys, get, values, sortBy, slice, size, isEqual } from 'lodash-es';

import { getGroupsPerPage, SCROLL_BAR_WIDTH, SESSION_GROUP_WIDTH } from './utils';

const SessionsPagesContainer = styled.div`
  ${MediaPoint.Tablet} {
    margin: 0 -24px;

    ${ArrowContainer} {
      &:first-child {
        margin-left: 5px;
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
        margin-right: 5px;
      }
    }
  }
`;

const SessionListContent = styled.div`
  width: calc(100% - 40px);
  display: inline-block;

  ${MediaPoint.Tablet} {
    width: calc(100% - 48px);
    margin: 0 -12px;
  }
`;

export const SessionsGroupContainer = styled.div`
  display: inline-block;
  width: 100%;

  ${MediaPoint.Tablet} {
    width: ${SESSION_GROUP_WIDTH}px;
  }
`;

export class SessionsPages extends React.Component {
  constructor(props) {
    super(props);
    this.groupsCount = 1;
    this.groupsPerPage = 1;
    this.state = {
      startIndex: 0,
      endIndex: this.groupsPerPage,
      furthestFetchedIndex: 0,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { width: prevWidth } = prevProps;
    const { width } = this.props;
    const widthDiff = width - (prevWidth || 0);
    const widthChanged = widthDiff < -SCROLL_BAR_WIDTH || widthDiff > SCROLL_BAR_WIDTH;

    if (widthChanged) {
      const newGroupsPerPage = getGroupsPerPage(width);
      if (!isEqual(newGroupsPerPage, this.groupsPerPage)) {
        this.groupsPerPage = newGroupsPerPage;
        this.resetPages();
      }
    }
  };

  getGroupedSessions = () => {
    const { sessions } = this.props;
    const mappedSessions = map(sessions, (session) => {
      const startsAt = moment(session.starts_at_tz_aware);
      return { ...session, sessionDate: startsAt.format(API_DATE_FORMAT) };
    });

    const groups = groupBy(mappedSessions, 'sessionDate');
    this.groupsCount = size(groups);

    return groups;
  };

  getPageKeys = (groupedSessions) => {
    const { startIndex, endIndex } = this.state;
    const sortedGroupKeys = sortBy(keys(groupedSessions));
    return slice(sortedGroupKeys, startIndex, endIndex);
  };

  resetPages = () => {
    this.setState({
      startIndex: 0,
      endIndex: this.groupsPerPage,
    });
  };

  handlePageChange = (groupsCount, direction) => {
    const { fetchMoreSessions } = this.props;
    const { startIndex, endIndex, furthestFetchedIndex } = this.state;

    const step = direction === 'forward' ? this.groupsPerPage : -this.groupsPerPage;

    if (startIndex + step < 0 || startIndex + step >= groupsCount) {
      return;
    }

    const nextStart = startIndex + step;
    const nextEnd = endIndex + step;

    if (furthestFetchedIndex < nextEnd) {
      fetchMoreSessions(nextEnd);
      this.setState({
        furthestFetchedIndex: nextEnd,
      });
    }

    this.setState({
      startIndex: nextStart,
      endIndex: nextEnd,
    });
  };

  previousPageClick = () => {
    this.handlePageChange(this.groupsCount, 'back');
  };

  nextPageClick = () => {
    this.handlePageChange(this.groupsCount, 'forward');
  };

  calculateContentWidth = () => {
    const width = this.groupsPerPage * SESSION_GROUP_WIDTH + 120;
    return width;
  };

  render = () => {
    const { onEnrollmentDelete } = this.props;
    const { startIndex } = this.state;

    const groupedSessions = this.getGroupedSessions();

    const pageKeys = this.getPageKeys(groupedSessions);
    const hasNextPage = startIndex + this.groupsPerPage < this.groupsCount;
    const hasPreviousPage = startIndex - this.groupsPerPage >= 0;

    return (
      <SessionsPagesContainer width={this.calculateContentWidth()}>
        <PageArrow
          handleOnClick={this.previousPageClick}
          enabled={hasPreviousPage}
          orientation="left"
          showsOnOfficeHour
        />
        <SessionListContent>
          {map(pageKeys, (dateStr) => {
            const groupDate = moment(dateStr, API_DATE_FORMAT);
            const groupSessions = get(groupedSessions, dateStr);
            return (
              <SessionsGroupContainer key={dateStr}>
                <SessionsGroup
                  title={groupDate.format(MONTH_DAY_DISPLAY_FORMAT)}
                  sessions={values(groupSessions)}
                  onEnrollmentDelete={onEnrollmentDelete}
                />
              </SessionsGroupContainer>
            );
          })}
        </SessionListContent>
        <PageArrow
          handleOnClick={this.nextPageClick}
          enabled={hasNextPage}
          orientation="right"
          showsOnOfficeHour
        />
      </SessionsPagesContainer>
    );
  };
}

SessionsPages.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.object),
  fetchMoreSessions: PropTypes.func,
  onEnrollmentDelete: PropTypes.func,

  width: PropTypes.number,
};

export default withResizeDetector(SessionsPages);
