import React from 'react';

import { Card, Stack } from '@mui/material';
import { TrackItemCardStyle } from './hooks';

interface NestedTrackItemCardWrapperProps {
  cardStyle: TrackItemCardStyle;
  children?: React.ReactNode;
}

const NestedTrackItemCardWrapper = ({ cardStyle, children }: NestedTrackItemCardWrapperProps) => {
  return (
    <Stack spacing={0} sx={{ width: '100%' }}>
      {children}
      <Card
        variant="outlined"
        sx={{
          ...cardStyle,
          height: '8px',
          mt: '-4px',
          width: 'calc(100% - 4px)',
          mx: 'auto',
          zIndex: 1,
        }}
      />
      <Card
        variant="outlined"
        sx={{
          ...cardStyle,
          height: '8px',
          mt: '-4px',
          width: 'calc(100% - 8px)',
          mx: 'auto',
          zIndex: 0,
        }}
      />
    </Stack>
  );
};

export default NestedTrackItemCardWrapper;
