import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import NewInternalSurveyBox from '~/app/surveys/components/NewSurvey/NewInternalSurveyBox';
import * as constants from '~/app/surveys/constants';

const AttachSurveyButtonWrapper = styled.div`
  margin-top: 16px;
`;

const NewInternalSurveyField = ({ formName, fields, assignmentsCount, isEditing }) => {
  const [lastOpenedBox, setLastOpenedBox] = useState(fields.length - 1);

  const handleAttachSurvey = () => {
    fields.push({
      survey_id: null,
      survey_cutoff_period_value: 1,
      survey_cutoff_period_option: constants.PERIOD_OPTION.hours,
      survey_schedule_period_value: 1,
      survey_schedule_period_option: constants.PERIOD_OPTION.hours,
      survey_schedule_kind: 'after_matter_starts',
    });

    setLastOpenedBox(lastOpenedBox < 0 ? fields.length : lastOpenedBox + 1);
  };

  const handleOnRemoveBox = (index) => {
    setLastOpenedBox(-1);
    fields.remove(index);
  };

  return (
    <>
      {/* Lodash's functions doesn't work with redux-form's ArrayField */}
      {/* eslint-disable-next-line lodash/prefer-lodash-method */}
      {fields.map((name, index) => (
        <NewInternalSurveyBox
          key={name}
          formName={formName}
          name={name}
          index={index}
          handleOnRemove={() => handleOnRemoveBox(index)}
          fields={fields}
          lastOpenedBox={lastOpenedBox}
          setLastOpenedBox={setLastOpenedBox}
          assignmentsCount={assignmentsCount}
          isEditing={isEditing}
        />
      ))}
      <AttachSurveyButtonWrapper>
        <Button
          size="small"
          type="button"
          startIcon={<Icon name="plus" />}
          onClick={handleAttachSurvey}
          disabled={fields.length === 3}
        >
          {fields.length > 0 ? 'Attach another Survey' : 'Attach Survey'}
        </Button>
      </AttachSurveyButtonWrapper>
    </>
  );
};

NewInternalSurveyField.propTypes = {
  formName: PropTypes.string,
  fields: PropTypes.object,
  assignmentsCount: PropTypes.number,
  isEditing: PropTypes.bool,
};

export default NewInternalSurveyField;
