import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { LEARNING_TYPES } from '~/app/catalog/constants';
import { useLearningTypeLabels } from '~/app/catalog/hooks';
import colors from '~/services/colors';
import Icon from '~/app/shared/components/Icon';
import Pill from '~/app/shared/components/Pill/Pill';
import Text from '~/app/shared/components/Text';
import { getColorByType } from '~/app/shared/services';
import { values } from 'lodash-es';

import NewSvg from './new.svg';

const HeaderContainer = styled(Link)`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

const Container = styled.div`
  position: relative;
  width: 312px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Cover = styled.div`
  position: relative;
  height: 176px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 4px 0 0;

  ${({ cover }) =>
    `
    ${cover && `background-image: url(${cover})`};
    background-size: cover;`}

  ${({ type }) =>
    `
    background-color: ${getColorByType(type, 600)};`}

  ${({ isFade }) =>
    isFade &&
    `
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: white;
      opacity: 0.32;
    }
  `}
`;

const Tags = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Badge = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

const PillWrapper = styled.div`
  margin-top: 12px;
  margin-left: 16px;
`;

const LearningType = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  width: 312px;
  padding: 0 16px;

  ${({ type }) =>
    `
    background-color: ${getColorByType(type, 200)};`}
`;

const TypeInfo = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  svg {
    margin-right: 8px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: ${colors.neutral900};

  ${({ disabled }) =>
    disabled &&
    `
    color: ${colors.neutral600};`}
`;

const CardHeader = ({
  headerContent,
  cover,
  externalContentLabel,
  finishedTypeLabel,
  icon,
  isHeaderContentDisabled,
  isFinished,
  isNew,
  isUnpublished,
  route,
  type,
  disableAllClicks,
}) => {
  const learningTypeLabels = useLearningTypeLabels();

  const LinkComponent = !disableAllClicks ? HeaderContainer : React.Fragment;

  return (
    <LinkComponent {...(!disableAllClicks && { to: route })}>
      <Container>
        <Tags>
          {isFinished && (
            <PillWrapper>
              <Pill label={finishedTypeLabel} icon="check" variant="success600" />
            </PillWrapper>
          )}
          {!isFinished && externalContentLabel !== '' && (
            <PillWrapper>
              <Pill label={externalContentLabel} variant="darkGray" />
            </PillWrapper>
          )}
          {isUnpublished && (
            <PillWrapper>
              <Pill label="Unpublished" icon="hide" variant="lightGray" />
            </PillWrapper>
          )}
        </Tags>
        {isNew && (
          <Badge>
            <NewSvg />
          </Badge>
        )}
        <Cover type={type} cover={cover} isFade={isFinished} isNew={isNew}>
          {!cover && <Icon name={icon} width={48} height={48} color={getColorByType(type, 700)} />}
        </Cover>
        <LearningType type={type}>
          <TypeInfo type={type}>
            <Icon name={icon} width={16} height={16} color={getColorByType(type, 700)} />
            <Text color={getColorByType(type, 700)} medium ellipsisOnOverflow size="h5">
              {learningTypeLabels[type]}
            </Text>
          </TypeInfo>
          {headerContent && (
            <HeaderContent disabled={isHeaderContentDisabled}>{headerContent}</HeaderContent>
          )}
        </LearningType>
      </Container>
    </LinkComponent>
  );
};

CardHeader.propTypes = {
  headerContent: PropTypes.object,
  cover: PropTypes.string,
  externalContentLabel: PropTypes.string,
  finishedTypeLabel: PropTypes.oneOf(['Completed', 'Attended']),
  icon: PropTypes.string,
  isHeaderContentDisabled: PropTypes.bool,
  isFinished: PropTypes.bool,
  isNew: PropTypes.bool,
  isUnpublished: PropTypes.bool,
  route: PropTypes.string,
  type: PropTypes.oneOf(values(LEARNING_TYPES)),
  disableAllClicks: PropTypes.bool,
};

CardHeader.defaultProps = {
  externalContentLabel: '',
};

export default CardHeader;
