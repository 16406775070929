import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import {
  CONVERT_EVENT_LOCAL_TO_ONLINE,
  CONVERT_EVENT_MERGE_TO_LOCAL,
  CONVERT_EVENT_MERGE_TO_ONLINE,
  CONVERT_EVENT_ONLINE_TO_LOCAL,
} from '~/app/event-shared/constants';
import CheckboxField from '~/app/inputs/components/CheckboxField';
import InputLabel from '~/app/inputs/components/InputLabel';
import OldFieldError from '~/app/inputs/components/OldFieldError';
import colors from '~/services/colors';
import { INTERCOM_DATA_TARGETS, withProductTour } from '~/services/product-tour';
import Button from '~/app/shared/components/Button';
import ButtonLink from '~/app/shared/components/ButtonLink';
import { FormFieldGroup } from '~/app/shared/components/Form';
import HR from '~/app/shared/components/HR';
import InfoText from '~/app/shared/components/InfoText';
import EnrollmentCapManager from '~/app/shared/components/NewContentForm/EnrollmentCapManager';
import { useToggles } from '~/app/shared/hooks';

const EnrollmentOptionsWrapper = styled.div`
  display: flex;
  margin: 0 -10px;
  > * {
    flex: 1;
  }
`;

const ConvertButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  :not(:last-child) {
    margin-top: 8px;
  }
`;

const EnrollmentOption = styled.div`
  padding: 0 10px;
`;

const TourFieldWrapper = withProductTour(EnrollmentOption);

const EnrollmentOptionsField = ({ meta: { touched, error } }) => {
  if (!error) {
    return null;
  }
  return <div>{touched && error && <OldFieldError>{error}</OldFieldError>}</div>;
};

EnrollmentOptionsField.propTypes = {
  meta: PropTypes.object,
};

const ConvertSwitchButton = ({
  label,
  changeConvertEvent,
  convertEvent,
  disabled,
  convertInto,
  convertUndo,
  shouldRenderSwitchButton,
}) => (
  <ConvertButtonContainer>
    {!convertEvent && shouldRenderSwitchButton && (
      <Button
        size="small"
        onClick={() => changeConvertEvent(convertInto)}
        color="secondary"
        fullWidth
        disabled={disabled}
      >
        Switch to {label}
      </Button>
    )}
    {convertEvent === convertUndo && (
      <ButtonLink onClick={() => changeConvertEvent(null)} variant="error">
        Undo
      </ButtonLink>
    )}
  </ConvertButtonContainer>
);

ConvertSwitchButton.propTypes = {
  label: PropTypes.string,
  changeConvertEvent: PropTypes.func,
  convertEvent: PropTypes.string,
  convertInto: PropTypes.oneOf([CONVERT_EVENT_ONLINE_TO_LOCAL, CONVERT_EVENT_LOCAL_TO_ONLINE]),
  convertUndo: PropTypes.oneOf([CONVERT_EVENT_ONLINE_TO_LOCAL, CONVERT_EVENT_LOCAL_TO_ONLINE]),
  disabled: PropTypes.bool,
  shouldRenderSwitchButton: PropTypes.bool,
};

const ConvertMergeButton = ({
  label,
  changeConvertEvent,
  convertEvent,
  disabled,
  convertInto,
  convertUndo,
  shouldRenderMergeButton,
}) => (
  <ConvertButtonContainer>
    {!convertEvent && shouldRenderMergeButton && (
      <Button
        size="small"
        onClick={() => changeConvertEvent(convertInto)}
        color="secondary"
        fullWidth
        disabled={disabled}
      >
        Merge to {label} only
      </Button>
    )}
    {convertEvent === convertUndo && (
      <ButtonLink onClick={() => changeConvertEvent(null)} variant="error">
        Undo
      </ButtonLink>
    )}
  </ConvertButtonContainer>
);

ConvertMergeButton.propTypes = {
  label: PropTypes.string,
  changeConvertEvent: PropTypes.func,
  convertEvent: PropTypes.string,
  convertInto: PropTypes.oneOf([CONVERT_EVENT_MERGE_TO_LOCAL, CONVERT_EVENT_MERGE_TO_ONLINE]),
  convertUndo: PropTypes.oneOf([CONVERT_EVENT_MERGE_TO_LOCAL, CONVERT_EVENT_MERGE_TO_ONLINE]),
  disabled: PropTypes.bool,
  shouldRenderMergeButton: PropTypes.bool,
};

const InPersonCapManager = ({
  toggleConvertEvents,
  changeConvertEvent,
  initialValues,
  fieldValues,
  fieldValues: { convert_event: convertEvent },
  isEditing,
  isEventType,
  // eslint-disable-next-line no-unused-vars
  switchDemotesAttendees,
  showWaitlist,
  ...props
}) => (
  <EnrollmentCapManager
    label="In Person"
    enrollmentLimitFieldName="enrollment_limit"
    waitlistLimitFieldName="wait_list_limit"
    checkboxDisabled={
      !isEventType &&
      (!!convertEvent || (toggleConvertEvents && isEditing && initialValues.is_local))
    }
    showWaitlist={showWaitlist}
    waitlistDisabled={convertEvent === CONVERT_EVENT_ONLINE_TO_LOCAL}
    renderConvertEvent={() =>
      !isEventType &&
      isEditing &&
      toggleConvertEvents && (
        <>
          <ConvertSwitchButton
            label="Online"
            convertEvent={convertEvent}
            changeConvertEvent={changeConvertEvent}
            disabled={fieldValues.is_online}
            convertInto={CONVERT_EVENT_LOCAL_TO_ONLINE}
            convertUndo={CONVERT_EVENT_ONLINE_TO_LOCAL}
            shouldRenderSwitchButton={initialValues.is_local && !initialValues.is_online}
          />
          <ConvertMergeButton
            label="Online"
            convertEvent={convertEvent}
            changeConvertEvent={changeConvertEvent}
            convertInto={CONVERT_EVENT_MERGE_TO_ONLINE}
            convertUndo={CONVERT_EVENT_MERGE_TO_LOCAL}
            shouldRenderMergeButton={initialValues.is_local && initialValues.is_online}
          />
        </>
      )
    }
    {...props}
  />
);

InPersonCapManager.propTypes = {
  label: PropTypes.string,
  toggleConvertEvents: PropTypes.bool,
  changeConvertEvent: PropTypes.func,
  initialValues: PropTypes.object,
  fieldValues: PropTypes.object,
  isEditing: PropTypes.bool,
  isEventType: PropTypes.bool,
  switchDemotesAttendees: PropTypes.bool,
  showWaitlist: PropTypes.bool,
};

const OnlineCapManager = ({
  toggleConvertEvents,
  changeConvertEvent,
  initialValues,
  fieldValues,
  fieldValues: { convert_event: convertEvent },
  isEditing,
  isEventType,
  // eslint-disable-next-line no-unused-vars
  switchDemotesAttendees,
  showWaitlist,
  ...props
}) => {
  return (
    <EnrollmentCapManager
      label="Online"
      enrollmentLimitFieldName="online_enrollment_limit"
      waitlistLimitFieldName="online_wait_list_limit"
      checkboxDisabled={
        !isEventType &&
        (!!convertEvent || (toggleConvertEvents && isEditing && initialValues.is_online))
      }
      enrollmentDisabled={false}
      enrollmentExtraProps={{ tourId: INTERCOM_DATA_TARGETS.eventFormOnlineEnrollmentCap }}
      waitlistDisabled={convertEvent === CONVERT_EVENT_LOCAL_TO_ONLINE}
      waitlistExtraProps={{ tourId: INTERCOM_DATA_TARGETS.eventFormOnlineWaitlistCap }}
      showWaitlist={showWaitlist}
      renderConvertEvent={() =>
        !isEventType &&
        isEditing &&
        toggleConvertEvents && (
          <>
            <ConvertSwitchButton
              label="In-Person"
              convertEvent={convertEvent}
              changeConvertEvent={changeConvertEvent}
              disabled={fieldValues.is_local}
              convertInto={CONVERT_EVENT_ONLINE_TO_LOCAL}
              convertUndo={CONVERT_EVENT_LOCAL_TO_ONLINE}
              shouldRenderSwitchButton={!initialValues.is_local && initialValues.is_online}
            />
            <ConvertMergeButton
              label="In-Person"
              convertEvent={convertEvent}
              changeConvertEvent={changeConvertEvent}
              convertInto={CONVERT_EVENT_MERGE_TO_LOCAL}
              convertUndo={CONVERT_EVENT_MERGE_TO_ONLINE}
              shouldRenderMergeButton={initialValues.is_local && initialValues.is_online}
            />
          </>
        )
      }
      {...props}
    />
  );
};

OnlineCapManager.propTypes = {
  toggleConvertEvents: PropTypes.bool,
  changeConvertEvent: PropTypes.func,
  initialValues: PropTypes.object,
  fieldValues: PropTypes.object,
  isEditing: PropTypes.bool,
  isEventType: PropTypes.bool,
  switchDemotesAttendees: PropTypes.bool,
  showWaitlist: PropTypes.bool,
};

// Section component
const EnrollmentCapSection = ({
  attendanceMethodsName,
  isOnlineName,
  isLocalName,
  isEditing,
  fieldValues,
  initialValues,
  isEventType,
  change,
  onlineWrapperTourId,
  onlineCheckboxTourId,
}) => {
  const [hasEnrollmentWaitlist, toggleEnrollmentWaitlist] = useState(true);
  const [switchWaitlistBaseline, setSwitchWaitlistBaseline] = useState(0);
  const [switchDemotesAttendees, setSwitchDemotesAttendees] = useState(false);

  const { toggle_convert_events: toggleConvertEvents } = useToggles();

  const handleConvertEventChange = (newValue) => {
    setSwitchDemotesAttendees(false);
    switch (newValue) {
      case CONVERT_EVENT_LOCAL_TO_ONLINE:
      case CONVERT_EVENT_MERGE_TO_ONLINE: {
        change('is_local', false);
        change('is_online', true);
        change('online_enrollment_limit', fieldValues.enrollment_limit);
        change('online_wait_list_limit', fieldValues.wait_list_limit);
        setSwitchWaitlistBaseline(fieldValues.wait_list_limit);
        break;
      }
      case CONVERT_EVENT_ONLINE_TO_LOCAL:
      case CONVERT_EVENT_MERGE_TO_LOCAL: {
        change('is_local', true);
        change('is_online', false);
        change('enrollment_limit', fieldValues.online_enrollment_limit);
        change('wait_list_limit', fieldValues.online_wait_list_limit);
        setSwitchWaitlistBaseline(fieldValues.online_wait_list_limit);
        break;
      }
      case null: {
        // Reset
        change('is_local', initialValues.is_local);
        change('is_online', initialValues.is_online);
        switch (fieldValues.convert_event) {
          case CONVERT_EVENT_LOCAL_TO_ONLINE: {
            change('online_enrollment_limit', 0);
            change('online_wait_list_limit', 0);

            break;
          }
          case CONVERT_EVENT_MERGE_TO_ONLINE: {
            change('online_enrollment_limit', initialValues.online_enrollment_limit);
            change('online_wait_list_limit', initialValues.online_wait_list_limit);

            break;
          }
          case CONVERT_EVENT_ONLINE_TO_LOCAL: {
            change('enrollment_limit', 0);
            change('wait_list_limit', 0);

            break;
          }
          case CONVERT_EVENT_MERGE_TO_LOCAL: {
            change('enrollment_limit', initialValues.enrollment_limit);
            change('wait_list_limit', initialValues.wait_list_limit);

            break;
          }
          // No default
        }
        break;
      }
      default:
    }
    change('convert_event', newValue);
  };

  /* Changes to In-person enrollment cap if the switch event is requested */
  useEffect(() => {
    if (fieldValues.convert_event === CONVERT_EVENT_ONLINE_TO_LOCAL) {
      const {
        going_online_enrollments_count: currentEnrolled,
        waitlist_going_online_enrollments_count: currentWaitlist,
        enrollment_limit: formCap,
      } = fieldValues;

      if (formCap === 0) {
        setSwitchDemotesAttendees(false);
        change('wait_list_limit', 0);
        return;
      }

      const minimumThreshold = Math.max(currentWaitlist + currentEnrolled - formCap, 0);
      const newWaitlistLimit = Math.max(switchWaitlistBaseline, minimumThreshold);

      setSwitchDemotesAttendees(formCap < currentEnrolled);
      change('wait_list_limit', newWaitlistLimit);
    }
  }, [fieldValues.enrollment_limit, fieldValues.convert_event]);

  /* Changes to Online enrollment cap if the switch event is requested */
  useEffect(() => {
    if (fieldValues.convert_event === CONVERT_EVENT_LOCAL_TO_ONLINE) {
      const {
        going_local_enrollments_count: currentEnrolled,
        waitlist_local_enrollments_count: currentWaitlist,
        online_enrollment_limit: formCap,
      } = fieldValues;

      if (formCap === 0) {
        setSwitchDemotesAttendees(false);
        change('online_wait_list_limit', 0);
        return;
      }

      const minimumThreshold = Math.max(currentWaitlist + currentEnrolled - formCap, 0);
      const newWaitlistLimit = Math.max(switchWaitlistBaseline, minimumThreshold);

      setSwitchDemotesAttendees(formCap < currentEnrolled);
      change('online_wait_list_limit', newWaitlistLimit);
    }
  }, [fieldValues.online_enrollment_limit, fieldValues.convert_event]);

  /* Changes to In-person enrollment cap if the merge event is requested */
  useEffect(() => {
    if (fieldValues.convert_event === CONVERT_EVENT_MERGE_TO_LOCAL) {
      const {
        going_online_enrollments_count: currentEnrolledOnline,
        waitlist_going_online_enrollments_count: currentWaitlistOnline,
        going_local_enrollments_count: currentEnrolled,
        waitlist_local_enrollments_count: currentWaitlist,
        enrollment_limit: formCap,
      } = fieldValues;

      if (formCap === 0) {
        setSwitchDemotesAttendees(false);
        change('wait_list_limit', 0);
        return;
      }

      const minimumThreshold = Math.max(
        currentWaitlist + currentEnrolled + currentEnrolledOnline + currentWaitlistOnline - formCap,
        0
      );
      const newWaitlistLimit = Math.max(switchWaitlistBaseline, minimumThreshold);

      setSwitchDemotesAttendees(formCap < currentEnrolled);
      change('wait_list_limit', newWaitlistLimit);
    }
  }, [fieldValues.enrollment_limit, fieldValues.convert_event]);

  /* Changes to Online enrollment cap if the merge event is requested */
  useEffect(() => {
    if (fieldValues.convert_event === CONVERT_EVENT_MERGE_TO_ONLINE) {
      const {
        going_online_enrollments_count: currentEnrolledOnline,
        waitlist_going_online_enrollments_count: currentWaitlistOnline,
        going_local_enrollments_count: currentEnrolled,
        waitlist_local_enrollments_count: currentWaitlist,
        online_enrollment_limit: formCap,
      } = fieldValues;

      if (formCap === 0) {
        setSwitchDemotesAttendees(false);
        change('online_wait_list_limit', 0);
        return;
      }

      const minimumThreshold = Math.max(
        currentWaitlist + currentEnrolled + currentEnrolledOnline + currentWaitlistOnline - formCap,
        0
      );
      const newWaitlistLimit = Math.max(switchWaitlistBaseline, minimumThreshold);

      setSwitchDemotesAttendees(formCap < currentEnrolled);
      change('online_wait_list_limit', newWaitlistLimit);
    }
  }, [fieldValues.online_enrollment_limit, fieldValues.convert_event]);

  useEffect(() => {
    toggleEnrollmentWaitlist(fieldValues.has_wait_list);
  }, [fieldValues.has_wait_list]);

  return (
    <>
      <FormFieldGroup margin="0 0 20px">
        <div>
          <InputLabel required={!isEventType} htmlFor={attendanceMethodsName}>
            Enrollment Methods
          </InputLabel>
          <InfoText
            top={4}
            bottom={16}
            content={
              isEventType
                ? 'Select default allowed enrollment methods below:'
                : 'Select allowed enrollment methods below:'
            }
          />
        </div>
        <EnrollmentOptionsWrapper>
          <EnrollmentOption>
            <Field
              name={isLocalName}
              type="checkbox"
              toggleConvertEvents={toggleConvertEvents}
              changeConvertEvent={handleConvertEventChange}
              initialValues={initialValues}
              fieldValues={fieldValues}
              isEventType={isEventType}
              isEditing={isEditing}
              switchDemotesAttendees={switchDemotesAttendees}
              component={InPersonCapManager}
              showWaitlist={hasEnrollmentWaitlist}
            />
          </EnrollmentOption>

          <TourFieldWrapper tourId={onlineWrapperTourId}>
            <Field
              name={isOnlineName}
              type="checkbox"
              toggleOnlineEnrollmentLimit
              toggleConvertEvents={toggleConvertEvents}
              changeConvertEvent={handleConvertEventChange}
              initialValues={initialValues}
              fieldValues={fieldValues}
              isEventType={isEventType}
              isEditing={isEditing}
              switchDemotesAttendees={switchDemotesAttendees}
              component={OnlineCapManager}
              checkBoxProductTour={onlineCheckboxTourId}
              showWaitlist={hasEnrollmentWaitlist}
            />
          </TourFieldWrapper>
        </EnrollmentOptionsWrapper>
        <Field name={attendanceMethodsName} component={EnrollmentOptionsField} />
      </FormFieldGroup>
      <HR color={colors.neutral200} margin="0 -20px" />
      <FormFieldGroup margin="20px 0 0">
        <Field
          name="has_wait_list"
          label="Waitlist"
          infoText="If this is enabled, users can sign in for the waitlist when enrollment cap is filled."
          labelSize="h5"
          labelWeight="medium"
          component={CheckboxField}
        />
      </FormFieldGroup>
    </>
  );
};

EnrollmentCapSection.propTypes = {
  attendanceMethodsName: PropTypes.string,
  isOnlineName: PropTypes.string,
  isLocalName: PropTypes.string,
  isEditing: PropTypes.bool,
  fieldValues: PropTypes.object,
  initialValues: PropTypes.object,
  isEventType: PropTypes.bool,
  change: PropTypes.func,
  onlineWrapperTourId: PropTypes.string,
  onlineCheckboxTourId: PropTypes.string,
};

EnrollmentCapSection.defaultProps = {
  attendanceMethodsName: 'attendance_methods',
  isOnlineName: 'is_online',
  isLocalName: 'is_local',
};

export default EnrollmentCapSection;
