import { QueryFunction, useQuery } from '@tanstack/react-query';
import { get } from 'lodash-es';

import { ContentItem } from '~/app/shared-content-item/interfaces';
import { DEFAULT_STALE_TIME } from '~/common/api/constants';
import { queries } from '~/queries';

/*
  The assignment info is returned when fetching the content item.
  When completing track items, questions, or retaking assessments,
  the content item is still the same (ie, the same public_id) and so
  the cache will keep the same data.

  However, the assignment data will be different and so we need to refetch
  the content item with the assignment. To do this with react query, we need
  to manually append the assignment id to the query key, so that react query
  knows that the data has changed and will refetch the content item.
  */

export function useEnsureContentItemAssignmentQuery(contentItem?: ContentItem) {
  const publicId = get(contentItem, 'public_id');
  const initialAssignment = get(contentItem, 'user_assignment') ?? get(contentItem, 'assignment');

  const queryOpts = queries.content_items.details(publicId ?? '');

  return useQuery({
    queryKey: [...queryOpts.queryKey, initialAssignment?.id],
    queryFn: queryOpts.queryFn as QueryFunction<ContentItem>,
    enabled: !!publicId,
    staleTime: DEFAULT_STALE_TIME,
    select: (data) => {
      const assignment = data.user_assignment ?? data.assignment;

      return {
        ...data,
        user_assignment: assignment,
      };
    },
  });
}
