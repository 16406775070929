import Box from '@mui/material/Box';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { Variant } from '@mui/material/styles/createTypography';
import MUITypography from '@mui/material/Typography';

import { isNil, isNumber, omit } from 'lodash-es';

export function LinearProgressWithLabel(
  props: LinearProgressProps & { value?: number; labelVariant?: Variant }
) {
  const { value, labelVariant } = props;
  const hasNumberValue = !isNil(value) && isNumber(value);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          {...omit(props, ['labelVariant', 'value'])}
          variant="determinate"
          value={hasNumberValue ? value : 0}
        />
      </Box>
      <Box>
        <MUITypography variant={labelVariant || 'body2'} color="text.secondary">
          {hasNumberValue ? `${Math.round(value)}%` : '--%'}
        </MUITypography>
      </Box>
    </Box>
  );
}
