import { useHistory } from 'react-router-dom';

import { useContentTypeRoutes, useGetContentTypeLabel } from '~/app/catalog/hooks';
import { WidgetContentList, WidgetContent } from '~/common/components/widget';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { constant, get, isNil } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Box, Card, CardActionArea, CardMedia, Skeleton, Stack } from '@mui/material';
import { defaultBase64Image } from '../utils/contentItemDefaultImage';

interface ContentItemWidgetRowProps {
  contentItem: ContentItem;
}

export function ContentItemWidgetRow(props: ContentItemWidgetRowProps) {
  const { contentItem } = props;

  const history = useHistory();
  const contentTypeRoutes = useContentTypeRoutes({});
  const getLabel = useGetContentTypeLabel();

  const { name, content_type: contentType, public_id_and_slug: publicIdAndSlug } = contentItem;

  const getContentDetailURL = get(contentTypeRoutes, `${contentType}.details`, constant('')) as any;
  const coverUrl = get(contentItem, 'cover_url');
  const cardImageUrl = isNil(coverUrl) ? defaultBase64Image : coverUrl;

  return (
    <Card elevation={0}>
      <CardActionArea
        onClick={() => history.push(getContentDetailURL({ public_id_and_slug: publicIdAndSlug }))}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1.5 }}>
          <CardMedia
            component="img"
            sx={{ width: 72, height: 44, objectFit: 'cover', borderRadius: 1 }}
            image={cardImageUrl}
            alt={name}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Typography variant="body1" noWrap>
              {name}
            </Typography>
            <Typography variant="caption" noWrap>
              {getLabel(contentType)}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

type ContentItemWidgetEmptyRowProps = {
  animate?: boolean;
};

export function ContentItemWidgetEmptyRow(props: ContentItemWidgetEmptyRowProps) {
  const { animate = false } = props;

  return (
    <Card elevation={0}>
      <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1.5 }}>
        <Box maxWidth={72} flex={1}>
          {animate ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={44}
              sx={{ borderRadius: 1 }}
              animation={animate ? 'wave' : false}
            />
          ) : (
            <CardMedia
              component="img"
              sx={{ width: 72, height: 44, objectFit: 'cover', borderRadius: 1 }}
              image={defaultBase64Image}
              alt="Default content item image"
            />
          )}
        </Box>
        <Box display="flex" flexDirection="column" flex={2}>
          <Typography variant="body1" noWrap>
            <Skeleton variant="text" animation={animate ? 'wave' : false} sx={{ maxWidth: 235 }} />
          </Typography>
          <Typography variant="caption" noWrap>
            <Skeleton
              variant="text"
              height={12}
              animation={animate ? 'wave' : false}
              sx={{ maxWidth: 118 }}
            />
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

type ContentItemWidgetLoadingContentProps = {
  animate?: boolean;
};

export function ContentItemWidgetLoadingContent(props: ContentItemWidgetLoadingContentProps) {
  const { animate = true } = props;

  return (
    <>
      <ContentItemWidgetEmptyRow animate={animate} />
      <ContentItemWidgetEmptyRow animate={animate} />
      <ContentItemWidgetEmptyRow animate={animate} />
    </>
  );
}

type ContentItemWidgetEmptyContentProps = {
  label: string;
};

export function ContentItemWidgetEmptyContent(props: ContentItemWidgetEmptyContentProps) {
  const { label } = props;

  return (
    <WidgetContent paddingTop={0}>
      <Stack direction="column" rowGap={3}>
        <Typography variant="body2" noWrap color="rgba(0, 0, 0, 0.6)">
          {label}
        </Typography>
        <WidgetContentList>
          <ContentItemWidgetEmptyRow />
          <ContentItemWidgetEmptyRow />
        </WidgetContentList>
      </Stack>
    </WidgetContent>
  );
}
