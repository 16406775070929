import { useEffect, useState } from 'react';
import styled from 'styled-components';

import SuggestMentorshipModal from '~/app/shared-modals/components/SuggestMentorshipModal/SuggestMentorshipModal';
import Dashboard from '~/app/backoffice/components/Dashboard';
import DashboardTopBar from '~/app/backoffice/components/Dashboard/DashboardTopBar';
import RQLDashboard from '~/app/backoffice/components/Dashboard/RQLDashboard';
import { useRQLRouteFilters } from '~/app/backoffice/hooks';
import actions from '~/app/entities/actions';
import { userSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import {
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE,
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR,
} from '~/app/program/constants';
import colors from '~/services/colors';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import { ApiURLs } from '~/services/requests-base';
import ManagerPortalPage from '~/app/shared-layouts/ManagerPortalPage/ManagerPortalPage.tsx';
import AssignContentModal from '~/app/shared/components/AssignContentModal/AssignContentModal.tsx';
import { STATUS_DONE } from '~/app/shared/constants';
import { useCurrentUser, useEncodedCurrentRoute, useToggles } from '~/app/shared/hooks';
import { isEmpty, join, map } from 'lodash-es';

const PAGE_SIZE = 20;

const Content = styled.div`
  margin: 0 auto;
  padding: 20px 0 40px;

  > * + * {
    margin-top: 20px;
  }
`;

const MyTeam = () => {
  const { trackActivity } = useMetrics();
  const {
    toggle_mentor_preferences: toggleMentorPreferences,
    toggle_mentee_preferences: toggleMenteePreferences,
  } = useToggles();
  const currentUser = useCurrentUser();
  const currentRoute = useEncodedCurrentRoute();

  const [assignContentModal, setAssignContentModal] = useState({
    user: null,
    isVisible: false,
  });

  const [suggestMentorModal, setSuggestMentorModal] = useState({
    user: null,
    isVisible: false,
  });

  const [suggestMenteeModal, setSuggestMenteeModal] = useState({
    user: null,
    isVisible: false,
  });

  const [fetchFilters, { data: dynamicFilters }] = useEntities(
    actions.userData.retrieveFilters,
    null
  );

  useEffect(() => {
    fetchFilters();
  }, []);

  const { expression, filterObj, updateOrdering, updatePagination } = useRQLRouteFilters(
    'name',
    map(dynamicFilters, (item) => item.filter),
    PAGE_SIZE
  );

  const buildExportUrl = (rqlExpression) => {
    const baseUrl = ApiURLs['api_users:export_csv_rql_team']();
    const expressionWithViewMode = `${rqlExpression}&manager=${currentUser?.id}`;

    return `${baseUrl}?${expressionWithViewMode}`;
  };

  const [fetchUsers, { data: users, status: usersRequestStatus, count: usersCount }] = useEntities(
    actions.userData.retrieveListRql,
    (data) => {
      if (data.status === STATUS_DONE) {
        trackActivity(METRICS_ACTIVITIES.MY_TEM_VIEW, {});
      }
    },
    { schema: [userSchema] }
  );

  const refreshDashboard = () => {
    // The empty expression means that the effects have not been processed yet, because an expression must have at least the ordering, page number, and page size.
    if (isEmpty(expression)) return;

    const expressionWithViewMode = `${expression}&view_mode=myteam&manager=${currentUser?.id}`;

    fetchUsers(expressionWithViewMode);
  };

  // Every time the expression changes, we must fetch the data from the backend.
  useEffect(() => {
    refreshDashboard();
  }, [expression]);

  const showSuggestMentee = ({ has_mentor_profile, is_mentor }) =>
    is_mentor || (toggleMentorPreferences && has_mentor_profile);

  return (
    <ManagerPortalPage
      renderTopbar={
        <DashboardTopBar title="My Directs" subTitle="My Manager Portal">
          <Dashboard.ExportCsvButton
            dashboardName="My-Directs"
            buttonUrl={buildExportUrl(expression)}
          />
        </DashboardTopBar>
      }
    >
      <Content>
        <RQLDashboard
          dashboardName="My Directs"
          rows={users}
          requestStatus={usersRequestStatus}
          actionBar={<Dashboard.ActionsBar resourceName="Users" />}
          pageSize={PAGE_SIZE}
          totalCount={usersCount}
          filterObj={filterObj}
          updateOrdering={updateOrdering}
          updatePagination={updatePagination}
        >
          <Dashboard.Column size="15" header={<Dashboard.Header title="Name" orderingKey="name" />}>
            {({ row }) => <Dashboard.UserCell showAvatar data={row} />}
          </Dashboard.Column>
          <Dashboard.Column
            size="15"
            header={<Dashboard.Header title="Title" orderingKey="title" />}
          >
            {({ row }) => <Dashboard.TextCell data={row.title} />}
          </Dashboard.Column>
          <Dashboard.Column
            size="10"
            header={<Dashboard.Header title="Hire Date" orderingKey="working_since" />}
          >
            {({ row }) => row.working_since && <Dashboard.MonthDateCell date={row.working_since} />}
          </Dashboard.Column>
          <Dashboard.Column size="20" header={<Dashboard.Header title="Top Tags" />}>
            {({ row }) => <Dashboard.TextCell data={join(row.top_tags || [], ', ')} />}
          </Dashboard.Column>
          <Dashboard.Column size="15" header={<Dashboard.Header title="Content Consumption" />}>
            {({ row }) => (
              <Dashboard.HorizontalStackedBarCell
                data={[
                  {
                    label: 'Completed',
                    value: row.content_consumption?.completed_assignments || 0,
                    color: colors.action700,
                  },
                  {
                    label: 'Outstanding',
                    value: row.content_consumption?.outstanding_assignments || 0,
                    color: colors.emphasis200,
                  },
                  {
                    label: 'Overdue',
                    value: row.content_consumption?.overdue_assignment_count || 0,
                    color: colors.error600,
                  },
                ]}
              />
            )}
          </Dashboard.Column>
          <Dashboard.Column size="15" header={<Dashboard.Header title="People Impacted" />}>
            {({ row }) => row.unique_people_impacted_count || 0}
          </Dashboard.Column>
          <Dashboard.MenuColumn buttonAriaLabel="Context Menu">
            {({ row }) => (
              <div>
                <Dashboard.MenuColumnItem
                  title="View profile"
                  icon="user"
                  targetBlank={false}
                  route={`${mapRoute('userProfile', { id: `${row.id}` })}?origin=${currentRoute}`}
                />
                <Dashboard.MenuColumnItem
                  title="Assign content"
                  onClick={() => setAssignContentModal({ user: row, isVisible: true })}
                />
                <Dashboard.MenuColumnItem
                  title="Manage assignments"
                  icon="user"
                  targetBlank={false}
                  route={`${mapRoute('teamAssignments')}?eq(assignee,${row.id})`}
                />
                {toggleMenteePreferences && row.has_mentee_profile && (
                  <Dashboard.MenuColumnItem
                    title="Suggest a mentor"
                    onClick={() => setSuggestMentorModal({ user: row, isVisible: true })}
                  />
                )}
                {showSuggestMentee(row) && (
                  <Dashboard.MenuColumnItem
                    title="Suggest a mentee"
                    onClick={() => setSuggestMenteeModal({ user: row, isVisible: true })}
                  />
                )}
              </div>
            )}
          </Dashboard.MenuColumn>
        </RQLDashboard>
        {assignContentModal?.isVisible && (
          <AssignContentModal
            assignee={assignContentModal.user}
            handleClose={() => setAssignContentModal({ user: null, isVisible: false })}
          />
        )}
        {suggestMentorModal?.isVisible && (
          <SuggestMentorshipModal
            receiver={suggestMentorModal.user}
            suggestionKind={MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR}
            handleClose={() => setSuggestMentorModal({ user: null, isVisible: false })}
            onRequestSent={() => setSuggestMentorModal({ user: null, isVisible: false })}
            isManagerSuggestion
          />
        )}
        {suggestMenteeModal?.isVisible && (
          <SuggestMentorshipModal
            receiver={suggestMenteeModal.user}
            suggestionKind={MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE}
            handleClose={() => setSuggestMenteeModal({ user: null, isVisible: false })}
            onRequestSent={() => setSuggestMenteeModal({ user: null, isVisible: false })}
            isManagerSuggestion
          />
        )}
      </Content>
    </ManagerPortalPage>
  );
};

export default MyTeam;
