import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import MuiThemeWrapper from '~/scenes/MuiThemeWrapper';
import colors from '~/services/colors';
import Button from '~/app/shared/components/Button';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Clicker from '~/app/shared/components/Clicker';
import Icon from '~/app/shared/components/Icon';
import { isNil, isNumber } from 'lodash-es';
import { Box, Modal as MuiModal } from '@mui/material';
import { LoadingButton } from '~/vendor/mui-lab';

import { ButtonProps } from '../Button/Button';

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

export interface ModalThemeProps {
  variant?: 'buttons' | 'links' | 'noActions';
  justifyContent?: 'spaceBetween' | 'flexStart' | 'flexEnd' | 'center';
}

const StyledHR = styled.hr`
  border-top-color: ${colors.neutral200};
  width: 100%;
  margin: 0;
`;

const Header = styled.div`
  width: 100%;
  min-height: 45px;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.neutral900};
  position: relative;
  flex: 0 0 auto;
`;

const Title = styled.div`
  align-self: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.4px;
  width: 100%;
  text-align: center;
`;

const Body = styled.div<{
  $overflow?: string;
  $height?: string | number | null;
  $minHeight?: string | number;
  $maxHeight?: string | number;
}>`
  flex: 1 1 auto;
  // TODO overflow should be removed here, otherwise footer will be scrolled
  overflow: ${({ $overflow }) => $overflow || 'auto'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${({ $minHeight }) =>
    isNil($minHeight) ? '290px' : isNumber($minHeight) ? `${$minHeight}px` : $minHeight};
  max-height: ${({ $maxHeight }) =>
    isNil($maxHeight) ? '70vh' : isNumber($maxHeight) ? `${$maxHeight}px` : $maxHeight};
  height: ${({ $height }) =>
    isNil($height) ? null : isNumber($height) ? `${$height}vh` : $height};
`;

export interface ModalBodyProps {
  fullSize?: boolean;
  scrollable?: boolean;
  backgroundColor?: string;
}

export const ModalBody = styled.div<ModalBodyProps>`
  display: flex;
  flex: 1;
  flex-flow: column;
  padding: ${({ fullSize }) => (fullSize ? '0' : '20px')};

  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`};

  ${({ scrollable }) =>
    scrollable &&
    `
    overflow-y: scroll;
  `}
`;

const getPadding = ({ variant }: ModalThemeProps) => {
  if (variant === 'buttons') {
    return 'padding: 12px;';
  }
  if (variant === 'links') {
    return 'padding: 10px 20px;';
  }
  return 'padding: 0 0 24px;';
};

const getJustifyContent = ({ justifyContent }: ModalThemeProps) => {
  if (justifyContent === 'spaceBetween') {
    return 'justify-content: space-between;';
  }
  if (justifyContent === 'flexStart') {
    return 'justify-content: flex-start;';
  }
  if (justifyContent === 'flexEnd') {
    return 'justify-content: flex-end;';
  }
  return 'justify-content: center;';
};

const FooterContainer = styled.footer<ModalThemeProps>`
  border-top: 1px solid ${colors.neutral200};
  display: flex;
  align-items: center;
  gap: 12px;

  ${getPadding};
  ${getJustifyContent};
`;

export const ModalFooterButton = (props: LoadingButtonProps) => (
  <LoadingButton variant="outlined" size="small" {...props} />
);

export interface ModalFooterButtonLinkProps {
  icon: string;
}

export const ModalFooterButtonLink = ({
  children,
  icon,
  ...linkProps
}: React.PropsWithChildren<ModalFooterButtonLinkProps>) => {
  return (
    <ButtonLink icon={icon} size="medium" {...linkProps}>
      {icon && <Icon name={icon} height={12} width={12} color={colors.action600} />}
      {children}
    </ButtonLink>
  );
};
export const ModalFooter = ({
  children,
  variant,
  justifyContent,
}: React.PropsWithChildren<ModalThemeProps>) => (
  <FooterContainer variant={variant} justifyContent={justifyContent}>
    {children}
  </FooterContainer>
);

export interface ModalProps {
  title: string;
  children: React.ReactNode;
  handleClose?: (event?: object, reason?: string) => void;
  handleBack?: () => void;
  width?: number | string | null;
  maxWidth?: number | string | null;
  height?: number | string;
  minBodyHeight?: number | string;
  maxBodyHeight?: number | string;
  overflow?: string;
  zIndex?: string;
  sx?: object;
  disableEscapeKeyDown?: boolean;
  disableCloseOnBackdropClick?: boolean;
  footer?: () => React.ReactNode;
}

export const Modal = ({
  children,
  handleClose,
  handleBack,
  overflow,
  title,
  width,
  height,
  maxWidth,
  minBodyHeight,
  maxBodyHeight,
  zIndex,
  disableEscapeKeyDown,
  disableCloseOnBackdropClick = true,
  sx = {}, // The sx prop has priority over the other styling props.
  footer,
}: ModalProps) => {
  useEffect(() => {
    // Necessary because it is the recommended lib approach to handle tooltip inside modals
    ReactTooltip.rebuild();
  }, []);

  const onClose = (event: object, reason: string) => {
    if (reason === 'backdropClick' && disableCloseOnBackdropClick) return;

    handleClose?.(event, reason);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: width || 400,
    maxWidth: maxWidth || null,
    bgcolor: 'background.paper',
    borderRadius: '8px',
  };
  return (
    <MuiThemeWrapper>
      <MuiModal
        closeAfterTransition
        disableEscapeKeyDown={disableEscapeKeyDown}
        BackdropProps={{
          timeout: 0,
        }}
        id="modal-root"
        open
        onClose={onClose}
        sx={zIndex ? { zIndex } : {}} // support for 3rd-party dialogs that may need to come on top
      >
        <Box sx={{ ...style, ...sx }}>
          <Header>
            {handleBack && (
              <Button
                startIcon={<Icon name="left" />}
                variant="text"
                size="small"
                onClick={handleBack}
              >
                Back
              </Button>
            )}
            <Title>{title}</Title>
            {handleClose && (
              <Clicker onClick={handleClose}>
                <i className="mdi mdi-close mdi-18px" />
              </Clicker>
            )}
          </Header>
          <StyledHR />
          <Body
            $overflow={overflow}
            $height={height}
            $minHeight={minBodyHeight}
            $maxHeight={maxBodyHeight}
          >
            {children}
          </Body>
          {footer && footer?.()}
        </Box>
      </MuiModal>
    </MuiThemeWrapper>
  );
};

Modal.FooterButton = Button;

export default Modal;
