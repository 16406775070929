import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import { withProductTour } from '~/services/product-tour';
import Clicker from '~/app/shared/components/Clicker';
import Text from '~/app/shared/components/Text';
import { noop } from 'lodash-es';

const getAccordionCheckboxLineColor = ({ isOpen, disabled, hasError, isNewEventForm }) => {
  if (disabled && isOpen) {
    return colors.neutral500;
  }

  if (disabled && !isOpen) {
    return 'transparent';
  }

  if (hasError) {
    return colors.error600;
  }

  if (isOpen && isNewEventForm) {
    return colors.action600;
  }

  if (isOpen) {
    return colors.emphasis600;
  }

  return 'transparent';
};

const getAccordionCheckboxBGColor = ({ isOpen, disabled, hasError, isNewEventForm }) => {
  if (disabled && isOpen) {
    return colors.neutral500;
  }

  if (disabled && !isOpen) {
    return 'transparent';
  }

  if (isOpen && hasError) {
    return colors.error600;
  }

  if (isOpen && isNewEventForm) {
    return colors.action600;
  }

  if (isOpen) {
    return colors.emphasis600;
  }

  return 'transparent';
};

const getAccordionCheckboxFocusColor = ({ isOpen, disabled, hasError, isNewEventForm }) => {
  if (disabled && isOpen) {
    return colors.neutral500;
  }

  if (disabled && !isOpen) {
    return 'transparent';
  }

  if (hasError) {
    return colors.error600;
  }

  if (isNewEventForm) {
    return colors.action600;
  }

  return colors.emphasis600;
};

const getAccordionFontColor = ({ disabled, isOpen, isNewEventForm }) => {
  if (isOpen) {
    return colors.emphasis600TextColor;
  }

  if (isOpen && isNewEventForm) {
    return colors.action600TextColor;
  }

  if (disabled) {
    return colors.neutral400;
  }

  return colors.neutral900;
};

const AccordionCheckboxWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  ${({ hasNoMaxWidth }) => !hasNoMaxWidth && 'max-width: 262px;'}
  border-radius: 4px;
  border: 1px solid ${(props) => getAccordionCheckboxLineColor(props)};

  &:hover,
  &:focus,
  &:focus-within {
    border: 1px solid ${(props) => getAccordionCheckboxFocusColor(props)};
  }
`;

AccordionCheckboxWrapper.propTypes = {
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  isNewEventForm: PropTypes.bool,
};

const AccordionCheckboxHeader = styled(Clicker)`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 32px;
  padding: 6px 15px;
  background-color: ${(props) => getAccordionCheckboxBGColor(props)};
  color: ${(props) => getAccordionFontColor(props)};

  &:hover,
  &:focus {
    ${({ disabled }) => !disabled && 'opacity: 0.9;'}
  }
`;

AccordionCheckboxHeader.propTypes = {
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  isNewEventForm: PropTypes.bool,
};

const AccordionCheckboxContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  ${({ show }) => (show ? 'display: block;' : 'display: none;')}
`;

const getCheckboxColor = ({ checked, disabled, hasError, isNewEventForm }) => {
  if (hasError) {
    return colors.error600;
  }

  if (checked && disabled) {
    return colors.neutral500;
  }

  if (checked && isNewEventForm) {
    return colors.action600;
  }

  if (checked) {
    return colors.emphasis600;
  }

  return colors.neutral200;
};

const Checkbox = withProductTour(styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid ${(props) => getCheckboxColor(props)};
  justify-content: center;
  background-color: white;

  &::after {
    content: '';
    display: ${({ checked }) => (checked ? 'inline-block' : 'none')};
    position: relative;
    border: 2px solid ${(props) => getCheckboxColor(props)};
    border-top: none;
    border-right: none;
    height: 30%;
    width: 60%;
    margin-top: 25%;
    transform: rotate(-45deg);
  }
`);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  isNewEventForm: PropTypes.bool,
};

const AccordionCheckboxLabel = styled.label`
  padding: 0;
  margin: 0;
  margin-left: 6px;
  font-weight: normal;

  &:hover {
    cursor: inherit;
  }
`;

AccordionCheckboxLabel.propTypes = {
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
};

// FIXME: this should use a real input component, see SquareCheckboxInput.js
const AccordionCheckboxInput = ({
  label,
  value,
  disabled,
  hasError,
  hasNoMaxWidth,
  onClick,
  children,
  checkBoxProductTour,
  isNewEventForm,
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick(!value);
    }
  };

  return (
    <AccordionCheckboxWrapper
      disabled={disabled}
      hasNoMaxWidth={hasNoMaxWidth}
      isOpen={value}
      hasError={hasError}
      isNewEventForm={isNewEventForm}
    >
      <AccordionCheckboxHeader
        disabled={disabled}
        isOpen={value}
        hasError={hasError}
        onClick={handleClick}
        tabIndex={disabled ? '-1' : null}
        isNewEventForm={isNewEventForm}
      >
        <Checkbox
          checked={value}
          disabled={disabled}
          hasError={hasError}
          tourId={checkBoxProductTour}
          isNewEventForm={isNewEventForm}
        />
        <AccordionCheckboxLabel isOpen={value} disabled={disabled}>
          <Text size="h4">{label}</Text>
        </AccordionCheckboxLabel>
      </AccordionCheckboxHeader>
      <AccordionCheckboxContent show={value && children} isNewEventForm={isNewEventForm}>
        {' '}
        {children}{' '}
      </AccordionCheckboxContent>
    </AccordionCheckboxWrapper>
  );
};

AccordionCheckboxInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  checkBoxProductTour: PropTypes.string,
  isNewEventForm: PropTypes.bool,

  hasNoMaxWidth: PropTypes.bool,
};

AccordionCheckboxInput.defaultProps = {
  value: false,
  hasError: false,
  disabled: false,
  onClick: noop,
  isNewEventForm: false,

  hasNoMaxWidth: false,
};

export default AccordionCheckboxInput;
