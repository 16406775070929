import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';

const CharacterCountNumber = styled.div`
  min-width: 80px;
  margin: 2px 4px;
  color: ${colors.neutral600};
  text-align: right;
  font-size: 12px;
`;

const CharacterCount = ({ maxLength, textLength }) => (
  <CharacterCountNumber>{maxLength - textLength}</CharacterCountNumber>
);

CharacterCount.propTypes = {
  maxLength: PropTypes.number.isRequired,
  textLength: PropTypes.number.isRequired,
};

export default CharacterCount;
