import moment from 'moment';
import { useState } from 'react';

import { getSuggestedDueAt, getSuggestedExpiresAt } from '~/app/assignments/services';
import {
  ATTENDANCE_METHODS,
  ATTENDANCE_METHODS_LABELS,
  ENROLLMENT_POLICIES,
  ENROLLMENT_POLICIES_LABELS,
} from '~/app/enrollments/constants';
import { Event } from '~/app/event/interfaces';
import DatePickerInput from '~/app/inputs/components/DatePickerInput';
import colors from '~/services/colors';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import Modal, { ModalBody, ModalFooterButton } from '~/app/shared/components/Modal';
import { ActionCallbackProps, User } from '~/app/shared/components/types';
import {
  get,
  size,
  map,
  head,
  isEmpty,
  toUpper,
  slice,
  filter,
  isNil,
  includes,
  max,
} from 'lodash-es';
import { brown, green, red, teal } from '@mui/material/colors';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { Avatar } from '~/common/components/Avatar';
import { Typography } from '~/common/components/Typography';
import {
  AvatarGroup,
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  styled,
} from '@mui/material';
import { LoadingButton } from '~/vendor/mui-lab';

type InputSection = 'dates' | 'attendance_method' | 'enrollment_policy';

interface HandleActionProps {
  dueAt: moment.Moment | null;
  expiresAt: moment.Moment | null;
  attendanceMethod: ATTENDANCE_METHODS;
  enrollmentPolicy: ENROLLMENT_POLICIES;
  forceCheckin: boolean;
}

interface BulkErrorMessage {
  message: string;
  user_name: string;
}

interface BulkAssignmentFormModalProps {
  content: ContentItem | Event;
  title: string;
  actionLabel: string;
  sections?: Array<InputSection>;
  hideActions?: boolean;
  selectedData: ActionCallbackProps | null;
  handleAction: (props: HandleActionProps) => void;
  isLoading: boolean;
  errors?: BulkErrorMessage[];
  handleClose: () => void;
  handleBack: () => void;
}

const Footer = styled('footer')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: 8px;
  padding: 8px;
  height: 60px;
`;

const getUsersNames = (users: User[], total: number): string => {
  const names = filter(
    map(users, (user) => get(user, 'name')),
    (name) => !isEmpty(name)
  );
  if (size(names) === 0) return '';
  if (size(names) === 1) return names[0];
  if (size(names) === 2) return `${names[0]} and ${names[1]}`;
  if (size(names) === 3) return `${names[0]}, ${names[1]}, and ${names[2]}`;
  return `${names[0]}, ${names[1]}, ${names[2]} and ${total - 3} others`;
};

interface GetBaseAttendanceMethodChipProps {
  label: string;
  methodEnabled: boolean;
  isLimited: boolean;
  limit: number;
  going: number;
  isWaitlistEnabled: boolean;
  waitlistLimit: number;
  waitlist: number;
}

const getBaseAttendanceMethodChip = ({
  label,
  methodEnabled,
  isLimited,
  limit,
  going,
  isWaitlistEnabled,
  waitlistLimit,
  waitlist,
}: GetBaseAttendanceMethodChipProps) => {
  const remainingCapacity = max([limit - going, 0]) || 0;
  const chipLabel = isLimited ? `${remainingCapacity} of ${limit} spots left` : 'Unlimited spots';
  const hasSpot = !isLimited || remainingCapacity > 1;
  const hasWaitlistSpot = isWaitlistEnabled && waitlist < waitlistLimit;
  const chipColor = hasSpot ? green['200'] : red['200'];
  const waitlistChipColor = hasWaitlistSpot ? green['200'] : red['200'];
  const chipLabelColor = hasSpot ? teal['900'] : brown['900'];
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1, alignItems: 'center' }}>
      <Typography variant="body2">{label}</Typography>
      {methodEnabled && (
        <Chip
          label={chipLabel}
          sx={{ backgroundColor: chipColor, color: chipLabelColor, borderRadius: 8 }}
        />
      )}
      {isWaitlistEnabled && (
        <Chip
          label={`${waitlist} of ${waitlistLimit} waitlisted`}
          sx={{ backgroundColor: waitlistChipColor, color: chipLabelColor, borderRadius: 8 }}
        />
      )}
    </Box>
  );
};

const getOnlineAttendanceChip = (event: Event) => {
  const isOnline = get(event, 'is_online', false);
  const isLimited = get(event, 'is_online_limited', false);
  const limit = get(event, 'online_enrollment_limit', 0);
  const going = get(event, 'enrollment_counts_dict.going_online', 0);
  const isWaitlistEnabled = get(event, 'is_online_waitlist_enabled', false);
  const waitlistLimit = get(event, 'online_wait_list_limit', 0);
  const waitlist = get(event, 'enrollment_counts_dict.wait_list_online', 0);
  return getBaseAttendanceMethodChip({
    label: ATTENDANCE_METHODS_LABELS.online,
    methodEnabled: isOnline,
    isLimited,
    limit,
    going,
    isWaitlistEnabled,
    waitlist,
    waitlistLimit,
  });
};

const getLocalAttendanceChip = (event: Event) => {
  const isLocal = get(event, 'is_local', false);
  const isLimited = get(event, 'is_limited', false);
  const limit = get(event, 'enrollment_limit', 0);
  const going = get(event, 'enrollment_counts_dict.going', 0);
  const isWaitlistEnabled = get(event, 'is_waitlist_enabled', false);
  const waitlistLimit = get(event, 'wait_list_limit', 0);
  const waitlist = get(event, 'enrollment_counts_dict.wait_list', 0);
  return getBaseAttendanceMethodChip({
    label: ATTENDANCE_METHODS_LABELS.local,
    methodEnabled: isLocal,
    isLimited,
    limit,
    going,
    isWaitlistEnabled,
    waitlist,
    waitlistLimit,
  });
};

const ERROR_PAGE_SIZE = 5;

const BulkAssignmentFormModal = ({
  content,
  title,
  actionLabel,
  sections = [],
  hideActions = false,
  selectedData,
  handleAction,
  isLoading,
  errors,
  handleClose,
  handleBack,
}: BulkAssignmentFormModalProps) => {
  const [errorPageSize, setErrorPageSize] = useState(ERROR_PAGE_SIZE);
  const [dueAt, setDueAt] = useState<moment.Moment | null>(getSuggestedDueAt(content));
  const [expiresAt, setExpiresAt] = useState<moment.Moment | null>(getSuggestedExpiresAt(content));
  const [invalidDueAt, setInvalidDueAt] = useState<boolean>(false);
  const [invalidExpiresAt, setInvalidExpiresAt] = useState<boolean>(false);
  const [attendanceMethod, setAttendanceMethod] = useState<ATTENDANCE_METHODS | null>(null);
  const [enrollmentPolicy, setEnrollmentPolicy] = useState<ENROLLMENT_POLICIES | null>(null);
  const [forceCheckin, setForceCheckin] = useState<boolean>(false);
  if (isNil(selectedData)) return null;

  const defaultAttendanceMethod = get(content, 'is_online', false)
    ? ATTENDANCE_METHODS.online
    : ATTENDANCE_METHODS.local;
  const selectedItems = get(selectedData, 'selectedItems');
  const selectAll = get(selectedData, 'selectAll', false);
  // In the create context, the item will be the user itself, in the other contexts (update, drop, etc) the item will be the assignment.
  const [users, total] =
    size(selectedItems) > 0 && !selectAll
      ? [map(selectedItems, (item) => get(item, 'user', item)), size(selectedItems)]
      : [
          map(get(selectedData, 'rows'), (item) => get(item, 'user', item)),
          get(selectedData, 'rowCount', 0),
        ];
  const columns = [
    { field: 'user_name', headerName: 'User', flex: 2 },
    { field: 'message', headerName: 'Reason', flex: 1 },
  ];

  return (
    <Modal
      title={title}
      width={640}
      minBodyHeight={240}
      handleClose={handleClose}
      handleBack={handleBack}
    >
      <ModalBody>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            minHeight: '140px',
            rowGap: 5,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <AvatarGroup>
              {map(slice(users, 0, 3), (user, index) => (
                <Avatar
                  key={`${get(user, 'id')}-${index}`}
                  src={get(user, 'profile_image')}
                  alt={get(user, 'name')}
                  sx={{ width: 24, height: 24 }}
                >
                  {toUpper(head(get(user, 'name', '')))}
                </Avatar>
              ))}
            </AvatarGroup>
            <Typography variant="caption">{getUsersNames(users ?? [], total ?? 0)}</Typography>
          </Box>
          {errors && size(errors) > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 1,
                minHeight: '200px',
                width: '100%',
              }}
            >
              <Typography>Oops. We ran into some issues performing this action:</Typography>
              <DataGrid
                columns={columns}
                rows={errors}
                density="compact"
                pagination
                pageSize={errorPageSize}
                onPageSizeChange={setErrorPageSize}
                rowsPerPageOptions={[5, 10, 15]}
                autoHeight
                disableSelectionOnClick
              />
            </Box>
          )}
          {size(errors) === 0 && includes(sections, 'attendance_method') && (
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
              <FormControl>
                <FormLabel>Attendance method</FormLabel>
                <RadioGroup
                  aria-labelledby="attendance-method-group-label"
                  defaultValue={defaultAttendanceMethod}
                  name="attendance-method-group"
                  onChange={(_, value: ATTENDANCE_METHODS) => setAttendanceMethod(value)}
                >
                  <FormControlLabel
                    value={ATTENDANCE_METHODS.online}
                    control={<Radio size="small" />}
                    label={getOnlineAttendanceChip(content)}
                    disabled={isLoading || !get(content, 'is_online', false)}
                  />
                  <FormControlLabel
                    value={ATTENDANCE_METHODS.local}
                    control={<Radio size="small" />}
                    label={getLocalAttendanceChip(content)}
                    disabled={isLoading || !get(content, 'is_local', false)}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          )}
          {size(errors) === 0 && includes(sections, 'enrollment_policy') && (
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
              <FormControl>
                <FormLabel>Enrollment policy</FormLabel>
                <RadioGroup
                  aria-labelledby="enrollment-policy-group-label"
                  defaultValue={ENROLLMENT_POLICIES.try_enroll_then_try_waitlist}
                  name="enrollment-policy-group"
                  onChange={(_, value: ENROLLMENT_POLICIES) => setEnrollmentPolicy(value)}
                >
                  <FormControlLabel
                    value={ENROLLMENT_POLICIES.try_enroll_then_try_waitlist}
                    control={<Radio size="small" />}
                    label={ENROLLMENT_POLICIES_LABELS.try_enroll_then_try_waitlist}
                    disabled={isLoading}
                  />
                  <FormControlLabel
                    value={ENROLLMENT_POLICIES.force_enroll}
                    control={<Radio size="small" />}
                    label={ENROLLMENT_POLICIES_LABELS.force_enroll}
                    disabled={isLoading}
                  />
                  <FormControlLabel
                    value={ENROLLMENT_POLICIES.try_enroll_then_force_waitlist}
                    control={<Radio size="small" />}
                    label={ENROLLMENT_POLICIES_LABELS.try_enroll_then_force_waitlist}
                    disabled={isLoading}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Force check-in</FormLabel>
                <FormControlLabel
                  disabled={isLoading}
                  control={<Switch />}
                  onChange={(_, checked) => setForceCheckin(checked)}
                  label="If enrolled, also mark as checked in."
                />
              </FormControl>
            </Box>
          )}
          {size(errors) === 0 && includes(sections, 'dates') && (
            <Box sx={{ display: 'flex', columnGap: 1 }}>
              <Box sx={{ maxWidth: '160px' }}>
                <DatePickerInput
                  label="Due at"
                  value={dueAt}
                  onChange={(newValue: moment.Moment | null) => {
                    setInvalidDueAt(Boolean(newValue) && !newValue?.isValid());
                    setDueAt(newValue);
                  }}
                  disabled={isLoading}
                />
              </Box>
              <Box sx={{ maxWidth: '160px' }}>
                <DatePickerInput
                  label="Expires at"
                  value={expiresAt}
                  onChange={(newValue: moment.Moment | null) => {
                    setInvalidExpiresAt(Boolean(newValue) && !newValue?.isValid());
                    setExpiresAt(newValue);
                  }}
                  disabled={isLoading}
                />
              </Box>
            </Box>
          )}
        </Box>
      </ModalBody>
      <Divider />
      <Footer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <Typography variant="subtitle2">
            {isLoading && 'You can close this dialog now.'}
          </Typography>
          {!hideActions && (
            <Box>
              <ModalFooterButton
                variant="text"
                sx={{ color: colors.neutral600 }}
                disabled={isLoading}
                onClick={handleBack}
              >
                Cancel
              </ModalFooterButton>
              <LoadingButton
                variant="outlined"
                size="small"
                loading={isLoading}
                loadingPosition="end"
                disabled={isLoading || invalidDueAt || invalidExpiresAt || size(errors) > 0}
                onClick={() =>
                  handleAction({
                    dueAt,
                    expiresAt,
                    attendanceMethod: attendanceMethod || defaultAttendanceMethod,
                    enrollmentPolicy:
                      enrollmentPolicy || ENROLLMENT_POLICIES.try_enroll_then_try_waitlist,
                    forceCheckin,
                  })
                }
                sx={{ pr: isLoading ? 4 : 1 }}
              >
                <span>{isLoading ? 'Running' : actionLabel}</span>
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Footer>
    </Modal>
  );
};

export default BulkAssignmentFormModal;
