import { ViewPage } from '@atlaskit/embedded-confluence';
import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { PARENT_PRODUCT_CONFLUENCE } from '~/app/shared/constants';

const ConfluencePageWrapper = styled.div`
  height: ${({ showBiggerPage }) => (showBiggerPage ? '800px' : '300px')};
`;

const ConfluenceViewPage = ({ blockProps, showBiggerPage }) => {
  const { hostName, contentId, spaceKey } = blockProps;
  return (
    <ConfluencePageWrapper showBiggerPage={showBiggerPage}>
      <ViewPage
        contentId={contentId}
        locale="en-US"
        spaceKey={spaceKey}
        hostname={hostName}
        parentProduct={PARENT_PRODUCT_CONFLUENCE}
      />
    </ConfluencePageWrapper>
  );
};

ConfluenceViewPage.defaultProps = {
  showBiggerPage: false,
};

ConfluenceViewPage.propTypes = {
  blockProps: PropTypes.shape({
    hostName: PropTypes.string,
    contentId: PropTypes.string,
    spaceKey: PropTypes.string,
  }),
  showBiggerPage: PropTypes.bool,
};

export default ConfluenceViewPage;
