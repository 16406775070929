import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import actions from '~/app/entities/actions';
import { surveyPreviewSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import BaseContainer from '~/app/navigation/components/BaseContainer';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { useEntityMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import InfoBox from '~/app/shared/components/InfoBox';
import PageTitle from '~/app/shared/components/PageTitle';
import Text from '~/app/shared/components/Text';
import { STATUS_DONE, STATUS_ERROR } from '~/app/shared/constants';
import { useOriginRoute, useQueryParams, useUnsavedChangesPrompt } from '~/app/shared/hooks';
import SurveyForm from '~/app/surveys/components/SurveyForm';
import { createSurveyJSONSchema } from '~/app/surveys/components/SurveyForm/surveyJSONSchema';
import { transformFromPreviewToJSONEditorFormat } from '~/app/surveys/services';
import { isEmpty } from 'lodash-es';

const SurveyCreate = () => {
  const { cloned: cloneId } = useQueryParams();
  const [value, setValue] = useState(null);
  const history = useHistory();
  const redirectionRoute = useOriginRoute(mapRoute('dashboardSurveys'));
  const { trackEntityActivity } = useEntityMetrics();

  const [fetchSurvey, { status: fetchSurveyStatus }] = useEntities(
    actions.survey.preview,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        setValue(transformFromPreviewToJSONEditorFormat(data));
      } else if (status === STATUS_ERROR) {
        toast.error(`Fetching survey ${cloneId} failed.`);
      }
    },
    {
      schema: surveyPreviewSchema,
    }
  );

  const [submitSurvey, { status: submitSurveyStatus }] = useEntities(
    actions.survey.create,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        toast.success('Survey successfully created!');
        trackEntityActivity({
          id: data,
          isEdit: false,
          entityType: 'survey',
        });
        history.push(redirectionRoute);
      } else if (status === STATUS_ERROR) {
        toast.error('Submission failed, fix errors and try again.');
      }
    }
  );

  const handleSubmit = (payload) => {
    submitSurvey(payload);
  };

  useUnsavedChangesPrompt(
    submitSurveyStatus !== STATUS_DONE,
    'Your survey has not been saved. Are you sure you wish to leave this form without saving your work?'
  );

  // Check if there is a cloned id in the URL. If so, fetch the survey data.
  useEffect(() => {
    if (isEmpty(cloneId)) return;

    fetchSurvey(cloneId);
  }, [cloneId]);

  return (
    <BaseContainer>
      <PageTitle title="Create Survey" />
      <SurveyForm
        value={value}
        title="Create Survey"
        schema={createSurveyJSONSchema}
        isLoading={cloneId && fetchSurveyStatus !== STATUS_DONE}
        onSubmit={handleSubmit}
        redirectionRoute={redirectionRoute}
        renderHeader={() =>
          !value && (
            <InfoBox
              margin="0 0 20px 0"
              content={
                <Text size="h5">
                  Note that rather than creating a new survey from scratch, you may also{' '}
                  <Text medium>duplicate an existing survey</Text> via the Survey Dashboard.
                </Text>
              }
            />
          )
        }
      />
    </BaseContainer>
  );
};

export default SurveyCreate;
