import { CardMedia } from '@mui/material';

export const ContentItemHeaderCover = ({ cover, alt }: { cover: string; alt: string }) => (
  <CardMedia
    component="img"
    sx={{
      height: 200,
      width: 350,
      margin: 1,
      borderRadius: '4px',
      flexShrink: 0,
    }}
    alt={alt}
    src={cover}
  />
);
