import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ATTENDANCE_METHOD_LOCAL } from '~/app/program/constants';
import {
  formatSessionLocationString,
  sessionStartsAtHasPassed,
  userIsAttendee,
} from '~/app/program/services';
import colors from '~/services/colors';
import Datetime from '~/app/shared/components/Datetime';
import OldSvgIcon from '~/app/shared/components/SvgIcon';
import Text from '~/app/shared/components/Text';
import { useLabels } from '~/app/shared/hooks';
import { isEmpty, isEqual, get } from 'lodash-es';

import UsersInfo from './UsersInfo';

const SessionInfoContainer = styled.div`
  width: 100%;
  display: inline-block;
  line-height: 1.5;
`;
export const LocationText = styled(Text)`
  margin-top: 4px;
`;

export const ExtraInfoText = styled(Text)`
  margin-top: 4px;
`;

const ProgramTitleText = styled(Text)`
  margin-top: 4px;
`;

const SvgContainer = styled.span`
  margin: 0 0 0 5px;
`;

const AttendanceInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  margin-top: 15px;
`;

const LeftAttendanceInfo = styled.div`
  color: ${colors.action700};
`;

const RightAttendanceInfo = styled.div`
  color: ${colors.neutral600};
`;

const FullyBooked = styled.div`
  color: ${colors.error600};
`;

const SessionInfo = ({
  session,
  currentUser,
  showDate,
  showProgramText,
  hasProgram,
  sessionIsBooked,
  sessionAttendanceLimit,
  sessionEnrollmentsCount,
  sessionSpotsLeft,
  componentName,
}) => {
  const locationString = formatSessionLocationString(session, currentUser);
  const currentUserIsHost = currentUser.id === session.host.id;
  const isLocal = isEqual(get(session, 'enrollment.attendance_method'), ATTENDANCE_METHOD_LOCAL);
  const {
    label_mentorship_tab: labelMentorshipApp,
    label_program: labelProgram,
    label_mentorship_program: labelMentorshipProgram,
  } = useLabels();

  const sessionIsUpcoming = !sessionStartsAtHasPassed(session, currentUser.timezone);
  const typeLabel =
    session.program_id && session.program_type == 'mentorship_program'
      ? labelMentorshipProgram
      : labelProgram;

  return (
    <SessionInfoContainer>
      <UsersInfo
        session={session}
        currentUserIsHost={currentUserIsHost}
        componentName={componentName}
        sessionAttendanceLimit={sessionAttendanceLimit}
        sessionEnrollmentsCount={sessionEnrollmentsCount}
      />
      {showDate && (
        <Text medium block size="h4" color={colors.neutral900}>
          <Datetime.Date
            datetime={session.starts_at_tz_aware}
            timezone={session.timezone}
            displayTimezone={currentUser.timezone}
            isOnline={session.allows_online}
            includeWeekDay
          />
        </Text>
      )}
      <Text medium block size="h4" color={colors.neutral900}>
        <Datetime.TimeRange
          start={session.starts_at_tz_aware}
          end={session.ends_at_tz_aware}
          timezone={session.timezone}
          displayTimezone={currentUser.timezone}
          isOnline={session.allows_online}
        />
      </Text>
      <LocationText block size="h4" color={colors.neutral900}>
        {locationString}
        {session.allows_online && !isLocal && (
          <SvgContainer>
            <OldSvgIcon.Remote width={12} height={12} fill={colors.neutral900} />
          </SvgContainer>
        )}
      </LocationText>
      {!isEmpty(session.extra_info) && isLocal && isEmpty(session.enrollments) && (
        <ExtraInfoText block size="h5" color={colors.neutral600}>
          {session.extra_info}
        </ExtraInfoText>
      )}
      {showProgramText && (
        <ProgramTitleText block size="h5" color={colors.neutral600}>
          {session.program_id
            ? `${typeLabel}: ${session.program_name || session.name}`
            : labelMentorshipApp}
        </ProgramTitleText>
      )}
      {hasProgram && (
        <AttendanceInfoContainer>
          <LeftAttendanceInfo>
            {/* Session is 1:1 session */}
            {sessionAttendanceLimit === 1 && '1:1 session'}

            {/* Session is multiple spots unlimited */}
            {sessionAttendanceLimit === 0 && 'Unlimited'}

            {/* Session has multiple spots and there are still spots left and is upcoming */}
            {sessionIsUpcoming &&
              sessionAttendanceLimit > 1 &&
              sessionAttendanceLimit !== sessionEnrollmentsCount &&
              `${sessionSpotsLeft} ${sessionSpotsLeft === 1 ? 'spot' : 'spots'} left`}

            {/* Session has multiple spots and there are no spots left and is upcoming */}
            {sessionIsUpcoming &&
              sessionAttendanceLimit > 1 &&
              sessionAttendanceLimit === sessionEnrollmentsCount && (
                <FullyBooked>Fully Booked</FullyBooked>
              )}
            {/* Session has multiple spots and has passed */}
            {!sessionIsUpcoming && sessionAttendanceLimit > 1 && 'Group Session'}
          </LeftAttendanceInfo>
          <RightAttendanceInfo>
            {/* Session is booked, only has 1 spot, and user is attendee and is upcoming */}
            {sessionIsUpcoming &&
              sessionAttendanceLimit === 1 &&
              sessionIsBooked &&
              userIsAttendee(session, currentUser) &&
              'You are attending'}

            {/* Session is booked, only has 1 spot and user is host and is upcoming */}
            {sessionIsUpcoming &&
              sessionAttendanceLimit === 1 &&
              sessionIsBooked &&
              currentUserIsHost &&
              'Booked'}

            {/* Session is booked, has multiple spots and user is attendee and is upcoming */}
            {sessionIsUpcoming &&
              sessionAttendanceLimit > 1 &&
              sessionIsBooked &&
              userIsAttendee(session, currentUser) &&
              `${
                sessionEnrollmentsCount === 1
                  ? 'You are attending'
                  : `You + ${sessionEnrollmentsCount - 1} are attending`
              }`}

            {/* Session is booked, has multiple spots and user is host and is upcoming */}
            {sessionIsUpcoming &&
              sessionAttendanceLimit > 1 &&
              sessionIsBooked &&
              currentUserIsHost &&
              `${
                sessionEnrollmentsCount === 1
                  ? '1 person is'
                  : `${sessionEnrollmentsCount} people are`
              } attending`}
          </RightAttendanceInfo>
        </AttendanceInfoContainer>
      )}
    </SessionInfoContainer>
  );
};

SessionInfo.propTypes = {
  session: PropTypes.object,
  currentUser: PropTypes.object,
  showDate: PropTypes.bool,
  showProgramText: PropTypes.bool,
  hasProgram: PropTypes.bool,
  sessionIsBooked: PropTypes.bool,
  sessionAttendanceLimit: PropTypes.number,
  sessionEnrollmentsCount: PropTypes.number,
  sessionSpotsLeft: PropTypes.number,
  componentName: PropTypes.string,
};

export default SessionInfo;
