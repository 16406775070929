import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import styled, { css } from 'styled-components';

import { Button } from '~/app/shared';
import colors from '~/services/colors';
import { useCurrentUser } from '~/app/shared/hooks';

const baseContainerStyle = css`
  display: flex;
  border: dashed 2px ${colors.action600};
  border-radius: 5px;
  min-width: ${({ width }) => width || '100%'};
  min-height: ${({ height }) => height || '200px'};
`;

const PreviewContainer = styled.div`
  ${baseContainerStyle};
  border-style: solid;
`;

const SelectionContainer = styled.div`
  ${baseContainerStyle};
  cursor: pointer;
  display: flex;
  border-style: dashed;
  justify-content: center;
`;

const ReselectionContainer = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const CtaWrapper = styled.span`
  cursor: pointer;
`;

const GoogleDrivePicker = ({
  onPicked,
  onAccessToken,
  viewId,
  initialAccessToken,
  width,
  height,
  makeSelectionCta,
  changeSelectionCta,
  resetSelectionCta,
}) => {
  const { google_client_id: googleClientId } = useCurrentUser();
  const [accessToken, setAccessToken] = useState(initialAccessToken);
  const [picked, setPicked] = useState(null);

  useEffect(() => onPicked(picked), [picked]);

  const [openPicker] = useDrivePicker();

  const DEVELOPER_KEY = 'AIzaSyAvY8T90YJ_YRx5GDI1iBNfpnasZ2dRRXk'; // nosec

  const requestGoogleAccessToken = (callback) => {
    /*
    Note, we can only request the access token for this scope (drive.readonly) if the the app
    designated by the specified googleClientId (typically the PlusPlus App) has been explictly
    approved by the user's Google Workspace admins.
    */

    // useDrivePicker implictly loads the `gooogle` libs
    // eslint-disable-next-line no-undef
    google.accounts.oauth2
      .initTokenClient({
        client_id: googleClientId,
        scope: 'https://www.googleapis.com/auth/drive.readonly',
        callback,
      })
      .requestAccessToken();
  };

  const openGoogleDocPicker = () => {
    const openGoogleDocPickerWithToken = (token) => {
      openPicker({
        clientId: googleClientId,
        developerKey: DEVELOPER_KEY,
        token,
        viewId,
        supportDrives: true,
        callbackFunction: (data) => {
          if (data.action === 'picked') {
            setPicked(data.docs[0]);
          }
        },
      });
    };

    if (accessToken) {
      openGoogleDocPickerWithToken(accessToken);
    } else {
      requestGoogleAccessToken((tokenResponse) => {
        setAccessToken(tokenResponse.access_token); // save for re-use
        if (onAccessToken) {
          onAccessToken(tokenResponse.access_token);
        }
        openGoogleDocPickerWithToken(tokenResponse.access_token);
      });
    }
  };

  return (
    <>
      {picked && (
        <PreviewContainer>
          <iframe title={picked.name} src={picked.embedUrl} width="100%" height="100%" />
        </PreviewContainer>
      )}

      {picked ? (
        <ReselectionContainer>
          <CtaWrapper onClick={openGoogleDocPicker}>
            <Button type="button" variant="text" size="small">
              {changeSelectionCta}
            </Button>
          </CtaWrapper>
          <CtaWrapper onClick={() => setPicked(null)}>{resetSelectionCta}</CtaWrapper>
        </ReselectionContainer>
      ) : (
        <SelectionContainer onClick={openGoogleDocPicker} width={width} height={height}>
          {makeSelectionCta}
        </SelectionContainer>
      )}
    </>
  );
};

GoogleDrivePicker.defaultProps = {
  viewId: 'DOCUMENTS',
  makeSelectionCta: (
    <Button type="button" variant="text" size="medium">
      Select
    </Button>
  ),
  changeSelectionCta: (
    <Button type="button" variant="text" size="small">
      Select another
    </Button>
  ),
  resetSelectionCta: (
    <Button type="button" variant="text" size="small" color="error">
      Reset
    </Button>
  ),
};

GoogleDrivePicker.propTypes = {
  onPicked: PropTypes.func.isRequired,
  onAccessToken: PropTypes.func,
  viewId: PropTypes.string,
  initialAccessToken: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  makeSelectionCta: PropTypes.node,
  changeSelectionCta: PropTypes.node,
  resetSelectionCta: PropTypes.node,
};

export default GoogleDrivePicker;
