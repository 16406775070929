import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { styleBackgroundImage } from '~/services/styled-theme';
import { noop } from 'lodash-es';

const Image = styled.div`
  flex: 0 0 ${({ width }) => width};
  width: ${({ width }) => width};

  ${({ height }) =>
    height &&
    `
    height: ${height};
  `}

  ${({ hasBorder }) =>
    hasBorder &&
    `
    border-radius: 4px;
  `}

  ${styleBackgroundImage}
`;

const CoverImage = ({
  imageUrl,
  alt,
  width,
  height,
  hasBorder,
  defaultBgColor,
  renderDefaultIcon,
}) => (
  <Image
    imageUrl={imageUrl}
    alt={alt}
    width={width}
    height={height}
    hasBorder={hasBorder}
    bgColor={defaultBgColor}
  >
    {!imageUrl && renderDefaultIcon && renderDefaultIcon()}
  </Image>
);

CoverImage.defaultProps = {
  height: null,
  hasBorder: false,
  defaultBgColor: null,
  renderDefaultIcon: noop,
};

CoverImage.propTypes = {
  imageUrl: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  hasBorder: PropTypes.bool,
  defaultBgColor: PropTypes.string,
  renderDefaultIcon: PropTypes.func,
};

export default CoverImage;
