// TODO merge this component with `FeedbackContainer` after we fully transform events in a `ContentItem`
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import { Rating } from '~/app/shared';
import CardCarousel from '~/app/shared-cards/components/CardCarousel';
import FeedbackViewMoreModal from '~/app/shared-modals/components/FeedbackViewMoreModal';
import Loading from '~/app/shared/components/Loading';
import MediaPoint from '~/app/shared/components/MediaPoint';
import { PAGE_ARROW_WIDTH } from '~/app/shared/components/PageArrow';
import ProgressBar from '~/app/shared/components/ProgressBar';
import Text from '~/app/shared/components/Text';
import ViewMoreCta from '~/app/shared/components/ViewMoreCta';
import { isEmpty, reduce, every, get } from 'lodash-es';

const FEEDBACK_CARD_MARGIN = 8;

const FeedbackCard = styled.div`
  background-color: white;
  margin: ${({ margin }) => margin}px;
  padding: 12px;
  border-radius: 10px;
  width: ${({ cardWidth }) => cardWidth}px;
  height: ${({ cardHeight }) => cardHeight}px;
  border: 1px solid ${colors.neutral200};
`;

const FeedbackCarouselContainer = styled.div`
  > ${Text} {
    margin-left: ${PAGE_ARROW_WIDTH}px;

    ${MediaPoint.DesktopSm} {
      margin: 0;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const FeedbackHeader = styled.div`
  display: flex;

  > * + * {
    margin-left: 10px;
  }
`;

const RatingCountWrapper = styled.div`
  flex: 1;
  > * + * {
    margin-top: 4px;
  }
`;

const RatingCountItem = styled.div`
  display: flex;
  align-items: center;
  min-width: 40px;

  > * + * {
    margin: 0;
    margin-left: 4px;
  }
`;

const HeaderContainer = styled.div`
  > * + * {
    margin-top: 8px;
  }
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 20px;
  background-color: ${colors.neutral0};
  border-radius: 8px;
  display: inline-block;

  ${({ $fillContainer }) => $fillContainer && '0px 4px 15px rgba(0, 0, 0, 0.1)'};

  ${({ $withFlexBasis }) => $withFlexBasis && 'flex: 1;'}

  > * + * {
    margin-top: 8px;
  }
`;

const Body = styled.div`
  ${({ useFlexbox }) =>
    useFlexbox
      ? `
        display: flex;
        align-items: center;
  `
      : `
        display: grid;
        grid-auto-flow: row;
  `}

  gap: 20px;
`;

const TextWrapper = styled.div`
  width: ${({ $mode }) => ($mode === 'wide' ? 'auto' : '100%')};
`;

const RatingCount = ({ totalRatingCount, ratingPercentageBar, rating }) => {
  return (
    <RatingCountItem>
      <div>
        <Rating value={rating} readOnly />
      </div>

      <ProgressBar completedProgress={ratingPercentageBar} completedColor={colors.rating600} />
      <Text size="h6" color={totalRatingCount > 0 ? colors.rating600 : colors.neutral200}>
        {totalRatingCount}
      </Text>
    </RatingCountItem>
  );
};

RatingCount.propTypes = {
  rating: PropTypes.number,
  totalRatingCount: PropTypes.number,
  ratingPercentageBar: PropTypes.number,
};

export const useBaseFeedbackContainerMode = ({ showFeedback, showOthers }) => {
  const showFeedbackOnly = useMemo(
    () => showFeedback === true && every(showOthers, (show) => show === false),
    [showFeedback, showOthers]
  );

  return showFeedbackOnly ? 'wide' : 'compact';
};

const BaseFeedbackContainer = (props) => {
  const {
    content,
    feedbackList,
    isLoading,
    cardWidth,
    cardHeight,
    containerTitle,
    withFlexBasis,
    fillContainer,
    mode,
    useFlexbox,
    latestReviewsLabel,
  } = props;

  const [showViewMoreModal, setShowViewMoreModal] = useState(false);
  const [viewMoreFeeback, setViewMoreFeeback] = useState({});

  const FEEDBACK_CARD_WIDTH = cardWidth || 180;
  const FEEDBACK_CARD_HEIGHT = cardHeight || 190;

  const openModal = (feedback) => {
    setShowViewMoreModal(true);
    setViewMoreFeeback(feedback);
  };

  const closeModal = () => {
    setShowViewMoreModal(false);
    setViewMoreFeeback({});
  };

  const totalFeedbackCount = reduce(
    content?.feedback_by_rating,
    (sum, n) => {
      return sum + n;
    },
    0
  );

  const getRatingCount = (rating) => {
    const ratingCount = get(content, `feedback_by_rating.feedback_rating_${rating}`, 0);
    return ratingCount;
  };

  const getRatingPercentageBar = (rating) => {
    if (totalFeedbackCount === 0) {
      return 0;
    }

    const ratingCount = getRatingCount(rating);
    return (ratingCount * 100) / totalFeedbackCount;
  };

  const rating =
    content.avg_feedback_rating || content.average_feedback_rating || content.feedback_rating || 0;

  return (
    <Container $fillContainer={fillContainer} $withFlexBasis={withFlexBasis} $mode={mode}>
      <Text medium>{containerTitle}</Text>

      <Body $mode={mode} useFlexbox={useFlexbox}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <FeedbackHeader>
              <div>
                <div>
                  <Text size="h1">{Math.round(rating * 10) / 10}</Text>
                  <Text color={colors.neutral400}>/5</Text>
                </div>
                <Text color={colors.neutral400}>
                  {totalFeedbackCount} review{totalFeedbackCount > 1 && 's'}
                </Text>
              </div>
              <RatingCountWrapper>
                <RatingCount
                  totalRatingCount={getRatingCount(5)}
                  ratingPercentageBar={getRatingPercentageBar(5)}
                  rating={5}
                />
                <RatingCount
                  totalRatingCount={getRatingCount(4)}
                  ratingPercentageBar={getRatingPercentageBar(4)}
                  rating={4}
                />
                <RatingCount
                  totalRatingCount={getRatingCount(3)}
                  ratingPercentageBar={getRatingPercentageBar(3)}
                  rating={3}
                />
                <RatingCount
                  totalRatingCount={getRatingCount(2)}
                  ratingPercentageBar={getRatingPercentageBar(2)}
                  rating={2}
                />
                <RatingCount
                  totalRatingCount={getRatingCount(1)}
                  ratingPercentageBar={getRatingPercentageBar(1)}
                  rating={1}
                />
              </RatingCountWrapper>
            </FeedbackHeader>

            {!isEmpty(feedbackList) && (
              <FeedbackCarouselContainer>
                {latestReviewsLabel && (
                  <TextWrapper $mode={mode}>
                    <Text>{latestReviewsLabel}</Text>
                  </TextWrapper>
                )}
                <CardCarousel
                  fillContainer
                  list={feedbackList}
                  renderItem={(feedback) => (
                    <FeedbackCard
                      key={feedback.id}
                      cardWidth={FEEDBACK_CARD_WIDTH}
                      cardHeight={FEEDBACK_CARD_HEIGHT}
                      margin={FEEDBACK_CARD_MARGIN}
                    >
                      <HeaderContainer>
                        <Rating value={feedback.feedback_rating} readOnly />
                        <Text size="h5" lineHeight={20} medium>
                          {feedback.user_title}
                        </Text>
                      </HeaderContainer>
                      <ViewMoreCta
                        backgroundFadeColor={colors.neutral0}
                        onClick={() => openModal(feedback)}
                        multipleLines
                        size="small"
                      >
                        {({ nodeRef }) => (
                          <Text
                            ref={nodeRef}
                            size="h6"
                            medium
                            color={colors.neutral600}
                            ellipsisOnLine={5}
                          >
                            {feedback.feedback_text}
                          </Text>
                        )}
                      </ViewMoreCta>
                      {showViewMoreModal && (
                        <FeedbackViewMoreModal
                          title={`Review for ${content.name}`}
                          feedbackText={viewMoreFeeback.feedback_text}
                          feedbackRating={viewMoreFeeback.feedback_rating}
                          userTitle={viewMoreFeeback.user_title}
                          handleClose={closeModal}
                        />
                      )}
                    </FeedbackCard>
                  )}
                  cardWidth={FEEDBACK_CARD_WIDTH}
                  margin={FEEDBACK_CARD_MARGIN}
                />
              </FeedbackCarouselContainer>
            )}
          </>
        )}
      </Body>
    </Container>
  );
};

BaseFeedbackContainer.propTypes = {
  content: PropTypes.object,
  feedbackList: PropTypes.array,
  isLoading: PropTypes.bool,
  cardWidth: PropTypes.number,
  cardHeight: PropTypes.number,
  containerTitle: PropTypes.string,
  withFlexBasis: PropTypes.bool,
  mode: PropTypes.string,
  fillContainer: PropTypes.bool,
  useFlexbox: PropTypes.bool,
  latestReviewsLabel: PropTypes.string,
};

BaseFeedbackContainer.defaultProps = {
  containerTitle: 'Feedback',
  withFlexBasis: true,
  mode: 'wide',
  useFlexbox: false,
  latestReviewsLabel: 'Latest reviews',
};

export default BaseFeedbackContainer;
