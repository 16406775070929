import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SelectField from '~/app/inputs/components/SelectField';
import colors from '~/services/colors';
import MediaPoint from '~/app/shared/components/MediaPoint';
import Text from '~/app/shared/components/Text';
import { get } from 'lodash-es';

const PageSorting = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 312px;

  ${MediaPoint.Tablet} {
    width: calc(100% - 24px);
  }
`;

const SortWrapper = styled.div`
  display: flex;
  align-items: center;

  > ${Text} {
    margin-right: 12px;
  }
`;

const SortSelectFieldWrapper = styled.div`
  width: 144px;
`;

const MyGroupsTopBar = ({ groupsCount, updateSelectedFilters, filtersSelected }) => {
  const sortValue = get(filtersSelected, 'o');

  const handleSortChange = (value) => {
    updateSelectedFilters({
      ...filtersSelected,
      o: value,
    });
  };

  return (
    <PageSorting>
      <Text size="h4" color={colors.neutral600}>
        {groupsCount} {groupsCount === 1 ? 'Group' : 'Groups'}
      </Text>

      <SortWrapper>
        <SortSelectFieldWrapper>
          <SelectField
            label="Sort by"
            options={[
              { label: 'A-Z', value: 'name' },
              {
                label: 'Largest Size',
                value: '-members_count',
              },
              { label: 'Most Recent', value: '-modified' },
            ]}
            input={{
              value: sortValue,
              onChange: handleSortChange,
            }}
          />
        </SortSelectFieldWrapper>
      </SortWrapper>
    </PageSorting>
  );
};

MyGroupsTopBar.propTypes = {
  groupsCount: PropTypes.number,
  updateSelectedFilters: PropTypes.func,
  filtersSelected: PropTypes.object,
};

export default MyGroupsTopBar;
