import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import { Tooltip } from '~/app/shared/components/Tooltip';
import { useTooltipUID } from '~/app/shared/hooks';

const FieldLabelContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 5px 0;

  ${({ block }) => block && 'display: flex'};
`;

const FieldLabelText = styled.label`
  font-weight: bold;
  font-size: 14px;
  margin: 0;

  ${({ color }) => (color ? `color: ${color}` : `color: ${colors.neutral600}`)};
`;

const FieldLabelTooltip = ({ tooltipTitle }) => {
  const { uid, targetProps } = useTooltipUID();

  if (!tooltipTitle) {
    return null;
  }

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-success btn-simple btn-xs"
        {...targetProps}
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <i className="fa fa-info-circle" />
      </button>
      <Tooltip id={uid}>{tooltipTitle}</Tooltip>
    </React.Fragment>
  );
};

FieldLabelTooltip.propTypes = {
  tooltipTitle: PropTypes.string,
};

const FieldLabel = ({ tooltipTitle, children, block, color, ...rest }) => (
  <FieldLabelContainer block={block}>
    <FieldLabelText color={color} {...rest}>
      {children}
    </FieldLabelText>
    <FieldLabelTooltip tooltipTitle={tooltipTitle} />
  </FieldLabelContainer>
);

FieldLabel.defaultProps = {
  block: true,
};

FieldLabel.propTypes = {
  tooltipTitle: PropTypes.string,
  block: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default FieldLabel;
