import CompositeTextInput from '~/app/inputs/components/CompositeTextInput';
import colors from '~/services/colors';
import HelpLink from '~/app/shared/components/HelpLink';
import { Typography } from '~/common/components/Typography';
import { Box, Stack } from '@mui/material';

export interface QuestionTextAnswerInputProps {
  value?: string;
  operator?: string;
  onChange: ({ operator, value }: { operator: string; value: string }) => void;
  error?: boolean;
}

const options = [
  { value: 'any_of', label: 'Any of' },
  { value: 'contains', label: 'Contains' },
  { value: 'equal', label: 'Is' },
  { value: 'startswith', label: 'Starts with' },
  { value: 'endswith', label: 'Ends with' },
  { value: 'matches', label: 'Matches' },
];

export const AssessmentQuestionTextAnswerInput = ({
  value,
  operator = options[0].value,
  onChange,
  error,
}: QuestionTextAnswerInputProps) => {
  return (
    <Box>
      <Stack direction="column" spacing={2.5} margin={2.5}>
        <Typography sx={{ color: colors.neutral600 }}>Set up accepted answers</Typography>
        <CompositeTextInput
          label="Accepted Answers"
          aria-label="Accepted Answers"
          value={value}
          selection={operator}
          options={options}
          required
          onChange={({ value, selection }) => onChange({ value, operator: selection })}
          helperText={
            <p>
              Type acceptable answers separated by commas (Answer 1,Answer 2,Answer 3).{' '}
              <HelpLink url="HCArticleURL">Learn more</HelpLink>
            </p>
          }
          error={error}
        />
      </Stack>
    </Box>
  );
};

export default AssessmentQuestionTextAnswerInput;
