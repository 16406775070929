import { useState } from 'react';
import styled from 'styled-components';

import { useContentItemAssignmentActions } from '~/features/contentitems/hooks/useContentItemAssignmentActions';
import { Assignment, ContentItem } from '~/app/shared-content-item/interfaces';
import ButtonLink from '~/app/shared/components/ButtonLink';
import RateButton from '~/app/shared/components/RateButton';
import Rating from '~/app/shared/components/Rating';
import ContentRating from '~/app/stand-alone-shared/components/ContentRating';
import { get } from 'lodash-es';

const Container = styled.div`
  min-height: 40px; // Large Button height
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
`;

const RatingContainer = styled.div`
  flex: 1 0 auto;
  margin-right: 12px;

  font-size: 20px; // Star size

  display: flex;
  justify-content: flex-end;
`;

type ContentRatingActionsProps = {
  content: ContentItem;
  assignment: Assignment;
};

export function ContentRatingActions(props: ContentRatingActionsProps) {
  const { content, assignment } = props;

  const { rate, isLoading } = useContentItemAssignmentActions({
    contentItem: content,
    assignment,
  });

  const [isRatingContent, setRatingContent] = useState(false);
  const currentRating = get(assignment, 'feedback_rating') || get(assignment, 'rating.rating', 0);

  return (
    <>
      <Container>
        {currentRating > 0 && (
          <>
            <RatingContainer>
              <Rating value={currentRating} readOnly />
            </RatingContainer>
            <ButtonLink
              onClick={() => setRatingContent(true)}
              variant="primary"
              disabled={isLoading}
            >
              Edit
            </ButtonLink>
          </>
        )}
        {currentRating <= 0 && (
          <RateButton
            size="medium"
            onClick={() => setRatingContent(true)}
            fullWidth
            disabled={isLoading}
          >
            Rate
          </RateButton>
        )}
      </Container>

      {isRatingContent && (
        <ContentRating
          content={content}
          onSubmitFunc={rate}
          handleClose={() => setRatingContent(false)}
        />
      )}
    </>
  );
}
