import { useContentItemAssignmentActions } from '~/features/contentitems/hooks/useContentItemAssignmentActions';
import { RateAssignmentBody } from '~/features/contentitems/types';
import FeedbackForm from '~/app/shared-cards/components/FeedbackForm';
import Modal from '~/app/shared/components/Modal';
import { useLabels } from '~/app/shared/hooks';
import RichTextDescription from '~/app/stand-alone-shared/components/ContentDescription';
import { Track } from '~/app/tracks/interfaces';
import { get, reduce, replace } from 'lodash-es';
import { styled } from '@mui/material';

const CongratulationsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface TrackCompletionModal {
  track: Track;
  handleClose: () => void;
}

const TrackCompletionModal = (props: TrackCompletionModal) => {
  const { track, handleClose } = props;

  const { label_track: labelTrack } = useLabels();

  const assignment = get(track, 'user_assignment');

  const { rate, acknowledgeCompletion, isLoading } = useContentItemAssignmentActions({
    contentItem: track,
    assignment,
  });

  const handleRate = (rateBody: Omit<RateAssignmentBody, 'id'>) => {
    rate(rateBody, {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  const handleCloseAndAckCompletion = () => {
    acknowledgeCompletion({
      onSuccess: () => {
        handleClose();
      },
    });
  };

  const initial = {
    id: track.id,
    feedback_rating: get(assignment, 'rating.rating', 5),
    feedback_text: get(assignment, 'rating.feedback', ''),
    user_id: get(assignment, 'user_id'),
  };

  const fieldLabels = {
    rating: 'The Author requested your Rating',
    feedback: 'Please leave a review',
  };

  const fieldPlaceholders = {
    feedback: 'How was your experience with this content?',
  };

  const replacementsMap = [
    ['contentName', track.name],
    ['labelTrack', labelTrack],
  ];

  const interpolatedCompletionDialogContent = reduce(
    replacementsMap,
    (acc, [replacementKey, replacementValue]) =>
      replace(acc, `{{ ${replacementKey} }}`, replacementValue),
    get(track, 'settings.track_completion_dialog_content.value.1')
  );

  return (
    <Modal title={`${labelTrack} Completed`} handleClose={handleCloseAndAckCompletion} width={500}>
      <CongratulationsContainer>
        <RichTextDescription description={interpolatedCompletionDialogContent} noPadding />
      </CongratulationsContainer>
      <FeedbackForm
        user={get(assignment, 'user')}
        initialValues={initial}
        onSubmitFunc={handleRate}
        fieldLabels={fieldLabels}
        fieldPlaceholders={fieldPlaceholders}
        handleDismiss={handleCloseAndAckCompletion}
        formMarginBottom={15}
        submitting={isLoading}
        feedbackIsPublic
      />
    </Modal>
  );
};

export default TrackCompletionModal;
