import { useHistory } from 'react-router-dom';

import { useContentTypeRoutes, useGetContentTypeLabel } from '~/app/catalog/hooks';
import { WidgetContent, WidgetContentList } from '~/common/components/widget';
import { Event } from '~/app/event/interfaces';
import { displayDatetime } from '~/services/datetime';
import { useCurrentUser } from '~/app/shared/hooks';
import { constant, get, join, filter } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Box, Card, CardActionArea, CardMedia, Skeleton, Stack } from '@mui/material';
import { CalendarTodayIcon } from '~/vendor/mui-icons';

interface EventWidgetRowProps {
  event: Event;
}

export function EventWidgetRow(props: EventWidgetRowProps) {
  const { event } = props;
  const {
    name,
    content_type: contentType,
    public_id_and_slug: publicIdAndSlug,
    cover_url: coverURL,
  } = event;

  const history = useHistory();
  const { timezone: userTimezone } = useCurrentUser();
  const contentTypeRoutes = useContentTypeRoutes({});
  const getLabel = useGetContentTypeLabel();

  const getContentDetailURL = get(contentTypeRoutes, `${contentType}.details`, constant('')) as any;

  return (
    <Card elevation={0}>
      <CardActionArea
        onClick={() => history.push(getContentDetailURL({ public_id_and_slug: publicIdAndSlug }))}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1.5 }}>
          <CardMedia
            component="img"
            sx={{ width: 72, height: 44, objectFit: 'cover', borderRadius: 1 }}
            image={coverURL}
            alt={name}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Typography variant="body1" noWrap>
              {name}
            </Typography>
            <Typography variant="caption" noWrap>
              {join(
                filter(
                  [
                    getLabel(contentType),
                    displayDatetime(
                      get(event, 'starts_at_tz_aware'),
                      get(event, 'timezone'),
                      userTimezone,
                      get(event, 'is_online', false)
                    ),
                  ],
                  Boolean
                ),
                ' • '
              )}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

type EventsWidgetEmptyRowProps = {
  animate?: boolean;
};

export function EventsWidgetEmptyRow(props: EventsWidgetEmptyRowProps) {
  const { animate = false } = props;

  return (
    <Card elevation={0}>
      <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1.5 }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <CalendarTodayIcon sx={{ fontSize: '35px' }} fill="rgba(0, 0, 0, 0.56)" />
        </Box>
        <Box display="flex" flexDirection="column" flex={1}>
          <Typography variant="body1" noWrap>
            <Skeleton variant="text" animation={animate ? 'wave' : false} sx={{ maxWidth: 235 }} />
          </Typography>
          <Typography variant="caption" noWrap>
            <Skeleton
              variant="text"
              height={12}
              animation={animate ? 'wave' : false}
              sx={{ maxWidth: 118 }}
            />
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

type EventsWidgetLoadingContentProps = {
  animate?: boolean;
};

export function EventsWidgetLoadingContent(props: EventsWidgetLoadingContentProps) {
  const { animate = true } = props;

  return (
    <>
      <EventsWidgetEmptyRow animate={animate} />
      <EventsWidgetEmptyRow animate={animate} />
      <EventsWidgetEmptyRow animate={animate} />
    </>
  );
}

type EvetnsWidgetEmptyContentProps = {
  label: string;
};

export function EvetnsWidgetEmptyContent(props: EvetnsWidgetEmptyContentProps) {
  const { label } = props;

  return (
    <WidgetContent paddingTop={0}>
      <Stack direction="column" rowGap={3}>
        <Typography variant="body2" noWrap color="rgba(0, 0, 0, 0.6)">
          {label}
        </Typography>
        <WidgetContentList>
          <EventsWidgetEmptyRow />
          <EventsWidgetEmptyRow />
        </WidgetContentList>
      </Stack>
    </WidgetContent>
  );
}
