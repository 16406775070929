import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
  ENROLLMENT_STATUS_ATTENDED,
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
  ENROLLMENT_STATUS_NOT_GOING,
  ENROLLMENT_STATUS_UNANSWERED,
} from '~/app/event-shared/constants';
import {
  getEnrollmentConditions,
  getEventStatus,
  getUserEnrollmentStatus,
} from '~/app/event-shared/services';
import { ApiURLs } from '~/services/requests-base';
import Button from '~/app/shared/components/Button';
import Loading from '~/app/shared/components/Loading';
import Pill from '~/app/shared/components/Pill';
import SplitButton from '~/app/shared/components/SplitButton';
import { useToggles } from '~/app/shared/hooks';
import { filter, get, find } from 'lodash-es';
import { Stack } from '@mui/material';
import {
  ConnectWithoutContactIcon,
  HighlightOffIcon,
  PersonPinCircleIcon,
} from '~/vendor/mui-icons';

export const CheckinActionButtons = ({ event, onEnrollmentStatusChange }) => {
  const { toggle_allow_auto_checkin_on_joining_vc: toggleAllowAutoCheckinOnJoiningVC } =
    useToggles();

  const enrollmentStatus = get(event, 'enrollment.status', ENROLLMENT_STATUS_UNANSWERED);

  let status = {
    ...getUserEnrollmentStatus(enrollmentStatus),
    ...getEventStatus(event),
  };

  status = {
    ...status,
    ...getEnrollmentConditions(status),
  };

  const {
    showLocalEnroll,
    showOnlineEnroll,
    eventAllowsCancellation,
    userIsEnrolledOnline,
    userIsEnrolledLocal,
    userHasAttended,
  } = status;

  const handleEnrollmentStatusChange = (status) => onEnrollmentStatusChange(status);
  const performUnenroll = () => handleEnrollmentStatusChange(ENROLLMENT_STATUS_NOT_GOING);
  const performOnlineEnrollment = () =>
    handleEnrollmentStatusChange(ENROLLMENT_STATUS_GOING_ONLINE);
  const performLocalEnrollment = () => handleEnrollmentStatusChange(ENROLLMENT_STATUS_GOING);
  const performCheckin = () => handleEnrollmentStatusChange(ENROLLMENT_STATUS_ATTENDED);

  const [isLoading, setIsLoading] = useState(false);

  const getVideoConferenceLink = () => {
    const now = moment.now();
    const { timeslots } = event;

    const timeslot = find(timeslots, (t) => {
      const { ends_at_tz_aware: endsAt } = t;
      const utcEndsAt = moment.utc(endsAt);
      return moment(now).isSameOrBefore(utcEndsAt);
    });

    const { id, watch_link: watchLink } = timeslot;

    return toggleAllowAutoCheckinOnJoiningVC
      ? ApiURLs.video_conference_redirect({ pk: id })
      : watchLink;
  };

  const joinVC = (videoConferenceLink) => {
    window.open(videoConferenceLink, '_blank');
    window.location.reload();
  };

  const checkIn = () => {
    setIsLoading(true);
    performCheckin();
  };

  const switchToOnline = () => {
    setIsLoading(true);
    performOnlineEnrollment();
  };

  const switchToLocal = () => {
    setIsLoading(true);
    performLocalEnrollment();
  };

  const drop = () => {
    setIsLoading(true);
    performUnenroll();
  };

  const callToActionButtonsOptions = filter(
    [
      userIsEnrolledOnline && {
        label: 'Join VC',
        onClick: () => joinVC(getVideoConferenceLink()),
        startIcon: <ConnectWithoutContactIcon />,
      },
      (userIsEnrolledLocal || userIsEnrolledOnline) && {
        label: 'Check in',
        onClick: () => checkIn(),
        startIcon: <PersonPinCircleIcon />,
      },
      showOnlineEnroll && {
        label: 'Switch to Online',
        onClick: () => switchToOnline(),
        startIcon: <ConnectWithoutContactIcon />,
      },
      showLocalEnroll && {
        label: 'Switch to In Person',
        onClick: () => switchToLocal(),
        startIcon: <PersonPinCircleIcon />,
      },
    ],
    Boolean
  );

  const [mainCallToActionButton, ...secondaryCallToActionButtons] = callToActionButtonsOptions;

  useEffect(() => {
    setIsLoading(false);
  }, [userIsEnrolledOnline, userIsEnrolledLocal]);

  if (isLoading) {
    return <Loading hasMargin={false} size={12} />;
  }

  if (userHasAttended) {
    return (
      <Stack direction="row" spacing="12px">
        <Pill icon="checkbox-round" variant="success200" label="You are checked in!" />
        <Button
          onClick={() => joinVC(getVideoConferenceLink())}
          startIcon={<PersonPinCircleIcon />}
        >
          Join VC
        </Button>
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing="12px">
      {eventAllowsCancellation && (
        <Button onClick={drop} startIcon={<HighlightOffIcon />}>
          Drop
        </Button>
      )}

      <SplitButton
        {...mainCallToActionButton}
        secondaryActionOptionList={secondaryCallToActionButtons}
      />
    </Stack>
  );
};

CheckinActionButtons.propTypes = {
  event: PropTypes.object,
  onEnrollmentStatusChange: PropTypes.func,
};
