import { CONTENT_TYPES } from '~/app/catalog/constants';
import rql from '~/vendor/rql';

export const baseSearch = rql({
  is_inline: false,
  content_type: {
    $out: [
      CONTENT_TYPES.event,
      CONTENT_TYPES.task,
      CONTENT_TYPES.scheduled_track,
      CONTENT_TYPES.question,
      CONTENT_TYPES.text_question,
      CONTENT_TYPES.multiple_choice_question,
    ],
  },
});
