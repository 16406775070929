import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import propTypes from '~/services/prop-types';
import Text from '~/app/shared/components/Text';
import { size } from 'lodash-es';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 20px;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
`;

const ChildrenContainer = styled.div`
  display: flex;
`;

const Pad = styled.div`
  display: table-cell;
  width: auto;
`;

const BulkActionsBar = ({ selectedItems, getText, showActionText, children }) => {
  const actionText = getText
    ? getText(selectedItems)
    : `${selectedItems.length} item(s) selected on all pages`;

  return (
    <Container>
      {showActionText && size(selectedItems) > 0 && (
        <InnerContainer>
          <Text size="h4">{actionText}</Text>
        </InnerContainer>
      )}
      <Pad />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

BulkActionsBar.defaultProps = {
  showActionText: true,
};

BulkActionsBar.propTypes = {
  selectedItems: PropTypes.array,
  children: propTypes.anyChildren,
  getText: PropTypes.func,
  showActionText: PropTypes.bool,
};

export default BulkActionsBar;
