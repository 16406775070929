import {
  Widget,
  WidgetContent,
  WidgetContentList,
  WidgetContentListItem,
  WidgetLinkAction,
} from '~/common/components/widget';
import { mapRoute } from '~/services/requests';
import { get, map, size } from 'lodash-es';

import {
  ContentItemWidgetLoadingContent,
  ContentItemWidgetRow,
} from '../components/BaseContentItemAsyncWidget';
import { useContentItemWidgetQuery } from '../hooks/useContentItemWidgetQuery';

import { baseSearch } from './utils';

type TopContentWidgetProps = {
  hideOnEmpty?: boolean;
};

export function TopContentWidget(props: TopContentWidgetProps) {
  const { hideOnEmpty = false } = props;

  const search = 'ordering(-total_assignments)&status=published';
  const querySearch = `${baseSearch}&${search}`;

  const { data, isLoading } = useContentItemWidgetQuery(querySearch);

  const results = get(data, 'results', []);
  const resultsTotal = get(data, 'total', 0);
  const displayResults = !isLoading && size(results) > 0;

  const viewAllUrl = `${mapRoute('unifiedCatalogList')}?${search}`;

  if (hideOnEmpty && (isLoading || !displayResults)) {
    return null;
  }

  return (
    <Widget
      title="Most Consumed Content"
      tooltip="Content with the highest consumption rates"
      action={
        resultsTotal > 3 ? (
          <WidgetLinkAction label={`View all ${resultsTotal}`} url={viewAllUrl} />
        ) : null
      }
    >
      <WidgetContent>
        <WidgetContentList>
          {displayResults ? (
            map(results, (contentItem) => (
              <WidgetContentListItem key={contentItem.id}>
                <ContentItemWidgetRow contentItem={contentItem} />
              </WidgetContentListItem>
            ))
          ) : (
            <ContentItemWidgetLoadingContent animate={isLoading} />
          )}
        </WidgetContentList>
      </WidgetContent>
    </Widget>
  );
}
