import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Button from '~/app/shared/components/Button';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING, STATUS_LOADING_MORE } from '~/app/shared/constants';
import { isEmpty, map } from 'lodash-es';

import ListItem from './ListItem';

const AttendeesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NoAttendees = styled.div`
  margin-top: 50px;
  margin-bottom: 25px;
  text-align: center;
`;

const NextPageContainer = styled.div`
  margin: 16px 0 16px;
  display: flex;
  justify-content: center;
`;

const SessionAttendeeList = ({
  session,
  enrollments,
  refreshEnrollments,
  getEnrollmentsStatus,
  hasNextPage,
  loadMoreEnrollments,
}) => {
  const isLoading = getEnrollmentsStatus === STATUS_LOADING;
  const isLoadingMore = getEnrollmentsStatus === STATUS_LOADING_MORE;

  if (isEmpty(session.enrollments)) {
    return <NoAttendees>Nobody has enrolled yet.</NoAttendees>;
  }

  if (isEmpty(enrollments) && getEnrollmentsStatus === STATUS_DONE) {
    return <NoAttendees>No attendees found.</NoAttendees>;
  }

  return (
    <div>
      <AttendeesList>
        {isLoading && <Loading />}
        {!isLoading &&
          map(enrollments, (enrollment) => (
            <ListItem
              key={enrollment.id}
              session={session}
              enrollment={enrollment}
              refreshEnrollments={refreshEnrollments}
            />
          ))}
      </AttendeesList>
      <NextPageContainer>
        {isLoadingMore && <Loading hasMargin={false} />}
        {hasNextPage && !isLoadingMore && (
          <Button size="small" onClick={loadMoreEnrollments}>
            Load more
          </Button>
        )}
      </NextPageContainer>
    </div>
  );
};

SessionAttendeeList.propTypes = {
  session: PropTypes.object,
  enrollments: PropTypes.array,
  refreshEnrollments: PropTypes.func,
  loadMoreEnrollments: PropTypes.func,
  getEnrollmentsStatus: PropTypes.string,
  hasNextPage: PropTypes.bool,
};

export default SessionAttendeeList;
