import { CONTENT_TYPES } from '~/app/catalog/constants';
import {
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
} from '~/app/event-shared/constants';
import { Event } from '~/app/event/interfaces';
import { Assignment, ContentItem } from '~/app/shared-content-item/interfaces';
import { User } from '~/app/shared/components/types';
import { get, includes, indexOf, isEmpty, map } from 'lodash-es';

export const shouldShowAttendees = (user: User, content: Event): boolean => {
  const {
    assignment,
    enrollment,
    facilitators,
    hide_enrollees: hideEnrollees,
    hide_attendees: hideAttendees,
  } = content;

  const facilitatorsIds = map(facilitators, 'user.id');

  const isUserFacilitator = indexOf(facilitatorsIds, user.id) > -1;

  const isUserAttending =
    includes([ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE], enrollment?.status) ||
    assignment?.user?.id === user.id;

  if (isUserAttending || isUserFacilitator) {
    return true;
  }

  return !(hideAttendees || hideEnrollees);
};

/**
 * Returns if a given content item, depending on its state, can have its completion
 * manually toggled by the assignee while consuming the content
 *
 * @param contentItem
 * @param assignment
 * @returns boolean
 */
export const isContentItemCompletable = (
  contentItem: ContentItem,
  assignment?: Assignment
): boolean => {
  if (isEmpty(contentItem)) return false;

  if (
    includes(
      [
        CONTENT_TYPES.eventtype,
        CONTENT_TYPES.event,
        CONTENT_TYPES.assessment,
        CONTENT_TYPES.scheduled_track,
        CONTENT_TYPES.track,
        CONTENT_TYPES.codelab,
        CONTENT_TYPES.course,
        CONTENT_TYPES.question,
        CONTENT_TYPES.text_question,
        CONTENT_TYPES.multiple_choice_question,
      ],
      contentItem.content_type
    )
  )
    return false;

  // Current usage is not receiving this field yet
  if (
    CONTENT_TYPES.video === contentItem.content_type &&
    get(contentItem, 'fraction_watched_for_completion') !== 0
  )
    return false;

  if (isEmpty(assignment)) return false;

  if (includes(['completed'], get(assignment, 'state'))) return false;

  return true;
};

/**
 * Returns if the completion checkbox for a given content item should be enabled or not
 *
 * @param contentItem
 * @param assignment
 * @returns boolean
 */
export const shouldEnableToggleCompletionCheckbox = (
  contentItem: ContentItem,
  assignment?: Assignment
): boolean => {
  if (isEmpty(contentItem)) return false;

  if (
    includes(
      [
        CONTENT_TYPES.eventtype,
        CONTENT_TYPES.event,
        CONTENT_TYPES.assessment,
        CONTENT_TYPES.scheduled_track,
        CONTENT_TYPES.track,
        CONTENT_TYPES.codelab,
        CONTENT_TYPES.course,
      ],
      contentItem.content_type
    )
  )
    return false;

  // Current usage is not receiving this field yet
  if (
    CONTENT_TYPES.video === contentItem.content_type &&
    get(contentItem, 'fraction_watched_for_completion') !== 0
  )
    return false;

  if (!assignment) return false;

  return true;
};

/**
 * Returns the tooltip text that should be rendered by the completion checkbox in
 * track item cards, which depend on the content item type and its assignment state
 *
 * @param contentItem
 * @param assignment
 * @returns The tooltip text
 */
export const getCompletionCheckboxTooltip = (
  contentItem: ContentItem,
  assignment?: Assignment
): string => {
  if (isEmpty(contentItem)) return '';

  if (includes([CONTENT_TYPES.eventtype, CONTENT_TYPES.event], contentItem.content_type))
    return 'You need to attend to an Event to complete';

  if (
    includes(
      [
        CONTENT_TYPES.assessment,
        CONTENT_TYPES.scheduled_track,
        CONTENT_TYPES.track,
        CONTENT_TYPES.codelab,
        CONTENT_TYPES.course,
      ],
      contentItem.content_type
    )
  )
    return 'Review this Content to Complete it';

  // Current usage is not receiving this field yet
  if (
    CONTENT_TYPES.video === contentItem.content_type &&
    get(contentItem, 'fraction_watched_for_completion') !== 0
  )
    return 'You need to watch this content in order to complete it';

  if (isEmpty(assignment)) return 'You are not assigned to this content';

  if (includes(['completed'], get(assignment, 'state'))) return 'Mark as incomplete';

  return 'Mark as complete';
};
