import { useState, useEffect, useMemo } from 'react';
import Chart from 'react-google-charts';
import { useParams } from 'react-router-dom';

import ChartPagination from '~/app/charts/components/ChartPagination';
import { getLastPageItemIndex } from '~/app/charts/services';
import { IdParam } from '~/common/types';
import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import colors from '~/services/colors';
import { slice, size, values, omit, map, isEmpty, toString, max } from 'lodash-es';
import { deepPurple, teal } from '@mui/material/colors';
import { Typography } from '~/common/components/Typography';
import { Box } from '@mui/material';

const GRAPH_PAGE_SIZE = 4;

type ConsumedContentByQuarter = {
  quarter: number;
  year: number;
  assignments_created_count: number;
  assignments_completed_count: number;
};

function fillMissingQuarters(data: ConsumedContentByQuarter[]) {
  const [firstQuarter, firstQuarterYear] = [data[0]?.quarter ?? 0, data[0]?.year ?? 0];
  const [lastQuarter, lastQuarterYear] = [data.at(-1)?.quarter ?? 0, data.at(-1)?.year ?? 0];

  const filledData = [] as ConsumedContentByQuarter[];
  let dataCounter = 0;

  for (let year = firstQuarterYear; year <= lastQuarterYear; year += 1) {
    for (let quarter = 1; quarter <= 4; quarter += 1) {
      if (year === firstQuarterYear && quarter < firstQuarter) {
        // Starting condition
        quarter = firstQuarter;
      }

      if (year === lastQuarterYear && quarter > lastQuarter) {
        break;
      }

      if (data[dataCounter]?.quarter === quarter && data[dataCounter]?.year === year) {
        filledData.push(data[dataCounter]);
        dataCounter += 1;
      } else {
        filledData.push({
          quarter,
          year,
          assignments_completed_count: 0,
          assignments_created_count: 0,
        });
      }
    }
  }

  return filledData;
}

// TODO refactor component
// Asana ticket: https://app.asana.com/0/0/1203714434965545/f
export const ConsumedContentWidget = () => {
  const { id: userId } = useParams<IdParam>();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [fetchConsumedContent, { data }] = useEntities(
    actions.userData.retrieveConsumedContentList,
    null
  ) as [
    (id: string) => void,
    {
      data: ConsumedContentByQuarter[];
    },
  ];

  useEffect(() => {
    fetchConsumedContent(toString(userId));
    // Explicitly ignore fetchConsumedContent on useEffects deps list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  let slicedPatchedData: (string | number)[][] = [];

  const filledData = useMemo(() => {
    if (size(data) === 0) {
      return [];
    }

    return fillMissingQuarters(data);
  }, [data]);

  useEffect(() => {
    if (size(filledData) === 0) {
      return;
    }

    const initialPageIndex = max([filledData.length - GRAPH_PAGE_SIZE, 0]) as number;
    setCurrentPageIndex(initialPageIndex);
  }, [filledData]);

  const patchedData = map(filledData, (datum) => {
    const formattedQuarter = { ...datum, quarter: `${datum.year} Q${datum.quarter}` };
    return values(omit(formattedQuarter, ['id', 'year']));
  });

  const lastPageItemIndex = getLastPageItemIndex(patchedData, 'quarter', currentPageIndex);

  slicedPatchedData = [
    ['Quarter', 'Assigned', 'Completed'],
    ...slice(patchedData, currentPageIndex, currentPageIndex + 4),
  ];

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom sx={{ color: colors.neutral900 }}>
        Consumed Content
      </Typography>
      {isEmpty(data) ? (
        <Typography variant="h6" gutterBottom sx={{ color: colors.neutral900 }}>
          No data available.
        </Typography>
      ) : (
        <>
          <Chart
            chartType="LineChart"
            data={slicedPatchedData}
            width="100%"
            height="100%"
            loader={<div>Loading Chart</div>}
            options={{
              chartArea: { width: '90%' },
              hAxis: {
                gridlines: {
                  color: 'transparent',
                },
              },
              animation: {
                duration: 500,
                easing: 'out',
              },
              legend: {
                position: 'bottom',
                alignment: 'center',
              },
              gridlines: {
                color: 'none',
              },
              series: {
                0: { color: deepPurple[500] },
                1: { color: teal[500] },
              },
            }}
          />
          <ChartPagination
            direction="row"
            pageOffset={currentPageIndex}
            intervalData={patchedData}
            lastPageItemIndex={lastPageItemIndex}
            handleIncreasePageOffset={() => setCurrentPageIndex(currentPageIndex + 1)}
            handleDecreasePageOffset={() => setCurrentPageIndex(currentPageIndex - 1)}
          />
        </>
      )}
    </Box>
  );
};
