import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useScrollToURLAnchorLocation, useCurrentUser } from '~/app/shared/hooks';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import ContentTopbarPage from '~/app/shared-layouts/ContentTopbarPage';
import ContentSidebarItem from '~/app/shared-layouts/ContentTopbarPage/ContentSidebarItem';
import Loading from '~/app/shared/components/Loading';
import SectionInfoPanel from '~/app/shared/components/NewContentForm/SectionInfoPanel';
import { IS_ADMIN_PERMISSION } from '~/app/shared/permissions';
import { get, includes, map, sortBy } from 'lodash-es';

import ToggleListSection from './ToggleListSection';

const Content = styled.div`
  width: 680px;
  margin: 0 auto;
  padding: 20px 0 40px;

  > * + * {
    margin-top: 20px;
  }
`;

const ApplicationToggles = () => {
  const { permissions, home_page_url_path: homePath } = useCurrentUser();

  const history = useHistory();
  const { hash } = useLocation();
  const urlHash = hash?.replace('#', '');

  const [loading, setLoading] = useState(false);
  const [toggles, setToggles] = useState({});

  const url = `${ApiURLs['api_settings:application_toggles']()}?context=system`;

  useEffect(() => {
    setLoading(true);
    fetchURL(url)
      .then((response) => {
        setToggles(response.data);

        setLoading(false);

        return response;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useScrollToURLAnchorLocation();

  if (!includes(permissions, IS_ADMIN_PERMISSION)) {
    toast.error("You're not allowed to access this page.");
    return <Redirect to={homePath} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <ContentTopbarPage
      renderSidebarContent={() =>
        map(
          [
            { key: 'general_toggles', label: 'General Toggles' },
            { key: 'development_toggles', label: 'Development Toggles' },
          ],
          ({ key, label }) => (
            <ContentSidebarItem
              key={key}
              selected={urlHash === key}
              label={label}
              onClick={() => {
                history.replace({ hash: `#${key}` });
              }}
            />
          )
        )
      }
    >
      <Content>
        <SectionInfoPanel
          title="Application toggles"
          infoText="This page contains a list and short description of all the toggles available in the settings so it can be easily used as a reference sheet. It also lists the development toggles in a separate section."
        />
        <ToggleListSection
          sectionKey="general_toggles"
          sectionTitle="General Toggles"
          toggleList={sortBy(Object.entries(get(toggles, 'general_toggles', {})), '0')}
        />
        <ToggleListSection
          sectionKey="development_toggles"
          sectionTitle="Development Toggles"
          toggleList={sortBy(Object.entries(get(toggles, 'development_toggles', {})), '0')}
        />
      </Content>
    </ContentTopbarPage>
  );
};

export default ApplicationToggles;
