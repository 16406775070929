/* eslint-disable lodash/prefer-lodash-method */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';

import actions from '~/app/entities/actions';
import { segmentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { useRQLFiltersContext } from '~/app/rosters/RQLFiltersContext';
import { STATUS_DONE } from '~/app/shared/constants';
import {
  isEmpty,
  noop,
  filter,
  get,
  includes,
  keys,
  head,
  slice,
  concat,
  isEqual,
  has,
  flatMap,
  find,
  isNil,
} from 'lodash-es';
import rql from '~/vendor/rql';

import { rqlExpressionToObject, rqlListToObject, escapeURL } from './utils';

export const useCurrentSegment = () => {
  const { public_id: segmentPublicId } = useParams();
  return useSelector((state) => {
    if (!segmentPublicId) return {};
    if (has(state.entities.segments, segmentPublicId))
      return state.entities.segments[segmentPublicId];
    return find(
      flatMap(state.entities.dashboardSegmentsData, (content_type) => content_type.segments),
      (segment) => segment.public_id === segmentPublicId
    );
  });
};

export const useRetrieveSegment = () => {
  const [fetch, { data, status }] = useEntities(actions.segment.retrieveDetails, null, {
    schema: segmentSchema,
  });

  // Optimization: if the segment is already in the state, get
  // it from there instead of fetching it again from the DB
  const currentSegment = useCurrentSegment();
  if (!isEmpty(currentSegment)) {
    return {
      fetch: noop,
      data: currentSegment,
      status: STATUS_DONE,
    };
  }

  return {
    fetch,
    data,
    status,
  };
};

export const useUpdateSegment = () => {
  const [update, { status, error }] = useEntities(
    actions.segment.updateSubmit,
    ({ status }) => {
      if (status === STATUS_DONE) {
        toast.success('Segment successfully saved.');
      }
    },
    {
      schema: segmentSchema,
    }
  );
  return { update, status, error };
};

export const useRemoveSegment = (onStatusChange) => {
  const [remove, { status, error }] = useEntities(actions.segment.remove, onStatusChange, {
    schema: segmentSchema,
  });
  return { remove, status, error };
};

export const useListSegments = () => {
  const [
    fetchSegments,
    { data: segmentsData, status: segmentsStatus, count: segmentsCount },
    loadMoreSegments,
  ] = useEntities(actions.segment.retrieveList, null, {
    schema: [segmentSchema],
    loadMoreAction: actions.segment.retrieveListLoadMore,
  });

  return {
    fetch: fetchSegments,
    data: segmentsData,
    count: segmentsCount,
    status: segmentsStatus,
    fetchMore: loadMoreSegments,
  };
};

export const useDashboardSegments = () => {
  const [
    fetchDashboardSegments,
    {
      data: dashboardSegments,
      status: dashboardSegmentsStatus,
      statusCode: dashboardSegmentsStatusCode,
    },
  ] = useEntities(actions.segment.retrieveDashboard, null, { key: 'dashboardSegments' });
  return {
    fetchDashboardSegments,
    dashboardSegmentsStatus,
    dashboardSegmentsStatusCode,
    dashboardSegments,
  };
};

const getFilterName = (filter) => {
  return head(keys(filter));
};

// Get the list of filter in a RQL object, for example:
// {$and: [{name: 'test'}, {name: 'another'}]} => [{name: 'test'}, {name: 'another'}]
// {name: 'test'} => [{name: 'test'}]
const getFiltersFromRQLObject = (rqlObject) => {
  const logicalOperator = head(keys(rqlObject));
  if (logicalOperator !== '$and' && logicalOperator !== '$or') {
    // This means there is only one filter or none
    return isEmpty(rqlObject) ? [] : [rqlObject];
  }
  return rqlObject[logicalOperator];
};

// As the RQL parser does not have a way to ignore invalid filters is necessary
// to set an arg in the hook with the list of valid filters to remove the
// invalid filters before parsing the RQL string.
export function useRQLRouteFilters(
  defaultOrderingField,
  validFilters,
  defaultPageSize = 15,
  defaultPage = 1,
  backend = 'url', // 'url' or 'state'
  cacheKey = ''
) {
  const location = useLocation();
  const history = useHistory();
  const segment = useCurrentSegment();
  const {
    getCachedFilters,
    setCachedFilters,
    getCachedExpression,
    setCachedExpression,
    setInitialFiltersUpdated,
    publishEvent,
  } = useRQLFiltersContext();
  // The filterObj should be like this: {filters: [], ordering: 'name', page: 1, pageSize: 15}
  const [filterObj, setFilterObj] = useState(getCachedFilters(cacheKey));
  const [expression, setExpression] = useState(getCachedExpression(cacheKey));

  const updateFilterObj = (newFilterObj) => {
    if (isEqual(filterObj, newFilterObj)) return;
    setFilterObj(newFilterObj);
    if (!isEmpty(cacheKey)) setCachedFilters(cacheKey, newFilterObj);
    publishEvent(cacheKey, 'filtersChange');
  };

  const updateExpression = (newExpression) => {
    if (isEmpty(newExpression)) {
      updateFilterObj({
        filters: [],
        ordering: defaultOrderingField,
        page: defaultPage,
        pageSize: defaultPageSize,
      });
      return;
    }
    const { $ordering: orderingField, ...rqlObject } = rqlExpressionToObject(newExpression);
    const rawFilters = getFiltersFromRQLObject(rqlObject);
    const pageFilter = find(rawFilters, (filter) => getFilterName(filter) === 'page');
    const pageSizeFilter = find(rawFilters, (filter) => getFilterName(filter) === 'page_size');
    const newFilters = filter(rawFilters, (filter) =>
      includes(validFilters, getFilterName(filter))
    );
    updateFilterObj({
      filters: newFilters,
      ordering: orderingField || defaultOrderingField,
      page: get(pageFilter, 'page.$eq', defaultPage),
      pageSize: get(pageSizeFilter, 'page_size.$eq', defaultPageSize),
    });
  };

  const addFilter = (filter) => {
    const { filters } = filterObj;
    const newFilters = [...filters, filter];
    updateFilterObj({ ...filterObj, filters: newFilters });
  };

  const removeFilter = (index) => {
    const { filters } = filterObj;
    const newFilters = concat(slice(filters, 0, index), slice(filters, index + 1));
    updateFilterObj({ ...filterObj, filters: newFilters });
  };

  const updateFilter = (index, filter) => {
    const { filters } = filterObj;
    const newFilters = concat(slice(filters, 0, index), filter, slice(filters, index + 1));
    // if the expression will change, is necessary to reset the page
    if (!isEqual(rqlListToObject(filters), rqlListToObject(newFilters))) {
      updateFilterObj({ ...filterObj, filters: newFilters, page: defaultPage });
      return;
    }
    updateFilterObj({ ...filterObj, filters: newFilters });
  };

  const updateFilters = (filters) => updateFilterObj({ ...filterObj, filters });

  const updateOrdering = (fieldName) => updateFilterObj({ ...filterObj, ordering: fieldName });

  const updatePagination = (pageNumber) => updateFilterObj({ ...filterObj, page: pageNumber });

  const updatePageSize = (newPageSize) => updateFilterObj({ ...filterObj, pageSize: newPageSize });

  // If the user is accessing a segment, the filterObj is updated using the expression, otherwise, the URL is used
  useEffect(() => {
    if (!isNil(get(segment, 'expression', null))) {
      updateExpression(segment.expression);
      return;
    }
    if (backend === 'url') {
      const search = isEmpty(location.search) ? '' : location.search.slice(1); // remove the ? symbol
      updateExpression(escapeURL(search));
      if (!isEmpty(search)) {
        setInitialFiltersUpdated(cacheKey);
      }
    } else if (backend === 'state' && isEmpty(getCachedFilters(cacheKey))) {
      updateExpression('');
    }
  }, [segment?.public_id]);

  // Update the URL if the filterObj changes
  useEffect(() => {
    const rqlObject = rqlListToObject(filterObj.filters);
    const rqlExpression = rql({
      ...rqlObject,
      $ordering: filterObj.ordering,
      page: filterObj.page,
      page_size: filterObj.pageSize,
    });
    if (rqlExpression !== expression) {
      if (backend === 'url') history.replace(`${location.pathname}?${rqlExpression}`);
      setExpression(rqlExpression);
      setCachedExpression(cacheKey, rqlExpression);
      publishEvent(cacheKey, 'expressionChange', { expression: rqlExpression });
    }
  }, [filterObj.filters, filterObj.ordering, filterObj.page, filterObj.pageSize]);

  return {
    expression, // Use the expression in the useEffect to handle the filters changes
    filterObj,
    // Instead of updating everything, prefer to use specific functions for the operations
    addFilter,
    removeFilter,
    updateFilter,
    updateFilters, // Update all filters at once
    updateOrdering,
    updatePagination,
    updatePageSize,
    updateExpression,
    updateFilterObj, // Use only if you have a very specific behavior that cannot be handled by the other functions
  };
}
