import * as React from 'react';

import { WidgetCreateAction } from '~/common/components/widget';
import NewEventModal from '~/app/event-shared/components/NewEventModal';

export function CreateEventWidgetAction() {
  const [showNewEventModal, setShowNewEventModal] = React.useState(false);

  const handleDisplayNewEventModal = () => {
    setShowNewEventModal(true);
  };

  const handleCloseDisplayedModal = () => {
    setShowNewEventModal(false);
  };

  return (
    <>
      <WidgetCreateAction label="Create Event" onClick={handleDisplayNewEventModal} />

      {showNewEventModal && (
        <NewEventModal
          // @ts-expect-error fix this later
          handleClose={handleCloseDisplayedModal}
        />
      )}
    </>
  );
}
