import { CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentItemCardBody = styled(CardContent, {
  name: 'ContentItemCard',
  slot: 'body',
})`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;
  overflow: hidden;
  padding: 12px 16px 8px 16px;

  // If there is no footer
  &:last-child {
    padding-bottom: 12px;
  }
`;
