import PropTypes from 'prop-types';
import { useEffect } from 'react';

import actions from '~/app/entities/actions';
import { userSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE } from '~/app/shared/constants';
import { some } from 'lodash-es';

import { BaseImpactSection } from './shared/BaseImpactSection';

export const OfficeHourSection = ({ userId }) => {
  const [
    fetchImpactedUsers,
    { count: impactedUserCount, status: fetchImpactedUsersStatus, data: impactedUsers },
  ] = useEntities(actions.userData.retrieveImpactedList, null, {
    schema: [userSchema],
  });

  const [fetchRatings, { status: fetchFeedbackStatus, data: feedbackRatings }] = useEntities(
    actions.userData.retrieveFacilitatorFeedback,
    null
  );

  const [
    fetchFeedbackList,
    {
      status: fetchFacilitatorReviewStatus,
      data: { results: feedbackList },
    },
  ] = useEntities(actions.session.feedbackList, null);

  useEffect(() => {
    fetchImpactedUsers(userId, { impact_content_type: 'office_hour' });
    fetchRatings(userId, { view_mode: 'office_hour' });
    fetchFeedbackList({
      hosts: [userId],
      is_mentorship_session: false,
    });
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    some(
      [fetchImpactedUsersStatus, fetchFeedbackStatus, fetchFacilitatorReviewStatus],
      (status) => status !== STATUS_DONE
    )
  ) {
    return <Loading />;
  }
  if (impactedUsers == 0) {
    return null;
  }
  return (
    <BaseImpactSection
      impactedUsers={impactedUsers}
      impactedUserCount={impactedUserCount}
      feedbackTitle="Office Hours Reviews"
      feedbackRatings={feedbackRatings.office_hour_ratings}
      feedbackList={feedbackList}
      title="Office Hour"
    />
  );
};

OfficeHourSection.propTypes = {
  userId: PropTypes.number.isRequired,
};
