import { isEmpty, get } from 'lodash-es';
import { Tooltip } from '@mui/material';
import { ErrorIcon } from '~/vendor/mui-icons';

export interface AssignmentTraitIconProps {
  trait:
    | 'overdue'
    | 'expired'
    | 'self_assigned'
    | 'prescribed'
    | 'suggested'
    | 'exempted'
    | 'watched';
}

const AssignmentTraitIcon = ({ trait }: AssignmentTraitIconProps) => {
  const traitIconMap = {
    overdue: {
      Icon: ErrorIcon,
      tooltipText: 'Overdue',
    },
    expired: {
      Icon: ErrorIcon,
      tooltipText: 'Expired',
    },
  };

  const traitIcon = get(traitIconMap, trait);

  if (isEmpty(traitIcon)) {
    return null;
  }

  const { Icon, tooltipText }: any = traitIcon;

  return (
    <Tooltip title={tooltipText} disableHoverListener={!tooltipText} arrow>
      <Icon />
    </Tooltip>
  );
};

export default AssignmentTraitIcon;
