import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Item, ItemList } from '~/app/inputs/components/ListSelector';
import StaticDateRangeInput from '~/app/inputs/components/StaticDateRangeInput';
import { isEmpty, keyBy, map } from 'lodash-es';

const DateRangeContainer = styled.div`
  min-height: 300px;
`;

class PeriodSelector extends React.Component {
  getDateOuput = (date) => {
    const { formatDateOutput } = this.props;

    if (!date || !formatDateOutput) {
      return date;
    }
    return date.format(formatDateOutput);
  };

  handleDateChange = ([startDate, endDate]) => {
    const { dateStartParam, dateEndParam, onChange } = this.props;
    onChange({
      [dateStartParam]: this.getDateOuput(startDate),
      [dateEndParam]: this.getDateOuput(endDate),
    });
  };

  handleOptionSelect = (value) => {
    const { onChange, periodParam, selectOptions, dateStartParam, dateEndParam } = this.props;

    const mapping = keyBy(
      map(selectOptions, (option) => ({
        [periodParam]: option.value,
      })),
      periodParam
    );
    mapping.custom = {
      [dateStartParam]: this.getDateOuput(moment()),
      [dateEndParam]: this.getDateOuput(moment().add(1, 'week')),
    };
    onChange(mapping[value]);
  };

  render = () => {
    const { selectOptions, startDate, endDate, period, limitWidth } = this.props;

    return (
      <ItemList>
        {map(selectOptions, (option) => (
          <Item
            key={option.value}
            item={{
              name: option.name,
              value: option.value,
            }}
            isChecked={period === option.value}
            onClick={this.handleOptionSelect}
            singleSelect
            limitWidth={limitWidth}
          />
        ))}
        <Item
          key="custom"
          item={{
            name: 'Custom',
            value: 'custom',
          }}
          isChecked={isEmpty(period)}
          onClick={this.handleOptionSelect}
          singleSelect
          limitWidth={limitWidth}
        />
        {isEmpty(period) && (
          <li>
            <DateRangeContainer>
              <StaticDateRangeInput value={[startDate, endDate]} onChange={this.handleDateChange} />
            </DateRangeContainer>
          </li>
        )}
      </ItemList>
    );
  };
}

PeriodSelector.propTypes = {
  periodParam: PropTypes.string,
  dateStartParam: PropTypes.string,
  dateEndParam: PropTypes.string,
  limitWidth: PropTypes.bool,

  selectOptions: PropTypes.array,

  formatDateOutput: PropTypes.string,

  period: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func,
};

export default PeriodSelector;
