import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { userSchema } from '~/app/entities/schema';
import { getDataFromState } from '~/app/entities/utils';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import UserItem from '~/app/shared/components/UserItem';
import { map } from 'lodash-es';
import './bulk-add-users-success-modal.scss';

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const UserList = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-top: 8px;
    margin-right: 8px;
  }
`;

const BulkAddUsersSuccessModal = ({ onAddMoreClick, show, handleClose, createdUsers }) => {
  if (!show) {
    return null;
  }

  return (
    <Modal
      title="Users added"
      width={window.innerWidth > 768 ? 600 : 'auto'}
      handleClose={handleClose}
    >
      <ModalBody>
        <ModalContainer>
          <Text size="h4">The following users were added:</Text>
          <UserList>
            {map(createdUsers, (user, i) => (
              <UserItem userEmail={user.email} size={32} key={i} />
            ))}
          </UserList>
        </ModalContainer>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose} color="error">
          Close
        </ModalFooterButton>
        <ModalFooterButton onClick={onAddMoreClick}>Add More</ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

BulkAddUsersSuccessModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  onAddMoreClick: PropTypes.func,

  createdUsers: PropTypes.array,
};

const mapStateToProps = (state) => {
  const createdUsers = getDataFromState('userBulkAdd', state, [userSchema]);
  return {
    createdUsers: createdUsers.data,
  };
};

export default connect(mapStateToProps, null)(BulkAddUsersSuccessModal);
