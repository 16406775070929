import { get, filter, isEmpty } from 'lodash-es';

const selector = (state, path, defaultValue) => {
  if (defaultValue === undefined) {
    return get(state, path);
  }

  return get(state, path, defaultValue);
};

const publicIdSelector = (state, path, publicId, defaultValue) => {
  const selectedList = get(state, path);
  const object = filter(selectedList, (item) => item.public_id === publicId);
  if (isEmpty(object) && defaultValue) {
    return defaultValue;
  }
  return object[0];
};

export const userSelector = (state, id, defaultValue) =>
  selector(state, `entities.users[${id}]`, defaultValue);

export const programSelector = (state, publicId, defaultValue) =>
  publicIdSelector(state, 'entities.programs', publicId, defaultValue);

export const eventSelector = (state, id, defaultValue) =>
  selector(state, `entities.events[${id}]`, defaultValue);

export const eventTypeSelector = (state, id, defaultValue) => {
  if (!id) return defaultValue;
  return selector(state, `entities.eventTypes[${id}]`, defaultValue);
};

export const locationSelector = (state, id, defaultValue) =>
  selector(state, `entities.locations[${id}]`, defaultValue);
