import { useQuery } from '@tanstack/react-query';

import Loading from '~/app/shared/components/Loading';
import RQLFilterBar from '~/app/filters/components/RQLFilterBar';
import { useRQLFilters } from '~/app/filters/hooks';
import { useMentorshipSessionFiltersDefinition } from '~/app/mentorship/hooks';
import { queries } from '~/queries';
import SessionFilterContent from '~/scenes/ProgramSessionsFilter/SessionFilterContent';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { isError, noop } from 'lodash-es';

const MentorshipProgramSessionList = ({ program }: { program: ContentItem }) => {
  const { public_id } = program;

  const { filters, updateFilter, resetFilters, rqlExpression } = useRQLFilters({
    initialFiltersState: {},
    initialOrdering: 'name',
  });

  const { filters: barFilters, moreFilters } = useMentorshipSessionFiltersDefinition({
    filters,
    updateFilter,
  });

  const {
    isLoading,
    isError: hasError,
    data,
    error,
  } = useQuery(queries.mentorship_program.sessions(public_id, rqlExpression));

  if (isLoading) {
    return <Loading />;
  }

  if (hasError && isError(error)) {
    return <span>Error: {error.message} </span>;
  }
  const sessionsCount = data.length;
  const handleSessionDelete = noop; // TODO
  const loadMoreSessions = noop; // TODO
  const setNumberOfColumns = noop; // TODO

  return (
    <>
      <RQLFilterBar filters={barFilters} moreFilters={moreFilters} onClearAll={resetFilters} />
      <SessionFilterContent
        isLoading={isLoading}
        sessions={data}
        program={program}
        availableSessionsCount={sessionsCount}
        onEnrollmentDelete={handleSessionDelete}
        fetchMoreSessions={loadMoreSessions}
        setNumberOfColumns={setNumberOfColumns}
      />
    </>
  );
};

export default MentorshipProgramSessionList;
