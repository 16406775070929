import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import InfoBox from '~/app/shared/components/InfoBox';
import { useLocalStorage } from '~/app/shared/hooks';

export const PersistentlyDismissableInfoBox = ({ localStorageKey, ...props }) => {
  const [show, setShow] = useState(true);
  const [closedInfo, setClosedInfo] = useLocalStorage(localStorageKey);

  useEffect(() => {
    if (closedInfo) {
      setShow(false);
    }
  }, [show, closedInfo]);

  const hideBox = () => {
    setClosedInfo(true);
    setShow(false);
  };

  if (!show) {
    return null;
  }

  return <InfoBox onClick={hideBox} {...props} />;
};

PersistentlyDismissableInfoBox.propTypes = {
  localStorageKey: PropTypes.string,
};
