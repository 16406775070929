import PropTypes from 'prop-types';
import React from 'react';

import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import Loading from '~/app/shared/components/Loading';

// This component could have a better naming and API

// This component could have a better naming and API
const LoadMoreButton = ({ ...props }) => {
  const { isLoading, show, ...rest } = props;

  if (isLoading) {
    return <Loading />;
  }

  if (!show) {
    return null;
  }

  return (
    <Button startIcon={<Icon name="double" />} {...rest}>
      More
    </Button>
  );
};

LoadMoreButton.propTypes = {
  isLoading: PropTypes.bool,
  show: PropTypes.bool,
};

export default LoadMoreButton;
