import PropTypes from 'prop-types';
import React from 'react';

import Modal from '~/app/shared/components/Modal';
import ScheduledTrackEnrollModalContent from '~/app/tracks/components/ScheduledTrackEnrollModalContent';

const ScheduledTrackEnrollModal = ({ content, handleClose, handleAssign }) => {
  return (
    <Modal
      handleClose={handleClose}
      title="Confirm enrollment"
      width={window.innerWidth > 1024 ? 680 : null}
      height={window.innerWidth > 1024 ? '600px' : 80}
    >
      <ScheduledTrackEnrollModalContent
        content={content}
        handleAssign={handleAssign}
        tableMinWidth={650}
      />
    </Modal>
  );
};

ScheduledTrackEnrollModal.propTypes = {
  content: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAssign: PropTypes.func.isRequired,
};

export default ScheduledTrackEnrollModal;
