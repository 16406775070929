import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { queries } from '~/queries';
import { map } from 'lodash-es';

import AutocompleteField from '../AutocompleteField';

interface ImageOptionType {
  label: string;
  value: string | number;
  image: string;
}

const BadgeSelectField = (props) => {
  const [options, setOptions] = useState<ImageOptionType[]>([]);

  const { isLoading } = useQuery({
    ...queries.badges.list(),
    onSuccess: (data: any) => {
      setOptions(
        map(data.results, (row) => ({
          value: row.id,
          image: row.image,
          label: row.name,
        }))
      );
    },
  });

  return <AutocompleteField options={options} loading={isLoading} {...props} />;
};

BadgeSelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default BadgeSelectField;
