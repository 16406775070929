import { useQuery } from '@tanstack/react-query';

import { FetchDataProps } from '~/app/data-fetching/hooks';
import QueryDynamicTable from '~/app/shared/components/DynamicTable/QueryDynamicTable';
import Loading from '~/app/shared/components/Loading';
import {
  DynamicFilter,
  DynamicFilterBackend,
  FetchFilterOptions,
  RosterAction,
} from '~/app/shared/components/types';
import {
  GridCellParams,
  GridColumnVisibilityModel,
  GridColumns,
  GridPinnedColumns,
} from '@mui/x-data-grid-pro';
import { TableSelectionMode } from '../constants';

interface QueryItemPickerProps {
  query: (filters) => FetchDataProps;
  queryOptions?: string;
  filtersQuery: (options) => FetchDataProps;
  fetchFiltersOptions?: FetchFilterOptions;
  defaultOrdering: string;
  columns: GridColumns;
  pageSize?: number;
  rowsPerPageOptions?: number[];
  actions?: RosterAction[];
  topBarActions?: (RosterAction | RosterAction[])[];
  backend?: DynamicFilterBackend;
  filterBarInputWidth?: string;
  disabledItemsIds?: string[] | number[];
  initialFiltersConfig?: Record<string, any>[];
  transformFilters?: (dynamicFilters: DynamicFilter[]) => DynamicFilter[]; // In some use cases, it is necessary to customize the filters.
  isCellEditable?: (params: GridCellParams) => boolean;
  processRowUpdate?: (
    newRow: Record<string, any>,
    oldRow: Record<string, any>
  ) => Promise<Record<string, any>> | Record<string, any>; // Using any here, because it is the ro
  onProcessRowUpdateError?: (error: any) => void; // This is the same type of the DataGridProps
  disableColumnMenu?: boolean;
  enableSelectAll?: boolean;
  selectionMode?: TableSelectionMode;
  cacheKey?: string;
  pinnedColumns?: GridPinnedColumns;
  columnVisibilityModel?: GridColumnVisibilityModel;
}
const QueryItemPicker = ({
  query,
  queryOptions = '',
  filtersQuery,
  fetchFiltersOptions = {},
  defaultOrdering,
  columns,
  pageSize = 5,
  rowsPerPageOptions = [5, 15, 25, 50],
  actions = [],
  topBarActions = [],
  filterBarInputWidth = '192px',
  disabledItemsIds,
  initialFiltersConfig,
  transformFilters = (dynamicFilters: DynamicFilter[]): DynamicFilter[] => dynamicFilters,
  isCellEditable,
  processRowUpdate,
  onProcessRowUpdateError,
  disableColumnMenu = false,
  enableSelectAll = false,
  selectionMode = TableSelectionMode.single,
  cacheKey = '',
  backend = 'state',
  pinnedColumns,
  columnVisibilityModel,
}: QueryItemPickerProps): React.ReactElement => {
  const { data: filters, isLoading: filtersLoading } = useQuery(filtersQuery(fetchFiltersOptions));

  if (filtersLoading) return <Loading />;
  return (
    <QueryDynamicTable
      initialFiltersConfig={initialFiltersConfig}
      query={query}
      queryOptions={queryOptions}
      dynamicFilters={transformFilters(filters as DynamicFilter[])}
      defaultOrdering={defaultOrdering}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      actions={actions}
      topBarActions={topBarActions}
      backend={backend}
      filterBarInputWidth={filterBarInputWidth}
      disabledItemsIds={disabledItemsIds}
      isCellEditable={isCellEditable}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={onProcessRowUpdateError}
      disableColumnMenu={disableColumnMenu}
      enableSelectAll={enableSelectAll}
      selectionMode={selectionMode}
      cacheKey={cacheKey}
      pinnedColumns={pinnedColumns}
      columnVisibilityModel={columnVisibilityModel}
    />
  );
};

export default QueryItemPicker;
