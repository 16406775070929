import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import FilterPills from '~/app/navigation/components/FilterPills';
import colors from '~/services/colors';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Icon from '~/app/shared/components/Icon';
import { size } from 'lodash-es';

const FilterInfoWrapper = styled.div`
  color: ${colors.neutral600};
  display: grid;
  grid-template-columns: auto 1fr 100px;
  margin-bottom: 16px;
`;

const FilterCountContainer = styled.span`
  margin-right: 12px;
`;

const HideSectionButton = styled.div`
  width: 100px;
  text-align: end;
  cursor: pointer;

  ${Icon} {
    margin-left: 8px;
  }
`;

const FilterPageContentManager = ({
  selecteds,
  onChange,
  pills,
  initialFilters,
  notListAttributes,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClearFilters = () => {
    onChange(initialFilters);
  };

  const filterCount = size(pills);
  const filterCountText = `${filterCount} filter${filterCount > 1 ? `s` : ``} applied`;

  if (filterCount === 0) return null;

  return (
    <>
      <FilterInfoWrapper>
        <FilterCountContainer>{filterCountText}</FilterCountContainer>
        <div>
          <ButtonLink variant="error" onClick={handleClearFilters}>
            Clear all filters
          </ButtonLink>
        </div>
        <HideSectionButton onClick={handleToggleClick}>
          Hide
          <Icon name={isOpen ? 'up' : 'down'} width={12} height={12} color={colors.neutral600} />
        </HideSectionButton>
      </FilterInfoWrapper>
      {isOpen && (
        <FilterPills
          selecteds={selecteds}
          onChange={onChange}
          pills={pills}
          notListAttributes={notListAttributes}
        />
      )}
    </>
  );
};

FilterPageContentManager.propTypes = {
  selecteds: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  pills: PropTypes.array,
  initialFilters: PropTypes.object,
  notListAttributes: PropTypes.array,
};

export default FilterPageContentManager;
