import { useRef } from 'react';
import { useHistory } from 'react-router';

import { CONTENT_TYPES, LEARNING_TYPES } from '~/app/catalog/constants';
import { useComposableTrackCtx } from '~/features/tracks/contexts/ComposableTrackCtx';
import TrackContentPage from '~/features/tracks/pages/TrackContentPage';
import TrackDetailPage from '~/scenes/TrackPage/TrackDetailPage';
import { mapRoute } from '~/services/requests';
import {
  usePublicIdFromURL,
  useQueryParams,
  useRouterQueryUtils,
  useSetDetailedObject,
} from '~/app/shared/hooks';
import { ContentItemListenerContext } from '~/app/stand-alone-shared/context-providers/ContentItemListenerContext';
import { get } from 'lodash-es';

export interface TrackPageContainerProps {
  track: any;
  entityType:
    | typeof LEARNING_TYPES.assessments
    | typeof LEARNING_TYPES.scheduled_tracks
    | typeof LEARNING_TYPES.tracks;
}

function TrackPageContainer(props: TrackPageContainerProps) {
  const { track, entityType } = props;

  const { publicId: trackId } = usePublicIdFromURL();

  const history = useHistory();

  const { addToQueryString } = useRouterQueryUtils();

  const { page: pageParam } = useQueryParams();
  const page = pageParam ? Number(pageParam) : 0;

  const { onRefreshRootTrack } = useComposableTrackCtx();

  const isAssessment = get(track, 'content_type') === CONTENT_TYPES.assessment;

  const listenerValue = useRef({
    onChange: (props: any) => {
      const goToFirstPage = get(props, 'goToFirstPage');
      onRefreshRootTrack();
      if (goToFirstPage) {
        addToQueryString({ page: 1 });
      }
    },
    // onDelete is called on DeleteStandAloneModal when a track item is deleted from the track
    // consumption page, or when a track/scheduled track/assessment is deleted from its detail page.
    onDelete: (props: any) => {
      const content = get(props, 'content');
      if (content?.public_id === trackId) {
        const isScheduleTrack = get(content, 'content_type') === CONTENT_TYPES.scheduled_track;
        const parentTrackPublicIdAndSlug = get(content, 'original_track.public_id_and_slug');

        history.push(
          isScheduleTrack && parentTrackPublicIdAndSlug
            ? mapRoute('trackDetails', {
                public_id_and_slug: parentTrackPublicIdAndSlug,
              })
            : mapRoute('unifiedCatalogList')
        );
      } else {
        window.location.reload();
      }
    },
  });

  useSetDetailedObject({ id: trackId }, entityType, []);

  const contentProps = {
    ...(isAssessment && { assessment: track }),
    ...(track.content_type === CONTENT_TYPES.scheduled_track && { scheduledTrack: track }),
    ...(track.content_type === CONTENT_TYPES.track && { track }),
  };

  return (
    <ContentItemListenerContext.Provider value={listenerValue.current}>
      {page === 0 ? <TrackDetailPage {...contentProps} /> : <TrackContentPage {...contentProps} />}
    </ContentItemListenerContext.Provider>
  );
}

export default TrackPageContainer;
