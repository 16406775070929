import { mapRoute } from '~/services/requests';
import { useOriginRoute, useLabels } from '~/app/shared/hooks';
import { toLower } from 'lodash-es';

import { useCreateChannel } from '../api/mutations';
import ChannelFormPage from '../components/ChannelFormPage';
import { useChannelRoutes } from '../hooks/useChannelRoutes';

const ChannelCreate = () => {
  const catalogRoute = `${mapRoute('unifiedCatalogList')}`;
  const backRoute = useOriginRoute(catalogRoute);
  const { create: channeCreateRoute } = useChannelRoutes();

  // TODO: use channel settings
  const parentSettingsFlat = {};

  const initialValuesParams = {
    settings: parentSettingsFlat,
  };

  const onSubmit = useCreateChannel();
  const { label_channel: labelChannel, label_channel_plural: labelChannelPlural } = useLabels();

  return (
    <ChannelFormPage
      pageTitle={`Create ${labelChannel}`}
      topBarActionName={`Creating ${labelChannel}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelChannelPlural,
          link: catalogRoute,
        },
        {
          label: `New ${toLower(labelChannel)}`,
          link: channeCreateRoute,
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default ChannelCreate;
