import queryString from 'query-string';
import { useEffect, useState } from 'react';

import { useFetchDataList } from '~/app/data-fetching/hooks';
import { queries } from '~/queries';
import { isUUID } from '~/services/utils';
import {
  size,
  noop,
  get,
  isArray,
  isNil,
  isInteger,
  toNumber,
  map,
  toString,
  includes,
  filter,
  isEmpty,
  constant,
  sortBy,
  join,
} from 'lodash-es';
import { Autocomplete, CircularProgress, TextField, createFilterOptions } from '@mui/material';

export interface Option {
  value: number | string;
  name?: string;
}

export type InputValue = string | number | (string | number)[] | null;

type MuiInputValue = Option | Option[] | null;

interface SelectProps {
  label?: string;
  value?: InputValue;
  onChange?: (newValue: InputValue) => void;
  multiple?: boolean;
  options?: Option[];
  sortOptions?: boolean;
  sortByOption?: ((option: Option) => string) | string;
  getOptionLabel?: (option: Option) => string;
  // Used to fetch the options, if the query key is not defined in the name, the "list" value will be used.
  // E.g.: tags => tags.list | users.departments => users.departments
  queryName?: string;
  // Extra params to be used to fetch the options. You could need to set an ordering value or another extra filtering. E.g. has_directs for teammate filter
  queryParams?: Record<string, string | number | boolean>;
  // Useful for queries that return all data in the first request. E.g. title and department endpoints.
  fetchQueryOnce?: boolean;
  limitTags?: number;
  width?: number | string;
  required?: boolean;
}

const getKey = (item: string | number | null) => {
  if (isNil(item)) return '';
  if (isInteger(toNumber(item))) return 'include_ids';
  if (isUUID(item)) return 'include_public_ids';
  return 'include_slugs';
};

const getIncludeKey = (value: InputValue): string => {
  if (isArray(value)) {
    return getKey(get(value, '0', null));
  }

  return getKey(value);
};

const getSearchQuery = (
  value: InputValue,
  queryParams: Record<string, string | number | boolean> = {},
  searchText = '',
  fetchQueryOnce = false
) => {
  // TanStack Query only performs a new request if the current key does not have a cache stored,
  // keeping it static will make the lib only fetch the first time and cache de result, the other
  // time that the data is accessed, it will be just the cache.
  if (fetchQueryOnce) return 'view_mode=filter_options';
  const includeKey = getIncludeKey(value);
  const baseQuery = {
    ...queryParams,
    ...(searchText ? { q: searchText } : {}),
    view_mode: 'filter_options',
    page_size: 10,
  };
  if (isNil(value) || isEmpty(value)) return queryString.stringify(baseQuery);
  if (isArray(value))
    return queryString.stringify({
      ...baseQuery,
      page_size: 10 + size(value),
      [includeKey]: join(value, ','),
    });
  return queryString.stringify({ ...baseQuery, page_size: 11, [includeKey]: value });
};

const Select = ({
  label,
  value = null,
  onChange = noop,
  multiple = false,
  options: initialOptions = [],
  sortOptions = false,
  sortByOption = 'name',
  getOptionLabel = (option: Option) => option.name || toString(option.value),
  queryName: queryNameProp = '',
  queryParams = {},
  fetchQueryOnce = false,
  limitTags = 1,
  width = 200,
  required = false,
}: SelectProps) => {
  const [shouldFetchOptions, setShouldFetchOptions] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [textFieldValue, setTextFieldValue] = useState('');
  const [options, setOptions] = useState(initialOptions);
  const [open, setOpen] = useState(false);

  const queryName = includes(queryNameProp, '.') ? queryNameProp : `${queryNameProp}.list`;
  const queryFn = get(queries, queryName);

  const queryConfig = queryFn
    ? queryFn(getSearchQuery(value, queryParams, searchText, fetchQueryOnce))
    : undefined;

  const useQueryHook = queryConfig
    ? useFetchDataList
    : constant({ isLoading: false, data: undefined });
  // The variable 'shouldFetchOptions' is enough to handle the search changes, it is not necessary to call any fetch function.
  // The TanStack hook will detect the change and reload the data
  const { isLoading: isLoadingQuery, data } = useQueryHook({
    ...queryConfig,
    enabled: shouldFetchOptions,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
  const isLoading = Boolean(isLoadingQuery && shouldFetchOptions);

  const getSelectedValue = () => {
    const selectedOption = isArray(value)
      ? filter(options, (option) => includes(value, option.value))
      : filter(options, (option) => value === option.value);
    return multiple ? selectedOption : get(selectedOption, '0', null);
  };

  const selectedValue = getSelectedValue();

  // When using the backend to list the options, the options are already filtered in the response,
  // but in the case of the fetch once mode, any new requests are made, so it is not possible to
  // filter using the backend, so in these cases the autocomplete filtering function is used.
  const filterOptions = fetchQueryOnce ? createFilterOptions() : (x: any) => x;
  const filterOptionsState = {
    inputValue: textFieldValue,
    getOptionLabel,
  };

  const getOptions = () => {
    if (sortOptions) return filterOptions(sortBy(options, sortByOption), filterOptionsState);
    return filterOptions(options, filterOptionsState);
  };

  /*
   * In what situations should this component search for options?
   * - The first time the component is rendered and there is at least one value to be used in the include key.
   * - The first time the user opens the component and the options have not been fetched yet.
   * - When the user edits the search value.
   */

  useEffect(() => {
    if (
      !shouldFetchOptions &&
      ((isArray(value) && !isEmpty(value)) || (!isArray(value) && value))
    ) {
      setShouldFetchOptions(true);
    }
  }, [value, shouldFetchOptions]);

  useEffect(() => {
    if (!shouldFetchOptions && open && queryName) {
      setShouldFetchOptions(true);
    }
  }, [open, queryName, shouldFetchOptions]);

  useEffect(() => {
    if (!isNil(data) && !isLoading) {
      setOptions(map(data as Option[], (option) => ({ ...option, value: toString(option.value) })));
    }
  }, [data, isLoading]);

  // Debounce
  // Based on the TanStack example:
  // https://github.com/TanStack/table/blob/f79330e80e5efa3c3c8680a3afa4cba193dad015/examples/react/filters/src/main.tsx#L400-L427
  // Discussion about debounce: https://github.com/TanStack/query/issues/293
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchText(textFieldValue);
    }, 500);
    return () => clearTimeout(timeout);
  }, [textFieldValue]);

  return (
    <Autocomplete
      sx={{
        width,
        minWidth: 100,
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': { minWidth: open ? 30 : 0 },
        '& .MuiAutocomplete-tag': {
          maxWidth: open
            ? 'calc(100% - 4px)'
            : multiple && isArray(value) && size(value) === 1 // Avoid breaking the line if the name of the selected option is too big
              ? 'calc(100% - 16px)'
              : multiple && isArray(value) && size(value) > 1 // Is necessary more space for the +{more} information
                ? 'calc(100% - 40px)'
                : 'calc(100% - 4px)', // Default value for closed and empty or not multiple options
        },
      }}
      value={selectedValue}
      size="small"
      open={open}
      openOnFocus
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false);
        setTextFieldValue('');
      }}
      onChange={(_, rawNewValue: MuiInputValue) => {
        const newValue = isArray(rawNewValue)
          ? map(rawNewValue, 'value')
          : isNil(rawNewValue)
            ? null
            : rawNewValue.value;
        onChange(newValue);
        setTextFieldValue('');
      }}
      disableCloseOnSelect={Boolean(multiple)}
      getOptionLabel={getOptionLabel}
      options={getOptions()}
      filterOptions={(options) => options}
      limitTags={limitTags}
      multiple={multiple}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          onChange={(event) => event?.target && setTextFieldValue(toString(event?.target.value))}
          variant="outlined"
          size="small"
          label={label}
          sx={{
            // Fixes the loading icon position. size='small' currently breaks the loading position
            '& > .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
              paddingRight: '39px',
            },
            '& .MuiAutocomplete-root.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
              paddingRight: '39px',
            },
          }}
          fullWidth
          InputLabelProps={{
            shrink: true, // https://mui.com/material-ui/react-text-field/#shrink
          }}
          inputProps={{
            ...params.inputProps,
            value: textFieldValue && open ? textFieldValue : get(params, 'inputProps.value', ''),
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default Select;
