import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';

import { Button } from '~/app/shared';
import { find, map, noop } from 'lodash-es';

const DashboardCompositeFilter = ({ children, value, onChange, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectValue, setSelectValue] = useState('');
  const open = Boolean(anchorEl);

  useEffect(() => {
    const newValue = find(options, (option) => option.value === value); // || options[0];
    setSelectValue(newValue);
    setAnchorEl(null);
  }, [value]);

  const InputProps = useMemo(() => {
    const handleClickBtn = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleChange = (e, value) => {
      if (!value) {
        setAnchorEl(null);
        return;
      }
      setSelectValue(value);
      onChange(value);
      setAnchorEl(null);
    };

    if (options && options.length > 0) {
      return {
        startAdornment: (
          <InputAdornment position="start">
            <Button
              variant="text"
              id="basic-button"
              aria-controls={open ? 'filter-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClickBtn}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {selectValue?.label || ''}
            </Button>
            <Menu
              id="filter-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => handleChange()}
              MenuListProps={{
                'aria-labelledby': 'filter-button',
              }}
            >
              {map(options, (option) => (
                <MenuItem key={option.value} onClick={(e) => handleChange(e, option)}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </InputAdornment>
        ),
      };
    }

    return null;
  }, [options, open, anchorEl, selectValue]);

  return (
    <>
      {children({
        InputProps,
        filterOptionValue: selectValue,
      })}
    </>
  );
};

DashboardCompositeFilter.defaultProps = {
  onChange: noop,
};

DashboardCompositeFilter.propTypes = {
  options: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default DashboardCompositeFilter;
