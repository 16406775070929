import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { useContentTypeRoutes } from '~/app/catalog/hooks';
import colors from '~/services/colors';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { useLabels } from '~/app/shared/hooks';
import { toLower, map } from 'lodash-es';

const TracksList = styled.ul`
  margin: 12px 0px;
  padding: 0;

  > li {
    margin: 0 20px;
    color: ${colors.error600};
  }
`;

const DropTrackItemWarnModal = ({ handleClose, unassign, content, tracks }) => {
  const contentTypeRoutes = useContentTypeRoutes();
  const { label_track_plural: labelTrackPlural } = useLabels();

  const handleDropTrackItems = () => {
    unassign({
      force_drop_track_item: true,
    });
    handleClose();
  };

  return (
    <Modal
      title="Assigned Tracks dependent on this Content"
      handleClose={handleClose}
      maxWidth={600}
    >
      <ModalBody>
        <Text block size="h5" colors={colors.neutral900}>
          By dropping <b>&quot;{content.name}&quot;</b>, you will also be dropping the following
          {` `}
          {toLower(labelTrackPlural)} that are dependent on its completion:
        </Text>

        <TracksList>
          {/* The field html_url is from CA-2.0 */}
          {map(tracks, (track) => (
            <li key={track.public_id}>
              <ButtonLink
                variant="error"
                target="_blank"
                route={
                  track.html_url ||
                  contentTypeRoutes[track.content_type].details({
                    public_id_and_slug: track.public_id_and_slug,
                  })
                }
              >
                {track.name}
              </ButtonLink>
            </li>
          ))}
        </TracksList>

        <Text block size="h5" colors={colors.neutral900}>
          Are you sure you want to proceed?
        </Text>
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton
          color="neutral"
          variant="text"
          type="button"
          onClick={handleClose}
          alt="Cancel"
        >
          Cancel
        </ModalFooterButton>
        <ModalFooterButton color="error" onClick={() => handleDropTrackItems()}>
          Drop anyway
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

DropTrackItemWarnModal.propTypes = {
  handleClose: PropTypes.func,
  unassign: PropTypes.func,
  content: PropTypes.object,
  tracks: PropTypes.array,
};

export default DropTrackItemWarnModal;
