import React from 'react';

import { ContentItem } from '~/app/shared-content-item/interfaces';
import TagList from '~/app/shared/components/TagList';
import OptionalChip from '~/app/tracks/components/OptionalChip';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import colors from '~/services/colors';
import { elapsetTime, sameTime } from '~/services/datetime';
import { mapRoute } from '~/services/requests';
import { slugify } from '~/services/utils';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Pill from '~/app/shared/components/Pill';
import { User } from '~/app/shared/components/types';
import { UserPopover } from '~/app/shared/components/UserAvatar';
import UserProfileInitials from '~/app/shared/components/UserAvatar/UserProfileInitials';
import { some } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Box, CardHeader, Stack } from '@mui/material';
import ContentItemHeaderSubtitle from './ContentItemHeaderSubtitle';

type ConditionalTitleComponentProps = {
  content: ContentItem;
  clickableName?: boolean;
  isRequired?: boolean;
};

type FreshnessProps = {
  content: ContentItem;
  maintainers?: User[];
};

const Freshness = (props: FreshnessProps) => {
  const { content, maintainers } = props;
  const created = content.modified_at == null || sameTime(content.created_at, content.modified_at);

  const label = created ? 'Created' : 'Updated';
  const user = created ? content.created_by : content.modified_by;
  const time = created ? content.created_at : content.modified_at;
  const showUserPopover = user && some(maintainers, (m) => m.id === user.id);

  return (
    <Box marginBottom={1} display="flex" gap={1}>
      <Typography variant="subtitle2" color="textSecondary">
        {`${label} ${elapsetTime(time)}${showUserPopover ? ' by:' : '.'}`}
      </Typography>

      {showUserPopover && (
        <UserPopover
          key={`freshness-user-${user.id}`}
          user={user}
          sendEmailCTA
          renderPopoverHoverable={
            <Pill
              variant="lighterGray"
              imageSrc={user.profile_image}
              label={user.display_name}
              labelMaxWidth={110}
              key={user.display_name}
              imagePlaceHolder={<UserProfileInitials userName={user.name} />}
            />
          }
        />
      )}
    </Box>
  );
};

const ConditionalTitleComponent = (props: ConditionalTitleComponentProps) => {
  const { content, clickableName, isRequired } = props;

  const contentTypeRoutes = useContentTypeRoutes();

  let title = (
    <Typography variant="h6" color={colors.neutral900}>
      {content.name}
    </Typography>
  );

  if (clickableName) {
    title = (
      <Box display="flex">
        <ButtonLink
          route={contentTypeRoutes[content.content_type].details({
            public_id_and_slug: content.public_id_and_slug,
          })}
          variant="primary"
        >
          {title}
        </ButtonLink>
      </Box>
    );
  }

  if (!isRequired) {
    title = (
      <Stack direction="row" spacing={1}>
        {title}
        <OptionalChip />
      </Stack>
    );
  }

  return title;
};

type ContentItemHeaderContentProps = {
  content: ContentItem;
  contextMenu: React.ReactNode;
  clickableName?: boolean;
  isRequired?: boolean;
  maintainers?: User[];
  detailContent?: React.ReactNode;
};

export const ContentItemHeaderContent = (props: ContentItemHeaderContentProps) => {
  const { content, contextMenu, clickableName, isRequired, maintainers, detailContent } = props;

  const handleGetTagUrl = (tag: string) => {
    return `${mapRoute('unifiedCatalogList')}?topics=${slugify(tag)}`;
  };

  const canDisplayFreshness =
    content && (content.created_at != null || content.modified_at != null);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      flexGrow={1}
      paddingY="20px"
      paddingX="16px"
    >
      <Stack direction="column" flexGrow={1}>
        <CardHeader
          title={
            <ConditionalTitleComponent
              content={content}
              clickableName={clickableName}
              isRequired={isRequired}
            />
          }
          sx={{ padding: 0 }}
          disableTypography
          action={contextMenu}
        />
        <ContentItemHeaderSubtitle contentItem={content} />
        {detailContent ?? null}
      </Stack>
      {canDisplayFreshness && <Freshness content={content} maintainers={maintainers} />}
      <TagList tags={content.tags} getTagUrl={handleGetTagUrl} />
    </Stack>
  );
};
