import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import EventCover from '~/app/event-shared/components/EventCover';
import { mapRoute } from '~/services/requests';
import InfoBox from '~/app/shared/components/InfoBox';
import Text from '~/app/shared/components/Text';

const Container = styled.div`
  margin-top: 16px;
`;

const EventTypeInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EventTypeCoverWrapper = styled.div`
  height: 56px;
  width: 100px;
  margin-right: 16px;
`;

const EventTypeInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const EventTypeInfoBox = ({ actionText, eventType }) => {
  const { name, upcoming_events_count: upcomingEvents, public_id_and_slug } = eventType;
  return (
    <Container>
      <InfoBox
        content={
          <EventTypeInfoWrapper>
            <EventTypeCoverWrapper>
              <EventCover eventCover={eventType.cover || eventType.default_cover} curvedBorders />
            </EventTypeCoverWrapper>
            <EventTypeInfo>
              <Text size="h4" medium>
                {name}
              </Text>
              <Text>
                {upcomingEvents !== null
                  ? `${upcomingEvents} upcoming event${upcomingEvents > 1 ? 's' : ''}`
                  : ''}
              </Text>
            </EventTypeInfo>
          </EventTypeInfoWrapper>
        }
        actionText={actionText}
        route={mapRoute('eventTypeDetails', { public_id_and_slug })}
        target="_blank"
        hideIcon
      />
    </Container>
  );
};

EventTypeInfoBox.propTypes = {
  actionText: PropTypes.string,
  eventType: PropTypes.object,
};

export default EventTypeInfoBox;
