import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import SelectField from '~/app/inputs/components/SelectField';
import TextInput from '~/app/inputs/components/TextInput';
import { ISO_UNIT_OPTIONS } from '~/app/inputs/constants';
import { isNil, noop, find, includes } from 'lodash-es';
import { Stack } from '@mui/material';

export const extractOffsetAndUnitFromDuration = (unitOptions, isoDate) => {
  // Workaroundfor Weeks, which are not supported by moment
  if (includes(isoDate, 'T')) {
    return [
      isoDate.slice(isoDate.indexOf('PT') + 2, -1),
      find(unitOptions, ({ isoIdentifier }) => isoIdentifier === isoDate.at(-1)),
    ];
  }

  return [
    isoDate.slice(isoDate.indexOf('P') + 1, -1),
    find(unitOptions, ({ isoIdentifier }) => isoIdentifier === isoDate.at(-1)),
  ];
};

const ISODatetimeOffsetInput = ({ value, label, onChange, unitOptions, coefficientOptions }) => {
  const [offsetValue, setOffsetValue] = useState(null);
  const [offsetUnit, setOffsetUnit] = useState(unitOptions[0]);
  const [offsetCoefficient, setOffsetCoefficient] = useState(coefficientOptions[0]);

  const [beforeOption, afterOption] = coefficientOptions;

  const resetState = () => {
    setOffsetValue(null);
    setOffsetUnit(unitOptions[0]);
    setOffsetCoefficient(coefficientOptions[0]);
  };

  useEffect(() => {
    if (!value) return resetState();

    const duration = moment.duration(value);
    const [newOffset, newUnit] = extractOffsetAndUnitFromDuration(unitOptions, value);
    setOffsetValue(newOffset);
    setOffsetUnit(newUnit);
    setOffsetCoefficient(duration > 0 ? afterOption : beforeOption);
  }, [value]);

  useEffect(() => {
    if (isNil(offsetValue) || isNil(offsetUnit) || isNil(offsetCoefficient)) return;

    if (offsetValue === '') {
      onChange(null);
      return;
    }

    const ISOString = `${offsetCoefficient.value > 0 ? '' : '-'}P${
      offsetUnit.type === 'time' ? 'T' : ''
    }${offsetValue}${offsetUnit.isoIdentifier}`;

    onChange(ISOString, {
      offset: offsetValue,
      unit: offsetUnit,
      coefficient: offsetCoefficient,
    });
  }, [offsetValue, offsetUnit, offsetCoefficient]);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ '> *': { flex: 2 }, '> *:first-of-type': { flex: 1 } }}
    >
      <TextInput
        placeholder={label}
        value={offsetValue || ''}
        onChange={(e) => {
          setOffsetValue(e.target.value);
        }}
        type="number"
        inputProps={{
          min: 0,
        }}
        fullWidth={false}
      />
      <SelectField
        options={unitOptions}
        input={{
          value: offsetUnit?.value,
          onChange: (value, object) => {
            setOffsetUnit(...object);
          },
        }}
      />
      <SelectField
        options={coefficientOptions}
        input={{
          value: offsetCoefficient?.value,
          onChange: (value, object) => {
            setOffsetCoefficient(...object);
          },
        }}
      />
    </Stack>
  );
};

ISODatetimeOffsetInput.defaultProps = {
  onChange: noop,
  unitOptions: ISO_UNIT_OPTIONS,
  coefficientOptions: [
    { value: -1, label: 'ago' },
    { value: 1, label: 'from now' },
  ],
};

ISODatetimeOffsetInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  unitOptions: PropTypes.array,
  coefficientOptions: PropTypes.array,
};

export default ISODatetimeOffsetInput;
