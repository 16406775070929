import PropTypes from 'prop-types';
import React from 'react';

import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';

const SegmentedControls = ({ item, isSelected, onClick, disabled, ...props }) => {
  const handleClick = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    onClick(item.value);
  };

  const isDisabled = disabled || item.disabled;

  return (
    <Button
      startIcon={item.icon && <Icon name={item.icon} />}
      variant={isSelected ? 'contained' : 'outlined'}
      disabled={isDisabled}
      // Only pass onClick to the MUI button if it was defined
      {...(onClick && { onClick: handleClick })}
      route={item.route}
      {...props}
      // Fixes button size when using icons
      size={item.icon ? 'medium' : 'small'}
      sx={{
        maxHeight: 40,

        '.MuiButton-root': {
          color: isSelected ? 'primary.contrastText' : 'primary.main',
          '&:hover, &:active, &:focus': {
            color: isSelected ? 'primary.contrastText' : 'primary.main',
          },

          ...(!item.name && {
            '.MuiButton-sizeMedium > .MuiButton-icon': {
              margin: 0,
              '& > *:first-child': {
                fontSize: 19,
              },
            },
          }),
        },
      }}
    >
      {item.name}
    </Button>
  );
};

SegmentedControls.propTypes = {
  item: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SegmentedControls;
