import { useQuery } from '@tanstack/react-query';

import { queries } from '~/queries';
import { map } from 'lodash-es';

import AutocompleteField from '../AutocompleteField';

interface MentorOption {
  value: number;
  image: string;
  label: string;
}

interface MentorSelectFieldProps {
  program_id: string;
  input: any;
  meta: any;
}

const MentorSelectField = (props: MentorSelectFieldProps) => {
  const { program_id } = props;

  const makeOptions = (data): MentorOption[] => {
    return map(data.results, (row) => ({
      value: row.mentor.id,
      image: row.mentor.profile_image,
      label: row.mentor.display_name,
    }));
  };

  const { data, isLoading } = useQuery({
    ...queries.mentorship_program.mentors(program_id, null),
    select: makeOptions,
  });

  return <AutocompleteField options={data} loading={isLoading} {...props} />;
};

export default MentorSelectField;
