import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, Fields, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import { userSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import EmailTagsInput from '~/app/inputs/components/EmailTagsInput';
import InputLabel from '~/app/inputs/components/InputLabel';
import UserSelectField from '~/app/inputs/components/UserSelectField';
import colors from '~/services/colors';
import InfoText from '~/app/shared/components/InfoText';
import Loading from '~/app/shared/components/Loading';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Form from '~/app/shared/components/OldForm';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import {
  filter,
  forEach,
  get,
  includes,
  isEmpty,
  join,
  keys,
  map,
  omit,
  toLower,
  values,
} from 'lodash-es';

const FindPeopleArea = styled.div`
  margin-top: 16px;
`;

const ErrorMessage = styled.div`
  color: ${colors.error600};
  overflow-wrap: break-word;
  margin-top: 4px;
  font-size: 14px;
  max-height: 100px;
  overflow: auto;
`;

const MAX_PAGE_SIZE = 200;

const AddPeopleModal = ({ handleClose, handleAddPeople, fieldValues }) => {
  const [emailsValidationMap, setEmailsValidationMap] = useState({});

  const emails = get(fieldValues, 'emails', []);
  const people = get(fieldValues, 'people_ids', []);

  const invalidEmails = filter(keys(emailsValidationMap), (email) => !emailsValidationMap[email]);

  const numValidPeopleBeingAdded = isEmpty(emailsValidationMap)
    ? filter(emails, ({ isValid }) => isValid !== false).length
    : filter(keys(emailsValidationMap), (email) => emailsValidationMap[email]).length;

  const getEmailsValidationMap = (usersFromEmails) => {
    // Emails from real users
    const validEmails = map(usersFromEmails, 'email');
    const validationMap = {};
    forEach(emails, ({ email }) => {
      validationMap[email] = includes(validEmails, toLower(email));
    });
    return validationMap;
  };

  const handleStatusChangeCallback = ({ status, data }) => {
    if (status === STATUS_DONE) {
      const validationMap = getEmailsValidationMap(data);
      const hasInvalidEmail = includes(values(validationMap), false);
      if (hasInvalidEmail) {
        setEmailsValidationMap(validationMap);
      } else {
        const people = get(fieldValues, 'people_ids', []);
        handleAddPeople(people, data);
      }
    }
  };

  const [fetchUsersByEmails, { status: fetchUsersByEmailsStatus }] = useEntities(
    actions.userData.retrieveList,
    handleStatusChangeCallback,
    {
      schema: [userSchema],
      key: 'usersFromEmails',
    }
  );

  const handleAddButton = () => {
    if (!isEmpty(emails)) {
      fetchUsersByEmails({ emails: join(map(emails, 'email'), ','), page_size: MAX_PAGE_SIZE });
    } else if (!isEmpty(people)) {
      handleAddPeople(people, []);
    }
  };

  return (
    <Modal title="Add people" handleClose={handleClose} width={500} overflow="initial">
      <ModalBody>
        <Form.FieldGroup margin="10">
          <Field name="people_ids" label="Find people" component={UserSelectField} multiple />
        </Form.FieldGroup>
        <Form.FieldGroup margin="15">
          <FindPeopleArea>
            <InputLabel>Add people by email</InputLabel>
            <Fields
              names={['emails']}
              component={EmailTagsInput}
              emailsValidationMap={emailsValidationMap}
              placeholder="You can paste multiple emails"
              handleTagDeletion={({ email }) =>
                setEmailsValidationMap(omit(emailsValidationMap, email))
              }
              tagLimit={MAX_PAGE_SIZE}
            />
          </FindPeopleArea>
          <InfoText
            top={4}
            bottom={8}
            content={`You may paste/type ${MAX_PAGE_SIZE} emails at a time.`}
          />
          {!isEmpty(invalidEmails) && (
            <ErrorMessage>
              No people found with the {invalidEmails.length > 1 ? 'emails' : 'email'}{' '}
              {join(invalidEmails, ', ')}
            </ErrorMessage>
          )}
        </Form.FieldGroup>
        {numValidPeopleBeingAdded > 0 && (
          <div>
            You are adding {numValidPeopleBeingAdded}{' '}
            {numValidPeopleBeingAdded > 1 ? 'people' : 'person'} to this group.
          </div>
        )}
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton color="error" onClick={handleClose}>
          Cancel
        </ModalFooterButton>
        {fetchUsersByEmailsStatus === STATUS_LOADING ? (
          <ModalFooterButton>
            <Loading size={10} hasMargin={false} color={colors.neutral0} />
          </ModalFooterButton>
        ) : (
          <ModalFooterButton onClick={handleAddButton}>Add People</ModalFooterButton>
        )}
      </ModalFooter>
    </Modal>
  );
};

AddPeopleModal.propTypes = {
  handleClose: PropTypes.func,
  handleAddPeople: PropTypes.func,
  fieldValues: PropTypes.object,
};

const formSelector = formValueSelector('addPeopleToGroupEdit');

const mapStateToProps = (state) => ({
  fieldValues: {
    people_ids: formSelector(state, 'people_ids'),
    emails: formSelector(state, 'emails'),
  },
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'addPeopleToGroupEdit',
  })(AddPeopleModal)
);
