import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, Fields, formValueSelector, reduxForm, change as changeAction } from 'redux-form';

import actions from '~/app/entities/actions';
import { useGetAllCachedOptions } from '~/app/filters/hooks';
import ModelSelect from '~/app/forms/inputs/components/ModelSelect';
import PeopleGroupsMultiselectField from '~/app/inputs/components/PeopleGroupsMultiselectField';
import UserSelectField from '~/app/inputs/components/UserSelectField/UserSelectField';
import { toast } from '~/app/notifications/components/NotificationCenter';
import {
  getMentorshipSessionSubmitBody,
  getProgramSessionSubmitBody,
} from '~/app/program/components/SessionForm/SessionFormHelpers';
import {
  ATTENDANCE_TYPE_MULTIPLE_ATTENDEES,
  ATTENDANCE_TYPE_SINGLE_ATTENDEE,
} from '~/app/program/constants';
import { FormFieldGroup } from '~/app/shared/components/Form';
import InfoBox from '~/app/shared/components/InfoBox';
import InfoText from '~/app/shared/components/InfoText';
import NewContentForm from '~/app/shared/components/NewContentForm';
import SessionEnrollmentCapSection from '~/app/shared/components/NewContentForm/sections/SessionEnrollmentCapSection';
import SessionLocationSection from '~/app/shared/components/NewContentForm/sections/SessionLocationSection';
import Form from '~/app/shared/components/OldForm';
import {
  useFormSelector,
  useLabels,
  useQueryParams,
  useFormPreventTransition,
} from '~/app/shared/hooks';
import { CREATE_SESSION_FOR_OTHERS_PERMISSION } from '~/app/shared/permissions';
import { useCalendarResourcesForSessionsEffect } from '~/app/users/hooks';
import { isNil, get, includes, union, isEmpty, toLower, replace, size } from 'lodash-es';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

const validateRequired = Form.validations.required();

const validate = (values) => {
  const errors = {};

  if (isNil(values.program_id)) {
    errors.program_id = 'This field is required.';
  }

  if (isNil(values.host_id)) {
    errors.host_id = 'This field is required.';
  }

  if (!values.allows_local && !values.allows_online) {
    errors.enrollment_methods = 'Please select at least one enrollment method below.';
  }

  if (values.attendee_id && values.allows_local && values.allows_online) {
    errors.enrollment_methods = `Sessions with pre selected mentee can only have one enrollment method.`;
  }

  if (!values.attendance_type) {
    errors.attendance_type = 'An attendance type must be selected.';
  }

  const inThePast = values.in_the_past;
  const isMultiAttendance = values.attendance_type === ATTENDANCE_TYPE_MULTIPLE_ATTENDEES;

  if (
    inThePast &&
    isMultiAttendance &&
    (!values.attendance_multiple_ids || size(values.attendance_multiple_ids) < 1)
  ) {
    errors.attendance_multiple_ids = 'Must select at least 1 attendee';
  }

  return errors;
};

const checkCanCreateForOthers = (userPermissions, selectedProgram) => {
  const programPermissions = selectedProgram ? get(selectedProgram, 'permissions') : [];

  return includes(union(userPermissions, programPermissions), CREATE_SESSION_FOR_OTHERS_PERMISSION);
};

export const SessionForm = ({
  form,
  invalid,
  handleSubmit,
  error,
  submitting,
  contentName,
  topBarActionName,
  isEdit,
  backRoute,
  breadcrumbsItemList,
  currentUser,
  touch,
  change,
  isDuplicating,
  showPrefillBox,
  prefilledValuesCleaned,
  cleanPrefilledValues,
  undoCleanPrefilledValues,
  isMentorshipSession,
}) => {
  useCalendarResourcesForSessionsEffect();
  useFormPreventTransition(form);

  const allPrograms = useGetAllCachedOptions({ keyPrefix: 'programs' });
  const programPublicId = useFormSelector(form, 'program_id');
  const selectedProgram = get(allPrograms, programPublicId);

  const { permissions: userPermissions } = currentUser;
  const { from_previous: fromPrevious } = useQueryParams();
  const {
    label_program: labelProgram,
    label_program_sessions_creator_title_single: labelProgramSessionCreatorTitleSingle,
    label_mentorship_sessions_creator_title_single: labelMentorshipSessionCreatorTitleSingle,
    label_mentorship_sessions_attendee_title_single: labelMentorshipSessionAttendeeTitleSingle,
    label_internal_groups: labelInternalGroups,
  } = useLabels();

  const fieldsValues = {
    allows_online: useFormSelector(form, 'allows_online'),
    location_id: useFormSelector(form, 'location_id'),
    is_available_to_other_locations: useFormSelector(form, 'is_available_to_other_locations'),
    groups_ids: useFormSelector(form, 'groups_ids'),
  };

  const saveAndDuplicate = useFormSelector(form, 'save_and_duplicate');
  const saveAndBulkDuplicate = useFormSelector(form, 'save_and_bulk_duplicate');

  const in_the_past = useFormSelector(form, 'in_the_past');

  const getInfoPanelText = () => {
    if (isMentorshipSession) {
      return 'Once you are a Mentor, you can create individual sessions to book time with the people at your organization.';
    }
    return 'Sessions are appointments designed to stimulate relationships between new employees and senior professionals.';
  };

  const getInfoBox = () => {
    if (!showPrefillBox) return;

    if (!prefilledValuesCleaned) {
      return (
        <InfoBox
          content={`Please notice this session has been prefilled with the data of ${
            isDuplicating ? 'an existing session' : 'your latest created session'
          }. You can clear the form values to start from scratch.`}
          actionText="Clear"
          onClick={cleanPrefilledValues}
        />
      );
    }

    return (
      <InfoBox
        content="Prefilled data was cleared."
        actionText="Prefill Again"
        onClick={undoCleanPrefilledValues}
      />
    );
  };

  const onSaveDuplicateHandler = (field) => {
    if (invalid) {
      handleSubmit();
    } else {
      change(field, true);
    }
  };

  // Get the updated value of the field after its change on onSaveDuplicateHandler
  useEffect(() => {
    if (saveAndDuplicate || saveAndBulkDuplicate) {
      handleSubmit();
    }
  }, [saveAndDuplicate, saveAndBulkDuplicate]);

  useEffect(() => {
    if (!selectedProgram || isEdit || isDuplicating) return;

    const { attendance_limit: attendanceLimit } = selectedProgram;
    const attendanceType =
      attendanceLimit === 1 ? ATTENDANCE_TYPE_SINGLE_ATTENDEE : ATTENDANCE_TYPE_MULTIPLE_ATTENDEES;
    const attendanceLimitMultiple = attendanceLimit !== 0 ? attendanceLimit : '';

    change('attendance_type', attendanceType);
    change('attendance_limit_multiple', attendanceLimitMultiple);
  }, [selectedProgram]);

  const sectionsList = [
    ...(!isMentorshipSession
      ? [
          {
            id: 'selected-program',
            label: labelProgram,
            icon: 'info',
            section: (
              <FormFieldGroup>
                <Field
                  name="program_id"
                  label={labelProgram}
                  required
                  queryName="programs"
                  component={ModelSelect}
                  validate={[validateRequired]}
                />
              </FormFieldGroup>
            ),
            sectionProps: {
              defaultOpen: !fromPrevious,
            },
          },
        ]
      : []),
    {
      id: 'people',
      label: 'People',
      icon: 'persons',
      section: (
        <>
          <Field component="input" type="hidden" name="save_and_duplicate" />
          <Field component="input" type="hidden" name="save_and_bulk_duplicate" />

          <FormFieldGroup>
            <Field
              name="host_id"
              label={
                isMentorshipSession
                  ? labelMentorshipSessionCreatorTitleSingle
                  : labelProgramSessionCreatorTitleSingle
              }
              required
              component={UserSelectField}
              validate={[validateRequired]}
              disabled={!checkCanCreateForOthers(userPermissions, selectedProgram)}
            />
            {isMentorshipSession && (
              <InfoText
                top={4}
                bottom={4}
                content={`Only people set as a ${labelMentorshipSessionCreatorTitleSingle} can be selected.
                  Admins can define who is a ${labelMentorshipSessionCreatorTitleSingle} by accessing the person's
                  profile page and editing it.`}
              />
            )}
          </FormFieldGroup>
          {isMentorshipSession && (
            <FormFieldGroup>
              <Field
                name="attendee_id"
                label={labelMentorshipSessionAttendeeTitleSingle}
                component={UserSelectField}
                placeholder={`Select the ${labelMentorshipSessionAttendeeTitleSingle} for this session`}
                required={in_the_past}
                validate={in_the_past ? [validateRequired] : undefined}
              />
              {!in_the_past && (
                <InfoText
                  top={4}
                  bottom={4}
                  content="If left blank, the session will be open to others to enroll."
                />
              )}
            </FormFieldGroup>
          )}
        </>
      ),
      sectionProps: {
        defaultOpen: !fromPrevious,
      },
    },
    {
      id: 'location',
      label: 'Location & Time',
      icon: 'calendar',
      section: <SessionLocationSection form={form} fieldValues={fieldsValues} />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'enrollment',
      label: 'Enrollment',
      icon: 'ticket',
      section: (
        <SessionEnrollmentCapSection
          fieldsValues={fieldsValues}
          form={form}
          touch={touch}
          isMentorshipSession={isMentorshipSession}
        />
      ),
      sectionProps: {
        defaultOpen: !fromPrevious,
      },
    },
  ];

  const advancedSettingsList = isMentorshipSession
    ? [
        {
          id: 'access-control',
          label: 'Access Control',
          icon: 'lock',
          section: (
            <FormFieldGroup>
              <Fields
                inputId="groups"
                label={`Restrict Session to ${toLower(labelInternalGroups)}`}
                names={['groups_ids']}
                component={PeopleGroupsMultiselectField}
                showGroups
              />
              <InfoText
                top={4}
                content={`This session will only be available for members of the selected
                  ${toLower(labelInternalGroups)}.`}
              />
            </FormFieldGroup>
          ),
          sectionProps: {
            defaultOpen: !isEmpty(fieldsValues.groups_ids),
          },
        },
      ]
    : [];

  return (
    <NewContentForm
      contentNameSingular={contentName}
      contentInfoPanelText={getInfoPanelText()}
      contentInfoBox={getInfoBox()}
      contentTitle={contentName}
      topBarActionName={topBarActionName}
      invalid={invalid}
      handleSubmit={handleSubmit}
      error={error}
      submitting={submitting}
      isEdit={isEdit}
      backRoute={backRoute}
      breadcrumbsItemList={breadcrumbsItemList}
      contentSectionsList={sectionsList}
      advancedSettingsList={advancedSettingsList}
      onSaveAndDuplicateHandler={() => onSaveDuplicateHandler('save_and_duplicate')}
      onSaveAndBulkDuplicateHandler={() => onSaveDuplicateHandler('save_and_bulk_duplicate')}
    />
  );
};

SessionForm.propTypes = {
  form: PropTypes.string,

  invalid: PropTypes.bool,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  touch: PropTypes.func,
  change: PropTypes.func,

  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
  isDuplicating: PropTypes.bool,
  showPrefillBox: PropTypes.bool,
  prefilledValuesCleaned: PropTypes.bool,
  cleanPrefilledValues: PropTypes.func,
  undoCleanPrefilledValues: PropTypes.func,
  isMentorshipSession: PropTypes.bool,

  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),

  contentName: PropTypes.string,
  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
};

const withReduxForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: false, // Needs to be false. Otherwise, fields values will not prefill in case they are inside sections with defaultOpen as false.
  validate,
  onChange: (values, dispatch, { locations, form }) => {
    const starts_at = values.starts_at;
    const tz = locations[values.location_id]?.timezone;

    if (starts_at && tz) {
      const isInThePast = moment.tz(tz).isSameOrAfter(moment.tz(replace(starts_at, 'Z', ''), tz));
      dispatch(changeAction(form, 'in_the_past', isInThePast));
      dispatch(changeAction(form, 'booking_cutoff_reminder', true));
    }
  },
  onSubmit: (
    values,
    dispatch,
    { isMentorshipSession, isEdit, form, currentUser, selectedProgram, initialValues = {} }
  ) => {
    const canCreateForOthers = checkCanCreateForOthers(currentUser.permissions, selectedProgram);

    const action = isMentorshipSession ? actions.sessionMentorship : actions.sessionProgram;
    const actionName = action[isEdit ? 'update' : 'create'].toString();

    return onSubmitActions(actionName, (values) => {
      const body = isMentorshipSession
        ? getMentorshipSessionSubmitBody(values, canCreateForOthers, currentUser)
        : getProgramSessionSubmitBody(values, canCreateForOthers, currentUser, initialValues);

      return {
        ...(initialValues.public_id && { id: initialValues.public_id }),
        key: form,
        body,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (
    result,
    dispatch,
    { onSubmitSuccessHandler, saveAndDuplicate, saveAndBulkDuplicate }
  ) => onSubmitSuccessHandler(result, saveAndDuplicate, saveAndBulkDuplicate),
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error('Submission failed. Check the flagged fields and try again.');
  },
});

const mapStateToProps = (state, { form }) => {
  const formSelector = formValueSelector(form);

  const saveAndDuplicate = formSelector(state, 'save_and_duplicate');
  const saveAndBulkDuplicate = formSelector(state, 'save_and_bulk_duplicate');

  const locations = get(state, 'entities.locations');

  return {
    saveAndDuplicate,
    saveAndBulkDuplicate,
    locations,
  };
};

const enhance = compose(connect(mapStateToProps), withReduxForm);

export default enhance(SessionForm);
