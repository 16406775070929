import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import styled from 'styled-components';

import EventTypeForm from '~/app/event-shared/components/EventForm';
import { useParentSettings } from '~/app/settings/hooks';
import ArticleForm from '~/app/article/components/ArticleForm/ArticleForm';
import { CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING, CONTENT_TYPES } from '~/app/catalog/constants';
import { useLearningTypeLabels } from '~/app/catalog/hooks';
import CodelabForm from '~/app/codelab/components/CodelabForm/CodelabForm';
import CodelabFormModal from '~/app/codelab/components/CodelabImportFormModal/CodelabImportFormModal';
import CourseForm from '~/app/course/components/CourseForm/CourseForm';
import CourseFormModal from '~/app/course/components/CourseImportFormModal/CourseImportFormModal';
import actions from '~/app/entities/actions';
import { codelabSchema, courseSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { toast } from '~/app/notifications/components/NotificationCenter';
import LinkedContentForm from '~/scenes/LinkedContentPage/LinkedContentForm';
import colors from '~/services/colors';
import Loading from '~/app/shared/components/Loading';
import Modal, { ModalBody, ModalFooter } from '~/app/shared/components/Modal';
import { STATUS_LOADING } from '~/app/shared/constants';
import { useCurrentUser, useFormSelector } from '~/app/shared/hooks';
import TaskForm from '~/app/task/components/TaskForm/TaskForm';
import { get, split } from 'lodash-es';
import VideoForm from '~/app/video/components/VideoForm/VideoForm';

const FormContainer = styled.div`
  margin: 20px 26px;
`;

const TrackCreateContentModal = ({ contentType, trackFormName, handleClose, onAdd }) => {
  const defaultSettings = useParentSettings(`${contentType}/form`, 'flat');

  const learningTypeLabelsSingle = useLearningTypeLabels();
  const contentTypeLabelSingle =
    learningTypeLabelsSingle[CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[contentType]];
  const currentUser = useCurrentUser();
  const { default_channel_id: defaultChannelId } = currentUser;

  const [fetchCodelab, { data: codelab, fetchCodelabStatus }] = useEntities(
    actions.codelab.retrieveDetails,
    null,
    {
      schema: codelabSchema,
    }
  );

  const [fetchCourse, { data: course, courseStatus }] = useEntities(
    actions.course.retrieveDetails,
    null,
    {
      schema: courseSchema,
    }
  );

  const onSubmitSuccessHandler = (result) => {
    onAdd({ selectedItems: [result] });
    handleClose();
    toast.success('Content item added successfully!');
  };

  const submitWithConfirmationHandler = ({ proceedToSubmit }) => proceedToSubmit();

  const isHiddenFormValue = useFormSelector(trackFormName, 'is_hidden');
  const groupsIDsFormValue = useFormSelector(trackFormName, 'groups_ids');
  const facilitatorsIds = useFormSelector(trackFormName, 'maintainers_ids');

  // In the creation flow, the content inherit access control policies from the Track
  const baseInitialValues = {
    is_inline: true,
    facilitators_ids: facilitatorsIds,
    is_hidden: isHiddenFormValue,
    groups_ids: groupsIDsFormValue,
    settings: defaultSettings,
    channel_id: defaultChannelId,
  };

  const formMapping = {
    [CONTENT_TYPES.eventtype]: (
      <EventTypeForm
        form="eventTypeCreateForm"
        onSubmitSuccessHandler={onSubmitSuccessHandler}
        submitWithConfirmationHandler={submitWithConfirmationHandler}
        currentUser={currentUser}
        initialValues={baseInitialValues}
        isEventType
        isModalForm
      />
    ),
    [CONTENT_TYPES.article]: (
      <ArticleForm
        form="articleCreateForm"
        onSubmitSuccessHandler={onSubmitSuccessHandler}
        initialValues={baseInitialValues}
        isModalForm
      />
    ),
    [CONTENT_TYPES.video]: (
      <VideoForm
        form="videoCreateForm"
        onSubmitSuccessHandler={onSubmitSuccessHandler}
        initialValues={baseInitialValues}
        isModalForm
      />
    ),
    [CONTENT_TYPES.codelab]: (
      <CodelabForm
        form="codelabCreateForm"
        onSubmitSuccessHandler={onSubmitSuccessHandler}
        initialValues={{ ...codelab, ...baseInitialValues }}
        isModalForm
      />
    ),
    [CONTENT_TYPES.course]: (
      <CourseForm
        form="courseCreateForm"
        onSubmitSuccessHandler={onSubmitSuccessHandler}
        initialValues={{ ...course, ...baseInitialValues }}
        isModalForm
      />
    ),
    [CONTENT_TYPES.task]: (
      <TaskForm
        form="taskCreateForm"
        onSubmitSuccessHandler={onSubmitSuccessHandler}
        initialValues={{
          duration: null,
          is_completable: true,
          ...baseInitialValues,
        }}
        isModalForm
      />
    ),
    [CONTENT_TYPES.linkedcontent]: (
      <LinkedContentForm
        form="linkedContentCreateForm"
        onSubmitSuccessHandler={onSubmitSuccessHandler}
        initialValues={baseInitialValues}
        isModalForm
      />
    ),
  };
  const formNameMapping = {
    [CONTENT_TYPES.eventtype]: 'eventTypeCreateForm',
    [CONTENT_TYPES.article]: 'articleCreateForm',
    [CONTENT_TYPES.video]: 'videoCreateForm',
    [CONTENT_TYPES.codelab]: 'codelabCreateForm',
    [CONTENT_TYPES.course]: 'courseCreateForm',
    [CONTENT_TYPES.task]: 'taskCreateForm',
    [CONTENT_TYPES.linkedcontent]: 'linkedContentCreateForm',
  };
  const formComponent = formMapping[contentType];
  const formName = formNameMapping[contentType];

  const dispatch = useDispatch();

  const handleSubmit = () => {
    return dispatch(submit(formName));
  };

  if (contentType === CONTENT_TYPES.codelab && !codelab.id) {
    return (
      <CodelabFormModal
        handleClose={() => {
          handleClose();
        }}
        handleUploadCompleted={(publicIdAndSlug) => {
          if (publicIdAndSlug) {
            fetchCodelab(get(split(publicIdAndSlug, '_'), '0'));
          }
        }}
      />
    );
  }

  if (contentType === CONTENT_TYPES.course && !course.id) {
    return (
      <CourseFormModal
        handleClose={() => {
          handleClose();
        }}
        action="Update"
        handleUploadCompleted={(publicIdAndSlug) => {
          if (publicIdAndSlug) {
            fetchCourse(get(split(publicIdAndSlug, '_'), '0'));
          }
        }}
        isModalForm
      />
    );
  }

  const isLoading =
    (CONTENT_TYPES.codelab && fetchCodelabStatus === STATUS_LOADING) ||
    (CONTENT_TYPES.course && courseStatus === STATUS_LOADING);

  return (
    <Modal
      title={`Add New ${contentTypeLabelSingle}`}
      width={window.innerWidth > 800 ? 800 : null}
      height={80}
      overflow="hidden"
      handleClose={handleClose}
      disableEscapeKeyDown
      disableCloseOnBackdropClick
    >
      <ModalBody backgroundColor={colors.neutral100} fullSize scrollable>
        <FormContainer>{isLoading ? <Loading /> : formComponent}</FormContainer>
      </ModalBody>
      <ModalFooter variant="buttons">
        <Modal.FooterButton color="error" onClick={handleClose}>
          Cancel
        </Modal.FooterButton>
        <Modal.FooterButton onClick={handleSubmit}>Create & Add</Modal.FooterButton>
      </ModalFooter>
    </Modal>
  );
};

TrackCreateContentModal.propTypes = {
  contentType: PropTypes.string,
  trackFormName: PropTypes.string,
  handleClose: PropTypes.func,
  onAdd: PropTypes.func,
};

export default TrackCreateContentModal;
