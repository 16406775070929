import { CONTENT_TYPES } from '~/app/catalog/constants';
import AssignmentTraitIcon from '~/app/content-items/components/AssignmentTraitIcon';
import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import AssignmentProgressBar from '~/app/rosters/components/AssignmentProgressBar';
import { CONTENT_TYPES_WITH_PROGRESS, TRACK_TYPES } from '~/services/contents';
import { get, includes } from 'lodash-es';
import { Button, styled } from '@mui/material';

const StyledProgressBar = styled(AssignmentProgressBar)`
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface ProgressCellProps {
  row: any;
  handleRowViewProgressClick: (row: any) => void;
}

const getContentType = (row) => {
  return get(row, 'deconstructed_content_item.content_type');
};

const getProgressable = (row) => {
  return includes(CONTENT_TYPES_WITH_PROGRESS, getContentType(row));
};

const ProgressCell = (props: ProgressCellProps) => {
  const { row, handleRowViewProgressClick } = props;

  const content_type = getContentType(row);
  const hasProgressColumn = getProgressable(row);
  const canViewProgressDetails = includes(TRACK_TYPES, content_type);

  if (!hasProgressColumn) {
    return null;
  }

  const isCourse = content_type === CONTENT_TYPES.course;

  const stateIsNotStarted = get(row, 'state') === ASSIGNMENT_STATES.not_started;
  const defaultProgressValue = isCourse && !stateIsNotStarted ? null : 0;
  return (
    <>
      <Button
        fullWidth
        sx={{ p: 0, color: 'inherit', fontWeight: 'inherit' }}
        onClick={canViewProgressDetails ? () => handleRowViewProgressClick(row) : undefined}
        disabled={!canViewProgressDetails}
        variant="text"
      >
        <StyledProgressBar
          progress={get(row, 'progress_details')}
          defaultProgressValue={defaultProgressValue}
        />
      </Button>
      {row.is_expired && <AssignmentTraitIcon trait="expired" />}
    </>
  );
};

export default ProgressCell;
