import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import colors from '~/services/colors';
import { withProductTour } from '~/services/product-tour';
import OldSvgIcon from '~/app/shared/components/SvgIcon';
import Text from '~/app/shared/components/Text';

const Container = styled.div`
  padding: ${({ padding }) => padding || 20}px;

  ${({ noBorder }) =>
    !noBorder &&
    css`
      &:not(:last-of-type) {
        border-bottom: solid 1px ${colors.neutral200};
      }
    `}
`;

const TitleContainer = styled.div`
  cursor: pointer;
  ${({ opened }) =>
    opened &&
    css`
      margin-bottom: 16px;
    `}
`;

const SvgContainer = styled.div`
  float: right;
  margin-top: 4px;
`;

const FormPanel = ({ collapsible, openDefault, title, children, padding, ...props }) => {
  const [opened, setOpened] = useState(openDefault);

  return (
    <Container padding={padding} {...props}>
      <TitleContainer opened={opened} onClick={() => setOpened((s) => !s)}>
        <Text size="h3" color={colors.neutral900}>
          {title}
        </Text>
        {collapsible && (
          <SvgContainer>
            <OldSvgIcon.Arrow
              width={16}
              height={16}
              fill={colors.neutral900}
              orientation={opened ? 'up' : 'down'}
            />
          </SvgContainer>
        )}
      </TitleContainer>
      {opened && <div>{children}</div>}
    </Container>
  );
};

FormPanel.defaultProps = {
  openDefault: true,
  collapsible: true,
};

FormPanel.propTypes = {
  title: PropTypes.string,
  openDefault: PropTypes.bool,
  collapsible: PropTypes.bool,
  padding: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default withProductTour(FormPanel);
