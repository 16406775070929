import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import colors from '~/services/colors';
import { apiPost } from '~/services/requests';
import { generateGuid } from '~/services/utils';
import Clicker from '~/app/shared/components/Clicker';
import DropDownMenu from '~/app/shared/components/DropDownMenu';
import { List } from '~/app/shared/components/DropDownMenu/DropDownMenu';
import Icon from '~/app/shared/components/Icon';
import Loading from '~/app/shared/components/Loading';
import Text from '~/app/shared/components/Text';
import {
  STATUS_DONE,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
} from '~/app/shared/constants';
import { split, forEach, keys, findIndex } from 'lodash-es';

const baseContainerStyle = css`
  display: flex;
  border: ${({ border }) => border} 2px
    ${({ hasError }) => (hasError ? colors.error600 : colors.action600)};
  border-radius: 5px;
  min-width: ${({ width }) => width || '100%'};
  min-height: ${({ height }) => height || '100%'};
`;

const ClickableContainer = styled(Clicker)`
  ${baseContainerStyle};
  justify-content: center;
`;

const PreviewContainer = styled.div`
  ${baseContainerStyle};
  justify-content: space-between;
  padding: 0 12px 0 12px;
  line-break: anywhere;
  position: relative;
`;

const FieldLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 20px 0;
`;

const FileInput = styled.input`
  display: none !important;
`;
const StyledFieldError = styled.div`
  color: ${colors.error600};
  font-size: 14px;
  margin-top: 4px;
`;

const RemoveFileButton = styled(Clicker)`
  margin-left: 16px;
`;

const ActionContainer = styled.div`
  background-color: ${colors.neutral0};
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.3);
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid ${colors.neutral200};
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 10px;
  }

  ${List} {
    border-radius: 4px;
    overflow: hidden;
    min-width: unset;
    border: 1px solid ${colors.neutral200};
  }
`;

const FileField = ({
  input,
  fileAreaText,
  uploadAreaText,
  filePath,
  acceptedFileExtensions,
  fileSizeLimit,
  showEditButton,
  setHelpTextError,
  height,
  width,
}) => {
  const [fileName, setFileName] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(STATUS_NOT_REQUESTED);
  const [inputError, setInputError] = useState(null);

  const inputGuid = generateGuid();

  const onFileInputOpen = () => {
    const inputTag = document.getElementById(inputGuid);
    inputTag.click();
  };

  const hiddenFileInput = useRef();

  const onUploadFileClick = () => {
    hiddenFileInput.current.click();
  };

  const uploadFile = async (file) => {
    setUploadStatus(STATUS_LOADING);

    const lastDotPosition = file.name.lastIndexOf('.');
    const fileExtension = file.name.slice(lastDotPosition);

    const signedURLResponse = await apiPost('api_integrations:get-signed-upload-url', null, {
      path: filePath,
      extension: fileExtension,
    });
    const { upload_data: uploadData, read_url: readUrl } = signedURLResponse.data;
    const { url, fields } = uploadData;

    const formData = new FormData();
    forEach(keys(fields), (field) => {
      formData.append(field, fields[field]);
    });

    formData.append('file', file);

    const uploadResponse = await fetch(url, { method: 'POST', body: formData });

    if (uploadResponse.status >= 200 && uploadResponse.status < 300) {
      input.onChange(readUrl);
      setUploadStatus(STATUS_DONE);
    } else {
      setUploadStatus(STATUS_ERROR);
    }
  };

  const onFileInputChange = async () => {
    const file = hiddenFileInput.current.files[0];

    setInputError(null);
    if (setHelpTextError) setHelpTextError(null);
    setFileName(file.name);

    if (fileSizeLimit && file.size > fileSizeLimit) {
      const errorMessage = "This file's size exceeds the maximum allowed.";

      if (setHelpTextError) {
        setHelpTextError(errorMessage);
      } else {
        setInputError(errorMessage);
      }

      return;
    }

    // this prop is not an array to begin with it because of the <input> attr "accept"
    const acceptedFileExtensionsList = split(acceptedFileExtensions, ',');

    const lastDotPosition = file.name.lastIndexOf('.');
    const fileExtension = file.name.slice(lastDotPosition);
    // Check if the file ext is in one the the accepted types
    if (findIndex(acceptedFileExtensionsList, (ft) => ft === fileExtension) >= 0) {
      await uploadFile(file);
    } else {
      const errorMessage = `This file extension is not supported. Please select a file with a valid extension.
      (${acceptedFileExtensionsList})`;

      if (setHelpTextError) {
        setHelpTextError(errorMessage);
      } else {
        setInputError(errorMessage);
      }
    }
  };

  const onRemoveFile = () => {
    setFileName(null);
    if (setHelpTextError) setHelpTextError(null);
    setInputError(null);
    input.onChange(null);
  };

  const ContainerComponent = fileName ? PreviewContainer : ClickableContainer;

  const mutableContainerProps = {
    onClick: !fileName ? onUploadFileClick : null,
    width,
    height,
    border: fileName ? 'solid' : 'dashed',
  };

  // Trick to make react create a new file field in order to clear the input
  const fileInputKey = fileName || new Date();

  const selectedFileAreaText = fileAreaText ? (
    fileAreaText(fileName)
  ) : (
    <Text size="h4">{fileName}</Text>
  );

  const labelContent = fileName ? selectedFileAreaText : uploadAreaText;
  return (
    <>
      <ContainerComponent {...mutableContainerProps} hasError={Boolean(inputError)}>
        <FieldLabel>
          {uploadStatus !== STATUS_LOADING && labelContent}
          {uploadStatus === STATUS_LOADING && <Loading size={8} hasMargin={false} />}
        </FieldLabel>

        {fileName && !showEditButton && (
          <RemoveFileButton onClick={onRemoveFile}>
            <Icon name="close" width={12} height={12} color={colors.neutral600} />
          </RemoveFileButton>
        )}
        {fileName && showEditButton && uploadStatus !== STATUS_LOADING && (
          <ActionContainer>
            <DropDownMenu icon="pencil" fontSize={16} buttonAriaLabel="Settings">
              <DropDownMenu.Item
                title="Replace"
                icon="zip-file-fill"
                onClick={onFileInputOpen}
                type="button"
              />
              <DropDownMenu.Item
                title="Remove"
                icon="delete"
                color={colors.error600}
                textColor={colors.error600}
                onClick={onRemoveFile}
                type="button"
              />
            </DropDownMenu>
          </ActionContainer>
        )}
        <FileInput
          id={inputGuid}
          key={fileInputKey}
          type="file"
          onChange={onFileInputChange}
          ref={hiddenFileInput}
          accept={acceptedFileExtensions}
        />
      </ContainerComponent>
      {inputError && <StyledFieldError>{inputError}</StyledFieldError>}
    </>
  );
};

FileField.propTypes = {
  input: PropTypes.object,
  fileAreaText: PropTypes.func,
  uploadAreaText: PropTypes.node,
  filePath: PropTypes.string,
  acceptedFileExtensions: PropTypes.string,
  fileSizeLimit: PropTypes.number,
  showEditButton: PropTypes.bool,
  setHelpTextError: PropTypes.func,
  height: PropTypes.string,
  width: PropTypes.string,
};

FileField.defaultProps = {
  uploadAreaText: 'Click to upload file',
  fileSizeLimit: null,
};

export default FileField;
