import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import DateTimeField from '~/app/inputs/components/DateTimeField';
import DurationField, { validateDuration } from '~/app/inputs/components/DurationField';
import LocationSelectField from '~/app/inputs/components/LocationSelectField';
import { FormFieldGroup } from '~/app/shared/components/Form';
import InfoBox from '~/app/shared/components/InfoBox';
import InfoText from '~/app/shared/components/InfoText';
import MediaPoint from '~/app/shared/components/MediaPoint';
import * as formValidations from '~/app/shared/formValidations';
import { useFormSelector, useCurrentUser } from '~/app/shared/hooks';

const validateRequired = formValidations.required();

const LocationFieldContainer = () => {
  return (
    <FormFieldGroup>
      <Field
        name="location_id"
        label="Location"
        required
        component={LocationSelectField}
        placeholder="Select the location of this session"
        validate={[validateRequired]}
      />
      <InfoText
        top={4}
        bottom={16}
        content="The system will calculate local time at other locations for online events."
      />
    </FormFieldGroup>
  );
};

const TimeslotContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DateTimeFieldContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100%;

  ${MediaPoint.Tablet} {
    width: 66%;
  }
`;

const DurationFieldContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100%;

  ${MediaPoint.Tablet} {
    width: 33%;
    padding-left: 16px;
  }
`;

const TimeslotFieldContainer = () => {
  return (
    <TimeslotContainer>
      <DateTimeFieldContainer>
        <Field
          name="starts_at"
          component={DateTimeField}
          customInputWidth="calc(100% - 30px)"
          isNewForm
        />
      </DateTimeFieldContainer>
      <DurationFieldContainer>
        <Field
          name="duration"
          label="Duration (H:MM)"
          required
          component={DurationField}
          validate={[validateRequired, validateDuration]}
        />
      </DurationFieldContainer>
    </TimeslotContainer>
  );
};

const SessionLocationSection = ({ form }) => {
  const {
    coaching_booking_closes_cutoff_minutes: coachingBookingClosesCutoffMinutes,
    coaching_booking_opens_cutoff_minutes: coachingBookingOpensCutoffMinutes,
  } = useCurrentUser();

  const in_the_past = useFormSelector(form, 'in_the_past');
  const booking_cutoff_reminder = useFormSelector(form, 'booking_cutoff_reminder');

  return (
    <>
      <LocationFieldContainer />
      <TimeslotFieldContainer />
      {in_the_past && (
        <InfoBox content="You're creating a session in the past. Be aware that notifications and calendar invites won't be sent out." />
      )}
      {booking_cutoff_reminder && (
        <InfoBox
          content={`Reminder: a person can book a session 
            ${moment.duration(Math.abs(coachingBookingOpensCutoffMinutes), 'seconds').humanize()} 
            ${coachingBookingOpensCutoffMinutes < 0 ? ' before it starts' : ' after it starts'} 
            until 
            ${moment.duration(Math.abs(coachingBookingClosesCutoffMinutes), 'seconds').humanize()} 
            ${coachingBookingClosesCutoffMinutes < 0 ? ' before it starts' : ' after it starts'}.
            `}
        />
      )}
    </>
  );
};

SessionLocationSection.propTypes = {
  form: PropTypes.string,
};

export default SessionLocationSection;
