import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

import actions from '~/app/entities/actions';
import { userProfileSchema } from '~/app/entities/schema';
import { getDataFromState } from '~/app/entities/utils';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import PageTitle from '~/app/shared/components/PageTitle';
import { STATUS_DONE } from '~/app/shared/constants';
import { useCurrentUser } from '~/app/shared/hooks';
import { IS_ADMIN_PERMISSION } from '~/app/shared/permissions';
import { includes, isEmpty, isNil } from 'lodash-es';

import UserForm from './UserForm';

const UserEdit = ({ fetchUserData, user, userRequestStatus }) => {
  const { id } = useParams();
  const { hash } = useLocation();
  const { trackActivity } = useMetrics();
  const { id: currentUserId, permissions } = useCurrentUser();

  useEffect(() => {
    fetchUserData(id);
  }, [id]);

  // This should be moved into a custom hook in the future
  useEffect(() => {
    if (userRequestStatus === STATUS_DONE && hash) {
      // eslint-disable-next-line lodash/prefer-lodash-method
      const anchor = hash.split('#').pop();
      scroller.scrollTo(anchor);
    }
  }, [userRequestStatus]);

  // Only an admin or the user itself can edit an user profile
  const isSameUser = Number.parseInt(id, 10) === Number.parseInt(currentUserId, 10);
  if (!isSameUser && !includes(permissions, IS_ADMIN_PERMISSION)) {
    const redirectionRoute = mapRoute('userProfile', { id });
    toast.error("You're not allowed to edit this user's profile.");
    return <Redirect to={redirectionRoute} />;
  }

  if (userRequestStatus !== STATUS_DONE) {
    return <Loading />;
  }

  const handleUpdateSuccess = () => {
    trackActivity(METRICS_ACTIVITIES.USER_PROFILE_EDIT);
  };

  const parseInitialValues = (user) => {
    // If the location is deleted should be removed
    if (!isEmpty(user.location) && !isNil(user.location.deleted_at)) {
      user.location = null;
      user.location_id = null;
    }
    return user;
  };

  return (
    <>
      <PageTitle title="Edit profile" />
      <UserForm
        initialValues={parseInitialValues(user)}
        handleUpdateSuccess={handleUpdateSuccess}
      />
    </>
  );
};

UserEdit.propTypes = {
  user: PropTypes.object,
  userRequestStatus: PropTypes.string,

  fetchUserData: PropTypes.func,
};

const mapStateToProps = (state) => {
  const requestState = getDataFromState('userDataEdit', state, userProfileSchema);
  return {
    user: requestState.data,
    userRequestStatus: requestState.status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserData: (id) => {
    dispatch(actions.userData.retrieveDetails('userDataEdit', id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
