import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';

import ContentDescription from '~/app/stand-alone-shared/components/ContentDescription';
import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import { queries } from '~/queries';
import colors from '~/services/colors';
import { isEmbeddedVideoUrl } from '~/services/embed';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { EmbeddedVideoPlayer } from '~/services/videos';
import { useCurrentUser } from '~/app/shared/hooks';
import { useStandaloneActions } from '~/app/stand-alone-shared/hooks';
import { get, toNumber } from 'lodash-es';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  row-gap: 24px;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  background-color: ${colors.neutral0};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
`;

const PlayerContainer = styled.div`
  width: 100%;
  height: ${(props) => props.width / 1.777}px;
  background-color: ${colors.neutral0};

  > div {
    height: 100%;
  }
`;

const VideoContent = ({ content }) => {
  const { trackActivity } = useMetrics();

  const { ref, width } = useResizeDetector();
  const isAssigned =
    Boolean(get(content, 'assignment.id')) &&
    !get(content, 'assignment.is_expired') &&
    get(content, 'assignment.state') !== ASSIGNMENT_STATES.dropped;

  const [playing, setPlaying] = useState(false);
  const playerRef = useRef(null);

  const descriptionContent = content.content_body;

  // 0 means disabled
  const fractionWatchedForCompletion = toNumber(get(content, 'fraction_watched_for_completion'));

  const { assign, updateCompletion, startCompletion, complete } = useStandaloneActions(
    content,
    LEARNING_TYPES.videos
  );

  const goToLastViewedPoint = () => {
    const progress = get(content, 'assignment.progress', 0);
    if (progress > 0) {
      playerRef.current.seekTo(progress, 'fraction');
    }
  };

  const queryClient = useQueryClient();

  const handleRefreshContentAssignment = () => {
    queryClient.invalidateQueries({
      queryKey: queries.content_items.details(content.public_id).queryKey,
    });
  };

  const handleReady = goToLastViewedPoint;

  const handleStart = () => {
    // onProgress callback is only called if playing is true as a param.
    // but if pass it statically on the component it will start playing automatically.
    setPlaying(true);

    if (!isAssigned) {
      assign({ firstAccess: true });
    } else if (!content?.assignment?.started_at) {
      startCompletion();
    } else {
      goToLastViewedPoint();
    }

    const duration = moment.duration(content.duration).asSeconds();
    trackActivity(METRICS_ACTIVITIES.VIDEO_PLAY, {
      videoId: content.id,
      duration: duration,
      absoluteOffset: duration ? get(content, 'assignment.progress', 0) / duration : 0,
    });
  };

  const handleProgress = (options) => {
    if (playing) {
      updateCompletion(options);
    }

    if (fractionWatchedForCompletion > 0 && options.played * 100 >= fractionWatchedForCompletion) {
      complete();
      handleRefreshContentAssignment();
    }
  };

  const handleEnd = () => {
    if (fractionWatchedForCompletion > 0) {
      complete();
      handleRefreshContentAssignment();
    }
  };

  const { allowed_video_url_pattern: allowedVideoUrlPattern } = useCurrentUser();

  return (
    <Container>
      <PlayerContainer ref={ref} width={width}>
        {isEmbeddedVideoUrl(content.video_url, allowedVideoUrlPattern) ? (
          <EmbeddedVideoPlayer
            url={content.video_url}
            width="100%"
            height="100%"
            allowFullScreen="true"
          />
        ) : (
          <ReactPlayer
            ref={playerRef}
            url={content.video_url}
            width="100%"
            height="100%"
            onProgress={handleProgress}
            onStart={handleStart}
            onReady={handleReady}
            onEnded={handleEnd}
            playing={playing}
            controls
            progressInterval={5000}
            config={{ file: { attributes: { poster: content.cover } } }}
          />
        )}
      </PlayerContainer>
      <DescriptionContainer>
        <ContentDescription description={descriptionContent} />
      </DescriptionContainer>
    </Container>
  );
};

VideoContent.propTypes = {
  content: PropTypes.object.isRequired,
};

export default VideoContent;
