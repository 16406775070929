import { handleActions } from 'redux-actions';

import { STATUS_LOADING, STATUS_DONE, STATUS_ERROR } from '~/app/shared/constants';

import actions from './actions';

const defaultState = {
  eventId: null,
  eventPublicId: null,
  eventStatus: STATUS_LOADING,

  resources: [],
  resourcesStatus: STATUS_LOADING,

  feedbacks: [],

  createdEventId: null,
};

export default handleActions(
  {
    [actions.eventFetchRequestSubmit]: (state) => ({
      ...state,
      eventStatus: STATUS_LOADING,
      // attendeesStatus: STATUS_LOADING,
    }),
    [actions.eventFetchRequestSuccess]: (state, action) => ({
      ...state,
      eventId: action.payload.eventId,
      eventPublicId: action.payload.eventPublicId,
      eventStatus: STATUS_DONE,
    }),
    [actions.eventFetchRequestFailure]: (state) => ({
      ...state,
      eventStatus: STATUS_ERROR,
    }),

    [actions.eventFeedbacksFetchRequestSuccess]: (state, action) => ({
      ...state,
      feedbacks: action.payload.results,
    }),

    [actions.resourcesFetchRequestSubmit]: (state) => ({
      ...state,
      resourcesStatus: STATUS_LOADING,
    }),
    [actions.resourcesFetchRequestSuccess]: (state, action) => ({
      ...state,
      resources: action.payload.results,
      resourcesStatus: STATUS_DONE,
    }),
  },
  defaultState
);
