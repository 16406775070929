import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';
import qs from 'query-string';

import { listQueryKeyFactory } from '~/app/data-fetching/utils';
import { ApiURLs, fetchURL } from '~/services/requests-base';

import { TrackBreadcrumb, TrackItem } from '../types';
import { get } from 'lodash-es';

type TrackBreadcrumbsQueryVariables = {
  parentTrackPublicId?: string;
  itemPublicId?: string;
  sectionId?: string;
};

type TrackBreadcrumbsResponse = {
  breadcrumbs: TrackBreadcrumb[];
};

export const tracks = createQueryKeys('tracks', {
  list: listQueryKeyFactory({ endpointName: 'tracks_api:list_create' }),
  descendantTrackItems: (trackPublicId: string) => {
    return {
      queryKey: [trackPublicId],
      queryFn: async () => {
        const url = ApiURLs['api_tracks:descendant_track_items'](trackPublicId);
        const res = await fetchURL(url);

        const data = get(res, 'data.results', []);

        /*
          TODO: The api response is not exactly of type TrackItem[]
        */
        return data as TrackItem[];
      },
    };
  },
  breadcrumbs: (rootTrackPublicId: string, variables: TrackBreadcrumbsQueryVariables) => {
    const { parentTrackPublicId, itemPublicId, sectionId } = variables;

    return {
      queryKey: [rootTrackPublicId, { parentTrackPublicId, itemPublicId, sectionId }],
      queryFn: async () => {
        let url = ApiURLs['api_tracks:breadcrumbs'](rootTrackPublicId);

        const queryParams = qs.stringify({
          direct_parent: parentTrackPublicId,
          item: itemPublicId,
          section: sectionId,
        });

        url = `${url}?${queryParams}`;

        const { data: response } = await fetchURL(url);
        return response as TrackBreadcrumbsResponse;
      },
    };
  },
});

export type TracksQueryKeys = inferQueryKeys<typeof tracks>;
