import { CreateContentDropdown } from '~/common/components/CreateContentDropdown';
import {
  Widget,
  WidgetContentList,
  WidgetContentListItem,
  WidgetLinkAction,
  WidgetContent,
  WidgetCreateAction,
} from '~/common/components/widget';
import { mapRoute } from '~/services/requests';
import { get, map, size } from 'lodash-es';

import { UsersWidgetRow, UsersWidgetLoadingContent } from '../components/BaseUsersAsyncWidget';
import { useUsersWidgetQuery } from '../hooks/useUsersWidgetQuery';

const searchQuery = 'my_audience=true&ordering(name)';

type MyAudienceWidgetActionProps = {
  total: number;
};

function MyAudienceWidgetAction(props: MyAudienceWidgetActionProps) {
  const { total } = props;

  if (total <= 3) {
    return (
      <CreateContentDropdown>
        {({ toggleMenu }) => <WidgetCreateAction label="Create Content" onClick={toggleMenu} />}
      </CreateContentDropdown>
    );
  }

  const viewAllUrl = `${mapRoute('peopleList')}?${searchQuery}`;

  return <WidgetLinkAction label={`View all ${total}`} url={viewAllUrl} />;
}

type MyAudienceWidgetProps = {
  hideOnEmpty?: boolean;
};

export function MyAudienceWidget(props: MyAudienceWidgetProps) {
  const { hideOnEmpty = false } = props;

  const { data, isLoading } = useUsersWidgetQuery(searchQuery);

  const results = get(data, 'results', []);
  const resultsTotal = get(data, 'total', 0);
  const displayResults = !isLoading && size(results) > 0;

  if (hideOnEmpty && (isLoading || !displayResults)) {
    return null;
  }

  return (
    <Widget
      title="My Audience"
      tooltip="People who consumed Content I created"
      action={!isLoading ? <MyAudienceWidgetAction total={resultsTotal} /> : null}
    >
      <WidgetContent>
        <WidgetContentList>
          {displayResults ? (
            map(results, (row) => (
              <WidgetContentListItem key={row.id}>
                <UsersWidgetRow user={row} />
              </WidgetContentListItem>
            ))
          ) : (
            <UsersWidgetLoadingContent animate={isLoading} />
          )}
        </WidgetContentList>
      </WidgetContent>
    </Widget>
  );
}
