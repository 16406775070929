import Select from '~/app/filters/components/inputs/Select';
import { InputValue } from '~/app/filters/components/inputs/Select/Select';
import { noop } from 'lodash-es';

interface InputProps {
  value: any;
  onChange?: (newValue: InputValue) => void;
}

interface ModelSelectProps {
  input: InputProps;
  label: string;
  queryName: string;
  required?: boolean;
}

// This select component is implemented to be wrapped by the Field component from the redux-form
const ModelSelect = ({
  input = { value: null, onChange: noop },
  label,
  queryName,
  required = false,
}: ModelSelectProps) => {
  const { value, onChange } = input;
  return (
    <Select
      label={label}
      onChange={onChange}
      value={value}
      queryName={queryName}
      required={required}
      width="100%"
    />
  );
};

export default ModelSelect;
