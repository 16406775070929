import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { ApiURLs, fetchURL } from '~/services/requests-base';

const DEFAULT_RQL_EXPRESSION = '?page=1&page_size=15';

const fetchWithRql = async (endpoint: string, public_id: string, rqlExpression?: string) => {
  const url = ApiURLs[endpoint]({ public_id });
  const finalRqlExpression = rqlExpression || DEFAULT_RQL_EXPRESSION;
  const { data: response } = await fetchURL(url + finalRqlExpression);
  return response;
};
const fetchSegment = async (public_id: string) => {
  const url = ApiURLs['api_segments:report']({ public_id });
  const { data: response } = await fetchURL(url);
  return response;
};

export const segments = createQueryKeys('segments', {
  enrollment: (public_id: string, rqlExpression: string) => ({
    queryKey: [public_id, rqlExpression],
    queryFn: () => fetchWithRql('api_segments:enrollment', public_id, rqlExpression),
  }),
  event: (public_id: string, rqlExpression: string) => ({
    queryKey: [public_id, rqlExpression],
    queryFn: () => fetchWithRql('api_segments:event', public_id, rqlExpression),
  }),
  assignment: (public_id: string, rqlExpression: string) => ({
    queryKey: [public_id, rqlExpression],
    queryFn: () => fetchWithRql('api_segments:assignment', public_id, rqlExpression),
  }),
  people: (public_id: string, rqlExpression: string) => ({
    queryKey: [public_id, rqlExpression],
    queryFn: () => fetchWithRql('api_segments:people', public_id, rqlExpression),
  }),
  report: (public_id: string) => ({
    queryKey: [public_id],
    queryFn: () => fetchSegment(public_id),
  }),
});

export type SegmentsQueryKeys = inferQueryKeys<typeof segments>;
