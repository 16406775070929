import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Clicker from '~/app/shared/components/Clicker';
import MediaPoint from '~/app/shared/components/MediaPoint';
import Pill from '~/app/shared/components/Pill';
import OldSvgIcon from '~/app/shared/components/SvgIcon';
import Text from '~/app/shared/components/Text';
import { noop } from 'lodash-es';

const IconContainer = styled(Clicker)`
  svg path {
    fill: ${({ color }) => color};
  }

  &:focus,
  &:hover {
    svg path {
      fill: ${({ hoverColor }) => hoverColor};
    }
  }
`;

const ActionContainer = styled.div`
  flex: 1 1 auto;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  * + * {
    margin-left: 16px;
  }
`;

const AlertItemLabel = (props) => <Text {...props} />;

const AlertItemField = styled.div`
  flex: 1 1 45%;
  max-width: 45%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * + * {
    margin-top: ${({ topic }) => (topic ? '4px' : '8px')};
  }

  ${MediaPoint.Tablet} {
    flex-basis: ${({ flexBasis }) => flexBasis || 'auto'};
    max-width: unset;
  }
`;

// Had to split into 2 components
//    because & + & does not work very well when you have a variant inside (prop function),
//    since styled-components will create new dynamic classnames for each variant
const AlertItemContainerBase = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  background-color: ${colors.neutral0};

  & + & {
    border-top: 1px solid ${colors.neutral100};
  }

  &:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const AlertItemContainer = styled(AlertItemContainerBase)`
  padding: ${({ topic }) => (topic ? '12px 20px' : '12px 20px 20px')};
`;

const AlertType = {
  TERM: 'term',
  TOPIC: 'topic',
};

const AlertTermValue = styled(Text)`
  max-width: 100%;
`;

const AlertTermField = ({ alert }) => (
  <AlertItemField flexBasis="55%">
    <AlertItemLabel size="h6" color={colors.neutral400}>
      Keyword
    </AlertItemLabel>
    <AlertTermValue size="h4" ellipsisOnOverflow>
      {alert.term}
    </AlertTermValue>
  </AlertItemField>
);

AlertTermField.propTypes = {
  alert: PropTypes.object,
};

const AlertTopicField = ({ alert }) => (
  <AlertItemField topic flexBasis="55%">
    <AlertItemLabel size="h6" color={colors.neutral400}>
      Tag
    </AlertItemLabel>
    <Pill size="large" label={alert.topic_name} />
  </AlertItemField>
);

AlertTopicField.propTypes = {
  alert: PropTypes.object,
};

const AlertItem = ({ alert, deleteAlert }) => {
  const alertType = alert.term ? AlertType.TERM : AlertType.TOPIC;

  return (
    <AlertItemContainer topic={alertType === AlertType.TOPIC} key={alert.id}>
      {alertType === AlertType.TERM ? (
        <AlertTermField alert={alert} />
      ) : (
        <AlertTopicField alert={alert} />
      )}

      <AlertItemField>
        <AlertItemLabel size="h6" color={colors.neutral400}>
          Frequency
        </AlertItemLabel>
        <Text size="h4">{alert.frequency_display}</Text>
      </AlertItemField>
      <ActionContainer>
        <IconContainer
          color={colors.neutral400}
          hoverColor={colors.error600}
          onClick={() => deleteAlert(alert.id)}
        >
          <OldSvgIcon.Delete width={20} height={20} />
        </IconContainer>
      </ActionContainer>
    </AlertItemContainer>
  );
};

AlertItem.propTypes = {
  alert: PropTypes.object,
  deleteAlert: PropTypes.func,
};

AlertItem.defaultProps = {
  deleteAlert: noop,
};

export default AlertItem;
