import React from 'react';

import actions from '~/app/entities/actions';
import { useLabels } from '~/app/shared/hooks';

import LazyDropdownFilter from './LazyDropdownFilter';

const ProgramFilter = (props) => {
  const { label_program: labelProgram } = useLabels();

  const getFetchExtraParams = (q, includeIds) => {
    return {
      include_ids: undefined,
      include_public_ids: includeIds,
    };
  };

  return (
    <LazyDropdownFilter
      label={labelProgram}
      actionFunc={actions.program.retrieveList}
      getFetchExtraParams={getFetchExtraParams}
      {...props}
    />
  );
};

export default ProgramFilter;
