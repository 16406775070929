import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import actions from '~/app/entities/actions';
import { surveyRelationshipSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';

const SurveyInsights = () => {
  const { id: surveyId } = useParams();

  const [getsurveyInsights] = useEntities(actions.surveyRelationship.insights, null, {
    schema: surveyRelationshipSchema,
  });

  useEffect(() => {
    getsurveyInsights(surveyId);
  }, []);

  return <div>SurveyInsights </div>;
};

export default SurveyInsights;
