import PropTypes from 'prop-types';
import React from 'react';

import actions from '~/app/entities/actions';
import Modal from '~/app/shared/components/Modal';
import TestimonialForm from '~/app/testimonials/components/TestimonialForm';
import { isEmpty } from 'lodash-es';

const TestimonialFormModal = (props) => {
  const { editingTestimonial, receiverId, receiverName, handleClose, onSuccessCallback } = props;
  const actionCreate = actions.testimonial.create.toString();
  const actionUpdate = actions.testimonial.update.toString();
  const actionName = isEmpty(editingTestimonial) ? actionCreate : actionUpdate;
  return (
    <Modal title="Write a Testimonial" handleClose={handleClose} minBodyHeight="190px" width={500}>
      <TestimonialForm
        actionName={actionName}
        editingTestimonial={editingTestimonial}
        initialValues={editingTestimonial}
        receiverId={receiverId}
        receiverName={receiverName}
        handleClose={handleClose}
        onSuccessCallback={onSuccessCallback}
      />
    </Modal>
  );
};

TestimonialFormModal.propTypes = {
  editingTestimonial: PropTypes.object,
  receiverId: PropTypes.number,
  receiverName: PropTypes.string,
  handleClose: PropTypes.func,
  onSuccessCallback: PropTypes.func,
};

TestimonialFormModal.defaultProps = {
  editingTestimonial: {},
};

export default TestimonialFormModal;
