import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { addClosedAnnouncements } from '~/services/closed-announcements';
import colors from '~/services/colors';
import Banner from '~/app/shared/components/Banner';
import Icon from '~/app/shared/components/Icon';
import Text from '~/app/shared/components/Text';
import { useCurrentUser } from '~/app/shared/hooks';
import { values } from 'lodash-es';
import ReactMarkdown from '~/vendor/react-markdown';

const ANNOUNCEMENT_LEVELS = {
  neutral: 'neutral',
  news: 'news',
  alert: 'alert',
  critical: 'critical',
};

const levelToIconMapping = {
  neutral: { name: 'info', color: colors.neutral400, backgroundColor: colors.neutral0 },
  news: {
    name: 'megaphone',
    color: colors.success100,
    backgroundColor: colors.success600,
  },
  alert: {
    name: 'bell',
    color: colors.alert100,
    backgroundColor: colors.alert600,
  },
  critical: {
    name: 'exclamation',
    color: colors.error600,
    backgroundColor: colors.error100,
  },
};

const AnnouncementIconWrapper = styled.div`
  display: flex;
  margin-right: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ level }) => levelToIconMapping[level].backgroundColor};
  align-items: center;
  justify-content: center;
`;

const AnnouncementIcon = ({ level }) => {
  return (
    <AnnouncementIconWrapper level={level}>
      <Icon
        name={levelToIconMapping[level].name}
        color={levelToIconMapping[level].color}
        width={14}
        height={14}
      />
    </AnnouncementIconWrapper>
  );
};

AnnouncementIcon.propTypes = {
  level: PropTypes.oneOf(values(ANNOUNCEMENT_LEVELS)),
};

const Announcement = ({ announcement, refreshAnnouncements }) => {
  const currentUser = useCurrentUser();

  const closeAnnouncementAndRefresh = () => {
    addClosedAnnouncements(currentUser.id, announcement.id);
    refreshAnnouncements();
  };

  return (
    <Banner
      handleClose={closeAnnouncementAndRefresh}
      variant={announcement.level}
      renderIcon={<AnnouncementIcon level={announcement.level} />}
    >
      <Text onClick={(e) => e.target.tagName === 'A' && closeAnnouncementAndRefresh()}>
        <ReactMarkdown>{announcement.description}</ReactMarkdown>
      </Text>
    </Banner>
  );
};

Announcement.propTypes = {
  announcement: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    level: PropTypes.oneOf(values(ANNOUNCEMENT_LEVELS)),
  }),
  refreshAnnouncements: PropTypes.func,
};

export default Announcement;
