import qs from 'query-string';
import * as React from 'react';
import { useHistory } from 'react-router';

import { useContentTypeRoutes } from '~/app/catalog/hooks';

import { useComposableTrackCtx } from '../contexts/ComposableTrackCtx';

type TrackStartContentOpts = {
  page?: number;
  preview?: boolean;
};

export function useTrackStartContent() {
  const { rootTrack } = useComposableTrackCtx();

  const history = useHistory();
  const contentTypeRoutes = useContentTypeRoutes();

  const handleStartTrackContent = React.useCallback(
    (opts: TrackStartContentOpts = {}) => {
      const { page = 1, preview } = opts;

      const trackContentType = rootTrack.content_type;
      const trackPublicIdAndSlug = rootTrack.public_id_and_slug;

      const url = contentTypeRoutes[trackContentType].details({
        public_id_and_slug: trackPublicIdAndSlug,
      });

      const queryParams = qs.stringify({
        page,
        preview,
      });

      history.push(`${url}?${queryParams}`);
    },
    [history, contentTypeRoutes, rootTrack]
  );

  return handleStartTrackContent;
}
