import PropTypes from 'prop-types';
import React from 'react';

import BaseFilter from '~/app/backoffice/components/Dashboard/Filters/BaseFilter';
import Autocomplete from '~/app/inputs/components/Autocomplete';
import { map, sortBy, keys, head, size, toString } from 'lodash-es';

import { rqlToInput, inputToRQL, inputToRQLSingle } from './utils';

const RQLDropdownFilter = ({
  filter,
  onChange,
  collectionIsOrdered,
  // BaseFilter
  label,
  handleRemoveFilter,
  inputWidth,
  // Autocomplete
  loading,
  options,
  disabled,
  single_select,
  ...rest
}) => {
  const handleNewSelected = single_select ? inputToRQLSingle : inputToRQL;
  const filterName = head(keys(filter));

  const sortOptions = () => {
    if (collectionIsOrdered) return options || [];
    return sortBy(options, ['name', 'label']) || [];
  };

  const getOptions = () => {
    // Some inputs are using the result of the filter_options view mode,
    // but the Autocomplete expects a label attribute that it's not available in the view mode
    // Also, if the main component is reading the options from the query string,
    // the selecteds options values will have the string type, but the server returns as integer
    // so is necessary to convert the value to string to have the same type to correctly show the selected options.
    return map(sortOptions(), (option) => ({
      ...option,
      label: option.name || option.label,
      value: toString(option.value),
    }));
  };

  const selectedItems = rqlToInput(filter[filterName]);

  const placeholder = size(selectedItems) > 0 ? '' : 'Select';

  return (
    <BaseFilter
      label={label}
      handleRemoveFilter={handleRemoveFilter}
      inputWidth={inputWidth}
      renderInput={() => (
        <Autocomplete
          label={label}
          disabled={disabled}
          value={selectedItems}
          options={getOptions()}
          onChange={(newSelecteds) => onChange({ [filterName]: handleNewSelected(newSelecteds) })}
          loading={loading}
          inputMinWidth="80px"
          placeholder={placeholder}
          multiple
          {...rest}
        />
      )}
    />
  );
};

RQLDropdownFilter.propTypes = {
  filter: PropTypes.object, // {group: {$in: [1, 2]}}
  onChange: PropTypes.func, // The onChange function will receive the field updated in the RQL Object format
  collectionIsOrdered: PropTypes.bool,
  // Base Filter
  label: PropTypes.string.isRequired,
  handleRemoveFilter: PropTypes.func,
  inputWidth: PropTypes.string,
  // Autocomplete
  loading: PropTypes.bool,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  single_select: PropTypes.bool,
};

export default RQLDropdownFilter;
