import PropTypes from 'prop-types';
import React from 'react';

import SelectField from '~/app/inputs/components/SelectField';
import { keys, map } from 'lodash-es';

const SettingSelectField = ({ input, options, disabled }) => {
  const formattedOptions = map(keys(options), (key) => ({ value: options[key], label: key }));

  return (
    <SelectField
      sx={{ maxWidth: 500 }}
      options={formattedOptions}
      input={{
        value: input.value.value || '',
        name: input.name,
        onChange: (value) => input.onChange({ ...input.value, value, inherited: false }),
      }}
      disabled={disabled}
    />
  );
};

SettingSelectField.propTypes = {
  input: PropTypes.object,
  options: PropTypes.object,
  disabled: PropTypes.bool,
};

export default SettingSelectField;
