import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { get } from 'lodash-es';

const PageTitle = (props) => {
  const { title, customerName } = props;
  let fullName = 'PlusPlus';
  if (title) {
    fullName = customerName ? `${customerName} - ${title}` : title;
  }
  return (
    <Helmet>
      <title>{`${fullName}`}</title>
    </Helmet>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  customerName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  customerName: get(state, 'user.currentUser.customer.name'),
});

export default connect(mapStateToProps, () => ({}))(PageTitle);
