import PropTypes from 'prop-types';
import React from 'react';
import { HotKeys } from 'react-hotkeys';
import styled from 'styled-components';

import colors from '~/services/colors';
import Clicker from '~/app/shared/components/Clicker';
import OpenCloseIcon from '~/app/shared/components/SelectOpenCloseIcon';

const LabelText = styled.span`
  display: inline-flex;
  width: calc(100% - ${({ iconSize = '20px' }) => iconSize});
`;

LabelText.propTypes = {
  iconSize: PropTypes.string,
};

const LabelClicker = styled(Clicker)`
  font-size: 16px;
  font-weight: 500;
  ${({ disabled }) => disabled && 'opacity: 0.1;'}
  ${({ active }) => active && `color: ${colors.emphasis600}`}
`;

export const Container = ({ children, ...rest }) => (
  <HotKeys
    keyMap={{ toggleDropdown: ['enter', 'space'], closeDropdown: 'esc' }}
    handlers={{ toggleDropdown: rest.onClick, closeDropdown: rest.onClose }}
  >
    <LabelClicker {...rest}>{children}</LabelClicker>
  </HotKeys>
);

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const Label = (props) => {
  const { open, active, children, disabled, dropdownControls } = props;

  return (
    <Container
      open={open}
      active={active}
      disabled={disabled}
      onClick={dropdownControls.toggle}
      onClose={dropdownControls.close}
    >
      <LabelText iconSize="14px">{children} </LabelText>
      <OpenCloseIcon open={open} />
    </Container>
  );
};

Label.propTypes = {
  children: PropTypes.string,
  open: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  dropdownControls: PropTypes.object,
};

Label.Container = Container;
Label.LabelClicker = LabelClicker;

export default Label;
