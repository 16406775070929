import * as PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Text from '~/app/shared/components/Text';
import ContentDescription from '~/app/stand-alone-shared/components/ContentDescription';

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
`;

const DescriptionHeading = styled.div`
  padding: 20px 0 0 20px;
`;

const DescriptionContent = ({ content, heading }) => {
  const description = content.content_body;

  if (!description) return null;

  return (
    <Container>
      {heading && (
        <DescriptionHeading>
          <Text medium>{heading}</Text>
        </DescriptionHeading>
      )}
      <ContentDescription description={description} />
    </Container>
  );
};

DescriptionContent.propTypes = {
  content: PropTypes.object.isRequired,
  heading: PropTypes.string,
};

export default DescriptionContent;
