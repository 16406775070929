import { useSelector } from 'react-redux';

import { Assignment } from '~/app/shared-content-item/interfaces';
import { get, filter, orderBy, last } from 'lodash-es';

export const useLatestContentItemAssignment = (contentId: number) => {
  // Find all assignments for the content item
  // Order by created_at Date
  // Return the most recent
  return useSelector((state) =>
    last(
      orderBy(
        filter(
          get(state, 'entities.assignments'),
          (assignment: Assignment) => assignment.content_item_id === contentId
        ),
        ['created_at']
      )
    )
  );
};
