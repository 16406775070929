import PropTypes from 'prop-types';
import React from 'react';

import { LEARNING_TYPES } from '~/app/catalog/constants';
import { useLearningTypeLabels } from '~/app/catalog/hooks';
import Text from '~/app/shared/components/Text';
import { toLower } from 'lodash-es';

import BaseTaskConverterModal from './BaseTaskConverterModal';

const ConvertToArticleModal = ({ content, handleClose }) => {
  const learningTypeLabels = useLearningTypeLabels();
  const labelArticle = learningTypeLabels[LEARNING_TYPES.articles];
  const labelTask = learningTypeLabels[LEARNING_TYPES.tasks];

  return (
    <BaseTaskConverterModal
      content={content}
      handleClose={handleClose}
      title={`Convert to ${labelArticle}`}
      modalBodyContent={
        <>
          <Text block>
            You&#39;re about to convert &quot;{content.name}&quot; from an {toLower(labelTask)} to a{' '}
            {toLower(labelArticle)}.
          </Text>
          <Text block>
            A {toLower(labelArticle)} can be converted back into an {toLower(labelTask)}.
          </Text>
        </>
      }
    />
  );
};

ConvertToArticleModal.propTypes = {
  content: PropTypes.object,
  handleClose: PropTypes.func,
};

export default ConvertToArticleModal;
