import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import BaseFilter from '~/app/backoffice/components/Dashboard/Filters/BaseFilter';
import CompositeTextInput from '~/app/inputs/components/CompositeTextInput';
import { useDebounce } from '~/app/shared/hooks';
import { isNil, keys, head, isObject, join, isEmpty, includes, filter as lfilter } from 'lodash-es';

import { filterInputSx } from '../styles';

import { getInOutValue } from './utils';

// {$ilike: 'text'} => {op: 'contains', value: 'text'}
const rqlToInput = (filter, defaultOperator) => {
  const parseNotOperator = (expression) => {
    const op = head(keys(expression));
    if (op === '$ilike') return { op: 'notcontains', value: expression[op] };
    if (op === '$regex') return { op: 'notmatches', value: expression[op] };
    return { op: 'eq', value: op };
  };

  const parseFilter = (expression) => {
    if (isNil(expression)) return { op: defaultOperator, value: '' };

    const op = head(keys(expression));
    if (isNil(op)) return { op: 'contains', value: '' };

    if (op === '$not') return parseNotOperator(expression[op]);
    if (op === '$eq') return { op: 'equal', value: expression[op] };
    if (op === '$ne') return { op: 'notequal', value: expression[op] };
    if (op === '$regex') return { op: 'matches', value: expression[op] };

    if (op === '$ilike') {
      if (isObject(expression[op])) {
        const subOp = head(keys(expression[op]));
        if (subOp === 'start') return { op: 'startswith', value: expression[op][subOp] };
        return { op: 'endswith', value: expression[op][subOp] };
      }
      return { op: 'contains', value: expression[op] };
    }

    if (op === '$in') return { op: 'in', value: join(expression[op], ',') };
    if (op === '$out') return { op: 'out', value: join(expression[op], ',') };

    // if it is not a valid operator, it is because the filter uses the equals symbol (name=abc)
    return { op: 'equal', value: op };
  };
  return parseFilter(filter);
};

// {op: 'contains', value: 'text'} => {$ilike: 'text'}
const inputToRQL = ({ op, value }) => {
  const operationBuilders = {
    equal: (value) => ({ $eq: value }),
    notequal: (value) => ({ $ne: value }),
    contains: (value) => ({ $ilike: value }),
    notcontains: (value) => ({ $not: { $ilike: value } }),
    startswith: (value) => ({ $ilike: { start: value } }),
    endswith: (value) => ({ $ilike: { end: value } }),
    matches: (value) => ({ $regex: value }),
    notmatches: (value) => ({ $not: { $regex: value } }),
    in: (value) => ({ $in: getInOutValue(value) }),
    out: (value) => ({ $out: getInOutValue(value) }),
  };
  const operationBuilder = operationBuilders[op];
  return operationBuilder(value);
};

const RQLCompositeTextFilter = ({
  filter,
  onChange,
  // Base Filter
  label,
  handleRemoveFilter,
  inputWidth,
  // CompositeTextInput
  placeholder,
  disabled,
  lookups,
  defaultOperator = 'contains',
}) => {
  const filterName = head(keys(filter));
  const { op: currentSelectionFilter, value: currentTextFilter } = rqlToInput(
    filter[filterName],
    defaultOperator
  );

  const [currentFilterValue, setCurrentFilterValue] = useState({
    selection: currentSelectionFilter,
    text: currentTextFilter,
  });
  const debouncedValue = useDebounce(currentFilterValue);

  const baseOptions = [
    { value: 'contains', label: 'Contains', filterLookup: 'ilike' },
    { value: 'notcontains', label: 'Does not contain', filterLookup: 'ilike' },
    { value: 'equal', label: 'Is', filterLookup: 'eq' },
    { value: 'notequal', label: 'Is not', filterLookup: 'ne' },
    { value: 'startswith', label: 'Starts with', filterLookup: 'ilike' },
    { value: 'endswith', label: 'Ends with', filterLookup: 'ilike' },
    { value: 'matches', label: 'Matches', filterLookup: 'regex' },
    { value: 'notmatches', label: 'Does not match', filterLookup: 'regex' },
    { value: 'in', label: 'Any of', filterLookup: 'in' },
    { value: 'out', label: 'None of', filterLookup: 'out' },
  ];

  const options = isNil(lookups)
    ? baseOptions
    : lfilter(baseOptions, (item) => includes(lookups, item.filterLookup));
  if (isEmpty(lfilter(options, (item) => item.value == defaultOperator))) {
    defaultOperator = options[0].value;
  }

  useEffect(() => {
    setCurrentFilterValue({ selection: currentSelectionFilter, text: currentTextFilter });
  }, [currentTextFilter, currentSelectionFilter]);

  useEffect(() => {
    const { selection, text } = debouncedValue;
    if (text !== currentTextFilter || selection !== currentSelectionFilter) {
      // Updates url when values change
      onChange({ [filterName]: inputToRQL({ op: selection, value: text }) });
    } else if (!currentSelectionFilter) {
      // Adds default value fo selection once it's added to the filter list
      onChange({ [filterName]: inputToRQL({ op: defaultOperator, value: '' }) });
    }
  }, [debouncedValue]);

  return (
    <BaseFilter
      label={label}
      handleRemoveFilter={handleRemoveFilter}
      inputWidth={inputWidth}
      renderInput={() => (
        <CompositeTextInput
          label={label}
          aria-label={label}
          value={currentFilterValue.text}
          selection={currentFilterValue.selection}
          options={options}
          placeholder={placeholder}
          onChange={({ selection, value }) => {
            setCurrentFilterValue({ selection, text: value });
          }}
          disabled={disabled}
          sx={{
            ...(handleRemoveFilter && filterInputSx),
          }}
        />
      )}
    />
  );
};

RQLCompositeTextFilter.defaultProps = {
  placeholder: 'Search...',
  defaultOperator: 'contains',
};

RQLCompositeTextFilter.propTypes = {
  filter: PropTypes.object, // {name: {$eq: 'text'}}
  onChange: PropTypes.func, // The onChange function will receive the field updated in the RQL Object format
  // BaseFilter
  label: PropTypes.string.isRequired,
  handleRemoveFilter: PropTypes.func,
  inputWidth: PropTypes.string,
  // CompositeTextInput
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  lookups: PropTypes.array,
  defaultOperator: PropTypes.string,
};

export default RQLCompositeTextFilter;
