import { useQueryClient } from '@tanstack/react-query';

import { Session } from '~/common/types';
import { filter, get, includes, has, map, forEach } from 'lodash-es';

interface AttendanceSessionInformationProps {
  hasProgram: boolean;
  isUpcoming: boolean;
  attendanceLimit: number;
  enrollmentsCount: number;
}

export const getAttendanceSessionInfo = ({
  hasProgram,
  isUpcoming,
  attendanceLimit,
  enrollmentsCount,
}: AttendanceSessionInformationProps) => {
  const spotsLeft = attendanceLimit - enrollmentsCount;
  if (!hasProgram) return '1:1 session';
  if (attendanceLimit === 1) return '1:1 session';
  if (attendanceLimit === 0) return 'Unlimited';
  if (isUpcoming && attendanceLimit > enrollmentsCount)
    return `${spotsLeft} ${spotsLeft === 1 ? 'spot' : 'spots'} left`;
  if (isUpcoming && attendanceLimit === enrollmentsCount) return 'Fully Booked';
  if (!isUpcoming) return 'Group Session';
  return '';
};

interface CurrentUserSessionInfo {
  isUpcoming: boolean;
  attendanceLimit: number;
  isAttendee: boolean;
  isHost: boolean;
  enrollmentsCount: number;
}

export const getCurrentUserSessionInfo = ({
  isUpcoming,
  isAttendee,
  isHost,
  attendanceLimit,
  enrollmentsCount,
}: CurrentUserSessionInfo) => {
  const isBooked = enrollmentsCount > 0;
  if (isUpcoming && attendanceLimit === 1 && isBooked && isAttendee) return 'You are attending';
  if (isUpcoming && attendanceLimit === 1 && isBooked && isHost) return 'Booked';
  if (isUpcoming && attendanceLimit > 1 && isBooked && isAttendee)
    return `${
      enrollmentsCount === 1 ? 'You are attending' : `You + ${enrollmentsCount - 1} are attending`
    }`;
  if (isUpcoming && attendanceLimit > 1 && isBooked && isHost)
    return `${enrollmentsCount === 1 ? '1 person is' : `${enrollmentsCount} people are`} attending`;
  return '';
};

export const useDeleteSessionFromCache = () => {
  const queryClient = useQueryClient();
  return {
    deleteSession: (id: number) => {
      // Get all ['sessions' | 'my_upcoming', 'list'] queries
      const queryCache = queryClient.getQueryCache();
      const queries = filter(
        queryCache.getAll(),
        (query) =>
          includes(['sessions', 'my_upcoming'], get(query, 'queryKey.0')) &&
          get(query, 'queryKey.1', '') === 'list'
      );
      forEach(queries, (query) => {
        const { data: currentData } = query.state;
        // The normal query hook store in the format:
        // { results... }
        // The infinite query hook store the results following the structure:
        // { pageParams: [], pages: [{ results... }, ...] }
        // More info here: https://tanstack.com/query/v4/docs/react/guides/infinite-queries
        // So it is necessary to check which type of query we are handling to update it properly

        const removeSession = (results: Session[]) =>
          filter(results, (session) => session.id !== id || session.content_type !== 'session');

        if (has(currentData, 'pages')) {
          const pages = get(currentData, 'pages', []) as Record<string, any>[];

          query.setData({
            ...(currentData as object),
            pages: map(pages, (page) => ({
              ...page,
              results: removeSession(get(page, 'results')),
            })),
          });
        } else {
          query.setData({
            ...(currentData as object),
            results: removeSession(get(currentData, 'results', [])),
          });
        }
      });
    },
  };
};
