import PropTypes from 'prop-types';
import React from 'react';

import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import {
  ATTENDANCE_METHOD_LOCAL,
  ATTENDANCE_METHOD_ONLINE,
  STATUS_CANCELED,
} from '~/app/program/constants';
import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { get, isEmpty } from 'lodash-es';

export const UnenrollButton = ({
  attendee,
  enrollment,
  sessionId,
  children,
  refreshEnrollments,
}) => {
  const [updateEnrollment, { status: updateEnrollmentStatus }] = useEntities(
    actions.session.enrollmentUpdate,
    ({ status }) => {
      if (status === STATUS_DONE) {
        refreshEnrollments();
      }
    }
  );

  const unenrollUser = () => {
    updateEnrollment(sessionId, attendee.id, {
      status: STATUS_CANCELED,
    });
  };

  const getButtonIconName = () => {
    if (attendanceMethod === ATTENDANCE_METHOD_LOCAL) {
      return 'location';
    }

    if (attendanceMethod === ATTENDANCE_METHOD_ONLINE) {
      return 'online';
    }

    return null;
  };

  const attendanceMethod = get(enrollment, 'attendance_method');

  const text = isEmpty(children) ? 'Unenroll' : children;

  if (updateEnrollmentStatus === STATUS_LOADING) {
    return <Loading size={8} hasMargin={false} />;
  }

  return (
    <Button
      size="small"
      color="error"
      onClick={unenrollUser}
      startIcon={<Icon name={getButtonIconName()} />}
    >
      {text}
    </Button>
  );
};

UnenrollButton.propTypes = {
  children: PropTypes.string,
  sessionId: PropTypes.number,
  attendee: PropTypes.object,
  enrollment: PropTypes.object,
  refreshEnrollments: PropTypes.func,
};

export default UnenrollButton;
