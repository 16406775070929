import PropTypes from 'prop-types';
import React from 'react';

import Button from '~/app/shared/components/Button';

const DeleteButton = (props) => {
  const { children, ...rest } = props;

  return (
    <Button type="button" {...rest} color="error">
      {children || 'Save'}
    </Button>
  );
};

DeleteButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  size: PropTypes.string,
};

DeleteButton.defaultProps = {
  size: 'large',
};

export default DeleteButton;
