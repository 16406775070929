import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import MediaPoint from '~/app/shared/components/MediaPoint';

const FilterHeaderActionAreaWrapper = styled.div`
  display: flex;
  float: left;
  margin: 12px 0 0;
  width: 100%;
  justify-content: space-between;

  ${MediaPoint.Tablet} {
    float: right;
    margin: 0;
    justify-content: flex-start;
    width: auto;
  }
`;

const FilterAction = styled.div`
  & + & {
    margin-left: 16px;
  }
`;

const OldFilterHeaderActionArea = ({ children }) => (
  <FilterHeaderActionAreaWrapper>
    {React.Children.map(children, (child) => (
      <FilterAction>{child}</FilterAction>
    ))}
  </FilterHeaderActionAreaWrapper>
);

OldFilterHeaderActionArea.propTypes = {
  children: PropTypes.node,
};

export default OldFilterHeaderActionArea;
