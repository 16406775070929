import PropTypes from 'prop-types';
import React from 'react';

import { OldIcon } from '~/app/shared/components/Icon';

const RestrictedIcons = (props) => {
  const { isPrivate, hasGroups, size } = props;

  return (
    <span>
      {isPrivate && (
        <OldIcon size={size} className="mdi mdi-eye-off" aria-hidden="true" title="Hidden">
          &nbsp;
        </OldIcon>
      )}
      {hasGroups && (
        <OldIcon size={size} className="mdi mdi-lock" aria-hidden="true" title="Group restricted">
          &nbsp;
        </OldIcon>
      )}
    </span>
  );
};

RestrictedIcons.propTypes = {
  isPrivate: PropTypes.bool,
  hasGroups: PropTypes.bool,
  size: PropTypes.string,
};

export default RestrictedIcons;
