import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import * as constants from '~/app/catalog/constants';
import { useContentOnSubmitSuccessHandler } from '~/app/catalog/hooks';
import CourseForm from '~/app/course/components/CourseForm/CourseForm';
import { queries } from '~/queries';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import { INITIAL_DURATIONS } from '~/app/shared/constants';
import { useCurrentUser, useQueryParams } from '~/app/shared/hooks';
import { sortCustomTags } from '~/app/topics/services';
import { has, omit, filter, find, get, map, isEmpty } from 'lodash-es';

const getInitialValues = ({ currentUser, clonedCourse, course, defaultChannelId }) => {
  let initialValues = {
    ...course,
  };

  if (clonedCourse) {
    initialValues = omit(
      {
        ...clonedCourse,
        name: `Duplicate of ${clonedCourse.name}`,
      },
      ['public_id', 'public_id_and_slug']
    );
  }

  // Facilitators
  const maintainerId = get(
    find(initialValues.facilitators, ['role', constants.FACILITATOR_ROLES.maintainer]),
    'user.id'
  );
  const coMaintainersIds = map(
    filter(initialValues.facilitators, ['role', constants.FACILITATOR_ROLES.co_maintainer]),
    ({ user }) => user.id
  );

  initialValues.maintainer_id = maintainerId || currentUser.id;
  initialValues.co_maintainers_ids = coMaintainersIds;

  // Access control
  if (!has(initialValues, 'is_hidden')) {
    initialValues.is_hidden = false;
  }

  // Should publish
  initialValues.should_publish = true;

  // Default duration for Course is 30min
  if (!has(initialValues, 'duration') || isEmpty(initialValues.duration)) {
    initialValues.duration = INITIAL_DURATIONS.course;
  }

  if (!initialValues.channel_id && defaultChannelId) {
    initialValues.channel_id = defaultChannelId;
  }

  const customTagsInitialValues = sortCustomTags(currentUser, initialValues, 'tags');

  return { ...initialValues, ...customTagsInitialValues };
};

const CourseFormPage = ({
  pageTitle,
  topBarActionName,
  backRoute,
  breadcrumbsItemList,
  initialValuesParams,
  initialValuesParams: { course },
}) => {
  const currentUser = useCurrentUser();
  const { default_channel_id: defaultChannelId } = currentUser;

  const { handleContentOnSubmitSuccessHandler } = useContentOnSubmitSuccessHandler();

  const queryClient = useQueryClient();

  const handleInvalidateCourseQuery = () => {
    const publicId = get(course, 'public_id');
    if (!publicId) {
      return;
    }

    queryClient.invalidateQueries({ queryKey: queries.courses.detail(publicId).queryKey });
  };

  const initialValuesState = useState(
    getInitialValues({
      currentUser,
      ...initialValuesParams,
      defaultChannelId,
    })
  );
  const initialValues = initialValuesState[0];

  const queryParams = useQueryParams();
  const isPublishing = queryParams.is_publishing === 'true';

  const isEdit = Boolean(course) && !isPublishing;
  const form = `course${isEdit ? 'Edit' : 'Publish'}Form`;

  return (
    <>
      <PageTitle title={pageTitle} />
      <CourseForm
        form={form}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        onSubmitSuccessHandler={(result) => {
          if (isEdit) {
            handleInvalidateCourseQuery();
          }

          handleContentOnSubmitSuccessHandler({
            backRoute,
            isEdit,
            result,
          });
        }}
      />
    </>
  );
};

export default CourseFormPage;

CourseFormPage.defaultProps = {
  initialValuesParams: {},
};

CourseFormPage.propTypes = {
  pageTitle: PropTypes.string,
  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),
  initialValuesParams: PropTypes.object,
};
