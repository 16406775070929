import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { useOnClickOutside } from '~/app/shared/hooks';
import Icon from '~/app/shared/components/Icon';

import CloudDocUpload from '../CloudDocUpload';
import ConfluenceDocUpload from '../ConfluenceDocUpload';
import ImageUpload from '../ImageUpload';
import Option from '../Option';
import VideoUpload from '../VideoUpload';
import WebsiteUpload from '../WebsiteUpload';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: 35px;
  display: flex;
  flex-direction: column;
  background: white;
  cursor: pointer;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  padding: 5px;
  z-index: 999;
  width: 120px;
`;

const EmbeddingButton = ({
  allowVideoButton,
  allowCloudDocButton,
  allowConfluenceDocButton,
  allowImageButton,
  allowWebsiteButton,
  toggleVideoModal,
  toggleCloudDocModal,
  toggleConfluenceDocModal,
  toggleImageModal,
  toggleWebsiteModal,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const optionEl = useRef(null);

  useOnClickOutside(optionEl, () => setShowDropdown(false));

  const handleOnClick = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <ButtonWrapper aria-label="rdw-embedding-control" title="Embed" ref={optionEl}>
      <Option aria-expanded={showDropdown} onClick={handleOnClick}>
        <Icon height="15" width="15" name="internal" />
      </Option>
      {showDropdown && (
        <DropdownWrapper>
          {allowVideoButton && <VideoUpload toggleModal={toggleVideoModal} />}
          {allowCloudDocButton && <CloudDocUpload toggleModal={toggleCloudDocModal} />}
          {allowConfluenceDocButton && (
            <ConfluenceDocUpload toggleModal={toggleConfluenceDocModal} />
          )}
          {allowImageButton && <ImageUpload toggleModal={toggleImageModal} />}
          {allowWebsiteButton && <WebsiteUpload toggleModal={toggleWebsiteModal} />}
        </DropdownWrapper>
      )}
    </ButtonWrapper>
  );
};

EmbeddingButton.propTypes = {
  toggleVideoModal: PropTypes.func,
  toggleCloudDocModal: PropTypes.func,
  toggleConfluenceDocModal: PropTypes.func,
  toggleImageModal: PropTypes.func,
  toggleWebsiteModal: PropTypes.func,

  allowImageButton: PropTypes.bool,
  allowVideoButton: PropTypes.bool,
  allowCloudDocButton: PropTypes.bool,
  allowConfluenceDocButton: PropTypes.bool,
  allowWebsiteButton: PropTypes.bool,
};

export default EmbeddingButton;
