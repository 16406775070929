import * as React from 'react';
import { get } from 'lodash';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { LEARNING_TYPES, CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING } from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';

const defaultLearningType = LEARNING_TYPES.articles;

export function useContentItemCardData(contentItem: ContentItem) {
  const contentTypesRoutes = useContentTypeRoutes();

  const data = React.useMemo(() => {
    const userAssignment = contentItem.user_assignment ?? contentItem.assignment;

    const contentType = contentItem.content_type;
    const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[contentType] || defaultLearningType;

    const detailsRoute = get(contentTypesRoutes, `${contentType}.details`) as any;
    const detailsRouteUrl = detailsRoute ? detailsRoute(contentItem) : '';

    return {
      userAssignment,
      learningType,
      detailsRouteUrl,
    };
  }, [contentItem, contentTypesRoutes]);

  return data;
}
