import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  EnrollmentStatusPropType,
  PerformEnrollmentActionsPropType,
} from '~/app/event-shared/components/AttendanceActionManager/EnrollmentActionButton/propTypes';
import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';

const ActionContainer = styled.div`
  display: flex;
  padding-bottom: 12px;
  gap: 8px;
`;

export const EventTypeAttendanceRenderEnrolled = ({
  actions: { performUnenroll },
  status: { userIsEnrolledLocal },
  unenrollLabel,
}) => {
  const icon = userIsEnrolledLocal ? 'location' : 'online';
  const label = unenrollLabel || 'Unenroll';

  return (
    <ActionContainer>
      <Button onClick={performUnenroll} size="small" startIcon={<Icon name={icon} />}>
        {label}
      </Button>
    </ActionContainer>
  );
};

EventTypeAttendanceRenderEnrolled.propTypes = {
  actions: PerformEnrollmentActionsPropType,
  status: EnrollmentStatusPropType,
  unenrollLabel: PropTypes.string,
};

export const EventTypeAttendanceRenderProspect = ({
  actions: { performLocalEnrollment, performOnlineEnrollment },
  status: { showLocalEnroll, showOnlineEnroll },
}) => {
  return (
    <ActionContainer>
      {showLocalEnroll && (
        <Button onClick={performLocalEnrollment} size="small" startIcon={<Icon name="location" />}>
          Enroll
        </Button>
      )}
      {showOnlineEnroll && (
        <Button onClick={performOnlineEnrollment} size="small" startIcon={<Icon name="online" />}>
          Enroll
        </Button>
      )}
    </ActionContainer>
  );
};

EventTypeAttendanceRenderProspect.propTypes = {
  actions: PerformEnrollmentActionsPropType,
  status: EnrollmentStatusPropType,
};
