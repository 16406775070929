import MUITab, { TabProps } from '@mui/material/Tab';
import MUITabs, { TabsProps } from '@mui/material/Tabs';
import { Link as ReactRouterLink } from 'react-router-dom';

export function Tabs({ sx, ...props }: TabsProps) {
  return <MUITabs sx={{ minHeight: 'auto', ...sx }} {...props} />;
}

export function Tab({ sx, ...props }: TabProps<typeof MUITab, { component?: typeof MUITab }>) {
  return <MUITab sx={{ textTransform: 'capitalize', minHeight: 'auto', ...sx }} {...props} />;
}

export function LinkTab(props: TabProps<ReactRouterLink, Record<string, never>>) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Tab component={ReactRouterLink} {...props} />;
}
