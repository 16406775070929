import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from '~/services/colors';
import RestrictedIcons from '~/app/shared/components/RestrictedIcons';
import { Tooltip } from '~/app/shared/components/Tooltip';
import { useEllipsisCheck, useTooltipUID } from '~/app/shared/hooks';
import { isNil } from 'lodash-es';

const mapping = {
  cardMode: `
    font-size: 18px;
  `,
  compactMode: `
    font-size: 18px;
  `,
  detailMode: `
    font-size: 30px;
    margin-top: 4px;
    margin-bottom: 4px;
  `,
};

const getStyle = (props) => {
  if (props.mode === 'card') {
    return mapping.cardMode;
  }
  if (props.mode === 'compact') {
    return mapping.compactMode;
  }
  return mapping.detailMode;
};

const StyledTitle = styled.h1`
  margin: 0;
  color: ${colors.neutral900};
  letter-spacing: -0.7px;
  line-height: 1.13;
  min-height: 30px;
  margin-left: ${({ mode, hasCreators }) => (mode === 'compact' && !hasCreators ? '10px' : 0)};
  overflow: ${(props) => (props.mode !== 'card' ? 'visible' : 'hidden')};
  text-overflow: ${(props) => (props.mode !== 'card' ? 'clip' : 'ellipsis')};
  white-space: ${(props) => (props.mode !== 'card' ? 'normal' : 'nowrap')};
  width: ${(props) => (props.mode !== 'card' ? '100%' : '320px')};
  max-width: 100%;
  ${getStyle}

  &:hover {
    opacity: ${(props) => props.theme.defaultLinkHoverOpacity};
  }
  ${({ $usePointer }) => $usePointer && 'cursor: pointer;'}
`;

const Title = (props) => {
  const { title, route, mode, isPrivate, hasGroups, hasCreators, className, onClick } = props;

  const titleTooltip = useTooltipUID();
  const { hasEllipsis, nodeRef } = useEllipsisCheck();

  return (
    <div className={className}>
      {route ? (
        <Link to={route}>
          <StyledTitle
            {...titleTooltip.targetProps}
            mode={mode}
            hasCreators={hasCreators}
            ref={nodeRef}
          >
            <RestrictedIcons isPrivate={isPrivate} hasGroups={hasGroups} size="25px" />
            {title}
          </StyledTitle>

          <Tooltip id={titleTooltip.uid} hide={!hasEllipsis} maxWidth={238}>
            {title}
          </Tooltip>
        </Link>
      ) : (
        <StyledTitle mode={mode} onClick={() => onClick?.()} $usePointer={!isNil(onClick)}>
          <RestrictedIcons isPrivate={isPrivate} hasGroups={hasGroups} size="25px" />
          {title}
        </StyledTitle>
      )}
    </div>
  );
};

Title.defaultProps = {
  mode: 'card',
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  route: PropTypes.string,
  mode: PropTypes.string,
  isPrivate: PropTypes.bool,
  hasGroups: PropTypes.bool,
  hasCreators: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Title;
