import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import { MENTOR_PREFERENCE_TAG_TYPE, MENTEE_PREFERENCE_TAG_TYPE } from '~/app/mentorship/constants';
import {
  convertItemValuesToString,
  FilterOptionsList,
  FilterOptionsQueryList,
} from '~/app/navigation/components/FilterBar/FilterOptions';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { filter, get, includes, size, max, toLower, join } from 'lodash-es';

const TopicsFilter = ({
  filterType,
  param,
  label,
  placeholderLabel,
  selecteds,
  onChange,
  disabled,
  setSelectedTopics,
}) => {
  const selectedTags = selecteds[param] || [];
  const showExpandedOptions = includes(
    ['main_topics', MENTOR_PREFERENCE_TAG_TYPE, MENTEE_PREFERENCE_TAG_TYPE],
    param
  );
  const pageSize = 10;

  const [options, setOptions] = useState([]);
  const [totalOptionsCount, setTotalOptionsCount] = useState(0);

  const [fetch, { status: fetchOptionsStatus }] = useEntities(
    actions.topic.retrieveList,
    ({ status, data: { results, count } }) => {
      if (status === STATUS_DONE) {
        setOptions(convertItemValuesToString(results));
        setTotalOptionsCount(max([totalOptionsCount, count]));
      }
    }
  );

  const getTagTypeParams = () => {
    if (!filterType) return {};
    if (filterType === 'all_tags') return { all_tags: true };
    return { tag_type: filterType };
  };

  const fetchOptions = (queryParams = {}) => {
    const includeIds = get(queryParams, 'include_ids');
    const pageSize = size(includeIds) + 10;

    fetch(
      {
        ...queryParams,
        ...getTagTypeParams(),
        include_slugs: join(includeIds, ','),
        include_ids: undefined,
        page_size: pageSize,
        view_mode: 'filter_options',
      },
      { skipSchema: true }
    );
  };

  const handleOnChange = (newTags) => {
    onChange({
      ...selecteds,
      [param]: newTags,
    });
  };

  useEffect(() => {
    if (!showExpandedOptions) return;

    fetchOptions();
  }, [param]);

  useEffect(() => {
    if (!setSelectedTopics) return;

    const tags = filter(options, (tag) => includes(selectedTags, tag.value));
    setSelectedTopics((oldTopics) => ({ ...oldTopics, [param]: tags }));
  }, [selecteds, options]);

  if (showExpandedOptions && totalOptionsCount < pageSize) {
    return (
      <FilterOptionsList
        allItemName={`All ${label}`}
        onChange={handleOnChange}
        options={options}
        disabled={disabled}
        selecteds={selectedTags}
      />
    );
  }

  return (
    <FilterOptionsQueryList
      onChange={handleOnChange}
      options={options}
      selecteds={selectedTags}
      searchPlaceholder={`Search by ${toLower(placeholderLabel || label)}`}
      fetchOptions={fetchOptions}
      isFetchingOptions={fetchOptionsStatus === STATUS_LOADING}
      disabled={disabled}
    />
  );
};

TopicsFilter.propTypes = {
  filterType: PropTypes.string,
  param: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholderLabel: PropTypes.string,
  selecteds: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  setSelectedTopics: PropTypes.func,
};

export default TopicsFilter;
