import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE,
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR,
} from '~/app/program/constants';
import SuggestMentorshipModal from '~/app/shared-modals/components/SuggestMentorshipModal';
import { MENTOR_PREFERENCE_TAG_TYPE, MENTEE_PREFERENCE_TAG_TYPE } from '~/app/mentorship/constants';
import SessionDetail from '~/app/program/components/SessionDetail';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import RequestSessionModal from '~/app/shared-modals/components/RequestSessionModal';
import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import Pill from '~/app/shared/components/Pill';
import Text from '~/app/shared/components/Text';
import { useToggles, useLabels, useCurrentUser } from '~/app/shared/hooks';
import { getUserSkillsWithLabelAndItems } from '~/app/topics/services';
import { isEmpty, map } from 'lodash-es';

import MenteeStats from './MenteeStats';
import MentorshipRequestModal from './MentorshipRequestModal';
import MentorStats from './MentorStats';

const TabSection = styled.div`
  border-bottom: 1px solid ${colors.neutral200};
`;
const LookingForMentorContainer = styled.div`
  margin: 30px 20px 24px;
  padding: 20px;
  border: 1px solid ${colors.neutral200};
  position: relative;

  > * + * {
    margin-top: 8px;
  }
`;

const TabTitleContainer = styled.div`
  margin: 20px 20px 16px;

  // If previous sibling is LookingForMentorContainer, then add a border
  ${LookingForMentorContainer} + & {
    border-top: 1px solid ${colors.neutral100};
  }
`;

const MentorshipHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 18px;
`;

const PreferencesWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 8px;
  margin-top: 12px;
`;

const RelatedSessionsContainer = styled.div`
  max-height: 515px;
  overflow: auto;
  padding: 20px;
`;

const SessionsAvailableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const SessionCTAContainer = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${colors.neutral100};
  margin: 20px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
`;

const MentorshipStats = styled.div`
  padding: 16px;
  color: ${colors.neutral900};
  font-size: 16px;
  font-weight: bold;
`;

export const SessionsTab = ({
  sessions,
  profileData,
  onEnrollmentDelete,
  showMentorSection,
  showMenteePreferenceSection,
  shouldShowMentorStatsSection,
  shouldShowMenteeStatsSection,
}) => {
  const currentUser = useCurrentUser();
  const isProfileOwner = currentUser.id === profileData.id;

  const [showMentorshipRequestModal, setShowMentorshipRequestModal] = useState(false);
  const [showSessionRequestModal, setShowSessionRequestModal] = useState(false);
  const [showSuggestMenteeModal, setShowSuggestMenteeModal] = useState(false);
  const [showSuggestMentorModal, setShowSuggestMentorModal] = useState(false);
  const [mentorshipRequestSent, setMentorshipRequestSent] = useState(false);

  const {
    toggle_mentor_preferences: toggleMentorPreferences,
    toggle_allow_users_to_request_sessions: toggleRequestSessions,
  } = useToggles();

  const { label_mentorship_tab: mentorshipLabel } = useLabels();

  const mentorPreferencesItems = getUserSkillsWithLabelAndItems({
    currentUser,
    skills: profileData?.skills,
    allowedTagTypes: [MENTOR_PREFERENCE_TAG_TYPE],
  })?.[MENTOR_PREFERENCE_TAG_TYPE]?.items;

  const menteePreferencesItems = getUserSkillsWithLabelAndItems({
    currentUser,
    skills: profileData?.skills,
    allowedTagTypes: [MENTEE_PREFERENCE_TAG_TYPE],
  })?.[MENTEE_PREFERENCE_TAG_TYPE]?.items;

  const handleMentorshipRequestSent = () => {
    setShowMentorshipRequestModal(false);
    setMentorshipRequestSent(true);
  };

  const shouldShowSessionRequestSection = () => {
    if (!toggleRequestSessions) return false;

    if (toggleMentorPreferences) return true;

    return profileData?.isMentor;
  };

  return (
    <div>
      {showMentorSection && (
        <TabSection>
          <TabTitleContainer>
            <MentorshipHeaderWrapper>
              <Text size="h4" medium>
                Mentor
              </Text>

              <PreferencesWrapper>
                {map(mentorPreferencesItems, (item) => (
                  <Pill label={item} variant="lighterGrayWithDarkerTextColor" />
                ))}
              </PreferencesWrapper>
            </MentorshipHeaderWrapper>
            {sessions.length > 0 ? (
              <SessionsAvailableWrapper>
                {isProfileOwner && (
                  <Button
                    startIcon={<Icon name="plus" />}
                    size="small"
                    route={`${mapRoute('sessionMentorshipCreate')}?host=${profileData.id}`}
                  >
                    New Session
                  </Button>
                )}
                {!isProfileOwner && (
                  <Text size="h5" color={colors.neutral500}>
                    Book a session now
                  </Text>
                )}
                <Text size="h5" color={colors.neutral500}>
                  {sessions.length} session{sessions.length !== 1 && 's'}
                </Text>
              </SessionsAvailableWrapper>
            ) : (
              sessions.length === 0 &&
              isProfileOwner && (
                <Button
                  startIcon={<Icon name="plus" />}
                  size="small"
                  fullWidth
                  route={`${mapRoute('sessionMentorshipCreate')}?host=${profileData.id}`}
                >
                  New Session
                </Button>
              )
            )}
          </TabTitleContainer>
          {!isEmpty(sessions) && (
            <RelatedSessionsContainer>
              {map(sessions, (session) => (
                <SessionDetail
                  key={session.id}
                  session={session}
                  showDate
                  onEnrollmentDelete={onEnrollmentDelete}
                />
              ))}
            </RelatedSessionsContainer>
          )}
          {!isProfileOwner && shouldShowSessionRequestSection() && (
            <>
              <SessionCTAContainer>
                <Text size="h5" color={colors.neutral500}>
                  {sessions.length > 1
                    ? "Can't find a time that works for you?"
                    : `Want to book time with ${profileData.name}?`}
                </Text>
                <Button size="small" fullWidth onClick={() => setShowSessionRequestModal(true)}>
                  Request a session
                </Button>
                {showSessionRequestModal && (
                  <RequestSessionModal
                    host={profileData}
                    handleClose={() => setShowSessionRequestModal(false)}
                  />
                )}
              </SessionCTAContainer>
              <SessionCTAContainer>
                <Text size="h5" color={colors.neutral500}>
                  {`Do you know someone else who would make a good mentee for ${profileData.name}?`}
                </Text>
                <Button size="small" fullWidth onClick={() => setShowSuggestMenteeModal(true)}>
                  Suggest a mentee
                </Button>
                {showSuggestMenteeModal && (
                  <SuggestMentorshipModal
                    receiver={profileData}
                    suggestionKind={MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE}
                    handleClose={() => setShowSuggestMenteeModal(false)}
                    onRequestSent={() => setShowSuggestMenteeModal(false)}
                  />
                )}
              </SessionCTAContainer>
            </>
          )}
        </TabSection>
      )}

      {showMenteePreferenceSection && (
        <TabSection>
          <TabTitleContainer>
            <MentorshipHeaderWrapper>
              <Text size="h4" medium>
                Mentee
              </Text>

              <PreferencesWrapper>
                {map(menteePreferencesItems, (item) => (
                  <Pill label={item} variant="lighterGrayWithDarkerTextColor" />
                ))}
              </PreferencesWrapper>
            </MentorshipHeaderWrapper>
          </TabTitleContainer>

          <SessionCTAContainer>
            <Text size="h5" color={colors.neutral500}>
              Want to mentor {profileData.name}?
            </Text>

            {!mentorshipRequestSent ? (
              <Button size="small" fullWidth onClick={() => setShowMentorshipRequestModal(true)}>
                Offer to mentor
              </Button>
            ) : (
              <Button size="medium" fullWidth disabled>
                Mentorship request sent!
              </Button>
            )}
            {showMentorshipRequestModal && (
              <MentorshipRequestModal
                mentee={profileData}
                handleClose={() => setShowMentorshipRequestModal(false)}
                onRequestSent={handleMentorshipRequestSent}
              />
            )}
          </SessionCTAContainer>
          <SessionCTAContainer>
            <Text size="h5" color={colors.neutral500}>
              {`Do you know someone else who would make a good mentor for ${profileData.name}?`}
            </Text>
            <Button size="small" fullWidth onClick={() => setShowSuggestMentorModal(true)}>
              Suggest a mentor
            </Button>
            {showSuggestMentorModal && (
              <SuggestMentorshipModal
                receiver={profileData}
                suggestionKind={MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR}
                handleClose={() => setShowSuggestMentorModal(false)}
                onRequestSent={() => setShowSuggestMentorModal(false)}
              />
            )}
          </SessionCTAContainer>
        </TabSection>
      )}

      {(shouldShowMentorStatsSection || shouldShowMenteeStatsSection) && (
        <>
          <MentorshipStats>{mentorshipLabel} Stats</MentorshipStats>
          {shouldShowMentorStatsSection && <MentorStats profileData={profileData} />}
          {shouldShowMenteeStatsSection && <MenteeStats profileData={profileData} />}
        </>
      )}
    </div>
  );
};

SessionsTab.propTypes = {
  sessions: PropTypes.array,
  profileData: PropTypes.object,
  onEnrollmentDelete: PropTypes.func,
  showMentorSection: PropTypes.bool,
  showMenteePreferenceSection: PropTypes.bool,
  shouldShowMentorStatsSection: PropTypes.bool,
  shouldShowMenteeStatsSection: PropTypes.bool,
};

export default SessionsTab;
