import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import TextInput from '~/app/inputs/components/TextInput';

const Container = styled.div`
  max-width: 320px;
  margin-top: 8px;
`;

const SurveyQuestionShortText = ({ input }) => (
  <Container>
    <TextInput {...input} />
  </Container>
);

SurveyQuestionShortText.propTypes = {
  input: PropTypes.object,
};

export default SurveyQuestionShortText;
