import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { EDIT_VIDEO_PERMISSION } from '~/app/shared/permissions';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import actions from '~/app/entities/actions';
import { videoSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { permissionDeniedRedirectAlert } from '~/services/permission-denied-redirect-alert';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { useOriginRoute, useSetDetailedObject } from '~/app/shared/hooks';
import { includes, toLower } from 'lodash-es';

import VideoFormPage from './VideoFormPage';

const VideoEdit = () => {
  const { public_id: publicId } = useParams();
  const [userCanSee, setUserCanSee] = useState(false);

  const labelVideo = 'Video';
  const labelVideoPlural = 'Videos';

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.videos
  }&o=upcoming`;
  const videoDetailRoute = mapRoute('standAloneVideoDetail', { public_id_and_slug: publicId });
  const backRoute = useOriginRoute(videoDetailRoute);

  const [fetchVideo, { data: video, status }] = useEntities(
    actions.video.retrieveDetails,
    ({ data: video, status }) => {
      if (status === STATUS_DONE) {
        if (includes(video.permissions, EDIT_VIDEO_PERMISSION)) {
          setUserCanSee(true);
        } else {
          permissionDeniedRedirectAlert();
        }
      }
    },
    {
      schema: videoSchema,
    }
  );

  useEffect(() => {
    fetchVideo(publicId);
  }, []);

  useSetDetailedObject({ id: publicId }, 'videos', [publicId]);

  if (status === STATUS_LOADING || !userCanSee) return <Loading />;

  const initialValuesParams = { video };

  return (
    <VideoFormPage
      pageTitle={`Edit ${labelVideo}`}
      topBarActionName={`Editing ${labelVideo}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelVideoPlural,
          link: catalogRoute,
        },
        {
          label: video.name,
          link: videoDetailRoute,
        },
        {
          label: `Edit ${toLower(labelVideo)}`,
        },
      ]}
    />
  );
};

export default VideoEdit;
