import { Box, Typography } from '@mui/material';

import { User } from '~/app/shared/components/types';
import Pill from '~/app/shared/components/Pill';
import { UserPopover } from '~/app/shared/components/UserAvatar';
import UserProfileInitials from '~/app/shared/components/UserAvatar/UserProfileInitials';

type ChannelOwnerPillProps = {
  owner: User;
};

// TODO: Adapt to display multiple owners

export function ChannelOwnerPill(props: ChannelOwnerPillProps) {
  const { owner } = props;

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="body2" component="span" sx={{ fontSize: '12px', fontWeight: 400 }}>
        Owner:
      </Typography>

      <UserPopover
        sendEmailCTA
        user={owner}
        renderPopoverHoverable={
          <Pill
            variant="lighterGray"
            imageSrc={owner.profile_image}
            label={owner.display_name}
            labelMaxWidth={110}
            imagePlaceHolder={<UserProfileInitials userName={owner.name} />}
          />
        }
      />
    </Box>
  );
}
