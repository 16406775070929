import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { WidgetContent, WidgetContentList } from '~/common/components/widget';
import { mapRoute } from '~/services/requests';
import { User } from '~/app/shared/components/types';
import { get, filter, join, split, map, trim, head, last, size } from 'lodash-es';
import { Avatar } from '~/common/components/Avatar';
import { Typography } from '~/common/components/Typography';
import { Box, Card, CardActionArea, Skeleton, Stack } from '@mui/material';

interface UsersWidgetRowProps {
  user: User;
}

export function UsersWidgetRow(props: UsersWidgetRowProps) {
  const { user } = props;

  const history = useHistory();

  const userLocation = useMemo(() => {
    return get(user, 'location_text') || get(user, 'location.name', '');
  }, [user]);

  const userAvatarFallback = useMemo(() => {
    const words = map(split(get(user, 'display_name'), ' '), trim);

    if (size(words) === 1) {
      return head(head(words));
    }

    return `${head(head(words))}${head(last(words))}`;
  }, [user]);

  return (
    <Card elevation={0}>
      <CardActionArea
        onClick={() => history.push(mapRoute('userProfile', { id: get(user, 'id') }))}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1.5 }}>
          <Avatar
            alt={get(user, 'display_name')}
            src={get(user, 'profile_image')}
            sx={{ width: 44, height: 44 }}
          >
            {userAvatarFallback}
          </Avatar>
          <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Typography variant="body1" noWrap>
              {get(user, 'display_name', '')}
            </Typography>
            <Typography variant="caption" noWrap>
              {join(filter([get(user, 'title'), userLocation], Boolean), ' • ')}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

type UsersWidgetEmptyRowProps = {
  animate?: boolean;
};

export function UsersWidgetEmptyRow(props: UsersWidgetEmptyRowProps) {
  const { animate = false } = props;

  return (
    <Card elevation={0}>
      <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1.5 }}>
        <Avatar sx={{ width: 40, height: 40 }} />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1" noWrap>
            <Skeleton variant="text" width={235} animation={animate ? 'wave' : false} />
          </Typography>
          <Typography variant="caption" noWrap>
            <Skeleton variant="text" width={118} height={12} animation={animate ? 'wave' : false} />
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

type UsersWidgetLoadingContentProps = {
  animate?: boolean;
};

export function UsersWidgetLoadingContent(props: UsersWidgetLoadingContentProps) {
  const { animate = true } = props;

  return (
    <>
      <UsersWidgetEmptyRow animate={animate} />
      <UsersWidgetEmptyRow animate={animate} />
      <UsersWidgetEmptyRow animate={animate} />
    </>
  );
}

type UsersWidgetEmptyContentProps = {
  label: string;
};

export function UsersWidgetEmptyContent(props: UsersWidgetEmptyContentProps) {
  const { label } = props;

  return (
    <WidgetContent paddingTop={0}>
      <Stack direction="column" rowGap={3}>
        <Typography variant="body2" noWrap color="rgba(0, 0, 0, 0.6)">
          {label}
        </Typography>
        <WidgetContentList>
          <UsersWidgetEmptyRow />
          <UsersWidgetEmptyRow />
        </WidgetContentList>
      </Stack>
    </WidgetContent>
  );
}
