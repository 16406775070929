import { call, put, takeLatest } from 'redux-saga/effects';

import { ApiURLs, fetchURL } from '~/services/requests-base';

import actions from './actions';

export function* inviteUsersToEvent(action) {
  const url = ApiURLs['api_events:invite']({ public_id: action.payload.public_id });

  const options = action.options || {};
  options.method = 'POST';
  options.body = JSON.stringify(action.payload);

  try {
    const response = yield call(fetchURL, url, options);

    yield put(actions.inviteToEventFormSuccess(response.data));
  } catch (error) {
    yield put(actions.inviteToEventFormFailure({ _error: error.data }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.inviteToEventFormSubmit.toString(), inviteUsersToEvent);
}
