import React, { useEffect } from 'react';

import actions from '~/app/entities/actions';
import { eventSchema, eventTypeSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { mapRoute } from '~/services/requests';
import { useParentSettings } from '~/app/settings/hooks';
import Loading from '~/app/shared/components/Loading';
import { STATUS_LOADING } from '~/app/shared/constants';
import { useOriginRoute, useQueryParams, useSetDetailedObject } from '~/app/shared/hooks';
import { isEmpty } from 'lodash-es';

import EventFormPage from './EventFormPage';

const EventCreate = () => {
  const { cloned: duplicatedEventPublicId, template: eventTypePublicId } = useQueryParams();
  const parentSettingsFlat = useParentSettings('event/form', 'flat', eventTypePublicId);
  const isDuplicating = Boolean(duplicatedEventPublicId);

  const eventsListRoute = mapRoute('eventsList');
  const backRoute = useOriginRoute(eventsListRoute);

  const [fetchEvent, { data: clonedEvent, status: clonedEventStatus }] = useEntities(
    actions.event.retrieveDetails,
    null,
    {
      schema: eventSchema,
    }
  );

  const [fetchEventType, { data: eventType, status: eventTypeStatus }] = useEntities(
    actions.eventType.retrieveDetails,
    null,
    {
      schema: eventTypeSchema,
    }
  );

  useEffect(() => {
    if (duplicatedEventPublicId) {
      fetchEvent(duplicatedEventPublicId);
    } else if (eventTypePublicId) {
      fetchEventType(eventTypePublicId);
    }
  }, [duplicatedEventPublicId, eventTypePublicId]);

  useSetDetailedObject({ id: null }, 'events', []);

  if (
    (duplicatedEventPublicId && clonedEventStatus === STATUS_LOADING) ||
    (eventTypePublicId && eventTypeStatus === STATUS_LOADING)
  )
    return <Loading />;

  const initialValuesParams = {};
  if (duplicatedEventPublicId) initialValuesParams.clonedEvent = clonedEvent;
  // When creating events, always ignore the settings from the event-type retrieve endpoint
  // We want to use data from parentSettingsFlat, which already sets initially everything to `inherited: true`
  if (eventTypePublicId) initialValuesParams.eventType = { ...eventType, settings: undefined };
  if (!duplicatedEventPublicId && !isEmpty(parentSettingsFlat))
    initialValuesParams.defaultSettings = parentSettingsFlat;

  return (
    <EventFormPage
      pageTitle={`${isDuplicating ? 'Duplicate' : 'Create'} Event`}
      topBarActionName={`${isDuplicating ? 'Duplicating' : 'Creating'} Event`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: 'Events',
          link: eventsListRoute,
        },
        {
          label: 'New event',
        },
      ]}
    />
  );
};

export default EventCreate;
