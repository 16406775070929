import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import alerts from '~/app/alerts/reducer';
import backoffice from '~/app/backoffice/reducer';
import entities from '~/app/entities/reducer';
import eventShared from '~/app/event-shared/reducer';
import event from '~/app/event/reducer';
import shared from '~/app/shared/reducer';
import user from '~/app/users/reducer';

export default combineReducers({
  entities,
  event,
  eventShared,
  user,
  alerts,
  shared,
  backoffice,
  form: formReducer,
});
