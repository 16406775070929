import PropTypes from 'prop-types';
import React from 'react';
import { HotKeys } from 'react-hotkeys';
import styled from 'styled-components';

import propTypes from '~/services/prop-types';
import HR from '~/app/shared/components/HR';
import SelectableListItem from '~/app/shared/components/SelectableListItem';

export const Separator = () => <HR height={3} />;

const StyledListItem = styled(SelectableListItem)`
  ${({ limitWidth }) => limitWidth && 'min-width: 144px;'}
`;

const ItemText = styled.span`
  display: block;
`;

const Item = (props) => {
  const { item, isChecked, onClick, children, singleSelect, ...rest } = props;

  const handleClick = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    onClick(item.value);
  };

  return (
    <StyledListItem
      isSelected={isChecked}
      singleSelect={singleSelect}
      onClick={handleClick}
      color={item.color}
      icon={item.icon}
      imageSrc={item.image}
      {...rest}
    >
      <HotKeys keyMap={{ handleClick: ['enter', 'space'] }} handlers={{ handleClick }}>
        {children || <ItemText>{item.name}</ItemText>}
      </HotKeys>
    </StyledListItem>
  );
};

Item.propTypes = {
  item: propTypes.listItem,
  children: propTypes.nodeOrList,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  singleSelect: PropTypes.bool,
  limitWidth: PropTypes.bool,
};

Item.defaultProps = {
  limitWidth: true,
};

export default Item;
