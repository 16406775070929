import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SearchInput from '~/app/inputs/components/SearchInput';
import colors from '~/services/colors';
import { useDebounce } from '~/app/shared/hooks';

const SearchField = styled.div`
  padding: 12px 20px;
  border-bottom: 1px solid ${colors.neutral100};
`;

const FilterBarSearchWidget = ({ selecteds, onChange, param }) => {
  const filterValue = selecteds[param] ?? '';
  const [inputValue, setInputValue] = useState('');
  const [changed, setChanged] = useState(false);
  const debouncedValue = useDebounce(inputValue, 500);

  // Hydrate inputValue when filterValue changes
  useEffect(() => {
    if (filterValue !== inputValue) {
      setInputValue(filterValue);
    }
  }, [filterValue]);

  // Update URL when debounced value changes, only do that if user already typed
  useEffect(() => {
    if (changed && filterValue !== debouncedValue) {
      onChange({ ...selecteds, [param]: debouncedValue });
    }
  }, [debouncedValue]);

  const handleValueChange = (value) => {
    setChanged(true);
    setInputValue(value);
  };

  return (
    <SearchField>
      <SearchInput
        placeholder="Search by keyword"
        value={inputValue}
        onChange={handleValueChange}
      />
    </SearchField>
  );
};

FilterBarSearchWidget.propTypes = {
  selecteds: PropTypes.object,
  onChange: PropTypes.func,
  param: PropTypes.string,
};

FilterBarSearchWidget.defaultProps = {
  param: 'q',
};

export default FilterBarSearchWidget;
