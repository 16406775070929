import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import { IdParam } from '~/common/types';
import actions from '~/app/entities/actions';
import { assignmentSchema, userProfileSchema } from '~/app/entities/schema';
import { getDataFromState, useEntities } from '~/app/entities/utils';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { map } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Box, Divider, List, TablePagination } from '@mui/material';
import { ContentItemRecord } from './ContentItemRecord';

interface AssignmentQueryParams {
  content_item_type: Array<string>;
  due_after: string;
  has_due_date: boolean;
  include_archived_items: boolean;
  include_events: boolean;
  o: string;
  page_size: number;
  page?: number;
  state: Array<string>;
  users: Array<number>;
  view_mode: string;
}

export const UpcomingContentWidget = () => {
  const { id: userId } = useParams<IdParam>();

  const [fetchUpcomingAssignments, { status, data: upcomingAssignments, count }] = useEntities(
    actions.contentAssignment.retrieveList,
    null,
    {
      schema: [assignmentSchema],
    }
  );

  const { status: userStatus, data: user } = useSelector((state) =>
    getDataFromState(`userProfile${userId}`, state, userProfileSchema)
  );

  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    if (userStatus === STATUS_DONE) {
      const params: AssignmentQueryParams = {
        content_item_type: [
          CONTENT_TYPES.article,
          CONTENT_TYPES.assessment,
          CONTENT_TYPES.codelab,
          CONTENT_TYPES.course,
          CONTENT_TYPES.event,
          CONTENT_TYPES.scheduled_track,
          CONTENT_TYPES.track,
          CONTENT_TYPES.video,
        ],
        due_after: moment().subtract(60, 'days').format('YYYY-MM-DD'),
        has_due_date: true,
        include_archived_items: false,
        include_events: true,
        page_size: rowsPerPage,
        state: [ASSIGNMENT_STATES.in_progress, ASSIGNMENT_STATES.not_started],
        users: [user.id],
        view_mode: 'engagement_upcoming',
        o: 'due_date',
      };
      if (pageNumber !== 0) {
        params.page = pageNumber + 1;
      }
      fetchUpcomingAssignments(params);
    }
  }, [pageNumber, rowsPerPage, userStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage === pageNumber) return;
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = Number.parseInt(event.target.value, 10);
    if (newRowsPerPage === rowsPerPage) return;
    setRowsPerPage(newRowsPerPage);
    setPageNumber(0);
  };

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Upcoming
      </Typography>
      {status === STATUS_LOADING && <Loading />}
      {status === STATUS_DONE &&
        upcomingAssignments &&
        upcomingAssignments.length === 0 &&
        userStatus === STATUS_DONE && (
          <Typography variant="body1"> No upcoming items for {user.name}</Typography>
        )}
      {status === STATUS_DONE && upcomingAssignments && upcomingAssignments.length > 0 && (
        <>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {upcomingAssignments &&
              map(upcomingAssignments, (assignment, i: number) => (
                <>
                  <ContentItemRecord key={assignment?.public_id ?? i} assignment={assignment} />
                  <Divider component="li" />
                </>
              ))}
          </List>
          <TablePagination
            component="div"
            count={count}
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ alignItems: 'baseline' }}
          />
        </>
      )}
    </Box>
  );
};
