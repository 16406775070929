import React, { useState } from 'react';

import { map } from 'lodash-es';
import { useLabels } from '~/app/shared/hooks';
import { DropdownFilter } from '~/app/backoffice/components/Dashboard/Filters';
import { useQuery } from '@tanstack/react-query';
import { queries } from '~/queries';

const PAGE_SIZE = 15;

const makeOptions = (data) => {
  return map(data.results, ({ slug, name }) => ({
    value: slug,
    label: name,
  }));
};

const ChannelFilter = ({ ...props }) => {
  const [channelQueryParam, setQueryParams] = useState({
    view_mode: 'filter',
    page_size: PAGE_SIZE,
  });

  const { label_channel: labelChannel } = useLabels();

  const { data: options } = useQuery({
    ...queries.channels.filters(channelQueryParam),
    select: makeOptions,
  });

  const fetchOptions = ({ q }) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      q,
    }));
  };

  return (
    <DropdownFilter
      label={labelChannel}
      options={options}
      loading={false}
      fetchOptions={fetchOptions}
      {...props}
      param={'channel'}
    />
  );
};

export default ChannelFilter;
