import React, { useEffect, useReducer, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import styled, { css } from 'styled-components';

import PageArrow, { PAGE_ARROW_WIDTH } from '~/app/shared/components/PageArrow';
import { floor, slice, size, map, isNaN, last, get } from 'lodash-es';

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CardCarouselContainer = styled.div<{ $fillContainer?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $fillContainer }) => $fillContainer && 'width: 100%;'}
`;

const CardsContainer = styled.div<{
  margin: number;
  parentWidth: number;
  justifyContent?: boolean;
}>`
  margin: 0 -${({ margin }) => margin}px;
  flex: 1 1 auto;
  display: flex;
  ${({ parentWidth }) => parentWidth < 520 && 'justify-content: center;'}
  ${({ justifyContent }) =>
    justifyContent
      ? css`
          justify-content: center;
        `
      : null};
`;

const pageReducer = (
  state: { start: number; end: number },
  action:
    | { type: 'forward' | 'back'; payload: number }
    | { type: 'reset'; payload: { start: number; end: number } }
) => {
  switch (action.type) {
    case 'forward': {
      return { start: state.start + action.payload, end: state.end + action.payload };
    }
    case 'back': {
      return { start: state.start - action.payload, end: state.end - action.payload };
    }
    case 'reset': {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export interface CardCarouselProps<Content = any> {
  renderItem: (content: Content, cardsPerPage: number, cardsCount: number) => React.ReactElement;
  renderMoreComponent: () => React.ReactElement;
  list: Content[];
  cardWidth: number;
  startIndex?: number;
  margin?: number;
  renderMore?: boolean;
  justifyContent?: boolean;
  fillContainer?: boolean;
  showArrowsIfRenderedAll?: boolean;
}

/**
 * Margin and Card Width are the values of the UI item
 *  it is only used so this component can calculate how many cards appear
 *  it doesn't change the layout by itself
 *
 * This components render as many items as possible, according to the props
 *  to manipulate this, set a width in the parent element
 */
export const CardCarousel = ({
  renderItem,
  renderMore = false,
  list,
  margin = 0,
  cardWidth,
  renderMoreComponent,
  justifyContent = false,
  startIndex = 0,
  fillContainer = false,
  showArrowsIfRenderedAll = true,
}: CardCarouselProps) => {
  const init = (end: number) => ({ start: startIndex, end });
  const { ref, width = 0 } = useResizeDetector();
  const count = size(list);
  const cardWithSpaceWidth = cardWidth + margin * 2;
  const getCardsPerPage = () => {
    let numberOfCards = floor((width - PAGE_ARROW_WIDTH * 2 + margin * 2) / cardWithSpaceWidth);
    numberOfCards = isNaN(numberOfCards) ? 1 : numberOfCards;
    return Math.max(numberOfCards, 1);
  };

  const [cardsPerPage, setCardsPerPage] = useState(getCardsPerPage);
  const [{ start, end }, dispatch] = useReducer(pageReducer, cardsPerPage + startIndex, init);

  useEffect(() => {
    const newCardsPerPage = getCardsPerPage();
    if (cardsPerPage !== newCardsPerPage) {
      setCardsPerPage(newCardsPerPage);
      dispatch({ type: 'reset', payload: init(startIndex + newCardsPerPage) });
    }
  }, [width]);

  const handlePageChange = (direction: 'forward' | 'back') => {
    dispatch({ type: direction, payload: 1 });
  };

  const hasPreviousPage = start > 0;
  const hasNextPage = end < count;
  const showArrows = showArrowsIfRenderedAll || count > cardsPerPage;

  if (renderMore && get(last(list), 'hasMoreItems', null) === null) {
    list.push({ hasMoreItems: true });
  }

  const slicedList = slice(list, start, end);

  const previousPageClick = () => {
    handlePageChange('back');
  };

  const nextPageClick = () => {
    handlePageChange('forward');
  };

  return (
    <CardCarouselContainer $fillContainer={fillContainer}>
      <CarouselContainer ref={ref}>
        {showArrows && (
          <PageArrow
            handleOnClick={previousPageClick}
            enabled={hasPreviousPage}
            orientation="left"
          />
        )}
        <CardsContainer margin={margin} parentWidth={width} justifyContent={justifyContent}>
          {map(slicedList, (item) => {
            return get(item, 'hasMoreItems', null) === null
              ? renderItem(item, cardsPerPage, count)
              : renderMoreComponent();
          })}
        </CardsContainer>
        {showArrows && (
          <PageArrow handleOnClick={nextPageClick} enabled={hasNextPage} orientation="right" />
        )}
      </CarouselContainer>
    </CardCarouselContainer>
  );
};

export default CardCarousel;
