import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import actions from '~/app/entities/actions';
import { eventTypeSchema } from '~/app/entities/schema';
import { getDataFromState } from '~/app/entities/utils';
import EventTypeListItem from '~/app/event-types/components/EventTypeListItem';
import EventTypeWidget from '~/app/event-types/components/EventTypeWidget';
import HR from '~/app/shared/components/HR';
import { STATUS_NOT_REQUESTED } from '~/app/shared/constants';
import { isEmpty } from 'lodash-es';

class EventTypeRenderer extends React.Component {
  componentDidMount() {
    const {
      blockProps: { id },
    } = this.props;
    if (id) this.getEventType(id);
  }

  getEventType = (eventTypeId) => {
    const {
      blockProps: { preview },
      eventTypeRequestStatus,
      fetchEventType,
    } = this.props;

    if (!preview && eventTypeRequestStatus === STATUS_NOT_REQUESTED) fetchEventType(eventTypeId);
  };

  render = () => {
    const {
      eventType,
      blockProps: { raw, preview, readOnly },
    } = this.props;

    if (preview || isEmpty(eventType)) {
      return <a href={raw}>{raw}</a>;
    }

    if (!readOnly) {
      return <EventTypeListItem eventType={eventType} canClick={false} bordered large />;
    }

    // This handles the render of event type track item
    return (
      <div>
        <HR />
        <EventTypeWidget eventType={eventType} insideTrack />
        <HR />
      </div>
    );
  };
}

EventTypeRenderer.propTypes = {
  /*
  contentState: PropTypes.object,
  block: PropTypes.object,
  */
  blockProps: PropTypes.object,

  eventType: PropTypes.object,
  eventTypeRequestStatus: PropTypes.string,
  fetchEventType: PropTypes.func,
};

const mapStateToProps = (state, { blockProps }) => {
  const { id } = blockProps;

  const requestState = getDataFromState(`eventTypeRenderer${id}`, state, eventTypeSchema);

  return {
    eventType: requestState.data,
    eventTypeRequestStatus: requestState.status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchEventType: (id) => dispatch(actions.eventType.retrieveDetails(`eventTypeRenderer${id}`, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventTypeRenderer);
