import {
  ListItemIcon,
  ListItemText,
  MenuItem as MUIMenuItem,
  MenuItemProps,
  styled,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  LEARNING_TYPES,
  LEARNING_TYPE_ICONS_MAPPING,
  LEARNING_TYPE_COLOR_MAPPING,
} from '~/app/catalog/constants';
import { useLearningTypeLabels } from '~/app/catalog/hooks';
import { useEncodedCurrentRoute } from '~/app/shared/hooks';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import Icon from '~/app/shared/components/Icon';
import { isString, omit } from 'lodash-es';

const MenuItem = styled(MUIMenuItem)<
  MenuItemProps & { component?: React.ElementType; to?: string }
>``;

type LearningMenuItemProps = MenuItemProps & {
  title: string;
  icon?: string | React.ElementType;
  route?: string;
  color: string;
};

export function LearningMenuItem(props: LearningMenuItemProps) {
  const { title, icon: IconProp, route, color, ...restButtonProps } = props;

  return (
    <MenuItem {...restButtonProps} component={route ? Link : undefined} to={route ?? undefined}>
      {IconProp ? (
        <ListItemIcon>
          {isString(IconProp) ? (
            <Icon name={IconProp} width={18} height={18} color={color} />
          ) : (
            <IconProp
              sx={{
                width: 18,
                height: 18,
                color,
              }}
            />
          )}
        </ListItemIcon>
      ) : null}
      <ListItemText
        sx={{
          color: colors.neutral600,
        }}
      >
        {title}
      </ListItemText>
    </MenuItem>
  );
}

const _allowedLearningTypes = omit(LEARNING_TYPES, [
  LEARNING_TYPES.all,
  LEARNING_TYPES.questions,
  LEARNING_TYPES.text_questions,
  LEARNING_TYPES.multiple_choice_questions,
  LEARNING_TYPES.scheduled_tracks,
  LEARNING_TYPES.events,
]);

type AllowedLearningTypes = keyof typeof _allowedLearningTypes;

type LearningTypeMenuItemProps = MenuItemProps & {
  type: AllowedLearningTypes;
  routeParams?: string;
};

const learningTypeRouteMapping = {
  [LEARNING_TYPES.event_types]: mapRoute('eventTypeCreate'),
  [LEARNING_TYPES.programs]: mapRoute('programCreate'),
  [LEARNING_TYPES.mentorship_programs]: mapRoute('mentorshipProgramCreate'),
  [LEARNING_TYPES.tracks]: mapRoute('trackCreate'),
  [LEARNING_TYPES.articles]: mapRoute('articleCreate'),
  [LEARNING_TYPES.videos]: mapRoute('videoCreate'),
  [LEARNING_TYPES.assessments]: mapRoute('assessmentCreate'),
};

function useLearningTypeVariant(type: AllowedLearningTypes) {
  // const currentRoute = useEncodedCurrentRoute();
  const learningTypeLabels = useLearningTypeLabels();

  const createRoute = learningTypeRouteMapping[type];

  return {
    title: learningTypeLabels[type],
    icon: LEARNING_TYPE_ICONS_MAPPING[type],
    color: LEARNING_TYPE_COLOR_MAPPING[type].color700,
    route: createRoute,
    // route: createRoute ? `${createRoute}?origin=${currentRoute}` : undefined,
  };
}

export function LearningTypeMenuItem(props: LearningTypeMenuItemProps) {
  const { type, routeParams = '', ...restMenuItemProps } = props;

  const currentRoute = useEncodedCurrentRoute();

  const variant = useLearningTypeVariant(type);
  const itemRoute = variant.route
    ? `${variant.route}?origin=${currentRoute}${routeParams ? `&${routeParams}` : ''}`
    : undefined;

  return (
    <LearningMenuItem
      title={variant.title}
      route={itemRoute}
      // route={variant.route ? `${variant.route}${routeParams ? `&${routeParams}` : ''}` : undefined}
      icon={variant.icon}
      color={variant.color}
      aria-label={`New ${variant.title}`}
      {...restMenuItemProps}
    />
  );
}
