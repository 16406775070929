import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import UserSelectField from '~/app/inputs/components/UserSelectField/UserSelectField';
import { FormFieldGroup } from '~/app/shared/components/Form';
import InfoText from '~/app/shared/components/InfoText';

const FacilitatorField = ({ multiple, label, name, infoText, last }) => {
  return (
    <FormFieldGroup>
      <span data-testid="facilitator-field">
        <Field multiple={multiple} name={name} component={UserSelectField} label={label} />
      </span>

      {infoText && <InfoText top={4} bottom={last ? 0 : 16} content={infoText} />}
    </FormFieldGroup>
  );
};

FacilitatorField.defaultProps = {
  multiple: true,
  last: false,
  label: 'Facilitator(s)',
  name: 'facilitators_ids',
};

FacilitatorField.propTypes = {
  multiple: PropTypes.bool,
  last: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  infoText: PropTypes.string,
};

export default FacilitatorField;
