import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import AttendanceStatusWidget from '~/app/event-shared/components/AttendanceStatusWidget';
import EventCardHeader from '~/app/event-shared/components/EventCardHeader';
import EventCover from '~/app/event-shared/components/EventCover';
import EventInviteUsersModal from '~/app/event-shared/components/EventInviteUsersModal';
import { getEnrollmentOpensCutoff } from '~/app/event-shared/services';
import colors from '~/services/colors';
import { displayDatetime } from '~/services/datetime';
import { mapRoute } from '~/services/requests';
import ButtonLink from '~/app/shared/components/ButtonLink';
import CancelledPill from '~/app/shared/components/CancelledPill';
import { useCurrentUser } from '~/app/shared/hooks';
import { toNumber, isNil } from 'lodash-es';

const Content = styled.div``;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colors.neutral200};
  padding: 8px 16px;
  color: ${colors.neutral400};
  align-self: flex-end;
  font-size: 11pt;
  padding-top: ${({ paddingSize }) => paddingSize ?? '16'}px;
`;

const EventCardContainer = styled.div`
  height: ${({ cardHeight }) => cardHeight ?? '480'}px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Body = styled.div``;

export const EventCard = ({
  event,
  className,
  showCover,
  cardHeight,
  paddingSize,
  showPersonalizedMaxLength,
}) => {
  const [showInviteMorePeopleModal, setShowInviteMorePeopleModal] = useState(false);
  const currentUser = useCurrentUser();

  const enrollmentOpeningDate = getEnrollmentOpensCutoff(event);

  const isOpenedForEnrollment = isNil(enrollmentOpeningDate)
    ? true
    : moment().isSameOrAfter(enrollmentOpeningDate);

  if (!event) {
    return <div />;
  }

  const eventInterestedEnrollmentsCount =
    (toNumber(event.going_local_enrollments_count) || 0) +
    (toNumber(event.going_online_enrollments_count) || 0);

  return (
    <EventCardContainer className={className} cardHeight={cardHeight} paddingSize={paddingSize}>
      {showCover ? (
        <EventCover
          eventPublicIdAndSlug={event.public_id_and_slug}
          eventCover={event.cover || event.default_cover}
          linkDetailsInCover
          curvedBorders
        />
      ) : null}
      <Content>
        <Body>
          <EventCardHeader
            event={event}
            route={mapRoute('eventDetails', { public_id_and_slug: event.public_id_and_slug })}
            mode="card"
            showPersonalizedMaxLength={showPersonalizedMaxLength}
          />
          <AttendanceStatusWidget event={event} viewMode="cozy" />
        </Body>

        {!isOpenedForEnrollment && enrollmentOpeningDate && (
          <Footer>
            <span>
              Opens on{' '}
              {displayDatetime(
                enrollmentOpeningDate,
                event.timezone,
                currentUser.timezone,
                event.is_online
              )}
            </span>
          </Footer>
        )}

        {isOpenedForEnrollment && !event.has_passed && (
          <Footer>
            {event.is_archived && <CancelledPill size="small" />}
            <span>
              {eventInterestedEnrollmentsCount}
              {eventInterestedEnrollmentsCount === 1 ? ' person is' : ' people are'} going
            </span>
            <ButtonLink
              alt={`Invite users to ${event.name}`}
              onClick={() => setShowInviteMorePeopleModal(true)}
            >
              Invite
            </ButtonLink>
          </Footer>
        )}
        {isOpenedForEnrollment && event.has_passed && (
          <Footer>
            <span>
              {event.attendance_count}
              {event.attendance_count === 1 ? ' person' : ' people'} went
            </span>
          </Footer>
        )}
      </Content>
      {showInviteMorePeopleModal && (
        <EventInviteUsersModal
          event={event}
          handleClose={() => setShowInviteMorePeopleModal(false)}
        />
      )}
    </EventCardContainer>
  );
};

EventCard.propTypes = {
  event: PropTypes.object,
  className: PropTypes.string,
  showCover: PropTypes.bool,
  cardHeight: PropTypes.string,
  paddingSize: PropTypes.string,
  showPersonalizedMaxLength: PropTypes.bool,
};

EventCard.defaultProps = {
  showCover: true,
  showPersonalizedMaxLength: false,
};

EventCard.Content = Content;
EventCard.Body = Body;
EventCard.Footer = Footer;

export default EventCard;
