import { useQuery } from '@tanstack/react-query';

import { queries } from '~/queries';
import { useQueryParams } from '~/app/shared/hooks';
import { get } from 'lodash-es';

export function useCreateContentFromChannelParams() {
  const { channel } = useQueryParams();
  const channelSlug = channel as string | undefined;

  const { data: channelData, isLoading: isLoadingChannelData } = useQuery({
    ...queries.channels.detail(channelSlug ?? ''),
    enabled: Boolean(channelSlug),
  });

  return {
    isFromChannel: Boolean(channelSlug),
    isLoading: isLoadingChannelData,
    channelId: get(channelData, 'id'),
  };
}
