import moment from 'moment-timezone';
import { Event as TEvent } from 'react-big-calendar';

import { Session } from '~/common/types';
import { Event, Timeslot } from '~/app/event/interfaces';
import { normalizeInterval } from '~/services/datetime';
import { map } from 'lodash-es';

// The calendar component requires Date objects to work. This workaround is needed
// because the moment.toDate() method ignores the object's timezone, always using
// the machine's local timezone and we want to keep the user's timezone.

// The calendar component requires Date objects to work. This workaround is needed
// because the moment.toDate() method ignores the object's timezone, always using
// the machine's local timezone and we want to keep the user's timezone.
export const parseMomentToDate = (momentObj: moment.Moment) =>
  new Date(momentObj.format('YYYY-MM-DD HH:mm'));

interface TimeslotToCalendarEventProps {
  timeslot: Timeslot;
  eventName: string;
  eventTimezone: string;
  userTimezone: string;
}

export const parseTimeslotToCalendarEvent = ({
  timeslot,
  eventName,
  eventTimezone,
  userTimezone,
}: TimeslotToCalendarEventProps): TEvent => {
  const { start, end } = normalizeInterval(
    timeslot.starts_at_tz_aware,
    timeslot.ends_at_tz_aware,
    eventTimezone,
    userTimezone
  );

  return {
    title: eventName,
    start: parseMomentToDate(start),
    end: parseMomentToDate(end),
  };
};

interface EventToCalendarEventsProps {
  event: Event;
  userTimezone: string;
}

export const parseEventToCalendarEvents = ({
  event,
  userTimezone,
}: EventToCalendarEventsProps): TEvent[] =>
  map(event.timeslots, (timeslot: Timeslot) =>
    parseTimeslotToCalendarEvent({
      timeslot,
      eventName: event.name,
      eventTimezone: event.timezone || '',
      userTimezone,
    })
  );

interface SessionToCalendarEventProps {
  session: Session;
  userTimezone: string;
}

export const parseSessionToCalendarEvent = ({
  session,
  userTimezone,
}: SessionToCalendarEventProps) => {
  const { start, end } = normalizeInterval(
    session.starts_at_tz_aware,
    session.ends_at_tz_aware,
    session.timezone,
    userTimezone
  );

  return {
    title: session.name,
    start: parseMomentToDate(start),
    end: parseMomentToDate(end),
  };
};
