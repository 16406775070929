import PropTypes from 'prop-types';
import React from 'react';

import PeriodSelector from '~/app/inputs/components/PeriodSelector';
import { formatDate } from '~/services/datetime';
import propTypes from '~/services/prop-types';
import DropdownBase from '~/app/shared/components/DropdownBase';
import { includes, pick, isEqual, map, filter } from 'lodash-es';

export const getLabel = ({ selectOptions, startDate, endDate, period, customLabel }) => {
  let title = 'Period';
  if (customLabel) {
    title = customLabel;
  }

  const selectedOptions = filter(selectOptions, ['value', period]);

  if (selectedOptions.length > 0) {
    title = selectedOptions[0].name;
  } else if (startDate || endDate) {
    const start = startDate ? formatDate(startDate) : '';
    const end = endDate ? ` - ${formatDate(endDate)}` : '';
    title = `${start}${end}`;
  }

  return title;
};

class PeriodSelectorAdapter extends React.Component {
  componentDidMount = () => {
    const {
      dropdownControls,
      noDefaultDates,
      selectOptions,
      startDate,
      endDate,
      period,
      customLabel,
    } = this.props;

    dropdownControls.setLabel(getLabel({ selectOptions, startDate, endDate, period, customLabel }));
    dropdownControls.setLabelActive(true);
    if (noDefaultDates) {
      dropdownControls.setLabelActive(false);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { selectOptions, dropdownControls, period, startDate, endDate, customLabel } = this.props;

    if (
      !isEqual(
        [prevProps.period, prevProps.startDate, prevProps.endDate],
        [period, startDate, endDate]
      )
    ) {
      dropdownControls.setLabel(
        getLabel({ selectOptions, startDate, endDate, period, customLabel })
      );
      if (includes(map(selectOptions, 'value'), period)) {
        dropdownControls.close();
      }
    }
  };

  render = () => {
    const { open } = this.props;
    if (!open) {
      // There's some bug in the datepicker component that makes it
      // show blank in case it's displayed imediatelly
      // (or in a hidden component, not sure)
      return null;
    }

    return (
      <PeriodSelector
        {...pick(this.props, [
          'onChange',
          'children',
          'period',
          'startDate',
          'endDate',
          'periodParam',
          'dateStartParam',
          'dateEndParam',
          'formatDateOutput',
          'selectOptions',
          'limitWidth',
          'noDefaultDates',
          'showClearButton',
        ])}
      />
    );
  };
}

PeriodSelectorAdapter.propTypes = {
  children: propTypes.nodeOrList,
  open: PropTypes.bool,
  period: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  name: PropTypes.string,
  items: PropTypes.array,
  selecteds: PropTypes.array,
  onChange: PropTypes.func,
  singleSelect: PropTypes.bool,
  fixedName: PropTypes.bool,
  emptyOptionText: PropTypes.string,
  capitalizeTitle: PropTypes.bool,
  dropdownControls: PropTypes.object,
  selectOptions: PropTypes.array,
  customLabel: PropTypes.string,
  noDefaultDates: PropTypes.bool,
};

PeriodSelectorAdapter.defaultProps = {
  selectOptions: [
    {
      name: 'Upcoming',
      value: 'upcoming',
    },
    {
      name: 'Past',
      value: 'previous',
    },
  ],
};

const Dropdown = (props) => {
  const {
    labelName,
    openInBand,
    openLeft,
    labelContentMargin,
    disabled,
    Label,
    isInline,
    ...rest
  } = props;

  return (
    <DropdownBase
      isInline={isInline}
      labelName={labelName}
      Label={Label}
      disabled={disabled}
      openInBand={openInBand}
      openLeft={openLeft}
      labelContentMargin={labelContentMargin}
    >
      <PeriodSelectorAdapter {...rest} />
    </DropdownBase>
  );
};

DropdownBase.defaultProps = {
  labelName: null,
};

Dropdown.propTypes = {
  openInBand: PropTypes.bool,
  openLeft: PropTypes.bool,
  labelContentMargin: PropTypes.number,
  disabled: PropTypes.bool,
  labelName: PropTypes.string,
  Label: PropTypes.func,
  periodSelector: PropTypes.func,
  selectOptions: PropTypes.array,
  isInline: PropTypes.bool,
  limitWidth: PropTypes.bool,
};

export default Dropdown;
