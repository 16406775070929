import {
  ASSIGNMENT_STATES_ICONS,
  ASSIGNMENT_STATES_LABELS,
  ASSIGNMENT_STATES,
} from '~/app/assignments/constants';
import { displayDate } from '~/services/datetime';
import { Assignment } from '~/app/shared-content-item/interfaces';
import { get, isEmpty } from 'lodash-es';
import { blue, green, lightBlue, pink } from '@mui/material/colors';
import { Typography } from '~/common/components/Typography';
import { Chip, Tooltip as MUITooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 320,
  },
});

const ASSIGNMENT_STATES_CHIP_BACKGROUND_COLORS = {
  [ASSIGNMENT_STATES.completed]: green[200],
  [ASSIGNMENT_STATES.exempted]: blue[50],
  [ASSIGNMENT_STATES.dropped]: pink[50],
  [ASSIGNMENT_STATES.in_progress]: lightBlue[200],
};

interface AssignmentStateChipProps {
  assignment: Assignment;
  size?: 'small' | 'medium';
}

const AssignmentStateChip = ({ assignment, size = 'small' }: AssignmentStateChipProps) => {
  const { state, exemption, completion, drop } = assignment;
  const Icon = ASSIGNMENT_STATES_ICONS[state];
  const label = ASSIGNMENT_STATES_LABELS[state];
  const backgroundColor = ASSIGNMENT_STATES_CHIP_BACKGROUND_COLORS[state];
  const sx = { borderRadius: 1, backgroundColor };

  switch (state) {
    case ASSIGNMENT_STATES.completed: {
      const completedAt = get(completion, 'completed_at');
      const tooltipTitle = completedAt ? (
        <Typography variant="caption">
          Completed at <strong>{displayDate(completedAt)}</strong>
        </Typography>
      ) : (
        ''
      );
      return (
        <Tooltip title={tooltipTitle}>
          <Chip label={label} size={size} sx={sx} icon={<Icon />} />
        </Tooltip>
      );
    }

    case ASSIGNMENT_STATES.exempted: {
      const exemptedByName = get(exemption, 'exempted_by.name');
      const exemptedAt = get(exemption, 'exempted_at');
      const tooltipTitle = !isEmpty(exemptedAt) ? (
        <Typography variant="caption">
          Marked as exempted{' '}
          {!isEmpty(exemptedByName) && (
            <>
              by <strong>{exemptedByName}</strong>{' '}
            </>
          )}
          on <strong>{displayDate(exemptedAt)}</strong>
        </Typography>
      ) : (
        ''
      );
      return (
        <Tooltip title={tooltipTitle}>
          <Chip label={label} size={size} sx={sx} icon={<Icon />} />
        </Tooltip>
      );
    }

    case ASSIGNMENT_STATES.dropped: {
      const droppedAt = get(drop, 'dropped_at');
      const tooltipTitle = droppedAt ? (
        <Typography variant="caption">
          Dropped at <strong>{displayDate(droppedAt)}</strong>
        </Typography>
      ) : (
        ''
      );
      return (
        <Tooltip title={tooltipTitle}>
          <Chip label={label} size={size} sx={sx} icon={<Icon />} />
        </Tooltip>
      );
    }

    case ASSIGNMENT_STATES.in_progress: {
      return <Chip label={label} size={size} sx={sx} icon={<Icon />} />;
    }

    case ASSIGNMENT_STATES.not_started: {
      return <Chip label={label} size={size} sx={sx} />;
    }

    default: {
      return null;
    }
  }
};

export default AssignmentStateChip;
