import { useState } from 'react';
import styled from 'styled-components';

import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { launchCodelabRequest } from '~/app/codelab/services';
import { useAssignmentActionsCtx } from '~/features/contentitems/contexts/AssignmentActionsCtx';
import { useIsRenderedWithinTrack } from '~/features/tracks/hooks/useIsRenderedWithinTrack';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import Button from '~/app/shared/components/Button';
import { get, isNil } from 'lodash-es';
import { OpenInNewOutlinedIcon } from '~/vendor/mui-icons';

const Container = styled.div`
  min-height: 40px; // Large Button height
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
`;

interface PrimaryActionButtonProps {
  content: ContentItem;
}

const PrimaryActionButton = (props: PrimaryActionButtonProps) => {
  const { content } = props;

  const isRenderedWithinTrack = useIsRenderedWithinTrack();

  const [isCodelabLaunchLoading, setIsCodelabLaunchLoading] = useState(false);
  const { trackActivity } = useMetrics();

  const assignment = content.user_assignment ?? content.assignment;
  const assignmentState = get(assignment, 'state');
  const isCompleted = assignmentState === ASSIGNMENT_STATES.completed;

  const displayAsPagePrimaryAction = !isRenderedWithinTrack || !isCompleted;

  const { isLoading } = useAssignmentActionsCtx();

  const handleCodelabLaunch = () => {
    trackActivity(METRICS_ACTIVITIES.CODELAB_LAUNCH, {
      codelabId: content.id,
      firstLaunch: isNil(content?.assignment?.version),
    });
    setIsCodelabLaunchLoading(true);
    launchCodelabRequest(content, () => {
      setIsCodelabLaunchLoading(false);
    });
  };

  return (
    <Container>
      <Button
        variant={displayAsPagePrimaryAction ? 'contained' : 'outlined'}
        startIcon={<OpenInNewOutlinedIcon />}
        disabled={isCodelabLaunchLoading || isLoading}
        fullWidth
        onClick={handleCodelabLaunch}
      >
        {isCodelabLaunchLoading
          ? 'Launching...'
          : isCompleted
            ? 'Relaunch'
            : assignmentState === ASSIGNMENT_STATES.in_progress
              ? 'Continue'
              : 'Launch'}
      </Button>
    </Container>
  );
};

export default PrimaryActionButton;
