/* eslint-disable func-names */
// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
(function (arr) {
  for (const item of arr) {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('remove')) {
      continue;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }
        // eslint-disable-next-line unicorn/prefer-node-remove
        this.remove();
      },
    });
  }
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
