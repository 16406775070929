import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';

import { rqlHasValue, useCurrentExpressionWithoutPage } from '~/app/backoffice/utils';
import { Button } from '~/app/shared';
import OperatorLabel from '~/app/backoffice/components/Dashboard/OperatorLabel';
import {
  SaveSegmentButton,
  SaveSegmentModal,
} from '~/app/backoffice/components/Dashboard/Segments';
import ShareSegmentButton from '~/app/backoffice/components/Dashboard/Segments/ShareSegmentButton';
import { DashboardSegmentsContext } from '~/app/backoffice/context';
import { useCurrentSegment, useUpdateSegment } from '~/app/backoffice/hooks';
import Autocomplete from '~/app/inputs/components/Autocomplete';
import { PUBLIC_REPORT_URLS } from '~/scenes/Dashboard/constants';
import colors from '~/services/colors';
import HelpLink from '~/app/shared/components/HelpLink';
import { useToggles } from '~/app/shared/hooks';
import {
  filter,
  includes,
  isEmpty,
  map,
  concat,
  keys,
  head,
  isNil,
  size,
  get,
  has,
} from 'lodash-es';
import { Box, ClickAwayListener, Grid } from '@mui/material';
import { AddIcon } from '~/vendor/mui-icons';

const FilterBarItemWrapper = styled.div`
  padding-top: 4px;
`;

const MarginFilter = styled.div`
  margin-right: 35px;
`;

const HelpLinkWrapper = styled.div`
  padding-top: 10px;
`;

const RQLDashboardFilterBar = ({
  contentType,
  filters,
  allFilterNames,
  defaultFilterNames,
  filterComponentMapping,
  filterMenuOptionsMapping,
  addFilter,
  removeFilter,
  updateFilter,
  updateFilters,
  enableSegments,
}) => {
  const toggles = useToggles();
  const [showButton, setShowButton] = useState(true);

  const currentSegment = useCurrentSegment();
  const { update: updateSegment } = useUpdateSegment();
  const isSegment = !isEmpty(currentSegment);
  const [showSaveNewSegmentModal, setShowSaveNewSegmentModal] = useState(false);

  const expression = useCurrentExpressionWithoutPage();
  const helpFilterLink =
    'https://help.plusplus.app/en/articles/6473477-how-dynamic-filters-work-on-dashboards';

  // We get this function from React Context instead of creating a new
  // hook instance because we must connect to the exact instance that is
  // has the data that's being rendered in the sidebar
  const { fetchDashboardSegments } = useContext(DashboardSegmentsContext);

  const hasAddedDefaultFiltersRef = useRef(false);

  useEffect(() => {
    const hasAddedDefaultFilters = hasAddedDefaultFiltersRef.current;

    if (isNil(filters) || !isNil(currentSegment.public_id) || hasAddedDefaultFilters) {
      return;
    }

    const filterNames = map(filters, (filterObj) => head(keys(filterObj)));
    const filtersToAdd = filter(
      defaultFilterNames,
      (filterName) => !includes(filterNames, filterName)
    );

    if (size(filtersToAdd) === 0) {
      return;
    }

    updateFilters(
      concat(
        filters,
        map(filtersToAdd, (filterName) => ({ [filterName]: null }))
      )
    );

    hasAddedDefaultFiltersRef.current = true;
  }, [filters]);

  const shouldRenderSaveSegmentButton =
    enableSegments && size(filter(filters, (filterObj) => rqlHasValue(filterObj))) > 0;

  const renderComponent = ({ filter, onChange, handleRemoveFilter, index }) => {
    const filterName = head(keys(filter));
    if (isNil(filterName)) return null;
    const component = filterComponentMapping[filterName];
    const key = `filterbar-component-${filterName}-${index}`;
    return React.cloneElement(component, {
      filter,
      onChange,
      handleRemoveFilter,
      key,
    });
  };

  const getOptions = () => {
    return map(allFilterNames, (option) => ({
      label: filterMenuOptionsMapping[option].title,
      value: option,
    }));
  };

  const handleClick = () => {
    setShowButton(!showButton);
  };

  const handleClickAway = () => {
    setShowButton(true);
  };

  const toogleShareReport = get(toggles, 'toggle_admin_users_can_share_reports', false);
  const sharePublicReports =
    has(PUBLIC_REPORT_URLS, currentSegment.content_type) && toogleShareReport;

  return (
    <Box
      sx={{ flexGrow: 1, border: `1px solid ${colors.neutral200}`, borderRadius: 1, p: 2, mb: 2 }}
    >
      {filterComponentMapping && (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1}
          sx={{ display: 'flex', alignItems: 'flex-start' }}
        >
          {map(filters, (filter, index, { length }) => (
            <Grid key={`filter-${index}`} item sx={{ display: 'flex', alignItems: 'center' }}>
              {renderComponent({
                filter,
                onChange: (filter) => updateFilter(index, filter),
                handleRemoveFilter: () => removeFilter(index),
                index,
              })}
              {index < length - 1 ? <OperatorLabel name="and" /> : !showButton && <MarginFilter />}
            </Grid>
          ))}
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            {!isEmpty(allFilterNames) && (
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <ClickAwayListener onClickAway={handleClickAway}>
                  {showButton ? (
                    <FilterBarItemWrapper>
                      <Button
                        variant="text"
                        onClick={handleClick}
                        aria-label="Add Filter"
                        startIcon={<AddIcon />}
                      >
                        Add Filter
                      </Button>
                    </FilterBarItemWrapper>
                  ) : (
                    <Autocomplete
                      label="Choose a filter"
                      disabled={false}
                      options={getOptions()}
                      onChange={(newSelected) => {
                        setShowButton(!showButton);
                        addFilter({ [newSelected]: null });
                      }}
                      inputMinWidth="325px"
                    />
                  )}
                </ClickAwayListener>
              </Grid>
            )}
          </Grid>
          {isSegment && shouldRenderSaveSegmentButton && (
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <FilterBarItemWrapper>
                <SaveSegmentButton
                  onClick={() => updateSegment(currentSegment.public_id, { expression })}
                />
              </FilterBarItemWrapper>
            </Grid>
          )}
          {shouldRenderSaveSegmentButton && (
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <FilterBarItemWrapper>
                <SaveSegmentButton
                  label={isSegment ? 'Save As' : 'Save'}
                  onClick={() => setShowSaveNewSegmentModal(true)}
                />
                {showSaveNewSegmentModal && (
                  <SaveSegmentModal
                    contentType={contentType}
                    refreshSegments={() => fetchDashboardSegments({ isRefetching: true })}
                    handleClose={() => setShowSaveNewSegmentModal(false)}
                  />
                )}
              </FilterBarItemWrapper>
            </Grid>
          )}
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <HelpLinkWrapper>
              <HelpLink url={helpFilterLink}>Learn more</HelpLink>
            </HelpLinkWrapper>
          </Grid>

          {isSegment && sharePublicReports && (
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <FilterBarItemWrapper>
                <ShareSegmentButton segment={currentSegment} />
              </FilterBarItemWrapper>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

RQLDashboardFilterBar.defaultProps = {
  enableSegments: true,
};

RQLDashboardFilterBar.propTypes = {
  contentType: PropTypes.string,
  filters: PropTypes.array,
  allFilterNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultFilterNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterComponentMapping: PropTypes.object.isRequired,
  filterMenuOptionsMapping: PropTypes.object.isRequired,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  enableSegments: PropTypes.bool,
};

export default RQLDashboardFilterBar;
