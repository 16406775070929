import { rqlToInput } from '~/app/backoffice/components/Dashboard/Filters/utils';
import { Option } from '~/app/filters/components/inputs/Select/Select';
import { useGetAllCachedOptions } from '~/app/filters/hooks';
import { RQLFilter } from '~/app/shared/components/types';
import { useLabels } from '~/app/shared/hooks';
import { get, size, endsWith } from 'lodash-es';

const possess = (s: string) => {
  if (!s) return '';
  if (endsWith(s, 's')) return `${s}'`;
  return `${s}'s`;
};

interface PeopleDirectoryPageNameProps {
  filters: RQLFilter | null;
}

export const usePeopleDirectoryPageTitle = ({ filters }: PeopleDirectoryPageNameProps) => {
  const { label_people_directory: peopleDirectoryLabel } = useLabels();
  // Cached options
  const allTitles = useGetAllCachedOptions({ keyPrefix: 'users', key: 'titles' });
  const allDepartments = useGetAllCachedOptions({ keyPrefix: 'users', key: 'departments' });
  const allLocations = useGetAllCachedOptions({ keyPrefix: 'locations', key: 'list' });
  const allGroups = useGetAllCachedOptions({ keyPrefix: 'groups', key: 'list' });
  const allUsers = useGetAllCachedOptions({ keyPrefix: 'old_users' });
  // Values
  const searchValue = get(filters, 'q.$eq', '');
  const selectedTitles = rqlToInput(get(filters, 'title'));
  const selectedDepartments = rqlToInput(get(filters, 'department'));
  const selectedLocations = rqlToInput(get(filters, 'location'));
  const selectedGroups = rqlToInput(get(filters, 'group'));
  const selectedOrgLeads = rqlToInput(get(filters, 'org_lead'));
  const selectedManagers = rqlToInput(get(filters, 'manager'));
  const selectedTeammates = rqlToInput(get(filters, 'teammate'));
  // helpers
  const getLabel = (cached: Record<string, Option>, value: string, attr = 'name'): string => {
    return get(cached, `${value}.${attr}`, '') as string;
  };
  // Labels
  const titleLabel = size(selectedTitles) === 1 ? getLabel(allTitles, selectedTitles[0]) : null;
  const departmentLabel =
    size(selectedDepartments) === 1 ? getLabel(allDepartments, selectedDepartments[0]) : null;
  const locationLabel =
    size(selectedLocations) === 1 ? getLabel(allLocations, selectedLocations[0]) : null;
  const groupLabel = size(selectedGroups) === 1 ? getLabel(allGroups, selectedGroups[0]) : null;
  const orgLeadLabel =
    size(selectedOrgLeads) === 1 ? getLabel(allUsers, selectedOrgLeads[0], 'display_name') : null;
  const managerLabel =
    size(selectedManagers) === 1 ? getLabel(allUsers, selectedManagers[0], 'display_name') : null;
  const teammateLabel =
    size(selectedTeammates) === 1 ? getLabel(allUsers, selectedTeammates[0], 'display_name') : null;
  // Possible titles
  const titles = [
    ...(searchValue ? [`People matching '${searchValue}'`] : []),
    ...(titleLabel ? [`People with title matching '${titleLabel}'`] : []),
    ...(departmentLabel ? [`People with department matching '${departmentLabel}'`] : []),
    ...(locationLabel ? [`People with '${locationLabel}' location`] : []),
    ...(groupLabel ? [`People in '${groupLabel}' group`] : []),
    ...(orgLeadLabel ? [`${possess(orgLeadLabel)} org`] : []),
    ...(managerLabel ? [`${possess(managerLabel)} directs`] : []),
    ...(teammateLabel ? [`${possess(teammateLabel)} teammates`] : []),
  ];
  return size(titles) === 1 ? titles[0] : peopleDirectoryLabel;
};
