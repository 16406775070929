import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Modal, { ModalBody } from '~/app/shared/components/Modal';

import EventUserListModal from './EventUserListModal';
import InviteUsersForm from './InviteUsersForm';

const EventInviteUsersModal = ({ event, handleClose }) => {
  const [showEventUserListModal, setShowEventUserListModal] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inviteMessage, setInviteMessage] = useState('');

  useEffect(() => {
    resetForm();
  }, []);

  const onFormSubmitSuccess = (result, dispatch, props) => {
    const { values } = props;
    setInviteMessage(values.invite_message);
    setFormSubmitted(true);
    setShowEventUserListModal(true);
  };

  const resetForm = () => {
    setFormSubmitted(false);
    setInviteMessage('');
  };

  const handleEventUserListModalClose = () => {
    setShowEventUserListModal(false);
    handleClose();
    resetForm();
  };

  if (formSubmitted) {
    // The pattern boolean && component cannot be used here, because it can return
    // a boolean value and this component should always return a JSX component.
    if (showEventUserListModal) {
      return (
        <EventUserListModal
          modalTitle="Invite Sent"
          closeButtonText="Back to Event"
          message={inviteMessage}
          handleClose={handleEventUserListModalClose}
        />
      );
    }
    return null;
  }

  const initialValues = {
    public_id: event.public_id,
    invite_message: `Check out ${event.name}`,
  };

  return (
    <Modal
      handleClose={handleClose}
      onExited={resetForm}
      title="Invite People"
      width={window.innerWidth > 768 ? 600 : 'auto'}
    >
      <ModalBody>
        <InviteUsersForm
          initialValues={initialValues}
          submitButtonText="Send Invites"
          onSubmitSuccess={onFormSubmitSuccess}
        />
      </ModalBody>
    </Modal>
  );
};

EventInviteUsersModal.propTypes = {
  values: PropTypes.object,
  event: PropTypes.object,
  handleClose: PropTypes.func,
};

export default EventInviteUsersModal;
