import PropTypes from 'prop-types';
import React from 'react';

import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import {
  LEARNING_TYPES,
  COMPLETED_STATUS,
  CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING,
} from '~/app/catalog/constants';
import actions from '~/app/entities/actions';
import {
  articleSchema,
  codelabSchema,
  contentSchema,
  scheduledTrackSchema,
  taskSchema,
  trackSchema,
  videoSchema,
} from '~/app/entities/schema';
import { FeedbackModal } from '~/app/shared-cards';
import { useToggles } from '~/app/shared/hooks';
import { get, includes, keys } from 'lodash-es';

const ContentRating = (props) => {
  const { content, onSubmitFunc, handleClose } = props;

  const contentType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[content.content_type];

  const isCompleted =
    get(content, 'assignment.completion_status') === COMPLETED_STATUS ||
    get(content, 'registration.completion_status') === COMPLETED_STATUS ||
    // new format in the CA-2.0
    get(content, 'assignment.state') === ASSIGNMENT_STATES.completed ||
    get(content, 'user_assignment.state') === ASSIGNMENT_STATES.completed;

  const { toggle_feedback_from_content_is_public: feedbackContentIsPublic } = useToggles();

  if (!isCompleted) {
    return null;
  }

  const currentFeedbackText =
    get(content, 'assignment.feedback_text') ||
    get(content, 'registration.feedback_text') ||
    // new format in the CA-2.0
    get(content, 'assignment.rating.feedback') ||
    get(content, 'user_assignment.rating.feedback') ||
    '';
  const currentFeedbackRating =
    get(content, 'assignment.feedback_rating') ||
    get(content, 'registration.feedback_rating') ||
    // new format in the CA-2.0
    get(content, 'assignment.rating.rating') ||
    get(content, 'user_assignment.rating.rating') ||
    5;

  const schemaMapping = {
    [LEARNING_TYPES.articles]: articleSchema,
    [LEARNING_TYPES.linkedcontent]: contentSchema,
    [LEARNING_TYPES.assessments]: contentSchema,
    [LEARNING_TYPES.codelabs]: codelabSchema,
    [LEARNING_TYPES.scheduled_tracks]: scheduledTrackSchema,
    [LEARNING_TYPES.tasks]: taskSchema,
    [LEARNING_TYPES.tracks]: trackSchema,
    [LEARNING_TYPES.videos]: videoSchema,
  };

  // TODO: Use assignment id to access endpoint in the future, same way as course registration
  let requestId = content.id;
  let submitAction = null;
  let schema = null;
  if (contentType === LEARNING_TYPES.courses) {
    submitAction = actions.courseRegistration.update.toString();
    requestId = get(content, 'user_assignment.id') ?? get(content, 'assignment.id');
  } else if (includes(keys(schemaMapping), contentType)) {
    submitAction = actions.contentAssignment.update.toString();
    schema = schemaMapping[contentType];
  }

  return (
    <FeedbackModal
      id={requestId}
      feedbackText={currentFeedbackText}
      feedbackRating={currentFeedbackRating}
      handleClose={handleClose}
      onSubmitFunc={onSubmitFunc}
      onSubmitActionName={submitAction}
      fieldLabels={{
        rating: 'The Author requested your Rating',
        feedback: 'Please leave a review',
      }}
      fieldPlaceholders={{
        feedback: 'How was your experience with this content?',
      }}
      schema={schema}
      feedbackIsPublic={feedbackContentIsPublic}
    />
  );
};

ContentRating.propTypes = {
  content: PropTypes.object,
  onSubmitFunc: PropTypes.func,
  handleClose: PropTypes.func,
};

export default ContentRating;
