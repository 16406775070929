import PropTypes from 'prop-types';
import React from 'react';

import CheckboxInput from '~/app/inputs/components/CheckboxInput';

const SettingToggleField = ({ input, disabled, ariaLabel }) => {
  return (
    <CheckboxInput
      checked={input?.value.value}
      onChange={() =>
        input.onChange({
          ...input.value,
          value: !input.value.value,
          inherited: false,
        })
      }
      disabled={disabled}
      aria-label={ariaLabel}
    />
  );
};

SettingToggleField.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default SettingToggleField;
