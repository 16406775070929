import PropTypes from 'prop-types';
import React from 'react';

import { isGoogleFile } from '~/services/embed';
import { split } from 'lodash-es';

const formatGoogleDriveURLForPreview = (url) => {
  const id = split(split(url, 'drive.google.com/file/d/')[1], '/view')[0];
  return `https://drive.google.com/file/d/${id}/preview`;
};

export const EmbeddedVideoPlayer = ({ url, title, width, height, allowFullScreen }) => {
  return (
    <iframe
      title={title}
      src={isGoogleFile(url) ? formatGoogleDriveURLForPreview(url) : url}
      width={width}
      height={height}
      allowFullScreen={allowFullScreen}
      frameBorder="0"
    />
  );
};

EmbeddedVideoPlayer.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  allowFullScreen: PropTypes.string,
};

EmbeddedVideoPlayer.defaultProps = {
  width: '100%',
  height: '310px',
  allowFullScreen: '',
};
