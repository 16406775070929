import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import UserContentBlock from '~/app/users/components/UserContentBlock';
import { useCurrentUser } from '~/app/shared/hooks';
import { isEmpty, isEqual } from 'lodash-es';
import { Grid } from '@mui/material';
import {
  OfficeHourSection,
  EventSection,
  MentorshipSection,
  ContentItemSection,
  OverallImpactWidget,
} from '../ImpactWidgets';
import { OwnedContentSection } from '../ImpactWidgets/OwnedContentSection';
import { TestimonialsSection } from '../ImpactWidgets/TestimonialsSection';

import UserInfo from './UserInfo';

const UserDetails = ({ profileData, toggleTestimonials, toggleMentorsTestimonials }) => {
  const hasTestimonials = toggleMentorsTestimonials
    ? toggleTestimonials && profileData.is_mentor
    : toggleTestimonials;

  const { id: userId } = useParams();
  const currentUser = useCurrentUser();

  const { display_name: displayName, skills, bio } = profileData;
  const isUser = isEqual(profileData.id, currentUser.id);
  // show the UserContentBlock for UserInfo to others only if there is bio or skills to show
  const showUserInfo = !isEmpty(bio) || !isEmpty(skills) || isUser;
  return (
    <Grid container spacing={1}>
      {showUserInfo && (
        <Grid item xs={12}>
          <UserContentBlock>
            <UserInfo profileData={profileData} />
          </UserContentBlock>
        </Grid>
      )}
      <Grid item xs={12}>
        <OwnedContentSection userId={userId} displayName={displayName} />
      </Grid>
      {hasTestimonials && (
        <Grid item xs={12}>
          <UserContentBlock>
            <TestimonialsSection />
          </UserContentBlock>
        </Grid>
      )}
      <Grid item xs={12}>
        <OverallImpactWidget />
      </Grid>
      <Grid item xs={12}>
        <EventSection userId={userId} />
      </Grid>
      <Grid item xs={12}>
        <MentorshipSection userId={userId} />
      </Grid>

      <Grid item xs={12}>
        <OfficeHourSection userId={userId} />
      </Grid>
      <Grid item xs={12}>
        <ContentItemSection userId={userId} />
      </Grid>
    </Grid>
  );
};

UserDetails.propTypes = {
  profileData: PropTypes.object,
  toggleTestimonials: PropTypes.bool,
  toggleMentorsTestimonials: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    toggleTestimonials: state.user.currentUser.toggles.toggle_testimonials,
    toggleMentorsTestimonials: state.user.currentUser.toggles.toggle_mentors_testimonials,
  };
};

export default connect(mapStateToProps)(UserDetails);
