import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import { useGetEventLabelWithDatetimeRange } from '~/app/event/hooks';
import { Event } from '~/app/event/interfaces';
import colors from '~/services/colors';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { get } from 'lodash-es';
import { grey } from '@mui/material/colors';
import { Typography } from '~/common/components/Typography';
import { Link } from '~/common/components/Links';
import { Box, CardMedia, IconButton, Paper } from '@mui/material';
import { ArrowBack } from '~/vendor/mui-icons';

import { useGetContentTypeLabelWithDuration } from '../hooks';

interface LiteHeaderProps {
  content: ContentItem | Event;
}
const LiteHeader = ({ content }: LiteHeaderProps) => {
  const history = useHistory();
  const useGetInfo =
    content.content_type === CONTENT_TYPES.event
      ? useGetEventLabelWithDatetimeRange // is event, show datetime range
      : useGetContentTypeLabelWithDuration; // is another content type, show duration

  const contentInfo = useGetInfo(content);
  const contentTypeRoutes = useContentTypeRoutes();

  const detailRouteFn = get(contentTypeRoutes, `${content.content_type}.details`) as any;
  const detailRoute = detailRouteFn ? detailRouteFn(content) : '';

  const handleBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(detailRoute);
    }
  }, [history, detailRoute]);

  return (
    <Paper
      sx={{
        display: 'flex',
        columnGap: 2,
        alignSelf: 'center',
        alignItems: 'center',
        borderRadius: 1,
        width: '80%',
        height: '70px',
        mt: 3,
        p: 1,
        pl: 3,
      }}
    >
      <IconButton onClick={handleBack}>
        <ArrowBack sx={{ color: colors.action700 }} fontSize="small" />
      </IconButton>
      <CardMedia
        component="img"
        alt={content.name}
        src={content.cover_url || content.cover}
        sx={{
          width: '100px',
          height: '50px',
          objectFit: 'contain',
          borderStyle: 'solid',
          borderColor: grey['400'],
          borderRadius: 1,
          borderWidth: '1px',
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 0, ml: 1, overflow: 'hidden' }}>
        <Link variant="h6" noWrap href={detailRoute} underline="hover" color={grey['900']}>
          {content.name}
        </Link>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <Typography variant="caption">{contentInfo}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default LiteHeader;
