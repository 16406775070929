import { ContentItem } from '~/app/shared-content-item/interfaces';
import { TrackContentItemCard } from '~/features/tracks/components/TrackContentItemCard/TrackContentItemCard';

type ScheduledTrackContentItemCardProps = {
  scheduledTrack: ContentItem;
};

export function ScheduledTrackContentItemCard(props: ScheduledTrackContentItemCardProps) {
  const { scheduledTrack } = props;

  return <TrackContentItemCard track={scheduledTrack} />;
}
