import { Badge } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  EnrollmentStatusPropType,
  PerformEnrollmentActionsPropType,
  SpotsStatusPropType,
} from '~/app/event-shared/components/AttendanceActionManager/EnrollmentActionButton/propTypes';
import RequestEventModal from '~/app/event-types/components/RequestEventModal';
import colors from '~/services/colors';
import { getOrdinalNumber } from '~/services/utils';
import Button from '~/app/shared/components/Button';
import { get } from 'lodash-es';

const SubText = styled.span`
  color: ${({ danger }) => (danger ? `  ${colors.error600}` : `${colors.neutral600}`)};
  font-size: 14px;
  letter-spacing: -0.34px;
  font-weight: normal;
`;

const Container = styled.div`
  & + & {
    margin-top: 12px;
  }
  flex: 1 50%;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const DetailsAttendanceWaitlistButton = ({
  performLeaveWaitlist,
  userIsOnLocalWaitlist,
  localWaitlistCurrentSpot,
  onlineWaitlistCurrentSpot,
}) => {
  const inPersonLabel = 'Leave In-Person Waitlist';
  const label = userIsOnLocalWaitlist ? inPersonLabel : 'Leave Online Waitlist';
  const currentSpot = userIsOnLocalWaitlist ? localWaitlistCurrentSpot : onlineWaitlistCurrentSpot;
  return (
    <Container>
      <Button
        onClick={performLeaveWaitlist}
        color="error"
        size="large"
        fullWidth
        subText={`You are ${getOrdinalNumber(currentSpot)}`}
      >
        {label}
      </Button>
    </Container>
  );
};

const DetailsAttendanceEnrolledButton = ({
  performUnenroll,
  userIsEnrolledLocal,
  unenrollLabel,
}) => {
  const inPersonLabel = 'Unenroll In-Person';
  const label = unenrollLabel || (userIsEnrolledLocal ? inPersonLabel : 'Unenroll Online');
  return (
    <Container>
      <Button onClick={performUnenroll} color="error" size="large" fullWidth alignLeft>
        {label}
      </Button>
    </Container>
  );
};

const DetailsAttendanceEnrollButtons = ({
  actions: {
    performJoinLocalWaitlist,
    performJoinOnlineWaitlist,
    performLocalEnrollment,
    performOnlineEnrollment,
  },
  status: {
    showLocalWaitlist,
    showOnlineWaitlist,
    showLocalEnroll,
    showOnlineEnroll,
    userIsOnWaitlist,
    userIsEnrolled,
  },
  spotStatus: {
    localWaitlistPretendedSpot,
    localWaitlistSpotsLeft,
    localSpotsLeft,
    hasUnlimitedLocalSpots,
    onlineWaitlistPretendedSpot,
    onlineWaitlistSpotsLeft,
    onlineSpotsLeft,
    hasUnlimitedOnlineSpots,
  },
}) => {
  const localEnrollLabel = 'Enroll In-Person';
  const localWaitlistLabel = 'In-Person Waitlist';
  const isProspect = !userIsOnWaitlist && !userIsEnrolled;

  return (
    <React.Fragment>
      {showLocalWaitlist && (
        <Container>
          <Badge
            color="primary"
            badgeContent={
              isProspect
                ? `${localWaitlistSpotsLeft} spots left`
                : `You will be ${getOrdinalNumber(localWaitlistPretendedSpot)}`
            }
          >
            <Button onClick={performJoinLocalWaitlist} color="secondary" size="large" fullWidth>
              {localWaitlistLabel}
            </Button>
          </Badge>
        </Container>
      )}
      {showOnlineWaitlist && (
        <Container>
          <Badge
            color="primary"
            badgeContent={
              isProspect
                ? `${onlineWaitlistSpotsLeft} spots left`
                : `You will be ${getOrdinalNumber(onlineWaitlistPretendedSpot)}`
            }
          >
            <Button onClick={performJoinOnlineWaitlist} color="secondary" size="large" fullWidth>
              Online Waitlist
            </Button>
          </Badge>
        </Container>
      )}
      {showLocalEnroll && (
        <Container>
          <Button
            onClick={performLocalEnrollment}
            size="large"
            fullWidth
            subText={
              hasUnlimitedLocalSpots
                ? 'Unlimited spots'
                : `${localSpotsLeft} ${localSpotsLeft === 1 ? 'spot' : 'spots'} left`
            }
          >
            {localEnrollLabel}
          </Button>
        </Container>
      )}
      {showOnlineEnroll && (
        <Container>
          <Button
            onClick={performOnlineEnrollment}
            size="large"
            fullWidth
            subText={
              hasUnlimitedOnlineSpots
                ? 'Unlimited spots'
                : `${onlineSpotsLeft} ${onlineSpotsLeft === 1 ? 'spot' : 'spots'} left`
            }
          >
            Enroll Online
          </Button>
        </Container>
      )}
    </React.Fragment>
  );
};

DetailsAttendanceWaitlistButton.propTypes = {
  userIsOnLocalWaitlist: PropTypes.bool,
  performLeaveWaitlist: PropTypes.func,
  localWaitlistCurrentSpot: PropTypes.number,
  onlineWaitlistCurrentSpot: PropTypes.number,
};

DetailsAttendanceEnrolledButton.propTypes = {
  performUnenroll: PropTypes.func,
  userIsEnrolledLocal: PropTypes.bool,
  unenrollLabel: PropTypes.string,
};

DetailsAttendanceEnrollButtons.propTypes = {
  actions: PerformEnrollmentActionsPropType,
  status: EnrollmentStatusPropType,
  spotStatus: SpotsStatusPropType,
};

export const DetailsAttendanceRenderActions = ({
  actions,
  actions: { performUnenroll, performLeaveWaitlist },
  status,
  status: { userIsOnWaitlist, userIsEnrolled, userIsOnLocalWaitlist, userIsEnrolledLocal },
  spotStatus,
  spotStatus: { localWaitlistCurrentSpot, onlineWaitlistCurrentSpot },
  unenrollLabel,
}) => {
  return (
    <ActionContainer>
      {userIsOnWaitlist && (
        <DetailsAttendanceWaitlistButton
          userIsOnLocalWaitlist={userIsOnLocalWaitlist}
          performLeaveWaitlist={performLeaveWaitlist}
          localWaitlistCurrentSpot={localWaitlistCurrentSpot}
          onlineWaitlistCurrentSpot={onlineWaitlistCurrentSpot}
        />
      )}
      {userIsEnrolled && (
        <DetailsAttendanceEnrolledButton
          userIsEnrolledLocal={userIsEnrolledLocal}
          performUnenroll={performUnenroll}
          unenrollLabel={unenrollLabel}
        />
      )}
      <DetailsAttendanceEnrollButtons actions={actions} status={status} spotStatus={spotStatus} />
    </ActionContainer>
  );
};

DetailsAttendanceRenderActions.propTypes = {
  actions: PerformEnrollmentActionsPropType,
  status: EnrollmentStatusPropType,
  spotStatus: SpotsStatusPropType,
  unenrollLabel: PropTypes.string,
};

export const DetailsAttendanceRenderUncancellableEnroll = ({ unenrollLabel }) => (
  <ActionContainer>
    <Container>
      <Button fullWidth disabled title="You are enrolled in the event and cancellation is closed">
        {unenrollLabel || 'Unenroll'}
      </Button>
    </Container>
  </ActionContainer>
);

DetailsAttendanceRenderUncancellableEnroll.propTypes = {
  unenrollLabel: PropTypes.string,
};

export const DetailsAttendanceRenderFullCapacity = (props) => {
  const { event } = props;
  const { event_type: eventType } = event;

  const toggleRequestEvent = get(event, 'settings.toggle_request_event.value', false);
  const toggleAskForTeamSize = get(
    event,
    'settings.toggle_ask_for_team_size_request_an_event.value',
    false
  );

  return (
    <ActionContainer>
      <Container>
        {toggleRequestEvent && eventType ? (
          <RequestEventModal
            eventType={eventType}
            toggleAskForTeamSize={toggleAskForTeamSize}
            renderButton={({ handleModalOpen }) => (
              <Button color="secondary" fullWidth onClick={handleModalOpen} subText="No spots left">
                Request an event
              </Button>
            )}
          />
        ) : (
          <Button fullWidth disabled>
            <SubText>No spots left</SubText>
          </Button>
        )}
      </Container>
    </ActionContainer>
  );
};

DetailsAttendanceRenderFullCapacity.propTypes = {
  event: PropTypes.object,
};
