import { map } from 'lodash-es';

// Converting values to string so the logic for FilterOptions components
// assumes that all values are strings

// Converting values to string so the logic for FilterOptions components
// assumes that all values are strings
export const convertItemValuesToString = (items) =>
  map(items, (item) => ({
    ...item,
    value: item.value.toString(),
  }));
