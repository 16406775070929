import { useEffect, useRef } from 'react';

import HorizontalCardList from '~/app/shared/components/HorizontalCardList';
import CardListWrapper from '~/app/content-items/components/CardListWrapper';
import { translatorFactory } from '~/app/feature-parity/utils';
import { useMyHubFiltersDefinition } from '~/features/myhub/hooks/useMyHubFiltersDefinition';
import { useMyHubPageData } from '~/features/myhub/hooks/useMyHubPageData';
import { useMyHubPillsDefinition } from '~/features/myhub/hooks/useMyHubPillsDefinition';
import RQLFilterBar from '~/app/filters/components/RQLFilterBar';
import { useNewRqlFilters } from '~/app/filters/hooks';
import FilterPills from '~/app/navigation/components/FilterPills';
import colors from '~/services/colors';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import { OptimalContentItemCard, PersonCard } from '~/app/shared/components/Card';
import Loading from '~/app/shared/components/Loading';
import PageTitle from '~/app/shared/components/PageTitle';
import Text from '~/app/shared/components/Text';
import { useLabels } from '~/app/shared/hooks';
import { get, isEmpty } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Stack } from '@mui/material';
import { MyHubWidgets } from '../../components/MyHubWidgets';

type MyHubTopListsProps = {
  topEvents: any;
  topContents: any;
  topExperts: any;
  searchQuery?: string;
};

function MyHubTopLists(props: MyHubTopListsProps) {
  const { topEvents, topContents, topExperts, searchQuery } = props;

  const eventsCount = get(topEvents, 'count', 0);
  const contentsCount = get(topContents, 'count', 0);
  const expertsCount = get(topExperts, 'count', 0);

  const isSearching = !isEmpty(searchQuery);

  return (
    <Stack direction="column" spacing={3} pb={3}>
      {eventsCount > 0 && (
        <HorizontalCardList
          sectionTitle={`Top Upcoming Events ${isSearching ? 'related to your search' : ''}`}
          items={get(topEvents, 'results', [])}
          renderItem={(item) => {
            return (
              <OptimalContentItemCard key={`${item.content_type}_${item.id}`} contentItem={item} />
            );
          }}
          totalItemsCount={eventsCount}
          viewAllLabel={`View All ${eventsCount > 1 && eventsCount}`}
          viewAllUrl={`${mapRoute(
            'eventsList'
          )}?gt(start_time,-PT0H)&ordering(-total_assignments)&status=published${
            isSearching ? `&${searchQuery}` : ''
          }`}
        />
      )}

      {contentsCount > 0 && (
        <HorizontalCardList
          sectionTitle={`Top OnDemand Content ${isSearching ? 'related to your search' : ''}`}
          items={get(topContents, 'results', [])}
          renderItem={(item) => {
            return (
              <OptimalContentItemCard key={`${item.content_type}_${item.id}`} contentItem={item} />
            );
          }}
          totalItemsCount={contentsCount}
          viewAllLabel={`View All ${contentsCount > 1 && contentsCount}`}
          viewAllUrl={`${mapRoute(
            'unifiedCatalogList'
          )}?ordering(-total_assignments)&status=published${isSearching ? `&${searchQuery}` : ''}`}
        />
      )}

      {expertsCount > 0 && (
        <HorizontalCardList
          sectionTitle={`Top Experts ${isSearching ? 'related to your search' : ''}`}
          items={get(topExperts, 'results', [])}
          renderItem={(item) => {
            return <PersonCard key={item.id} person={item} />;
          }}
          totalItemsCount={expertsCount}
          viewAllLabel={`View All ${expertsCount > 1 && expertsCount}`}
          viewAllUrl={`${mapRoute('peopleList')}?ordering(-unique_people_impacted_count)${
            isSearching ? `&${searchQuery}` : ''
          }`}
        />
      )}
    </Stack>
  );
}

function MyHubEmptySearchState() {
  const { topEvents, topContents, topExperts, isLoadingData } = useMyHubPageData({
    rqlExpression: '',
  });

  return (
    <Stack direction="column" gap={3}>
      <Stack direction="column" alignItems="center">
        <Typography variant="h5">No content found based on your search</Typography>
        <Typography variant="body1">Try exploring these other options</Typography>
      </Stack>

      {isLoadingData ? (
        <Loading />
      ) : (
        <MyHubTopLists topEvents={topEvents} topContents={topContents} topExperts={topExperts} />
      )}
    </Stack>
  );
}

// Filters
const translator = translatorFactory({
  defaultOrdering: '',
  orderingOptions: {
    name: 'name',
    '-name': '-name',
    title: 'title',
    department: 'department',
    location: 'location.name',
  },
});

function MyHub() {
  const { trackActivity } = useMetrics();

  const { label_myhub: labelMyHub } = useLabels();

  const { filters, rqlExpression, updateFilter, removeValue } = useNewRqlFilters({
    translator,
  });

  const { topEvents, topContents, topExperts, isLoadingData } = useMyHubPageData({ rqlExpression });

  const eventsCount = get(topEvents, 'count', 0);
  const contentsCount = get(topContents, 'count', 0);
  const expertsCount = get(topExperts, 'count', 0);

  // Filters definition
  const { filters: filterDefinition } = useMyHubFiltersDefinition({
    filters,
    updateFilter,
  });

  // Pills definition
  const { pills } = useMyHubPillsDefinition({ filters });

  // Creating reference to mutable values to avoid re-renders
  const cachedTrackActivity = useRef(trackActivity);

  useEffect(() => {
    cachedTrackActivity.current = trackActivity;
  });

  // Track activity
  useEffect(() => {
    cachedTrackActivity.current(METRICS_ACTIVITIES.MY_HUB_VIEW);
  }, []);

  useEffect(() => {
    if (!isEmpty(rqlExpression)) {
      cachedTrackActivity.current(METRICS_ACTIVITIES.MY_HUB_SEARCH, { filters: rqlExpression });
    }
  }, [rqlExpression]);

  const isSearching = !isEmpty(rqlExpression);
  const hasContent = eventsCount > 0 || contentsCount > 0 || expertsCount > 0;

  return (
    <>
      <PageTitle title={labelMyHub} />
      <CardListWrapper>
        <Text size="h1" color={colors.neutral900} medium>
          {labelMyHub}
        </Text>

        <RQLFilterBar filters={filterDefinition} />

        <FilterPills
          pills={pills}
          onRemove={(item) => removeValue(get(item, 'filterName', ''), get(item, 'value', ''))}
        />

        {!isSearching && <MyHubWidgets />}

        {isLoadingData && <Loading />}

        {!isLoadingData && !hasContent && <MyHubEmptySearchState />}

        {!isLoadingData && hasContent && (
          <MyHubTopLists
            topEvents={topEvents}
            topContents={topContents}
            topExperts={topExperts}
            searchQuery={rqlExpression}
          />
        )}
      </CardListWrapper>
    </>
  );
}

export default MyHub;
