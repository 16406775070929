import React, { Suspense } from 'react';

import { lazyWithRetry } from '~/services/utils';
import LazyErrorBoundary from '~/app/shared/components/LazyErrorBoundary';
import Loading from '~/app/shared/components/Loading';

const TextEditor = lazyWithRetry(() => import(/* webpackChunkName: "TextEditor" */ './TextEditor'));

const LazyTextEditor = (props) => (
  <LazyErrorBoundary>
    <Suspense fallback={<Loading />}>
      <TextEditor {...props} />
    </Suspense>
  </LazyErrorBoundary>
);

export default LazyTextEditor;
