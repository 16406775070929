import PropTypes from 'prop-types';
import React from 'react';
import { Fields, reduxForm } from 'redux-form';

import EmailTagsInput from '~/app/inputs/components/EmailTagsInput';
import InfoBox from '~/app/shared/components/InfoBox';
import entitiesAction from '~/app/entities/actions';
import InputLabel from '~/app/inputs/components/InputLabel';
import Button from '~/app/shared/components/Button';
import InfoText from '~/app/shared/components/InfoText';
import Form from '~/app/shared/components/OldForm';
import * as formValidations from '~/app/shared/formValidations';
import { filter, trim } from 'lodash-es';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

import './bulk-add-users-form.scss';

export class BulkAddUsersForm extends React.Component {
  handleOnSubmit = () => {
    const { handleSubmit } = this.props;

    return handleSubmit(
      onSubmitActions(entitiesAction.userData.create.toString(), (values) => {
        const cleanedEmails = filter(values.emails, (email) => trim(email));
        return {
          key: 'userBulkAdd',
          body: cleanedEmails,
        };
      })
    );
  };

  render() {
    const { error, invalid, submitting } = this.props;

    return (
      <form className="bulk-add-users" onSubmit={this.handleOnSubmit()}>
        <Form.ErrorContainer invalid={invalid} error={error} submitting={submitting} />

        <InputLabel>Emails</InputLabel>
        <Fields
          names={['emails']}
          placeholder="You can paste multiple emails"
          component={EmailTagsInput}
          tagLimit={50}
        />
        <InfoText top={4} bottom={8} content="You may paste/type 50 emails at a time." />
        <InfoBox type="warning" content="People will be added as regular users" />

        <div className="submit-area">
          <Button type="submit" disabled={submitting} alt="Add people and send invites">
            Add and Send Invites
          </Button>
        </div>
      </form>
    );
  }
}

BulkAddUsersForm.propTypes = {
  error: PropTypes.array,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'bulkAddUsers',
  validate: formValidations.createValidator({
    emails: formValidations.required(),
  }),
})(BulkAddUsersForm);
