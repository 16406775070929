import { rqlToInput } from '~/app/backoffice/components/Dashboard/Filters/utils';
import { useGetAllCachedOptions } from '~/app/filters/hooks';
import { PillsDefinitionProps } from '~/app/filters/types';
import { get, map } from 'lodash-es';

export const useMyHubPillsDefinition = (props: PillsDefinitionProps) => {
  const { filters } = props;

  // Cached options
  const allTags = useGetAllCachedOptions({ keyPrefix: 'tags' });

  // Values
  const searchValue = get(filters, 'q.$eq', '');
  const selectedCategories = rqlToInput(get(filters, 'category'));

  return {
    pills: [
      ...(searchValue
        ? [
            {
              id: 'search',
              value: searchValue,
              label: searchValue,
              icon: 'search',
              filterName: 'q',
            },
          ]
        : []),

      ...map(selectedCategories, (value: string) => ({
        id: `category-${value}`,
        value: value,
        label: get(allTags, value, { name: value }).name,
        icon: 'hashtag',
        filterName: 'category',
      })),
    ],
  };
};
