import { mapRoute } from '~/services/requests';
import { filter, head, map, reduce, includes, split, tail, startsWith } from 'lodash-es';

const filterOutNonContextSettings = (settings) => {
  return filter(settings, (entry) => {
    return entry[1].type === 'context';
  });
};

export const createSettingsMenuItem = ([settingName, setting]) => {
  const children = map(
    filterOutNonContextSettings(Object.entries(setting.items)),
    createSettingsMenuItem
  );

  let settingPathItems = split(settingName, '/');
  if (head(settingPathItems) === 'system') {
    settingPathItems = tail(settingPathItems);
  }

  const [context, ...section] = settingPathItems;

  const url = `${mapRoute('settings', { context })}#${section.join('-')}`;

  return {
    label: setting.label,
    children,
    url,
  };
};

export const createSettingsMenu = (settings) => {
  const items = map(
    filterOutNonContextSettings(Object.entries(settings?.items || {})),
    createSettingsMenuItem
  );

  return items;
};

export const prepareTopLevelSystemSettingsData = (values) => {
  const settings = {};

  for (const [key, value] of Object.entries(values)) {
    settings[key] = {
      value: value.value,
      inherited: false,
      default: value.default,
    };
  }

  return settings;
};

export const prepareSettingsValuesForSubmission = (values = {}) => {
  const prepareDefaultValue = (key, value) => {
    if (startsWith(key, 'toggle')) {
      return value ?? false;
    }
    return value;
  };

  const nonInheritedSettings = filter(Object.entries(values), ([, { inherited }]) => !inherited);
  const settingsWithValues = map(nonInheritedSettings, ([key, { value }]) => ({
    [key]: prepareDefaultValue(key, value),
  }));
  return reduce(settingsWithValues, (prev, curr) => ({ ...prev, ...curr }), {});
};

export const getSettingHashKey = (settingName) => {
  const settingPathItems = split(settingName, '/');
  return settingName && settingPathItems.length > 2
    ? settingPathItems.splice(2).join('-')
    : settingName;
};

export const getSettingIcon = (settingName) => {
  if (includes(settingName, 'cutoffs')) return 'rules';
  if (includes(settingName, 'notifications')) return 'bell';
  if (includes(settingName, 'policies')) return 'list-outlined';

  return 'settings';
};
