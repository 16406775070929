import {
  ATTENDANCE_METHODS,
  ENROLLMENT_ACTIONS,
  ENROLLMENT_ACTIONS_ICONS,
  ENROLLMENT_ACTIONS_LABELS,
  ENROLLMENT_PERMISSIONS,
  ENROLLMENT_VIEW_MODES,
} from '~/app/enrollments/constants';
import { useEnrollmentsActions } from '~/app/enrollments/hooks';
import colors from '~/services/colors';
import { includes } from 'lodash-es';
import {
  GridActionsCellItem as MUIGridActionsCellItem,
  GridActionsCellItemProps,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import {
  NorthOutlinedIcon,
  SouthOutlinedIcon,
  VerticalAlignBottomOutlinedIcon,
  VerticalAlignTopOutlinedIcon,
} from '~/vendor/mui-icons';

const GridActionsCellItem = MUIGridActionsCellItem as any;

interface EnrollmentsActionsProps extends GridRowParams {
  showOrderActions?: boolean;
  maxOrder?: number;
  viewMode?: ENROLLMENT_VIEW_MODES;
  postActionCallback?: (
    id?: number | string | null,
    filters?: Record<string, any> | string
  ) => void;
}

const EnrollmentsActions = ({
  row,
  showOrderActions = false,
  maxOrder = -1,
  viewMode = ENROLLMENT_VIEW_MODES.roster,
  postActionCallback,
}: EnrollmentsActionsProps): React.ReactElement<GridActionsCellItemProps>[] => {
  const { id, permissions, order, attendance_method } = row;
  const { updateOrder, checkin, undoCheckin, promote, demote, drop, isLoading } =
    useEnrollmentsActions({
      enrollmentId: id,
      viewMode,
      postActionCallback,
    });
  const actions: JSX.Element[] = [];

  if (includes(permissions, ENROLLMENT_PERMISSIONS.manage) && showOrderActions) {
    if (order > 0) {
      actions.push(
        <GridActionsCellItem
          key={`move-to-top-${id}`}
          label="Move to top"
          icon={<VerticalAlignTopOutlinedIcon />}
          onClick={() => updateOrder({ order: 0 })}
          disabled={isLoading}
          showInMenu
        />,
        <GridActionsCellItem
          key={`move-up-${id}`}
          label="Move up"
          icon={<NorthOutlinedIcon />}
          onClick={() => updateOrder({ order: order - 1 })}
          disabled={isLoading}
          showInMenu
        />
      );
    }
    if (order < maxOrder) {
      actions.push(
        <GridActionsCellItem
          key={`move-down-${id}`}
          label="Move down"
          icon={<SouthOutlinedIcon />}
          onClick={() => updateOrder({ order: order + 1 })}
          disabled={isLoading}
          showInMenu
        />,
        <GridActionsCellItem
          key={`move-bottom-${id}`}
          label="Move to bottom"
          icon={<VerticalAlignBottomOutlinedIcon />}
          onClick={() => updateOrder({ order: maxOrder })}
          disabled={isLoading}
          showInMenu
        />
      );
    }
  }
  if (includes(permissions, ENROLLMENT_PERMISSIONS.checkin)) {
    const Icon = ENROLLMENT_ACTIONS_ICONS.checkin;
    actions.push(
      <GridActionsCellItem
        key={`checkin-enrollment-${id}`}
        label={ENROLLMENT_ACTIONS_LABELS.checkin}
        icon={<Icon />}
        onClick={checkin}
        disabled={isLoading}
        showInMenu
      />
    );
  }
  if (includes(permissions, ENROLLMENT_PERMISSIONS.undo_checkin)) {
    const Icon = ENROLLMENT_ACTIONS_ICONS.undo_checkin;
    actions.push(
      <GridActionsCellItem
        key={`undo-checkin-enrollment-${id}`}
        label={ENROLLMENT_ACTIONS_LABELS.undo_checkin}
        icon={<Icon />}
        onClick={undoCheckin}
        disabled={isLoading}
        showInMenu
      />
    );
  }
  if (includes(permissions, ENROLLMENT_PERMISSIONS.switch_to_online_enrollment)) {
    const action =
      attendance_method === ATTENDANCE_METHODS.online
        ? ENROLLMENT_ACTIONS.promote
        : ENROLLMENT_ACTIONS.switch_to_online;
    const Icon = ENROLLMENT_ACTIONS_ICONS[action];
    actions.push(
      <GridActionsCellItem
        key={`switch-to-online-enrollment-${id}`}
        label={ENROLLMENT_ACTIONS_LABELS[action]}
        icon={<Icon />}
        onClick={() => promote({ attendanceMethod: ATTENDANCE_METHODS.online })}
        disabled={isLoading}
        showInMenu
      />
    );
  }
  if (includes(permissions, ENROLLMENT_PERMISSIONS.switch_to_local_enrollment)) {
    const action =
      attendance_method === ATTENDANCE_METHODS.local
        ? ENROLLMENT_ACTIONS.promote
        : ENROLLMENT_ACTIONS.switch_to_local;
    const Icon = ENROLLMENT_ACTIONS_ICONS[action];
    actions.push(
      <GridActionsCellItem
        key={`switch-to-local-enrollment-${id}`}
        label={ENROLLMENT_ACTIONS_LABELS[action]}
        icon={<Icon />}
        onClick={() => promote({ attendanceMethod: ATTENDANCE_METHODS.local })}
        disabled={isLoading}
        showInMenu
      />
    );
  }
  if (includes(permissions, ENROLLMENT_PERMISSIONS.switch_to_online_waitlist)) {
    const action =
      attendance_method === ATTENDANCE_METHODS.online
        ? ENROLLMENT_ACTIONS.demote
        : ENROLLMENT_ACTIONS.switch_to_online_waitlist;
    const Icon = ENROLLMENT_ACTIONS_ICONS[action];
    actions.push(
      <GridActionsCellItem
        key={`switch-to-online-waitlist-enrollment-${id}`}
        label={ENROLLMENT_ACTIONS_LABELS[action]}
        icon={<Icon />}
        onClick={() => demote({ attendanceMethod: ATTENDANCE_METHODS.online })}
        disabled={isLoading}
        showInMenu
      />
    );
  }
  if (includes(permissions, ENROLLMENT_PERMISSIONS.switch_to_local_waitlist)) {
    const action =
      attendance_method === ATTENDANCE_METHODS.local
        ? ENROLLMENT_ACTIONS.demote
        : ENROLLMENT_ACTIONS.switch_to_local_waitlist;
    const Icon = ENROLLMENT_ACTIONS_ICONS[action];
    actions.push(
      <GridActionsCellItem
        key={`switch-to-local-waitlist-enrollment-${id}`}
        label={ENROLLMENT_ACTIONS_LABELS[action]}
        icon={<Icon />}
        onClick={() => demote({ attendanceMethod: ATTENDANCE_METHODS.local })}
        disabled={isLoading}
        showInMenu
      />
    );
  }

  if (includes(permissions, ENROLLMENT_PERMISSIONS.drop)) {
    const Icon = ENROLLMENT_ACTIONS_ICONS.drop;
    actions.push(
      <GridActionsCellItem
        key={`drop-enrollment-${id}`}
        label={ENROLLMENT_ACTIONS_LABELS.drop}
        icon={<Icon sx={{ color: colors.error600 }} />}
        onClick={drop}
        disabled={isLoading}
        sx={{ color: colors.error600 }}
        showInMenu
      />
    );
  }
  return actions;
};

export default EnrollmentsActions;
