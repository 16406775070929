import styled from 'styled-components';

import colors from '~/services/colors';
import Text from '~/app/shared/components/Text';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 16px;
  min-height: 82px;

  ${({ isRemoving }) =>
    isRemoving
      ? `
    background-color: ${colors.error100};
    border-bottom: 1px solid ${colors.error600};`
      : `border-bottom: 1px solid ${colors.neutral200};`}
`;

export const OldSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }
`;

export const TextContainer = styled.div`
  flex-grow: 1;

  > ${Text} {
    max-width: 380px;
  }
`;

export const Item = styled.div`
  padding: 12px;
  border: 1px solid ${colors.neutral200};
  margin-top: 16px;
  border-radius: 8px;

  > * + * {
    margin-top: 12px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-bottom: 12px;

  * + * {
    margin-left: 16px;
  }
`;

export const FormWrapper = styled.div`
  margin-top: 4px;
`;

export const Title = styled.div`
  display: flex;
  margin-bottom: 10px;
  max-width: 404px;
  word-wrap: break-word;
`;

export const TitleLink = styled.a`
  display: flex;
  overflow: hidden;
  max-width: 310px;
  margin-bottom: 8px;

  span:hover,
  span:focus {
    box-shadow: inset 0 -1px 0 0 ${colors.emphasis600};
  }
`;
