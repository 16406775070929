import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Button from '~/app/shared/components/Button';

const SubmitButton = (props) => {
  const { children, ...rest } = props;

  return (
    <Button {...rest} type="submit">
      {children || 'Save'}
    </Button>
  );
};

SubmitButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const StyledSubmitButton = styled(SubmitButton)`
  float: right;
`;

export default StyledSubmitButton;
