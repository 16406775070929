import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';

const ProgressBarContainer = styled.div`
  background-color: ${colors.neutral200};
  width: 100%;
  height: 6px;
  margin: 0px 0px 4px;
  border-radius: 3px;
`;

const ProgressBarFilledArea = styled.div`
  width: 0%;
  height: 6px;
  background-color: ${({ completedColor }) => completedColor || colors.success600};
  color: ${colors.emphasis600TextColor};
  opacity: 1 !important;
  border-radius: 3px;
`;

const ProgressBar = ({ completedProgress, completedColor, renderToolTip }) => {
  return (
    <ProgressBarContainer>
      <ProgressBarFilledArea
        className="completion-progress-bar-filler"
        style={{
          width: `${Math.min(completedProgress, 100)}%`,
        }}
        completedColor={completedColor}
      />
      {renderToolTip?.()}
    </ProgressBarContainer>
  );
};

ProgressBar.propTypes = {
  completedProgress: PropTypes.number,
  renderToolTip: PropTypes.func,
  completedColor: PropTypes.string,
};

export default ProgressBar;
