import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { toast } from '~/app/notifications/components/NotificationCenter';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { apiPost } from '~/services/requests';
import Button from '~/app/shared/components/Button';

const ExportCsvButton = ({
  exportURL,
  currentFilters,
  dashboardName,
  currentQueryCount,
  buttonUrl,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { trackActivity } = useMetrics();

  const trackExportActivity = () => {
    trackActivity(METRICS_ACTIVITIES.DASHBOARD_BULK_ACTION, {
      bulkAction: 'export',
      dashboardName,
      itemCount: currentQueryCount,
    });
    return true;
  };

  const handleExportCsv = () => {
    setSubmitting(true);
    apiPost(exportURL, null, currentFilters)
      // eslint-disable-next-line promise/always-return
      .then(() => {
        trackExportActivity();

        toast.success(
          'Export CSV requested successfully',
          'An email with the download link will be sent to you once the CSV is ready.'
        );
      })
      .catch(() => {
        toast.error('Error', 'Please try again later');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (buttonUrl) {
    return (
      <Button href={buttonUrl} target="_self" onClick={trackExportActivity}>
        Export CSV
      </Button>
    );
  }

  return (
    <Button disabled={submitting} onClick={handleExportCsv}>
      Export CSV
    </Button>
  );
};

ExportCsvButton.propTypes = {
  exportURL: PropTypes.string,
  buttonUrl: PropTypes.string,
  currentFilters: PropTypes.object,
  dashboardName: PropTypes.string,
  currentQueryCount: PropTypes.number,
};

export default ExportCsvButton;
