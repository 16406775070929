import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Field } from 'redux-form';

import SettingField from '~/app/settings/components/input-fields/SettingField';
import { OnChangeListenerContext } from '~/app/settings/context-providers/OnChangeListenerContext';
import { get } from 'lodash-es';

const SettingValueBlock = ({ settingName, setting }) => {
  const { onChange, canResetToDefault } = useContext(OnChangeListenerContext);

  const setFieldValue = (newValue) => {
    onChange(`settings.${settingName}`, newValue);
  };

  const forceAllowResetToDefault = get(setting, 'resetable', false);

  if (!settingName) {
    return null;
  }

  const formattedSettings = {
    ...setting,
    label: setting.label,
    description: setting.description,
  };

  return (
    <Field
      key={settingName}
      name={settingName}
      setting={formattedSettings}
      component={SettingField}
      handleInputChange={canResetToDefault || forceAllowResetToDefault ? setFieldValue : undefined}
    />
  );
};

SettingValueBlock.propTypes = {
  setting: PropTypes.object,
  settingName: PropTypes.string,
};

export default SettingValueBlock;
