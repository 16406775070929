import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { concat, includes, isEmpty, filter, noop, get, without, join, size } from 'lodash-es';

import { convertItemValuesToString, FilterOptionsQueryList } from '../FilterOptions';

const LocationFilter = (props) => {
  const {
    selecteds,
    param,
    labelLiveStreamed,
    disabled,
    showAttendanceMethod, // TODO: Remove this flag after separating all Location filters from Attendance method filter
    onChange,
    setSelectedLocations,
  } = props;

  const [options, setOptions] = useState([]);

  const [fetch, { status: fetchOptionsStatus }] = useEntities(
    actions.location.retrieveList,
    ({ status, data: { results } }) => {
      if (status === STATUS_DONE) setOptions(convertItemValuesToString(results));
    }
  );

  const fetchOptions = (queryParams = {}) => {
    // TODO: Remove this after separating all Location filters from Attendance method filter
    const includeIds = without(get(queryParams, 'include_ids'), 'online');
    const pageSize = size(includeIds) + 10;

    fetch(
      {
        ...queryParams,
        include_ids: join(includeIds, ','),
        page_size: pageSize,
        view_mode: 'filter_options',
        o: 'name',
      },
      { skipSchema: true }
    );
  };

  const selectedLocations = selecteds[param] || [];
  const onlineLabel = labelLiveStreamed || 'Remote Friendly';

  const handleOnLocationChange = (selectedLocations) => {
    let newLocations = [...selectedLocations];
    const selectedsCopy = { ...selecteds };
    if (
      showAttendanceMethod &&
      isEmpty(selectedsCopy[param]) &&
      !includes(newLocations, 'online')
    ) {
      newLocations = concat(newLocations, 'online');
    }
    selectedsCopy[param] = newLocations;

    onChange(selectedsCopy);
  };

  const onlineItem = {
    name: onlineLabel,
    value: 'online',
    icon: 'online',
  };

  useEffect(() => {
    let locations = filter(options, (location) => includes(selectedLocations, location.value));

    // TODO: Remove this after separating all Location filters from Attendance method filter
    if (includes(selectedLocations, 'online')) {
      locations = [...locations, onlineItem];
    }

    setSelectedLocations(locations);
  }, [selecteds, options]);

  return (
    <FilterOptionsQueryList
      allItemName="All Locations"
      permanentItems={showAttendanceMethod ? [onlineItem] : []}
      selecteds={selectedLocations}
      onChange={handleOnLocationChange}
      disabled={disabled}
      options={options}
      fetchOptions={fetchOptions}
      isFetchingOptions={fetchOptionsStatus === STATUS_LOADING}
      searchPlaceholder="Find a location"
    />
  );
};

LocationFilter.propTypes = {
  param: PropTypes.string,
  selecteds: PropTypes.object,
  labelLiveStreamed: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  showAttendanceMethod: PropTypes.bool,
  setSelectedLocations: PropTypes.func,
};

LocationFilter.defaultProps = {
  disabled: false,
  showAttendanceMethod: false,
  selecteds: {},
  setSelectedLocations: noop,
};

export default LocationFilter;
