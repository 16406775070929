import React from 'react';

import { mapRoute } from '~/services/requests';
import Button from '~/app/shared/components/Button';
import { useCurrentUser } from '~/app/shared/hooks';

const APITokenField = () => {
  const currentUser = useCurrentUser();

  return (
    <Button
      color="secondary"
      type="button"
      href={`${mapRoute('userEdit', { id: currentUser.id })}#additional-info`}
    >
      Access here
    </Button>
  );
};

export default APITokenField;
