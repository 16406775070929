import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import UserContentBlock from '~/app/users/components/UserContentBlock';
import { DEFAULT_STALE_TIME } from '~/common/api/constants';
import { possess } from '~/common/helpers';
import { ContentItemWidgetRow } from '~/features/contentitems/components/BaseContentItemAsyncWidget';
import { queries } from '~/queries';
import { mapRoute } from '~/services/requests';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import Pill from '~/app/shared/components/Pill';
import { map, constant, get, slice } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Link } from '~/common/components/Links';
import { Box, Stack } from '@mui/material';
import rql from '~/vendor/rql';

const getResourceListFn = (resource: string) => get(queries, `${resource}.list`, constant({}));

interface Props {
  userId: number;
  displayName: string;
}

export const OwnedContentSection = ({ userId, displayName }: Props) => {
  const baseSearch = rql({
    is_inline: false,
    content_type: {
      $out: [
        CONTENT_TYPES.event,
        CONTENT_TYPES.task,
        CONTENT_TYPES.scheduled_track,
        CONTENT_TYPES.question,
        CONTENT_TYPES.text_question,
        CONTENT_TYPES.multiple_choice_question,
      ],
    },
  });

  const page = 10;
  const urlParams = new URLSearchParams(window.location.search);
  const [qParam, setQParam] = useState(urlParams.get('q'));
  const qSearch = qParam ? `&q=${qParam}` : '';
  const search = `${baseSearch}&eq(facilitator,${userId})&ordering(-total_assignments)${qSearch}`;
  const pageSearch = `eq(facilitator,${userId})&ordering(-total_assignments)`;
  const searchLimit = `&page=1&page_size=${page}&view_mode=widget`;

  const queryConfig = getResourceListFn('content_items') as any;
  const { queryKey, queryFn } = queryConfig(`${search}${searchLimit}`);

  const { data } = useQuery({ queryKey, queryFn, staleTime: DEFAULT_STALE_TIME, retry: 1 });
  const total = get(data, 'count', 0);

  const morePages = total > page && !qSearch;
  const fullPageUrl = `${mapRoute('unifiedCatalogList')}?${pageSearch}`;
  const rows: ContentItem[] = slice(get(data, 'results'), 0, page);

  if (total == 0 && !qSearch) {
    return null;
  }
  return (
    <UserContentBlock>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="overline" noWrap>
          {possess(displayName)} Content {qSearch && 'matching search term'}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {morePages && (
            <Link href={fullPageUrl} variant="body2" noWrap>
              View all {total}
            </Link>
          )}
          {qSearch && (
            <Stack direction="row" spacing={1}>
              <Pill icon="search" label={qParam || ''} onEdit={() => setQParam('')} />
            </Stack>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1.5 }}>
        {map(rows, (row, index) => (
          <Box key={`widget-row-${index}`} sx={{ height: 44, overflow: 'hidden' }}>
            <ContentItemWidgetRow contentItem={row} />
          </Box>
        ))}
      </Box>
    </UserContentBlock>
  );
};
