import { useResizeDetector } from 'react-resize-detector';

import { CONTENT_TYPES, LEARNING_TYPES } from '~/app/catalog/constants';
import { useLearningTypeFilterOptions, useLearningTypeLabels } from '~/app/catalog/hooks';
import { getDefaultCatalogFiltersQuerystring } from '~/app/catalog/utils';
import { useFetchDataList } from '~/app/data-fetching/hooks';
import { queries } from '~/queries';
import { mapRoute } from '~/services/requests';
import { OptimalContentItemCard } from '~/app/shared/components/Card';
import ContentItemHorizontalCardList from '~/app/shared/components/ContentItemHorizontalCardList';
import HorizontalCardList, {
  getMaxItemsPerRow,
} from '~/app/shared/components/HorizontalCardList/HorizontalCardList';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { filter, find, indexOf, isEmpty, join, map, size, take } from 'lodash-es';
import { Box } from '@mui/material';

interface Props {
  pageSize?: number;
}

const SuggestedProgramsList = ({ pageSize = 6 }: Props) => {
  const { ref, width } = useResizeDetector();

  const maxItemsPerRow = getMaxItemsPerRow(Math.round(width || 0));

  // Filters out content types not included in the catalog
  const enabledLearningTypes = useLearningTypeFilterOptions();
  const labels = useLearningTypeLabels({ isPlural: true });

  const selectedEnabledLearningTypes = filter(
    enabledLearningTypes,
    (learningType) =>
      indexOf([LEARNING_TYPES.mentorship_programs, LEARNING_TYPES.programs], learningType.value) >
      -1
  );
  const isOfficeHoursEnabled = Boolean(
    find(selectedEnabledLearningTypes, ['value', LEARNING_TYPES.programs])
  );
  const isMentorshipProgramEnabled = Boolean(
    find(selectedEnabledLearningTypes, ['value', LEARNING_TYPES.mentorship_programs])
  );

  const {
    data: officeHours,
    count: officeHoursCount,
    status: officeHoursRequestStatus,
  } = useFetchDataList({
    ...queries.content_items.list(
      getDefaultCatalogFiltersQuerystring({
        pageSize,
        selectedContentTypes: [CONTENT_TYPES.program],
        status: 'published',
      })
    ),
    enabled: isOfficeHoursEnabled,
  });

  const {
    data: mentorshipPrograms,
    count: mentorshipProgramsCount,
    status: mentorshipProgramsRequestStatus,
  } = useFetchDataList({
    ...queries.content_items.list(
      getDefaultCatalogFiltersQuerystring({
        pageSize,
        selectedContentTypes: [CONTENT_TYPES.mentorship_program],
        status: 'published',
      })
    ),
    enabled: isMentorshipProgramEnabled,
  });

  if (
    officeHoursRequestStatus === STATUS_LOADING ||
    mentorshipProgramsRequestStatus === STATUS_LOADING
  )
    return <Loading />;

  if (officeHoursRequestStatus !== STATUS_DONE && mentorshipProgramsRequestStatus !== STATUS_DONE)
    return null;

  const shouldMergeLists =
    isOfficeHoursEnabled &&
    isMentorshipProgramEnabled &&
    (size(officeHours) <= maxItemsPerRow / 2 || size(mentorshipPrograms) <= maxItemsPerRow / 2);

  const mergedList =
    size(officeHours) <= size(mentorshipPrograms)
      ? [...(officeHours as any[]), ...(mentorshipPrograms as any[])]
      : [...(mentorshipPrograms as any[]), ...(officeHours as any[])];

  const mergedListLabel = join(
    take(
      map(selectedEnabledLearningTypes, ({ name }) => name),
      2
    ),
    ' and '
  );

  const mergedListCount = officeHoursCount + mentorshipProgramsCount;

  const mergedViewAllUrlParams = join(
    filter(
      [
        'o=relevance',
        'status=published',
        `${join(
          map(
            selectedEnabledLearningTypes,
            (learningType) => `learning_types=${learningType.value}`
          ),
          '&'
        )}`,
      ],
      (entry) => !isEmpty(entry)
    ),
    '&'
  );

  return (
    <Box ref={ref}>
      {shouldMergeLists && (
        // We cannot use ContentItemHorizontalCardList here since it would fetch both content items as a single list
        // so the list is being created manually using the generic HorizontalListComponent
        <HorizontalCardList
          sectionTitle={`Suggested ${mergedListLabel}`}
          items={mergedList}
          renderItem={(item) => (
            <OptimalContentItemCard key={`${item.content_type}_${item.id}`} contentItem={item} />
          )}
          totalItemsCount={mergedListCount}
          viewAllLabel={`View All ${mergedListCount > 1 && mergedListCount}`}
          viewAllUrl={`${mapRoute('unifiedCatalogList')}?${mergedViewAllUrlParams}`}
        />
      )}
      {!shouldMergeLists && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2.5,
          }}
        >
          {isOfficeHoursEnabled && (
            <ContentItemHorizontalCardList
              sectionTitle={`Suggested ${labels[LEARNING_TYPES.programs]}`}
              learningTypes={[LEARNING_TYPES.programs]}
            />
          )}
          {isMentorshipProgramEnabled && (
            <ContentItemHorizontalCardList
              sectionTitle={`Suggested ${labels[LEARNING_TYPES.mentorship_programs]}`}
              learningTypes={[LEARNING_TYPES.mentorship_programs]}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default SuggestedProgramsList;
