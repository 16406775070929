import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { addBusinessDays } from '~/services/datetime';
import { generateGuid, isGoogleMeetLink } from '~/services/utils';
import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import InfoBox from '~/app/shared/components/InfoBox';
import NewTimeslotField from '~/app/shared/components/NewContentForm/NewTimeslotField';
import { NAIVE_DATETIME_FORMAT } from '~/app/shared/constants';

import TimeslotField from './TimeslotField';

const TimeSlotFieldsWrapper = styled.div`
  > * + * {
    margin-top: 20px;
  }
`;

const getNextTimeslotStart = (lastTimeslotStart) => {
  // Default to first available business day in a week
  if (!lastTimeslotStart) {
    const nextWeek = moment(addBusinessDays(7)).startOf('hour');
    return nextWeek.format(NAIVE_DATETIME_FORMAT);
  }

  // if there's a previous timeslot, return the same time on the next business day
  const nextBusinessDay = moment(
    addBusinessDays(1, moment(lastTimeslotStart, NAIVE_DATETIME_FORMAT))
  );
  return nextBusinessDay.format(NAIVE_DATETIME_FORMAT);
};

export class TimeslotsField extends React.Component {
  constructor(props) {
    super(props);
    // This is for the new form only
    const openTimeslotIndex = props.fields.length - 1;
    // The last timeslot will always start open
    this.state = {
      openTimeslotIndex,
    };
  }

  componentDidMount = () => {
    const { fields } = this.props;

    if (!fields.get(0)) {
      this.handleAddTimeslot();
    }
  };

  handleAddTimeslot = () => {
    const { fields, currentUser, initialWatchLink, initialDuration, isNewForm } = this.props;

    const lastField = fields.length > 0 ? fields.get(fields.length - 1) : null;

    const watchLink = isGoogleMeetLink(lastField?.watch_link) ? null : lastField?.watch_link;

    const timeslot = {
      id: generateGuid(),
      starts_at: getNextTimeslotStart(lastField?.starts_at),
      duration: initialDuration || lastField?.duration || '01:00:00',
      rooms_info: '',
      extra_info: '',
      watch_link: watchLink || initialWatchLink,
      google_calendar_calendar_owner: currentUser,
      rooms: null,
    };

    fields.push(timeslot);

    if (isNewForm) {
      // fields.length here is ignoring the new timeslot (I guess it's because we're mutating
      // the original array and we'll only get the new length in the next update), so we don't
      // have to subtract 1 to get the last index
      this.setState({ openTimeslotIndex: fields.length });
    }
  };

  handleAddSessionClick = (e) => {
    e.preventDefault();
    this.handleAddTimeslot();
  };

  handleRemoveTimeslot = (index) => {
    const { fields, isNewForm } = this.props;
    fields.remove(index);
    // As we've removed a timeslot, keep all others closed
    if (isNewForm) this.setState({ openTimeslotIndex: -1 });
  };

  render = () => {
    const {
      fields,
      locationId,
      isOnline,
      addButtonDisabled,
      isNewForm,
      formName,
      change,
      isEditing,
      meta: { error },
    } = this.props;

    return (
      <TimeSlotFieldsWrapper>
        {error && <InfoBox type="error" content={error} />}
        {isNewForm &&
          // XXX: Lodash map doesn't work with redux-form ArrayField
          // eslint-disable-next-line lodash/prefer-lodash-method
          fields.map((name, index) => {
            const timeslot = fields.get(index);
            // If we receive false, it means that we should close that timeslot
            // Since only one timeslot can be open at a given time, we update the state
            // to close all of them (openTimeslotIndex === -1)
            const setOpen = (value) => {
              this.setState({ openTimeslotIndex: value ? index : -1 });
            };
            const { openTimeslotIndex } = this.state;
            return (
              <NewTimeslotField
                key={timeslot.id}
                name={name}
                locationId={locationId}
                isOnline={isOnline}
                onRemove={this.handleRemoveTimeslot}
                index={index}
                timeslot={timeslot}
                isOpen={index === openTimeslotIndex}
                setOpen={setOpen}
                formName={formName}
                change={change}
                isEditing={isEditing}
              />
            );
          })}
        {!isNewForm &&
          // XXX: Lodash map doesn't work with redux-form ArrayField
          // eslint-disable-next-line lodash/prefer-lodash-method
          fields.map((name, index) => {
            const timeslot = fields.get(index);
            return (
              <TimeslotField
                key={timeslot.id}
                name={name}
                locationId={locationId}
                isOnline={isOnline}
                onRemove={this.handleRemoveTimeslot}
                index={index}
              />
            );
          })}
        <div>
          <Button
            startIcon={<Icon name="plus" />}
            size="small"
            onClick={this.handleAddSessionClick}
            disabled={addButtonDisabled}
          >
            New Timeslot
          </Button>
        </div>
      </TimeSlotFieldsWrapper>
    );
  };
}

TimeslotsField.propTypes = {
  fields: PropTypes.object,

  currentUser: PropTypes.object,

  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOnline: PropTypes.bool,
  addButtonDisabled: PropTypes.bool,

  initialWatchLink: PropTypes.string,
  initialDuration: PropTypes.string,

  isNewForm: PropTypes.bool,
  isEditing: PropTypes.bool,
  formName: PropTypes.string,
  change: PropTypes.func,
  meta: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TimeslotsField);
