import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import emitter from '~/services/emitters';
import { ApiURLs } from '~/services/requests-base';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FailedAuthenticationModal = () => {
  const handleClose = () => {
    window.location.href = ApiURLs.logout();
  };
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const handler = () => setShowModal(true);
    emitter.on('sessionAuthenticationIssue', handler);
    return () => {
      emitter.off('sessionAuthenticationIssue', handler);
    };
  }, []);

  if (!showModal) {
    return null;
  }

  return (
    <Modal title="Your session expired" width={600} handleClose={handleClose}>
      <ModalBody>
        <MessageContainer>
          <Text>Oops, your session expired. Please login again.</Text>
        </MessageContainer>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose}>Login</ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

export default FailedAuthenticationModal;
