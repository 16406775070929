import { useState } from 'react';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import { ContentItemContextMenu } from '~/app/content-items/components/interfaces';
import actions from '~/app/entities/actions';
import { useArchiveUnarchive } from '~/app/entities/utils';
import { getFacilitatorsIds, getEventTypeEditableField } from '~/app/event-shared/services';
import EventTypeApplyToEventsModal from '~/app/event-types/components/EventTypeApplyToEventsModal';
import { EventType } from '~/app/event/interfaces';
import { toast } from '~/app/notifications/components/NotificationCenter';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import ContentSurveyModal from '~/app/shared/components/ContentSurveyModal';
import DropDownMenu from '~/app/shared/components/DropDownMenu';
import {
  CONTENT_ITEM_PERMISSIONS,
  CREATE_EVENT_TYPE_PERMISSION,
  EDIT_EVENT_TYPE_PERMISSION,
} from '~/app/shared/permissions';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import { includes, get } from 'lodash-es';

const EventTypeContextMenu = ({ content: contentItem }: ContentItemContextMenu) => {
  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });

  const content: EventType = contentItem;
  const { public_id: publicId, public_id_and_slug: publicIdAndSlug, permissions } = content;

  const isArchived = get(content, 'is_archived', Boolean(get(content, 'archival.archived_at')));

  const canEdit =
    includes(permissions, EDIT_EVENT_TYPE_PERMISSION) ||
    includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canManage =
    includes(permissions, EDIT_EVENT_TYPE_PERMISSION) ||
    includes(permissions, CREATE_EVENT_TYPE_PERMISSION) ||
    includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);

  const { archiveItem, unarchiveItem } = useArchiveUnarchive(actions.eventType.archive);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showPropagateModal, setShowPropagateModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const { organizerId, presentersIds, coOrganizersIds } = getFacilitatorsIds(content);

  if (!canEdit) {
    return null;
  }

  const handleDeleteClick = () => setShowDeleteModal(true);

  content.organizer_id = organizerId;
  content.presenters_ids = presentersIds;
  content.co_organizers_ids = coOrganizersIds;

  const handleApplyToSubmit = async (fields, propagation) => {
    const url = ApiURLs['api_eventtypes:apply_changes']();
    const data = { event_type_id: content.public_id, fields, propagation };
    await fetchURL(url, { method: 'POST', body: JSON.stringify(data) });

    toast.success('Success!', 'The selected events will update in a few minutes!');
  };

  const editableFields = getEventTypeEditableField(content);

  return (
    <>
      <DropDownMenu icon="elipsis" fontSize={20} buttonAriaLabel="Settings">
        <>
          <DropDownMenu.Item
            route={contentTypeRoutes[CONTENT_TYPES.eventtype].edit({
              public_id_and_slug: publicIdAndSlug,
            })}
            title="Edit"
            icon="pencil"
          />
          <DropDownMenu.Item
            route={`${mapRoute('eventTypeCreate')}?cloned=${publicId}`}
            title="Duplicate"
            icon="clone"
          />
          <DropDownMenu.Item
            onClick={() => setShowPropagateModal(true)}
            title="Apply to Existing Events"
            icon="edit"
          />
          <DropDownMenu.Item
            route={mapRoute('eventNewFromTemplate', { public_id: publicId })}
            title="Schedule"
            icon="plus"
          />
          <DropDownMenu.Separator />
          <DropDownMenu.Item
            route={mapRoute('contentItemManageAssignments', {
              public_id_and_slug: content.public_id_and_slug,
            })}
            title="View Roster"
            icon="group"
          />
          <DropDownMenu.Item
            onClick={() => setShowSurveyModal(true)}
            title="Manage Surveys"
            icon="survey"
          />
          <DropDownMenu.Separator />
          {!isArchived && (
            <DropDownMenu.Item
              onClick={() => archiveItem(publicId)}
              title="Archive"
              icon="archive"
            />
          )}
          {isArchived && (
            <>
              <DropDownMenu.Item
                onClick={() => unarchiveItem(publicId)}
                title="Unarchive"
                icon="unarchive"
              />
              <DropDownMenu.Item
                onClick={handleDeleteClick}
                title="Delete"
                icon="delete"
                color={colors.error600}
                textColor={colors.error600}
              />
            </>
          )}
          {canManage && (
            <DropDownMenu.Item onClick={() => setShowLogModal(true)} title="View Logs" icon="log" />
          )}
        </>
      </DropDownMenu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}

      {showSurveyModal && (
        <ContentSurveyModal content={content} handleClose={() => setShowSurveyModal(false)} />
      )}

      {showPropagateModal && (
        <EventTypeApplyToEventsModal
          eventFields={editableFields}
          handleClose={() => setShowPropagateModal(false)}
          handleSubmit={handleApplyToSubmit}
        />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </>
  );
};

export default EventTypeContextMenu;
