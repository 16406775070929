import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import DisabledButton from '~/app/event-shared/components/AttendanceActionManager/DisabledButton';
import RatingArea from '~/app/event-shared/components/AttendanceActionManager/RatingArea';
import RestrictedEnrollmentInfoBox from '~/app/event-shared/components/RestrictedEnrollmentInfoBox';
import {
  ENROLLMENT_STATUS_ATTENDED,
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
  ENROLLMENT_STATUS_NOT_GOING,
  ENROLLMENT_STATUS_UNANSWERED,
} from '~/app/event-shared/constants';
import { allowCancellation, allowSelfCheckin } from '~/app/event-shared/services';
import { mapRoute } from '~/services/requests';
import Button from '~/app/shared/components/Button';
import * as permissions from '~/app/shared/permissions';
import { includes } from 'lodash-es';

const CompactButtonWrapper = styled.div`
  text-align: center;
`;

export const CompactAttendanceActionElement = ({ event, enrollmentStatus, onRateClick }) => {
  const viewMode = 'compact';

  const userIsNotGoing = includes(
    [ENROLLMENT_STATUS_UNANSWERED, ENROLLMENT_STATUS_NOT_GOING],
    enrollmentStatus
  );
  const userHasEnrolledStatus = !userIsNotGoing;
  const userIsGoing = includes(
    [ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE],
    enrollmentStatus
  );
  const userHasAttended = enrollmentStatus === ENROLLMENT_STATUS_ATTENDED;

  const userCanNotCheckin = !allowSelfCheckin(event);
  const userCanCancel = allowCancellation(event) && userIsGoing;

  const shouldShowRatingArea =
    event.has_passed && ((userCanNotCheckin && !userCanCancel) || userIsNotGoing);

  const hasGroupAccessPermission = includes(event.permissions, permissions.GROUP_ACCESS_PERMISSION);
  const canChangeStatus = hasGroupAccessPermission || userHasEnrolledStatus;

  // The event has passed and the user is not going or the user can't check in and can't cancel
  if (shouldShowRatingArea) {
    return <RatingArea event={event} onRateClick={onRateClick} viewMode={viewMode} />;
  }

  if (!canChangeStatus) {
    return <RestrictedEnrollmentInfoBox event={event} />;
  }

  if (userHasAttended) {
    return <DisabledButton mainText="You are checked in!" icon="check" />;
  }

  return (
    <CompactButtonWrapper>
      <Button
        fullWidth
        route={mapRoute('eventDetails', { public_id_and_slug: event.public_id_and_slug })}
      >
        View Event Details
      </Button>
    </CompactButtonWrapper>
  );
};

CompactAttendanceActionElement.propTypes = {
  event: PropTypes.object,
  enrollmentStatus: PropTypes.string,
  onRateClick: PropTypes.func,
};

export default CompactAttendanceActionElement;
