import moment from 'moment';

import { LEARNING_TYPE_COLOR_MAPPING } from '~/app/catalog/constants';
import {
  capitalize,
  forEach,
  includes,
  keys,
  map,
  replace,
  toInteger,
  parseInt,
  split,
  upperFirst,
} from 'lodash-es';

export function mergeNormalizedArray(state, normalized, resultKey) {
  let newState = { ...state };
  const { entities } = normalized;

  /* eslint-disable-next-line no-restricted-syntax */
  for (const key of keys(entities)) {
    const stateItems = state[key];
    let entityItems = entities[key];
    entityItems = { ...stateItems, ...entityItems };
    newState = { ...newState, [key]: entityItems };
  }

  if (resultKey) {
    newState[resultKey] = normalized.result;
  }

  return newState;
}

export const capitalizeFirstLetter = upperFirst;

export const capitalizeMultipleWordsName = (name) => map(split(name, ' '), capitalize).join(' ');

export const sortPeopleAndGroups = (list, groupsPayload, peoplePayload) => {
  forEach(list, (item) => {
    if (includes(item, 'user')) {
      const newItem = replace(item, 'user-', '');
      peoplePayload.push(toInteger(newItem));
    } else if (includes(item, 'group')) {
      const newItem = replace(item, 'group-', '');
      groupsPayload.push(toInteger(newItem));
    }
  });
};

export const removePrefixFromId = (idsList, prefix) =>
  map(idsList, (id) => parseInt(replace(id, prefix, '')));

export const getColorByType = (type, intensity) =>
  LEARNING_TYPE_COLOR_MAPPING[type][`color${intensity}`];

const suggestDate = (duration) => (duration ? moment().add(moment.duration(duration)) : null);

export const getSuggestedDueDate = (content) => suggestDate(content.time_to_complete);
export const getSuggestedExpirationDate = (content) => suggestDate(content.time_to_retake);
