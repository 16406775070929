import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import { alpha } from '@mui/material';

const StyledStarIcon = styled(Icon)`
  margin-right: 4px;
`;

const RatingText = styled.span`
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: -0.34px;
`;

const RateButton = (props) => {
  const { children, rating, ...restProps } = props;

  return (
    <Button
      alt={`${children} - ${rating}`}
      color="rating"
      {...restProps}
      sx={{
        borderColor: alpha(colors.rating600, 0.8),
        gap: 1,
        '& > *': {
          color: colors.rating600,
        },
        svg: {
          width: '14px',
        },
        'svg path': {
          fill: colors.alert600,
          opacity: 0.7,
        },
      }}
    >
      {children}
      {rating ? (
        <RatingText>
          <StyledStarIcon name="star" width={16} height={16} noTransition />
          {rating}
        </RatingText>
      ) : null}
    </Button>
  );
};

RateButton.propTypes = {
  children: PropTypes.any,
  rating: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default RateButton;
