import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { LEARNING_TYPES, COMPLETED_STATUS } from '~/app/catalog/constants';
import { useLearningTypeLabels } from '~/app/catalog/hooks';
import RelaunchCourseModal from '~/app/course/components/RelaunchCourseModal';
import { COURSE_STATUS_DRAFT } from '~/app/course/constants';
import { launchCourse } from '~/app/course/services';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import Button from '~/app/shared/components/Button';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Icon from '~/app/shared/components/Icon';
import RateButton from '~/app/shared/components/RateButton';
import Rating from '~/app/shared/components/Rating';
import ContentRating from '~/app/stand-alone-shared/components/ContentRating';
import { get, isNil } from 'lodash-es';

const Container = styled.div`
  min-height: 40px; // Large Button height
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
`;

const RatingContainer = styled.div`
  flex: 1 0 auto;
  margin-right: 12px;

  font-size: 20px; // Star size

  display: flex;
  justify-content: flex-end;
`;

const DraftLink = styled(Link)`
  width: 100%;
`;

const PrimaryActionButton = ({ content, isRenderedWithinTrack = false }) => {
  const [isCourseLaunchLoading, setIsCourseLaunchLoading] = useState(false);
  const [showRelaunchCourseModal, setShowRelaunchCourseModal] = useState(false);
  const courseLabel = useLearningTypeLabels()[LEARNING_TYPES.courses];
  const [isRatingContent, setRatingContent] = useState(false);
  const { trackActivity } = useMetrics();
  const isUnpublished = content.status === COURSE_STATUS_DRAFT;
  const contentType = LEARNING_TYPES.courses;

  const handleCourseLaunch = async () => {
    trackActivity(METRICS_ACTIVITIES.COURSE_LAUNCH, {
      courseId: content.id,
      firstLaunch: isNil(content?.assignment?.latest_sync),
    });

    setIsCourseLaunchLoading(true);

    await launchCourse({
      courseId: content.public_id,
      isLaunchedInsideTrack: isRenderedWithinTrack,
    });

    setIsCourseLaunchLoading(false);
  };

  const completionStatus = content?.assignment?.completion_status;
  const userIsRegistered = !isNil(content?.assignment?.latest_sync);
  const isCompleted = completionStatus === COMPLETED_STATUS;

  if (isUnpublished) {
    return (
      <Container>
        <DraftLink to={mapRoute('courseEdit', { public_id_and_slug: content.public_id_and_slug })}>
          <Button color="primary" size="small">
            Edit and Publish
          </Button>
        </DraftLink>
      </Container>
    );
  }

  const currentRating = get(content, 'assignment.feedback_rating', 0);
  if (isCompleted && currentRating > 0) {
    return (
      <>
        <Container>
          <RatingContainer>
            <Rating value={currentRating} readOnly />
          </RatingContainer>
          <ButtonLink onClick={() => setRatingContent(true)} color="primary">
            Edit
          </ButtonLink>
        </Container>
        <Container>
          <Button color="primary" size="small" onClick={() => setShowRelaunchCourseModal(true)}>
            Relaunch
          </Button>
        </Container>
        {isRatingContent && (
          <ContentRating
            contentType={contentType}
            content={content}
            handleClose={() => setRatingContent(false)}
          />
        )}
        {showRelaunchCourseModal && (
          <RelaunchCourseModal
            title={content.name}
            handleRelaunch={handleCourseLaunch}
            isCourseLaunchLoading={isCourseLaunchLoading}
            handleClose={() => setShowRelaunchCourseModal(false)}
          />
        )}
      </>
    );
  }

  if (isCompleted) {
    return (
      <>
        <Container>
          <RateButton size="medium" onClick={() => setRatingContent(true)}>
            Rate
          </RateButton>
        </Container>
        <Container>
          <Button color="primary" size="small" onClick={() => setShowRelaunchCourseModal(true)}>
            Relaunch
          </Button>
        </Container>
        {isRatingContent && (
          <ContentRating
            content={content}
            contentType={contentType}
            handleClose={() => setRatingContent(false)}
          />
        )}
        {showRelaunchCourseModal && (
          <RelaunchCourseModal
            title={content.name}
            handleRelaunch={handleCourseLaunch}
            isCourseLaunchLoading={isCourseLaunchLoading}
            handleClose={() => setShowRelaunchCourseModal(false)}
          />
        )}
      </>
    );
  }
  return (
    <Container>
      <Button
        color="primary"
        size="small"
        disabled={isCourseLaunchLoading}
        startIcon={<Icon name="external" />}
        fullWidth
        onClick={handleCourseLaunch}
      >
        {isCourseLaunchLoading
          ? 'Launching...'
          : userIsRegistered
            ? `Continue ${courseLabel}`
            : `Launch ${courseLabel}`}
      </Button>
    </Container>
  );
};

PrimaryActionButton.propTypes = {
  content: PropTypes.object.isRequired,
  isRenderedWithinTrack: PropTypes.bool,
};

export default PrimaryActionButton;
