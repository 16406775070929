/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/first */
/* eslint-disable no-underscore-dangle */

if (process.env.NODE_ENV === 'production' && window.WEBPACK_PUBLIC_PATH) {
  // @ts-ignore
  __webpack_public_path__ = window.WEBPACK_PUBLIC_PATH;
}

import 'core-js/stable';
import 'regenerator-runtime/runtime';
// @ts-ignore
import React from 'react';
import { render } from 'react-dom';

// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/extensions
import { LicenseInfo } from '@mui/x-license-pro';

import '~/services/setup-datadog-rum';

import 'what-input';

import './ie-polyfill';

import './sass/style.scss';

import ReactApp, { Container, elementId, UserAddButtonContainer, PosterContainer } from './scenes';

let children: React.ReactNode = null;
const element = document.getElementById(elementId);
if (elementId === 'user-add-button') {
  render(<UserAddButtonContainer />, element);
} else if (elementId === 'poster-app') {
  render(<PosterContainer />, element);
} else if (element) {
  children = Container;
}

LicenseInfo.setLicenseKey(window.MUI_LICENSE_KEY);

const reactApp = document.getElementById('react-app');
if (reactApp) {
  render(<ReactApp>{children}</ReactApp>, reactApp);
}

if (module.hot) {
  module.hot.accept('./scenes/index');
}
