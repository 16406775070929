import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { to } from '~/common/helpers';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { queries } from '~/queries';
import colors from '~/services/colors';
import { displayDate } from '~/services/datetime';
import { mapRoute } from '~/services/requests';
import { UserAvatar } from '~/app/shared';
import DropDownMenu from '~/app/shared/components/DropDownMenu';
import Pill from '~/app/shared/components/Pill';
import { Item, Section, TextContainer } from '~/app/shared/components/SurveyItem';
import Text from '~/app/shared/components/Text';
import { Tooltip } from '~/app/shared/components/Tooltip';
import {
  useEllipsisCheck,
  useTooltipUID,
  useCopyToClipboard,
  useCurrentUser,
} from '~/app/shared/hooks';
import SurveyModalReminder from '~/app/surveys/components/SurveyModal/SurveyModalReminder';
import {
  SurveyRelationshipStatus,
  SurveyRelationshipStatusLabelMap,
} from '~/app/surveys/constants';
import {
  parseCutoffInfo,
  parseScheduleInfo,
  getSurveyExportResponsesCsvUrl,
} from '~/app/surveys/services';
import { includes, isEqual, noop, map, get, size, join } from 'lodash-es';

const RespondentsAvatars = styled.div`
  margin-left: 16px;
  display: flex;
  justify-content: space-between;
  > * + * {
    margin-left: 4px;
  }
`;

const SurveyStatusPill = ({ status }) => {
  if (status === SurveyRelationshipStatus.NOT_STARTED) {
    return (
      <Pill
        variant="lighterGrayWithDarkerTextColor"
        label={SurveyRelationshipStatusLabelMap[status]}
      />
    );
  }

  if (status === SurveyRelationshipStatus.OPEN) {
    return <Pill variant="success200" label={SurveyRelationshipStatusLabelMap[status]} />;
  }

  if (status === SurveyRelationshipStatus.CLOSED) {
    return <Pill variant="lightGray" label={SurveyRelationshipStatusLabelMap[status]} />;
  }

  return null;
};

SurveyStatusPill.propTypes = {
  status: PropTypes.string,
};

export const EventSurveyModalItem = ({ attendeesCount, surveyRelationship, isLast, eventId }) => {
  const [isConfirmingRemind, setIsConfirmingRemind] = useState(false);

  const { hasEllipsis, nodeRef } = useEllipsisCheck();
  const titleTooltip = useTooltipUID();
  const { copy_emails_default_separator: copyEmailsDefaultSeparator } = useCurrentUser();

  const { copyToClipboard } = useCopyToClipboard(5000);

  const { refetch: fetchAnsweredEmails } = useQuery({
    ...queries.surveys.surveyRelationshipEmails(surveyRelationship.id, 'answered'),
    enabled: false,
  });
  const { refetch: fetchUnansweredEmails } = useQuery({
    ...queries.surveys.surveyRelationshipEmails(surveyRelationship.id, 'unanswered'),
    enabled: false,
  });

  const {
    survey,
    survey_schedule_date: scheduleDate,
    survey_cutoff_date: cutoffDate,
    survey_status: status,
    user_survey_count: userSurveyCount,
  } = surveyRelationship;

  const { title } = survey;

  const enableExportResponses =
    includes([SurveyRelationshipStatus.OPEN, SurveyRelationshipStatus.CLOSED], status) &&
    userSurveyCount > 0;

  const copyURL = () => {
    const { survey_link: surveyLink } = surveyRelationship;
    copyToClipboard(surveyLink);
    toast.success('Survey URL copied successfully!');
  };

  const showReminderModal = () => {
    setIsConfirmingRemind(true);
  };

  const handleCopySurveyRelationshipEmails = async (status) => {
    const [err, res] = await to(
      status === 'answered' ? fetchAnsweredEmails() : fetchUnansweredEmails()
    );

    if (err) {
      toast.error('Error', 'Please try again later');
      return;
    }

    const emails = get(res, 'data.emails_to_copy', []);
    const count = size(emails);

    if (count) {
      toast.success(`${count} ${count === 1 ? 'email' : 'emails'} copied.`);
      copyToClipboard(join(emails, copyEmailsDefaultSeparator));
    } else {
      toast.warning('No items to be copied.');
    }
  };

  const unansweredUserSurveysCount = attendeesCount - surveyRelationship.user_survey_count;
  const showSendReminder =
    isEqual(SurveyRelationshipStatus.OPEN, status) && unansweredUserSurveysCount !== 0;

  return (
    <Item>
      <Section>
        <SurveyStatusPill status={status} />
        <TextContainer>
          <Link
            target="_blank"
            to={mapRoute('surveyPreview', { id: `${surveyRelationship.survey_id}` })}
          >
            <Text
              size="h5"
              color={colors.neutral900}
              medium
              ellipsisOnOverflow
              ref={nodeRef}
              {...titleTooltip.targetProps}
            >
              {title}
            </Text>
            <Tooltip id={titleTooltip.uid} hide={!hasEllipsis}>
              {title}
            </Tooltip>
          </Link>
        </TextContainer>

        <DropDownMenu
          icon="elipsis"
          fontSize={20}
          buttonAriaLabel={`Actions for ${title}`}
          upwards={isLast}
        >
          <DropDownMenu.Item title="Copy Link to Survey" icon="link" onClick={copyURL} />
          <DropDownMenu.Item
            title="Preview Survey"
            target="_blank"
            route={mapRoute('surveyPreview', { id: `${surveyRelationship.survey_id}` })}
            icon="view"
          />
          <DropDownMenu.Item
            title="Manage Survey"
            icon="external"
            target="_blank"
            route={mapRoute('dashboardSurveyInstances', {
              id: `${surveyRelationship.survey_id}`,
            })}
          />
          {showSendReminder && (
            <DropDownMenu.Item title="Send Reminder" onClick={showReminderModal} icon="bell" />
          )}
          <DropDownMenu.Separator />
          <DropDownMenu.Item
            onClick={() => handleCopySurveyRelationshipEmails('answered')}
            title="Copy Answered Survey E-mails"
            icon="mail"
          />
          <DropDownMenu.Item
            onClick={() => handleCopySurveyRelationshipEmails('unanswered')}
            title="Copy Non-Answered Survey E-mails"
            icon="mail"
          />
          <DropDownMenu.Separator />
          <DropDownMenu.Item
            title={`Export ${userSurveyCount === 1 ? 'Response' : 'Responses'}`}
            icon="download"
            disabled={!enableExportResponses}
            url={getSurveyExportResponsesCsvUrl({
              survey_relationship_id: surveyRelationship.id,
            })}
          />
        </DropDownMenu>
      </Section>

      <Section>
        {status === SurveyRelationshipStatus.NOT_STARTED ? (
          <Pill
            icon="clock-filled"
            variant="lighterGrayWithDarkerTextColor"
            label={`Opens ${displayDate(scheduleDate)} • ${parseScheduleInfo(surveyRelationship)}`}
          />
        ) : (
          <Pill
            icon="checkbox-round"
            variant="success200"
            label={`Opened ${displayDate(scheduleDate)}`}
          />
        )}
        {status !== SurveyRelationshipStatus.CLOSED ? (
          <Pill
            icon="clock-filled"
            variant="lighterGrayWithDarkerTextColor"
            label={`Closes ${displayDate(cutoffDate)} • ${parseCutoffInfo(
              surveyRelationship
            )} after opening`}
          />
        ) : (
          <Pill
            icon="checkbox-round"
            variant="success200"
            label={`Closed ${displayDate(cutoffDate)}`}
          />
        )}
      </Section>

      <Section>
        {userSurveyCount === 0 ? (
          <Pill
            variant={
              status === SurveyRelationshipStatus.CLOSED
                ? 'error200'
                : 'lighterGrayWithDarkerTextColor'
            }
            label="No responses"
          />
        ) : (
          <Pill
            variant={
              unansweredUserSurveysCount === 0 ? 'success200' : 'lighterGrayWithDarkerTextColor'
            }
            label={`${userSurveyCount}/${attendeesCount} responses`}
          />
        )}
        <RespondentsAvatars>
          {map(surveyRelationship.last_five_respondent_users, (user) => (
            <UserAvatar user={user} size="small" showPopover sendEmailCTA />
          ))}{' '}
          {userSurveyCount > 5 && (
            <Pill
              variant="lighterGrayWithDarkerTextColor"
              size="medium"
              label={`+${userSurveyCount - 5}`}
            />
          )}
        </RespondentsAvatars>
      </Section>
      {isConfirmingRemind && (
        <SurveyModalReminder
          surveyRelationship={surveyRelationship}
          entityId={eventId}
          unansweredUserSurveysCount={unansweredUserSurveysCount}
          handleClose={() => setIsConfirmingRemind(false)}
          setIsConfirmingRemind={setIsConfirmingRemind}
          setReminderSent={noop}
        />
      )}
    </Item>
  );
};

EventSurveyModalItem.propTypes = {
  attendeesCount: PropTypes.number,
  surveyRelationship: PropTypes.object,
  isLast: PropTypes.bool,
  eventId: PropTypes.number,
};

export default EventSurveyModalItem;
