import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  NOT_STARTED_STATUS,
  INCOMPLETE_STATUS,
  IN_PROGRESS_STATUS,
  COMPLETED_STATUS,
} from '~/app/catalog/constants';
import colors from '~/services/colors';
import { OptimalContentItemCard } from '~/app/shared/components/Card';
import HR from '~/app/shared/components/HR';
import MediaPoint from '~/app/shared/components/MediaPoint';
import Text from '~/app/shared/components/Text';
import { get, filter, map, includes, size } from 'lodash-es';

const SnippetsGroupContainer = styled.div`
  margin: 0 auto;
  width: 350px;

  ${MediaPoint.Tablet} {
    width: 690px;
  }

  ${MediaPoint.DesktopLg} {
    width: 1015px;
  }
`;

const SnippetsGroupTopBlock = styled.div`
  padding: 0 12px;
  width: 335px;

  ${MediaPoint.Tablet} {
    width: 690px;
  }

  ${MediaPoint.DesktopLg} {
    width: 1015px;
  }
`;

const SnippetsCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 32px 0;
  width: 350px;

  ${MediaPoint.Tablet} {
    width: 690px;
  }

  ${MediaPoint.DesktopSm} {
  }

  ${MediaPoint.DesktopLg} {
    width: 1015px;
  }

  &:after {
    content: '';
    flex: 0 0 360px; /* 336px width + margins */
  }
`;

const SnippetContainer = styled.div`
  margin: 0 12px 20px 12px;
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;

  ol {
    padding-inline-start: 16px;
  }

  ${MediaPoint.MobileLg} {
    margin: 0 auto;
  }

  ${MediaPoint.Tablet} {
    margin: 0 12px 20px 12px;
  }
`;

const PageTitle = styled.span`
  color: ${colors.neutral600};
  font-size: 24px;
`;

const HeaderArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px auto;
  width: 300px;

  ${MediaPoint.MobileLg} {
    width: 300px;
  }

  ${MediaPoint.Tablet} {
    width: 690px;
  }

  ${MediaPoint.DesktopLg} {
    width: 1000px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MyLearningContent = (props) => {
  const { contents } = props;

  if (!contents) return null;

  const completedContents = filter(
    contents,
    (content) => get(content, 'assignment.completion_status') === COMPLETED_STATUS
  );

  const incompleteContents = filter(contents, (content) =>
    includes(
      [INCOMPLETE_STATUS, IN_PROGRESS_STATUS, NOT_STARTED_STATUS],
      get(content, 'assignment.completion_status')
    )
  );

  return (
    <div>
      <HeaderArea>
        <FirstRow>
          <PageTitle>My Content</PageTitle>
        </FirstRow>
      </HeaderArea>
      {/* in-progress tracks */}
      <SnippetsGroupContainer>
        <SnippetsGroupTopBlock>
          <Text size="h4" block color={colors.neutral600}>
            {size(incompleteContents) || 'No'} incomplete content item
            {incompleteContents.length !== 1 && 's'}
          </Text>
          <HR height={2} color={colors.neutral200} />
        </SnippetsGroupTopBlock>
        <SnippetsCardsContainer>
          {map(incompleteContents, (item) => (
            <SnippetContainer key={item.public_id}>
              <OptimalContentItemCard contentItem={item} />
            </SnippetContainer>
          ))}
        </SnippetsCardsContainer>
      </SnippetsGroupContainer>
      {/* completed tracks */}
      <SnippetsGroupContainer>
        <SnippetsGroupTopBlock>
          <Text size="h4" block color={colors.neutral600}>
            {size(completedContents) || 'No'} completed content item
            {completedContents.length !== 1 && 's'}
          </Text>
          <HR height={2} color={colors.neutral200} />
        </SnippetsGroupTopBlock>
        <SnippetsCardsContainer>
          {map(completedContents, (item) => (
            <SnippetContainer key={item.public_id}>
              <OptimalContentItemCard contentItem={item} />
            </SnippetContainer>
          ))}
        </SnippetsCardsContainer>
      </SnippetsGroupContainer>
    </div>
  );
};

MyLearningContent.propTypes = {
  contents: PropTypes.array,
};

export default MyLearningContent;
