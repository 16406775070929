import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import HR from '~/app/shared/components/HR';
import Text from '~/app/shared/components/Text';
import { useCurrentUser } from '~/app/shared/hooks';
import { map, size } from 'lodash-es';

import { TimeslotDetail } from './TimeslotDetail';

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
`;

const DescriptionHeading = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  justify-content: space-between;
`;

const EventTimeslots = ({ event }) => {
  const { timezone: userTimezone } = useCurrentUser();

  const { timeslots, is_online: isOnline } = event;

  if (size(timeslots) === 1) return null;

  return (
    <Container>
      <DescriptionHeading>
        <Text medium>Schedule</Text>
      </DescriptionHeading>
      {map(timeslots, (timeslot, index) => (
        <React.Fragment key={`timeslot-${timeslot.id}`}>
          <TimeslotDetail timeslot={timeslot} isOnline={isOnline} userTimezone={userTimezone} />
          <HR color={index !== size(timeslots) - 1 ? colors.neutral100 : colors.neutral0} />
        </React.Fragment>
      ))}
    </Container>
  );
};

EventTimeslots.propTypes = {
  event: PropTypes.object,
};

export default EventTimeslots;
