import * as Sentry from '@sentry/react';

export function setupSentry(history: History) {
  const sentryDsn = window.SENTRY_REACT_DSN;

  if (!sentryDsn || sentryDsn === '') {
    return;
  }

  Sentry.init({
    dsn: sentryDsn,
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
    tracesSampleRate: 0.5,
  });
}
