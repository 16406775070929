import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import { sessionEnrollmentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import SearchInput from '~/app/inputs/components/SearchInput';
import SessionAttendeeAgendaList from '~/app/program/components/SessionAttendeeAgendaList';
import colors from '~/services/colors';
import { useDebounce, useLabels } from '~/app/shared/hooks';

const Container = styled.div`
  max-height: 600px;
`;

const SearchBar = styled.div`
  padding: 20px;
`;

const AttendeeLabel = styled.div`
  color: ${colors.neutral600};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const StyledHR = styled.hr`
  color: gray;
  width: 100%;
  margin: 0px;
`;

const SessionRosterAgenda = ({ session }) => {
  const [searchText, setSearchText] = useState('');
  const {
    label_mentorship_sessions_attendee_title_single: mentorshipAttendeeLabel,
    label_program_sessions_attendee_title_single: programAttendeeLabel,
  } = useLabels();
  const attendeeLabel = session.program_id ? programAttendeeLabel : mentorshipAttendeeLabel;

  const handleOnSearchTextChange = (value) => setSearchText(value);
  const debouncedSearchText = useDebounce(searchText, 500);

  const [
    getEnrollments,
    { status: getEnrollmentsStatus, data: enrollments, nextPage },
    loadMoreEnrollments,
  ] = useEntities(actions.sessionEnrollment.retrieveList, null, {
    schema: [sessionEnrollmentSchema],
    loadMoreAction: actions.sessionEnrollment.retrieveListLoadMore,
  });

  useEffect(() => {
    getEnrollments({
      q: debouncedSearchText,
      session: session.public_id,
      page_size: 5,
    });
  }, [debouncedSearchText]);

  return (
    <Container>
      <SearchBar>
        <AttendeeLabel>{attendeeLabel}</AttendeeLabel>
        <SearchInput
          placeholder="Search by name"
          value={searchText}
          onChange={handleOnSearchTextChange}
        />
      </SearchBar>
      <StyledHR />
      <SessionAttendeeAgendaList
        session={session}
        enrollments={enrollments}
        getEnrollmentsStatus={getEnrollmentsStatus}
        hasNextPage={Boolean(nextPage)}
        loadMoreEnrollments={loadMoreEnrollments}
      />
    </Container>
  );
};

SessionRosterAgenda.propTypes = {
  session: PropTypes.object,
};

export default SessionRosterAgenda;
