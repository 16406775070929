import { useCallback, useEffect } from 'react';

import ManageAssignments from '~/app/content-items/components/ManageAssignments/ManageAssignments';
import actions from '~/app/entities/actions';
import { contentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { EVENT_VIEW_MODES } from '~/app/event/constants';
import Page404 from '~/scenes/404';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import Loading from '~/app/shared/components/Loading';
import { HTTP_404_NOT_FOUND, STATUS_DONE } from '~/app/shared/constants';
import { usePublicIdFromURL } from '~/app/shared/hooks';

import ListEnrollments from './ListEnrollments';

const EventEnrollments = () => {
  const { publicId } = usePublicIdFromURL();
  const { trackActivity } = useMetrics();
  const [fetchData, { data: event, status: fetchEventStatus, statusCode }] = useEntities(
    actions.content.retrieveDetails,
    ({ status }) => {
      if (status === STATUS_DONE) {
        trackActivity(METRICS_ACTIVITIES.EVENT_ENROLLMENTS_VIEW, { eventId: event.id });
      }
    },
    { schema: contentSchema }
  );

  const fetchEvent = useCallback(
    () => fetchData(publicId, { view_mode: EVENT_VIEW_MODES.manage_enrollments }),
    [fetchData, publicId]
  );

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent]);

  if (statusCode === HTTP_404_NOT_FOUND) return <Page404 />;
  if (fetchEventStatus !== STATUS_DONE) return <Loading />;
  return (
    <ManageAssignments
      contentItem={event}
      titleLabel="Manage attendance"
      ListComponent={ListEnrollments}
    />
  );
};

export default EventEnrollments;
