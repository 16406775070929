import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';

const StyledFieldWarning = styled.div`
  color: ${colors.alert600};
  font-size: 14px;
  margin-top: 4px;
`;

const FieldWarning = ({ warning, meta: { error } }) => {
  if (error) {
    return null;
  }

  return <StyledFieldWarning>{warning}</StyledFieldWarning>;
};

FieldWarning.propTypes = {
  warning: PropTypes.string,
  meta: PropTypes.object,
};

export default FieldWarning;
