import * as React from 'react';

import { useEncodedCurrentRoute } from '~/app/shared/hooks';
import { mapRoute } from '~/services/requests';

type UseChannelRoutesOptions = {
  withOriginRoute?: boolean;
};

export function useChannelRoutes(options: UseChannelRoutesOptions = {}) {
  const { withOriginRoute = false } = options;

  const currentRoute = useEncodedCurrentRoute();

  const withOrigin = (path) => (withOriginRoute ? `${path}?origin=${currentRoute}` : path);

  const routes = React.useMemo(
    () => ({
      create: withOrigin(mapRoute('channelCreate')),
      detail: ({ slug }) => withOrigin(mapRoute('channelDetail', { slug })),
      edit: ({ slug }) => withOrigin(mapRoute('channelEdit', { slug })),
    }),
    [withOrigin]
  );

  return routes;
}
