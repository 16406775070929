import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useFetchDataList } from '~/app/data-fetching/hooks';
import { queries } from '~/queries';
import Button from '~/app/shared/components/Button';
import Loading from '~/app/shared/components/Loading';
import Modal, { ModalBody, ModalFooter } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { STATUS_LOADING } from '~/app/shared/constants';
import { map, isEmpty } from 'lodash-es';
import { Box } from '@mui/material';
import { getMessage } from '../../../../scenes/Dashboard/Logs/LogRenderingUtils';

import LogText from './LogText';

const LogList = styled.ul`
  margin: 0;
  padding: 0;
`;

// Used for Dashboard UI and action dispatch
const LOGS_PAGE_SIZE = 15;

const LogtrailModal = ({ title, expression, handleClose, ...rest }) => {
  const [page, setPage] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [rqlExpression, setRqlExpression] = useState(
    `${expression}&page=${page}&page_size=${LOGS_PAGE_SIZE}&view_mode=modal`
  );
  const [loadedRows, setLoadedRows] = useState([] as any[]);

  const { data, status, scrollId } = useFetchDataList({
    ...queries.logs.list(rqlExpression),
  });

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
    setLoadingMore(true);
  };

  useEffect(() => {
    let expressionWithViewModeAndScrollId = `${expression}&page=${page}&page_size=${LOGS_PAGE_SIZE}&view_mode=modal`;
    if (!isEmpty(scrollId)) {
      expressionWithViewModeAndScrollId = `${expressionWithViewModeAndScrollId}&scroll_id=${scrollId}`;
    }
    setRqlExpression(expressionWithViewModeAndScrollId);
  }, [page]);

  useEffect(() => {
    const normalizedData = data || [];
    setLoadedRows((prev) => [...prev, ...normalizedData]);
    setLoadingMore(false);
  }, [data]);

  useEffect(() => {
    if (loadingMore && isEmpty(data)) {
      setShowLoadMore(false);
    }
  }, [loadingMore]);

  const isLoading = status === STATUS_LOADING;
  return (
    <Modal handleClose={handleClose} width={600} title={title} {...rest}>
      <ModalBody>
        {isLoading && isEmpty(loadedRows) ? (
          <Loading />
        ) : !isEmpty(loadedRows) ? (
          <>
            <LogList>
              {map(loadedRows, (log) => (
                <LogText key={log.id} log={log} message={getMessage(log)} />
              ))}
            </LogList>
            {showLoadMore ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                paddingY={2.5}
              >
                <Button onClick={handleLoadMore} size="small">
                  Load more
                </Button>
              </Box>
            ) : null}
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            paddingY={2.5}
          >
            <Text size="h4">No logs found</Text>
          </Box>
        )}
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
};

LogtrailModal.propTypes = {
  handleClose: PropTypes.func,
  expression: PropTypes.string,
  title: PropTypes.string,
};

export default LogtrailModal;
