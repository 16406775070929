import PropTypes from 'prop-types';
import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';

import colors from '~/services/colors';

const LoadingPanel = styled.div`
  vertical-align: middle;
  text-align: center;
  margin: 0;
  ${({ hasMargin }) => hasMargin && 'margin-top: 50px;margin-bottom: 50px;'};
`;

const Loading = ({ size, color, hasMargin }) => (
  <LoadingPanel hasMargin={hasMargin}>
    <BeatLoader size={size} color={color} loading />
  </LoadingPanel>
);

Loading.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  hasMargin: PropTypes.bool,
};

Loading.defaultProps = {
  size: 15,
  color: colors.emphasis600,
  hasMargin: true,
};

export default Loading;
