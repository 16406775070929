import PropTypes from 'prop-types';
import React, { useState } from 'react';

import actions from '~/app/entities/actions';
import { contentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { STATUS_DONE, STATUS_LOADING, STATUS_LOADING_MORE } from '~/app/shared/constants';
import { includes, map, size } from 'lodash-es';

import AutocompleteField from '../AutocompleteField';

const pageSize = 25;

const ContentTypeSelectField = ({ contentType, ...props }) => {
  const [options, setOptions] = useState([]);

  const [fetch, { status, count }] = useEntities(
    actions.content.retrieveList,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        let contentOptions = map(data, ({ value, name, is_archived: isArchived }) => ({
          value,
          label: isArchived ? `${name} (Archived)` : name,
        }));

        if (count && count > pageSize) {
          contentOptions = [
            ...contentOptions,
            {
              label: `and ${count - size(data)} more`,
              disabled: true,
            },
          ];
        }

        setOptions(contentOptions);
      }
    },
    {
      schema: [contentSchema],
    }
  );

  const loading = includes([STATUS_LOADING, STATUS_LOADING_MORE], status);
  const fetchOptionsCb = ({ q, includeValues }) => {
    fetch({
      view_mode: 'filter_options',
      page_size: pageSize,
      include_public_ids: includeValues || undefined,
      content_type: contentType || undefined,
      q: q || undefined,
    });
  };

  return (
    <AutocompleteField
      options={options}
      loading={loading}
      fetchOptions={fetchOptionsCb}
      {...props}
    />
  );
};

ContentTypeSelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  contentType: PropTypes.object,
};

export default ContentTypeSelectField;
