import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Pill from '~/app/shared/components/Pill';
import Text from '~/app/shared/components/Text';
import { Tooltip } from '~/app/shared/components/Tooltip';
import { useEllipsisCheck, useTooltipUID } from '~/app/shared/hooks';
import { map } from 'lodash-es';

const TextContainer = styled.div`
  max-width: 95%;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  padding: 12px;
  border: 1px solid ${colors.neutral200};
  border-radius: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const ResourceName = ({ resource }) => {
  const labelTooltip = useTooltipUID();
  const { hasEllipsis, nodeRef } = useEllipsisCheck();
  return (
    <TextContainer>
      <ButtonLink url={resource.link} variant="neutral" target="_blank">
        <Text {...labelTooltip.targetProps} ellipsisOnOverflow ref={nodeRef}>
          {resource.name}
        </Text>
        <Tooltip id={labelTooltip.uid} maxWidth={282} hide={!hasEllipsis}>
          {resource.name}
        </Tooltip>
      </ButtonLink>
    </TextContainer>
  );
};

ResourceName.propTypes = {
  resource: PropTypes.object,
};

const ResourceLinkContainer = ({ resources }) => {
  return (
    <Container>
      {map(resources, (resource) => (
        <Item key={`resource_item-${resource.id}`}>
          <Pill
            icon="world"
            size="medium"
            iconMarginRight="0px"
            variant="lighterGrayWithDarkerTextColor"
            round
          />
          <ResourceName resource={resource} />
        </Item>
      ))}
    </Container>
  );
};

ResourceLinkContainer.propTypes = {
  resources: PropTypes.array,
};

export default ResourceLinkContainer;
