import moment from 'moment';

import { secondsToDuration } from '~/services/datetime';
import { endsWith, replace, startsWith } from 'lodash-es';

export function generateDurationOptionsIn15MinIntervals() {
  const options = [] as { label: string; value: string }[];

  for (let hour = 0; hour < 24; hour += 1) {
    for (let minute = 0; minute < 60; minute += 15) {
      // Do not push 00:00
      if (!(hour === 0 && minute === 0)) {
        const time = moment({ hour, minute }).format('H:mm');
        options.push({ label: time, value: time });
      }
    }
  }

  options.push({ label: '24:00', value: '24:00' });

  return options;
}

export const isDurationFormatValid = (value) => {
  const DURATION_REGEX = /^([0-9]?[0-9]):([0-5][0-9])((:([0-5][0-9]))?)$/;

  return value && String(value).match(DURATION_REGEX);
};

export const hasSecondsInDuration = (value) => {
  const DURATION_WITH_SECONDS_REGEX = /^([0-9]?[0-9]):([0-5][0-9]):([0-5][0-9])$/;

  return value.match(DURATION_WITH_SECONDS_REGEX);
};

export const hasZeroedSecondsInDuration = (value) => {
  return hasSecondsInDuration(value) && endsWith(value, ':00');
};

export const hasZeroedHoursInDuration = (value) => {
  return startsWith(value, '0:') || startsWith(value, '00:');
};

export const isDurationLengthValid = ({ value, minLengthInSeconds }) => {
  const durationAsSeconds = moment.duration(value).asSeconds();

  return isDurationFormatValid(value) && durationAsSeconds >= minLengthInSeconds;
};

export const validateDuration = (value) => {
  if (!value) {
    return;
  }

  if (!isDurationFormatValid(value)) {
    return 'Please enter a valid duration. e.g.: 1:00';
  }

  if (!isDurationLengthValid({ value, minLengthInSeconds: 1 })) {
    return 'Duration cannot be 0:00';
  }
};

export const validateVideoDuration = (value) => {
  if (!value) {
    return;
  }

  return !isDurationFormatValid(value)
    ? 'Please enter a valid duration. e.g.: 00:30:00'
    : undefined;
};

export const addLeadingZeroToTimeUnit = (value) => {
  return value > 9 ? value : `0${value}`;
};

export const normalizeHours = (days, hours) => {
  return addLeadingZeroToTimeUnit(days * 24 + hours);
};

export const normalizeDuration = (value) => {
  const normalizedForDays = replace(value, ' ', '.');
  const durationAsSeconds = moment.duration(normalizedForDays).asSeconds();
  const duration = secondsToDuration(durationAsSeconds);

  const hours = normalizeHours(duration.days, duration.hours);
  const minutes = addLeadingZeroToTimeUnit(duration.minutes);
  const seconds = addLeadingZeroToTimeUnit(duration.seconds);

  return [hours, minutes, seconds].join(':');
};

export const formatDurationToEmit = (value) => {
  const durationWithSeconds = hasSecondsInDuration(value) ? value : `${value}:00`;

  return normalizeDuration(durationWithSeconds);
};

export const formatDurationToPresent = (value) => {
  const valueWithoutLeadingZeros = hasZeroedHoursInDuration(value)
    ? replace(value, /^0+/, '0')
    : replace(value, /^0+/, '');

  const valueWithoutZeroedSeconds = hasZeroedSecondsInDuration(value)
    ? valueWithoutLeadingZeros.slice(0, -3)
    : valueWithoutLeadingZeros;

  return valueWithoutZeroedSeconds;
};
