import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';

const FieldError = styled.div`
  color: ${colors.error600};
  font-size: 14px;
  margin-top: 4px;
`;

// New component: FieldError.js
const OldFieldError = (props) => {
  const { meta, children } = props;

  if (children) {
    return <FieldError>{children}</FieldError>;
  }

  if (!meta) return null;

  const { error, warning } = meta;

  if (!error && !warning) return null;

  return (
    <FieldError>
      {error} {warning}
    </FieldError>
  );
};

OldFieldError.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  meta: PropTypes.object,
};

export default OldFieldError;
