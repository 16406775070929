import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import { getUserImage } from '~/services/utils';
import Pill from '~/app/shared/components/Pill';
import Text from '~/app/shared/components/Text';

const SuggestedLabel = styled(Text)`
  margin-right: 6px;
  color: ${colors.neutral600};
`;

export const MentorshipSuggestionPill = ({
  mentorshipRequest,
  onSuggestionClicked,
  suggestionLabel,
}) => {
  const { suggested } = mentorshipRequest;

  return (
    <Text style={{ display: 'flex' }}>
      <SuggestedLabel>{suggestionLabel}</SuggestedLabel>
      <Pill
        onClick={onSuggestionClicked}
        size="medium"
        label={suggested.display_name}
        labelMaxWidth={100}
        variant="lighterGrayWithDarkerTextColor"
        imageSrc={getUserImage(suggested)}
        arial-label={`${suggestionLabel} - ${suggested.display_name}`}
        showLabelOnTooltip={false}
      />
    </Text>
  );
};

MentorshipSuggestionPill.propTypes = {
  mentorshipRequest: PropTypes.object.isRequired,
  suggestionLabel: PropTypes.string.isRequired,
  onSuggestionClicked: PropTypes.func,
};
