import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';
import queryString from 'query-string';

import { ApiURLs, fetchURL } from '~/services/requests-base';
import { omit } from 'lodash-es';

export const catalog = createQueryKeys('catalog', {
  discover: (filters: Record<string, unknown>) => ({
    queryKey: [filters],
    queryFn: async () => {
      const baseUrl = ApiURLs['api_catalog:discover']();
      const query = queryString.stringify(omit(filters, ['id']));

      const { data: response } = await fetchURL(`${baseUrl}?${query}`);
      return response;
    },
  }),
});

export type CatalogQueryKeys = inferQueryKeys<typeof catalog>;
