import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import AccordionCheckboxField from '~/app/inputs/components/AccordionCheckboxField';
import TextField from '~/app/inputs/components/TextField';
import colors from '~/services/colors';
import { parseNumber } from '~/services/utils';
import HR from '~/app/shared/components/HR';

const EnrollmentCapManager = ({
  label,
  enrollmentLimitFieldName,
  waitlistLimitFieldName,
  renderConvertEvent,
  checkboxDisabled,
  enrollmentDisabled,
  waitlistDisabled,
  showWaitlist,
  enrollmentExtraProps,
  waitlistExtraProps,
  ...props
}) => (
  <AccordionCheckboxField
    {...props}
    disabled={checkboxDisabled}
    label={label}
    hasNoMaxWidth
    isNewEventForm
  >
    <div>
      <div>
        <Field
          name={enrollmentLimitFieldName}
          component={TextField}
          label="Enrollment Cap"
          type="number"
          inputProps={{
            min: 0,
            'aria-label': `${label} Enrollment Cap`,
          }}
          parse={parseNumber}
          disabled={enrollmentDisabled}
          subtext="Empty or '0' means unlimited spots."
          {...enrollmentExtraProps}
        />
      </div>
      {showWaitlist && (
        <>
          <HR color={colors.neutral200} margin="15px -15px" />
          <div>
            <Field
              name={waitlistLimitFieldName}
              label="Waitlist Cap"
              component={TextField}
              type="number"
              inputProps={{
                min: 0,
                'aria-label': `${label} Waitlist Cap`,
              }}
              parse={parseNumber}
              disabled={waitlistDisabled}
              subtext="Empty or '0' means no waitlisting is allowed."
              {...waitlistExtraProps}
            />
          </div>
        </>
      )}
      {renderConvertEvent?.()}
    </div>
  </AccordionCheckboxField>
);

EnrollmentCapManager.propTypes = {
  label: PropTypes.string,
  enrollmentLimitFieldName: PropTypes.string,
  waitlistLimitFieldName: PropTypes.string,
  renderConvertEvent: PropTypes.func,

  checkboxDisabled: PropTypes.bool,
  enrollmentDisabled: PropTypes.bool,
  waitlistDisabled: PropTypes.bool,
  showWaitlist: PropTypes.bool,

  enrollmentExtraProps: PropTypes.object,
  waitlistExtraProps: PropTypes.object,
};

EnrollmentCapManager.defaultProps = {
  checkboxDisabled: false,
  enrollmentDisabled: false,
  waitlistDisabled: false,
  enrollmentExtraProps: {},
  waitlistExtraProps: {},
};

export default EnrollmentCapManager;
