import * as React from 'react';
import { Box, styled } from '@mui/material';
import { includes, isNil } from 'lodash-es';

import PageTitle from '~/app/shared/components/PageTitle';
import TrackConsumptionTopBar from '~/app/tracks/components/TrackConsumptionTopBar';
import { Assessment } from '~/app/assessments/interfaces';
import { RemoveAppFooter } from '~/common/components/RemoveAppFooter';
import { isTrackAssessment } from '~/features/assessments/utils/helpers';
import { TrackContentRenderer } from '~/features/tracks/components/TrackContentRenderer';
import {
  TrackContentConsumptionProvider,
  useTrackContentConsumptionCtx,
} from '~/features/tracks/contexts/TrackContentConsumptionCtx';
import { TrackContentNavigationProvider } from '~/features/tracks/contexts/TrackContentNavigationCtx';
import colors from '~/services/colors';
import { ScheduledTrack, Track } from '~/app/tracks/interfaces';

import { TrackContentSidebar } from '../components/TrackContentSidebar';
import { TrackNavigationBar } from '../components/TrackNavigationBar';
import { TrackSidebarProvider, useTrackSidebarCtx } from '../contexts/TrackSidebarCtx';
import { useQueryParams, useRouterQueryUtils } from '~/app/shared/hooks';
import { useComposableTrackCtx } from '../contexts/ComposableTrackCtx';

type MainContainerProps = {
  sidebarOpen?: boolean;
  sidebarWidth: number;
};

const mainContainerOwnProps = ['sidebarOpen', 'sidebarWidth'];

const MainContainer = styled('div', {
  shouldForwardProp: (prop) => !includes(mainContainerOwnProps, prop),
})<MainContainerProps>(({ theme, sidebarOpen, sidebarWidth }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
  backgroundColor: colors.neutral50,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${sidebarWidth}px`,

  ...(sidebarOpen && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

function TrackContentMain() {
  const { currentTrackNode } = useTrackContentConsumptionCtx();
  const { isSidebarOpen, sidebarWidth } = useTrackSidebarCtx();

  const isCurrentTrackNodeAssessment = isTrackAssessment(currentTrackNode.track);
  const shouldDisplayNavigationBar = !isCurrentTrackNodeAssessment;

  return (
    <MainContainer sidebarOpen={isSidebarOpen} sidebarWidth={sidebarWidth}>
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        minHeight="0"
        overflow="auto"
        sx={{
          overscrollBehavior: 'contain',
        }}
      >
        <Box display="flex" flexDirection="column">
          <TrackContentRenderer />
        </Box>
      </Box>

      {shouldDisplayNavigationBar && <TrackNavigationBar />}
    </MainContainer>
  );
}

function TrackContentPageLayout() {
  return (
    <Box display="flex" flexDirection="column" flex="1 1 0" minHeight="0">
      <TrackConsumptionTopBar />

      <Box flex="1" display="flex" minHeight="0" position="relative">
        <TrackContentSidebar />
        <TrackContentMain />
      </Box>
    </Box>
  );
}

function useGuardTrackContentPageBounds() {
  const { page: pageParam } = useQueryParams();
  const page = pageParam ? Number(pageParam) : 0;

  const { removeFromQueryString } = useRouterQueryUtils();

  const { totalCount } = useComposableTrackCtx();

  const isOutOfBounds = page < 1 || page > totalCount;

  React.useEffect(() => {
    if (!isOutOfBounds) return;
    removeFromQueryString(['page'], true);
  }, [isOutOfBounds, removeFromQueryString]);

  return isOutOfBounds;
}

type TrackContentPageProps = {
  track?: Track;
  scheduledTrack?: ScheduledTrack;
  assessment?: Assessment;
};

function TrackContentPage(props: TrackContentPageProps) {
  const { track, scheduledTrack, assessment } = props;

  const content = track || scheduledTrack || assessment;

  const isOutOfBounds = useGuardTrackContentPageBounds();

  if (isNil(content) || isOutOfBounds) {
    return null;
  }

  return (
    <TrackContentConsumptionProvider>
      <TrackContentNavigationProvider>
        <TrackSidebarProvider>
          <PageTitle title={content.name} />
          <TrackContentPageLayout />
          <RemoveAppFooter />
        </TrackSidebarProvider>
      </TrackContentNavigationProvider>
    </TrackContentConsumptionProvider>
  );
}

export default TrackContentPage;
