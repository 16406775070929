import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormSection } from 'redux-form';
import styled from 'styled-components';

import { useFormPreventTransition } from '~/app/shared/hooks';
import { scrollTo } from '~/services/utils';
import SettingContextBaseBlock from '~/app/settings/components/SettingContextBaseBlock';
import ContentTopbarPage from '~/app/shared-layouts/ContentTopbarPage';
import ContentSidebarItem from '~/app/shared-layouts/ContentTopbarPage/ContentSidebarItem';
import EditFormTopBar from '~/app/shared/components/FormTopBar/EditFormTopBar';
import Loading from '~/app/shared/components/Loading';
import { includes, map, replace } from 'lodash-es';

const Content = styled.div`
  width: 680px;
  margin: 0 auto;
  padding: 20px 0 40px;

  > * + * {
    margin-top: 20px;
  }
`;

const SettingsForm = ({
  form,
  settings: settingsContext,
  menuItems,
  handleSubmit,
  submitting,
  loading,
}) => {
  // Handles the sidebar interactions
  const { pathname, hash } = useLocation();
  const urlHash = replace(hash, '#', '');
  const history = useHistory();

  // If the page opens up with a hash, we want that users to be sent to the section
  // immediately. However, for sidebar interactions, we'll use smooth scrolling
  const [sectionScrollBehavior, setSectionScrollBehavior] = useState('auto');

  useFormPreventTransition(form);

  useEffect(() => {
    if (urlHash) {
      const el = document.getElementById(urlHash);
      if (el) {
        const offsetTop = 64 + 20; // Navbar height + Margin top
        const top = el?.offsetTop - offsetTop;
        scrollTo({ top, behavior: sectionScrollBehavior });
      }
    }
  }, [urlHash, settingsContext, loading]);

  return (
    <form onSubmit={handleSubmit}>
      <ContentTopbarPage
        hideOpenSidebarButton
        renderTopbar={
          <EditFormTopBar
            action="System Settings"
            title={settingsContext.label || ''}
            loading={submitting}
          />
        }
        renderSidebarContent={() => (
          // Renders all inner sections of a section and then add a HR separator
          // to the end in case this isn't the last top-level section
          <>
            {map(menuItems, ({ url, label, children }) => (
              <ContentSidebarItem
                key={url}
                selected={includes(url, pathname)}
                icon={null}
                label={label}
                subItemsList={children}
                setSectionScrollBehavior={setSectionScrollBehavior}
                onClick={() => {
                  setSectionScrollBehavior('smooth');
                  history.push(url);
                }}
              />
            ))}
          </>
        )}
      >
        <Content>
          <FormSection name="settings">
            {loading ? (
              <Loading />
            ) : (
              <SettingContextBaseBlock form={form} setting={settingsContext} depth={0} />
            )}
          </FormSection>
        </Content>
      </ContentTopbarPage>
    </form>
  );
};

SettingsForm.propTypes = {
  form: PropTypes.string,
  menuItems: PropTypes.array,
  settings: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  loading: PropTypes.bool,
};

export default SettingsForm;
