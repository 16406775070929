import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const LearningTypeBox = styled(Box, {
  name: 'ContentItemCard',
  slot: 'learningType',
})`
  display: flex;
  height: 24px;
  padding: 3px 6px 3px 16px;
  align-items: center;
  gap: 8px;
`;

type ContentItemCardLearningTypeProps = BoxProps & {
  icon: React.ReactNode;
};

export function ContentItemCardLearningType(props: ContentItemCardLearningTypeProps) {
  const { icon, children, ...restBoxProps } = props;

  return (
    <LearningTypeBox {...restBoxProps}>
      {icon}
      {children}
    </LearningTypeBox>
  );
}
