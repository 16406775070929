import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import colors from '~/services/colors';
import Datetime from '~/app/shared/components/Datetime';
import Text from '~/app/shared/components/Text';
import { get } from 'lodash-es';

const EventTextContainer = styled.div`
  margin: 0;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const DateBlock = styled.div`
  vertical-align: top;
  display: inline-block;
  width: 140px;
  color: ${colors.neutral400};
  font-size: 14px;
`;

const EventTextBlock = styled.div`
  display: inline-block;
  width: calc(100% - 140px);
`;

const EventText = (props) => {
  const { log, message, currentUser } = props;

  return (
    <EventTextContainer>
      <DateBlock>
        <Datetime
          datetime={log.created_at}
          timezone="UTC"
          displayTimezone={currentUser.timezone}
          isOnline
        />
      </DateBlock>
      <EventTextBlock>
        <Text size="h5" color={colors.neutral900}>
          {message}
        </Text>
      </EventTextBlock>
    </EventTextContainer>
  );
};

EventText.propTypes = {
  log: PropTypes.object,
  message: PropTypes.object,

  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: get(state, 'user.currentUser'),
});

export default connect(mapStateToProps, null)(EventText);
