import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import equal from 'deep-equal';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Button } from '~/app/shared';
import OperatorLabel from '~/app/backoffice/components/Dashboard/OperatorLabel';
import colors from '~/services/colors';
import DropDownMenu from '~/app/shared/components/DropDownMenu';
import { usePrevious } from '~/app/shared/hooks';
import {
  filter,
  includes,
  indexOf,
  isEmpty,
  map,
  omit,
  sortBy,
  without,
  concat,
  keys,
  find,
  split,
} from 'lodash-es';

const StyledDropDownMenu = styled(DropDownMenu)`
  padding-top: 4px;
`;

const DashboardFilterBar = ({
  filterComponentMapping,
  defaultFilterNames,
  availableFilterComponentMapping,
  defaultAvailableFilterNames,
  handleFilterChange,
  currentFilters,
  hasSelectedItems,
}) => {
  const allFilterNames = concat(defaultFilterNames, defaultAvailableFilterNames);

  const [currentFilterNames, setCurrentFilterNames] = useState(defaultFilterNames);
  const [availableFilterNames, setAvailableFilterNames] = useState(defaultAvailableFilterNames);

  const previousDefaultFilterNames = usePrevious(defaultFilterNames);

  useEffect(() => {
    if (equal(previousDefaultFilterNames, defaultFilterNames)) return;
    setCurrentFilterNames(defaultFilterNames);
    setAvailableFilterNames(defaultAvailableFilterNames);
  }, [defaultFilterNames]);

  const getCurrentFilterComponents = () => {
    if (!currentFilterNames) return [];

    const filterComponents = filter(
      map(allFilterNames, (filterName) =>
        includes(currentFilterNames, filterName) ? filterComponentMapping[filterName] : {}
      ),
      (filterComponent) => !isEmpty(filterComponent)
    );

    return filterComponents;
  };

  const handleAddFilterComponent = (filterComponentName) => {
    setCurrentFilterNames([
      ...currentFilterNames,
      filterComponentName,
      `${filterComponentName}-op`,
    ]);
    setAvailableFilterNames(
      without(availableFilterNames, filterComponentName, `${filterComponentName}-op`)
    );
  };

  const handleRemoveFilterComponent = (param) => {
    const filterComponentName = find(
      allFilterNames,
      (filterName) =>
        includes(split(filterName, ','), param) || includes(split(filterName, ','), `${param}-op`)
    );

    setCurrentFilterNames(without(currentFilterNames, filterComponentName));

    setAvailableFilterNames(
      sortBy([...availableFilterNames, filterComponentName], (filterComponent) =>
        indexOf(keys(availableFilterComponentMapping), filterComponent)
      )
    );

    handleFilterChange(
      omit(currentFilters, split(filterComponentName, ','), split(`${filterComponentName}-op`, ','))
    );
  };

  const renderFilterComponent = ({
    key,
    filterComponent,
    onChange,
    selecteds,
    disabled,
    handleRemoveFilter,
  }) => {
    return React.cloneElement(filterComponent, {
      key,
      onChange,
      selecteds,
      disabled,
      handleRemoveFilter,
    });
  };

  return (
    <Box
      sx={{ flexGrow: 1, border: `1px solid ${colors.neutral200}`, borderRadius: 4, p: 2, mb: 2 }}
    >
      {filterComponentMapping && (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1}
          sx={{ display: 'flex', alignItems: 'flex-start' }}
        >
          {map(getCurrentFilterComponents(), (filterComponent, index, { length }) => (
            <Grid key={`filter-${index}`} item sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {renderFilterComponent({
                filterComponent,
                onChange: handleFilterChange,
                selecteds: currentFilters,
                disabled: hasSelectedItems,
                handleRemoveFilter: handleRemoveFilterComponent,
              })}
              {index < length - 1 && <OperatorLabel name="and" />}
            </Grid>
          ))}
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            {!isEmpty(availableFilterNames) && (
              <StyledDropDownMenu
                renderButton={({ toggleMenu }) => (
                  <Button
                    variant="text"
                    onClick={toggleMenu}
                    aria-label="Add Filter"
                    startIcon={<AddIcon />}
                  >
                    Add Filter
                  </Button>
                )}
              >
                {map(availableFilterNames, (filterName) => (
                  <DropDownMenu.Item
                    key={`available-filter-${filterName}`}
                    onClick={() => handleAddFilterComponent(filterName)}
                    {...availableFilterComponentMapping[filterName]}
                  />
                ))}
              </StyledDropDownMenu>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

DashboardFilterBar.propTypes = {
  filterComponentMapping: PropTypes.object,
  defaultFilterNames: PropTypes.arrayOf(PropTypes.string),
  availableFilterComponentMapping: PropTypes.object,
  defaultAvailableFilterNames: PropTypes.arrayOf(PropTypes.string),
  handleFilterChange: PropTypes.func,
  currentFilters: PropTypes.object,
  hasSelectedItems: PropTypes.bool,
};

export default DashboardFilterBar;
