import * as React from 'react';
import MUILink, { LinkProps as MUILinkProps } from '@mui/material/Link';
import { Link as ReactRouterLink } from 'react-router-dom';

export const RouterLink = (
  props: MUILinkProps<ReactRouterLink, { component: ReactRouterLink }>
) => {
  const defaultProps: MUILinkProps<ReactRouterLink, { component: ReactRouterLink }> = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    component: ReactRouterLink,
    underline: 'none',
  };

  return <MUILink {...defaultProps} {...props} />;
};

export const Link: React.FC<React.ComponentProps<typeof RouterLink>> &
  React.FC<MUILinkProps<'a'>> = (
  props: React.ComponentProps<typeof RouterLink> | MUILinkProps<'a'>
) => {
  if ('to' in props) {
    return <RouterLink {...props} />;
  }

  return <MUILink {...props} />;
};
