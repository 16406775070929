import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import { apiPost } from '~/services/requests';
import { Button } from '~/app/shared';
import Modal from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  margin-top: 20px;
`;

const TopPart = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BottomPart = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
  gap: 10px;
`;

const SurveyModalReminder = ({
  surveyRelationship,
  unansweredUserSurveysCount,
  setReminderSent,
  setIsConfirmingRemind,
  handleClose,
  entityId,
}) => {
  const [isReminding, setIsReminding] = useState(false);
  const handleConfirmRemind = (pk, entityId) => {
    setIsReminding(true);
    apiPost('api_surveys:survey_relationship_remind', { pk }, { entity_id: entityId })
      /* eslint-disable promise/always-return */
      .then(() => {
        setReminderSent(true);
        setTimeout(() => {
          setReminderSent(false);
        }, 5000);
      })
      .catch(() => {
        toast.error('Error', 'Please try again later');
      })
      .finally(() => {
        setIsConfirmingRemind(false);
        setIsReminding(false);
      });
  };

  return (
    <Modal handleClose={handleClose} width={200} height={20} title="Send Survey Reminder">
      <Container>
        <TopPart>
          <Text size="h5" align="center" color={colors.neutral600}>
            Send a notification to the
            {unansweredUserSurveysCount === 1
              ? ' 1 attendee '
              : ` ${unansweredUserSurveysCount} attendees `}
            who didn&apos;t yet respond to this survey.
          </Text>
        </TopPart>
        <BottomPart>
          <Spinner>{isReminding && <i className="fa fa-refresh fa-spin" />}</Spinner>
          {!isReminding && (
            <>
              <Button
                onClick={() => setIsConfirmingRemind(false)}
                iconName="close"
                width={14}
                height={14}
                color="error"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleConfirmRemind(surveyRelationship.id, entityId)}
                iconName="check"
                width={14}
                height={14}
                color="primary"
              >
                Send
              </Button>
            </>
          )}
        </BottomPart>
      </Container>
    </Modal>
  );
};

SurveyModalReminder.propTypes = {
  surveyRelationship: PropTypes.object,
  unansweredUserSurveysCount: PropTypes.number,
  setReminderSent: PropTypes.func,
  setIsConfirmingRemind: PropTypes.func,
  handleClose: PropTypes.func,
  entityId: PropTypes.number,
};

export default SurveyModalReminder;
