import { to } from '~/common/helpers';
import { toast } from '~/app/notifications/components/NotificationCenter';
import emitter from '~/services/emitters';
import { HTTP_500_INTERNAL_SERVER_ERROR } from '~/app/shared/constants';
import { includes, omit, get } from 'lodash-es';

export const ApiURLs = window.DjangoUrls;

const shouldLogout = (errorMessage) => {
  return includes(errorMessage, 'Authentication') || includes(errorMessage, 'CSRF');
};

const emitLoginAction = () => {
  emitter.emit('sessionAuthenticationIssue', {});
};

const emitConsentNoticeAction = (consentNoticeTitle, consentNoticeBlurb) => {
  emitter.emit('requireConsentNotice', { consentNoticeTitle, consentNoticeBlurb });
};

const generateIntervalServerErrorMessage = (method) => {
  return method === 'GET'
    ? `Oops. We ran into an error while attempting to load a part of this page. Our support team has been notified and will be looking into this issue shortly. Sorry for the inconvenience. You may wish to wait a few moments and try reloading the page.`
    : `Oops. This operation failed due to an internal error. Our support team has been notified and will be looking into this issue shortly. Sorry for the inconvenience. You may wish to wait a few moments and try again.`;
};

function shouldRedirectOn401(data) {
  const redirectUrl = get(data, 'redirect');
  return !!redirectUrl;
}

const handleThrowError = (response, method = 'GET', json = null) => {
  if (includes([403, 401], response.status) && shouldLogout(json?.detail)) {
    emitLoginAction();
  }

  if (response.status === 401 && shouldRedirectOn401(json)) {
    const redirectUrl = get(json, 'redirect');
    window.location = redirectUrl;
    return;
  }

  if (response.status === 403 && includes(json?.detail, 'consent notice')) {
    emitConsentNoticeAction(json?.consent_notice_title, json?.consent_notice_blurb);
  }

  if (includes([429], response.status)) {
    toast.error('Too many requests! Please try again later.');
  }

  const correlationId = response.headers.get('correlation-id');

  if (response.status === 408) {
    const errorMessage = json?.detail;
    const errorReferenceMessage = correlationId ? ` Error reference: ${correlationId}` : '';
    const title = `${response.statusText || response.status}`;
    toast.warning(`${title}`, `${errorMessage} ${errorReferenceMessage}`);
  }

  if (response.status >= HTTP_500_INTERNAL_SERVER_ERROR || (json?.is_dialog_error && json?.error)) {
    const errorMessage = json?.error || generateIntervalServerErrorMessage(method);
    const errorReferenceMessage = correlationId ? ` Error reference: ${correlationId}` : '';
    const title = json?.is_dialog_error ? 'Error' : `${response.statusText || response.status}`;

    toast.error(`${title}`, `${errorMessage}${errorReferenceMessage}`);
  }

  const error = new Error(`[${correlationId}] ${response.statusText || response.status}`);
  error.data = json;
  error.statusCode = response.status;
  throw error;
};

function formatResponse(response, data) {
  return {
    ...response,
    data,
    statusCode: response.status,
  };
}

export async function fetchURL(url, options = {}) {
  const defaultOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFTOKEN': window.CSRFTOKEN,
      ...get(options, 'headers', {}),
    },
    credentials: 'same-origin',
  };

  const mergedOptions = { ...defaultOptions, ...omit(options, 'headers') };

  const response = await fetch(url, mergedOptions);

  if (!response.ok) {
    const [error, json] = await to(response.json());

    if (error) {
      handleThrowError(response, options?.method);
    }

    handleThrowError(response, options?.method, json);
  }

  if (response.status === 204) {
    return formatResponse(response, {});
  }

  const [error, json] = await to(response.json());

  if (error) {
    return response;
  }

  return formatResponse(response, json);
}
