import {
  TextField,
  Autocomplete,
  ListItem,
  ListItemButton,
  Typography,
  menuItemClasses,
} from '@mui/material';
import { ListItemButtonProps } from '@mui/material/ListItemButton';
import { styled, darken } from '@mui/material/styles';
import { ElementType } from 'react';
import { Field } from 'redux-form';

import { FormFieldGroup } from '~/app/shared/components/Form';
import InfoText from '~/app/shared/components/InfoText';
import { find, get, map } from 'lodash-es';
import { ChannelTheme } from '~/features/channels/types';
import { useQuery } from '@tanstack/react-query';
import { queries } from '~/queries';

const ChannelListItem = styled(ListItem)`
  padding: 0px !important;
  border-radius: 6px;
  background-color: transparent !important;
`;

interface ChannelListOptionProps extends ListItemButtonProps {
  to?: string;
  component?: ElementType;
  backgroundColor?: string;
}

const ChannelListOption = styled(ListItemButton)<ChannelListOptionProps>`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  height: 54px;
  border-radius: 8px;
  margin: 5px 10px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'} !important;

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'} !important;
    border-radius: 8px;
  }

  &.${menuItemClasses.selected} {
    border: 1px solid;
    border-color: ${({ backgroundColor }) =>
      backgroundColor ? darken(backgroundColor, 0.2) : '#00000061'};
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'} !important;
    border-radius: 8px;
  }
`;

function makeThemeOptions(data: any) {
  const themes = get(data, 'results', []) as ChannelTheme[];

  return map(themes, (theme) => ({
    value: theme.id,
    label: theme.name,
    color: theme.config.colors.soft,
  }));
}

interface ColorPickerProps {
  input: {
    value: any;
    onChange: (value: any) => void;
  };
  meta?: any;
}

function ChannelThemePicker(props: ColorPickerProps) {
  const {
    input: { value, onChange },
    ...rest
  } = props;

  const { data: themes, isLoading } = useQuery({
    ...queries.channels.list_themes(),
    select: makeThemeOptions,
  });

  const currentThemeOption = themes ? find(themes, (theme) => theme.value === value) : null;

  return (
    <Autocomplete
      size="small"
      id="color-select"
      multiple={false}
      options={themes || []}
      loading={isLoading}
      onChange={(_event, newValue) => {
        onChange(newValue?.value || null);
      }}
      getOptionLabel={(option) => option?.label ?? ''}
      value={currentThemeOption}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Theme"
            sx={{
              backgroundColor: currentThemeOption?.color,
              borderRadius: 1,
            }}
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <ChannelListItem {...props} key={option?.value}>
            <ChannelListOption backgroundColor={option?.color}>
              <Typography variant="body1" component="span">
                {option?.label}
              </Typography>
            </ChannelListOption>
          </ChannelListItem>
        );
      }}
      {...rest}
    />
  );
}

const ChannelColorSelectField = () => {
  return (
    <FormFieldGroup>
      <span data-testid="channel-field">
        <Field label="Theme" name="theme_id" multiple={false} component={ChannelThemePicker} />
      </span>
      <InfoText top={4} bottom={4} content="Select a theme for your Channel" />
    </FormFieldGroup>
  );
};

export default ChannelColorSelectField;
