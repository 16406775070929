import PropTypes from 'prop-types';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import OutsideLearningHoursBadge from '~/app/event-shared/components/OutsideLearningHoursBadge';
import { DEFAULT_EVENT_COVER } from '~/app/event-shared/constants';
import { mapRoute } from '~/services/requests';
import LinkHover from '~/app/shared/components/LinkHover';
import Pill from '~/app/shared/components/Pill';
import { useToggles } from '~/app/shared/hooks';
import { Box } from '@mui/material';

const Container = styled.div`
  position: relative;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  flex-shrink: 0;
  max-height: ${(props) => props.width / 1.777}px;
  height: 100%;
`;

const PillWrapper = styled.div`
  margin: 5px;
`;

const StyledTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
`;

const Image = styled.div`
  background: url('${(props) => props.imageUrl}') no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  ${({ curvedBorders }) =>
    curvedBorders &&
    `
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
   `}
`;

const EventCover = ({
  eventPublicIdAndSlug,
  eventCover,
  eventType,
  linkDetailsInCover,
  curvedBorders,
  showOutsideHoursBadge,
}) => {
  const imageSource = eventCover || DEFAULT_EVENT_COVER;
  const { ref, width } = useResizeDetector();

  let cover = <Image imageUrl={imageSource} alt="Event Cover" curvedBorders={curvedBorders} />;
  if (eventPublicIdAndSlug && linkDetailsInCover) {
    cover = (
      <LinkHover to={mapRoute('eventDetails', { public_id_and_slug: eventPublicIdAndSlug })}>
        {cover}
        {showOutsideHoursBadge && (
          <Box position="absolute" top="12px" left="16px">
            <OutsideLearningHoursBadge marginLeft={false} />
          </Box>
        )}
      </LinkHover>
    );
  }

  const { toggle_ui_cover_shows_event_type: toggleEventType } = useToggles();

  return (
    <Container ref={ref} width={width}>
      {cover}
      <StyledTagsContainer>
        {eventType && toggleEventType && (
          <Link
            to={mapRoute('eventTypeDetails', { public_id_and_slug: eventType.public_id_and_slug })}
          >
            <PillWrapper>
              <Pill variant="darkGray" size="small" label={eventType.name} labelMaxWidth={100} />
            </PillWrapper>
          </Link>
        )}
      </StyledTagsContainer>
    </Container>
  );
};

EventCover.propTypes = {
  eventPublicIdAndSlug: PropTypes.string,
  eventCover: PropTypes.string,
  eventType: PropTypes.object,
  linkDetailsInCover: PropTypes.bool,
  curvedBorders: PropTypes.bool,
  showOutsideHoursBadge: PropTypes.bool,
};

EventCover.defaultProps = {
  linkDetailsInCover: false,
};

EventCover.Image = Image;

export default EventCover;
