import styled from 'styled-components';

import MediaPoint from '~/app/shared/components/MediaPoint';

const groupCardSize = '312px';
const groupCardMargin = '12px';
const groupCardMinHeight = '104px';
const groupCardBorderRadius = '8px';

export const BaseGroupCard = styled.div`
  width: ${groupCardSize};
  margin-top: ${groupCardMargin};
  margin-bottom: ${groupCardMargin};
  min-height: ${groupCardMinHeight};
  border-radius: ${groupCardBorderRadius};

  ${MediaPoint.MobileLg} {
    margin: ${groupCardMargin};
  }
`;

export const GroupCardSizeWithMargins = `calc(${groupCardSize} + ${groupCardMargin} * 2)`;
