import { get } from 'lodash';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { ContentItemCardRoot } from '~/common/components/ContentItemCard/ContentItemCardRoot';
import { ContentItemCardCover } from '~/common/components/ContentItemCard/ContentItemCardCover';
import { ContentItemCardLearningType } from '~/common/components/ContentItemCard/ContentItemCardLearningType';
import Icon from '~/app/shared/components/Icon';
import { Divider, Typography } from '@mui/material';
import { ContentItemCardBody } from '~/common/components/ContentItemCard/ContentItemCardBody';
import { ContentItemCardHeader } from '~/common/components/ContentItemCard/ContentItemCardHeader';
import { ContentItemCardOwners } from '~/common/components/ContentItemCard/ContentItemCardOwners';
import { useChannelToggle } from '~/app/shared/hooks';
import { ContentItemCardDescription } from '~/common/components/ContentItemCard/ContentItemCardDescription';
import { ContentItemCardTags } from '~/common/components/ContentItemCard/ContentItemCardTags';
import { useContentItemCardData } from '~/common/hooks/useContentItemCardData';
import { useLearningTypeCardVariant } from '~/common/hooks/useLearningTypeCardVariant';
import { ContentItemCardFooterArea } from '~/common/components/ContentItemCard/ContentItemCardFooterArea';
import { ContentItemCardRating } from '~/common/components/ContentItemCard/ContentItemCardRating';

type LearningTypeAreaProps = {
  learningType: string;
};

function LearningTypeArea(props: LearningTypeAreaProps) {
  const { learningType } = props;

  const {
    colors: learningTypeColors,
    icon: learningTypeIcon,
    label: learningTypeLabel,
  } = useLearningTypeCardVariant(learningType);

  return (
    <ContentItemCardLearningType
      icon={
        <Icon name={learningTypeIcon} width={14} height={14} color={learningTypeColors.color700} />
      }
      sx={{
        backgroundColor: learningTypeColors.color200,
      }}
    >
      <Typography variant="body2" component="span" noWrap color={learningTypeColors.color700}>
        {learningTypeLabel}
      </Typography>
    </ContentItemCardLearningType>
  );
}

type DefaultContentItemCardProps = {
  contentItem: ContentItem;
};

export function DefaultContentItemCard(props: DefaultContentItemCardProps) {
  const { contentItem } = props;

  const { userAssignment, learningType, detailsRouteUrl } = useContentItemCardData(contentItem);
  const toggleChannels = useChannelToggle();

  const assignmentsCount = contentItem.total_assignments ?? contentItem.assignments_count ?? 0;

  const rating =
    contentItem.avg_feedback_rating ??
    contentItem.average_feedback_rating ??
    contentItem.feedback_rating;
  const formattedRating = rating ? Math.round(rating * 10) / 10 : null;

  return (
    <ContentItemCardRoot>
      <ContentItemCardCover
        contentItem={contentItem}
        userAssignment={userAssignment}
        route={detailsRouteUrl}
      />
      <LearningTypeArea learningType={learningType} />

      <ContentItemCardBody>
        <ContentItemCardHeader
          title={contentItem.name}
          route={detailsRouteUrl}
          channel={toggleChannels ? contentItem.channel : undefined}
        />
        <ContentItemCardOwners
          facilitators={get(contentItem, 'facilitators', [])}
          learningType={learningType}
        />
        <ContentItemCardDescription description={contentItem.content_body} />
        <ContentItemCardTags contentItem={contentItem} />
      </ContentItemCardBody>

      <Divider />
      <ContentItemCardFooterArea>
        <Typography variant="body2" component="span">
          {assignmentsCount} engaged
        </Typography>
        {formattedRating && <ContentItemCardRating rating={formattedRating} />}
      </ContentItemCardFooterArea>
    </ContentItemCardRoot>
  );
}
