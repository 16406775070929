import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import RatingField from '~/app/inputs/components/RatingField';

const RatingArea = styled.div`
  font-size: 32px;
`;

const SurveyQuestionRatingScale = ({ input }) => (
  <RatingArea>
    <RatingField input={input} />
  </RatingArea>
);

SurveyQuestionRatingScale.propTypes = {
  input: PropTypes.object,
};

export default SurveyQuestionRatingScale;
