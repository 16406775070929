import React from 'react';

import BaseContainer from '~/app/navigation/components/BaseContainer';
import PageTitle from '~/app/shared/components/PageTitle';

import MySessions from './MySessions';
import SessionDetailRouteListener from './SessionDetailRouteListener';

const MySessionsContainer = () => (
  <React.Fragment>
    <PageTitle title="My Sessions" />
    <BaseContainer>
      <MySessions />
    </BaseContainer>
    <SessionDetailRouteListener />
  </React.Fragment>
);

export default MySessionsContainer;
