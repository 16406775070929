import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import { UserAvatar } from '~/app/shared';
import Text from '~/app/shared/components/Text';
import { UserPopover } from '~/app/shared/components/UserAvatar';

const UserSection = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }
`;

const UserTextSection = styled.div`
  > * + * {
    margin-top: 2px;
  }
`;

const UserHighlight = ({ user, nameColor }) => {
  return (
    <UserPopover
      showPopover
      sendEmailCTA
      showEmail
      user={user}
      renderPopoverHoverable={
        <UserSection>
          <UserAvatar size="medium" user={user} />
          <UserTextSection>
            <Text size="h4" block color={nameColor}>
              {user.name}
            </Text>
            <Text size="h6" block color={colors.neutral500}>
              {user.title}
            </Text>
          </UserTextSection>
        </UserSection>
      }
    />
  );
};

UserHighlight.propTypes = {
  user: PropTypes.object,
  nameColor: PropTypes.string,
};

UserHighlight.defaultProps = {
  nameColor: colors.neutral900,
};

export default UserHighlight;
