import { ArrowBack, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  IconButton,
  styled,
  Typography,
  Divider,
  Drawer,
  drawerClasses,
  List,
  ListItem,
  Card,
  CardActionArea,
  CardHeader,
  Tooltip,
  cardActionAreaClasses,
  Avatar,
  Button,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { includes, isEmpty, isNil, map, some } from 'lodash-es';
import * as React from 'react';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { useCurrentUser, useIsPreviewQueryParam } from '~/app/shared/hooks';
import TrackItemCard from '~/app/tracks/components/TrackItemCard';
import { TrackSectionCircularProgress } from '~/app/tracks/components/TrackSectionProgress';
import { getTrackItemsWithHumanizedDate, getTrackProgress } from '~/app/tracks/services';
import {
  isTrackAssessment,
  getShouldDisplayAssessmentFeedback,
} from '~/features/assessments/utils/helpers';
import colors from '~/services/colors';
import { CONTENT_SIDEBAR_MIN_WIDTH, CONTENT_SIDEBAR_MAX_WIDTH } from '~/app/shared/ui-constants';
import { LinearProgressWithLabel } from '~/common/components/LinearProgressWithLabel';
import { useComposableTrackCtx } from '../contexts/ComposableTrackCtx';
import { useTrackContentConsumptionCtx } from '../contexts/TrackContentConsumptionCtx';
import { useTrackContentNavigationCtx } from '../contexts/TrackContentNavigationCtx';
import { useTrackSidebarCtx } from '../contexts/TrackSidebarCtx';
import { useTrackStepLabels } from '../hooks/useTrackStepLabels';
import { TrackSectionWithItems, Track, TrackItem } from '../types';

function TrackSidebarProgress() {
  const { currentTrackNode } = useTrackContentConsumptionCtx();
  const { current: currentNavigation } = useTrackContentNavigationCtx();

  const stepLabels = useTrackStepLabels();

  const assignmentProgress = getTrackProgress(currentTrackNode.track);
  const hasProgress = !isNil(assignmentProgress);

  return (
    // @ts-expect-error For some reason, MUI is complaining
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      backgroundColor={colors.neutral50}
      padding="4px 12px"
    >
      <Typography variant="caption" color={colors.neutral500}>
        {stepLabels.singular} {currentNavigation.page} of {currentNavigation.totalPages}
      </Typography>

      {hasProgress && (
        <Box flex="1">
          <LinearProgressWithLabel
            /*
              We pass a key prop so that the progress bar is fully re mounted when the
              current track changes in order to avoid animating between different tracks
              progress
            */
            key={currentTrackNode.track.public_id}
            value={assignmentProgress}
          />
        </Box>
      )}
    </Box>
  );
}

type TrackSidebarParentTrackProps = {
  track: Track;
  trackIndex: number;
};

function TrackSidebarParentTrack(props: TrackSidebarParentTrackProps) {
  const { track, trackIndex } = props;

  const { onGoToPageIndex } = useTrackContentNavigationCtx();

  const handleGoBack = () => {
    onGoToPageIndex(trackIndex);
  };

  return (
    <>
      <Box bgcolor={grey[100]} padding={1}>
        <Button
          variant="text"
          size="small"
          startIcon={<ArrowBack fontSize="small" />}
          onClick={handleGoBack}
        >
          Back
        </Button>
      </Box>

      <Divider />

      <Box
        padding="10px 12px"
        bgcolor={colors.neutral0}
        display="flex"
        alignItems="center"
        gap={1.5}
      >
        {track.cover ? (
          <Avatar
            alt={track.name}
            src={track.cover}
            variant="rounded"
            sx={{ height: 40, width: 58 }}
          />
        ) : null}

        <Typography variant="subtitle2">{track.name}</Typography>
      </Box>
    </>
  );
}

type TrackListItemProps = {
  item: TrackItem;
};

function TrackListItem(props: TrackListItemProps) {
  const { item } = props;

  const isPreviewMode = useIsPreviewQueryParam();

  const { getSectionOrItemIndex, onRefreshRootTrack } = useComposableTrackCtx();
  const { currentTrackNode } = useTrackContentConsumptionCtx();
  const {
    global: { page },
    onGoToPageIndex,
  } = useTrackContentNavigationCtx();

  const index = getSectionOrItemIndex(item.track_id!, item.content_item.public_id);
  const isCheckListItem = !includes(
    [CONTENT_TYPES.question, CONTENT_TYPES.text_question, CONTENT_TYPES.multiple_choice_question],
    item.content_item.content_type
  );

  const shouldDisplayAssignmentState = React.useMemo(() => {
    const track = currentTrackNode.track;

    if (!isTrackAssessment(track)) {
      return true;
    }

    return getShouldDisplayAssessmentFeedback(track);
  }, [currentTrackNode]);

  const handleItemClick = () => {
    onGoToPageIndex(index);
  };

  return (
    <ListItem
      key={item.content_item.public_id}
      sx={{
        paddingX: 1.5,
      }}
    >
      <TrackItemCard
        item={item}
        itemIndex={index}
        selected={page - 1 === index}
        onClick={handleItemClick}
        isCheckListItem={isCheckListItem}
        showAssignmentState={shouldDisplayAssignmentState}
        refreshContent={onRefreshRootTrack}
        isPreviewMode={isPreviewMode}
      />
    </ListItem>
  );
}

/**
 * Returns true if the current page is displaying an item from the section
 */
function useIsSectionItemSelected(section: TrackSectionWithItems) {
  const { getSectionOrItemIndex } = useComposableTrackCtx();

  const {
    global: { page },
  } = useTrackContentNavigationCtx();
  const currentPageIndex = page - 1;

  const isAnyItemFromSectionSelected = some(
    section.items,
    (item) =>
      currentPageIndex === getSectionOrItemIndex(item.track_id!, item.content_item.public_id)
  );

  return isAnyItemFromSectionSelected;
}

type TrackSidebarSectionProps = {
  section: TrackSectionWithItems;
  children: React.ReactNode;
};

function TrackSidebarSection(props: TrackSidebarSectionProps) {
  const { section, children } = props;

  const { getSectionOrItemIndex } = useComposableTrackCtx();
  const { currentTrackNode } = useTrackContentConsumptionCtx();
  const {
    global: { page },
    onGoToPageIndex,
  } = useTrackContentNavigationCtx();

  const sectionPageIndex = getSectionOrItemIndex(section.track_id!, section.id);
  const currentPageIndex = page - 1;
  const isSectionSelected = currentPageIndex === sectionPageIndex;
  const isSectionItemSelected = useIsSectionItemSelected(section);
  const isCurrentSection = isSectionSelected || isSectionItemSelected;

  const [isSectionExpanded, setIsSectionExpanded] = React.useState(isCurrentSection);
  const isCurrentSectionOrExpanded = isCurrentSection || isSectionExpanded;

  React.useEffect(() => {
    if (isCurrentSection) {
      setIsSectionExpanded(true);
    }
  }, [isCurrentSection]);

  const isUserAssignedToTrack = !isNil(currentTrackNode.track.user_assignment);

  const handleToggleSectionOpen = () => {
    setIsSectionExpanded((prev) => !prev);
  };

  const handleSectionClick = () => {
    if (isSectionExpanded) {
      setIsSectionExpanded(false);
    } else {
      onGoToPageIndex(sectionPageIndex);
      setIsSectionExpanded(true);
    }
  };

  return (
    <Box width="100%" bgcolor={isCurrentSectionOrExpanded ? colors.neutral0 : grey[100]}>
      <Card square elevation={0}>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          gap={0.5}
          padding={0.5}
          paddingRight="12px"
          bgcolor={isCurrentSectionOrExpanded ? colors.neutral0 : grey[100]}
          color={isCurrentSectionOrExpanded ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.6)'}
        >
          <IconButton size="small" onClick={handleToggleSectionOpen}>
            {isSectionExpanded ? (
              <KeyboardArrowDown fontSize="small" />
            ) : (
              <KeyboardArrowRight fontSize="small" />
            )}
          </IconButton>

          <CardActionArea
            disableRipple
            onClick={handleSectionClick}
            sx={{
              flex: 1,
              width: 'auto',
              [`&:hover .${cardActionAreaClasses.focusHighlight}`]: {
                opacity: '0 !important',
              },
            }}
          >
            <CardHeader
              title={
                <Box display="flex" alignContent="center" gap={0.5}>
                  <Tooltip title={section.name} disableHoverListener={!section.name} arrow>
                    <span>
                      <Typography noWrap width="150px" variant="subtitle2">
                        {section.name}
                      </Typography>
                    </span>
                  </Tooltip>
                </Box>
              }
              sx={{ padding: 0 }}
            />
          </CardActionArea>

          {isUserAssignedToTrack && (
            <TrackSectionCircularProgress
              section={section}
              userIsAssignedToTrack={isUserAssignedToTrack}
            />
          )}
        </Box>
      </Card>

      {isSectionExpanded && <>{children}</>}
    </Box>
  );
}

const ResizeHandle = styled(Box)`
  width: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0px;
  display: flex;
  justify-content: center;
`;

const ResizeHandleLine = styled(Box)`
  width: 4px;
  background-color: ${colors.neutral200};
  opacity: 0;
  height: 100%;
  position: absolute;
  right: 0;
  cursor: col-resize;
  transition: opacity 100ms;
  &:hover {
    opacity: 1;
  }
`;

const Sidebar = styled(Drawer)({
  backgroundColor: grey[100],
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    position: 'absolute',
    boxSizing: 'border-box',
  },
});

export function TrackContentSidebar() {
  const currentUser = useCurrentUser();

  const { filterTrackItems, filterTrackSections } = useComposableTrackCtx();
  const { currentTrackNode, isInNestedTrack } = useTrackContentConsumptionCtx();
  const { isSidebarOpen, sidebarWidth, onSidebarResize } = useTrackSidebarCtx();

  const isResizing = React.useRef(false);

  const handleResize = (e: MouseEvent) => {
    if (!isResizing.current) {
      return;
    }

    onSidebarResize((prevWidth) => {
      let newWidth = prevWidth + e.movementX;
      newWidth = Math.min(newWidth, CONTENT_SIDEBAR_MAX_WIDTH);
      newWidth = Math.max(newWidth, CONTENT_SIDEBAR_MIN_WIDTH);

      return newWidth;
    });
  };

  const handleStopResizing = () => {
    isResizing.current = false;
  };

  React.useEffect(() => {
    document.addEventListener('mousemove', handleResize);
    document.addEventListener('mouseup', handleStopResizing);

    return () => {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleStopResizing);
    };
  }, []);

  const currentSectionsWithItems = filterTrackSections(currentTrackNode.track.public_id);
  const currentTrackItems = filterTrackItems(currentTrackNode.track.public_id);
  const trackItems = getTrackItemsWithHumanizedDate(currentTrackItems, currentUser);

  const hasSections = !isEmpty(currentSectionsWithItems);

  return (
    <Sidebar
      variant="persistent"
      anchor="left"
      open={isSidebarOpen}
      sx={{
        width: sidebarWidth,
        [`& .${drawerClasses.paper}`]: {
          width: sidebarWidth,
          backgroundColor: hasSections ? grey[100] : colors.neutral0,
        },
      }}
    >
      <Box bgcolor={colors.neutral0}>
        {isInNestedTrack && (
          <>
            <TrackSidebarParentTrack
              track={currentTrackNode.track}
              trackIndex={currentTrackNode.index}
            />

            <Divider />
          </>
        )}

        <TrackSidebarProgress />

        <Divider />
      </Box>

      {hasSections ? (
        <>
          {map(currentSectionsWithItems, (section, sectionIdx) => (
            <React.Fragment key={section.id}>
              {sectionIdx > 0 && <Divider />}

              <TrackSidebarSection section={section}>
                <List dense>
                  {map(section.items, (item) => (
                    <TrackListItem key={item.content_item.public_id} item={item} />
                  ))}
                </List>
              </TrackSidebarSection>
            </React.Fragment>
          ))}

          <Divider />
        </>
      ) : (
        <List dense>
          {map(trackItems, (item) => (
            <TrackListItem key={item.content_item.public_id} item={item} />
          ))}
        </List>
      )}

      <ResizeHandle
        onMouseDown={() => {
          isResizing.current = true;
        }}
      >
        <ResizeHandleLine />
      </ResizeHandle>
    </Sidebar>
  );
}
