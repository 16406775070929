import { Box } from '@mui/material';
import queryString from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { rqlToInput } from '~/app/backoffice/components/Dashboard/Filters/utils';
import NewLearningButton from '~/app/catalog/components/NewLearningButton';
import ViewAllContent from '~/app/catalog/components/ViewAllContent';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import { useStickyFilters, useCatalogFetchData } from '~/app/catalog/hooks';
import CodelabFormModal from '~/app/codelab/components/CodelabImportFormModal/CodelabImportFormModal';
import { SettingConfiguredWidgetList } from '~/common/components/SettingConfiguredWidgetList';
import CardListWrapper from '~/app/content-items/components/CardListWrapper';
import CourseFormModal from '~/app/course/components/CourseImportFormModal/CourseImportFormModal';
import { translatorFactory } from '~/app/feature-parity/utils';
import {
  useCatalogPillsDefinition,
  useCatalogFiltersDefinition,
} from '~/features/contentitems/hooks';
import RQLFilterBar from '~/app/filters/components/RQLFilterBar';
import { useRQLFilters } from '~/app/filters/hooks';
import { excludeFieldFromRQLExpression } from '~/app/filters/utils';
import NewLinkedContentModal from '~/app/linkedcontent/components/NewLinkedContentModal';
import FilterPills from '~/app/navigation/components/FilterPills';
import colors from '~/services/colors';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import CardList from '~/app/shared-cards/components/CardList';
import { OptimalContentItemCard } from '~/app/shared/components/Card';
import Loading from '~/app/shared/components/Loading';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import Text from '~/app/shared/components/Text';
import {
  useCurrentUser,
  useLabels,
  useToggles,
  useOrderingLabel,
  useChannelToggle,
} from '~/app/shared/hooks';
import { filter, get, has, includes, isEmpty, map, noop, reduce, size, some } from 'lodash-es';
import { styled } from '@mui/material';
import { ContentItem } from '~/app/shared-content-item/interfaces';

const NoLearningFoundMessage = styled('div')`
  margin: 110px 0;
  text-align: center;
  font-size: 25px;
`;

const Catalog = () => {
  const { trackActivity } = useMetrics();
  const location = useLocation();
  const currentUser = useCurrentUser();
  const search = useRef(isEmpty(location.search) ? '' : location.search.slice(1)); // remove the '?' symbol
  const defaultTabsQueryStrings = useRef(
    map(
      filter(
        get(currentUser, 'tabs_configuration'),
        (tab) => get(tab, 'module') === 'unified_catalog'
      ),
      'querystring'
    )
  );
  const { label_catalog: labelCatalog } = useLabels();
  const { catalog_sections_order: catalogSectionsOrder } = currentUser;
  const { toggle_mentorship_programs: toggleMentorshipProgram } = useToggles();
  const [isStickyFiltersLoaded, setIsStickyFiltersLoaded] = useState(() => {
    // Load the sticky filters only if the user is accessing the page with an empty query string or
    // one of the custom tab configurations which has a query string defined.
    return !isEmpty(search.current) && !includes(defaultTabsQueryStrings.current, search.current); // set as true if the user is accessing the page with a search defined
  });

  // Filters
  const translator = translatorFactory({
    translationMap: {
      type: 'learning_types',
      q: 'q',
      category: 'main_topics',
      facilitator: 'owners',
      tag: 'topics',
    },
    defaultOrdering: 'relevance',
    orderingOptions: {
      relevance: 'relevance',
      upcoming: 'created',
      newest: '-created',
      '-feedback_rating': '-avg_feedback_rating',
      name: 'name',
    },
  });
  const {
    filters,
    ordering,
    updateFilter,
    removeValue,
    setOrdering,
    resetFilters,
    rqlExpression,
    processExpression,
  } = useRQLFilters({
    initialFiltersState: { status: { $eq: 'published' } },
    initialOrdering: 'relevance',
    translator,
  });
  const selectedLearningTypes = rqlToInput(get(filters, 'type'));

  // Sections order
  const orderedLearningTypes = filter(catalogSectionsOrder, (type) => {
    if (!toggleMentorshipProgram && type === LEARNING_TYPES.mentorship_programs) return false;
    return isEmpty(selectedLearningTypes) || includes(selectedLearningTypes, type);
  });

  // Data fetching
  // removing the type filter to remap it to content type
  const searchRQLExpression = excludeFieldFromRQLExpression(rqlExpression, 'type');
  const catalogFetchData = useCatalogFetchData({
    selectedLearningTypes,
    rqlExpression: searchRQLExpression,
  });

  // Page configuration
  const selectedLearningType = size(selectedLearningTypes) === 1 ? selectedLearningTypes[0] : null;
  const isFixed = get(rqlToInput(get(filters, 'fixed')), '0') === 'true';
  const filterPageName = 'catalog_page';

  const [showCodelabFormModal, setShowCodelabFormModal] = useState(false);
  const [showCourseFormModal, setShowCourseFormModal] = useState(false);
  const [showLinkedContentFormModal, setShowLinkedContentFormModal] = useState(false);

  const isLoadingLearnings = some(
    orderedLearningTypes,
    () => get(catalogFetchData, 'isLoading', false) && size(get(catalogFetchData, 'data', [])) === 0
  );
  const hasLearningFound =
    reduce(orderedLearningTypes, (acc: number) => acc + get(catalogFetchData, 'count', 0), 0) > 0;
  const noLearningFoundMessage = 'No learning found for the selected filters.';

  // Sticky filters
  const { getStickyFilters, updateStickyFilters } = useStickyFilters();
  const stickyFilters = getStickyFilters(filterPageName);
  const stickyRqlExpression = has(stickyFilters, 'rqlExpression')
    ? get(stickyFilters, 'rqlExpression', '')
    : stickyFilters // check if the sticky filters are in the old format
      ? translator(queryString.stringify(stickyFilters))
      : '';

  const includeFilterChannels = useChannelToggle();

  // Filters definition
  const { filters: barFilters, moreFilters } = useCatalogFiltersDefinition({
    filters,
    ordering,
    updateFilter,
    setOrdering,
    fixedFilters: [],
    includeFilterChannels,
  });
  // Pills definition
  const { pills } = useCatalogPillsDefinition({ filters });

  // Creating reference to mutable values to avoid re-renders
  const cachedTrackActivity = useRef(trackActivity);
  const cachedSelectedLearningTypes = useRef(selectedLearningTypes);
  const cachedOrdering = useRef(ordering);
  const cachedUpdateStickyFilters = useRef(updateStickyFilters);

  useEffect(() => {
    cachedTrackActivity.current = trackActivity;
    cachedSelectedLearningTypes.current = selectedLearningTypes;
    cachedOrdering.current = ordering;
    cachedUpdateStickyFilters.current = updateStickyFilters;
  });

  // Track activity
  useEffect(() => {
    if (rqlExpression) {
      cachedTrackActivity.current(METRICS_ACTIVITIES.CATALOG_VIEW, {
        learningTypes: cachedSelectedLearningTypes.current,
        filters: rqlExpression,
        sortBy: cachedOrdering.current,
      });
    }
  }, [rqlExpression]);

  // Load sticky filters
  useEffect(() => {
    if (
      !isStickyFiltersLoaded &&
      !isEmpty(stickyRqlExpression) &&
      rqlExpression &&
      isEmpty(search.current)
    ) {
      // Ensure it loads once and the initial URL was processed
      setIsStickyFiltersLoaded(true);
      processExpression(stickyRqlExpression);
    }
    if (isEmpty(stickyRqlExpression)) {
      setIsStickyFiltersLoaded(true);
    }
  }, [stickyRqlExpression, isStickyFiltersLoaded, processExpression, rqlExpression]);

  useEffect(() => {
    if (rqlExpression && isStickyFiltersLoaded) {
      cachedUpdateStickyFilters.current(filterPageName, { rqlExpression });
    }
  }, [rqlExpression, isStickyFiltersLoaded, filterPageName]);

  // Do not show the widget list if the user is filtering the catalog list
  const showWidgetList = rqlExpression === `eq(status,published)&ordering(${ordering})`;
  const orderingLabel = useOrderingLabel(ordering);

  return (
    <>
      <PageTitle title={labelCatalog} />
      <CardListWrapper alignSelf="center">
        {!isFixed && (
          <Text size="h1" color={colors.neutral900} medium>
            {labelCatalog}
          </Text>
        )}

        <RQLFilterBar filters={barFilters} moreFilters={moreFilters} onClearAll={resetFilters}>
          <Box height="100%">
            <NewLearningButton
              learningType={isFixed ? selectedLearningType : null}
              onCodelabClickCallback={() => setShowCodelabFormModal(true)}
              onCourseClickCallback={() => setShowCourseFormModal(true)}
              onLinkedContentClickCallback={() => setShowLinkedContentFormModal(true)}
            />
          </Box>
        </RQLFilterBar>
        <FilterPills
          pills={pills}
          onRemove={(item) => removeValue(get(item, 'filterName', ''), get(item, 'value', ''))}
        />
        {showWidgetList && (
          <SettingConfiguredWidgetList orderSetting="widgets_order.widget_order_catalog" />
        )}
        {isLoadingLearnings && <Loading />}

        {!isLoadingLearnings && !hasLearningFound && (
          <NoLearningFoundMessage>{noLearningFoundMessage}</NoLearningFoundMessage>
        )}

        <ViewAllContent
          fetchStatus={get(catalogFetchData, 'status', '')}
          fetchNextPage={get(catalogFetchData, 'fetchMore', noop)}
          hasNextPage={get(catalogFetchData, 'hasNextPage', false)}
          totalResults={get(catalogFetchData, 'count', 0)}
          tooltipMessage={orderingLabel}
        >
          <CardList
            items={get(catalogFetchData, 'data', []) as ContentItem[]}
            renderItem={(item) => (
              <OptimalContentItemCard key={`${item.content_type}_${item.id}`} contentItem={item} />
            )}
          />
        </ViewAllContent>
      </CardListWrapper>
      {showCodelabFormModal && (
        <CodelabFormModal handleClose={() => setShowCodelabFormModal(false)} />
      )}
      {showCourseFormModal && (
        <CourseFormModal handleClose={() => setShowCourseFormModal(false)} action="New" />
      )}
      {showLinkedContentFormModal && (
        <NewLinkedContentModal handleClose={() => setShowLinkedContentFormModal(false)} />
      )}
    </>
  );
};

export default Catalog;
