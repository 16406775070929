import React from 'react';
import { Field } from 'redux-form';

import CheckboxField from '~/app/inputs/components/CheckboxField';
import TextField from '~/app/inputs/components/TextField';
import { FormFieldGroup } from '~/app/shared/components/Form';
import Form from '~/app/shared/components/OldForm';

const validateRequired = Form.validations.required();

export const DetailsSection = () => {
  return (
    <>
      <FormFieldGroup>
        <Field
          name="is_enabled"
          label="Enable"
          labelSize="h5"
          labelWeight="medium"
          infoText="Use toggle to enable or disable rule."
          infoTextAlignment="left"
          component={CheckboxField}
        />
      </FormFieldGroup>
      <FormFieldGroup>
        <Field
          name="name"
          label="Name"
          required
          component={TextField}
          placeholder="Rule name (e.g. Assign new hires to the NHO track)"
          validate={[validateRequired]}
        />
      </FormFieldGroup>
      <FormFieldGroup>
        <Field
          multiline
          label="Description"
          rows={4}
          component={TextField}
          name="description"
          placeholder="Descriptions help other people understand how a rule works"
        />
      </FormFieldGroup>
    </>
  );
};
