import { useQuery } from '@tanstack/react-query';

import { queries } from '~/queries';
import { entries, filter, isEmpty, map } from 'lodash-es';

import ProfileDataSection from '../ProfileDataSection';

const mapExtractedFields = ({ summary, ...remainingProfileDataLists }): any[] => {
  return [
    ...(summary ? [<p key="summary">{summary}</p>] : []),
    ...map(
      filter(entries(remainingProfileDataLists), ([, value]) => !isEmpty(value)),
      ([key, value]) => <ProfileDataSection sectionTitle={key} data={value} />
    ),
  ];
};

export const useProfileEnrichmentData = (username: string) => {
  const { data, isLoading, isError } = useQuery({
    ...queries.users.profile_enrichment(username),
    retry: false,
  });

  return {
    data: !isEmpty(data || {}) ? mapExtractedFields(data || {}) : [],
    isLoading,
    isError,
  };
};
