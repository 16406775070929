import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  CardMedia,
  Typography,
  Button,
  Menu,
  MenuItem,
  MenuItemProps,
  menuItemClasses,
  styled,
} from '@mui/material';
import { darken, emphasize, alpha } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { matchPath } from 'react-router';
import { useLocation, Link } from 'react-router-dom';

import { queries } from '~/queries';
import { find, get, map } from 'lodash-es';

import { useChannelRoutes } from '../hooks/useChannelRoutes';
import { ChannelLite } from '../types';
import colors from '~/services/colors';

const ChannelMenuItem = styled(MenuItem)<
  MenuItemProps & { component: React.ElementType; to: string }
>`
  border-radius: 8px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  height: 64px;
  border-radius: 8px;

  &:hover,
  &:focus {
    color: unset;
  }
`;

function useGetCurrentChannelSlug() {
  const location = useLocation();

  const match = matchPath(location.pathname, {
    path: '/channels/:slug',
  });

  if (!match) {
    return '';
  }

  const channelSlug = get(match, 'params.slug');

  if (!channelSlug) {
    return '';
  }

  return channelSlug;
}

export function ChannelSelector() {
  const currenChannelSlug = useGetCurrentChannelSlug();
  const { detail: detailRoute } = useChannelRoutes();

  const { data: channelsData } = useQuery({
    ...queries.channels.list('view_mode=lite'),
  });

  const channelOptions = get(channelsData, 'results', []) as ChannelLite[];
  const currentChannel = find(channelOptions, { slug: currenChannelSlug });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentChannelThemeColors = get(currentChannel, 'theme.config.colors');

  return (
    <>
      <Button
        id="channel-selector-button"
        aria-controls={open ? 'channel-selector-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        color="inherit"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        disableRipple
        sx={{
          padding: '8px 11px',
          backgroundColor: currentChannelThemeColors?.soft ?? 'neutral.light',
          color: currentChannelThemeColors?.soft
            ? darken(currentChannelThemeColors?.soft, 0.8)
            : alpha('#000000', 0.6),

          '&:hover': {
            backgroundColor: currentChannelThemeColors?.soft
              ? emphasize(currentChannelThemeColors?.soft, 0.05)
              : undefined,
          },
        }}
      >
        {currentChannel ? currentChannel.name : 'Channels'}
      </Button>

      <Menu
        id="channel-selector-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: 450,
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'channel-selector-button',
          sx: {
            padding: '15px',
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
          },
        }}
      >
        {map(channelOptions, (channel: ChannelLite) => {
          const optionThemeColors = get(channel, 'theme.config.colors');

          return (
            <ChannelMenuItem
              onClick={handleClose}
              disableRipple
              component={Link}
              to={detailRoute({ slug: channel.slug })}
              selected={channel.slug === currenChannelSlug}
              sx={{
                backgroundColor: optionThemeColors?.soft
                  ? `${optionThemeColors.soft} !important`
                  : `${colors.neutral0} !important`,
                '&:hover': {
                  boxShadow: 1,
                  backgroundColor: optionThemeColors?.soft
                    ? `${optionThemeColors.soft} !important`
                    : `${colors.neutral0} !important`,
                },
                [`&.${menuItemClasses.selected}`]: {
                  border: '1px solid',
                  borderColor: optionThemeColors?.soft
                    ? darken(optionThemeColors.soft, 0.2)
                    : '#00000061',
                  backgroundColor: optionThemeColors?.soft
                    ? `${optionThemeColors.soft} !important`
                    : `${colors.neutral0} !important`,
                },
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 72, height: 44, objectFit: 'cover', borderRadius: 1 }}
                image={channel.cover_url}
                alt={channel.name}
              />
              <Typography variant="body1" component="span">
                {channel.name}
              </Typography>
            </ChannelMenuItem>
          );
        })}
      </Menu>
    </>
  );
}
