import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import ChannelByline from '~/app/shared/components/ChannelByline';
import { useChannelToggle } from '~/app/shared/hooks';
import colors from '~/services/colors';
import UserListModalShared from '~/app/shared-modals/components/UserListModalShared';
import MediaPoint from '~/app/shared/components/MediaPoint';
import UserSmallerPillList from '~/app/shared/components/UserSmallerPillList';
import { size } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Box } from '@mui/material';
import { StyledCardAvatar } from '../CardHeader/styleBlocks';

import Title from './Title';

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
`;

const Container = styled.div`
  padding: ${({ mode }) =>
    (mode === 'details' && '16px 16px 0px 0px') ||
    (mode === 'card' && '16px 16px 0px 16px') ||
    '10px 0 0 0'};
  display: flex;
`;

const Presenters = styled.div`
  color: ${colors.emphasis600};
  ${({ mode, empty }) => mode === 'card' && empty && 'height: 29px'};
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ empty }) => !empty && 'margin-bottom: 10px'};
  width: 150px;

  ${MediaPoint.MobileLg} {
    width: 200px;
  }

  ${MediaPoint.Tablet} {
    max-width: 450px;
    width: auto;
  }
`;

const StyledTitle = styled(Title)`
  color: ${colors.neutral900};
  font-weight: 500;
  max-width: 100%;
`;

const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-left: ${({ creators, mode }) =>
    mode === 'details' && creators.length === 0 ? '10px' : 0};
`;

const SimpleCardHeader = ({
  creators,
  contentTitle,
  isPrivate,
  hasGroups,
  route,
  mode,
  multipleCreatorsTitle,
  showBadge,
  showPersonalizedMaxLength,
  onTitleClick,
  emptyCreatorsMessage,
  channel,
}) => {
  const [showCreatorsModal, setShowCreatorsModal] = useState(false);

  const handleCreatorClick = () => {
    if (size(creators) > 1) {
      setShowCreatorsModal(true);
    }
  };

  const handleCreatorModalClose = () => {
    setShowCreatorsModal(false);
  };

  const hasPresenters = size(creators) > 0;
  // compact mode has more width, so we can display more presenters
  const maxPresenters = mode === 'card' ? 2 : 4;
  const presentersLabelMaxLength = mode === 'card' ? 70 : 100;

  const toggleChannels = useChannelToggle();

  return (
    <Container mode={mode}>
      {mode !== 'card' && hasPresenters && (
        <StyledButton onClick={handleCreatorClick} border="none">
          <StyledCardAvatar
            creators={creators}
            badgeTop={70}
            badgeLeft={70}
            badgeSize="small"
            showBadge={showBadge}
            size="l"
          />
        </StyledButton>
      )}
      <HeaderInfo creators={creators} mode={mode}>
        <StyledTitle
          title={contentTitle}
          isPrivate={isPrivate}
          hasGroups={hasGroups}
          hasCreators={hasPresenters}
          route={route}
          mode={mode}
          onClick={onTitleClick}
        />

        {toggleChannels && channel && (
          <Box sx={{ paddingBottom: '5px' }}>
            <ChannelByline channel={channel} />
          </Box>
        )}

        {hasPresenters ? (
          <UserSmallerPillList
            users={creators}
            maxUsers={maxPresenters}
            labelMaxLength={presentersLabelMaxLength}
            showPersonalizedMaxLength={showPersonalizedMaxLength}
            isCard
          />
        ) : emptyCreatorsMessage ? (
          <Box sx={{ marginBottom: 1 }}>
            <Typography>{emptyCreatorsMessage}</Typography>
          </Box>
        ) : (
          <Presenters mode={mode} empty />
        )}
      </HeaderInfo>
      {showCreatorsModal && (
        <UserListModalShared
          modalTitle={`${contentTitle} - ${multipleCreatorsTitle}`}
          users={creators}
          handleClose={handleCreatorModalClose}
        />
      )}
    </Container>
  );
};

SimpleCardHeader.propTypes = {
  contentTitle: PropTypes.string,
  route: PropTypes.string,
  mode: PropTypes.string,
  multipleCreatorsTitle: PropTypes.string,

  channel: PropTypes.object,

  creators: PropTypes.array,

  hasGroups: PropTypes.bool,
  isPrivate: PropTypes.bool,
  showBadge: PropTypes.bool,
  showPersonalizedMaxLength: PropTypes.bool,
  onTitleClick: PropTypes.func,
  emptyCreatorsMessage: PropTypes.string,
};

SimpleCardHeader.defaultProps = {
  showPersonalizedMaxLength: false,
};

export default SimpleCardHeader;
