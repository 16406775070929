import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Loading from '~/app/shared/components/Loading';
import { STATUS_LOADING, STATUS_LOADING_MORE } from '~/app/shared/constants';
import { isEmpty, isNil, map, differenceWith, get } from 'lodash-es';

import ListItem from './ListItem';

const AttendeesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NoAttendees = styled.div`
  margin-top: 50px;
  text-align: center;
`;

const SessionEveryoneList = ({
  session,
  users,
  usersStatus,
  refreshEnrollments,
  isSessionAvailable,
}) => {
  const onUserEnroll = () => {
    refreshEnrollments();
  };

  if (isEmpty(users) && usersStatus === STATUS_LOADING) {
    return <Loading />;
  }

  if (isNil(users) || isEmpty(users)) {
    return <NoAttendees>No one is here.</NoAttendees>;
  }

  const currentAttendees = get(session, 'attendees', []);

  const usersList = differenceWith(users, currentAttendees, (x, y) => x.id === y.id);

  return (
    <AttendeesList>
      <React.Fragment>
        {map(usersList, (user) => (
          <ListItem
            key={user.id}
            session={session}
            user={user}
            onUserEnroll={onUserEnroll}
            isSessionAvailable={isSessionAvailable}
          />
        ))}
        {usersStatus === STATUS_LOADING_MORE && <Loading />}
      </React.Fragment>
    </AttendeesList>
  );
};

SessionEveryoneList.propTypes = {
  session: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object),
  usersStatus: PropTypes.string,
  refreshEnrollments: PropTypes.func,
  isSessionAvailable: PropTypes.bool,
};

export default SessionEveryoneList;
