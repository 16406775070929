import PropTypes from 'prop-types';
import React, { useState } from 'react';

import actions from '~/app/entities/actions';
import { eventTypeSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { STATUS_DONE, STATUS_LOADING, STATUS_LOADING_MORE } from '~/app/shared/constants';
import { map, includes } from 'lodash-es';

import AutocompleteField from '../AutocompleteField';

const EventTypeSelectField = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  const [fetch, { status }] = useEntities(
    actions.eventType.retrieveList,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        setOptions(
          map(data, ({ id, name }) => ({
            value: id,
            label: name,
          }))
        );
      }
    },
    { schema: [eventTypeSchema] }
  );

  const loading = includes([STATUS_LOADING, STATUS_LOADING_MORE], status);

  const fetchOptionsCb = ({ q, includeValues }) => {
    fetch({
      view_mode: 'lite',
      page_size: 50,
      include_ids: includeValues || undefined,
      q: q || undefined,
    });
  };

  return (
    <AutocompleteField
      options={options}
      loading={loading}
      fetchOptions={fetchOptionsCb}
      {...props}
    />
  );
};

EventTypeSelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default EventTypeSelectField;
