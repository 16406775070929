import { CONTENT_ITEM_PERMISSIONS } from '~/app/shared/permissions';

export enum EVENT_VIEW_MODES {
  lite = 'lite',
  detail = 'detail',
  manage_enrollments = 'manage_enrollments',
  enrollment_dashboard = 'enrollment_dashboard',
  listing = 'listing',
  calendar = 'calendar',
  'default' = 'default',
  full = 'full',
}

export const EVENT_PERMISSIONS = {
  ...CONTENT_ITEM_PERMISSIONS,
  enroll_locally: 'enroll_locally',
  waitlist_locally: 'waitlist_locally',
  enroll_online: 'enroll_online',
  waitlist_online: 'waitlist_online',
};
