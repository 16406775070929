import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import actions from '~/app/entities/actions';
import { surveyRelationshipSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import { STATUS_LOADING } from '~/app/shared/constants';
import SurveyModal from '~/app/surveys/components/SurveyModal';
import SurveyModalItem from '~/app/surveys/components/SurveyModal/SurveyModalItem';
import { SurveyRelationshipEntityType } from '~/app/surveys/constants';
import { useSurveyRelationshipTypeLabels } from '~/app/surveys/hooks';
import { isEqual, toLower } from 'lodash-es';

const ContentSurveyModal = ({ content, handleClose }) => {
  const { public_id: contentPublicId, name: contentName, content_type: contentType } = content;
  const surveyRelationshipTypeLabels = useSurveyRelationshipTypeLabels();
  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });

  const [fetchContentSurveys, { data: surveys, status, count: surveysCount }] = useEntities(
    actions.surveyRelationship.retrieveList,
    null,
    {
      schema: [surveyRelationshipSchema],
    }
  );

  useEffect(() => {
    fetchContentSurveys({
      content_item: contentPublicId,
      o: '-survey_cutoff_date',
      view_mode: 'surveys_dialog',
    });
  }, []);

  const isLoading = isEqual(status, STATUS_LOADING);
  let entityType;
  switch (contentType) {
    case CONTENT_TYPES.eventtype: {
      entityType = SurveyRelationshipEntityType.EVENT_TYPE;
      break;
    }
    case CONTENT_TYPES.article: {
      entityType = SurveyRelationshipEntityType.ARTICLE;
      break;
    }
    case CONTENT_TYPES.video: {
      entityType = SurveyRelationshipEntityType.VIDEO;
      break;
    }
    case CONTENT_TYPES.program: {
      entityType = SurveyRelationshipEntityType.PROGRAM;
      break;
    }
    case CONTENT_TYPES.course: {
      entityType = SurveyRelationshipEntityType.COURSE;
      break;
    }
    case CONTENT_TYPES.track: {
      {
        entityType = SurveyRelationshipEntityType.TRACK;
        // No default
      }
      break;
    }
  }

  let entityLabel = 'content';
  if (entityType) {
    entityLabel = toLower(surveyRelationshipTypeLabels[entityType]);
  }

  return (
    <SurveyModal
      entityLabel={entityLabel}
      isLoading={isLoading}
      title={contentName}
      surveys={surveys}
      surveysCount={surveysCount}
      handleClose={handleClose}
      renderItem={(surveyRelationship) => (
        <SurveyModalItem key={surveyRelationship.id} surveyRelationship={surveyRelationship} />
      )}
      renderTopButton={() => (
        <Button
          endIcon={<Icon name="external" />}
          route={`${contentTypeRoutes[contentType].edit({
            // Some routes use public_id and some use public_id_and_slug,
            // we let the function decide which to use by providing both
            public_id_and_slug: content.public_id_and_slug,
            public_id: contentPublicId,
          })}#surveys`}
        >
          Edit Surveys Schedule
        </Button>
      )}
    />
  );
};

ContentSurveyModal.propTypes = {
  content: PropTypes.object,
  handleClose: PropTypes.func,
};

export default ContentSurveyModal;
