import PropTypes from 'prop-types';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';

import { useContentTypeRoutes } from '~/app/catalog/hooks';
import { DEFAULT_EVENT_COVER } from '~/app/event-shared/constants';
import LinkHover from '~/app/shared/components/LinkHover';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => props.width / 1.777}px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const Image = styled.div`
  background: url('${(props) => props.imageUrl}') no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  ${({ curvedBorders }) =>
    curvedBorders &&
    `
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
   `}
`;

const ContentItemInputCover = ({ content, clickable, curvedBorders }) => {
  const contentTypeRoutes = useContentTypeRoutes();
  const imageSource = content.cover || content.default_cover || DEFAULT_EVENT_COVER;
  const { ref, width } = useResizeDetector();
  const LinkComponent = clickable ? LinkHover : React.Fragment;

  const cover = (
    <Image
      imageUrl={imageSource}
      alt={`${content.content_type} cover`}
      curvedBorders={curvedBorders}
    />
  );

  return (
    <Container ref={ref} width={width}>
      <LinkComponent
        {...(clickable && {
          to: contentTypeRoutes[content.content_type].details({
            public_id_and_slug: content.public_id_and_slug,
          }),
        })}
      >
        {cover}
      </LinkComponent>
    </Container>
  );
};

ContentItemInputCover.propTypes = {
  content: PropTypes.object.isRequired,
  clickable: PropTypes.bool,
  curvedBorders: PropTypes.bool,
};

ContentItemInputCover.defaultProps = {
  clickable: false,
  curvedBorders: false,
};

ContentItemInputCover.Image = Image;

export default ContentItemInputCover;
