import React from 'react';

import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';

export interface ConfirmationModalProps {
  open: boolean;

  confirmText?: string;
  confirmButtonColor?: React.ComponentProps<typeof ModalFooterButton>['color'];
  onConfirmClick: () => void;
  hideConfirmationAction?: boolean;

  closeText?: string;
  handleClose: () => void;

  disabled?: boolean;

  deleteModalTitle: string;
}

export const ConfirmationModal = ({
  open,
  children,
  closeText = 'Cancel',
  handleClose,
  confirmText = 'Proceed',
  confirmButtonColor = 'error',
  onConfirmClick,
  hideConfirmationAction = false,
  deleteModalTitle,
  disabled = false,
}: React.PropsWithChildren<ConfirmationModalProps>) => {
  if (!open) return null;

  return (
    <Modal title={deleteModalTitle} handleClose={handleClose} width={500} minBodyHeight={0}>
      <ModalBody>{children}</ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose} color="secondary" variant="text">
          {closeText}
        </ModalFooterButton>
        {!hideConfirmationAction && (
          <ModalFooterButton
            onClick={onConfirmClick}
            color={confirmButtonColor}
            disabled={disabled}
          >
            {confirmText}
          </ModalFooterButton>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
