import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import TextInput from '~/app/inputs/components/TextInput';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR } from '~/app/program/constants';
import { FormFieldGroup } from '~/app/shared/components/Form';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from '~/app/shared/constants';
import { noop } from 'lodash-es';

const ModalContent = styled.div`
  > * + * {
    margin-top: 16px;
  }
`;

const MentorshipRequestModal = ({ handleClose, mentee, onRequestSent, program }) => {
  const [note, setNote] = useState('');

  const [requestMentorship, { status }] = useEntities(
    actions.mentorshipRequest.create,
    ({ status, error }) => {
      if (status === STATUS_DONE) {
        handleClose();
        toast.success(
          'Mentorship request sent!',
          `${mentee.name} will be notified of your request.`
        );
        onRequestSent();
      }

      if (status === STATUS_ERROR) {
        toast.error('Request failed!', error?.detail);
      }
    }
  );

  const handleSendRequest = () => {
    requestMentorship({
      note,
      receiver_id: mentee.id,
      kind: MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR,
      program_id: program?.id,
    });
  };

  return (
    <Modal title={`Offer to mentor ${mentee.name}`} width={500} handleClose={handleClose}>
      <ModalBody>
        <ModalContent>
          <Text size="h4" block>
            If they wish to take you up on this offer, they will be able to book or request a
            session with you. Pro-tip: have sessions available.
          </Text>
          <FormFieldGroup>
            <TextInput
              label="Note"
              multiline
              rows={4}
              placeholder={`Let ${mentee.name} know why you are offering to mentor them.`}
              inputProps={{
                id: 'mentorship_request_note',
                name: 'mentorship_request_note',
                'aria-label': 'Note',
                value: note,
                maxLength: 255,
                onChange: (event) => setNote(event.target.value),
              }}
              helperText={`${note.length}/255 characters`}
            />
          </FormFieldGroup>
        </ModalContent>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose} color="error">
          Cancel
        </ModalFooterButton>
        <ModalFooterButton onClick={handleSendRequest} disabled={status === STATUS_LOADING}>
          Send Offer
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

MentorshipRequestModal.propTypes = {
  handleClose: PropTypes.func,
  mentee: PropTypes.object,
  onRequestSent: PropTypes.func,
  program: PropTypes.object,
};

MentorshipRequestModal.defaultProps = {
  onRequestSent: noop,
};

export default MentorshipRequestModal;
