import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { isEmpty, map } from 'lodash-es';
import * as React from 'react';

import { useCompleteQuestion } from '~/features/assessments/hooks/useCompleteQuestion';
import {
  Assessment,
  MultipleChoiceOption,
  MultipleChoiceQuestion,
} from '~/features/assessments/types';
import {
  getIsFirstQuestionInAssessment,
  getIsLastQuestionInAssessment,
  getShouldDisplayAssessmentFeedback,
} from '~/features/assessments/utils/helpers';
import { useTrackContentConsumptionCtx } from '~/features/tracks/contexts/TrackContentConsumptionCtx';
import { useTrackContentNavigationCtx } from '~/features/tracks/contexts/TrackContentNavigationCtx';
import Button from '~/app/shared/components/Button';
import ContentDescription from '~/app/stand-alone-shared/components/ContentDescription';
import { Typography } from '~/common/components/Typography';
import { Box, FormControl, List, RadioGroup } from '@mui/material';
import {
  MultipleChoiceOptionFeedbackState,
  MultipleChoiceOptionRadioButton,
} from './MultipleChoiceOptionRadioButton';
import { QuestionDetail } from './QuestionDetail';

interface MultipleChoiceQuestionDetailProps {
  question: MultipleChoiceQuestion;
}

export const MultipleChoiceQuestionDetail = (props: MultipleChoiceQuestionDetailProps) => {
  const { question } = props;

  const questionAssignment = question.user_assignment;

  const submittedOption = questionAssignment?.option;
  const isQuestionCompleted = submittedOption != null;

  const { onAdvance, onGoBack, onExitCurrentTrack } = useTrackContentNavigationCtx();
  const { currentTrackNode } = useTrackContentConsumptionCtx();

  const assessment = currentTrackNode.track as Assessment;

  const [selectedOption, setSelectedOption] = React.useState(
    submittedOption ? submittedOption.id : null
  );

  const shouldDisplayAssessmentFeedback = getShouldDisplayAssessmentFeedback(assessment);
  const isFirstQuestionInAssessment = getIsFirstQuestionInAssessment(question.id, assessment);
  const isLastQuestionInAssessment = getIsLastQuestionInAssessment(question.id, assessment);

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(Number.parseInt(e.target.value));
  };

  const { completeQuestion, isLoading } = useCompleteQuestion();

  const handleSubmit = () => {
    if (!selectedOption) {
      return;
    }

    completeQuestion({
      type: 'multipleChoice',
      content_type: question.content_type,
      option_id: selectedOption,
    });
  };

  const handleGetOptionFeedbackState = (
    option: MultipleChoiceOption
  ): MultipleChoiceOptionFeedbackState => {
    if (!isQuestionCompleted) {
      return 'none';
    }

    const isCurrentOptionUserSubmitted = submittedOption.id === option.id;

    if (!shouldDisplayAssessmentFeedback) {
      return isCurrentOptionUserSubmitted ? 'indeterminate' : 'none';
    }

    if (isCurrentOptionUserSubmitted) {
      return submittedOption.is_correct ? 'correct' : 'incorrect';
    }

    return option.is_correct ? 'correct' : 'none';
  };

  const showFeedbackText =
    isQuestionCompleted && shouldDisplayAssessmentFeedback && submittedOption?.feedback_text;
  const disableSubmitButton = isEmpty(questionAssignment) || !selectedOption || isLoading;

  return (
    <QuestionDetail question={question}>
      <FormControl>
        <RadioGroup
          name={question.public_id_and_slug}
          value={selectedOption}
          onChange={handleOptionChange}
        >
          <List>
            {map(question.option_set, (option, index) => {
              const feedbackState = handleGetOptionFeedbackState(option);

              return (
                <MultipleChoiceOptionRadioButton
                  key={option.id}
                  option={option}
                  optionLabel={String.fromCharCode(index + 65)}
                  disabled={isQuestionCompleted}
                  feedbackState={feedbackState}
                />
              );
            })}
          </List>
        </RadioGroup>
      </FormControl>
      {showFeedbackText && (
        <Box padding={2.5}>
          <Typography variant="caption">Feedback</Typography>
          <ContentDescription description={submittedOption.feedback_text} noPadding />
        </Box>
      )}
      <Box display="flex" justifyContent="space-between">
        <Button onClick={onGoBack} disabled={isFirstQuestionInAssessment}>
          Previous
        </Button>

        {!isQuestionCompleted ? (
          <Button
            color="primary"
            variant="contained"
            disabled={disableSubmitButton}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ) : !isLastQuestionInAssessment ? (
          <Button
            color="primary"
            variant="contained"
            endIcon={<ChevronRightIcon />}
            onClick={onAdvance}
          >
            Next
          </Button>
        ) : (
          <Button color="primary" variant="contained" onClick={onExitCurrentTrack}>
            Finish
          </Button>
        )}
      </Box>
    </QuestionDetail>
  );
};
