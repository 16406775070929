import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import EventBulkDuplicateModal from '~/app/event-shared/components/EventBulkDuplicate/EventBulkDuplicateModal';
import EventForm from '~/app/event-shared/components/EventForm';
import { WATCH_LINK_METHOD } from '~/app/event-shared/constants';
import ReviewModal from '~/app/event/components/EventForm/ReviewModal';
import { toast } from '~/app/notifications/components/NotificationCenter';
import confirmAlert from '~/services/confirm-alert';
import { useEntityMetrics, useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import { isGoogleMeetLink } from '~/services/utils';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import { useCurrentUser } from '~/app/shared/hooks';
import { sortCustomTags } from '~/app/topics/services';
import {
  filter,
  find,
  first,
  get,
  has,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  keyBy,
  map,
  omit,
  pick,
  some,
  sortBy,
} from 'lodash-es';

const getOrganizer = (currentUser, initialValues) => {
  // Organizers will be pre-filled with the following priority order:
  // 1. Event type organizer
  // 2. The first co-organizer
  // 3. The first presenter
  // 4. Current user making the request
  if (!isEmpty(initialValues.organizer)) return {};

  const [coOrganizer, ...coOrganizers] = get(initialValues, 'co_organizers', []);
  const [coOrganizerId, ...coOrganizersId] = get(initialValues, 'co_organizers_ids', []);

  if (coOrganizer) {
    return {
      organizer: coOrganizer,
      organizer_id: coOrganizerId,
      co_organizers: coOrganizers,
      co_organizers_ids: coOrganizersId,
    };
  }

  const presenter = first(initialValues.presenters);
  if (presenter) return { organizer: presenter, organizer_id: presenter.id };

  return { organizer: currentUser, organizer_id: currentUser.id };
};

const getInitialValues = ({ clonedEvent, event, eventType, currentUser, defaultChannelId }) => {
  let initialValues = {
    is_local: false,
    is_online: false,
  };

  if (event) {
    initialValues = { ...event, topics: event.tags };
  } else if (clonedEvent) {
    initialValues = {
      ...omit({ ...clonedEvent, name: `Duplicate of ${clonedEvent.name}` }, [
        'id',
        'public_id',
        'timeslots',
        'timezone',
      ]),
      timeslots: map(clonedEvent.timeslots, (timeslot) =>
        omit(timeslot, isGoogleMeetLink(timeslot.watch_link) ? ['id', 'watch_link'] : ['id'])
      ),
    };
  } else if (eventType) {
    initialValues = omit(eventType, 'id', 'public_id');
    initialValues.topics = eventType.tags;

    // Extract organizer, co-organizers and presenters ids from facilitators
    const organizerId = get(find(eventType.facilitators, ['role', 'main_organizer']), 'user.id');
    const presentersIds = map(
      filter(eventType.facilitators, ['role', 'presenter']),
      ({ user }) => user.id
    );
    const coOrganizersIds = map(
      filter(eventType.facilitators, ['role', 'co_organizer']),
      ({ user }) => user.id
    );

    initialValues.organizer_id = organizerId;
    initialValues.presenters_ids = presentersIds;
    initialValues.co_organizers_ids = coOrganizersIds;

    initialValues.event_type_id = eventType.id;
    if (eventType.watch_link_method === WATCH_LINK_METHOD.google) initialValues.watch_link = '';
  }

  // Set default organizer if none is provided
  initialValues = { ...initialValues, ...getOrganizer(currentUser, initialValues) };

  // Pre-selects every week recurrence option
  initialValues.recurrence = 1;

  initialValues.convert_event = null;

  // If initialValues doesn't have a value for is_hidden, set the default as public
  if (!has(initialValues, 'is_hidden')) {
    initialValues.is_hidden = false;
  }

  // Waitlist
  if (initialValues.wait_list_limit || initialValues.online_wait_list_limit) {
    initialValues.has_wait_list = true;
  }

  // External registration
  if (initialValues.external_link_description || initialValues.external_link) {
    initialValues.has_external_registration = true;
  }

  if (!initialValues.channel_id && defaultChannelId) {
    initialValues.channel_id = defaultChannelId;
  }

  const customTagsInitialValues = sortCustomTags(currentUser, initialValues, 'topics');

  initialValues = { ...initialValues, ...customTagsInitialValues };

  return initialValues;
};

const submitWithConfirmationHandler =
  ({ proceedToSubmit, cancelSubmit }) =>
  ({
    initialValues,
    values,
    selectedPresenters,
    selectedOrganizer,
    selectedCoOrganizers,
    selectedLocation,
  }) => {
    let sendICSFile = false;

    if (initialValues.id) {
      // Check if the event has relevant event changes
      const presenters = map(selectedPresenters, (presenter) => presenter.id);
      const coOrganizers = map(selectedCoOrganizers, (organizer) => organizer.id);

      const hasRelevantEventChanges =
        !isEqual(initialValues.name, values.name) ||
        !isEqual(initialValues.content_body, values.content_body) ||
        !isEqual(initialValues.organizer.id, selectedOrganizer.id) ||
        !isEqual(initialValues.location_id, selectedLocation.id) ||
        !isEqual(sortBy(initialValues.presenters_ids), sortBy(presenters)) ||
        !isEqual(sortBy(initialValues.co_organizers_ids), sortBy(coOrganizers));

      const now = moment();

      // FIXME: This is not timezone aware, the validation might not consider that a timeslot that is happening with a few hour difference not in the future
      const hasTimeslotInTheFuture = some(values.timeslots, (timeslot) =>
        moment(timeslot.starts_at).isSameOrAfter(now)
      );

      const initialTimeslotsMap = keyBy(initialValues.timeslots, 'id');

      // starts_at is checked separately because the date field changes the format a little
      const relevantTimeslotFields = [
        'duration',
        'rooms',
        'rooms_info',
        'extra_info',
        'watch_link',
      ];
      const hasTimeslotImpactChanges = some(values.timeslots, (timeslot) => {
        const initialTimeslot = initialTimeslotsMap?.[timeslot.id];
        // If the timeslot was not found in the map, it was removed, therefore it's an important change
        if (!initialTimeslot) {
          return true;
        }
        const initialTimeslotStartsAt = moment(initialTimeslot.starts_at).format();
        const timeslotStartsAt = moment(timeslot.starts_at).format();
        return (
          !isEqual(
            pick(timeslot, relevantTimeslotFields),
            pick(initialTimeslotsMap[timeslot.id], relevantTimeslotFields)
          ) || initialTimeslotStartsAt !== timeslotStartsAt
        );
      });

      sendICSFile = hasTimeslotInTheFuture && (hasRelevantEventChanges || hasTimeslotImpactChanges);

      confirmAlert({
        title: 'Update Review',
        // Disabling eslint rule because this is not a React Component
        // eslint-disable-next-line react/prop-types
        content: ({ state }) => {
          return (
            <ReviewModal
              alertState={state}
              initialValues={initialValues}
              selectedCoOrganizers={selectedCoOrganizers}
              selectedLocation={selectedLocation}
              selectedOrganizer={selectedOrganizer}
              selectedPresenters={selectedPresenters}
              values={values}
            />
          );
        },
        onConfirm: (data) =>
          proceedToSubmit({
            send_notification: data.sendNotification,
            update_note: data.notificationText,
            send_ics_file: data.sendICSFile,
          }),
        onSecondaryAction: () => cancelSubmit(),
        onClose: () => cancelSubmit(),
        confirmLabel: 'Confirm Update',
        isDangerSecondaryAction: true,
        initialAlertState: {
          sendNotification: false,
          notificationText: '',
          sendICSFile,
        },
      });
    } else {
      proceedToSubmit({});
    }
  };

const onSubmitSuccessHandler = (
  result,
  saveAndDuplicate,
  saveAndBulkDuplicate,
  location,
  history,
  isEdit
) => {
  const actionName = isEdit ? 'saved' : 'published';
  const args = queryString.parse(location.search);
  const eventId = isArray(result) ? get(result, '[0].id') : get(result, 'id');
  const eventPublicId = isArray(result) ? get(result, '[0].public_id') : get(result, 'public_id');
  const eventPublicIdAndSlug = isArray(result)
    ? get(result, '[0].public_id_and_slug')
    : get(result, 'public_id_and_slug');
  let url;

  if (saveAndBulkDuplicate) {
    toast.success(`Event ${actionName}`, `Your previous event was ${actionName}!`);
    return;
  }

  if (!isNil(eventId)) {
    url = mapRoute('eventDetails', { public_id_and_slug: eventPublicIdAndSlug });

    if (isArray(result)) {
      const eventTypePublicIdAndSlug = get(result, '[0].event_type.public_id_and_slug');
      url = isNil(eventTypePublicIdAndSlug)
        ? mapRoute('eventsList')
        : mapRoute('eventTypeDetails', { public_id_and_slug: eventTypePublicIdAndSlug });
    }

    if (saveAndDuplicate) {
      url = `${mapRoute('eventNew')}?cloned=${eventPublicId}&from_previous=true`;

      toast.success(
        `Event ${actionName}`,
        `Your previous event was ${actionName}, we also added the current form for creating another one!`
      );
    }
  } else if (get(args, 'origin')) {
    url = args.origin;
  }

  history.push(url);
};

const EventFormPage = ({
  pageTitle,
  topBarActionName,
  backRoute,
  breadcrumbsItemList,
  initialValuesParams,
  initialValuesParams: { event, defaultSettings },
}) => {
  const currentUser = useCurrentUser();
  const { default_channel_id: defaultChannelId } = currentUser;

  const history = useHistory();
  const location = useLocation();
  const { trackActivity } = useMetrics();

  const { trackEntityActivity } = useEntityMetrics();
  const initialValuesState = useState(
    getInitialValues({
      currentUser,
      ...initialValuesParams,
      defaultChannelId,
    })
  );

  const settings = initialValuesState[0]?.settings || defaultSettings;

  const initialValues = { ...initialValuesState[0], settings };

  const [eventToBulkDuplicate, setEventToBulkDuplicate] = useState({});

  const isEdit = Boolean(event);
  const form = `newEvent${isEdit ? 'Edit' : 'Create'}Form`;

  return (
    <>
      <PageTitle title={pageTitle} />
      <EventForm
        form={form}
        settingsContext="event/form"
        currentUser={currentUser}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        setEventToBulkDuplicate={setEventToBulkDuplicate}
        submitWithConfirmationHandler={submitWithConfirmationHandler}
        onSubmitSuccessHandler={(result, saveAndDuplicate, saveAndBulkDuplicate) => {
          trackEntityActivity({
            id: result.id,
            isEdit,
            entityType: 'event',
          });
          onSubmitSuccessHandler(
            result,
            saveAndDuplicate,
            saveAndBulkDuplicate,
            location,
            history,
            isEdit
          );
        }}
      />
      {!isEmpty(eventToBulkDuplicate) && (
        <EventBulkDuplicateModal
          event={eventToBulkDuplicate}
          trackActivity={trackActivity}
          handleClose={() => {
            setEventToBulkDuplicate({});
            history.push(
              mapRoute('eventDetails', {
                public_id_and_slug: eventToBulkDuplicate.public_id_and_slug,
              })
            );
          }}
        />
      )}
    </>
  );
};

EventFormPage.defaultProps = {
  initialValuesParams: {},
};

EventFormPage.propTypes = {
  pageTitle: PropTypes.string,
  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),
  initialValuesParams: PropTypes.object,
};

export default EventFormPage;
