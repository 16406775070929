/* eslint-disable lodash/prefer-lodash-method */
import { closestCenter, DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { rqlHasValue } from '~/app/backoffice/utils';
import { useCurrentSegment } from '~/app/backoffice/hooks';
import { getDashboardMetadata } from '~/app/backoffice/services';
import colors from '~/services/colors';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import propTypes from '~/services/prop-types';
import Breadcrumbs from '~/app/shared/components/Breadcrumbs';
import BreadcrumbProptypes from '~/app/shared/components/Breadcrumbs/types';
import Button from '~/app/shared/components/Button';
import Clicker from '~/app/shared/components/Clicker';
import { OldIcon } from '~/app/shared/components/Icon';
import Loading from '~/app/shared/components/Loading';
import Text from '~/app/shared/components/Text';
import { STATUS_LOADING, STATUS_LOADING_MORE } from '~/app/shared/constants';
import { useLabels } from '~/app/shared/hooks';
import {
  map,
  ceil,
  isEmpty,
  includes,
  unionBy,
  differenceBy,
  find,
  toInteger,
  filter,
  size,
  noop,
} from 'lodash-es';
import { Pagination } from '~/common/components/Pagination';
import { Box } from '@mui/material';
import ActionsBar from './ActionsBar';
import SelectorColumn, { SelectorIndicator, SELECTED_ITEMS_STATUS } from './SelectorColumn';

const EmptyTableContainer = styled.div`
  text-align: center;
  margin-top: 50px;
`;

const TableWrapper = styled.div`
  min-height: 570px;
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const THead = styled.thead`
  width: 100%;
`;

const HRow = styled.tr`
  height: 38px;
  width: 100%;
`;

const HData = styled.th`
  color: ${colors.neutral900};
  padding: 8px 16px;
  width: ${({ size }) => size || 3}%;

  // padding-right includes distance between this content and the one on the right
  ${({ alignRight }) =>
    alignRight &&
    `
    text-align: right;
    padding-right: 40px;

    ${Clicker} {
      float: right;
    };
  `}
`;

const HSelector = styled(HData)`
  width: 16px;
`;

const TBody = styled.tbody``;

const StyledRow = styled.tr`
  margin: 0 0 1px 0;
  background-color: #ffffff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: ${colors.neutral100};
  `}
  &:hover {
    background-color: ${colors.neutral100};
  }
`;

const Row = ({ rowId, children, isSelected, ...rest }) => {
  const { setNodeRef, attributes, listeners, transform, transition } = useSortable({
    id: rowId,
  });

  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <StyledRow
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isSelected={isSelected}
      style={{ transform: CSS.Transform.toString(transform), transition }}
      role="row"
      {...rest}
    >
      {children({
        isHovered,
        dragAndDropSetNodeRef: setNodeRef,
        dragAndDropAttrributesAndListeners: { ...attributes, ...listeners },
      })}
    </StyledRow>
  );
};

Row.propTypes = {
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSelected: PropTypes.bool,
  children: propTypes.anyChildren,
};

const RowSpacer = styled.tr`
  height: 16px;
  background-color: transparent;
`;

const Container = styled.div`
  padding: 0 54px;
`;

const TableTopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DashboardLite = ({
  dashboardName,
  breadcrumbItems,
  idAccessor,
  rows,
  pageSize,
  totalCount,
  requestStatus,
  isLoading: isLoadingProp,
  refreshDashboard,
  children,
  actionBar,
  bulkBar,
  filterBar,
  // filters management
  filterObj,
  updateOrdering,
  updatePagination,
  // for drag'n'drop
  onReorder,
  // for infinite dashboard
  infinityMode,
  showLoadMoreButton,
  loadMoreButtonCallback,
}) => {
  const { trackActivity } = useMetrics();
  const labels = useLabels();
  const { filters, ordering, page } = filterObj;

  // At this point, if there's a segment public id in the URL, it has
  // been already fetched, so we can use this hook to get the data
  // directly from state instead of fetching it again
  const segment = useCurrentSegment();

  const [selectedItems, setSelectedItems] = useState([]);
  const dragAndDropSensors = [useSensor(PointerSensor)];

  useEffect(() => {
    if (segment.public_id) {
      trackActivity(METRICS_ACTIVITIES.DASHBOARD_SEGMENT_VIEW, {
        dashboardName,
        segmentId: segment.public_id,
        segmentExpression: segment.expression,
      });
    }
  }, [segment.public_id]);

  const getSelectedItemsPageStatus = () => {
    const difference = differenceBy(rows, selectedItems, idAccessor);
    if (difference.length === rows.length) return SELECTED_ITEMS_STATUS.none;
    if (difference.length === 0) return SELECTED_ITEMS_STATUS.all;
    return SELECTED_ITEMS_STATUS.partial;
  };

  const onSelectAllClick = () => {
    const status = getSelectedItemsPageStatus();

    if (includes([SELECTED_ITEMS_STATUS.none, SELECTED_ITEMS_STATUS.partial], status)) {
      setSelectedItems((selectedItems) => unionBy(selectedItems, rows, idAccessor));
    } else {
      setSelectedItems((selectedItems) => differenceBy(selectedItems, rows, idAccessor));
    }
  };

  const handlePageChange = (_, page) => {
    window.scrollTo(0, 60);
    updatePagination(page);
  };

  const handleLoadMore = (_, page) => {
    updatePagination(page);
    loadMoreButtonCallback();
  };

  const handleOrderingChange = ({ o: fieldName }) => {
    updateOrdering(fieldName);
  };

  const addToSelectedItems = (item) => {
    setSelectedItems((selectedItems) => unionBy(selectedItems, [item], idAccessor));
  };

  const removeFromSelectedItems = (item) => {
    setSelectedItems((selectedItems) => differenceBy(selectedItems, [item], idAccessor));
  };

  const handleDelete = () => {
    trackActivity(METRICS_ACTIVITIES.DASHBOARD_BULK_ACTION, {
      bulkAction: 'delete',
      itemCount: size(selectedItems),
      dashboardName,
    });
    setSelectedItems([]);
  };

  const getBreadcrumbItems = () => {
    if (breadcrumbItems) return breadcrumbItems;
    if (!isEmpty(segment)) {
      return [
        { label: getDashboardMetadata(segment.content_type, labels).label, link: null },
        { label: segment.name },
      ];
    }
    return [];
  };

  const requestStatusLoading = includes([STATUS_LOADING, STATUS_LOADING_MORE], requestStatus);
  const isLoading = isLoadingProp || requestStatusLoading;

  const isFiltered = size(filter(filters, (item) => rqlHasValue(item))) > 0;

  const dashboardBreadcrumbItems = getBreadcrumbItems();

  const paginationCount = ceil(totalCount / pageSize);
  const paginationDefaultPage = toInteger(page);

  return (
    <Container>
      <div>
        {!isEmpty(dashboardBreadcrumbItems) && (
          <Breadcrumbs items={dashboardBreadcrumbItems} hasMargin />
        )}
        {filterBar}
        <TableTopBar>
          {size(selectedItems) === 0 &&
            actionBar &&
            React.cloneElement(actionBar, {
              totalCount,
              isFiltered,
              isLoading,
              refreshDashboard,
              selectedItems,
            })}
          {bulkBar &&
            React.cloneElement(bulkBar, {
              totalCount,
              selectedItems,
              onClearClick: handleDelete,
              refreshDashboard,
            })}
        </TableTopBar>
      </div>
      <TableWrapper>
        <Table>
          <THead>
            <HRow>
              {!isEmpty(bulkBar) && (
                <HSelector>
                  <SelectorIndicator
                    status={getSelectedItemsPageStatus()}
                    onClick={onSelectAllClick}
                  />
                </HSelector>
              )}
              {React.Children.map(children, (child) => {
                if (!child) return null;

                const { size, alignRight, header } = child.props;

                return (
                  <HData size={size} alignRight={alignRight}>
                    {React.isValidElement(header)
                      ? React.cloneElement(header, {
                          onFilterChange: handleOrderingChange,
                          selectedFilters: { o: ordering },
                        })
                      : header}
                  </HData>
                );
              })}
            </HRow>
          </THead>
          <TBody>
            <DndContext
              sensors={dragAndDropSensors}
              collisionDetection={closestCenter}
              onDragEnd={onReorder}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext items={map(rows, idAccessor)} strategy={verticalListSortingStrategy}>
                {(!isLoading || infinityMode) &&
                  map(rows, (row, rowIndex) => {
                    const id = idAccessor ? row[idAccessor] : row.id;
                    const isSelected = !isEmpty(find(selectedItems, [idAccessor, id]));

                    return (
                      <React.Fragment key={id}>
                        <RowSpacer />
                        <Row isSelected={isSelected} rowId={row[idAccessor]}>
                          {({
                            isHovered,
                            dragAndDropAttrributesAndListeners,
                            dragAndDropSetNodeRef,
                          }) => (
                            <>
                              {!isEmpty(bulkBar) && (
                                <SelectorColumn
                                  id={id}
                                  item={row}
                                  isSelected={isSelected}
                                  addToSelectedItems={addToSelectedItems}
                                  removeFromSelectedItems={removeFromSelectedItems}
                                />
                              )}

                              {React.Children.map(children, (child, index) => {
                                if (!child) return null;

                                return React.cloneElement(child, {
                                  isHovered,
                                  row: {
                                    ...row,
                                    dragAndDropSetNodeRef,
                                    dragAndDropAttrributesAndListeners,
                                  },
                                  rows,
                                  highlighted: index === 0,
                                  index: rowIndex,
                                });
                              })}
                            </>
                          )}
                        </Row>
                      </React.Fragment>
                    );
                  })}
              </SortableContext>
            </DndContext>
          </TBody>
        </Table>

        {isLoading && <Loading />}

        {!isLoading && isEmpty(rows) && (
          <EmptyTableContainer>
            <div>
              <OldIcon name="magnify" size="100" color={colors.neutral600} />
            </div>
            <Text h4 color={colors.neutral400}>
              Sorry, we couldn’t find any results matching your search.
            </Text>
          </EmptyTableContainer>
        )}
      </TableWrapper>
      {!isLoading && !isEmpty(rows) && !infinityMode && (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" paddingY={2.5}>
          <Pagination
            size="medium"
            color="primary"
            variant="outlined"
            shape="rounded"
            count={paginationCount}
            defaultPage={paginationDefaultPage}
            onChange={handlePageChange}
          />
        </Box>
      )}
      {!isLoading && infinityMode && showLoadMoreButton && (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" paddingY={2.5}>
          <Button onClick={handleLoadMore} size="small">
            Load more
          </Button>
        </Box>
      )}
    </Container>
  );
};

DashboardLite.propTypes = {
  dashboardName: PropTypes.string,
  breadcrumbItems: PropTypes.arrayOf(BreadcrumbProptypes.Item),
  idAccessor: PropTypes.string,
  rows: PropTypes.array,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  requestStatus: PropTypes.string,
  isLoading: PropTypes.bool,
  refreshDashboard: PropTypes.func,
  children: PropTypes.array,
  actionBar: PropTypes.node,
  bulkBar: PropTypes.node,
  filterBar: PropTypes.node,
  // filters management
  filterObj: PropTypes.object,
  updateOrdering: PropTypes.func,
  updatePagination: PropTypes.func,
  // for drag'n'drop
  onReorder: PropTypes.func,
  infinityMode: PropTypes.bool,
  showLoadMoreButton: PropTypes.bool,
  loadMoreButtonCallback: PropTypes.func,
};

DashboardLite.defaultProps = {
  idAccessor: 'id',
  actionBar: <ActionsBar />,
  filterBar: null,
  infinityMode: false,
  showLoadMoreButton: false,
  loadMoreButtonCallback: noop,
};

export default DashboardLite;
