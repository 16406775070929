import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router';

import SettingsFormPage from '~/app/settings/components/SettingsFormPage';
import { ExtraSettingsContext } from '~/app/settings/context-providers/ExtraSettingsContext';
import { createSettingsMenu, prepareTopLevelSystemSettingsData } from '~/app/settings/services';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { mapRoute } from '~/services/requests';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import { useCurrentUser } from '~/app/shared/hooks';
import { IS_ADMIN_PERMISSION } from '~/app/shared/permissions';
import { get, includes } from 'lodash-es';

const SystemSettings = () => {
  const { context: contextParam } = useParams();

  const contextKey = `system/${contextParam}`;

  const [loadingSettingsStructure, setLoadingSettingsStructure] = useState(false);
  const [loadingSettingsData, setLoadingSettingsData] = useState(false);
  const [settings, setSettings] = useState({});
  const { permissions } = useCurrentUser();

  const url = `${ApiURLs['api_settings:settings']()}?context=system`;

  useEffect(() => {
    setLoadingSettingsStructure(true);
    fetchURL(url)
      .then((response) => {
        setSettings(response.data);

        setLoadingSettingsStructure(false);

        return response;
      })
      .catch(() => {
        setLoadingSettingsStructure(false);
      });
  }, [contextParam]);

  const [settingsData, setSettingsData] = useState({});

  const settingsUrl = ApiURLs['api_customers:retrieve_edit'](contextKey);

  useEffect(() => {
    setLoadingSettingsData(true);
    fetchURL(settingsUrl).then((response) => {
      setLoadingSettingsData(false);
      setSettingsData(response.data);

      return response;
    });
  }, [contextParam]);

  if (!includes(permissions, IS_ADMIN_PERMISSION)) {
    const redirectionRoute = mapRoute('home');
    toast.error("You're not allowed to access the settings page.");
    return <Redirect to={redirectionRoute} />;
  }

  return (
    <>
      <PageTitle title="Settings" />
      <ExtraSettingsContext.Provider
        value={{
          slackIntegrationUrl: settingsData.slack_integration_url,
          apiToken: settingsData.api_token,
          icalUrl: settingsData.ical_url,
        }}
      >
        <SettingsFormPage
          form="systemSettingsForm"
          currentContext={contextKey}
          menuItems={createSettingsMenu(settings)}
          loading={loadingSettingsData || loadingSettingsStructure}
          settings={get(settings, `items.${contextKey}`, {})}
          initialValues={{
            settings: prepareTopLevelSystemSettingsData(settingsData?.settings || {}),
          }}
        />
      </ExtraSettingsContext.Provider>
    </>
  );
};

export default SystemSettings;
