import { to } from '~/common/helpers';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { getErrorMessageFromResponse } from '~/services/requests';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import { split } from 'lodash-es';

export async function launchCourse({
  courseId,
  showPreview = false,
  isLaunchedInsideTrack = false,
}) {
  const apiCourseEndpoint = showPreview ? 'api_courses:preview' : 'api_courses:launch';
  const apiUrl = ApiURLs[apiCourseEndpoint]({ public_id: courseId });

  /**
   * Courses inside a track will have a `page` query param
   * which is needed to correctly redirect the user back to the
   * course content item inside of the track page.
   */
  let redirectUrl = window.location.href;

  if (!isLaunchedInsideTrack) {
    redirectUrl = split(redirectUrl, '?', 1)[0];
  }

  const [err, res] = await to(
    fetchURL(apiUrl, {
      method: 'POST',
      body: JSON.stringify({ redirect_url: redirectUrl }),
    })
  );

  if (err) {
    const errorMessage = getErrorMessageFromResponse(err);
    toast.error('Error', errorMessage);
    return;
  }

  const { data } = res;

  if (!showPreview) {
    window.open(data.launch_link, '_self');
  } else {
    window.open(data.preview_link, '_self');
  }
}
