import { get, toNumber, toLower, trimEnd, replace, reduce } from 'lodash-es';

import { Assessment } from '~/app/assessments/interfaces';
import { useContentItemAssignmentActions } from '~/features/contentitems/hooks/useContentItemAssignmentActions';
import { RateAssignmentBody } from '~/features/contentitems/types';
import FeedbackForm from '~/app/shared-cards/components/FeedbackForm';
import Modal from '~/app/shared/components/Modal';
import { useLabels } from '~/app/shared/hooks';
import RichTextDescription from '~/app/stand-alone-shared/components/ContentDescription';
import { styled } from '@mui/material';

const CongratulationsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface AssessmentCompletionModalProps {
  assessment: Assessment;
  handleClose: () => void;
}

export const AssessmentCompletionModal = (props: AssessmentCompletionModalProps) => {
  const { assessment, handleClose } = props;

  const assignment = get(assessment, 'user_assignment');

  const { rate, acknowledgeCompletion, isLoading } = useContentItemAssignmentActions({
    contentItem: assessment,
    assignment,
  });

  const { label_assessment: labelAssessment } = useLabels();

  const feedbackText = get(assignment, 'rating.feedback', '');
  const feedbackRating = get(assignment, 'rating.rating', 5);

  const passingScore = toNumber(get(assessment, 'passing_score', 0)) * 100;
  const hasPassingGrade = passingScore > 0;
  const score = toNumber(get(assignment, 'completion.score', '0')) * 100;

  const percentageScore = `${replace(trimEnd(score.toFixed(2), '0'), /\.$/, '')}%`;
  const percentagePassingScore = `${replace(trimEnd(passingScore.toFixed(2), '0'), /\.$/, '')}%`;

  const fieldLabels = {
    rating: `Rate the ${toLower(labelAssessment)}`,
    feedback: 'Leave a review',
  };
  const fieldPlaceholders = {
    // prettier-ignore
    feedback: 'We\'d appreciate your feedback.',
  };

  const handleRate = (rateBody: Omit<RateAssignmentBody, 'id'>) => {
    rate(rateBody, {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  const handleCloseAndAckCompletion = () => {
    acknowledgeCompletion({
      onSuccess: () => {
        handleClose();
      },
    });
  };

  const initial = {
    id: assessment.id,
    feedback_rating: feedbackRating || 5,
    feedback_text: feedbackText,
    user_id: get(assignment, 'user_id'),
  };

  const getCompletionDialogSetting = () => {
    if (hasPassingGrade && score >= passingScore) {
      return 'assessment_completion_success_dialog_content';
    }
    if (hasPassingGrade) {
      return 'assessment_completion_fail_dialog_content';
    }
    return 'assessment_completion_dialog_content';
  };

  const replacementsMap = [
    ['contentName', assessment.name],
    ['labelAssessment', labelAssessment],
    ['grade', percentageScore],
    ['passingGradeThreshold', percentagePassingScore],
  ];

  const interpolatedCompletionDialogContent = reduce(
    replacementsMap,
    (acc, [replacementKey, replacementValue]) =>
      replace(acc, `{{ ${replacementKey} }}`, replacementValue),
    get(assessment, `settings.${getCompletionDialogSetting()}.value.1`)
  );

  return (
    <Modal
      title={`${labelAssessment} Complete`}
      handleClose={handleCloseAndAckCompletion}
      width={500}
    >
      <CongratulationsContainer>
        <RichTextDescription description={interpolatedCompletionDialogContent} noPadding />
      </CongratulationsContainer>
      <FeedbackForm
        user={get(assignment, 'user')}
        initialValues={initial}
        onSubmitFunc={handleRate}
        fieldLabels={fieldLabels}
        fieldPlaceholders={fieldPlaceholders}
        handleDismiss={handleCloseAndAckCompletion}
        formMarginBottom={15}
        submitting={isLoading}
        feedbackIsPublic
      />
    </Modal>
  );
};

export default AssessmentCompletionModal;
