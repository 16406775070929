import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { listQueryKeyFactory, detailQueryKeyFactory } from '~/app/data-fetching/utils';

export const content_items = createQueryKeys('content_items', {
  list: listQueryKeyFactory({ endpointName: 'content_items_api:list' }),
  details: detailQueryKeyFactory({ endpointName: 'content_items_api:retrieve' }),
});

export type ContentItemsQueryKeys = inferQueryKeys<typeof content_items>;
