import { ContentState, convertFromRaw, convertToRaw, EditorState, RichUtils } from 'draft-js';
import PropTypes from 'prop-types';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import CharacterCount from '~/app/shared/components/CharacterCount';
import { forEach, debounce, isObject, get, isEmpty } from 'lodash-es';

import { linkDecorator } from './decorators';
import { getBlockRenderFunc, getBlockRenderMap } from './renderers';
import {
  videoEmbedder,
  eventTypeEmbedder,
  googleSlidesEmbedder,
  googleDocsEmbedder,
  googleSheetsEmbedder,
  googleImagesEmbedder,
  imageEmbedder,
  genericLinkEmbedder,
  confluenceDocEmbedder,
} from './services';
import toolbar from './toolbar';
import CloudDocModal from './toolbar/CloudDocModal';
import ConfluenceDocModal from './toolbar/ConfluenceDocModal';
import EmbeddingButton from './toolbar/EmbeddingButton';
import ImageModal from './toolbar/ImageModal';
import VideoModal from './toolbar/VideoModal';
import WebsiteModal from './toolbar/WebsiteModal';

const convertAndCall = (editorState, func) => {
  func(convertToRaw(editorState.getCurrentContent()));
};

// `leading: true` make debounce invoke the function in the beginning of the timeout.
// This is necessary to make pasting text into the editor and saving work properly.
const debouncedConvertAndCall = debounce(convertAndCall, 400, { leading: true });

class PatchedEditor extends Editor {
  focusEditor = () => {
    setTimeout(() => {
      if (this.editor) this.editor.focus();
    });
  };
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  .public-DraftStyleDefault-block {
    margin: 0.5em 0;
  }
  .rdw-colorpicker-modal-options {
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const TextReader = ({ value }) => {
  const isInitialContentEmpty = !value || isEmpty(value?.blocks);

  const editorState = isInitialContentEmpty
    ? EditorState.createEmpty()
    : EditorState.createWithContent(convertFromRaw(value));

  return (
    <Editor
      editorState={editorState}
      wrapperClassName="text-editor-wrapper"
      toolbarHidden
      readOnly
    />
  );
};

TextReader.propTypes = {
  value: PropTypes.object,
};

class TextEditor extends React.Component {
  constructor(props) {
    super(props);

    const { initialContent, readOnly, preview } = props;

    const isInitialContentEmpty = !initialContent || isEmpty(initialContent?.blocks);

    const editorState = isInitialContentEmpty
      ? EditorState.createEmpty()
      : EditorState.createWithContent(convertFromRaw(initialContent));

    const showImageModal = false;
    const uploadedImageUrl = '';

    const showCloudDocModal = false;
    const showConfluenceDocModal = false;

    this.state = {
      editorState,
      showImageModal,
      uploadedImageUrl,
      showCloudDocModal,
      showConfluenceDocModal,
    };

    const editorConfigs = {
      getEditorState: this.getEditorState,
      readOnly,
      preview,
    };
    this.blockRenderMap = getBlockRenderMap(editorConfigs);
    this.blockRendererFn = getBlockRenderFunc(editorConfigs);
  }

  componentDidUpdate = (prevProps) => {
    const { input } = this.props;
    const { editorState } = this.state;
    const value = get(input, 'value');
    const prevValue = get(prevProps, 'input.value');
    if (value !== prevValue && isObject(value) && value.type === 'link_embed') {
      this.handlePastedText(value.content, undefined, editorState);
    } else if (value !== prevValue && value && value.type === 'refresh_content') {
      // Specific solution to refresh the editor content when the initial data changes from outside
      // the component
      const contentState = ContentState.createFromBlockArray(
        value.content.blocks,
        value.content.entityMap
      );
      const newState = EditorState.createWithContent(contentState);
      this.onChange(newState);
    }
  };

  validateMaxLength = (textLength) => {
    const { maxLength } = this.props;
    return textLength < maxLength;
  };

  handleChange = (value) => {
    const { onChange, afterChange } = this.props;

    onChange?.(value);
    afterChange?.();
  };

  onChange = (editorState) => {
    const { beforeChange, setHasText } = this.props;

    this.setState({ editorState });

    const hasText = editorState?.getCurrentContent().hasText();

    // Used for validation in parent TextEditorField
    setHasText?.(hasText);

    // Sends the update content in the next Render cycle to make sure setHasText
    // has been successfully called in the parent components
    setTimeout(() => {
      beforeChange?.();
      if (editorState && hasText) {
        debouncedConvertAndCall(editorState, this.handleChange);
      }
      if (editorState && !hasText) {
        convertAndCall(editorState, this.handleChange);
      }
    });
  };

  getEditorState = () => {
    const { editorState } = this.state;
    return editorState;
  };

  getDecorators = () => {
    const { readOnly } = this.props;

    if (!readOnly) return [];

    return [linkDecorator];
  };

  handlePastedText = (text, html, editorState) => {
    const { allowGenericLinks, maxLength } = this.props;
    const parsers = [
      videoEmbedder,
      eventTypeEmbedder,
      googleSlidesEmbedder,
      googleDocsEmbedder,
      googleSheetsEmbedder,
      googleImagesEmbedder,
      imageEmbedder,
      confluenceDocEmbedder,
    ];

    if (allowGenericLinks) {
      parsers.push(genericLinkEmbedder);
    }

    let newState = null;

    forEach(parsers, (parser) => {
      const parsedState = parser(text, html, editorState);
      if (parsedState) {
        newState = parsedState;
        return false;
      }
      return true;
    });

    let hasValidTextLength = true;
    if (maxLength) {
      const textLength = text.length + editorState.getCurrentContent().getPlainText().length;
      hasValidTextLength = this.validateMaxLength(textLength);
    }

    if (hasValidTextLength && !newState) {
      return false;
    }

    if (hasValidTextLength && newState) {
      this.onChange(newState);
    }

    return true;
  };

  handleBeforeInput = (text) => {
    const { maxLength } = this.props;
    const { editorState } = this.state;
    if (text && maxLength) {
      const hasValidTextLength = this.validateMaxLength(
        editorState.getCurrentContent().getPlainText().length
      );

      if (!hasValidTextLength) return 'handled';
    }

    return 'not-handled';
  };

  handleKeyCommand = (command) => {
    const { editorState } = this.state;

    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  toggleImageModal = () => {
    const { showImageModal } = this.state;
    this.setState({
      showImageModal: !showImageModal,
      uploadedImageUrl: '',
      canRenderUploadedImage: false,
    });
  };

  toggleCloudDocModal = () => {
    const { showCloudDocModal } = this.state;
    this.setState({
      showCloudDocModal: !showCloudDocModal,
    });
  };

  toggleConfluenceDocModal = () => {
    const { showConfluenceDocModal } = this.state;
    this.setState({
      showConfluenceDocModal: !showConfluenceDocModal,
    });
  };

  toggleVideoModal = () => {
    const { showVideoModal } = this.state;
    this.setState({
      showVideoModal: !showVideoModal,
    });
  };

  toggleWebsiteModal = () => {
    const { showWebsiteModal } = this.state;
    this.setState({
      showWebsiteModal: !showWebsiteModal,
    });
  };

  handleUploadImage = (url) => {
    this.setState({ uploadedImageUrl: url, canRenderUploadedImage: true });
  };

  handleInsertClickImage = () => {
    const { uploadedImageUrl } = this.state;
    const { handleAppendToEditor } = this.props;
    setTimeout(() => {
      handleAppendToEditor(uploadedImageUrl);
      this.toggleImageModal();
    });
  };

  handleInsertClickCloudDoc = (url) => {
    const { handleAppendToEditor } = this.props;
    setTimeout(() => {
      handleAppendToEditor(url);
      this.toggleCloudDocModal();
    });
  };

  handleInsertClickConfluenceDoc = (url) => {
    const { handleAppendToEditor } = this.props;
    setTimeout(() => {
      handleAppendToEditor(url);
      this.toggleConfluenceDocModal();
    });
  };

  handleInsertClickVideo = (url) => {
    const { handleAppendToEditor } = this.props;
    setTimeout(() => {
      handleAppendToEditor(url);
      this.toggleVideoModal();
    });
  };

  handleInsertClickWebsite = (url) => {
    const { handleAppendToEditor } = this.props;
    setTimeout(() => {
      handleAppendToEditor(url);
      this.toggleWebsiteModal();
    });
  };

  handleOnFocus = () => {
    const { setIsFocused } = this.props;
    if (setIsFocused) setIsFocused(true);
  };

  handleOnBlur = () => {
    const { setIsFocused } = this.props;
    if (setIsFocused) setIsFocused(false);
  };

  render() {
    const {
      readOnly,
      klassName,
      maxLength,
      allowImageButton,
      allowCloudDocButton,
      allowConfluenceDocButton,
      allowVideoButton,
      allowWebsiteButton,
      toolbarOnFocus,
      isFocused,
      placeholder,
    } = this.props;
    const {
      editorState,
      uploadedImageUrl,
      showImageModal,
      canRenderUploadedImage,
      showCloudDocModal,
      showConfluenceDocModal,
      showVideoModal,
      showWebsiteModal,
    } = this.state;

    const customButtons = [];

    const customTags = [
      allowCloudDocButton,
      allowConfluenceDocButton,
      allowVideoButton,
      allowImageButton,
      allowWebsiteButton,
    ];

    // eslint-disable-next-line lodash/prefer-lodash-method
    if (customTags.some(Boolean)) {
      customButtons.push(
        <EmbeddingButton
          toggleCloudDocModal={this.toggleCloudDocModal}
          toggleConfluenceDocModal={this.toggleConfluenceDocModal}
          toggleVideoModal={this.toggleVideoModal}
          toggleImageModal={this.toggleImageModal}
          toggleWebsiteModal={this.toggleWebsiteModal}
          allowVideoButton={allowVideoButton}
          allowCloudDocButton={allowCloudDocButton}
          allowConfluenceDocButton={allowConfluenceDocButton}
          allowImageButton={allowImageButton}
          allowWebsiteButton={allowWebsiteButton}
        />
      );
    }
    return (
      <Container>
        <PatchedEditor
          editorState={editorState}
          onEditorStateChange={this.onChange}
          handleBeforeInput={this.handleBeforeInput}
          handlePastedText={this.handlePastedText}
          handleKeyCommand={this.handleKeyCommand}
          customDecorators={this.getDecorators()}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          customBlockRenderFunc={this.blockRendererFn}
          blockRenderMap={this.blockRenderMap}
          toolbar={toolbar}
          toolbarOnFocus={toolbarOnFocus}
          wrapperClassName="text-editor-wrapper"
          editorClassName={klassName}
          toolbarHidden={readOnly || (toolbarOnFocus && !isFocused)}
          toolbarCustomButtons={customButtons}
          readOnly={readOnly}
          placeholder={placeholder}
          spellCheck
        />

        {maxLength && (
          <CharacterCount
            maxLength={maxLength}
            textLength={editorState.getCurrentContent().getPlainText().length}
          />
        )}
        {showImageModal && (
          <ImageModal
            handleClose={this.toggleImageModal}
            handleUploadImage={this.handleUploadImage}
            handleInsertClick={this.handleInsertClickImage}
            uploadedImageUrl={uploadedImageUrl}
            canRender={canRenderUploadedImage}
          />
        )}
        {showCloudDocModal && (
          <CloudDocModal
            handleClose={this.toggleCloudDocModal}
            handleInsertClick={this.handleInsertClickCloudDoc}
            editorState={editorState}
          />
        )}
        {showConfluenceDocModal && (
          <ConfluenceDocModal
            handleClose={this.toggleConfluenceDocModal}
            handleInsertClick={this.handleInsertClickConfluenceDoc}
            editorState={editorState}
          />
        )}
        {showVideoModal && (
          <VideoModal
            handleClose={this.toggleVideoModal}
            handleInsertClick={this.handleInsertClickVideo}
          />
        )}
        {showWebsiteModal && (
          <WebsiteModal
            handleClose={this.toggleWebsiteModal}
            handleInsertClick={this.handleInsertClickWebsite}
          />
        )}
      </Container>
    );
  }
}

TextEditor.defaultProps = {
  readOnly: true,
  preview: false,
  allowImageButton: false,
  allowCloudDocButton: false,
  allowConfluenceDocButton: false,
  allowVideoButton: false,
  allowWebsiteButton: false,
};

TextEditor.propTypes = {
  initialContent: PropTypes.object,
  onChange: PropTypes.func,
  klassName: PropTypes.string,
  placeholder: PropTypes.string,

  input: PropTypes.object,

  readOnly: PropTypes.bool,
  preview: PropTypes.bool,

  beforeChange: PropTypes.func,
  afterChange: PropTypes.func,

  allowGenericLinks: PropTypes.bool,
  allowImageButton: PropTypes.bool,
  allowCloudDocButton: PropTypes.bool,
  allowConfluenceDocButton: PropTypes.bool,
  allowVideoButton: PropTypes.bool,
  allowWebsiteButton: PropTypes.bool,
  maxLength: PropTypes.number,
  handleAppendToEditor: PropTypes.func,
  setHasText: PropTypes.func,
  setIsFocused: PropTypes.func,
  toolbarOnFocus: PropTypes.bool,
  isFocused: PropTypes.bool,
};

export default TextEditor;
