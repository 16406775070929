import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import { eventSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE } from '~/app/shared/constants';
import { useCurrentUser, useLabels, usePrevious } from '~/app/shared/hooks';
import { join, map, isEmpty, difference } from 'lodash-es';

import { EventTypeEnrollmentActionElement } from '../AttendanceActionElements';
import EventItemHorizontal from '../EventItemHorizontal';

const RelatedEventContainer = styled.div`
  // Container related styling
  &:hover {
    background-color: ${colors.neutral50};
  }
`;

const NoEventsContainer = styled.div`
  color: #a6a6a6;
  font-size: 72px;
  text-align: center;
  width: 260px;
  margin: 30px auto;
`;

const NoEventsMessage = styled.p`
  font-size: 16px;
`;

const RelatedEventList = styled.div`
  .event-link {
    display: block;
    width: 100%;
    &:hover {
      background-color: ${colors.neutral200};
    }
  }

  > * + * {
    border-top: 1px solid ${colors.neutral100};
  }
`;

const SeeMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
`;

const SeeMoreLink = styled(Link)`
  color: ${colors.action600};

  &:hover {
    text-decoration: underline;
    color: ${colors.action700};
  }
`;

export const RelatedEvents = ({
  eventTypeId,
  excludeEventsIds,
  includePrivate,
  insideTrack,
  showEnrollmentAction,
  eventTypePublicIdAndSlug,
  pageSize,
}) => {
  const { timezone: userTimezone } = useCurrentUser();
  const {
    label_events_single_creator_title: singleCreatorTitle,
    label_events_multiple_creators_title: multipleCreatorsTitle,
    label_event_type: labelEventType,
  } = useLabels();

  const previousExcludeEventsIds = usePrevious(excludeEventsIds);

  const [fetchEventList, { data: events, status: eventsRequestStatus, nextPage: eventsNextPage }] =
    useEntities(actions.event.retrieveList, null, {
      schema: [eventSchema],
    });

  const defaultPageSize = insideTrack ? 2 : 20;

  const refreshEvents = () => {
    fetchEventList({
      event_types: [eventTypeId],
      include_private: includePrivate ? 'true' : 'false',
      period: 'upcoming',
      view_mode: showEnrollmentAction ? 'default' : 'lite',
      exclude_events: excludeEventsIds ? join(excludeEventsIds, ',') : null,
      page_size: pageSize ?? defaultPageSize,
    });
  };

  useEffect(() => {
    if (eventTypeId) refreshEvents();
  }, [eventTypeId]);

  useEffect(() => {
    const excludeEventsIdsChanged = !isEmpty(
      difference(excludeEventsIds, previousExcludeEventsIds)
    );

    if (excludeEventsIdsChanged) refreshEvents();
  }, [excludeEventsIds]);

  if (eventsRequestStatus !== STATUS_DONE) {
    return <Loading />;
  }

  if (isEmpty(events) && eventsRequestStatus === STATUS_DONE) {
    return (
      <NoEventsContainer>
        <i className="glyphicon glyphicon-calendar" />
        <NoEventsMessage>
          Sorry, there are no upcoming events for this {labelEventType}.
        </NoEventsMessage>
      </NoEventsContainer>
    );
  }

  return (
    <>
      <RelatedEventList>
        {map(events, (event) => (
          <RelatedEventContainer key={event.id}>
            <Link to={mapRoute('eventDetails', { public_id_and_slug: event.public_id_and_slug })}>
              <EventItemHorizontal
                singleCreatorTitle={singleCreatorTitle}
                multipleCreatorsTitle={multipleCreatorsTitle}
                event={event}
                userTimezone={userTimezone}
                hideOnlineTag={showEnrollmentAction}
              />
            </Link>
            {showEnrollmentAction && <EventTypeEnrollmentActionElement event={event} />}
          </RelatedEventContainer>
        ))}
      </RelatedEventList>
      {eventsNextPage && insideTrack && (
        <SeeMoreContainer>
          <SeeMoreLink
            to={mapRoute('eventTypeDetails', { public_id_and_slug: eventTypePublicIdAndSlug })}
          >
            See more
          </SeeMoreLink>
        </SeeMoreContainer>
      )}
    </>
  );
};

RelatedEvents.propTypes = {
  eventTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  eventTypePublicIdAndSlug: PropTypes.string,

  includePrivate: PropTypes.bool,
  excludeEventsIds: PropTypes.array,
  showEnrollmentAction: PropTypes.bool,

  insideTrack: PropTypes.bool,

  pageSize: PropTypes.number,
};

RelatedEvents.defaultProps = {
  includePrivate: false,
  showEnrollmentAction: false,
};

export default RelatedEvents;
