import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { useToggles } from '~/app/shared/hooks';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { redirectToGoogleCalendarLogin } from '~/services/integrations';
import { identify } from '~/services/metrics';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import { STATUS_DONE } from '~/app/shared/constants';
import actions from '~/app/users/actions';
import { useRenewCalendarAuthorizationModal } from '~/app/users/hooks';
import { get, startsWith } from 'lodash-es';

const CurrentUser = ({
  currentUser,
  updateCurrentUserTimezone,
  fetchCurrentUser,
  currentUserUpdateStatus,
}) => {
  const { dismissRenewCalendarAuthorizationModal, shouldShowRenewCalendarAuthorizationModal } =
    useRenewCalendarAuthorizationModal();
  const {
    toggle_block_profile_edit: toggleBlockProfileEdit,
    toggle_update_impersonated_user_timezone: toggleUpdateImpersonatedUserTimezone,
  } = useToggles();

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (currentUser.id && window.Sentry) {
      window.Sentry.configureScope((scope) => {
        scope.setUser({
          id: currentUser.id,
          email: currentUser.email,
        });
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser || !currentUser.is_timezone_automatic || toggleBlockProfileEdit) {
      return;
    }

    const isUserImpersonated = get(window, 'CURRENT_USER.is_hijacked', false);
    if (isUserImpersonated && !toggleUpdateImpersonatedUserTimezone) {
      return;
    }

    const guessedTimezone = moment.tz.guess();
    const shortGuessedTimezone = moment.tz(guessedTimezone).format('z');
    const shortCurrentUserTimezone = moment.tz(currentUser.timezone).format('z');

    if (shortGuessedTimezone !== shortCurrentUserTimezone) {
      currentUser.timezone = guessedTimezone;
      updateCurrentUserTimezone(currentUser.timezone);
    }
  }, [currentUser]);

  useEffect(() => {
    const userGlobalId = window.CURRENT_USER?.user_id;
    if (!userGlobalId) return;

    identify(userGlobalId);
  }, []);

  useEffect(() => {
    if (currentUserUpdateStatus === STATUS_DONE) {
      const updatedTimezone = moment.tz(currentUser.timezone).format('z');
      const isGMT = startsWith(updatedTimezone, '+') || startsWith(updatedTimezone, '-');
      const shortTimezone = isGMT ? `GMT ${updatedTimezone}` : `${updatedTimezone}`;

      fetchCurrentUser();

      toast.warning(
        `Your timezone was automatically updated to ${shortTimezone}.`,
        'You can change this behavior by editing your profile',
        { autoClose: false }
      );
    }
  }, [currentUserUpdateStatus]);

  return shouldShowRenewCalendarAuthorizationModal ? (
    <Modal
      title="Renew Google Calendar Authorization"
      width={600}
      handleClose={dismissRenewCalendarAuthorizationModal}
    >
      <ModalBody>
        <p>
          To make sure we can continue syncing events to <strong>Google Calendar</strong> we
          periodically need you to go through the authorization process. Please use the button
          bellow to grant access to your Google Calendar.
        </p>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={redirectToGoogleCalendarLogin}>
          Login with Google
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  ) : null;
};

CurrentUser.propTypes = {
  currentUser: PropTypes.object,
  currentUserUpdateStatus: PropTypes.string,
  fetchCurrentUser: PropTypes.func,
  updateCurrentUserTimezone: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentUser: get(state, 'user.currentUser'),
  currentUserUpdateStatus: get(state, 'user.currentUserUpdateStatus'),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => {
    dispatch(actions.currentUserRequestSubmit());
  },
  updateCurrentUserTimezone: (timezone) => {
    dispatch(actions.currentUserUpdateRequestSubmit({ timezone }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentUser);
