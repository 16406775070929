import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import TextInput from '~/app/inputs/components/TextInput';

const Select = (props) => {
  const { label, open, children, disabled, dropdownControls, ...rest } = props;

  return (
    <TextInput
      label={label}
      disabled={disabled}
      onClick={dropdownControls.toggle}
      fullWidth
      value={children}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

Select.defaultProps = {
  label: null,
};

Select.propTypes = {
  label: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownControls: PropTypes.object,
};

export default Select;
