import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import FileField from '~/app/inputs/components/FileField';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import { DisplayFormErrors } from '~/app/shared';
import Button from '~/app/shared/components/Button';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Loading from '~/app/shared/components/Loading';
import Form from '~/app/shared/components/OldForm';
import Text from '~/app/shared/components/Text';
import { useLabels } from '~/app/shared/hooks';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

const FormContainer = styled.div`
  margin-top: 32px;
`;

const FormDescription = styled.div`
  margin: 16px 0;
`;

const FormWarningDescription = styled.div`
  margin-bottom: 12px;

  pre {
    margin-top: 12px;
    white-space: pre;
    max-height: 480px;
  }
`;

const EventTypeCSVImportForm = ({
  handleSubmit,
  redirectionRoute,
  submitting,
  error,
  asyncJobError,
  csvImport,
}) => {
  const { label_event_type: labelEventType, label_event_type_plural: labelEventTypePlural } =
    useLabels();
  const formError = { ...error, ...asyncJobError };

  const templateDownloadUrl = `${mapRoute('bulkImportDownloadTemplate')}?import_type=event_type`;
  const importHelpArticleUrl =
    'https://help.plusplus.app/en/articles/5585989-how-to-bulk-import-event-types';

  return (
    <FormContainer>
      <Form
        onSubmit={handleSubmit}
        renderButtons={() => {
          if (submitting) {
            return <Loading />;
          }
          return (
            <Form.SubmitButtonsContainer>
              <Button type="submit" size="large">
                {csvImport ? 'Import' : 'Validate'}
              </Button>
              <Button route={redirectionRoute} type="button" color="error" size="large">
                Cancel
              </Button>
            </Form.SubmitButtonsContainer>
          );
        }}
      >
        <Form.Panel title={`Bulk Import ${labelEventTypePlural}`} collapsible={false}>
          {!csvImport && (
            <>
              <FormDescription>
                Use this form to bulk import {labelEventTypePlural} from a CSV file.
              </FormDescription>
              <FormDescription>
                First time doing this? You can{' '}
                <ButtonLink size="large" url={templateDownloadUrl}>
                  download our template
                </ButtonLink>{' '}
                or{' '}
                <ButtonLink size="large" url={importHelpArticleUrl} target="_blank">
                  learn more about bulk importing
                </ButtonLink>
                .
              </FormDescription>
              <Field
                name="csv_file"
                component={FileField}
                uploadAreaText={
                  <Text size="h4">
                    Click to{' '}
                    <Text color={colors.success600} size="h4" medium>
                      upload
                    </Text>
                  </Text>
                }
                filePath="event_type_csv"
                acceptedFileExtensions=".csv"
              />
            </>
          )}

          {csvImport && (
            <FormDescription block size="h4">
              Successfully validated <strong>{csvImport.created_count}</strong>{' '}
              {csvImport.created_count === 1 ? labelEventType : labelEventTypePlural}.
            </FormDescription>
          )}
          {csvImport && csvImport.pre_persist_output && (
            <FormWarningDescription>
              Identified the following issues: <pre>{csvImport.pre_persist_output}</pre>
              Import anyway?
            </FormWarningDescription>
          )}

          <DisplayFormErrors errors={formError} />
        </Form.Panel>
      </Form>
    </FormContainer>
  );
};

EventTypeCSVImportForm.propTypes = {
  redirectionRoute: PropTypes.string,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.object,
  asyncJobError: PropTypes.object,
  csvImport: PropTypes.object,
};

export default reduxForm({
  form: 'eventTypeCSVImportForm',
  onSubmit: (values, dispatch, { csvImport }) => {
    let actionName = actions.csvImport.create.toString();
    let payload = {
      body: {
        csv_file: values.csv_file,
        import_type: 'event_type',
      },
    };

    if (csvImport) {
      actionName = actions.csvImport.update.toString();
      payload = {
        id: csvImport.id,
      };
    }

    return onSubmitActions(actionName, () => payload)(values, dispatch);
  },
})(EventTypeCSVImportForm);
