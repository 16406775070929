import * as browserRum from '@datadog/browser-rum';

if (
  window.ENVIRONMENT_NAME !== 'Local' &&
  window.DD_RUM_APPLICATION_ID !== '' &&
  window.DD_RUM_CLIENT_TOKEN !== ''
) {
  browserRum.datadogRum.init({
    applicationId: window.DD_RUM_APPLICATION_ID,
    clientToken: window.DD_RUM_CLIENT_TOKEN,
    site: window.DD_RUM_SITE,
    service: window.DD_RUM_SERVICE_NAME,
    env: window.ENVIRONMENT_NAME,
    version: window.DD_RELEASE,
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [/https:\/\/.*\.plusplus\.app/],
    defaultPrivacyLevel: 'allow',
  });
}
