import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ContentItemCardRoot } from '~/common/components/ContentItemCard/ContentItemCardRoot';
import { Track } from '../../types';
import { useContentItemCardData } from '~/common/hooks/useContentItemCardData';
import { ContentItemCardCover } from '~/common/components/ContentItemCard/ContentItemCardCover';
import { TrackContentItemCardLearningArea } from './TrackContentItemCardLearningArea';
import { ContentItemCardBody } from '~/common/components/ContentItemCard/ContentItemCardBody';
import { ContentItemCardHeader } from '~/common/components/ContentItemCard/ContentItemCardHeader';
import { useChannelToggle } from '~/app/shared/hooks';
import { ContentItemCardOwners } from '~/common/components/ContentItemCard/ContentItemCardOwners';
import { ContentItemCardDescription } from '~/common/components/ContentItemCard/ContentItemCardDescription';
import { ContentItemCardTags } from '~/common/components/ContentItemCard/ContentItemCardTags';
import { ContentItemCardFooterArea } from '~/common/components/ContentItemCard/ContentItemCardFooterArea';
import { ContentItemCardRating } from '~/common/components/ContentItemCard/ContentItemCardRating';
import colors from '~/services/colors';
import { get } from 'lodash-es';

type TrackContentItemCardProps = {
  track: Track;
};

export function TrackContentItemCard(props: TrackContentItemCardProps) {
  const { track } = props;

  const { userAssignment, learningType, detailsRouteUrl } = useContentItemCardData(track);
  const toggleChannels = useChannelToggle();

  const assignmentsCount = track.total_assignments ?? track.assignments_count ?? 0;

  const rating =
    track.avg_feedback_rating ?? track.average_feedback_rating ?? track.feedback_rating;
  const formattedRating = rating ? Math.round(rating * 10) / 10 : null;

  const channelThemeColors = get(track.channel, 'theme.config.colors');

  return (
    <ContentItemCardRoot
      sx={{
        borderColor: grey[600],
      }}
    >
      <ContentItemCardCover
        contentItem={track}
        userAssignment={userAssignment}
        route={detailsRouteUrl}
      />
      <TrackContentItemCardLearningArea learningType={learningType} channel={track.channel} />
      <ContentItemCardBody>
        <ContentItemCardHeader
          title={track.name}
          route={detailsRouteUrl}
          channel={toggleChannels ? track.channel : undefined}
        />
        <ContentItemCardOwners
          facilitators={track.facilitators ?? []}
          learningType={learningType}
        />
        <ContentItemCardDescription description={track.content_body} />
        <ContentItemCardTags contentItem={track} />
      </ContentItemCardBody>

      <ContentItemCardFooterArea
        sx={{
          backgroundColor: channelThemeColors?.solid ?? colors.action700,
        }}
      >
        <Typography variant="body2" component="span" color={colors.neutral0}>
          {assignmentsCount} engaged
        </Typography>
        {formattedRating && (
          <ContentItemCardRating rating={formattedRating} sx={{ color: colors.neutral0 }} />
        )}
      </ContentItemCardFooterArea>
    </ContentItemCardRoot>
  );
}
