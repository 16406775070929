import { apiGet } from '~/services/requests';
import { get } from 'lodash-es';

export async function waitForAsyncJobCompletion(asyncJobId: string) {
  let asyncJobRes = await apiGet('api_async_jobs:retrieve', { public_id: asyncJobId });
  let asyncJobStatus = get(asyncJobRes, 'data.status');

  while (asyncJobStatus === 'pending') {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    asyncJobRes = await apiGet('api_async_jobs:retrieve', { public_id: asyncJobId });
    asyncJobStatus = get(asyncJobRes, 'data.status');
  }

  if (asyncJobStatus === 'failed') {
    throw new Error('Async job failed');
  }

  return asyncJobRes;
}
