import {
  Widget,
  WidgetContent,
  WidgetContentList,
  WidgetContentListItem,
  WidgetLinkAction,
} from '~/common/components/widget';
import { mapRoute } from '~/services/requests';
import { get, map, size } from 'lodash-es';

import {
  ContentItemWidgetEmptyContent,
  ContentItemWidgetLoadingContent,
  ContentItemWidgetRow,
} from '../components/BaseContentItemAsyncWidget';
import { useContentItemWidgetQuery } from '../hooks/useContentItemWidgetQuery';

import { baseSearch } from './utils';

type MySavedContentWidgetProps = {
  hideOnEmpty?: boolean;
};

export function MySavedContentWidget(props: MySavedContentWidgetProps) {
  const { hideOnEmpty = false } = props;

  const search = 'eq(self_assigned,true)&ordering(-total_assignments)';
  const querySearch = `${baseSearch}&${search}`;

  const { data, isLoading } = useContentItemWidgetQuery(querySearch);
  const { data: exploreCountData } = useContentItemWidgetQuery(
    'ordering(-total_assignments)&status=published'
  );

  const results = get(data, 'results', []);
  const displayResults = !isLoading && size(results) > 0;

  const resultsTotal = get(data, 'total', 0);
  const exploreCount = get(exploreCountData, 'total', 0);

  const viewAllUrl = `${mapRoute('unifiedCatalogList')}?${search}`;
  const exploreAllUrl = `${mapRoute(
    'unifiedCatalogList'
  )}?ordering(-total_assignments)&status=published`;

  if (hideOnEmpty && (isLoading || !displayResults)) {
    return null;
  }

  return (
    <Widget
      title="Saved Content"
      tooltip="Content I saved, self-started, or completed"
      action={
        resultsTotal > 3 ? (
          <WidgetLinkAction label={`View all ${resultsTotal}`} url={viewAllUrl} />
        ) : exploreCount > 0 ? (
          <WidgetLinkAction label={`Explore all ${exploreCount}`} url={exploreAllUrl} />
        ) : null
      }
    >
      {isLoading || displayResults ? (
        <WidgetContent>
          <WidgetContentList>
            {displayResults ? (
              map(results, (contentItem) => (
                <WidgetContentListItem key={contentItem.id}>
                  <ContentItemWidgetRow contentItem={contentItem} />
                </WidgetContentListItem>
              ))
            ) : (
              <ContentItemWidgetLoadingContent />
            )}
          </WidgetContentList>
        </WidgetContent>
      ) : (
        <ContentItemWidgetEmptyContent label="You didn't save any content" />
      )}
    </Widget>
  );
}
