import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const I = styled.i`
  font-size: ${({ size }) => size || 18}px;
  color: ${({ color }) => color || 'black'};

  ${({ onClick }) => onClick && `cursor: pointer;`}
`;

const OldIcon = (props) => {
  const { name, className, ...rest } = props;

  return <I className={`mdi mdi-${name} ${className}`} {...rest} />;
};

OldIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
};

export { OldIcon };
