/* eslint-disable lodash/prefer-lodash-method */
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';

import { useRemoveSegment, useCurrentSegment } from '~/app/backoffice/hooks';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { DASHBOARD_TYPES_URL_MAPPING } from '~/scenes/Dashboard/constants';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from '~/app/shared/constants';
import { includes } from 'lodash-es';

const DeleteSegmentModal = ({ segment, refreshSegments, handleClose }) => {
  const history = useHistory();
  const urlSegment = useCurrentSegment();
  const { remove: removeSegment, status } = useRemoveSegment(({ status }) => {
    if (status === STATUS_DONE) {
      const segmentBaseUrl = mapRoute(DASHBOARD_TYPES_URL_MAPPING[segment.content_type]);
      // Only redirect the user to the segment type main dashboard
      // page if they were in the deleted segment page
      if (urlSegment.public_id === segment.public_id) history.replace(segmentBaseUrl);
      toast.success('Segment successfully removed.');
      handleClose();
      refreshSegments();
    }
  });

  return (
    <Modal handleClose={handleClose} title={`Delete "${segment.name}"`}>
      <ModalBody>
        <Text block size="h5" colors={colors.neutral900}>
          You&#39;re about to remove the &quot;{segment.name}&quot; segment.
        </Text>
        <Text block size="h5" colors={colors.neutral900}>
          Are you sure you want to do this?
        </Text>
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose}>Cancel</ModalFooterButton>
        <ModalFooterButton
          color="error"
          onClick={() => removeSegment(segment.public_id)}
          disabled={includes([STATUS_LOADING, STATUS_ERROR], status)}
          aria-label="Delete"
        >
          Delete
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

DeleteSegmentModal.propTypes = {
  segment: PropTypes.object,
  handleClose: PropTypes.func,
  refreshSegments: PropTypes.func,
};

export default DeleteSegmentModal;
