import { chunk } from 'lodash-es';

export function buildWidgetsGrid<T = unknown>(widgets: T[], maxPerRow = 3) {
  if (maxPerRow < 2) {
    return chunk(widgets, 1);
  }

  const grid = chunk(widgets, maxPerRow);

  // If the last row has only one item ([...][.]),
  // steal from previous row so we get even items ([..][..])
  const lastChunkIdx = grid.length - 1;
  if (grid.length > 1 && grid[lastChunkIdx].length < 2) {
    const el = grid[lastChunkIdx - 1].pop();

    if (el) {
      grid[lastChunkIdx].unshift(el);
    }
  }

  return grid;
}
