import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import RecurrentDatesField, {
  validateRecurrentDates,
} from '~/app/inputs/components/RecurrentDatesField';
import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import { METRICS_ACTIVITIES } from '~/services/metrics';
import Datetime from '~/app/shared/components/Datetime';
import HelpLink from '~/app/shared/components/HelpLink';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { get, map, pick, isEmpty, size } from 'lodash-es';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

const formName = 'eventBulkCopyForm';

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SessionsContainer = styled.div`
  margin: 20px 0;
  max-height: 180px;
  overflow: auto;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HelpLinkWrapper = styled.div`
  margin-bottom: 20px;
`;

const selector = formValueSelector(formName);

const EventBulkDuplicateModal = (props) => {
  const { event, handleClose, handleSubmit, submitting } = props;
  const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);

  const recurringDates = useSelector((state) => selector(state, 'recurring_dates'));

  const eventStartsAt = moment(get(event, 'timeslots[0].starts_at_tz_aware')).tz(
    get(event, 'timezone')
  );

  const startsAt = eventStartsAt.set({
    hour: eventStartsAt.get('hour'),
    minute: eventStartsAt.get('minute'),
    second: eventStartsAt.get('second'),
  });

  return (
    <Modal
      title="Bulk Duplicate"
      handleClose={handleClose}
      /* We need this because of the Date popout v */
      overflow="visible"
      width={window.innerWidth > 500 ? 500 : null}
    >
      <form>
        {!showRecurrenceModal ? (
          <ModalContainer>
            <ModalBody>
              <FieldsContainer>
                <Text block size="h4" color={colors.neutral900}>
                  You are bulk duplicating{' '}
                  <Text bold size="h4" color={colors.neutral900}>
                    {event.name}
                  </Text>
                </Text>
                <HelpLinkWrapper>
                  <HelpLink url="https://help.plusplus.app/en/articles/5506010-making-multiple-copies">
                    Learn more about Bulk Duplicates
                  </HelpLink>
                </HelpLinkWrapper>
                <Field
                  name="recurring_dates"
                  component={RecurrentDatesField}
                  startsAt={startsAt}
                  baseItemTime={{
                    hour: eventStartsAt.get('hour'),
                    minute: eventStartsAt.get('minute'),
                    second: eventStartsAt.get('second'),
                  }}
                  locationId={event.location_id}
                  duration={event.timeslots[0].duration}
                  itemLabel="event"
                  validate={[validateRecurrentDates]}
                  timezone={get(event, 'timezone')}
                />
              </FieldsContainer>
            </ModalBody>
            <ModalFooter variant="buttons">
              <ModalFooterButton type="button" color="secondary" onClick={handleClose}>
                Back
              </ModalFooterButton>
              <ModalFooterButton
                type="button"
                disabled={isEmpty(recurringDates)}
                onClick={() => setShowRecurrenceModal(true)}
              >
                Next...
              </ModalFooterButton>
            </ModalFooter>
          </ModalContainer>
        ) : (
          <ModalContainer>
            <ModalBody>
              <Text block size="h4" color={colors.neutral900}>
                You are bulk duplicating{' '}
                <Text bold size="h4" color={colors.neutral900}>
                  {event.name}
                </Text>
                . Here is the list of {recurringDates.length} events you will be creating:
              </Text>
              <SessionsContainer>
                <ul>
                  {map(recurringDates, (dateInfo) => {
                    const startsAt = get(dateInfo, 'starts_at_tz_aware');
                    return (
                      <li key={startsAt}>
                        <Text block size="h4" color={colors.neutral900}>
                          <Datetime
                            key={startsAt}
                            datetime={startsAt}
                            timezone={dateInfo.timezone}
                          />
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              </SessionsContainer>

              <Text block size="h4" color={colors.neutral900}>
                Each event will be editable individually by admins through the Events Dashboard.
              </Text>
            </ModalBody>
            <ModalFooter variant="buttons">
              <ModalFooterButton
                type="button"
                color="secondary"
                onClick={() => setShowRecurrenceModal(false)}
              >
                Back
              </ModalFooterButton>
              <ModalFooterButton type="button" disabled={submitting} onClick={() => handleSubmit()}>
                Save
              </ModalFooterButton>
            </ModalFooter>
          </ModalContainer>
        )}
      </form>
    </Modal>
  );
};

const ConnectedEventBulkDuplicateModal = reduxForm({
  form: formName,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: (values, dispatch, { event, trackActivity }) => {
    return onSubmitActions(actions.event.bulkCopy.toString(), (values) => {
      const payload = {
        ...pick(values, ['recurring_dates']),
      };

      // Tracking activity in the submit since we don't have access to the payload in "onSubmitSuccess"
      //  Since the fields are generated, I believe this request won't be failing, which means
      //  it's safe to do this here
      trackActivity(METRICS_ACTIVITIES.ENTITY_BULK_DUPLICATE, {
        entity_type: 'event',
        duplicated_entity_id: event.id,
        // repeat_frequency missing since it's a local state in the form's field
        count: size(payload.recurring_dates),
      });

      return {
        key: formName,
        body: { ...payload, event_public_id: event.public_id },
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result, dispatch, { handleClose }) => {
    handleClose();
    toast.success('Events copied successfully.');
  },
  onSubmitFail: () => {
    toast.error('Submission failed, fix errors and try again.');
  },
})(EventBulkDuplicateModal);

EventBulkDuplicateModal.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  event: PropTypes.object,
  submitting: PropTypes.bool,
};

export default ConnectedEventBulkDuplicateModal;
