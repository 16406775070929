import React from 'react';
import styled from 'styled-components';

import CardCarousel from '~/app/shared-cards/components/CardCarousel';
import Text from '~/app/shared/components/Text';
import colors from '~/services/colors';

const RelatedEventContainer = styled.div`
  // Container related styling
  &:hover {
    background-color: ${colors.neutral50};
  }
  border: 1px solid ${colors.neutral200};
  width: 315px;
  border-radius: 4px;
  margin: 8px;
`;

const UpcomingContainer = styled.div`
  width: 100%;
  height: auto;
  margin: 20px auto 28px;
`;

const CarouselWrapper = styled.div`
  margin: 0px auto;
  width: 94%;
`;

const UpcomingEventsCount = styled.div`
  margin: 20px 20px 8px;
`;

const TextWrapper = styled(Text)`
  display: flex;
  justify-content: space-between;
`;

export interface UpcomingSectionProps<Content = any> {
  contentList: Content[];
  ContentCard: React.FC<{ content: Content }>;
  title?: string;
  subTitle?: string;
  startIndex?: number;
}

export const UpcomingSection = ({
  contentList,
  ContentCard,
  title = 'Upcoming offerings',
  subTitle = '',
  startIndex = 0,
}: UpcomingSectionProps) => {
  return (
    <UpcomingContainer>
      <UpcomingEventsCount>
        <TextWrapper size="h4" lineHeight={20} medium>
          <Text>{title}</Text>
          <Text size="h5" color={colors.neutral500}>
            {subTitle}
          </Text>
        </TextWrapper>
      </UpcomingEventsCount>
      <CarouselWrapper>
        <CardCarousel
          cardWidth={312}
          margin={10}
          list={contentList}
          startIndex={startIndex}
          justifyContent
          renderItem={(content) => (
            <RelatedEventContainer key={content.id}>
              <ContentCard content={content} />
            </RelatedEventContainer>
          )}
          renderMoreComponent={() => <span />}
        />
      </CarouselWrapper>
    </UpcomingContainer>
  );
};
