import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { LEARNING_TYPES } from '~/app/catalog/constants';
import actions from '~/app/entities/actions';
import { taskSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { permissionDeniedRedirectAlert } from '~/services/permission-denied-redirect-alert';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { useLabels, useOriginRoute } from '~/app/shared/hooks';
import { EDIT_TASK_PERMISSION } from '~/app/shared/permissions';
import { includes, map } from 'lodash-es';

import TaskFormPage from './TaskFormPage';

const TaskEdit = () => {
  const { public_id: publicId } = useParams();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.tracks
  }&o=upcoming`;

  const { label_task: labelTask } = useLabels();
  const backRoute = useOriginRoute(catalogRoute);

  const [fetchTask, { data: task, status }] = useEntities(
    actions.task.retrieveDetails,
    ({ data, status }) => {
      if (status === STATUS_DONE && !includes(data.permissions, EDIT_TASK_PERMISSION)) {
        permissionDeniedRedirectAlert();
      }
    },
    {
      schema: taskSchema,
    }
  );

  useEffect(() => {
    fetchTask(publicId);
  }, []);

  const initialValues = {
    ...task,
    facilitators_ids: map(task.facilitators, (facilitator) => facilitator.user_id),
    duration: task.duration === '00:00:00' ? null : task.duration,
  };

  if (status === STATUS_LOADING) return <Loading />;

  return (
    <TaskFormPage
      pageTitle={`Edit ${labelTask}`}
      topBarActionName={`Editing ${labelTask}`}
      backRoute={backRoute}
      initialValues={initialValues}
      isEdit
    />
  );
};

export default TaskEdit;
