import PropTypes from 'prop-types';
import React from 'react';

import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import { useTrackArchiveUnarchive } from '~/app/tracks/hooks';
import { Box } from '@mui/material';

const TrackArchiveUnarchiveConfirmationModal = ({ track, handleClose }) => {
  const { actionLabel, handleAction, isUpdating, renderConfirmationText } =
    useTrackArchiveUnarchive(track, () => handleClose({ shouldRefreshContent: true }));

  return (
    <Modal
      handleClose={handleClose}
      title={`${actionLabel} "${track.name}"`}
      maxWidth={window.innerWidth > 600 ? 600 : null}
      minBodyHeight="124px"
    >
      <ModalBody>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {renderConfirmationText()}
        </Box>
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose} color="primary">
          Close
        </ModalFooterButton>
        <ModalFooterButton
          color="error"
          onClick={handleAction}
          loading={isUpdating}
          disabled={isUpdating}
        >
          {actionLabel}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

TrackArchiveUnarchiveConfirmationModal.propTypes = {
  track: PropTypes.object,
  handleClose: PropTypes.func,
};

export default TrackArchiveUnarchiveConfirmationModal;
