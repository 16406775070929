import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import Pill from '~/app/shared/components/Pill';
import { get } from 'lodash-es';
import { styled } from '@mui/material';

const Container = styled('div')`
  min-height: 40px; // Large Button height
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
`;

interface PrimaryActionButtonProps {
  content: ContentItem;
}

const PrimaryActionButton = ({ content }: PrimaryActionButtonProps) => {
  const isCompleted = get(content, 'user_assignment.state') === ASSIGNMENT_STATES.completed;

  if (isCompleted) return null;

  return (
    <Container>
      <Pill
        icon="calendar"
        iconMarginRight="4px"
        label="Attend to Complete"
        variant="lighterGrayWithDarkerTextColor"
        size="medium"
      />
    </Container>
  );
};

export default PrimaryActionButton;
