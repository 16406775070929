import { useState } from 'react';

import LazyTextEditor from '~/app/inputs/components/LazyTextEditor';
import TextInput from '~/app/inputs/components/TextInput';
import Modal, { ModalProps, ModalFooter } from '~/app/shared/components/Modal';
import { TextEditorContainer, BorderWrapper } from '~/app/inputs/components/OldTextEditorField';
import colors from '~/services/colors';
import { FullTrackSection, TrackSection } from '~/app/tracks/interfaces';
import { isEmpty } from 'lodash-es';
import { Box, Button, CircularProgress, FormControl, InputLabel, Stack } from '@mui/material';

interface SectionFormModalProps extends Omit<ModalProps, 'title' | 'children'> {
  section: FullTrackSection;
  handleEditSection: (session: Omit<TrackSection, 'items'>) => void;
  handleClose: () => void;
}

export const SectionFormModal = ({
  section: { id, name: initialName, description: initialDescription },
  handleEditSection,
  handleClose,
}: SectionFormModalProps) => {
  const [name, setName] = useState(initialName);
  const initialContent = initialDescription ? JSON.parse(initialDescription) : null;
  const [description, setDescription] = useState(initialDescription);
  const [hasText, setHasText] = useState(false);
  const [error, setError] = useState(false);
  const [isDescriptionReady, setDescriptionReady] = useState(true);

  const handleChangeName = (e) => {
    if (error) {
      validate();
    }
    setName(e.target.value);
  };
  const handleChangeDescription = (value) => {
    setDescription(hasText ? JSON.stringify(value) : '');
  };

  const validate = () => {
    const hasError = isEmpty(name);
    setError(hasError);
  };

  const handleSubmit = () => {
    if (!error) {
      handleEditSection({ id, name, description });
      handleClose();
    }
  };

  return (
    <Modal width={window.innerWidth > 680 ? 680 : null} title="Edit Section">
      <Box sx={{ padding: 2 }}>
        <Stack spacing={2}>
          <TextInput
            label="Section Title"
            sx={undefined}
            onChange={handleChangeName}
            inputProps={undefined}
            onBlur={validate}
            value={name}
            error={error}
            required
          />
          <FormControl sx={{ minHeight: '240px', maxHeight: '360px' }}>
            <InputLabel
              shrink
              htmlFor="section-description-input"
              sx={{ backgroundColor: 'white', paddingX: '8px', marginLeft: '-8px' }}
            >
              Description
            </InputLabel>
            <TextEditorContainer>
              <BorderWrapper>
                <LazyTextEditor
                  id="section-description-input"
                  readOnly={false}
                  onChange={handleChangeDescription}
                  setHasText={setHasText}
                  allowGenericLinks
                  initialContent={initialContent}
                  beforeChange={() => {
                    setDescriptionReady(false);
                  }}
                  afterChange={() => {
                    setDescriptionReady(true);
                  }}
                />
              </BorderWrapper>
            </TextEditorContainer>
          </FormControl>
        </Stack>
      </Box>
      <ModalFooter variant="buttons">
        <Button
          variant="outlined"
          sx={{ color: colors.error600 }}
          onClick={handleClose}
          color="error"
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          sx={{ color: colors.success600, maxHeight: '38.5px', width: '71.61px' }}
          onClick={isDescriptionReady ? handleSubmit : undefined}
        >
          {isDescriptionReady ? 'Apply' : <CircularProgress size={26} />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
