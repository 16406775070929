import React from 'react';

import { PUBLISHED_STATUS } from '~/app/catalog/constants';
import actions from '~/app/entities/actions';

import LazyDropdownFilter from './LazyDropdownFilter';

const ContentItemFilter = (props) => {
  const getFetchExtraParams = (q, includeIds) => {
    return {
      include_ids: undefined,
      include_public_ids: includeIds,
      status: PUBLISHED_STATUS,
    };
  };

  return (
    <LazyDropdownFilter
      label="Content Items"
      param="content_item"
      actionFunc={actions.content.retrieveList}
      getFetchExtraParams={getFetchExtraParams}
      {...props}
    />
  );
};

export default ContentItemFilter;
