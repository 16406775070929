import { isString } from 'lodash-es';

const getClosedAnnouncements = (userId) => {
  const closedAnnouncementsStr = window.localStorage.getItem(`closedAnnouncements-${userId}`);

  if (!isString(closedAnnouncementsStr)) {
    return [];
  }

  return JSON.parse(closedAnnouncementsStr);
};

const addClosedAnnouncements = (userId, announcementId) => {
  const closedAnnouncements = getClosedAnnouncements(userId);
  const newClosedAnnouncements = [...closedAnnouncements, announcementId];

  window.localStorage.setItem(
    `closedAnnouncements-${userId}`,
    JSON.stringify(newClosedAnnouncements)
  );
};

export { getClosedAnnouncements, addClosedAnnouncements };
