import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { EDIT_EVENT_TYPE_PERMISSION } from '~/app/shared/permissions';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import actions from '~/app/entities/actions';
import { eventTypeSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { permissionDeniedRedirectAlert } from '~/services/permission-denied-redirect-alert';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import { STATUS_LOADING, STATUS_DONE } from '~/app/shared/constants';
import {
  useOriginRoute,
  useSetDetailedObject,
  usePublicIdFromURL,
  useLabels,
} from '~/app/shared/hooks';
import { capitalizeMultipleWordsName } from '~/app/shared/services';
import { toLower } from 'lodash-es';

import EventTypeFormPage from './EventTypeFormPage';

const EventTypeEdit = () => {
  const { publicId } = usePublicIdFromURL();
  const { public_id_and_slug } = useParams();
  const { label_event_type: labelEventType } = useLabels();
  const [userCanSee, setUserCanSee] = useState(false);

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.event_types
  }&o=upcoming`;
  const eventTypeDetailRoute = mapRoute('eventTypeDetails', { public_id_and_slug });
  const backRoute = useOriginRoute(eventTypeDetailRoute);

  const [fetchEventType, { data: eventType, status }] = useEntities(
    actions.eventType.retrieveDetails,
    ({ data: eventType, status }) => {
      if (status === STATUS_DONE) {
        if (eventType.permissions?.includes(EDIT_EVENT_TYPE_PERMISSION)) {
          setUserCanSee(true);
        } else {
          permissionDeniedRedirectAlert();
        }
      }
    },
    {
      schema: eventTypeSchema,
    }
  );

  useEffect(() => {
    fetchEventType(publicId);
  }, []);

  useSetDetailedObject({ id: publicId }, 'event_types', [publicId]);

  if (status === STATUS_LOADING || !userCanSee) return <Loading />;

  const initialValuesParams = { eventType };

  const capitalizedLabelEventType = capitalizeMultipleWordsName(labelEventType);

  return (
    <EventTypeFormPage
      pageTitle={`Edit ${capitalizedLabelEventType}`}
      topBarActionName={`Editing ${capitalizedLabelEventType}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: `${capitalizedLabelEventType}s`,
          link: catalogRoute,
        },
        {
          label: eventType.name,
          link: eventTypeDetailRoute,
        },
        {
          label: `Edit ${toLower(labelEventType)}`,
        },
      ]}
    />
  );
};

export default EventTypeEdit;
