import React, { useEffect } from 'react';

import { useParentSettings } from '~/app/settings/hooks';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import actions from '~/app/entities/actions';
import { articleSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { useCreateContentFromChannelParams } from '~/features/channels/hooks/useCreateContentFromChannelParams';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import { STATUS_LOADING } from '~/app/shared/constants';
import {
  useOriginRoute,
  useQueryParams,
  useSetDetailedObject,
  useLabels,
  useCurrentUser,
} from '~/app/shared/hooks';
import { get, isEmpty, toLower } from 'lodash-es';

import ArticleFormPage from './ArticleFormPage';

const ArticleCreate = () => {
  const currentUser = useCurrentUser();

  const parentSettingsFlat = useParentSettings('article/form', 'flat');
  const { label_article: labelArticle, label_article_plural: labelArticlePlural } = useLabels();

  const { cloned: duplicatedArticleId } = useQueryParams();
  const isDuplicating = Boolean(duplicatedArticleId);

  const {
    isFromChannel,
    isLoading: isLoadingChannelData,
    channelId,
  } = useCreateContentFromChannelParams();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.articles
  }&o=upcoming`;
  const backRoute = useOriginRoute(catalogRoute);

  const [fetchArticle, { data: clonedArticle, status }] = useEntities(
    actions.article.retrieveDetails,
    null,
    {
      schema: articleSchema,
    }
  );

  useEffect(() => {
    if (duplicatedArticleId) {
      fetchArticle(duplicatedArticleId);
    }
  }, []);

  useSetDetailedObject({ id: null }, 'articles', []);

  if (
    (duplicatedArticleId && status === STATUS_LOADING) ||
    (isFromChannel && isLoadingChannelData)
  ) {
    return <Loading />;
  }

  const initialValuesParams = {};

  if (duplicatedArticleId) {
    initialValuesParams.clonedArticle = clonedArticle;
  }

  if (!duplicatedArticleId && !isEmpty(parentSettingsFlat)) {
    initialValuesParams.defaultSettings = parentSettingsFlat;
  }

  if (isFromChannel) {
    initialValuesParams.defaultChannelId = channelId;
  } else {
    initialValuesParams.defaultChannelId = get(currentUser, 'default_channel_id');
  }

  return (
    <ArticleFormPage
      pageTitle={`${isDuplicating ? 'Duplicate' : 'Create'} ${labelArticle}`}
      topBarActionName={`${isDuplicating ? 'Duplicating' : 'Creating'} ${labelArticle}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelArticlePlural,
          link: catalogRoute,
        },
        {
          label: `New ${toLower(labelArticle)}`,
        },
      ]}
    />
  );
};

export default ArticleCreate;
