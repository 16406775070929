import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { RenderPropSticky } from 'react-sticky-el';
import styled from 'styled-components';

import colors from '~/services/colors';
import { useContentSidebarContext } from '~/app/shared-layouts/ContentTopbarPage';
import { TOPBAR_HEIGHT } from '~/app/shared/ui-constants';

const TopBarContainer = styled.div`
  height: ${TOPBAR_HEIGHT};
  background-color: ${colors.neutral0};
  position: relative;

  ${({ isSidebarOnMobileOpen }) =>
    isSidebarOnMobileOpen &&
    `
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
  `}

  &:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
    z-index: -1;
  }
`;

const TopBar = ({ children }) => {
  const [isSidebarOpen] = useContentSidebarContext();
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 768px)' });

  if (isMobileOrTablet && isSidebarOpen) {
    return <TopBarContainer isSidebarOnMobileOpen>{children}</TopBarContainer>;
  }

  return (
    // Sticky render prop version is used so we can pass zIndex: 2 for the container div
    // zIndex 2 because this component needs to be in front of ContentSidebar component
    <RenderPropSticky>
      {({ wrapperStyles, wrapperRef, holderStyles, holderRef }) => {
        return (
          <div ref={holderRef} style={{ ...holderStyles, zIndex: 2, position: 'relative' }}>
            <div style={wrapperStyles} ref={wrapperRef}>
              <TopBarContainer>{children}</TopBarContainer>
            </div>
          </div>
        );
      }}
    </RenderPropSticky>
  );
};

TopBar.propTypes = {
  children: PropTypes.node,
};

export default TopBar;
