import React from 'react';

import SelectField from '~/app/inputs/components/SelectField';
import { map } from 'lodash-es';

import TIMEZONES from './timezones';

class TimezoneField extends React.Component {
  getOptions = () =>
    map(TIMEZONES, (t) => ({
      label: t,
      value: t,
    }));

  render = () => <SelectField {...this.props} options={this.getOptions()} />;
}

export default TimezoneField;
