import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { ApiURLs, fetchURL } from '~/services/requests-base';

import { listQueryKeyFactory } from '../utils';

const fetchAssessment = async (id: number, viewMode = 'default') => {
  const base = ApiURLs['assessments_api:retrieve_update_destroy'](id);
  const url = `${base}?view_mode=${viewMode}`;
  const { data } = await fetchURL(url);
  return data;
};

export const assessments = createQueryKeys('assessments', {
  list: listQueryKeyFactory({ endpointName: 'assessments_api:list_create' }),
  listFilters: () => ({
    queryKey: ['api_content_items:rql_filters'],
    queryFn: async () => {
      const url = ApiURLs['api_content_items:rql_filters']();
      const { data } = await fetchURL(
        `${url}?filters_to_exclude=content_type&initial_filters=name&initial_filters=facilitator`
      );
      return { results: data };
    },
  }),
  detail: (id: number, viewMode = 'default') => ({
    queryKey: [id, viewMode],
    queryFn: () => fetchAssessment(id, viewMode),
  }),
});

export type AssessmentsQueryKeys = inferQueryKeys<typeof assessments>;
