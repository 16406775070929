import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { hasEditPermission } from '~/app/automated-rule/permissions';
import AutomatedRuleForm from '~/app/automated-rule/components/AutomatedRuleForm';
import {
  AutomatedRuleProvider,
  useAutomatedRuleContext,
} from '~/app/automated-rule/context-providers/AutomatedRuleContext';
import { toast } from '~/app/notifications/components/NotificationCenter';
import Page404 from '~/scenes/404';
import { permissionDeniedRedirectAlert } from '~/services/permission-denied-redirect-alert';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import PageTitle from '~/app/shared/components/PageTitle';
import { HTTP_404_NOT_FOUND, STATUS_DONE } from '~/app/shared/constants';
import { useQueryParams, useCurrentUser } from '~/app/shared/hooks';

const AutomatedRuleEditPage = () => {
  const history = useHistory();
  const { origin } = useQueryParams();
  const { automatedRule, fetchAutomatedRuleStatus, fetchAutomatedRuleStatusCode } =
    useAutomatedRuleContext();
  const { role: currentUserRole } = useCurrentUser();
  const hasPermission = hasEditPermission(currentUserRole);

  useEffect(() => {
    if (!hasPermission) {
      permissionDeniedRedirectAlert();
    }
  }, [hasPermission]);

  if (!hasPermission) return null;

  if (fetchAutomatedRuleStatusCode === HTTP_404_NOT_FOUND) return <Page404 />;
  if (fetchAutomatedRuleStatus !== STATUS_DONE) return <Loading />;

  return (
    <>
      <PageTitle title="Edit an Automated Rule" />
      <AutomatedRuleForm
        form="automatedRuleEditForm"
        topBarActionName="Editing an Automated Rule"
        initialValues={automatedRule}
        onSubmitSuccessHandler={() => {
          const url = origin ?? mapRoute('dashboardAutomatedRules');
          history.push(url);
          toast.success('Automated rule modified successfully!');
        }}
        isEdit
      />
    </>
  );
};

const AutomatedRuleEdit = () => {
  return (
    <AutomatedRuleProvider>
      <AutomatedRuleEditPage />
    </AutomatedRuleProvider>
  );
};

export default AutomatedRuleEdit;
