import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Clicker from '~/app/shared/components/Clicker';
import Icon from '~/app/shared/components/Icon';
import Text from '~/app/shared/components/Text';

const FAQItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.neutral400};
  padding: 20px;
  font-size: 14px;
`;

const PreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IndexContainer = styled.div`
  color: ${colors.emphasis600};
  margin-right: 15px;
`;

const TitleContainer = styled.div`
  display: flex;
`;

const ViewButton = styled(Clicker)`
  font-weight: 500;
  color: ${colors.neutral600};
  &:hover {
    color: ${colors.action600};
  }

  span {
    display: flex;
    align-items: center;
  }
`;

const MoreContainer = styled.div`
  margin-top: 15px;
`;

const ExtraInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-top: 8px;
  border: 1px solid ${colors.neutral200};
`;

const FAQItem = ({ index, title, content, extraInfo }) => {
  const [openViewMore, setOpenViewMore] = useState(false);
  return (
    <FAQItemContainer>
      <PreviewContainer>
        <TitleContainer>
          <IndexContainer>
            <Text size="h4">{index}</Text>
          </IndexContainer>
          <Text size="h4">{title}</Text>
        </TitleContainer>
        <ViewButton onClick={() => setOpenViewMore(!openViewMore)}>
          <Text size="h4">
            {openViewMore ? (
              <React.Fragment>
                Hide <Icon name="up" color={colors.neutral600} height={12} width={20} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                View <Icon name="down" color={colors.neutral600} height={12} width={20} />
              </React.Fragment>
            )}
          </Text>
        </ViewButton>
      </PreviewContainer>
      {openViewMore && (
        <div>
          <MoreContainer>{content}</MoreContainer>
          {extraInfo && <ExtraInfoContainer>{extraInfo}</ExtraInfoContainer>}
        </div>
      )}
    </FAQItemContainer>
  );
};

FAQItem.propTypes = {
  index: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.any,
  extraInfo: PropTypes.string,
};

export default FAQItem;
