import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import CheckboxInput from '~/app/inputs/components/CheckboxInput';
import InputLabel from '~/app/inputs/components/InputLabel';
import SliderField from '~/app/inputs/components/SliderField';
import TextField from '~/app/inputs/components/TextField';
import colors from '~/services/colors';
import { FormFieldGroup } from '~/app/shared/components/Form';
import InfoText from '~/app/shared/components/InfoText';
import Text from '~/app/shared/components/Text';
import { isNil } from 'lodash-es';

const PercentageFieldContainer = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 4px;
  }
`;

const PercentageOnlyFieldWrapper = styled.div`
  width: 88px;
`;

const PercentageSliderFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > * + * {
    margin-left: 8px;
    width: 100px;
  }
`;

const VideoAutocompletionField = ({
  input,
  showSlider,
  infoText,
  showToggle,
  meta: { initial },
}) => {
  const { value, onChange } = input;

  const minValue = '0.00';
  const maxValue = '100.00';

  // 0 means disabled
  const [autocompletion, setAutocompletion] = useState(!showToggle || value > 0);

  useEffect(() => {
    const initialValue = isNil(initial) ? minValue : initial;
    onChange(initialValue);
  }, []);

  useEffect(() => {
    if (autocompletion && value === minValue) {
      onChange(maxValue);
      return;
    }
    if (!autocompletion && value !== minValue) {
      onChange(minValue);
    }
  }, [autocompletion]);

  return (
    <>
      {showToggle && (
        <FormFieldGroup>
          <CheckboxInput
            label="Auto-Completion Enabled"
            labelWeight="medium"
            checked={autocompletion}
            onChange={() => setAutocompletion(!autocompletion)}
            infoText={infoText}
          />
        </FormFieldGroup>
      )}

      {autocompletion && (
        <FormFieldGroup>
          <InputLabel htmlFor="fraction_watched_for_completion">
            Video percentage that needs to be watched to autocomplete
          </InputLabel>
          {!showToggle && infoText && <InfoText top={4} bottom={8} content={infoText} />}
          <PercentageFieldContainer>
            {showSlider && (
              <PercentageSliderFieldWrapper>
                <Field
                  name="fraction_watched_for_completion"
                  component={SliderField}
                  min={minValue}
                  max={maxValue}
                  step="1.00"
                />
                <Field
                  name="fraction_watched_for_completion"
                  component={TextField}
                  type="number"
                  min={minValue}
                  max={maxValue}
                  step="1.00"
                />
              </PercentageSliderFieldWrapper>
            )}
            {!showSlider && (
              <>
                <PercentageOnlyFieldWrapper>
                  <Field
                    name="fraction_watched_for_completion"
                    component={TextField}
                    type="number"
                    min={minValue}
                    max={maxValue}
                    step="1.00"
                  />
                </PercentageOnlyFieldWrapper>
                <Text size="h5" color={colors.neutral500}>
                  %
                </Text>
              </>
            )}
          </PercentageFieldContainer>
        </FormFieldGroup>
      )}
    </>
  );
};

VideoAutocompletionField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  showSlider: PropTypes.bool,
  showToggle: PropTypes.bool,
  infoText: PropTypes.string,
};

VideoAutocompletionField.defaultProps = {
  showSlider: false,
  showToggle: true,
};

export default VideoAutocompletionField;
