import PropTypes from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { submit, isSubmitting } from 'redux-form';

import { CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING } from '~/app/catalog/constants';
import { useLearningTypeLabels } from '~/app/catalog/hooks';
import { mapRoute } from '~/services/requests';
import Button from '~/app/shared/components/Button';
import Loading from '~/app/shared/components/Loading';
import { SurveyRelationshipEntityType } from '~/app/surveys/constants';
import {
  getContentRouteBasedOnType,
  getSurveyRelationshipEntityType,
} from '~/app/surveys/services';
import surveyProptypes from '~/app/surveys/types';

const submittingSelector = isSubmitting('surveyAnswerForm');

const SurveyAnswerButton = ({ surveyRelationship, showBackButton, showSurveyPreview }) => {
  const dispatch = useDispatch();
  const submitting = useSelector(submittingSelector);
  const labels = useLearningTypeLabels();
  const { content_item_public_id_and_slug: ciPublicIdAndSlug, content_type: contentType } =
    surveyRelationship;
  const route = getContentRouteBasedOnType(contentType);
  const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[contentType];

  const submitForm = () => dispatch(submit('surveyAnswerForm'));

  if (showSurveyPreview) {
    return null;
  }

  if (showBackButton) {
    const type = getSurveyRelationshipEntityType(surveyRelationship);

    switch (type) {
      case SurveyRelationshipEntityType.EVENT: {
        return (
          <Button route={mapRoute('eventDetails', { public_id_and_slug: ciPublicIdAndSlug })}>
            Back to Event Details
          </Button>
        );
      }
      case SurveyRelationshipEntityType.ARTICLE:
      case SurveyRelationshipEntityType.COURSE:
      case SurveyRelationshipEntityType.TRACK:
      case SurveyRelationshipEntityType.VIDEO: {
        return (
          <Button route={mapRoute(route, { public_id_and_slug: ciPublicIdAndSlug })}>
            Back to {labels[learningType]} Details
          </Button>
        );
      }
      default: {
        return <Button disabled>Not Implemented</Button>;
      }
    }
  }

  if (submitting) {
    return <Loading hasMargin={false} />;
  }

  return (
    <Button size="small" onClick={submitForm}>
      Submit
    </Button>
  );
};

SurveyAnswerButton.propTypes = {
  surveyRelationship: surveyProptypes.surveyRelationship,
  showBackButton: PropTypes.bool,
  showSurveyPreview: PropTypes.bool,
};

export default SurveyAnswerButton;
