import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import FieldError from '~/app/inputs/components/FieldError';
import colors from '~/services/colors';
import { isString } from 'lodash-es';

import LazyTextEditor from '../LazyTextEditor';

export const TextEditorContainer = styled.div`
  & .rdw-editor-toolbar {
    border: 0;
    margin-bottom: 0;
    padding: 8px;

    > div {
      margin-bottom: 0;
    }
  }

  & .rdw-editor-main {
    min-height: ${({ minHeight }) => minHeight ?? '170px'};
    max-height: ${({ maxHeight }) => maxHeight ?? '310px'};
    max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
    ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
    padding: 8px;
  }
`;

TextEditorContainer.propTypes = {
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export const BorderWrapper = styled.div`
  border: 1px solid ${colors.neutral400};
  border-radius: 2px;

  ${({ isFocused, error }) =>
    isFocused &&
    !error &&
    `
      border-color: ${colors.action600};
    `}

  ${({ error }) =>
    error &&
    `
      border-color: ${colors.error600};
    `}
`;

const TextEditorField = (props) => {
  const { meta, input } = props;
  const { value } = input;

  const [hasText, setHasText] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (value) => {
    const { onChange } = input;
    // Make sure hasText is up to date before calling onChange in any child component,
    // otherwise the next line may evaluate wrongly the current editor state
    onChange(hasText ? JSON.stringify(value) : '');
  };

  const initialValue = value || meta.initial;
  let initialContent = null;

  if (initialValue) {
    if (isString(initialValue)) {
      try {
        initialContent = JSON.parse(initialValue);
      } catch (exception) {
        console.error('Got a invalid initial value on text editor', { exception, initialValue });
        window.Sentry.captureException(exception);
      }
    } else {
      initialContent = initialValue;
    }
  }

  return (
    <>
      <BorderWrapper isFocused={isFocused} error={meta.touched && meta.error}>
        <LazyTextEditor
          {...props}
          readOnly={false}
          initialContent={initialContent}
          onChange={handleChange}
          setHasText={setHasText}
          setIsFocused={setIsFocused}
        />
      </BorderWrapper>

      <FieldError meta={meta} />
    </>
  );
};

TextEditorField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default TextEditorField;
