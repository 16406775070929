import styled from 'styled-components';

import Text from '~/app/shared/components/Text';
import { useComposableTrackCtx } from '~/features/tracks/contexts/ComposableTrackCtx';
import { useTrackContentNavigationCtx } from '~/features/tracks/contexts/TrackContentNavigationCtx';
import { useTrackStepLabels } from '~/features/tracks/hooks/useTrackStepLabels';
import { TrackSectionWithItems } from '~/features/tracks/types';
import BaseContainer from '~/app/navigation/components/BaseContainer';
import colors from '~/services/colors';
import BackgroundPanel from '~/app/shared/components/BackgroundPanel';
import ContentDescription from '~/app/stand-alone-shared/components/ContentDescription';
import TrackItemCard from '~/app/tracks/components/TrackItemCard';
import { map, size, toLower } from 'lodash-es';
import { Paper, Stack } from '@mui/material';

const ContentItemBackgroundPanel = styled(BackgroundPanel)`
  left: 0;
  top: -40px;
`;

interface TrackSectionDetailProps {
  section: TrackSectionWithItems;
  currentTrackSectionIndex: number;
  trackSectionsCount: number;
}

const TrackSectionDetailPage = (props: TrackSectionDetailProps) => {
  const { section, currentTrackSectionIndex, trackSectionsCount } = props;

  const { description, items } = section;
  const stepsCount = size(items);

  const { getSectionOrItemIndex } = useComposableTrackCtx();
  const { onGoToPageIndex } = useTrackContentNavigationCtx();

  const handleSectionItemClick = (itemPageIndex: number) => {
    onGoToPageIndex(itemPageIndex);
  };

  const stepLabels = useTrackStepLabels();

  return (
    <BaseContainer>
      <ContentItemBackgroundPanel height="120px" />
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          width: '100%',
          padding: '16px 38px 20px 30px',
          marginTop: 5,
          borderRadius: '8px',
        }}
      >
        <Stack flex={1} spacing={2}>
          <Stack flex={1} spacing={0.5}>
            <Text medium size="h2" color={colors.neutral900}>
              {section.name}
            </Text>
            <Text size="h5" color={colors.neutral700}>
              Section {currentTrackSectionIndex} of {trackSectionsCount} • {stepsCount}
              {stepsCount === 1
                ? ` ${toLower(stepLabels.singular)}`
                : ` ${toLower(stepLabels.plural)}`}
            </Text>
          </Stack>

          {description && <ContentDescription noPadding description={description} />}

          {map(items, (item) => {
            const index = getSectionOrItemIndex(item.track_id!, item.content_item.public_id);

            return (
              <TrackItemCard item={item} onClick={() => handleSectionItemClick(index)} isSummary />
            );
          })}
        </Stack>
      </Paper>
    </BaseContainer>
  );
};

export default TrackSectionDetailPage;
