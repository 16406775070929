import type { ValueOf } from 'type-fest';

import colors from '~/services/colors';
import { InvertResult } from '~/types/generic-types';
import { invert } from 'lodash-es';

export const CHANNEL = {
  icon: 'channel',
  color: colors.channel700,
};

export const LEARNING_TYPES = {
  articles: 'articles',
  assessments: 'assessments',
  codelabs: 'codelabs',
  courses: 'courses',
  event_types: 'event_types',
  events: 'events',
  programs: 'programs',
  linkedcontent: 'linkedcontent',
  officehour_programs: 'officehour_programs',
  mentorship_programs: 'mentorship_programs',
  questions: 'questions',
  text_questions: 'text_questions',
  multiple_choice_questions: 'multiple_choice_questions',
  scheduled_tracks: 'scheduled_tracks',
  tasks: 'tasks',
  tracks: 'tracks',
  videos: 'videos',
  all: 'all',
} as const;

export const LEARNING_TYPE_ICONS_MAPPING: Record<
  Exclude<ValueOf<typeof LEARNING_TYPES>, 'all'>,
  string
> = {
  [LEARNING_TYPES.articles]: 'doc',
  [LEARNING_TYPES.assessments]: 'fact-check',
  [LEARNING_TYPES.codelabs]: 'integration-instructions',
  [LEARNING_TYPES.courses]: 'library',
  [LEARNING_TYPES.event_types]: 'calendar',
  [LEARNING_TYPES.events]: 'calendar',
  [LEARNING_TYPES.programs]: 'teach',
  [LEARNING_TYPES.linkedcontent]: 'link',
  [LEARNING_TYPES.questions]: 'question-mark',
  [LEARNING_TYPES.officehour_programs]: 'teach',
  [LEARNING_TYPES.mentorship_programs]: 'teach',
  [LEARNING_TYPES.multiple_choice_questions]: 'survey',
  [LEARNING_TYPES.text_questions]: 'text',
  [LEARNING_TYPES.scheduled_tracks]: 'scheduled-track',
  [LEARNING_TYPES.tasks]: 'task',
  [LEARNING_TYPES.tracks]: 'section',
  [LEARNING_TYPES.videos]: 'video',
};

export const LEARNING_TYPE_COLOR_MAPPING: Record<
  Exclude<ValueOf<typeof LEARNING_TYPES>, 'all'>,
  {
    color200: ValueOf<typeof colors>;
    color600: ValueOf<typeof colors>;
    color700: ValueOf<typeof colors>;
  }
> = {
  [LEARNING_TYPES.articles]: {
    color200: colors.learningArticle200,
    color600: colors.learningArticle600,
    color700: colors.learningArticle700,
  },
  [LEARNING_TYPES.assessments]: {
    color200: colors.learningAssessment200,
    color600: colors.learningAssessment600,
    color700: colors.learningAssessment700,
  },
  [LEARNING_TYPES.codelabs]: {
    color200: colors.learningCodelab200,
    color600: colors.learningCodelab600,
    color700: colors.learningCodelab700,
  },
  [LEARNING_TYPES.courses]: {
    color200: colors.learningCourse200,
    color600: colors.learningCourse600,
    color700: colors.learningCourse700,
  },
  [LEARNING_TYPES.event_types]: {
    color200: colors.learningEventType200,
    color600: colors.learningEventType600,
    color700: colors.learningEventType700,
  },
  [LEARNING_TYPES.events]: {
    color200: colors.learningEventType200,
    color600: colors.learningEventType600,
    color700: colors.learningEventType700,
  },
  [LEARNING_TYPES.programs]: {
    color200: colors.learningProgram200,
    color600: colors.learningProgram600,
    color700: colors.learningProgram700,
  },
  [LEARNING_TYPES.officehour_programs]: {
    color200: colors.learningProgram200,
    color600: colors.learningProgram600,
    color700: colors.learningProgram700,
  },
  [LEARNING_TYPES.mentorship_programs]: {
    color200: colors.learningMentorshipProgram200,
    color600: colors.learningMentorshipProgram600,
    color700: colors.learningMentorshipProgram700,
  },
  [LEARNING_TYPES.linkedcontent]: {
    color200: colors.learningLinkedContent200,
    color600: colors.learningLinkedContent600,
    color700: colors.learningLinkedContent700,
  },
  [LEARNING_TYPES.questions]: {
    color200: colors.learningQuestion200,
    color600: colors.learningQuestion600,
    color700: colors.learningQuestion700,
  },
  [LEARNING_TYPES.text_questions]: {
    color200: colors.learningQuestion200,
    color600: colors.learningQuestion600,
    color700: colors.learningQuestion700,
  },
  [LEARNING_TYPES.multiple_choice_questions]: {
    color200: colors.learningQuestion200,
    color600: colors.learningQuestion600,
    color700: colors.learningQuestion700,
  },
  [LEARNING_TYPES.tracks]: {
    color200: colors.learningTrack200,
    color600: colors.learningTrack600,
    color700: colors.learningTrack700,
  },
  [LEARNING_TYPES.scheduled_tracks]: {
    color200: colors.learningTrack200,
    color600: colors.learningTrack600,
    color700: colors.learningTrack700,
  },
  [LEARNING_TYPES.videos]: {
    color200: colors.learningVideo200,
    color600: colors.learningVideo600,
    color700: colors.learningVideo700,
  },
  [LEARNING_TYPES.tasks]: {
    color200: colors.learningTask200,
    color600: colors.learningTask600,
    color700: colors.learningTask700,
  },
};

export const FACILITATOR_ROLES = {
  main_organizer: 'main_organizer',
  co_organizer: 'co_organizer',
  presenter: 'presenter',
  main_host: 'main_host',
  co_host: 'co_host',
  author: 'author',
  co_author: 'co_author',
  program_manager: 'program_manager',
  maintainer: 'maintainer',
  co_maintainer: 'co_maintainer',
} as const;

export const LEARNING_TYPE_FACILITATOR_MAIN_ROLE: {
  [key in ValueOf<typeof LEARNING_TYPES>]?: ValueOf<typeof FACILITATOR_ROLES> | null;
} = {
  [LEARNING_TYPES.articles]: 'author',
  [LEARNING_TYPES.assessments]: 'maintainer',
  [LEARNING_TYPES.codelabs]: 'maintainer',
  [LEARNING_TYPES.courses]: 'maintainer',
  [LEARNING_TYPES.events]: 'main_organizer',
  [LEARNING_TYPES.event_types]: 'main_organizer',
  [LEARNING_TYPES.linkedcontent]: 'maintainer',
  [LEARNING_TYPES.officehour_programs]: 'program_manager',
  [LEARNING_TYPES.mentorship_programs]: 'program_manager',
  [LEARNING_TYPES.programs]: 'program_manager',
  [LEARNING_TYPES.scheduled_tracks]: 'maintainer',
  [LEARNING_TYPES.tasks]: 'maintainer',
  [LEARNING_TYPES.tracks]: 'author',
  [LEARNING_TYPES.videos]: null,
};

export const CONTENT_TYPES = {
  article: 'article',
  assessment: 'assessment',
  codelab: 'codelab',
  course: 'course',
  event: 'event',
  linkedcontent: 'linkedcontent',
  eventtype: 'eventtype',
  program: 'program',
  officehour_program: 'officehour_program',
  mentorship_program: 'mentorship_program',
  question: 'question',
  text_question: 'text_question',
  multiple_choice_question: 'multiple_choice_question',
  scheduled_track: 'scheduled_track',
  task: 'task',
  track: 'track',
  video: 'video',
  all: 'all',
} as const;

export const CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING: Record<
  ValueOf<typeof CONTENT_TYPES>,
  ValueOf<typeof LEARNING_TYPES>
> = {
  [CONTENT_TYPES.article]: LEARNING_TYPES.articles,
  [CONTENT_TYPES.assessment]: LEARNING_TYPES.assessments,
  [CONTENT_TYPES.codelab]: LEARNING_TYPES.codelabs,
  [CONTENT_TYPES.course]: LEARNING_TYPES.courses,
  [CONTENT_TYPES.event]: LEARNING_TYPES.events,
  [CONTENT_TYPES.eventtype]: LEARNING_TYPES.event_types,
  [CONTENT_TYPES.linkedcontent]: LEARNING_TYPES.linkedcontent,
  [CONTENT_TYPES.program]: LEARNING_TYPES.programs,
  [CONTENT_TYPES.officehour_program]: LEARNING_TYPES.officehour_programs,
  [CONTENT_TYPES.mentorship_program]: LEARNING_TYPES.mentorship_programs,
  [CONTENT_TYPES.question]: LEARNING_TYPES.questions,
  [CONTENT_TYPES.text_question]: LEARNING_TYPES.text_questions,
  [CONTENT_TYPES.multiple_choice_question]: LEARNING_TYPES.multiple_choice_questions,
  [CONTENT_TYPES.task]: LEARNING_TYPES.tasks,
  [CONTENT_TYPES.track]: LEARNING_TYPES.tracks,
  [CONTENT_TYPES.scheduled_track]: LEARNING_TYPES.scheduled_tracks,
  [CONTENT_TYPES.video]: LEARNING_TYPES.videos,
  [CONTENT_TYPES.all]: LEARNING_TYPES.all,
};

export const LEARNING_TYPE_TO_CONTENT_TYPE_MAPPING: Record<
  ValueOf<typeof LEARNING_TYPES>,
  ValueOf<typeof CONTENT_TYPES>
> = invert(CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING) as InvertResult<
  typeof CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING
>;

export const SYSTEM_PAGE_NAMES = {
  unifiedCatalog: 'unified_catalog',
  eventsFilter: 'events_filter',
  coachingSessionsFilter: 'coaching_sessions_filter',
  mentorshipSessionsFilter: 'mentorship_sessions_filter',
  menteeFilter: 'mentee_filter',
};

export const LEARNING_TYPE_KIND_FILTER: Partial<Record<ValueOf<typeof LEARNING_TYPES>, string>> = {
  [LEARNING_TYPES.articles]: 'article',
  [LEARNING_TYPES.videos]: 'cloud_video',
};

export const ACCESS_LEVELS = {
  public: 'public',
  unlisted: 'unlisted',
  restricted: 'restricted',
} as const;

// Assignment Statuses
// TODO remove after migration to Assignment 2.0
export const UNASSIGNED_STATUS = 'UNASSIGNED_STATUS';
export const ASSIGNED_STATUS = 'ASSIGNED_STATUS';
export const INCOMPLETE_STATUS = 'INCOMPLETE_STATUS';
export const IN_PROGRESS_STATUS = 'IN_PROGRESS_STATUS';
export const COMPLETED_STATUS = 'COMPLETED_STATUS';
export const DROPPED_STATUS = 'DROPPED_STATUS';
export const NOT_STARTED_STATUS = 'NOT_STARTED_STATUS';

export const DROP_TRACK_ITEM_NOT_GRANTED_EXCEPTION_NAME =
  'drop_track_item_not_granted_exception_name';

export const DELETE_CONTENT_ITEM_NOT_GRANTED_EXCEPTION_NAME =
  'delete_content_item_not_granted_exception';

// Local Storage
export const CATALOG_KEY_LOCAL_STORAGE = 'baseCatalogStickyFilter';

export const PUBLISHED_STATUS = 'published';

export const { DEFAULT_CONTENT_COVER } = window;
