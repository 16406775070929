import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Fields } from 'redux-form';
import styled from 'styled-components';

import CalendarMultipleResourcesFields from '~/app/inputs/components/CalendarMultipleResourcesFields';
import DateTimeField from '~/app/inputs/components/DateTimeField';
import DurationField, { validateDuration } from '~/app/inputs/components/DurationField';
import TextField from '~/app/inputs/components/TextField';
import colors from '~/services/colors';
import Clicker from '~/app/shared/components/Clicker';
import Icon from '~/app/shared/components/Icon';
import InfoText from '~/app/shared/components/InfoText';
import MediaPoint from '~/app/shared/components/MediaPoint';
import Form from '~/app/shared/components/OldForm';
import * as formValidations from '~/app/shared/formValidations';
import { ALLOW_SWITCHING_CALENDAR_OWNERSHIP_IN_EVENTS } from '~/app/shared/permissions';
import { values, get, includes } from 'lodash-es';

import CalendarManagerField from './CalendarManagerField';

const TopGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MediaPoint.Tablet} {
    flex-direction: row;
  }
`;

const LargeFieldContainer = styled.div`
  width: 100%;

  ${MediaPoint.Tablet} {
    width: 66%;
  }
`;

const SmallFieldContainer = styled.div`
  width: 100%;

  ${MediaPoint.Tablet} {
    width: 33%;
    padding-left: 15px;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

class TimeslotField extends React.Component {
  getFieldNameMapping = () => {
    const { name } = this.props;
    return {
      id: `${name}.id`,
      resourceOwner: `${name}.google_calendar_calendar_owner`,
      roomsInfo: `${name}.rooms_info`,
      startsAt: `${name}.starts_at`,
      duration: `${name}.duration`,
      watch_link: `${name}.watch_link`,
      rooms: `${name}.rooms`,
    };
  };

  handleRemoveTimeslot = () => {
    const { onRemove, index } = this.props;
    onRemove(index);
  };

  render() {
    const { name, locationId, isOnline, allowBookCalendarRooms, allowSwitchCalendarOwner } =
      this.props;

    const fieldNameMapping = this.getFieldNameMapping();

    const validateRequired = Form.validations.required();

    const validateWatchLink = () => {
      if (!isOnline) return;
      if (!allowBookCalendarRooms) {
        return [formValidations.url, validateRequired];
      }
      return [formValidations.url];
    };

    return (
      <div>
        <div>
          <hr />
        </div>
        <TopGroup>
          <CloseButtonContainer>
            <Clicker onClick={this.handleRemoveTimeslot}>
              <Icon name="close" width={12} height={12} color={colors.neutral600} />
            </Clicker>
          </CloseButtonContainer>

          <FieldsContainer>
            <LargeFieldContainer>
              {/* This field validation happens at form validate function */}
              <Field
                name={`${name}.starts_at`}
                component={DateTimeField}
                validate={[validateRequired]}
              />
            </LargeFieldContainer>
            <SmallFieldContainer>
              <Form.FieldLabel htmlFor="duration">Duration (h:mm) *</Form.FieldLabel>
              <Field
                inputId="duration"
                name={`${name}.duration`}
                component={DurationField}
                validate={[validateRequired, validateDuration]}
              />
            </SmallFieldContainer>
          </FieldsContainer>
        </TopGroup>
        {isOnline && (
          <React.Fragment>
            <Form.FieldLabel htmlFor={`${name}.watch_link`}>Live-stream link</Form.FieldLabel>
            <Field
              name={`${name}.watch_link`}
              component={TextField}
              validate={validateWatchLink()}
            />
            <InfoText
              top={4}
              bottom={8}
              content={
                allowBookCalendarRooms
                  ? `Link to where the attendees can participate in the online event streamed
               online. For example: Zoom, Meet, WebEx, etc. If the event allows online enrollment and you don't
                provide a specific link, a Google Meet link will be generated automatically.`
                  : `Link to where the attendees can participate in the online event streamed online. For example: Zoom, Meet, WebEx, etc.`
              }
            />
          </React.Fragment>
        )}
        <div>
          <div>
            <Form.FieldLabel htmlFor={`${name}.rooms_info`}>Room</Form.FieldLabel>
            {allowBookCalendarRooms ? (
              <Fields
                names={values(fieldNameMapping)}
                component={CalendarMultipleResourcesFields}
                nameMapping={fieldNameMapping}
                locationId={locationId}
                idFieldName="timeslot_id"
              />
            ) : (
              <Field name={`${name}.rooms_info`} component={TextField} />
            )}
          </div>
          <div>
            <Form.FieldLabel htmlFor={`${name}.extra_info`}>Extra Info</Form.FieldLabel>
            <Field name={`${name}.extra_info`} component={TextField} />
          </div>
        </div>
        {allowSwitchCalendarOwner && (
          <Fields
            names={values(fieldNameMapping)}
            component={CalendarManagerField}
            nameMapping={fieldNameMapping}
          />
        )}
      </div>
    );
  }
}

TimeslotField.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,

  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  allowBookCalendarRooms: PropTypes.bool,
  allowSwitchCalendarOwner: PropTypes.bool,
  isOnline: PropTypes.bool,
  onRemove: PropTypes.func,
};

const mapStateToProps = (state) => {
  const permissions = get(state, 'user.currentUser.permissions', []);

  return {
    allowBookCalendarRooms: get(state, 'user.currentUser.use_calendar_resources_for_events'),
    allowSwitchCalendarOwner: includes(permissions, ALLOW_SWITCHING_CALENDAR_OWNERSHIP_IN_EVENTS),
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TimeslotField);
