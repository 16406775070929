import { Box } from '@mui/material';
import { includes, size, some, get } from 'lodash-es';
import { useState } from 'react';

import Button from '~/app/shared/components/Button';
import { Assessment } from '~/app/assessments/interfaces';
import { ASSIGNMENT_REASONS, ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { canBeDropped } from '~/app/assignments/services';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import RetryAssessmentModal from '~/features/assessments/components/RetryAssessmentModal';
import { useAssignmentActionsCtx } from '~/features/contentitems/contexts/AssignmentActionsCtx';
import { CreateAssignmentBody } from '~/features/contentitems/types';
import { useComposableTrackCtx } from '~/features/tracks/contexts/ComposableTrackCtx';
import { ScheduledTrack } from '~/features/tracks/types';
import colors from '~/services/colors';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import Icon from '~/app/shared/components/Icon';
import Pill from '~/app/shared/components/Pill';
import { CONTENT_ITEM_PERMISSIONS } from '~/app/shared/permissions';
import ScheduledTrackEnrollModal from '~/app/tracks/components/ScheduledTrackEnrollModal';
import { Track } from '~/app/tracks/interfaces';
import {
  OpenInNewOutlinedIcon,
  PlayCircleOutlinedIcon,
  RedoOutlined,
  StartOutlined,
  VisibilityIcon,
} from '~/vendor/mui-icons';

import { useCanStartTrack } from '../hooks/useCanStartTrack';
import { useIsRenderedWithinTrack } from '../hooks/useIsRenderedWithinTrack';
import { useTrackStartContent } from '../hooks/useTrackStartContent';
import { hasScheduledTrackSpots } from '../utils/helpers';

type AssessmentActionButtonProps = {
  assessment: Assessment;
};

function AssessmentActionButton(props: AssessmentActionButtonProps) {
  const { assessment } = props;

  const {
    getTrackItemToResume,
    getSectionOrItemIndex,
    filterTrackItems,
    onRefreshDescendantTrackItems,
  } = useComposableTrackCtx();
  const onStartTrackContent = useTrackStartContent();
  const { create, isLoading } = useAssignmentActionsCtx();

  const isRenderedWithinTrack = useIsRenderedWithinTrack();

  const [showRetryAssessmentModal, setShowRetryAssessmentModal] = useState(false);

  const handleResumeAssessment = () => {
    const [resumeIdx] = getTrackItemToResume(assessment.public_id);

    onStartTrackContent({
      page: resumeIdx + 1,
    });
  };

  const handleLaunchAssessment = () => {
    const trackItems = filterTrackItems(assessment.public_id);
    const firstItem = trackItems[0];
    const firstItemIdx = getSectionOrItemIndex(
      assessment.public_id,
      firstItem.content_item.public_id
    );

    onStartTrackContent({
      page: firstItemIdx + 1,
    });
  };

  const handleRetryAssessment = () => {
    create(
      { is_reassign: true, reason: ASSIGNMENT_REASONS.assignment_retry },
      {
        onSuccess() {
          onRefreshDescendantTrackItems();
          handleLaunchAssessment();
        },
      }
    );
  };

  const handleStartAssessment = () => {
    create(
      {},
      {
        onSuccess() {
          handleLaunchAssessment();
        },
      }
    );
  };

  const canStartAssessment = useCanStartTrack(assessment);

  const assignment = assessment.user_assignment ?? assessment.assignment;

  const hasRetriesEnabled = assessment.max_attempts !== 1;
  const hasAttemptsLeft = get(assessment, 'remaining_attempts', 0) > 0;
  const canAssign = includes(assessment.permissions, CONTENT_ITEM_PERMISSIONS.assign);
  const canRetry = hasRetriesEnabled && canAssign && hasAttemptsLeft;
  const hasNoProgress = assignment?.state === ASSIGNMENT_STATES.not_started;
  const isCompleted = assignment?.state === ASSIGNMENT_STATES.completed;

  const displayAsPagePrimaryAction = !isRenderedWithinTrack || !isCompleted;

  if (isCompleted) {
    return (
      <>
        <Box display="flex" alignItems="center" gap="12px">
          {canRetry && (
            <Button
              endIcon={<RedoOutlined />}
              onClick={() => setShowRetryAssessmentModal(true)}
              disabled={isLoading}
            >
              Retake
            </Button>
          )}
          <Button
            variant={displayAsPagePrimaryAction ? 'contained' : 'outlined'}
            endIcon={<VisibilityIcon />}
            onClick={handleLaunchAssessment}
            disabled={isLoading}
          >
            Review
          </Button>
        </Box>

        {showRetryAssessmentModal && (
          <RetryAssessmentModal
            assessment={assessment}
            handleClose={() => setShowRetryAssessmentModal(false)}
            handleConfirm={handleRetryAssessment}
          />
        )}
      </>
    );
  }

  if (canBeDropped(assignment)) {
    return (
      <Button
        variant="contained"
        endIcon={hasNoProgress ? <OpenInNewOutlinedIcon /> : <PlayCircleOutlinedIcon />}
        onClick={handleResumeAssessment}
        disabled={isLoading}
      >
        {hasNoProgress ? 'Launch' : 'Resume'}
      </Button>
    );
  }

  if (canStartAssessment) {
    const disabled = !size(assessment.track_items) || isLoading;

    return (
      <Button
        variant="contained"
        endIcon={<OpenInNewOutlinedIcon />}
        onClick={handleStartAssessment}
        disabled={disabled}
      >
        Launch
      </Button>
    );
  }

  return null;
}

type ScheduledTrackActionButtonProps = {
  scheduledTrack: ScheduledTrack;
};

function ScheduledTrackActionButton(props: ScheduledTrackActionButtonProps) {
  const { scheduledTrack } = props;

  const [showScheduledTrackEnrollModal, setShowScheduledTrackEnrollModal] = useState(false);

  const { getTrackItemToResume, getSectionOrItemIndex, filterTrackItems } = useComposableTrackCtx();
  const onStartTrackContent = useTrackStartContent();
  const { create, isLoading } = useAssignmentActionsCtx();

  const handleResume = () => {
    const [resumeIdx] = getTrackItemToResume(scheduledTrack.public_id);

    onStartTrackContent({
      page: resumeIdx + 1,
    });
  };

  const handleLaunch = () => {
    const trackItems = filterTrackItems(scheduledTrack.public_id);
    const firstItem = trackItems[0];
    const firstItemIdx = getSectionOrItemIndex(
      scheduledTrack.public_id,
      firstItem.content_item.public_id
    );

    onStartTrackContent({
      page: firstItemIdx + 1,
    });
  };

  const canStartScheduledTrack = useCanStartTrack(scheduledTrack);

  const assignment = scheduledTrack.user_assignment ?? scheduledTrack.assignment;
  const hasNoProgress = assignment?.state === ASSIGNMENT_STATES.not_started;
  const hasSpots = hasScheduledTrackSpots(scheduledTrack);
  const hasEvents = some(scheduledTrack.track_items, ['content_type', CONTENT_TYPES.event]);

  const handleDisplayEnrollModal = () => {
    if (!hasEvents) {
      return;
    }

    setShowScheduledTrackEnrollModal(true);
  };

  const handleStartScheduledTrack = (
    createAssignmentBody: Omit<CreateAssignmentBody, 'content_item_id'> = {}
  ) => {
    create(createAssignmentBody, {
      onSuccess() {
        handleLaunch();
      },
    });
  };

  if (assignment?.state === ASSIGNMENT_STATES.completed) {
    return (
      <Button
        variant="contained"
        endIcon={<VisibilityIcon />}
        onClick={handleLaunch}
        disabled={isLoading}
      >
        Review
      </Button>
    );
  }

  if (canBeDropped(assignment)) {
    return (
      <Button
        variant="contained"
        endIcon={hasNoProgress ? <StartOutlined /> : <PlayCircleOutlinedIcon />}
        onClick={handleResume}
        disabled={isLoading}
      >
        {hasNoProgress ? 'Launch' : 'Resume'}
      </Button>
    );
  }

  if (!hasSpots) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" gap="12px">
        <Pill variant="lightGray" label="No spots left" />
      </Box>
    );
  }

  if (canStartScheduledTrack) {
    const disabled = !size(scheduledTrack.track_items) || isLoading;

    return (
      <>
        <Button
          variant="contained"
          endIcon={<Icon color={colors.action600} height={16} width={16} name="world" />}
          onClick={handleDisplayEnrollModal}
          disabled={disabled}
        >
          Enroll
        </Button>

        {showScheduledTrackEnrollModal && (
          <ScheduledTrackEnrollModal
            content={scheduledTrack}
            handleClose={() => setShowScheduledTrackEnrollModal(false)}
            handleAssign={({ eventEnrollmentOptions }) => {
              handleStartScheduledTrack({ event_enrollment_options: eventEnrollmentOptions });
            }}
          />
        )}
      </>
    );
  }

  return null;
}

type TrackActionButtonProps = {
  track: Track;
};

function TrackActionButton(props: TrackActionButtonProps) {
  const { track } = props;

  const { getTrackItemToResume, getSectionOrItemIndex, filterTrackItems } = useComposableTrackCtx();
  const onStartTrackContent = useTrackStartContent();
  const { create, isLoading } = useAssignmentActionsCtx();
  const isRenderedWithinTrack = useIsRenderedWithinTrack();

  const canStartTrack = useCanStartTrack(track);

  const assignment = track.user_assignment ?? track.assignment;
  const hasNoProgress = assignment?.state === ASSIGNMENT_STATES.not_started;
  const isCompleted = assignment?.state === ASSIGNMENT_STATES.completed;

  const displayAsPagePrimaryAction = !isRenderedWithinTrack || !isCompleted;

  const handleResume = () => {
    const [resumeIdx] = getTrackItemToResume(track.public_id);

    onStartTrackContent({
      page: resumeIdx + 1,
    });
  };

  const handleLaunch = () => {
    const trackItems = filterTrackItems(track.public_id);
    const firstItem = trackItems[0];
    const firstItemIdx = getSectionOrItemIndex(track.public_id, firstItem.content_item.public_id);

    onStartTrackContent({
      page: firstItemIdx + 1,
    });
  };

  const handleStartTrack = () => {
    create(
      {},
      {
        onSuccess() {
          handleLaunch();
        },
      }
    );
  };

  if (isCompleted) {
    return (
      <Button
        variant={displayAsPagePrimaryAction ? 'contained' : 'outlined'}
        endIcon={<VisibilityIcon />}
        onClick={handleLaunch}
        disabled={isLoading}
      >
        Review
      </Button>
    );
  }

  if (canBeDropped(assignment)) {
    return (
      <Button
        variant="contained"
        endIcon={hasNoProgress ? <StartOutlined /> : <PlayCircleOutlinedIcon />}
        onClick={handleResume}
        disabled={isLoading}
      >
        {hasNoProgress ? 'Launch' : 'Resume'}
      </Button>
    );
  }

  if (canStartTrack) {
    const disabled = !size(track.track_items) || isLoading;

    return (
      <Button
        variant="contained"
        endIcon={<StartOutlined />}
        onClick={handleStartTrack}
        disabled={disabled}
      >
        Start
      </Button>
    );
  }

  return null;
}

type TrackPrimaryActionButtonProps = {
  content: ContentItem;
};

export function TrackPrimaryActionButton(props: TrackPrimaryActionButtonProps) {
  const { content } = props;

  if (content.content_type === CONTENT_TYPES.assessment) {
    return <AssessmentActionButton assessment={content as Assessment} />;
  }

  if (content.content_type === CONTENT_TYPES.scheduled_track) {
    return <ScheduledTrackActionButton scheduledTrack={content as ScheduledTrack} />;
  }

  return <TrackActionButton track={content} />;
}
