import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import TextEditorInput from '~/app/inputs/components/TextEditorInput';
import colors from '~/services/colors';
import { useToggles } from '~/app/shared/hooks';
import { Typography } from '~/common/components/Typography';
import { Box, IconButton, Radio, Stack, Tooltip } from '@mui/material';
import {
  CloseOutlinedIcon,
  DragIndicatorIcon,
  ArchiveIcon,
  UnarchiveIcon,
} from '~/vendor/mui-icons';

export interface AssessmentQuestionChoiceInputProps {
  id: string;
  index: number;
  selected?: boolean;
  value: string;
  feedback: string;
  autoFocus?: boolean;
  disableRemoveChoice?: boolean;
  hasAnswer?: boolean;
  isArchived?: boolean;
  addChoice: () => void;
  updateChoice: (value: any) => void;
  updateChoiceFeedback: (value: any) => void;
  updateSelectedChoice: () => void;
  removeChoice: () => void;
  updateChoiceIsArchived: (isArchived: boolean) => void;
}

export const AssessmentQuestionChoiceInput = ({
  id,
  index,
  selected,
  value,
  feedback,
  disableRemoveChoice = false,
  hasAnswer = false,
  isArchived = false,
  updateChoice,
  updateChoiceFeedback,
  updateSelectedChoice,
  removeChoice,
  updateChoiceIsArchived,
}: AssessmentQuestionChoiceInputProps) => {
  const {
    toggle_allow_generic_link_embedding_on_snippet: toggleAllowGenericLinkEmbeddingOnSnippet,
  } = useToggles();

  const { attributes, listeners, setNodeRef, isDragging, transform } = useSortable({
    id,
  });
  const choiceLetter = String.fromCharCode(65 + index); // 65 == 'A'

  return (
    <Box display="flex" alignItems="center" padding="4px 30px">
      <Typography sx={{ width: '40px' }}>{choiceLetter}</Typography>
      <Box
        bgcolor={colors.neutral100}
        borderRadius="4px"
        display="flex"
        gap="8px"
        alignItems="center"
        width="100%"
        padding="6px 12px"
        ref={setNodeRef}
        sx={{
          opacity: isDragging ? 0.4 : undefined,
          // transition, // disabled until transition bug is fixed
          transform: CSS.Transform.toString(transform),
        }}
      >
        <IconButton aria-label={`question ${id} drag handle`}>
          <DragIndicatorIcon
            htmlColor={colors.neutral400}
            sx={{ cursor: isDragging ? 'grabbing' : 'grab' }}
            {...attributes}
            {...listeners}
          />
        </IconButton>
        <Tooltip title={selected ? 'Option selected as the correct choice' : ''}>
          <Radio
            name="selected-question"
            value={id}
            checked={selected}
            onChange={() => updateSelectedChoice()}
            inputProps={{ 'aria-label': choiceLetter }}
          />
        </Tooltip>
        <Stack gap={1} py={0.5} width="100%">
          <TextEditorInput
            value={value}
            label={`Choice ${choiceLetter} content`}
            required
            allowGenericLinks
            allowImageButton
            allowCloudDocButton
            allowConfluenceDocButton
            allowVideoButton
            onChange={updateChoice}
            toolbarOnFocus
            ContainerProps={{ TextEditorProps: { maxHeight: '100px', minHeight: '0px' } }}
            handleAppendToEditor={(url) => updateChoice({ type: 'link_embed', content: url })}
            allowWebsiteButton={toggleAllowGenericLinkEmbeddingOnSnippet}
          />
          <TextEditorInput
            value={feedback}
            label={`Choice ${choiceLetter} feedback`}
            required
            allowGenericLinks
            allowImageButton
            allowCloudDocButton
            allowConfluenceDocButton
            allowVideoButton
            onChange={updateChoiceFeedback}
            toolbarOnFocus
            ContainerProps={{ TextEditorProps: { maxHeight: '100px', minHeight: '0px' } }}
            handleAppendToEditor={(url) =>
              updateChoiceFeedback({ type: 'link_embed', content: url })
            }
            allowWebsiteButton={toggleAllowGenericLinkEmbeddingOnSnippet}
          />
        </Stack>
        {isArchived ? (
          <Tooltip title="Restore">
            <IconButton
              onClick={() => {
                updateChoiceIsArchived(false);
              }}
            >
              <UnarchiveIcon />
            </IconButton>
          </Tooltip>
        ) : hasAnswer ? (
          <Tooltip title="Archive">
            <IconButton
              disabled={disableRemoveChoice}
              onClick={() => {
                updateChoiceIsArchived(true);
              }}
            >
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Delete">
            <IconButton
              disabled={disableRemoveChoice}
              onClick={() => {
                removeChoice();
              }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
