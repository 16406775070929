import { useToggles } from '~/app/shared/hooks';
import UserContentBlock from '~/app/users/components/UserContentBlock';
import { grey } from '@mui/material/colors';
import { Typography } from '~/common/components/Typography';
import { Link } from '~/common/components/Links';
import { Box, Stack } from '@mui/material';
import { LinkedInIcon, MailIcon, SlackIcon } from '~/vendor/mui-icons';

interface ItemWrapperProps {
  link: string;
  text: string;
  Icon: React.ElementType;
}

const ItemWrapper = ({ link, text, Icon }: ItemWrapperProps) => (
  <Box display="flex" alignItems="center" gap={1} width="100%">
    <Icon fontSize="small" sx={{ color: grey[600] }} />
    <Link
      target="_blank"
      href={link}
      variant="body2"
      underline="hover"
      sx={{
        overflowWrap: 'anywhere',
      }}
    >
      {text}
    </Link>
  </Box>
);

interface ContactInfoProps {
  profileData: {
    email?: string;
    slack_link?: string;
    slack_name?: string;
    linkedin_username?: string;
  };
}

const ContactInfo = ({ profileData }: ContactInfoProps) => {
  const { toggle_user_profile_enrichment: toggleUserProfileEnrichment } = useToggles();

  const {
    email,
    slack_link: slackLink,
    slack_name: slackName,
    linkedin_username: linkedinUsername,
  } = profileData;

  return (
    <UserContentBlock>
      <Box paddingY={2}>
        <Typography variant="h6" gutterBottom>
          Contact Info
        </Typography>

        <Stack gap={1}>
          {email && <ItemWrapper link={`mailto:${email}`} text={email} Icon={MailIcon} />}

          {slackLink && <ItemWrapper link={slackLink} text={`@${slackName}`} Icon={SlackIcon} />}

          {toggleUserProfileEnrichment && linkedinUsername && (
            <ItemWrapper
              link={`https://www.linkedin.com/in/${linkedinUsername}`}
              text={linkedinUsername}
              Icon={LinkedInIcon}
            />
          )}
        </Stack>
      </Box>
    </UserContentBlock>
  );
};

export default ContactInfo;
