import { call, put, takeLatest } from 'redux-saga/effects';

import { ApiURLs, fetchURL } from '~/services/requests-base';

import * as constants from './constants';

export function* socialSharingForm(action) {
  const url = ApiURLs['api_integrations:social-sharing']({ service: action.payload.service });

  const requestData = {
    body: JSON.stringify(action.payload),
    method: 'POST',
  };
  const options = { ...action.options, ...requestData };

  try {
    yield call(fetchURL, url, options);
    yield put({
      type: constants.SOCIAL_SHARING_FORM_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: constants.SOCIAL_SHARING_FORM_FAILURE,
      payload: { _error: error.data },
    });
  }
}

export default function* sagas() {
  yield takeLatest(constants.SOCIAL_SHARING_FORM_SUBMIT, socialSharingForm);
}
