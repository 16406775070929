import { useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import styled from 'styled-components';
import type { ValueOf } from 'type-fest';

import { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import actions from '~/app/entities/actions';
import TextEditorField from '~/app/inputs/components/TextEditorField';
import colors from '~/services/colors';
import { FormFieldGroup } from '~/app/shared/components/Form';
import {
  useFormPreventTransition,
  useFormSelector,
  useFormSubmitErrors,
  useLabels,
  useToggles,
} from '~/app/shared/hooks';
import { pick, map, some, split, isEmpty, filter, trim, isNumber, toLower } from 'lodash-es';
import { Divider, Stack } from '@mui/material';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

import AssessmentQuestionMultipleChoicesInput from './AssessmentQuestionMultipleChoicesInput';
import AssessmentQuestionTextAnswerInput from './AssessmentQuestionTextAnswerInput';

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 75vh;
`;

export type QuestionTypes = ValueOf<
  Pick<typeof CONTENT_TYPES, 'text_question' | 'multiple_choice_question'>
>;

export interface AssessmentQuestion {
  title: string;
  explanation: string;
}

export interface AssessmentQuestionFormProps {
  form: string;
  questionType: QuestionTypes;
  handleSubmit: () => void;
  handleCancel: () => void;
  change(field: string, value: any): void;
  // eslint-disable-next-line react/no-unused-prop-types
  initialValues: any;
  isEdit: boolean;
}

export const AssessmentQuestionForm = ({
  form,
  questionType,
  handleSubmit,
  change,
  handleCancel,
  isEdit,
}: AssessmentQuestionFormProps) => {
  const textAnswerValue = useFormSelector(form, 'accepted_answers_value');
  const textAnswerOperator = useFormSelector(form, 'accepted_answers_operator');
  const textAnswerValueError = useFormSubmitErrors(form, 'accepted_answers_value');

  useFormPreventTransition(form);

  const { label_question: labelQuestion } = useLabels();

  const {
    toggle_allow_generic_link_embedding_on_snippet: toggleAllowGenericLinkEmbeddingOnSnippet,
  } = useToggles();

  const [contentBodyEditorLoading, setContentBodyEditorLoading] = useState(false);

  const handleOptionsChange = useCallback(
    (options) => {
      change('option_set', options);
    },
    [change]
  );

  const handleTextAnswerChange = useCallback(
    ({ value, operator }: { operator: string; value: string }) => {
      change('accepted_answers_value', value);
      change('accepted_answers_operator', operator);
    },
    [change]
  );

  const optionField = useMemo(() => {
    if (questionType === CONTENT_TYPES.multiple_choice_question) {
      return (
        <AssessmentQuestionMultipleChoicesInput
          form={form}
          setOptions={(newOptions) => handleOptionsChange(newOptions)}
        />
      );
    }

    return (
      <AssessmentQuestionTextAnswerInput
        value={textAnswerValue}
        operator={textAnswerOperator}
        onChange={(newValues) => handleTextAnswerChange(newValues)}
        error={Boolean(textAnswerValueError)}
      />
    );
  }, [
    questionType,
    textAnswerValue,
    textAnswerOperator,
    textAnswerValueError,
    form,
    handleOptionsChange,
    handleTextAnswerChange,
  ]);

  return (
    <ModalForm onSubmit={handleSubmit}>
      <ModalBody fullSize scrollable>
        <Stack
          direction="column"
          spacing="20px"
          padding="16px"
          sx={{ backgroundColor: colors.neutral50 }}
        >
          <FormFieldGroup>
            <Field
              name="content_body"
              label={labelQuestion}
              component={TextEditorField}
              required
              allowGenericLinks
              allowImageButton
              allowCloudDocButton
              allowConfluenceDocButton
              allowVideoButton
              allowWebsiteButton={toggleAllowGenericLinkEmbeddingOnSnippet}
              beforeChange={() => setContentBodyEditorLoading(true)}
              afterChange={() => setContentBodyEditorLoading(false)}
              ContainerProps={{ TextEditorProps: { minHeight: '70px', maxHeight: '150px' } }}
            />
          </FormFieldGroup>
          {questionType === CONTENT_TYPES.text_question && (
            <>
              <FormFieldGroup>
                <Field
                  name="feedback_text_on_success"
                  label="Feedback on a correct answer"
                  placeholder="Type a custom feedback message."
                  helperText={`This text appears when the ${toLower(
                    labelQuestion
                  )} is answered correctly.`}
                  component={TextEditorField}
                  allowGenericLinks
                  allowImageButton
                  allowCloudDocButton
                  allowConfluenceDocButton
                  allowVideoButton
                  allowWebsiteButton={toggleAllowGenericLinkEmbeddingOnSnippet}
                  beforeChange={() => setContentBodyEditorLoading(true)}
                  afterChange={() => setContentBodyEditorLoading(false)}
                  toolbarOnFocus
                  ContainerProps={{ TextEditorProps: { minHeight: '0px', maxHeight: '150px' } }}
                />
              </FormFieldGroup>
              <FormFieldGroup>
                <Field
                  name="feedback_text_on_fail"
                  label="Feedback on an incorrect answer"
                  placeholder="Type a custom feedback message."
                  helperText={`This text appears when the ${toLower(
                    labelQuestion
                  )} is answered incorrectly.`}
                  component={TextEditorField}
                  allowGenericLinks
                  allowImageButton
                  allowCloudDocButton
                  allowConfluenceDocButton
                  allowVideoButton
                  allowWebsiteButton={toggleAllowGenericLinkEmbeddingOnSnippet}
                  beforeChange={() => setContentBodyEditorLoading(true)}
                  afterChange={() => setContentBodyEditorLoading(false)}
                  toolbarOnFocus
                  ContainerProps={{ TextEditorProps: { minHeight: '0px', maxHeight: '150px' } }}
                />
              </FormFieldGroup>
            </>
          )}
        </Stack>
        <Divider />
        {optionField}
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton variant="text" color="error" onClick={handleCancel}>
          Cancel
        </ModalFooterButton>
        <ModalFooterButton type="submit" loading={contentBodyEditorLoading}>
          {isEdit ? 'Update' : 'Create'}
        </ModalFooterButton>
      </ModalFooter>
    </ModalForm>
  );
};

const ConnectedAssessmentQuestionForm = reduxForm({
  form: 'assessmentQuestionForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  onSubmit: (values, dispatch, { isEdit, formName, questionType, initialValues = {} as any }) => {
    const actionName = actions.question[isEdit ? 'update' : 'create'].toString();
    return onSubmitActions(actionName, (values) => {
      const payload: Record<string, any> = {
        ...pick(values, ['content_body']),
      };

      payload.content_type = questionType;
      if (questionType === CONTENT_TYPES.multiple_choice_question) {
        if (!some(values.option_set, (option) => option.selected)) {
          throw new SubmissionError({
            option_set: 'Please select the correct option',
          });
        }
        payload.writable_option_set = map(values.option_set, (choice, index) => ({
          id: isNumber(choice.id) ? choice.id : null,
          content_body: choice.content_body,
          is_correct: choice.selected,
          is_archived: choice.is_archived,
          order: index,
          feedback_text: choice.feedback_text,
        }));
      } else if (questionType === CONTENT_TYPES.text_question) {
        const blankAnswers = filter(map(split(values.accepted_answers_value, ','), trim), isEmpty);
        if (!isEmpty(blankAnswers)) {
          throw new SubmissionError({
            accepted_answers_value: 'One or more answers are empty.',
          });
        }

        payload.feedback_text_on_success = values.feedback_text_on_success;
        payload.feedback_text_on_fail = values.feedback_text_on_fail;
        payload.accepted_answers_value = values.accepted_answers_value;
        payload.accepted_answers_operator = values.accepted_answers_operator;
      }

      return {
        ...(initialValues.id && { id: initialValues.id }),
        key: formName,
        body: payload,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result, _, { onSubmitSuccessHandler }) => onSubmitSuccessHandler(result),
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error('Content submission failed. Check the flagged fields and try again.');
  },
})(AssessmentQuestionForm);

export default connect()(ConnectedAssessmentQuestionForm);
