import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { formatDate } from '~/services/datetime';
import Pill from '~/app/shared/components/Pill';

const ScheduledTrackDateRange = ({ scheduledTrack, labelInsidePill }) => {
  const startsAt = formatDate(moment(scheduledTrack.starts_at), null, { includeYear: true });
  const endsAt = formatDate(moment(scheduledTrack.ends_at), null, { includeYear: true });
  const dateRange = `${startsAt} - ${endsAt}`;
  return (
    <>
      <Pill
        variant="lighterGrayWithDarkerTextColor"
        icon="calendar-clock"
        label={labelInsidePill ? dateRange : null}
        round={!labelInsidePill}
      />
      {labelInsidePill ? null : dateRange}
    </>
  );
};

ScheduledTrackDateRange.propTypes = {
  scheduledTrack: PropTypes.object.isRequired,
  labelInsidePill: PropTypes.bool,
};

ScheduledTrackDateRange.defaultProps = {
  labelInsidePill: false,
};

export default ScheduledTrackDateRange;
