import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import InfoBox from '~/app/shared/components/InfoBox';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import actions from '~/app/entities/actions';
import { eventSchema, surveyRelationshipSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import BaseContainer from '~/app/navigation/components/BaseContainer';
import colors from '~/services/colors';
import Breadcrumbs from '~/app/shared/components/Breadcrumbs';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Loading from '~/app/shared/components/Loading';
import Text from '~/app/shared/components/Text';
import {
  ADMIN_ROLE_KEY,
  ORGANIZER_ROLE_KEY,
  STATUS_DONE,
  STATUS_ERROR,
} from '~/app/shared/constants';
import { useCurrentUser } from '~/app/shared/hooks';
import { SurveyRelationshipEntityType } from '~/app/surveys/constants';
import {
  getContentItemSurveyBreadcrumbs,
  getEventSurveyBreadcrumbs,
  getSurveyRelationshipEntityType,
} from '~/app/surveys/services';
import { get, includes } from 'lodash-es';

import SurveyAnswerButton from './SurveyAnswerButton';
import SurveyAnswerContent from './SurveyAnswerContent';
import SurveyAnswerSubheader from './SurveyAnswerSubheader';

const Content = styled.div`
  margin-top: 32px;
`;

const BreadcrumbWrapper = styled.div`
  max-width: 580px;
  margin: 0 auto;
`;

const SurveyCard = styled.div`
  background: ${colors.neutral0};
  max-width: 580px;
  margin: 16px auto 48px;
  border-radius: 10px;
`;

const SurveyCardHeader = styled.div`
  padding: 24px 32px;
`;

const SurveyCardBody = styled.div`
  padding: 32px;
  border-top: 1px solid ${colors.neutral200};
`;

const SurveyCardFooter = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${colors.neutral200};

  display: flex;
  justify-content: center;
`;

const InfoBoxWrapper = styled.div`
  max-width: 580px;
  margin: 0 auto;
`;

const ContentItemInfoBox = ({ surveyRelationship }) => {
  const {
    content_type: contentType,
    content_item_public_id_and_slug: publicIdAndSlug,
    related_matter_id: publicId,
  } = surveyRelationship;
  const contentTypeRoutes = useContentTypeRoutes();
  const route = `${contentTypeRoutes[contentType].edit({
    public_id_and_slug: publicIdAndSlug,
    public_id: publicId,
  })}#surveys`;
  return (
    <div>
      This survey will be available for assignees based on the schedule defined{' '}
      <ButtonLink route={route}>here</ButtonLink>.
    </div>
  );
};

ContentItemInfoBox.propTypes = {
  surveyRelationship: PropTypes.object,
  related_matter_id: PropTypes.number,
};

const getBreadcrumbsFromSurveyRelationship = (surveyRelationship) => {
  const relationshipType = getSurveyRelationshipEntityType(surveyRelationship);
  if (relationshipType === SurveyRelationshipEntityType.EVENT) {
    return getEventSurveyBreadcrumbs(surveyRelationship);
  }
  if (relationshipType === SurveyRelationshipEntityType.SESSION) {
    return <div>Not Implemented</div>;
  }
  if (relationshipType === SurveyRelationshipEntityType.CONTENT_ITEM) {
    return getContentItemSurveyBreadcrumbs(surveyRelationship);
  }
  return [];
};

const showSurveyPreviewFunc = (surveyRelationship, item, currentUser) => {
  const { is_available_for_answer: isAvailable } = surveyRelationship;
  // Session is available to answer, no need for previews
  if (isAvailable) {
    return false;
  }
  if (!item) {
    return false;
  }
  if (surveyRelationship.content_type === 'event') {
    return showSurveyPreviewFuncForEvents(item, currentUser);
  }
  if (surveyRelationship.content_type === 'session') {
    return <div>Not Implemented</div>;
  }
  return showSurveyPreviewFuncForContentItems(item, currentUser);
};

const showSurveyPreviewFuncForContentItems = (item, currentUser) => {
  // User is admin or organizer
  if (includes([ADMIN_ROLE_KEY, ORGANIZER_ROLE_KEY], currentUser.role)) {
    return true;
  }
  // TODO: CONTENT ITEM SURVEYS -> implement this function by fetching the content item and passing it to it,
  //  the logic is already built (pls test though)
  // const facilitators = get(item, 'facilitators', []);
  // const facilitatorsIds = map(facilitators, 'id');
  // // User is facilitator
  // if (includes(facilitatorsIds, currentUser.id)) {
  //   return true;
  // }
  return false;
};

const showSurveyPreviewFuncForEvents = (event, currentUser) => {
  // User is admin or organizer
  if (includes([ADMIN_ROLE_KEY, ORGANIZER_ROLE_KEY], currentUser.role)) {
    return true;
  }
  // User is event organizer
  if (get(event, 'organizer_id') === currentUser.id) {
    return true;
  }
  // User is event co-organizer
  if (includes(get(event, 'co_organizers_ids', []), currentUser.id)) {
    return true;
  }
  // User is event presenter
  if (includes(get(event, 'presenters_ids', []), currentUser.id)) {
    return true;
  }
  return false;
};

const SurveyAnswer = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const currentUser = useCurrentUser();

  const [request, { status, error, data: surveyRelationship }] = useEntities(
    actions.surveyRelationship.retrieveDetails,
    null,
    {
      schema: surveyRelationshipSchema,
    }
  );

  const [eventRequest, { data: event }] = useEntities(actions.event.retrieveDetails, null, {
    schema: eventSchema,
  });

  const { id } = useParams();
  useEffect(() => {
    request(id);
  }, []);

  useEffect(() => {
    if (surveyRelationship.content_type === 'event') {
      eventRequest(surveyRelationship.event);
    }
  }, [get(surveyRelationship, 'id')]);

  let item = null;

  if (event) {
    item = event;
  }

  if (status === STATUS_ERROR) {
    let errorMessage = error;
    if (error.detail) {
      errorMessage = error.detail;
    }
    return (
      <BaseContainer backgroundPanel>
        <Content>
          <SurveyCard>
            <SurveyCardHeader>
              <Text size="h2">{errorMessage}</Text>
            </SurveyCardHeader>
          </SurveyCard>
        </Content>
      </BaseContainer>
    );
  }

  if (status !== STATUS_DONE) {
    return <Loading hasMargin />;
  }

  const {
    survey,
    is_available_for_answer: isAvailable,
    is_answered_by_user: isAnswered,
  } = surveyRelationship;

  const showSurveyPreview = showSurveyPreviewFunc(surveyRelationship, item, currentUser);

  return (
    <BaseContainer backgroundPanel>
      <Content>
        <BreadcrumbWrapper>
          <Breadcrumbs
            items={getBreadcrumbsFromSurveyRelationship(surveyRelationship)}
            variant="alternate"
          />
        </BreadcrumbWrapper>
        {showSurveyPreview && (
          <InfoBoxWrapper>
            {surveyRelationship.content_type === 'event' ? (
              <InfoBox
                hideIcon
                type="warning"
                margin="16px 0 0 0"
                content="This survey is not accepting responses. If you’re the item's Organizer, you can reschedule this survey to resume collecting responses."
              />
            ) : (
              <InfoBox
                hideIcon
                type="warning"
                margin="16px 0 0 0"
                content={<ContentItemInfoBox surveyRelationship={surveyRelationship} />}
              />
            )}
          </InfoBoxWrapper>
        )}
        <SurveyCard>
          <SurveyCardHeader>
            <Text size="h1">{survey.title}</Text>
            <SurveyAnswerSubheader
              surveyRelationship={surveyRelationship}
              showSurveyPreview={showSurveyPreview}
            />
          </SurveyCardHeader>
          <SurveyCardBody>
            <SurveyAnswerContent
              showSurveyPreview={showSurveyPreview}
              surveyRelationship={surveyRelationship}
              showSuccess={submitSuccess}
              onSuccess={() => setSubmitSuccess(true)}
            />
          </SurveyCardBody>
          <SurveyCardFooter>
            <SurveyAnswerButton
              surveyRelationship={surveyRelationship}
              showBackButton={submitSuccess || !isAvailable || isAnswered}
              showSurveyPreview={showSurveyPreview}
            />
          </SurveyCardFooter>
        </SurveyCard>
      </Content>
    </BaseContainer>
  );
};

export default SurveyAnswer;
