import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import InfoText from '~/app/shared/components/InfoText';
import VideoPreview from '~/app/video/components/VideoPreview';
import TextField from '~/app/inputs/components/TextField';
import colors from '~/services/colors';
import { isEmbeddedVideoUrl } from '~/services/embed';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Clicker from '~/app/shared/components/Clicker';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Form from '~/app/shared/components/OldForm';
import Text from '~/app/shared/components/Text';
import { useFormSelector } from '~/app/shared/hooks';
import { trim, get } from 'lodash-es';
import VideoEmbedDocsModal from '~/app/video/components/VideoForm/VideoEmbedDocsModal';

const VideoURLLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DocsButtonContainer = styled(Clicker)`
  color: ${colors.action600};
  &:hover {
    text-decoration: underline;
    color: ${colors.action700};
  }
`;

const ButtonLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const VideoPreviewWrapper = styled.div`
  max-width: 500px;
  display: flex;
  justify-content: center;
`;

const validateRequired = Form.validations.required();

const validateUrl = (values, allValues, { videoUrl, allowedVideoUrlPattern }) => {
  if (!ReactPlayer.canPlay(videoUrl) && !isEmbeddedVideoUrl(videoUrl, allowedVideoUrlPattern)) {
    return ' ';
  }
};

const formName = 'videoModal';

const VideoModal = ({ handleClose, handleSubmit, invalid, pristine, allowedVideoUrlPattern }) => {
  const [showVideoEmbedDocsModal, setShowVideoEmbedDocsModal] = useState(false);
  const videoSource = useFormSelector(formName, 'video_url');

  const isEmbeddedVideoFile = isEmbeddedVideoUrl(videoSource, allowedVideoUrlPattern);

  return (
    <Modal handleClose={handleClose} title="Insert Video" width={550}>
      <ModalBody>
        <Form.FieldGroup>
          <VideoURLLabelContainer>
            <ButtonLinkWrapper>
              <ButtonLink onClick={null}>
                <DocsButtonContainer type="button" onClick={() => setShowVideoEmbedDocsModal(true)}>
                  <Text size="h5">How to Embed Videos</Text>
                </DocsButtonContainer>
                {showVideoEmbedDocsModal && (
                  <VideoEmbedDocsModal
                    height="430px"
                    handleClose={() => setShowVideoEmbedDocsModal(false)}
                  />
                )}
              </ButtonLink>
            </ButtonLinkWrapper>
          </VideoURLLabelContainer>
          <Field
            label="Video URL"
            placeholder="Insert URL"
            name="video_url"
            component={TextField}
            validate={[validateRequired, validateUrl]}
          />
          <InfoText
            top={4}
            bottom={8}
            content="Supported sources: HTML5-compatible video, YouTube, Google Drive, Vimeo, Wistia, Vidyard, and others."
          />
        </Form.FieldGroup>
        <VideoPreviewWrapper>
          <VideoPreview
            videoSource={videoSource}
            width="426px"
            height="240px"
            previewMessage={
              <>
                Once you specify a valid <em>Video URL</em> from a supported video source, you will
                see a preview of it here.
              </>
            }
            warningMessage={
              <>
                The specified <em>Video URL</em> points to an unsupported video source. Please check
                the URL to try again. Once a valid video is detected, you will see a preview of it
                here.
              </>
            }
            isEmbeddedVideoFile={isEmbeddedVideoFile}
          />
        </VideoPreviewWrapper>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose} color="error" size="large">
          Cancel
        </ModalFooterButton>
        <ModalFooterButton onClick={handleSubmit} size="large" disabled={invalid || pristine}>
          Insert
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

VideoModal.propTypes = {
  allowedVideoUrlPattern: PropTypes.string,

  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,

  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
};

const VideoModalForm = reduxForm({
  form: formName,
  onSubmit: (values, dispatch, { videoUrl, handleInsertClick }) => {
    handleInsertClick(videoUrl);
  },
})(VideoModal);

const formSelector = formValueSelector(formName);

const mapStateToProps = (state) => {
  const videoUrl = formSelector(state, 'video_url');
  const parsedUrl = trim(videoUrl);
  const allowedVideoUrlPattern = get(state, 'user.currentUser.allowed_video_url_pattern');

  return {
    videoUrl: parsedUrl,
    allowedVideoUrlPattern,
  };
};

export default connect(mapStateToProps)(VideoModalForm);
