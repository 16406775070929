import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { mapRoute } from '~/services/requests';

import BasePersonCard from './BasePersonCard';

const CardContainer = styled.div`
  ${({ hasMargins }) => hasMargins && 'margin: 12px 10px;'};
`;

const MenteeCard = ({ mentee, disableAllClicks, offerComponent, hasMargins, cardProps }) => {
  const { id } = mentee;

  const userProfileUrl = mapRoute('userProfile', { id });

  return (
    <CardContainer hasMargins={hasMargins}>
      <BasePersonCard
        user={mentee}
        route={userProfileUrl}
        disableAllClicks={disableAllClicks}
        cardProps={cardProps}
      />
      {offerComponent}
    </CardContainer>
  );
};

MenteeCard.propTypes = {
  mentee: PropTypes.shape({
    id: PropTypes.number,
    free_hosting_sessions_count: PropTypes.number,
    badges: PropTypes.array,
    name: PropTypes.string,
    title: PropTypes.string,
    department: PropTypes.string,
    location: PropTypes.object,
    skills: PropTypes.array,
  }),
  disableAllClicks: PropTypes.bool,
  hasMargins: PropTypes.bool,
  offerComponent: PropTypes.node,
  cardProps: PropTypes.object,
};

export { MenteeCard };
