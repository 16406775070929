import { Box, styled } from '@mui/material';
import colors from '~/services/colors';

export const ContentItemCardFooterArea = styled(Box, {
  name: 'ContentItemCard',
  slot: 'footerArea',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  color: ${colors.neutral500};
`;
