import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';

const InfoTextContainer = styled.div`
  color: ${colors.neutral600};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 20px;
  margin: ${({ top }) => top}px ${({ right }) => right}px ${({ bottom }) => bottom}px
    ${({ left }) => left}px;
`;

const InfoText = ({ content, top, right, bottom, left, fontSize }) => (
  <InfoTextContainer top={top} right={right} bottom={bottom} left={left} fontSize={fontSize}>
    {content}
  </InfoTextContainer>
);

InfoText.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  fontSize: PropTypes.number,
};

InfoText.defaultProps = {
  top: 16,
  right: 0,
  bottom: 0,
  left: 0,
  fontSize: 14,
};

export default InfoText;
