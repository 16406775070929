import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { COMPLETED_STATUS } from '~/app/catalog/constants';
import colors from '~/services/colors';
import ProgressBar from '~/app/shared/components/ProgressBar';
import { Tooltip } from '~/app/shared/components/Tooltip';
import { useTooltipUID } from '~/app/shared/hooks';

const CompletedSnippets = styled.div`
  font-size: 12px;
  color: ${colors.neutral600};
`;

const Wrapper = styled.div`
  margin: ${({ margin }) => margin ?? 0}px;
`;

const TrackCompletionProgressBar = ({
  className,
  margin,
  total,
  completed,
  completedColor,
  hintText,
  completionStatus,
}) => {
  const tooltip = useTooltipUID();
  const plural = completed > 1 ? 's' : '';
  const assignmentComplete = completionStatus === COMPLETED_STATUS;

  if (!total) return null;

  return (
    <Wrapper margin={margin} className={className} {...tooltip.targetProps}>
      <ProgressBar
        completedProgress={(completed / total) * 100}
        completedColor={completedColor}
        renderToolTip={() =>
          assignmentComplete ? (
            <Tooltip id={tooltip.uid} hide={!hintText} maxWidth={238}>
              Completed ({completed} item{plural})
            </Tooltip>
          ) : (
            <Tooltip id={tooltip.uid} hide={!hintText} maxWidth={238}>
              {completed}/{total} completed
            </Tooltip>
          )
        }
      />
      {/* eslint-disable-next-line react/no-adjacent-inline-elements */}
      {hintText && <CompletedSnippets>{hintText}</CompletedSnippets>}
      {!hintText && assignmentComplete ? (
        <CompletedSnippets>
          Completed ({completed} item{plural})
        </CompletedSnippets>
      ) : (
        <CompletedSnippets>
          {completed}/{total} completed
        </CompletedSnippets>
      )}
    </Wrapper>
  );
};

TrackCompletionProgressBar.propTypes = {
  total: PropTypes.number,
  completed: PropTypes.number,
  completedColor: PropTypes.string,
  margin: PropTypes.number,
  className: PropTypes.string,
  hintText: PropTypes.string,
  completionStatus: PropTypes.string,
};

export default TrackCompletionProgressBar;
