import PropTypes from 'prop-types';
import React from 'react';

import { useContentOnSubmitSuccessHandler } from '~/app/catalog/hooks';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import TaskForm from '~/app/task/components/TaskForm';

const TaskFormPage = ({ pageTitle, topBarActionName, backRoute, initialValues, isEdit }) => {
  const form = `task${isEdit ? 'Edit' : 'Create'}Form`;

  const { handleContentOnSubmitSuccessHandler } = useContentOnSubmitSuccessHandler();

  return (
    <>
      <PageTitle title={pageTitle} />
      <TaskForm
        form={form}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        initialValues={initialValues}
        onSubmitSuccessHandler={(result) =>
          handleContentOnSubmitSuccessHandler({
            backRoute,
            isEdit,
            result,
          })
        }
      />
    </>
  );
};

TaskFormPage.propTypes = {
  pageTitle: PropTypes.string,
  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default TaskFormPage;
