import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import actions from '~/app/entities/actions';
import { eventSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import colors from '~/services/colors';
import propTypes from '~/services/prop-types';
import Datetime from '~/app/shared/components/Datetime';
import Text from '~/app/shared/components/Text';
import { STATUS_DONE } from '~/app/shared/constants';
import { SurveyRelationshipEntityType } from '~/app/surveys/constants';
import { getSurveyRelationshipEntityType } from '~/app/surveys/services';
import surveyProptypes from '~/app/surveys/types';
import { get } from 'lodash-es';

const Subheader = styled.div`
  margin-top: 8px;

  > * + * {
    margin-left: 16px;
  }
`;

const SubheaderUI = ({ title, children }) => (
  <Subheader>
    <Text size="h3" primary medium>
      {title}
    </Text>
    <Text size="h5" color={colors.neutral400}>
      {children}
    </Text>
  </Subheader>
);

const EventSubheader = ({ surveyRelationship }) => {
  const [request, { data: event, status }] = useEntities(actions.event.retrieveDetails, null, {
    schema: eventSchema,
  });

  useEffect(() => {
    request(surveyRelationship.event);
  }, []);

  if (status !== STATUS_DONE) {
    return <Subheader />;
  }

  const eventDate = get(event, 'timeslots[0].starts_at_tz_aware');

  return (
    <SubheaderUI title={event.name}>
      <Datetime.Date datetime={eventDate} isOnline={event.is_online} /> ∙ {event.location_name}
    </SubheaderUI>
  );
};

const ContentItemSubheader = ({ surveyRelationship }) => {
  const { related_matter_title: name } = surveyRelationship;

  return <SubheaderUI title={name} />;
};

const SurveyAnswerSubheader = ({ surveyRelationship }) => {
  const type = getSurveyRelationshipEntityType(surveyRelationship);

  /* eslint-disable sonarjs/no-small-switch */
  switch (type) {
    case SurveyRelationshipEntityType.EVENT: {
      return <EventSubheader surveyRelationship={surveyRelationship} />;
    }
    case SurveyRelationshipEntityType.SESSION: {
      return <div>Not Implemented</div>;
    }
    default: {
      return <ContentItemSubheader surveyRelationship={surveyRelationship} />;
    }
  }
};

SubheaderUI.propTypes = {
  title: PropTypes.string,
  children: propTypes.anyChildren,
};

EventSubheader.propTypes = {
  surveyRelationship: surveyProptypes.surveyRelationship,
};

ContentItemSubheader.propTypes = {
  surveyRelationship: surveyProptypes.surveyRelationship,
};

SurveyAnswerSubheader.propTypes = {
  surveyRelationship: surveyProptypes.surveyRelationship,
};

export default SurveyAnswerSubheader;
