import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import actions from '~/app/alerts/actions';
import colors from '~/services/colors';
import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import Loading from '~/app/shared/components/Loading';
import MediaPoint from '~/app/shared/components/MediaPoint';
import Text from '~/app/shared/components/Text';
import { STATUS_DONE } from '~/app/shared/constants';

import AlertFormModal from '../AlertFormModal';

import AlertPageContent from './AlertPageContent';

const AlertPageContainer = styled.div`
  width: 100%;
  padding: 28px 20px 0;

  ${MediaPoint.Tablet} {
    width: 580px;
    margin: 48px auto 0;
    padding: 0;
  }
  ${MediaPoint.DesktopLg} {
    width: 700px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;

  ${MediaPoint.Tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TextWrapper = styled.div`
  flex: 1 0 auto;
`;

const Header = styled(Text)`
  display: block;
`;

const Subheader = styled(Text)`
  display: block;
  margin-top: 8px;
`;

const NewAlertButtonContainer = styled.div`
  align-self: flex-start;
  margin-top: 12px;

  ${MediaPoint.Tablet} {
    align-self: flex-end;
    margin: 0;
  }
`;

const ContentContainer = styled.div`
  margin-top: 24px;
`;

const newAlertInitialValues = {
  frequency: 'weekly',
  type: 'term',
  term: '',
  topic_name: '',
};

export const AlertPage = (props) => {
  const { alerts, alertsStatus, fetchAlerts, deleteAlert } = props;

  const [showAlertModal, setShowAlertModal] = useState(false);
  useEffect(() => {
    fetchAlerts();
  }, []);

  return (
    <React.Fragment>
      <AlertPageContainer>
        <HeaderSection>
          <TextWrapper>
            <Header size="h1" color={colors.neutral400}>
              Alerts
            </Header>
            <Subheader size="h4" color={colors.neutral900}>
              Get notified by email when an event of interest comes up!
            </Subheader>
          </TextWrapper>
          <NewAlertButtonContainer>
            <Button
              size="small"
              onClick={() => setShowAlertModal(true)}
              startIcon={<Icon name="plus" />}
            >
              New Alert
            </Button>
          </NewAlertButtonContainer>
        </HeaderSection>
        <ContentContainer>
          {alertsStatus !== STATUS_DONE ? (
            <Loading />
          ) : (
            <AlertPageContent
              alerts={alerts}
              deleteAlert={deleteAlert}
              handleNewAlertClick={() => setShowAlertModal(true)}
            />
          )}
        </ContentContainer>
      </AlertPageContainer>
      {showAlertModal && (
        <AlertFormModal
          initialValues={newAlertInitialValues}
          handleClose={() => setShowAlertModal(false)}
          onSubmitSuccess={() => setShowAlertModal(false)}
        />
      )}
    </React.Fragment>
  );
};

AlertPage.propTypes = {
  alerts: PropTypes.array,
  alertsStatus: PropTypes.string,

  fetchAlerts: PropTypes.func,
  deleteAlert: PropTypes.func,
};

const mapStateToProps = (state) => ({
  alerts: state.alerts.alerts,
  alertsStatus: state.alerts.alertsStatus,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAlerts: () => dispatch(actions.userAlertListFetchRequestSubmit()),
  deleteAlert: (alertId) => dispatch(actions.userAlertDeleteRequestSubmit({ id: alertId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertPage);
