import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import Loading from '~/app/shared/components/Loading';
import Modal, { ModalBody, ModalFooter } from '~/app/shared/components/Modal';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { size } from 'lodash-es';

import CourseConfigForm from './CourseConfigForm';

const CourseConfigModal = ({ course, handleClose }) => {
  const [fieldValues, setFieldValues] = useState([]);

  const [fetchConfiguration, { status, error }] = useEntities(
    actions.course.retrieveConfiguration,
    ({ status, data: { configurations } }) => {
      if (status === STATUS_DONE) {
        setFieldValues(configurations);
      }
    }
  );

  useEffect(() => {
    fetchConfiguration(course.public_id);
  }, []);

  return (
    <Modal
      title={`Advanced Settings for ${course.name}`}
      handleClose={handleClose}
      width={window.innerWidth > 800 ? 800 : null}
      overflow="hidden"
    >
      <ModalBody fullSize scrollable>
        <CourseConfigForm
          courseId={course.public_id}
          loading={status === STATUS_LOADING}
          requestError={error?.detail}
          fieldValues={fieldValues}
          refreshSettings={() => fetchConfiguration(course.public_id)}
        />
      </ModalBody>
      <ModalFooter variant="links">
        {size(fieldValues) > 0 && status === STATUS_LOADING && <Loading hasMargin={false} />}
      </ModalFooter>
    </Modal>
  );
};

CourseConfigModal.propTypes = {
  course: PropTypes.object,
  handleClose: PropTypes.func,
};

export default CourseConfigModal;
