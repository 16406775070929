import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import colors from '~/services/colors';
import propTypes from '~/services/prop-types';
import { baseButtonStyle, ButtonWrapper } from '~/app/shared/components/Button';
import Text from '~/app/shared/components/Text';
import { isNil } from 'lodash-es';

export const actionButtonStyle = css`
  && {
    ${baseButtonStyle};
    color: ${colors.emphasis600};
    border: solid 2px ${colors.emphasis600};
    margin-left: 8px;

    &:focus,
    &:hover {
      background-color: ${colors.emphasis600};
      color: ${colors.neutral0};
      svg path {
        fill: ${colors.neutral0};
      }
    }

    ${({ disabled }) =>
      disabled &&
      `
      background-color: transparent;
      cursor: not-allowed !important;
      opacity: 0.4;

      &:hover,
      &:focus {
        background-color: transparent;
        color: ${colors.emphasis600};
        opacity: 0.4;
        svg path {
          fill: ${colors.emphasis600};
        }
      }`}
  }
`;

const StyledActionButton = styled(ButtonWrapper)`
  ${actionButtonStyle};
  ${({ width }) => width && `width: ${width}px`};
`;

export const ActionButton = ({ targetBlank, ...props }) => (
  <StyledActionButton size="medium" target={targetBlank ? '_blank' : '_self'} {...props} />
);

ActionButton.propTypes = {
  targetBlank: PropTypes.bool,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 20px;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChildrenContainer = styled.div`
  display: flex;
`;

const ActionsBar = ({
  resourceName,
  disableCounter,
  totalCount,
  children,
  isFiltered,
  isLoading,
  subTitle,
}) => {
  const getText = () => {
    if (isLoading) return '';

    const suffix = totalCount === 1 ? 'result' : 'results';

    if (isFiltered && !disableCounter) return `Showing ${totalCount} ${suffix}`;
    if (disableCounter) return `Showing All ${resourceName}`;

    return `Showing all ${resourceName} - ${totalCount} ${suffix}`;
  };

  return (
    <Container>
      <InnerContainer>
        <Text size="h4" medium={!isNil(subTitle)}>
          {getText()}
        </Text>
        {subTitle && <Text size="h5">{subTitle}</Text>}
      </InnerContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

ActionsBar.propTypes = {
  resourceName: PropTypes.string,
  disableCounter: PropTypes.bool,
  totalCount: PropTypes.number,
  children: propTypes.anyChildren,
  isFiltered: PropTypes.bool,
  isLoading: PropTypes.bool,
  subTitle: PropTypes.string,
};

ActionsBar.defaultProps = {
  resourceName: 'Items',
};

export default ActionsBar;
