import moment from 'moment-timezone';
import { lazy } from 'react';

import {
  toLower,
  startsWith,
  isString,
  map,
  escapeRegExp,
  startCase,
  replace,
  toString,
  includes,
  reduce,
} from 'lodash-es';

export function scrollTo(options) {
  window.scrollTo(options);
}

/**
 * Scroll to an element with a given id
 *
 * @param {string} id - Element id
 * @param {ScrollToOptions} options - Scroll options
 */
export function scrollToElement(id, options = {}) {
  const el = document.getElementById(id);
  const top = el?.offsetTop - options.top;
  scrollTo({ top, behavior: options.behavior });
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

function s4() {
  const randomDigits = Math.trunc((1 + Math.random()) * 0x1_00_00);
  return randomDigits.toString(16).slice(1);
}

export function generateGuid() {
  const guid = toLower(`${s4()}${s4()}-${s4()}-4${s4().slice(0, 3)}-${s4()}-${s4()}${s4()}${s4()}`);
  return guid;
}

// From https://github.com/twbs/bootstrap/blob/639c405c6510a286a3cfcfd6d733d28d0e7baf92/dist/js/bootstrap.js#L105
// Generates UID for DOM elements, useful for tooltips
const MAX_UID = 10_000_000;
export function getUID() {
  let uid = 'a';
  do {
    // eslint-disable-next-line no-bitwise
    uid += Math.trunc(Math.random() * MAX_UID); // "~~" acts like a faster Math.floor() here
  } while (document.getElementById(uid));

  return uid;
}

export function getUrlParser(url) {
  // REMEMBER: this will escape characters such as blank space.
  // use unescape() method on the properties you want, if necessary

  const parser = document.createElement('a');
  parser.href = url;
  return parser;

  // example:

  // parser.href = "http://example.com:3000/pathname/?search=test#hash";
  // parser.protocol; => "http:"
  // parser.hostname; => "example.com"
  // parser.port;     => "3000"
  // parser.pathname; => "/pathname/"
  // parser.search;   => "?search=test"
  // parser.hash;     => "#hash"
  // parser.host;     => "example.com:3000"
}

export function parseNumber(string) {
  if (string === '') return string;

  return Number(string);
}

export function getOrdinalNumber(number) {
  const j = number % 10;
  const k = number % 100;

  if (j === 1 && k !== 11) {
    return `${number}st`;
  }
  if (j === 2 && k !== 12) {
    return `${number}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${number}rd`;
  }

  return `${number}th`;
}

export function isHttpsUrl(string) {
  if (!startsWith(string, 'https://')) {
    return false;
  }

  const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

  const localhostDomainRE = /^localhost[\d:?]*(?:[^\d:?]\S*)?$/;
  const nonLocalhostDomainRE = /^[^\s.]+\.\S{2,}$/; // eslint-disable-line no-useless-escape

  if (!isString(string)) {
    return false;
  }

  const match = string.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (
    localhostDomainRE.test(everythingAfterProtocol) ||
    nonLocalhostDomainRE.test(everythingAfterProtocol)
  ) {
    return true;
  }

  return false;
}

export function addTrailingSlashToUrl(url) {
  return url.at(-1) === '/' ? url : `${url}/`;
}

export function sendExceptionToSentry(error, errorInfo = {}) {
  if (window.Sentry) {
    window.Sentry.withScope((scope) => {
      scope.setExtra('errorInfo', errorInfo);
      window.Sentry.captureException(error);
    });
  }
}

/**
 * Borrowed from https://github.com/prakhar1989/react-tags/blob/master/src/components/utils.js
 *
 * Convert an array of delimiter characters into a regular expression
 * that can be used to split content by those delimiters.
 * @param {Array<char>} delimiters Array of characters to turn into a regex
 * @returns {RegExp} Regular expression
 */
export function buildRegExpFromDelimiters(delimiters) {
  const delimiterChars = map(delimiters, (delimiter) => {
    // See: http://stackoverflow.com/a/34711175/1463681
    const chrCode = delimiter - 48 * Math.floor(delimiter / 48);
    return String.fromCharCode(delimiter >= 96 ? chrCode : delimiter);
  }).join('');
  const escapedDelimiterChars = escapeRegExp(delimiterChars);
  return new RegExp(`[${escapedDelimiterChars}]+`);
}

export function getUserImage(user, fallbackImage = '') {
  return user.profile_image || fallbackImage;
}

export function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp([...a].join('|'), 'g');
  const baseString = toLower(toString(string));
  const patternsAndReplacements = [
    [/\s+/g, '-'], // Replace spaces with -
    [p, (c) => b.charAt(a.indexOf(c))], // Replace special characters
    [/&/g, '-and-'], // Replace & with 'and'
    [/[^\w-]+/g, ''], // Remove all non-word characters
    [/--+/g, '-'], // Replace multiple - with single -
    [/^-+/, ''], // Trim - from start of text
    [/-+$/, ''], // Trim - from end of text
  ];
  return reduce(
    patternsAndReplacements,
    (acc, [pattern, replacement]) => replace(acc, pattern, replacement),
    baseString
  );
}

// The following excerpt was taken from:
// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
// and it's a way of fixing the ChunkLoadError issue:
// https://sentry.io/organizations/plusplusco/issues/2481348798/?project=139746&referrer=asana_plugin
export const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

// Taken from https://stackoverflow.com/questions/34979051/find-next-instance-of-a-given-weekday-ie-monday-with-moment-js
export const getNextInstanceOfGivenWeekday = (startsAt) => {
  const dayINeed = startsAt.isoWeekday();
  const today = moment().isoWeekday();

  // if we haven't yet passed the day of the week that I need:
  if (today <= dayINeed) {
    // then just give me this week's instance of that day
    return moment().isoWeekday(dayINeed);
  }
  // otherwise, give me *next week's* instance of that same day
  return moment().add(1, 'weeks').isoWeekday(dayINeed);
};

export const isGoogleMeetLink = (link) => {
  return isString(link) && includes(link, 'meet.google');
};

export const titleCase = (text) => {
  return startCase(toLower(replace(text, '_', ' ')));
};

export const isUUID = (s) => {
  const uuidRegEx = /([\da-f]{8}\b(?:-[\da-f]{4}){3}-\b[\da-f]{12})/g;
  return s.match(uuidRegEx) !== null;
};

export const getNextHalfHour = () => {
  const now = moment();
  if (now.minutes() < 30) {
    // If XX:00-XX:29, round to XX:30
    now.startOf('hour').minutes(30);
  } else {
    // If XX:30-XX:59, round to XX+1:00
    now.startOf('hour').add(1, 'hour');
  }

  return now;
};
