import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { mapRoute } from '~/services/requests';
import Button from '~/app/shared/components/Button';
import Icon from '~/app/shared/components/Icon';
import MediaPoint from '~/app/shared/components/MediaPoint';
import SectionInfoPanel from '~/app/shared/components/NewContentForm/SectionInfoPanel';
import PageTitle from '~/app/shared/components/PageTitle';
import { useLabels } from '~/app/shared/hooks';
import ReactMarkdown from '~/vendor/react-markdown';

import MentorshipRequestList from './MentorshipRequestList';

const PageContainer = styled.div`
  padding: 0 16px;
`;

const Container = styled.div`
  margin: 32px auto 0;

  > * + * {
    margin-top: 24px;
  }

  ${MediaPoint.DesktopSm} {
    width: 960px;
  }
`;

const MyMentorshipRequests = ({ viewMode }) => {
  const {
    label_mentorship_tab: labelMentorshipApp,
    label_cta_mentorship_requests_page_tip: labelCtaMentorshipRequestsPageTip,
  } = useLabels();

  const HCArticleURL = 'https://help.plusplus.app/en/articles/5327150-how-mentorship-requests-work';

  return (
    <>
      <PageTitle title={`My ${labelMentorshipApp} Messages`} />

      <PageContainer>
        <Container>
          <SectionInfoPanel
            title={`My ${labelMentorshipApp} Messages`}
            HCArticleURL={HCArticleURL}
            blockHelpLink
            infoText={
              <ReactMarkdown linkTarget="_blank">{labelCtaMentorshipRequestsPageTip}</ReactMarkdown>
            }
            renderRightSection={() => (
              <Button route={mapRoute('mySessions')} startIcon={<Icon name="external" />}>
                My sessions
              </Button>
            )}
          />
          <MentorshipRequestList viewMode={viewMode} />
        </Container>
      </PageContainer>
    </>
  );
};

MyMentorshipRequests.propTypes = {
  viewMode: PropTypes.string,
};

export default MyMentorshipRequests;
