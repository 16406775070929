import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AttendanceActionManager from '~/app/event-shared/components/AttendanceActionManager';
import { Button } from '~/app/shared';
import Pill from '~/app/shared/components/Pill';

import { CheckinActionButtons } from './CheckinActionButtons';
import { EnrolledActionButtons } from './EnrolledActionButtons';
import { FullCapacityActionButtons } from './FullCapacityActionButtons';
import { ProspectActionButtons } from './ProspectActionButtons';
import { RatingActionButtons } from './RatingActionButtons';
import { WaitlistActionButtons } from './WaitlistActionButtons';

const PillWrapper = styled.div`
  margin-left: 0px;
`;

const EventActionButtons = ({ content: event }) => {
  return (
    <AttendanceActionManager
      event={event}
      renderProspect={(actions, status) => (
        <ProspectActionButtons actions={actions} status={status} />
      )}
      renderEnrolled={(actions, status, spotStatus) => (
        <EnrolledActionButtons actions={actions} status={status} spotStatus={spotStatus} />
      )}
      renderWaitlist={(actions, status, spotStatus) => (
        <WaitlistActionButtons actions={actions} status={status} spotStatus={spotStatus} />
      )}
      renderCheckin={({ event, onEnrollmentStatusChange }) => (
        <CheckinActionButtons event={event} onEnrollmentStatusChange={onEnrollmentStatusChange} />
      )}
      renderRating={() => <RatingActionButtons event={event} />}
      renderEnrollmentClosed={() => (
        <PillWrapper>
          <Pill variant="lighterGrayWithDarkerTextColor" label="Enrollment closed" icon="cancel" />
        </PillWrapper>
      )}
      renderUncancellableEnroll={() => (
        <Button title="You are enrolled in the event and cancellation is closed" disabled>
          Drop
        </Button>
      )}
      renderFullCapacity={() => <FullCapacityActionButtons event={event} />}
      renderHasAttended={() => (
        <Pill icon="checkbox-round" variant="success200" label="You are checked in!" />
      )}
    />
  );
};

EventActionButtons.propTypes = {
  content: PropTypes.object,
};

export default EventActionButtons;
