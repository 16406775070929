import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';

import Button from '~/app/shared/components/Button';
import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { canBeDropped } from '~/app/assignments/services';
import { CONTENT_TYPES, DROP_TRACK_ITEM_NOT_GRANTED_EXCEPTION_NAME } from '~/app/catalog/constants';
import { Course } from '~/app/course/interfaces';
import { useAssignmentActionsCtx } from '~/features/contentitems/contexts/AssignmentActionsCtx';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Datetime from '~/app/shared/components/Datetime';
import Pill from '~/app/shared/components/Pill';
import { useToggles, useIsPreviewQueryParam } from '~/app/shared/hooks';
import DropTrackItemWarnModal from '~/app/stand-alone-shared/components/DropTrackItemWarnModal';
import { includes, get, map, size, isEmpty } from 'lodash-es';
import { BookmarkBorderOutlined, HighlightOffIcon } from '~/vendor/mui-icons';

import OldSecondaryActionButton from '../OldSecondaryActionButton';

const Container = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 12px;
  }
`;

const DateContainer = styled.div`
  flex: 1 0 auto;

  line-height: 18px;
`;

type DropTrackItemConflictInfo = {
  dependents: ContentItem[];
};

interface SecondaryActionButtonProps {
  content: ContentItem;
  contentType?: string;
}

const SecondaryActionButton = (props: SecondaryActionButtonProps) => {
  const { content, contentType: learningType } = props;

  const { dev_toggle_content_assignments_2_0: devToggleContentAssignment2 } = useToggles();

  const [dropTrackItemConflictInfo, setDropTrackItemConflictInfo] =
    useState<DropTrackItemConflictInfo | null>(null);
  const showDropTrackItemWarnModal = dropTrackItemConflictInfo != null;

  const { content_type: contentType, is_completable: isCompletable } = content;
  const isAssessment = contentType === CONTENT_TYPES.assessment;
  const isCourse = contentType === CONTENT_TYPES.course;
  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  const assignment = content?.user_assignment || content?.assignment;
  const assignmentState = get(assignment, 'state');

  const { create, drop, undoCompletion, isLoading } = useAssignmentActionsCtx();

  const isPreviewMode = useIsPreviewQueryParam();

  const handleDrop = () => {
    drop(
      {},
      {
        onError(error) {
          const dropError = get(error, 'data.exception');

          if (dropError === DROP_TRACK_ITEM_NOT_GRANTED_EXCEPTION_NAME) {
            setDropTrackItemConflictInfo({
              dependents: get(error, 'data.dependents', []),
            });
          }
        },
      }
    );
  };

  const handleCloseDropTrackItemWarnModal = () => {
    setDropTrackItemConflictInfo(null);
  };

  if (!devToggleContentAssignment2) {
    return <OldSecondaryActionButton content={content} contentType={learningType || ''} />;
  }

  if (
    includes(
      [CONTENT_TYPES.event, CONTENT_TYPES.track, CONTENT_TYPES.scheduled_track],
      contentType
    ) ||
    (!isCompletable &&
      includes(
        [
          CONTENT_TYPES.article,
          CONTENT_TYPES.program,
          CONTENT_TYPES.video,
          CONTENT_TYPES.linkedcontent,
        ],
        contentType
      )) ||
    (assignmentState === ASSIGNMENT_STATES.completed && isAssessment)
  )
    return null;

  if (assignmentState === ASSIGNMENT_STATES.completed && !isPreviewMode) {
    return (
      <Container>
        <ButtonLink onClick={undoCompletion} variant="error" disabled={isLoading}>
          Undo
        </ButtonLink>
      </Container>
    );
  }

  if (canBeDropped(assignment)) {
    // The code is dealing with both formats, because the old content item endpoint is still
    // returning the assignment in the old format, as long as the migration to CA.2.0 is not
    // done completely, we have to deal with these two formats.
    const dueDate = get(assignment, 'due_date') || get(assignment, 'due_at');
    const isOverdue = dueDate ? moment().isAfter(moment(dueDate).endOf('day')) : false;
    const formattedDueDate = dueDate ? Datetime.Date({ datetime: dueDate }) : '';

    return (
      <Container>
        {!dueDate && (
          <>
            <Button onClick={handleDrop} startIcon={<HighlightOffIcon />} disabled={isLoading}>
              Drop
            </Button>

            {showDropTrackItemWarnModal && (
              <DropTrackItemWarnModal
                handleClose={handleCloseDropTrackItemWarnModal}
                unassign={() => drop({ force: true })}
                content={content}
                tracks={map(get(dropTrackItemConflictInfo, 'dependents', []), 'content_item')}
              />
            )}
          </>
        )}
        {dueDate && (
          <DateContainer>
            {isOverdue ? (
              <Pill icon="exclamation" label={formattedDueDate} variant="error200" size="medium" />
            ) : (
              <Pill
                icon="clock-filled"
                label={formattedDueDate}
                variant="neutral200"
                size="medium"
              />
            )}
          </DateContainer>
        )}
      </Container>
    );
  }

  const isSaveButtonDisabled =
    isLoading ||
    (isAssessment && !size(content?.track_items)) ||
    (isCourse && isEmpty((content as Course)?.scorm_cloud_id));

  return (
    <Container>
      <Button
        onClick={() => create()}
        disabled={isSaveButtonDisabled}
        startIcon={<BookmarkBorderOutlined />}
      >
        Save
      </Button>
    </Container>
  );
};

export default SecondaryActionButton;
