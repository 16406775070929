import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import Button from '~/app/shared/components/Button';
import Loading from '~/app/shared/components/Loading';
import { Stack } from '@mui/material';
import { ConnectWithoutContactIcon, PersonPinCircleIcon } from '~/vendor/mui-icons';

const Container = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 12px;
  }

  > :last-child {
    margin-right: 0;
  }
`;

const ActionButtons = ({ actions, status }) => {
  const {
    performJoinLocalWaitlist,
    performJoinOnlineWaitlist,
    performLocalEnrollment,
    performOnlineEnrollment,
  } = actions;
  const { showLocalWaitlist, showOnlineWaitlist, showLocalEnroll, showOnlineEnroll } = status;

  const [isEnrolling, setIsEnrolling] = useState(false);

  const enrollOnline = () => {
    setIsEnrolling(true);
    performOnlineEnrollment();
  };

  const enrollLocal = () => {
    setIsEnrolling(true);
    performLocalEnrollment();
  };

  const joinOnlineWaitlist = () => {
    setIsEnrolling(true);
    performJoinOnlineWaitlist();
  };

  const joinLocalWaitlist = () => {
    setIsEnrolling(true);
    performJoinLocalWaitlist();
  };

  const canEnrollAny =
    showLocalEnroll || showOnlineEnroll || showLocalWaitlist || showOnlineWaitlist;

  if (!canEnrollAny) {
    return (
      <Button variant="contained" type="button" disabled>
        Full
      </Button>
    );
  }

  if (isEnrolling) {
    return <Loading hasMargin={false} size={12} />;
  }

  return (
    <Stack direction="row" spacing="12px">
      {showLocalEnroll && (
        <Button
          variant="contained"
          onClick={() => enrollLocal()}
          startIcon={<PersonPinCircleIcon />}
        >
          Enroll In-Person
        </Button>
      )}
      {showLocalWaitlist && (
        <Button
          variant="contained"
          onClick={() => joinLocalWaitlist()}
          startIcon={<PersonPinCircleIcon />}
        >
          Waitlist In-Person
        </Button>
      )}
      {showOnlineEnroll && (
        <Button
          variant="contained"
          onClick={() => enrollOnline()}
          startIcon={<ConnectWithoutContactIcon />}
        >
          Enroll Online
        </Button>
      )}
      {showOnlineWaitlist && (
        <Button
          variant="contained"
          onClick={() => joinOnlineWaitlist()}
          startIcon={<ConnectWithoutContactIcon />}
        >
          Waitlist Online
        </Button>
      )}
    </Stack>
  );
};

ActionButtons.propTypes = {
  actions: PropTypes.object,
  status: PropTypes.object,
};

export const ProspectActionButtons = (props) => {
  const { actions, status } = props;

  return (
    <Container>
      <ActionButtons actions={actions} status={status} />
    </Container>
  );
};

ProspectActionButtons.propTypes = {
  actions: PropTypes.object,
  status: PropTypes.object,
};
