import { ContentState, convertToRaw } from 'draft-js';
import moment from 'moment';

import { TextReader } from '~/app/inputs/components/LazyTextEditor';
import colors from '~/services/colors';
import { formatDuration } from '~/services/datetime';
import { TrackItemCardList } from '~/app/tracks/components/TrackItemCardList';
import { TrackSectionCircularProgress } from '~/app/tracks/components/TrackSectionProgress';
import { FullTrackSection, trackTypes } from '~/app/tracks/interfaces';
import { reduce } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Box } from '@mui/material';

interface SectionCardHeaderProps {
  section: FullTrackSection;
  userIsAssignedToTrack: boolean;
}

const SectionCardHeader = ({ section, userIsAssignedToTrack }: SectionCardHeaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: 300,
      }}
    >
      <Typography sx={{ fontSize: 18, fontWeight: 'bold' }} noWrap>
        {section.name}
      </Typography>
      <TrackSectionCircularProgress
        section={section}
        userIsAssignedToTrack={userIsAssignedToTrack}
      />
    </Box>
  );
};

interface SectionCardDescriptionProps {
  section: FullTrackSection;
}

const SectionCardDescription = ({ section }: SectionCardDescriptionProps) => {
  const { items: trackItems, description } = section;

  const getDuration = (): string => {
    const duration: moment.Duration = reduce(
      trackItems,
      (acc, { content_item: contentItem }) => {
        const d = moment.duration(contentItem.duration);
        return acc.add(d);
      },
      moment.duration(0)
    );

    const durationAsString: string = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');

    return formatDuration(durationAsString);
  };

  const parsedDescription = description
    ? JSON.parse(description)
    : convertToRaw(ContentState.createFromText('No description'));
  const duration = getDuration();

  return (
    <>
      <Typography sx={{ fontSize: 14, color: colors.neutral900 }}>
        {duration ?? <span>&#8203;</span>}
      </Typography>

      <Typography
        sx={{
          fontSize: 14,
          color: colors.neutral500,
          marginTop: -1,
        }}
        collapsable
        collapsedSize={80}
        maxLines={4}
        noWrap
      >
        <TextReader value={parsedDescription} />
      </Typography>
    </>
  );
};

interface SectionCardProps {
  section: FullTrackSection;
  getTrackItemIndex: (trackId: string, contentItemId: string) => number;
  cardsPerPage: number;
  cardsCount: number;
  userIsAssignedToTrack: boolean;
  isPreview: boolean;
}

export const SectionCard = ({
  section,
  getTrackItemIndex,
  cardsPerPage,
  cardsCount,
  userIsAssignedToTrack,
  isPreview,
}: SectionCardProps) => {
  const { items: trackItems, track_type } = section;

  const getCardWidth = (): string => {
    if (cardsPerPage <= cardsCount) {
      return `${100 / cardsPerPage}%`;
    }
    return `${100 / cardsCount}%`;
  };

  const pageSize = 5;
  const cardWidth = getCardWidth();

  return (
    <Box paddingX={1.25} width={cardWidth}>
      <Box marginBottom={2} minHeight="150px">
        <SectionCardHeader section={section} userIsAssignedToTrack={userIsAssignedToTrack} />
        <SectionCardDescription section={section} />
      </Box>
      <TrackItemCardList
        items={trackItems}
        getTrackItemIndex={getTrackItemIndex}
        pageSize={pageSize}
        isSummary
        trackType={track_type as trackTypes}
        isPreview={isPreview}
      />
    </Box>
  );
};
