import PropTypes from 'prop-types';
import React from 'react';

import { filter, map, get } from 'lodash-es';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { KeyboardArrowDownIcon } from '~/vendor/mui-icons';

const SplitButton = ({
  label,
  onClick,
  type,
  secondaryActionOptionList,
  color,
  onClickExpand,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (callback, event, index) => {
    setOpen(false);
    callback(event, index);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const visibleSecondaryActionOptionList = filter(
    secondaryActionOptionList,
    ({ hidden }) => !hidden
  );

  const onMainClick = onClickExpand ? handleToggle : onClick;
  const { variant: mainButtonVariant, disabled } = props;

  return (
    <Box>
      <ButtonGroup variant="contained" color={color} ref={anchorRef} aria-label="split button">
        <Button type={type} onClick={onMainClick} {...props}>
          {label}
        </Button>
        {visibleSecondaryActionOptionList?.length > 0 && (
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="Settings"
            aria-haspopup="menu"
            onClick={handleToggle}
            variant={mainButtonVariant}
            disabled={disabled}
          >
            <KeyboardArrowDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        sx={{ zIndex: 9999 }} // This will force the options to be above the modal's paper.
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {map(
                    filter(visibleSecondaryActionOptionList, ({ hidden }) => !hidden),
                    ({ label, startIcon, onClick, disabled, ...props }, index) => (
                      <MenuItem
                        disableRipple
                        key={label}
                        disabled={disabled}
                        onClick={(event) => handleMenuItemClick(onClick, event, index)}
                        {...props}
                      >
                        {startIcon && (
                          <ListItemIcon sx={{ color: get(props, 'sx.color') }}>
                            {startIcon}
                          </ListItemIcon>
                        )}
                        <ListItemText primary={label} />
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

SplitButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  onClickExpand: PropTypes.bool, // Set the main button to expand the options
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  secondaryActionOptionList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      hidden: PropTypes.bool,
    })
  ),
};

SplitButton.defaultProps = {
  color: 'primary',
  onClickExpand: false,
};

export default SplitButton;
