import { get } from 'lodash-es';

import { decryptObject, encryptObject } from './encryption';

export function getStickyFiltersFromUser(stickyFiltersObject, userId, userKey) {
  const encryptedUserStickyFiltersObject = get(stickyFiltersObject, userId);
  if (!encryptedUserStickyFiltersObject) {
    return null;
  }
  try {
    return decryptObject(encryptedUserStickyFiltersObject, userKey);
  } catch {
    return {};
  }
}

export function formatStickyFilters(
  stickyFiltersObject,
  filterPageName,
  newFilters,
  userEncryptionKey
) {
  return encryptObject(
    {
      ...stickyFiltersObject,
      [filterPageName]: newFilters,
    },
    userEncryptionKey
  );
}
