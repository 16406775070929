import PropTypes from 'prop-types';
import React, { useState } from 'react';

import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import { STATUS_DONE, STATUS_LOADING, STATUS_LOADING_MORE } from '~/app/shared/constants';
import { useCurrentUser } from '~/app/shared/hooks';
import { map, includes } from 'lodash-es';

import AutocompleteField from '../AutocompleteField';

const TopicsInputField = ({ tagType, creatable, ...props }) => {
  const [options, setOptions] = useState([]);
  const { has_allowed_topics: hasAllowedTopics } = useCurrentUser();
  const [fetch, { status }] = useEntities(actions.topic.retrieveList, ({ status, data }) => {
    if (status === STATUS_DONE) {
      setOptions(
        map(data.results, ({ name, value }) => ({
          id: value,
          value: name,
          label: name,
        }))
      );
    }
  });

  const loading = includes([STATUS_LOADING, STATUS_LOADING_MORE], status);

  const fetchOptionsCb = ({ q, includeValues }) => {
    fetch(
      {
        view_mode: 'filter_options',
        page_size: 50,
        include_slugs: includeValues || undefined,
        tag_type: tagType,
        q: q || undefined,
      },
      { skipSchema: true }
    );
  };

  return (
    <AutocompleteField
      options={options}
      loading={loading}
      fetchOptions={fetchOptionsCb}
      creatable={!hasAllowedTopics && creatable}
      {...props}
      input={{
        ...props.input,
        onChange: (...a) => {
          props.input.onChange(...a);
        },
      }}
    />
  );
};

TopicsInputField.defaultProps = {
  multiple: true,
};

TopicsInputField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  multiple: PropTypes.bool,
  creatable: PropTypes.bool,
  tagType: PropTypes.string,
};

export default TopicsInputField;
