import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import Text from '~/app/shared/components/Text';
import { SurveyQuestionKind } from '~/app/surveys/constants';
import surveyProptypes from '~/app/surveys/types';

import SurveyQuestionLinearScale from './SurveyQuestionLinearScale';
import SurveyQuestionMultipleSelection from './SurveyQuestionMultipleSelection';
import SurveyQuestionMultipleSelectionOther from './SurveyQuestionMultipleSelectionOther';
import SurveyQuestionRatingScale from './SurveyQuestionRatingScale';
import SurveyQuestionShortText from './SurveyQuestionShortText';
import SurveyQuestionSingleSelection from './SurveyQuestionSingleSelection';
import SurveyQuestionSingleSelectionOther from './SurveyQuestionSingleSelectionOther';
import SurveyQuestionTextArea from './SurveyQuestionTextArea';

const SurveyQuestionKindComponentMap = {
  [SurveyQuestionKind.SHORT_TEXT]: SurveyQuestionShortText,
  [SurveyQuestionKind.PARAGRAPH_TEXT]: SurveyQuestionTextArea,
  [SurveyQuestionKind.SINGLE_CHOICE]: SurveyQuestionSingleSelection,
  [SurveyQuestionKind.SINGLE_CHOICE_AND_OTHER]: SurveyQuestionSingleSelectionOther,
  [SurveyQuestionKind.RATING_SCALE]: SurveyQuestionRatingScale,
  [SurveyQuestionKind.LINEAR_SCALE]: SurveyQuestionLinearScale,
  [SurveyQuestionKind.MULTIPLE_CHOICE]: SurveyQuestionMultipleSelection,
  [SurveyQuestionKind.MULTIPLE_CHOICE_AND_OTHER]: SurveyQuestionMultipleSelectionOther,
};

const QuestionContainer = styled.div`
  margin: 20px -12px 0;
`;

const BoxContainer = styled.div`
  border: 1px solid ${({ hasError }) => (hasError ? colors.error600 : colors.neutral0)};
  padding: 12px;
`;

const ErrorMessageContainer = styled.div`
  margin-top: 4px;
`;

const Description = styled(Text)`
  margin-top: 4px;
`;

const getSurveyQuestionComponent = (kind) => SurveyQuestionKindComponentMap[kind];

const SurveyQuestionField = ({ input, meta, question }) => {
  const Component = getSurveyQuestionComponent(question.kind);
  const hasError = meta.touched && meta.error;

  return (
    <QuestionContainer>
      <BoxContainer hasError={hasError}>
        <Text size="h3" color={colors.neutral600} medium block>
          {question.text}{' '}
          {question.is_required && (
            <Text size="h3" color={colors.error600}>
              *
            </Text>
          )}
        </Text>
        {question.description && (
          <Description size="h5" color={colors.neutral600}>
            {question.description}
          </Description>
        )}
        <Component input={input} meta={meta} question={question} />
      </BoxContainer>
      {hasError && (
        <ErrorMessageContainer>
          <Text size="h5" color={colors.error600}>
            {meta.error}
          </Text>
        </ErrorMessageContainer>
      )}
    </QuestionContainer>
  );
};

SurveyQuestionField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  question: surveyProptypes.surveyQuestion,
};

export default SurveyQuestionField;
