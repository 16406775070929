import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useContentOnSubmitSuccessHandler } from '~/app/catalog/hooks';
import EventForm from '~/app/event-shared/components/EventForm';
import { getFacilitatorsIds, getEventTypeEditableField } from '~/app/event-shared/services';
import EventTypeApplyToEventsModal from '~/app/event-types/components/EventTypeApplyToEventsModal';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { queries } from '~/queries';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import { INITIAL_DURATIONS } from '~/app/shared/constants';
import { useCurrentUser, useLabels } from '~/app/shared/hooks';
import { sortCustomTags } from '~/app/topics/services';
import { get, has, isEmpty, omit } from 'lodash-es';

const getInitialValues = ({ currentUser, eventType, clonedEventType, defaultChannelId }) => {
  let initialValues = {
    is_local: false,
    is_online: false,
    is_completable: true,
    ...eventType,
  };

  if (clonedEventType) {
    const { organizerId, presentersIds, coOrganizersIds } = getFacilitatorsIds(clonedEventType);

    initialValues = omit(
      {
        ...clonedEventType,
        name: `Duplicate of ${clonedEventType.name}`,
        organizer_id: organizerId,
        presenters_ids: presentersIds,
        co_organizers_ids: coOrganizersIds,
      },
      ['public_id', 'public_id_and_slug']
    );
  } else if (eventType) {
    const { organizerId, presentersIds, coOrganizersIds } = getFacilitatorsIds(eventType);
    const { duration: eventTypeDuration } = eventType;

    initialValues.organizer_id = organizerId;
    initialValues.presenters_ids = presentersIds;
    initialValues.co_organizers_ids = coOrganizersIds;
    initialValues.duration = eventTypeDuration === '00:00:00' ? null : eventTypeDuration;
  }

  // Facilitators
  if (!has(initialValues, 'organizer_id')) {
    initialValues.organizer_id = currentUser.id;
  }

  // If initialValues doesn't have a value for is_hidden, set the default as public
  if (!has(initialValues, 'is_hidden')) {
    initialValues.is_hidden = false;
  }

  if (!has(initialValues, 'hide_enrollees')) {
    initialValues.hide_enrollees = false;
  }

  // Waitlist
  if (initialValues.wait_list_limit || initialValues.online_wait_list_limit) {
    initialValues.has_wait_list = true;
  }

  // External registration
  if (initialValues.external_link_description || initialValues.external_link) {
    initialValues.has_external_registration = true;
  }

  // Default duration for Event Type is 1h
  if (!has(initialValues, 'duration')) {
    initialValues.duration = INITIAL_DURATIONS.eventType;
  }

  if (!initialValues.channel_id && defaultChannelId) {
    initialValues.channel_id = defaultChannelId;
  }

  const customTagsInitialValues = sortCustomTags(currentUser, initialValues, 'tags');

  initialValues = { ...initialValues, ...customTagsInitialValues };

  return initialValues;
};

const submitWithConfirmationHandler = ({ proceedToSubmit }) => proceedToSubmit();

const EventTypeFormPage = ({
  pageTitle,
  topBarActionName,
  backRoute,
  breadcrumbsItemList,
  initialValuesParams,
  initialValuesParams: { eventType },
}) => {
  const history = useHistory();
  const currentUser = useCurrentUser();
  const { default_channel_id: defaultChannelId } = currentUser;

  const { handleContentOnSubmitSuccessHandler } = useContentOnSubmitSuccessHandler();

  const [initialValuesState] = useState(
    getInitialValues({
      currentUser,
      defaultChannelId,
      ...initialValuesParams,
    })
  );

  const isEdit = Boolean(eventType);
  const form = `eventType${isEdit ? 'Edit' : 'Create'}Form`;
  // TODO: Investigate why adding the 'settings' via getInitialValues was not working
  const initialValues = {
    ...initialValuesState,
    ...(!isEmpty(initialValuesParams?.settings) ? { settings: initialValuesParams?.settings } : {}),
  };

  // Handles propagate to existing events after edit
  const [showPropagateModal, setShowPropagateModal] = useState(false);
  const [redirectWhenPropagateModalCloses, setRedirectWhenPropagateModalCloses] = useState(false);

  useEffect(() => {
    if (!showPropagateModal && redirectWhenPropagateModalCloses) {
      history.push(backRoute);
    }
  }, [showPropagateModal, redirectWhenPropagateModalCloses]);

  const onPropagateHandler = () => {
    setShowPropagateModal(true);
    setRedirectWhenPropagateModalCloses(true);
  };

  const { label_event_type: labelEventType } = useLabels();

  // Make sure to add support for whatever fields you add here in
  // services.tasks.event_type_tasks.propagate_event_type_changes and write automated tests for them
  const editableFields = getEventTypeEditableField(initialValues);

  const handleApplyToSubmit = async (fields, propagation) => {
    const url = ApiURLs['api_eventtypes:apply_changes']();
    const data = { event_type_id: initialValues.public_id, fields, propagation };
    await fetchURL(url, { method: 'POST', body: JSON.stringify(data) });

    toast.success('Success!', 'The selected events will update in a few minutes!');
  };

  const queryClient = useQueryClient();

  const handleInvalidateEventTypeQuery = () => {
    const publicId = get(eventType, 'public_id');
    if (!publicId) {
      return;
    }

    queryClient.invalidateQueries({ queryKey: queries.event_types.detail(publicId).queryKey });
  };

  return (
    <>
      <PageTitle title={pageTitle} />
      <EventForm
        isEventType
        form={form}
        settingsContext="event_type/form"
        currentUser={currentUser}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        submitWithConfirmationHandler={submitWithConfirmationHandler}
        onSubmitSuccessHandler={(result, saveAndApply) => {
          if (isEdit) {
            handleInvalidateEventTypeQuery();
          }

          if (isEdit && saveAndApply) {
            toast.success(
              `${labelEventType} modified successfully!`,
              'Please choose if you want to apply changes to events.'
            );
            onPropagateHandler();
          } else {
            handleContentOnSubmitSuccessHandler({
              backRoute,
              isEdit,
              result,
            });
          }
        }}
      />
      {showPropagateModal && (
        <EventTypeApplyToEventsModal
          eventFields={editableFields}
          handleClose={() => setShowPropagateModal(false)}
          handleSubmit={handleApplyToSubmit}
        />
      )}
    </>
  );
};

EventTypeFormPage.defaultProps = {
  initialValuesParams: {},
};

EventTypeFormPage.propTypes = {
  pageTitle: PropTypes.string,
  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),
  initialValuesParams: PropTypes.object,
};

export default EventTypeFormPage;
