import { useComposableTrackCtx } from '~/features/tracks/contexts/ComposableTrackCtx';
import colors from '~/services/colors';
import CardCarousel from '~/app/shared-cards/components/CardCarousel';
import { Track } from '~/app/tracks/interfaces';
import { getTrackSectionsAndItemsCountText } from '~/app/tracks/services';
import { isNil } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Box } from '@mui/material';
import { SectionCard } from './SectionCard';
import { ASSIGNMENT_STATES } from '~/app/assignments/constants';

const BULLET_POINT = ' • ';

interface SectionTrackItemSummaryProps {
  track: Track;
}

export const SectionTrackItemSummary = (props: SectionTrackItemSummaryProps) => {
  const { track } = props;

  const { filterTrackSections, getSectionOrItemIndex } = useComposableTrackCtx();

  const subTitle = getTrackSectionsAndItemsCountText(
    track.sections,
    track.track_items,
    BULLET_POINT
  );

  const sections = filterTrackSections(track.public_id);

  // If the user has an assignment that's non-dropped, TrackItem cards do not go to Preview
  const isPreview =
    !track.user_assignment || track.user_assignment.state == ASSIGNMENT_STATES.dropped;

  return (
    <Box marginY={2.5}>
      <Box marginBottom={2}>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: colors.emphasis700 }}>
          Summary
        </Typography>
        <Typography sx={{ fontSize: 14, color: colors.neutral500 }}>{subTitle}</Typography>
      </Box>
      <CardCarousel
        cardWidth={315}
        list={sections}
        startIndex={0}
        renderItem={(section, cardsPerPage, cardsCount) => (
          <SectionCard
            key={section.id}
            section={section}
            cardsPerPage={cardsPerPage}
            cardsCount={cardsCount}
            getTrackItemIndex={getSectionOrItemIndex}
            userIsAssignedToTrack={!isNil(track?.user_assignment)}
            isPreview={isPreview}
          />
        )}
        renderMoreComponent={() => <span />}
        showArrowsIfRenderedAll={false}
      />
    </Box>
  );
};
