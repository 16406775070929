import PropTypes from 'prop-types';
import styled from 'styled-components';

import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';

const ModalBodyContainer = styled.div`
  padding: 16px;
`;

const RefreshTokenModal = ({ integrationName, handleRefresh, handleClose }) => {
  return (
    <Modal
      title={`Refresh ${integrationName} token`}
      handleClose={handleClose}
      width={window.innerWidth > 600 ? 600 : null}
      overflow="hidden"
    >
      <ModalBody fullSize scrollable>
        <ModalBodyContainer>
          <Text size="h5" block>
            By refresh this {integrationName} token, you&#39;ll be revoking the current token, and a
            new one will be generated. Are you sure?
          </Text>
        </ModalBodyContainer>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton type="button" color="secondary" onClick={handleClose}>
          Cancel
        </ModalFooterButton>
        <ModalFooterButton type="button" onClick={() => handleRefresh()} color="error">
          Refresh {integrationName}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

RefreshTokenModal.propTypes = {
  integrationName: PropTypes.string,
  handleRefresh: PropTypes.func,
  handleClose: PropTypes.func,
};

export default RefreshTokenModal;
