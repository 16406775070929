import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queries } from '~/queries';
import { apiPatch, apiPost, apiDelete } from '~/services/requests';

export function useCreateChannel() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) => {
      const { data: response } = await apiPost('channels_api:list_create', {}, data);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.channels.list().queryKey });
    },
  });
}

export function useEditChannel(public_id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) => {
      const { data: response } = await apiPatch(
        'channels_api:retrieve_update_destroy',
        public_id,
        data
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.channels.list().queryKey });
      queryClient.invalidateQueries({ queryKey: queries.channels.detail(public_id).queryKey });
    },
  });
}

export function useDeleteChannel(public_id: string) {
  return useMutation({
    mutationFn: async () => {
      const { data: response } = await apiDelete('channels_api:retrieve_update_destroy', public_id);
      return response;
    },
  });
}
