import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { DEFAULT_DETAIL_PAGE_STALE_TIME } from '~/common/api/constants';
import actions from '~/app/entities/actions';
import { sessionSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { LOCAL_DEFAULT_LABEL } from '~/app/event-shared/constants';
import LocationFilter from '~/app/navigation/components/FilterBar/LocationFilter';
import UserFilter from '~/app/navigation/components/FilterBar/UserFilter/UserFilter';
import WidgetMultiSelectFilter from '~/app/navigation/components/FilterBar/WidgetMultiSelectFilter';
import { FilterBar } from '~/app/navigation/components/FilterPage';
import {
  ONLINE_AVAILABILITY_METHOD,
  FRIENDLY_TO_MY_TIME_AVAILABILITY_METHOD,
  LOCAL_AVAILABILITY_METHOD,
} from '~/app/program/constants';
import { queries } from '~/queries';
import Page404 from '~/scenes/404';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import ContentTopbarPage from '~/app/shared-layouts/ContentTopbarPage';
import Loading from '~/app/shared/components/Loading';
import { API_DATE_FORMAT } from '~/app/shared/constants';
import {
  useSetDetailedObject,
  usePublicIdFromURL,
  useRouteFilters,
  useLabels,
  useStickyFilters,
} from '~/app/shared/hooks';
import { filter, get, includes, isEmpty, isEqual, isNil, map, pull, uniq } from 'lodash-es';
import { styled } from '@mui/material';
import ProgramSessionsFilterPage from './ProgramSessionsFilterPage';

const Container = styled('div')`
  width: 100%;
  height: 100%;
`;

const ProgramSessionsFilter = () => {
  const [isTracked, setIsTracked] = useState(false);
  const { trackActivity } = useMetrics();
  const { publicId } = usePublicIdFromURL();
  const [currentFilters, updateFilters, hasLoadedRouteFilters] = useRouteFilters({
    notListAttrs: ['q', 'o', 'fixed'],
  });

  const [stickyFilters, updateStickyFilters] = useStickyFilters('program_sessions_page');
  const {
    label_live_streamed: labelLiveStreamed,
    label_program_sessions_creator_title_single: labelProgramSessionsCreatorTitleSingle,
  } = useLabels();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedHosts, setSelectedHosts] = useState([]);
  const [numberOfColumns, setNumberOfColumns] = useState(0);

  const [
    fetchSessions,
    { data: sessions, status: sessionsStatus, count: sessionsCount },
    loadMoreSessions,
  ] = useEntities(actions.sessionProgram.retrieveColumns, null, {
    schema: [sessionSchema],
    loadMoreAction: actions.session.retrieveColumnsLoadMore,
  });

  const { data, status, error } = useQuery({
    ...queries.programs.detail(publicId || '', 'detail'),
    staleTime: DEFAULT_DETAIL_PAGE_STALE_TIME,
    retry: 0,
  });

  useEffect(() => {
    if (status === 'success' && !isTracked) {
      trackActivity(METRICS_ACTIVITIES.CONTENT_ITEM_VIEW, {
        contentItemId: get(data, 'id', ''),
        contentItemType: get(data, 'content_type', ''),
      });
      setIsTracked(true);
    }
  }, [status, data, trackActivity, isTracked]);

  useEffect(() => {
    if (isEmpty(currentFilters) && stickyFilters) {
      updateFilters(stickyFilters);
    }
  }, []);

  useEffect(() => {
    if (!hasLoadedRouteFilters) return; // Prevent duplicated requests
    fetchSessions(numberOfColumns, {
      ...currentFilters,
      program: publicId,
      host_or_program_manager: true,
      page_size: 50,
    });
  }, [currentFilters, numberOfColumns]);

  const handleUpdateFilters = (value) => {
    const newFilters = { ...currentFilters, ...value };
    if (isEqual(currentFilters, newFilters)) return;

    updateStickyFilters(newFilters);
    updateFilters(newFilters);
  };

  const handleFetchMoreColumns = (numberOfColumns) => {
    const dates = uniq(map(sessions, (s) => moment(s.starts_at_tz_aware).format(API_DATE_FORMAT)));
    loadMoreSessions(dates, numberOfColumns);
  };

  const handleAvailabilityDependentOptionsChange = (param, selecteds, selected) => {
    if (param !== 'availability_method') return selected;

    const currentSelected = selecteds[param];
    const newSelected = selected;

    // If "Friendly to My Time" is selected, also selects "Online".
    if (
      includes(newSelected, FRIENDLY_TO_MY_TIME_AVAILABILITY_METHOD) &&
      !includes(newSelected, ONLINE_AVAILABILITY_METHOD) &&
      !includes(currentSelected, ONLINE_AVAILABILITY_METHOD)
    ) {
      newSelected.push(ONLINE_AVAILABILITY_METHOD);
    }

    // If both "Friendly to My Time" and "Online" are selected and "Online" is deselected,
    // also deselects "Friendly to My Time".
    if (
      includes(currentSelected, FRIENDLY_TO_MY_TIME_AVAILABILITY_METHOD) &&
      includes(currentSelected, ONLINE_AVAILABILITY_METHOD) &&
      !includes(newSelected, ONLINE_AVAILABILITY_METHOD)
    ) {
      pull(newSelected, FRIENDLY_TO_MY_TIME_AVAILABILITY_METHOD);
    }

    return newSelected;
  };

  useSetDetailedObject({ id: publicId }, 'sessions', []);

  if (!isNil(data)) {
    const fixedFilterBarWidgets = [
      {
        show: true,
        title: 'Availability',
        icon: 'clock',
        component: (
          <WidgetMultiSelectFilter
            key="attendance-filter"
            param="availability_method"
            options={[
              {
                name: LOCAL_DEFAULT_LABEL,
                value: LOCAL_AVAILABILITY_METHOD,
              },
              {
                name: labelLiveStreamed || 'Remote Friendly',
                value: ONLINE_AVAILABILITY_METHOD,
              },
              {
                name: 'Friendly to My Time',
                value: FRIENDLY_TO_MY_TIME_AVAILABILITY_METHOD,
              },
            ]}
            getSelectedOnChange={handleAvailabilityDependentOptionsChange}
          />
        ),
      },
      {
        show: true,
        title: 'Location',
        icon: 'location',
        component: (
          <LocationFilter
            key="location-filter"
            param="location"
            labelLiveStreamed={labelLiveStreamed}
            setSelectedLocations={setSelectedLocations}
          />
        ),
      },
      {
        show: true,
        title: labelProgramSessionsCreatorTitleSingle,
        icon: 'person',
        component: (
          <UserFilter
            key="host-filter"
            param="host"
            extraQueryParams={{ host_program_id: data?.id }}
            label={labelProgramSessionsCreatorTitleSingle}
            setSelectedUsers={setSelectedHosts}
          />
        ),
      },
    ];

    return (
      <ContentTopbarPage
        renderSidebarContent={({ closeSidebar }) => (
          <FilterBar
            selecteds={currentFilters}
            onChange={handleUpdateFilters}
            widgets={filter(fixedFilterBarWidgets, ({ show }) => show)}
            closeSidebar={closeSidebar}
            showSearchWidget={false}
          />
        )}
        renderTopbar={false}
        comesFromContent
      >
        <Container>
          <ProgramSessionsFilterPage
            content={data}
            sessions={sessions}
            sessionsStatus={sessionsStatus}
            sessionsCount={sessionsCount}
            selecteds={currentFilters}
            updateFilters={handleUpdateFilters}
            selectedLocations={selectedLocations}
            selectedHosts={selectedHosts}
            loadMoreSessions={handleFetchMoreColumns}
            fetchSessions={fetchSessions}
            setNumberOfColumns={setNumberOfColumns}
            numberOfColumns={numberOfColumns}
          />
        </Container>
      </ContentTopbarPage>
    );
  }

  if (error) return <Page404 />;

  return <Loading />;
};

export default ProgramSessionsFilter;
