import { Moment } from 'moment';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import DateRangeShortcuts from '~/app/inputs/components/FlexibleDateRangeInput/DateRangeShortcuts';
import SegmentedControlsInput from '~/app/inputs/components/SegmentedControlsInput';
import TextInput from '~/app/inputs/components/TextInput';
import { Box, InputBaseProps, Stack } from '@mui/material';
import { DATE_RANGE_EXACT_MODE, DATE_RANGE_RELATIVE_MODE } from './constants';
import ExactDateRangeInput from './ExactDateRangeInput';
import PopperMenu from './PopperMenu';
import RelativeDateRangeInput from './RelativeDateRangeInput';
import { formatFlexibleDateRangeInputText } from './utils';

const Wrapper = styled.div`
  width: 240px;
`;

export type FlexibleDateRangeMode = typeof DATE_RANGE_EXACT_MODE | typeof DATE_RANGE_RELATIVE_MODE;

interface Props {
  label: string;
  mode: FlexibleDateRangeMode;
  startDate?: Moment | string;
  endDate?: Moment | string;
  onChange?: ({
    mode,
    startDate,
    endDate,
  }: {
    mode: FlexibleDateRangeMode;
    startDate?: Moment | string;
    endDate?: Moment | string;
  }) => void;
  placeholder?: string;
  textInputSx?: InputBaseProps['inputProps'];
}
const FlexibleDateRangeInput = (props: Props) => {
  const {
    label,
    mode = DATE_RANGE_EXACT_MODE,
    startDate,
    endDate,
    onChange,
    placeholder = 'Pick a date range',
    textInputSx,
  } = props;

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const inputText = mode ? formatFlexibleDateRangeInputText(mode)(startDate, endDate) : '';

  return (
    <Wrapper>
      <TextInput
        label={label}
        inputRef={anchorRef}
        onClick={handleToggle}
        value={inputText}
        readOnly
        placeholder={placeholder}
        sx={textInputSx}
        inputProps={{}}
      />
      <PopperMenu
        inputRef={anchorRef}
        open={open}
        onToggle={handleToggle}
        // Using z-index 2 because the menu needs to be above the other inputs
        sx={{ zIndex: 2 }}
      >
        <Stack
          spacing={1}
          sx={{
            px: 2,
            py: 3,
            boxSizing: 'border-box',
            ...(mode === DATE_RANGE_EXACT_MODE && {
              width: '240px',
            }),
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <SegmentedControlsInput
              items={[
                { value: DATE_RANGE_RELATIVE_MODE, name: 'Relative' },
                { value: DATE_RANGE_EXACT_MODE, name: 'Exact' },
              ]}
              value={mode}
              onChange={(newValue) => onChange?.({ mode: newValue })}
              fullWidth
            />
          </Box>

          <DateRangeShortcuts
            onChange={({ startDate, endDate }) =>
              onChange?.({
                startDate: startDate || '',
                endDate: endDate || '',
                mode: DATE_RANGE_RELATIVE_MODE,
              })
            }
          />

          {mode === DATE_RANGE_EXACT_MODE && (
            <ExactDateRangeInput
              startDate={startDate as Moment}
              endDate={endDate as Moment}
              onChange={(newValue) => onChange?.({ ...newValue, mode })}
            />
          )}
          {mode === DATE_RANGE_RELATIVE_MODE && (
            <RelativeDateRangeInput
              startDate={startDate as string}
              endDate={endDate as string}
              onChange={(newValue) => onChange?.({ ...newValue, mode })}
            />
          )}
        </Stack>
      </PopperMenu>
    </Wrapper>
  );
};

export default FlexibleDateRangeInput;
