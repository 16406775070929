import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React from 'react';

import actions from '~/app/entities/actions';
import eventActions from '~/app/event/actions';
import { queries } from '~/queries';
import FeedbackModal from '~/app/shared-cards/components/FeedbackModal';
import { get } from 'lodash-es';

export const EventFeedbackModal = (props) => {
  const { feedbackRating, feedbackText, handleClose, event, user } = props;

  const publicId = get(event, 'public_id');

  const feedbackIsPublic = get(event, 'settings.feedbacks_are_public.value', false);

  const queryClient = useQueryClient();

  const handleInvalidateEventFeedbackList = () => {
    const queryKey = queries.events.feedbackList(publicId).queryKey;
    queryClient.invalidateQueries({ queryKey });
  };

  return (
    <FeedbackModal
      publicId={publicId}
      user={user}
      feedbackText={feedbackText}
      feedbackRating={feedbackRating}
      handleClose={handleClose}
      onSubmitActionName={actions.eventEnrollment.update.toString()}
      onSubmitSuccessDispatchAction={eventActions.eventFetchRequestSubmit}
      onSubmitSuccessCallback={handleInvalidateEventFeedbackList}
      fieldLabels={{
        rating: 'Your rating',
        feedback: 'Leave your feedback',
      }}
      fieldPlaceholders={{
        feedback: 'How was your experience in this event?',
      }}
      feedbackIsPublic={feedbackIsPublic}
    />
  );
};

EventFeedbackModal.propTypes = {
  feedbackRating: PropTypes.number,
  feedbackText: PropTypes.string,
  handleClose: PropTypes.func,
  user: PropTypes.object,
  event: PropTypes.object,
};

export default EventFeedbackModal;
