import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import actions from '~/app/entities/actions';
import { segmentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { DASHBOARD_TYPES } from '~/scenes/Dashboard/constants';
import { STATUS_DONE, STATUS_LOADING, STATUS_LOADING_MORE } from '~/app/shared/constants';
import { find, includes, map, size } from 'lodash-es';

import AutocompleteField from '../AutocompleteField';

const pageSize = 25;

const PeopleSegmentSelectField = ({ contentType, setSelectedSegment, input, ...props }) => {
  const [options, setOptions] = useState([]);

  const { value } = input;

  useEffect(() => {
    if (!setSelectedSegment) return;

    const selectedOption = find(options, (segment) => segment.value === value);
    setSelectedSegment(selectedOption);
  }, [value]);

  const [fetch, { status, count }] = useEntities(
    actions.segment.retrieveList,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        let contentOptions = map(data, ({ public_id, name, expression, content_type }) => ({
          value: public_id,
          label: name,
          expression,
          content_type,
          public_id,
        }));

        if (count && count > pageSize) {
          contentOptions = [
            ...contentOptions,
            {
              label: `and ${count - size(data)} more`,
              disabled: true,
            },
          ];
        }

        setOptions(contentOptions);
      }
    },
    {
      schema: [segmentSchema],
    }
  );

  const loading = includes([STATUS_LOADING, STATUS_LOADING_MORE], status);
  const fetchOptionsCb = ({ q, includeValues }) => {
    fetch({
      view_mode: 'filter_options',
      page_size: pageSize,
      include_public_ids: includeValues || undefined,
      content_type: DASHBOARD_TYPES.people,
      q: q || undefined,
    });
  };

  return (
    <AutocompleteField
      options={options}
      loading={loading}
      input={input}
      fetchOptions={fetchOptionsCb}
      {...props}
    />
  );
};

PeopleSegmentSelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  contentType: PropTypes.object,
  setSelectedSegment: PropTypes.func,
};

export default PeopleSegmentSelectField;
