import { Field } from 'redux-form';

import { useLabels } from '~/app/shared/hooks';
import { FormFieldGroup } from '~/app/shared/components/Form';
import InfoText from '~/app/shared/components/InfoText';
import Form from '~/app/shared/components/OldForm';

import { ChannelSelectFieldInput } from './ChannelSelectFieldInput';

const validateRequired = Form.validations.required();

interface ChannelSelectFieldProps {
  disabled?: boolean;
}

const ChannelSelectField = (props: ChannelSelectFieldProps) => {
  const { disabled } = props;
  const { label_channel: labelChannel } = useLabels();

  return (
    <FormFieldGroup>
      <InfoText top={1} bottom={16} content="Choose this item's Channel." />

      <span data-testid="channel-field">
        <Field
          required
          multiple={false}
          name="channel_id"
          label={labelChannel}
          component={ChannelSelectFieldInput}
          validate={[validateRequired]}
          disabled={disabled}
        />
      </span>

      <InfoText
        top={4}
        bottom={4}
        content="If the default channel is selected this new item will be available for every user."
      />
    </FormFieldGroup>
  );
};

export default ChannelSelectField;
