import PropTypes from 'prop-types';
import React from 'react';

import {
  MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR,
  MENTORSHIP_REQUEST_KIND_SESSION_REQUEST,
} from '~/app/program/constants';

import BaseMentorshipRequestCard from './BaseMentorshipRequestCard';

const MentorshipRequestSentCard = ({ mentorshipRequest }) => {
  const { receiver } = mentorshipRequest;

  let statusMessage;

  switch (mentorshipRequest.kind) {
    case MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR: {
      statusMessage = 'You offered to mentor them';
      break;
    }
    case MENTORSHIP_REQUEST_KIND_SESSION_REQUEST: {
      statusMessage = 'You requested a session with them';
      break;
    }
    default:
  }

  return (
    <BaseMentorshipRequestCard
      pillIcon="outgoing"
      user={receiver}
      mentorshipRequest={mentorshipRequest}
      statusMessage={statusMessage}
    />
  );
};

MentorshipRequestSentCard.propTypes = {
  mentorshipRequest: PropTypes.object,
};

export default MentorshipRequestSentCard;
