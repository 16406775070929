import MUIPagination, { PaginationProps } from '@mui/material/Pagination';

export function Pagination(props: PaginationProps) {
  // Only render the pagination component if there are at least two pages
  const { count } = props;
  if (count && count <= 1) {
    return null;
  }
  return <MUIPagination {...props} />;
}
