import React from 'react';

import { EventsMissingFeedback } from '~/app/feedbacks';
import PageTitle from '~/app/shared/components/PageTitle';

function Container() {
  const pageTitle = 'Event feedback';
  return (
    <React.Fragment>
      <PageTitle title={pageTitle} />
      <EventsMissingFeedback />
    </React.Fragment>
  );
}

export default Container;
