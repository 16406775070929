import { ElementType } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ChannelByline from '~/app/shared/components/ChannelByline';
import colors from '~/services/colors';
import { Link } from '~/common/components/Links';
import { Box, LinkProps as MUILinkProps, styled } from '@mui/material';
import { Channel } from '~/features/channels/types';

type LinkProps = MUILinkProps & { component: ElementType; to: string };

const HeaderTitleLink = styled(Link)<LinkProps>`
  color: ${colors.neutral900};
  font-size: 1.125rem;
  line-height: 1.275;
  word-break: break-word;

  &:hover {
    color: ${colors.neutral900};
  }
`;

type ContentItemCardHeaderProps = {
  title: string;
  route: string;
  channel?: Channel;
};

export function ContentItemCardHeader(props: ContentItemCardHeaderProps) {
  const { title, route, channel } = props;

  return (
    <Box display="flex" flexDirection="column" gap="4px">
      <HeaderTitleLink component={RouterLink} to={route} underline="hover" variant="h6">
        {title}
      </HeaderTitleLink>

      {channel && <ChannelByline channel={channel} />}
    </Box>
  );
}
