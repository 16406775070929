import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { LEARNING_TYPE_ICONS_MAPPING, LEARNING_TYPES } from '~/app/catalog/constants';
import colors from '~/services/colors';
import Icon from '~/app/shared/components/Icon';
import Text from '~/app/shared/components/Text';

export const CourseFormContainer = styled.div`
  margin-top: 32px;
`;

const RightTitleContainer = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 8px;
  }
`;

export const CourseFormRightTitleSection = ({ text }) => {
  return (
    <RightTitleContainer>
      <Icon
        width={24}
        height={24}
        name={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.courses]}
        color={colors.learningCourse700}
      />
      <Text size="h1" color={colors.neutral400}>
        {text}
      </Text>
    </RightTitleContainer>
  );
};

CourseFormRightTitleSection.propTypes = {
  text: PropTypes.string,
};
