import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import { baseButtonStyle, ButtonContent, ButtonWrapper } from '~/app/shared/components/Button';
import MediaPointJSX from '~/app/shared/components/MediaPointJSX';

const SidebarButtonWrapper = styled(ButtonWrapper)`
  ${baseButtonStyle};

  border-radius: 0 4px 4px 0;
  background-color: ${colors.action600};
  color: ${colors.neutral0};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  transform: translateY(12px);
  z-index: 1;
  border: none;

  svg path {
    fill: ${colors.neutral0};
  }

  &:hover,
  &:focus {
    background-color: ${colors.neutral0};
    border-color: ${colors.neutral0};
    color: ${colors.action600};
    box-shadow: 0 2px 4px 0 rgba(105, 105, 105, 0.5);

    svg path {
      fill: ${colors.action600};
    }
  }
`;

const SidebarButton = ({ onClick, icon, text, renderContent }) => {
  return (
    <SidebarButtonWrapper size="large" type="button" onClick={onClick}>
      {renderContent ? (
        <>
          {renderContent()}
          <span style={{ marginLeft: '8px' }}>Items</span>
        </>
      ) : (
        <ButtonContent icon={icon}>
          <MediaPointJSX type="tablet">{text}</MediaPointJSX>
        </ButtonContent>
      )}
    </SidebarButtonWrapper>
  );
};

SidebarButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  text: PropTypes.string,
  renderContent: PropTypes.func,
};

export default SidebarButton;
