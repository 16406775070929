import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import UserProfileInitials from '~/app/shared/components/UserAvatar/UserProfileInitials';

import ImageField, { Preview } from '../ImageField';

const StyledPreview = styled(Preview)`
  border-radius: 50%;
`;

const Container = styled.div`
  width: ${({ width }) => width || '500px'};
  height: ${({ height }) => height || '392px'};
`;

const DefaultProfileImagePlaceholder = (props) => {
  const { userName } = props;
  return (
    <Container {...props}>
      <UserProfileInitials userName={userName} size="extra-large" />
    </Container>
  );
};

DefaultProfileImagePlaceholder.propTypes = {
  userName: PropTypes.string,
};

const ProfilePreview = (props) => {
  return <StyledPreview {...props} Placeholder={DefaultProfileImagePlaceholder} />;
};

const ProfileImageField = (props) => {
  const { userName } = props;
  return <ImageField Previewer={ProfilePreview} previewExtraProps={{ userName }} {...props} />;
};

ProfileImageField.propTypes = {
  userName: PropTypes.string,
};

export default ProfileImageField;
