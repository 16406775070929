import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { mapRoute } from '~/services/requests';
import Icon from '~/app/shared/components/Icon';
import InfoBox from '~/app/shared/components/InfoBox';
import Loading from '~/app/shared/components/Loading';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { useLabels } from '~/app/shared/hooks';

const ModalContent = styled.div`
  position: relative;
  max-height: 60vh;
  overflow: auto;
`;

const LoaderContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ImportingInfo = styled(Text)`
  margin-top: 16px;
`;

const UploadStatusModal = ({ uploadedCourse }) => {
  const { latest_sync: latestSync } = uploadedCourse;

  const { label_catalog: labelCatalog } = useLabels();

  // If latest sync is null, it is still uploading
  const isUploadingCourse = !latestSync;
  return (
    <Modal title="Importing..." height="220px" width={500}>
      <ModalBody>
        <ModalContent>
          {isUploadingCourse && (
            <LoaderContainer>
              <Loading hasMargin={false} />
              <ImportingInfo size="h4">Importing your course may take a few minutes.</ImportingInfo>
            </LoaderContainer>
          )}

          <InfoBox
            content="It is safe to leave this page. You will receive a notification once your course has been imported."
            hideIcon
            margin="16px 0 12px 0"
          />
        </ModalContent>
      </ModalBody>
      <ModalFooter variant="buttons" justifyContent="spaceBetween">
        <ModalFooterButton
          variant="text"
          startIcon={<Icon name="left" />}
          href={mapRoute('unifiedCatalogList')}
        >
          Back to {labelCatalog}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

UploadStatusModal.propTypes = {
  uploadedCourse: PropTypes.object,
};

export default UploadStatusModal;
