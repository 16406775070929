import React, { useEffect, useState } from 'react';

import { EDIT_COURSE_PERMISSION } from '~/app/shared/permissions';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import actions from '~/app/entities/actions';
import { courseSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { permissionDeniedRedirectAlert } from '~/services/permission-denied-redirect-alert';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import {
  useLabels,
  useOriginRoute,
  useSetDetailedObject,
  useQueryParams,
  usePublicIdFromURL,
} from '~/app/shared/hooks';
import { toLower, includes } from 'lodash-es';

import CourseFormPage from './CourseFormPage';

const CourseEdit = () => {
  const { publicId } = usePublicIdFromURL();
  const [userCanSee, setUserCanSee] = useState(false);

  const queryParams = useQueryParams();
  const isPublishing = queryParams.is_publishing === 'true';

  const { label_course: labelCourse, label_course_plural: labelCoursePlural } = useLabels();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.courses
  }&o=upcoming`;
  const courseDetailRoute = mapRoute('standAloneCourseDetail', { public_id_and_slug: publicId });
  const backRoute = useOriginRoute(isPublishing ? catalogRoute : courseDetailRoute);

  const [fetchCourse, { data: course, status }] = useEntities(
    actions.course.retrieveDetails,
    ({ data: course, status }) => {
      if (status === STATUS_DONE) {
        if (includes(course.permissions, EDIT_COURSE_PERMISSION)) {
          setUserCanSee(true);
        } else {
          permissionDeniedRedirectAlert();
        }
      }
    },
    {
      schema: courseSchema,
    }
  );

  useEffect(() => {
    fetchCourse(publicId);
  }, []);

  useSetDetailedObject({ id: publicId }, 'courses', [publicId]);

  if (status === STATUS_LOADING || !userCanSee) return <Loading />;

  const initialValuesParams = { course };

  return (
    <>
      {isPublishing && (
        <CourseFormPage
          pageTitle={`Publish ${labelCourse}`}
          topBarActionName={`Publishing ${labelCourse}`}
          backRoute={backRoute}
          initialValuesParams={initialValuesParams}
          breadcrumbsItemList={[
            {
              label: labelCoursePlural,
              link: catalogRoute,
            },
            {
              label: `New ${toLower(labelCourse)}`,
            },
          ]}
        />
      )}
      {!isPublishing && (
        <CourseFormPage
          pageTitle={`Edit ${labelCourse}`}
          topBarActionName={`Editing ${labelCourse}`}
          backRoute={backRoute}
          initialValuesParams={initialValuesParams}
          breadcrumbsItemList={[
            {
              label: labelCoursePlural,
              link: catalogRoute,
            },
            {
              label: course.name,
              link: courseDetailRoute,
            },
            {
              label: `Edit ${toLower(labelCourse)}`,
            },
          ]}
        />
      )}
    </>
  );
};

export default CourseEdit;
