import { useGetContentTypeLabel } from '~/app/catalog/hooks';
import { Modal } from '~/app/shared';
import ListTrackItemAssignments from '~/scenes/ContentItemAssignments/ListTrackItemAssignments';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { User } from '~/app/shared/components/types';
import { Box } from '@mui/material';

interface ListTrackItemAssignmentsModalProps {
  user: User;
  contenItem: ContentItem;
  handleClose: () => void;
  handleBack: () => void;
}

const ListTrackItemAssignmentsModal = (props: ListTrackItemAssignmentsModalProps) => {
  const { handleClose, handleBack, user, contenItem } = props;
  const getContentTypeLabel = useGetContentTypeLabel();

  return (
    <Modal
      width={window.innerWidth > 1024 ? 1024 : '80%'}
      title={`${getContentTypeLabel(contenItem.content_type)} progress - ${user.name}`}
      handleClose={handleClose}
      handleBack={handleBack}
    >
      <Box sx={{ px: 2 }}>
        <ListTrackItemAssignments contentItem={contenItem} user={user} />
      </Box>
    </Modal>
  );
};

export default ListTrackItemAssignmentsModal;
