import { useState } from 'react';

import { createPostAssignCallback, getNormalizedSelectedData } from '~/app/rosters/utils';
import { ASSIGNMENT_REASONS } from '~/app/assignments/constants';
import { useAssignmentsBulkActions } from '~/app/assignments/hooks';
import { getBulkAsyncJobErrors } from '~/app/assignments/services';
import BulkAssignmentFormModal from '~/app/rosters/components/AssignmentsRoster/BulkAssignmentFormModal';
import { AssignUsersModalProps } from '~/app/rosters/components/AssignmentsRoster/types';
import Modal from '~/app/shared/components/Modal';
import { RosterContentContainer } from '~/app/stand-alone-shared/components/BaseRosterStyling';
import { get, capitalize, noop } from 'lodash-es';

import ScheduledTrackEnrollModalContent from '../ScheduledTrackEnrollModalContent';

const ScheduledTrackAssignUsersModal = ({
  content,
  assignLabel = 'Assign',
  assignmentLabel = 'Assignment',
  selectedData,
  isReAssign,
  handleClose,
  handleBack,
}: AssignUsersModalProps) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const { total, expression: userRql } = getNormalizedSelectedData(selectedData, 'id');
  const postActionCallback = createPostAssignCallback({ total, assignmentLabel, handleBack });

  const { bulkCreate, bulkCreateResponse, isLoading } = useAssignmentsBulkActions({
    postActionCallback,
  });

  const handleAssign = ({ eventEnrollmentOptions }) => {
    bulkCreate({
      content_item_public_ids: [content.public_id],
      user_rql: userRql,
      due_at: null,
      expires_at: null,
      is_reassign: isReAssign,
      event_enrollment_options: eventEnrollmentOptions,
      reason: ASSIGNMENT_REASONS.roster_bulk_assignment,
    });
  };

  const handleConfirm = ({ eventEnrollmentOptions }) => {
    handleAssign({ eventEnrollmentOptions });
    setShowFeedbackModal(true);
  };

  const assignTitle = capitalize(`${isReAssign ? 'Re/' : ''}${assignLabel}`);
  if (showFeedbackModal) {
    return (
      <BulkAssignmentFormModal
        content={content}
        title={`${assignTitle} - ${get(content, 'name', '')}`}
        actionLabel={assignLabel}
        selectedData={selectedData}
        handleAction={noop}
        isLoading={isLoading}
        errors={getBulkAsyncJobErrors(bulkCreateResponse)}
        handleBack={handleBack}
        handleClose={handleClose}
        hideActions
      />
    );
  }
  return (
    <Modal
      title="Confirm enrollment"
      handleClose={handleClose}
      handleBack={handleBack}
      overflow="visible"
      sx={{ width: '90%', maxWidth: '820px', minWidth: '500px', maxHeight: '95vh' }}
    >
      <RosterContentContainer>
        <ScheduledTrackEnrollModalContent
          content={content}
          handleAssign={handleConfirm}
          count={total}
          isBulk
        />
      </RosterContentContainer>
    </Modal>
  );
};

export default ScheduledTrackAssignUsersModal;
