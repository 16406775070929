import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import actions from '~/app/entities/actions';
import { contentSchema } from '~/app/entities/schema';
import { getDataFromState } from '~/app/entities/utils';
import BaseContainer from '~/app/navigation/components/BaseContainer';
import Loading from '~/app/shared/components/Loading';
import PageTitle from '~/app/shared/components/PageTitle';
import { STATUS_DONE } from '~/app/shared/constants';
import { isEqual, isEmpty } from 'lodash-es';

import MyLearningContent from './MyLearningContent';

class MyLearningContentContainer extends React.Component {
  componentDidMount = () => {
    const { fetchContents } = this.props;
    fetchContents();
  };

  render = () => {
    const { currentUser, contents, contentsStatus, fetchContents } = this.props;

    // userTimezone can be undefined while the page hasn't finished loading, raising errors
    if (!isEqual(contentsStatus, STATUS_DONE) || isEmpty(currentUser.timezone)) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        <PageTitle title="My Content" />
        <BaseContainer>
          <MyLearningContent
            contents={contents}
            fetchContents={fetchContents}
            currentUser={currentUser}
          />
        </BaseContainer>
      </React.Fragment>
    );
  };
}

MyLearningContentContainer.propTypes = {
  contents: PropTypes.array,
  contentsStatus: PropTypes.string,
  currentUser: PropTypes.object,

  fetchContents: PropTypes.func,
};

const mapStateToProps = (state) => {
  const contents = getDataFromState('contentList', state, [contentSchema]);

  return {
    contents: contents.data,
    contentsStatus: contents.status,
    contentsNextPage: contents.nextPage,

    currentUser: state.user.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContents: () =>
    dispatch(
      actions.content.retrieveList(
        'contentList',
        {
          page_size: 1000, // use max page size, after all we're using fetchAll: true
          my_contents: true,
          is_archived: false,
          is_inline: false,
        },
        { fetchAll: true }
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyLearningContentContainer);
