import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import SidebarMenu from 'react-burger-menu/lib/menus/slide';
import ReactDOM from 'react-dom';

import { noop } from 'lodash-es';

const sidebarStyles = {
  bmMenu: {
    backgroundColor: 'white',
    WebkitOverflowScrolling: 'touch',
    overflowX: 'hidden',
  },
};

const Sidebar = ({ onChange, isOpen, width, children }) => {
  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
      onChange({ isOpen: false });
    };
  }, []);

  const handleOnChange = (state) => {
    const el = document.querySelector('.bm-menu');
    if (state.isOpen) {
      disableBodyScroll(el);
    } else {
      clearAllBodyScrollLocks();
    }

    onChange(state);
  };

  return ReactDOM.createPortal(
    <SidebarMenu
      disableAutoFocus
      isOpen={isOpen}
      width={width || 300}
      onStateChange={handleOnChange}
      customBurgerIcon={false}
      customCrossIcon={false}
      styles={sidebarStyles}
    >
      {children}
    </SidebarMenu>,
    document.getElementById('react-app-sidebar-root')
  );
};

export default Sidebar;

Sidebar.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  onChange: PropTypes.func,
  width: PropTypes.number,
};

Sidebar.defaultProps = {
  onChange: noop,
};
