import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import InfoBox from '~/app/shared/components/InfoBox';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';

import { ENROLLMENT_STATUS_EXIT_WAIT_LIST } from '../../constants';

const ModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 16px;
  }
`;

const ExternalWaitlistDeregistrationModal = ({
  updateEnrollmentStatus,
  show,
  event,
  handleClose,
}) => {
  const handleUnenrollClick = () => {
    updateEnrollmentStatus(ENROLLMENT_STATUS_EXIT_WAIT_LIST);
    handleClose();
  };

  const buttonText = event.external_link ? 'Leave Externally' : 'Leave';

  if (!show) {
    return null;
  }

  return (
    <Modal
      title="Exit Waitlist"
      width={window.innerWidth > 768 ? 600 : 'auto'}
      handleClose={handleClose}
    >
      <ModalBody>
        <ModalBodyWrapper>
          <Text size="h4">We will miss you!</Text>
          <InfoBox content={event.external_link_description} />
        </ModalBodyWrapper>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton
          color="error"
          type="button"
          onClick={handleUnenrollClick}
          alt={buttonText}
        >
          {buttonText}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

ExternalWaitlistDeregistrationModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,

  event: PropTypes.object,

  updateEnrollmentStatus: PropTypes.func,
};

export default ExternalWaitlistDeregistrationModal;
