import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from '~/services/colors';
import { getSettingHashKey } from '~/app/settings/services';
import tagsCheatsheet from '~/app/settings/tagCheatsheet';
import { FormSectionContainer } from '~/app/shared/components/Form';
import { get, has } from 'lodash-es';

import NotificationTagsCheatsheet from './NotificationTagsCheatsheet';

const FormSectionContainerWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.neutral200};
`;

const NotificationTagsCheatsheetWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const NotificationContextBlock = ({ settingName, setting, children }) => {
  const hashKey = getSettingHashKey(settingName);

  return (
    <FormSectionContainerWrapper>
      <FormSectionContainer
        key={hashKey}
        id={hashKey}
        title={setting.label}
        description={setting.description}
        defaultOpen={false}
      >
        {children}
        {has(tagsCheatsheet, settingName) && (
          <NotificationTagsCheatsheetWrapper>
            <NotificationTagsCheatsheet content={get(tagsCheatsheet, settingName)} />
          </NotificationTagsCheatsheetWrapper>
        )}
      </FormSectionContainer>
    </FormSectionContainerWrapper>
  );
};

NotificationContextBlock.propTypes = {
  settingName: PropTypes.string,
  setting: PropTypes.object,
  children: PropTypes.node,
};

export default NotificationContextBlock;
