import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import DatePickerInput from '~/app/inputs/components/DatePickerInput';

import TextInput from '../TextInput';

const SingleDatePickerField = ({ input, meta, disabled, label, required, InputProps }) => {
  const { value } = input;
  const { error, touched } = meta;

  const [date, setDate] = useState(value ? moment(value) : null);

  const handleDateChange = (value) => {
    const { onChange } = input;

    if (!value) {
      onChange(null);
      setDate(null);
      return;
    }
    onChange(value.toISOString());
    setDate(value);
  };

  return (
    <div>
      <DatePickerInput
        label={label}
        value={date ? moment(date) : null}
        onChange={handleDateChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextInput
            {...params}
            required={required}
            error={touched && error}
            helperText={touched && error}
          />
        )}
        {...InputProps}
      />
    </div>
  );
};

SingleDatePickerField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  InputProps: PropTypes.object,
};

SingleDatePickerField.defaultProps = {
  disabled: false,
  required: false,
};

export default SingleDatePickerField;
