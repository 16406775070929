import PropTypes from 'prop-types';
import React from 'react';
import { Field, FieldArray } from 'redux-form';

import TimeslotsField from '~/app/event/components/EventForm/TimeslotsField';
import InputLabel from '~/app/inputs/components/InputLabel';
import LocationSelectField from '~/app/inputs/components/LocationSelectField';
import { FormFieldGroup } from '~/app/shared/components/Form';
import InfoText from '~/app/shared/components/InfoText';
import NewTimeslotField from '~/app/shared/components/NewContentForm/NewTimeslotField';
import OldForm from '~/app/shared/components/OldForm';

const validateRequired = OldForm.validations.required();

const LocationSection = ({
  isCompactMode,
  locationName,
  timeslotsName,
  locationId,
  isOnline,
  initialWatchLink,
  initialDuration,
  formName,
  change,
  isEditing,
}) => (
  <>
    <FormFieldGroup>
      <Field
        name={locationName}
        label="Location"
        required={!isCompactMode}
        component={LocationSelectField}
        validate={isCompactMode ? [] : [validateRequired]}
      />
      <InfoText
        top={4}
        bottom={16}
        content="The system will calculate local time at other locations for online events."
      />
    </FormFieldGroup>
    <FormFieldGroup>
      {isCompactMode ? (
        <NewTimeslotField
          isCompactMode
          locationId={locationId}
          isOnline={isOnline}
          formName={formName}
          change={change}
          initialContentWatchLink={initialWatchLink}
          isEditing={isEditing}
        />
      ) : (
        <>
          <InputLabel>Timeslots</InputLabel>
          <InfoText
            top={4}
            bottom={8}
            content="Your event can span multiple time periods, allowing you to create multi-day events or events that skip over logical breaks (E.g. lunch break)."
          />
          <FieldArray
            isNewForm
            name={timeslotsName}
            component={TimeslotsField}
            locationId={locationId}
            isOnline={isOnline}
            initialWatchLink={initialWatchLink}
            initialDuration={initialDuration}
            formName={formName}
            change={change}
            isEditing={isEditing}
          />
        </>
      )}
    </FormFieldGroup>
  </>
);

LocationSection.propTypes = {
  isCompactMode: PropTypes.bool,
  locationName: PropTypes.string,
  timeslotsName: PropTypes.string,
  locationId: PropTypes.number,
  isOnline: PropTypes.bool,
  isEditing: PropTypes.bool,
  initialWatchLink: PropTypes.string,
  initialDuration: PropTypes.string,
  formName: PropTypes.string,
  change: PropTypes.func,
};

export default LocationSection;
