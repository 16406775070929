import { useContext } from 'react';
import { useHistory } from 'react-router';

import { useLabels } from '~/app/shared/hooks';
import {
  DELETE_CONTENT_ITEM_NOT_GRANTED_EXCEPTION_NAME,
  CONTENT_TYPES,
} from '~/app/catalog/constants';
import { useGetContentTypeLabel, useContentTypeRoutes } from '~/app/catalog/hooks';
import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from '~/app/shared/constants';
import { ContentItemListenerContext } from '~/app/stand-alone-shared/context-providers/ContentItemListenerContext';
import { includes, get, map } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Box, Stack } from '@mui/material';

const CONTENT_DELETE_ACTION_MAP = {
  [CONTENT_TYPES.article]: actions.article.remove,
  [CONTENT_TYPES.video]: actions.video.remove,
  [CONTENT_TYPES.codelab]: actions.codelab.remove,
  [CONTENT_TYPES.course]: actions.course.remove,
  [CONTENT_TYPES.eventtype]: actions.eventType.remove,
  [CONTENT_TYPES.program]: actions.program.remove,
  [CONTENT_TYPES.task]: actions.task.remove,
  [CONTENT_TYPES.multiple_choice_question]: actions.question.remove,
  [CONTENT_TYPES.text_question]: actions.question.remove,
  [CONTENT_TYPES.linkedcontent]: actions.linkedContent.remove,
  [CONTENT_TYPES.assessment]: actions.assessment.remove,
  [CONTENT_TYPES.track]: actions.track.remove,
  [CONTENT_TYPES.scheduled_track]: actions.scheduledTrack.remove,
} as const;

// Basically content items that can be added to tracks
const CONTENT_FORCE_DELETE_ACTION_MAP = {
  [CONTENT_TYPES.article]: actions.article.forceRemove,
  [CONTENT_TYPES.video]: actions.video.forceRemove,
  [CONTENT_TYPES.codelab]: actions.codelab.forceRemove,
  [CONTENT_TYPES.course]: actions.course.forceRemove,
  [CONTENT_TYPES.eventtype]: actions.eventType.forceRemove,
  [CONTENT_TYPES.task]: actions.task.forceRemove,
  [CONTENT_TYPES.linkedcontent]: actions.linkedContent.forceRemove,
  [CONTENT_TYPES.assessment]: actions.assessment.forceRemove,
  [CONTENT_TYPES.track]: actions.track.forceRemove,
} as const;

interface DeleteStandAloneModalProps {
  content: any;
  handleClose: () => void;
  onDelete?: ({ content }) => void;
}

const DeleteStandAloneModal = ({
  content,
  handleClose,
  onDelete: onDeleteCallback,
}: DeleteStandAloneModalProps) => {
  const history = useHistory();
  const onDeleteContext = useContext(ContentItemListenerContext);
  const { onDelete } = onDeleteCallback ? { onDelete: onDeleteCallback } : onDeleteContext;
  const contentType = content.content_type;

  const contentTypeRoutes = useContentTypeRoutes();
  const { trackActivity } = useMetrics();
  const getContentTypeLabel = useGetContentTypeLabel();

  const contentLabel = getContentTypeLabel(contentType);
  const { label_track: labelTrack, label_track_plural: labelTrackPlural } = useLabels();
  const handleStatusChange = ({ status, error }) => {
    if (status === STATUS_DONE) {
      trackActivity(METRICS_ACTIVITIES.ENTITY_DELETE, {
        entityType: contentType,
        entityId: content.id,
      });
      if (onDelete) {
        onDelete({ content });
      } else {
        history.push(mapRoute('unifiedCatalogList'));
      }
      handleClose();
      toast.success(`${contentLabel || 'Content'} deleted successfully`);
    } else if (
      status === STATUS_ERROR &&
      !get(error, 'is_dialog_error', false) &&
      get(error, 'exception') !== DELETE_CONTENT_ITEM_NOT_GRANTED_EXCEPTION_NAME
    ) {
      toast.error('Error', 'Please try again later');
    }
  };

  const [deleteContent, { status: deleteStatus, error: deleteError }] = useEntities(
    CONTENT_DELETE_ACTION_MAP[contentType],
    handleStatusChange
  );

  const [forceDeleteContent, { status: forceDeleteStatus }] = useEntities(
    CONTENT_FORCE_DELETE_ACTION_MAP[contentType],
    handleStatusChange
  );

  const id = includes(
    [
      CONTENT_TYPES.track,
      CONTENT_TYPES.assessment,
      CONTENT_TYPES.multiple_choice_question,
      CONTENT_TYPES.text_question,
      CONTENT_TYPES.linkedcontent,
    ],
    contentType
  )
    ? content.id
    : content.public_id;

  const handleDeleteClick = () => deleteContent(id);
  const handleForceDeleteClick = () => forceDeleteContent(id);

  if (
    deleteStatus === STATUS_ERROR &&
    get(deleteError, 'exception') === DELETE_CONTENT_ITEM_NOT_GRANTED_EXCEPTION_NAME
  )
    return (
      <Modal
        handleClose={handleClose}
        minBodyHeight="124px"
        title={`${labelTrackPlural} dependent on this ${contentLabel}`}
      >
        <ModalBody>
          <Stack spacing={2}>
            <Typography variant="body2">
              Are you sure you want to remove <b>&quot;{content.name}&quot;?</b>
            </Typography>
            <Typography variant="body2">
              It is currently referenced by the following {labelTrackPlural}:
            </Typography>
          </Stack>

          <Box sx={{ color: colors.error600 }} margin={1}>
            {map(get(deleteError, 'tracks'), (track) => (
              <li key={track.public_id}>
                <ButtonLink
                  variant="error"
                  size="medium"
                  target="_blank"
                  route={contentTypeRoutes[track.content_type].details({
                    public_id_and_slug: track.public_id_and_slug,
                  })}
                >
                  {track.name}
                </ButtonLink>
              </li>
            ))}
          </Box>

          {/* NOTE: CA 2.0 drops outstanding assignments instead of removing them. Once all content
          items delete calls are migrated to CA 2.0, this copy should be rephrased. */}
          <Typography variant="body2">
            Upon removing it, all references to this content are also removed, such as {labelTrack}{' '}
            associations and all outstanding assignments.
          </Typography>
        </ModalBody>

        <ModalFooter variant="buttons">
          <ModalFooterButton variant="text" onClick={handleClose} sx={{ color: colors.neutral600 }}>
            Cancel
          </ModalFooterButton>
          <ModalFooterButton
            color="error"
            onClick={handleForceDeleteClick}
            disabled={includes([STATUS_LOADING, STATUS_ERROR], forceDeleteStatus)}
          >
            Delete
          </ModalFooterButton>
        </ModalFooter>
      </Modal>
    );

  return (
    <Modal
      title={`Delete "${content.name}"`}
      handleClose={handleClose}
      maxWidth={window.innerWidth > 600 ? 600 : null}
      minBodyHeight="124px"
    >
      <ModalBody>
        <Stack spacing={2}>
          <Typography variant="body2">
            You are about to delete <b>&quot;{content.name}&quot;</b>. Once deleted, you will no
            longer be able to access this {contentLabel}, nor will you be able to restore it.
          </Typography>

          {includes([CONTENT_TYPES.track, CONTENT_TYPES.scheduled_track], contentType) && (
            <Typography variant="body2">
              This action will <b>not</b> implicitly delete any underlying {contentLabel} Items nor
              unassign people from them. It is up to you if you wish to do so explicitly.
            </Typography>
          )}

          <Typography variant="body2">
            Are you sure you want to proceed? This action cannot be undone.
          </Typography>
        </Stack>
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton variant="text" onClick={handleClose} sx={{ color: colors.neutral600 }}>
          Cancel
        </ModalFooterButton>
        <ModalFooterButton
          color="error"
          onClick={handleDeleteClick}
          disabled={includes([STATUS_LOADING, STATUS_ERROR], deleteStatus)}
          aria-label="Delete"
        >
          Delete
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteStandAloneModal;
