import { forEach, get, isArray, isObject } from 'lodash-es';

/**
 * Use this function to do some async action before submitting to Redux Form
 * This async action can be a request, or a confirmation modal
 * Return this function on the "onSubmit" option in reduxForm() HOC
 *
 * @param confirm Function that receives an object with 2 functions: proceedToSubmit and cancelSubmit.
 *  When calling proceedToSubmit, the next param function "submit" will be called.
 *    It receives one parameter, which will be passed to the "submit" callback
 *  When calling cancelSubmit, it will return a rejected promise, so Redux Forms will call onSubmitFail.
 *    Pass handleFailureWithConfirmation to onSubmitFail to check the cancellation properly
 *
 * @param submit Function that will be called after confirmation of the previous callback
 *  This submit function must return a promise that will be passed to Redux Form submit flow
 */

/**
 * Use this function to do some async action before submitting to Redux Form
 * This async action can be a request, or a confirmation modal
 * Return this function on the "onSubmit" option in reduxForm() HOC
 *
 * @param confirm Function that receives an object with 2 functions: proceedToSubmit and cancelSubmit.
 *  When calling proceedToSubmit, the next param function "submit" will be called.
 *    It receives one parameter, which will be passed to the "submit" callback
 *  When calling cancelSubmit, it will return a rejected promise, so Redux Forms will call onSubmitFail.
 *    Pass handleFailureWithConfirmation to onSubmitFail to check the cancellation properly
 *
 * @param submit Function that will be called after confirmation of the previous callback
 *  This submit function must return a promise that will be passed to Redux Form submit flow
 */
export const submitWithConfirmation = (confirm, submit) =>
  new Promise((resolve, reject) => {
    confirm({
      proceedToSubmit: resolve,
      // eslint-disable-next-line prefer-promise-reject-errors
      cancelSubmit: () => reject({ failedConfirmation: true }),
    });
  }).then((data) => submit(data));

// If needed add catch((e) => console.log(e)) to debug the modal

/**
 * Pass this function to onSubmitFail with your callback as a parameter, when you use submitWithConfirmation
 *  on the onSubmit option
 * This function will not call the callback parameter if the confirmation failed
 *
 * @param callback
 * @returns {Function}
 */
export const handleFailureWithConfirmation = (callback) => (errors, dispatch, submitErrors) => {
  if (get(submitErrors, 'failedConfirmation')) {
    return null;
  }

  return callback(errors, dispatch, submitErrors);
};

/**
 * Redux-form considers forms as valid even if there are errors on unregistered fields, see here:
 * https://redux-form.com/8.3.0/examples/syncvalidation/
 * "IMPORTANT: If the validation function returns errors and the form does not currently render
 * fields for all of the errors, then the form will be considered valid and will be submitted."
 *
 * However sometimes you want to validate even if the field is not being rendered.
 * For that, use this function
 *
 * @param errors Errors object from redux-form, either form validation or field validation
 * @returns {boolean} if the object is valid
 */
export const checkIfErrorsAreValid = (errors) => {
  let valid = true;
  forEach(errors, (value) => {
    if (isArray(value) || isObject(value)) {
      forEach(value, (element) => {
        if (!checkIfErrorsAreValid(element)) {
          valid = false;
        }
      });
    } else if (value) {
      valid = false;
    }
  });
  return valid;
};
