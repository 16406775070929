import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { mapRoute } from '~/services/requests';
import { slugify } from '~/services/utils';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import { useLabels, useEncodedCurrentRoute } from '~/app/shared/hooks';
import { toLower, size, isEmpty, pick } from 'lodash-es';

import ScheduleTrackReviewBody from './ScheduleTrackReviewBody';

const ScheduleTrackModal = ({ track, handleClose }) => {
  const history = useHistory();
  const currentRoute = useEncodedCurrentRoute();
  const { label_track: labelTrack } = useLabels();

  const defaultStartsAtMoment = moment().add(1, 'weeks').endOf('day');
  const defaultEndsAtMoment = moment().add(2, 'weeks').endOf('day');
  const defaultStartsAtDisplay = defaultStartsAtMoment.format('MMM DD');
  const defaultEndsAtDisplay = defaultEndsAtMoment.format('MMM DD');
  const defaultTrackName = `${track?.name} (${defaultStartsAtDisplay} - ${defaultEndsAtDisplay})`;

  const [info, setInfo] = useState({
    name: defaultTrackName,
    slackChannel: slugify(defaultTrackName),
    slackChannelError: null,
    locationId: null,
    capacity: 0,
    starts_at: defaultStartsAtMoment.toISOString(),
    ends_at: defaultEndsAtMoment.toISOString(),
  });

  useEffect(() => {
    if (size(info.slackChannel) > 80) {
      setInfo({ ...info, slackChannelError: 'This field must be 80 characters or less.' });
      return;
    }

    if (!isEmpty(info.slackChannel.match(/[^a-z0-9_-]/g))) {
      setInfo({
        ...info,
        slackChannelError:
          'This field must only contain lowercase letters, numbers, hyphens and underscores.',
      });
      return;
    }

    setInfo({ ...info, slackChannelError: null });
  }, [info.slackChannel]);

  const redirectToCreatePage = () => {
    const url = `${mapRoute('scheduledTrackCreate')}?origin=${currentRoute}`;

    const infoToPass = pick(info, [
      'name',
      'slackChannel',
      'locationId',
      'capacity',
      'starts_at',
      'ends_at',
    ]);
    history.push(url, {
      scheduledTrack: {
        track,
        ...infoToPass,
      },
    });
    return null;
  };

  return (
    <Modal
      handleClose={handleClose}
      title={`Schedule "${track.name}" ${toLower(labelTrack)}`}
      width={window.innerWidth > 1024 ? 680 : null}
      height={window.innerWidth > 1024 ? '600px' : 80}
    >
      <ModalBody fullSize scrollable>
        <ScheduleTrackReviewBody info={info} setInfo={setInfo} />
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose}>Cancel</ModalFooterButton>
        <ModalFooterButton
          onClick={redirectToCreatePage}
          disabled={Boolean(info.slackChannelError)}
        >
          Review
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

ScheduleTrackModal.propTypes = {
  track: PropTypes.object,
  handleClose: PropTypes.func,
};

export default ScheduleTrackModal;
