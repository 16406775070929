import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SessionEnrollmentModal from '~/app/program/components/SessionEnrollmentModal';
import { useCurrentUser } from '~/app/shared/hooks';

import SessionCardButtons from './SessionCardButtons';

const SessionCardButtonsWrapper = ({
  session,
  sessionIsBooked,
  sessionSpotsLeft,
  submitEnrollment,
  onClickOpenDetails,
  onClickOpenFeedback,
}) => {
  const [showEnrollmentModal, setshowEnrollmentModal] = useState(false);
  const currentUser = useCurrentUser();

  const handleSubmitEnrollmentModal = (selectedAttendanceMethod, agenda) => {
    submitEnrollment(selectedAttendanceMethod, agenda);
  };

  return (
    <div>
      <SessionCardButtons
        session={session}
        userTimezone={currentUser.timezone}
        handleEnrollmentModalOpen={() => setshowEnrollmentModal(true)}
        handleOpenFeedbackModal={onClickOpenFeedback}
        handleOpenViewDetailsModal={onClickOpenDetails}
        sessionIsBooked={sessionIsBooked}
        sessionSpotsLeft={sessionSpotsLeft}
      />

      {showEnrollmentModal && (
        <SessionEnrollmentModal
          session={session}
          currentUser={currentUser}
          handleSubmitModal={handleSubmitEnrollmentModal}
          handleClose={() => setshowEnrollmentModal(false)}
        />
      )}
    </div>
  );
};

SessionCardButtonsWrapper.propTypes = {
  session: PropTypes.object,
  submitEnrollment: PropTypes.func,

  sessionIsBooked: PropTypes.bool,
  sessionSpotsLeft: PropTypes.number,

  onClickOpenDetails: PropTypes.func,
  onClickOpenFeedback: PropTypes.func,
};

export default SessionCardButtonsWrapper;
