import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AlertItem from '~/scenes/AlertPage/AlertPage/AlertItem';
import colors from '~/services/colors';
import ButtonLink from '~/app/shared/components/ButtonLink';
import { map } from 'lodash-es';

const EmptyAlerts = styled.div`
  width: 100%;
  padding: 20px 0;
  text-align: center;

  border-radius: 8px;
  border: 1px solid ${colors.neutral200};
`;

const AlertList = styled.div``;

const AlertPageContent = (props) => {
  const { alerts, deleteAlert, handleNewAlertClick } = props;

  if (alerts.length === 0) {
    return (
      <EmptyAlerts>
        You do not have any alerts yet. <br />
        Start by creating a{' '}
        <ButtonLink size="medium" onClick={handleNewAlertClick}>
          New Alert
        </ButtonLink>
        !
      </EmptyAlerts>
    );
  }

  return (
    <AlertList>
      {map(alerts, (alert) => (
        <AlertItem key={alert.id} alert={alert} deleteAlert={deleteAlert} />
      ))}
    </AlertList>
  );
};

AlertPageContent.propTypes = {
  alerts: PropTypes.array,
  handleNewAlertClick: PropTypes.func,
  deleteAlert: PropTypes.func,
};

export default AlertPageContent;
