import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SessionAttendeeEnrollModal from '~/app/program/components/SessionAttendeeEnrollModal';
import SessionDetailsModal from '~/app/program/components/SessionDetail/SessionDetailsModal';
import SessionFeedbackModal from '~/app/program/components/SessionFeedbackModal';
import SessionRosterModal from '~/app/program/components/SessionRosterModal';
import { SESSION_ROSTER_DEFAULT_TAB } from '~/app/program/constants';
import { getUserEnrollment } from '~/app/program/services';
import { useCurrentUser } from '~/app/shared/hooks';
import { noop } from 'lodash-es';

export const useSessionModalManagerState = () => {
  const [showViewDetailsModal, setShowViewDetailsModal] = useState(false);
  const [showRosterModal, setShowRosterModal] = useState(false);
  const [showEnrollModal, setShowEnrollModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackModalReadOnly, setFeedbackModalReadOnly] = useState(false);
  const [rosterTab, setRosterTab] = useState(SESSION_ROSTER_DEFAULT_TAB);

  const openRosterModal = () => {
    setRosterTab(SESSION_ROSTER_DEFAULT_TAB);
    setShowRosterModal(true);
  };

  const openDetailsModal = () => {
    setShowViewDetailsModal(true);
  };

  const openFeedbackModal = ({ readOnly = false }) => {
    setShowFeedbackModal(true);
    setFeedbackModalReadOnly(readOnly);
  };

  return {
    modalManagerState: {
      showViewDetailsModal,
      showRosterModal,
      showEnrollModal,
      showFeedbackModal,
      feedbackModalReadOnly,
      rosterTab,
    },
    modalManagerSetters: {
      setShowViewDetailsModal,
      setShowRosterModal,
      setShowEnrollModal,
      setShowFeedbackModal,
      setFeedbackModalReadOnly,
      setRosterTab,
    },
    openRosterModal,
    openDetailsModal,
    openFeedbackModal,
  };
};

const SessionModalManager = ({ session, state, setters, handleDropSession, onClose }) => {
  const currentUser = useCurrentUser();
  const {
    showViewDetailsModal,
    showRosterModal,
    showEnrollModal,
    showFeedbackModal,
    feedbackModalReadOnly,
    rosterTab,
  } = state;
  const {
    setShowViewDetailsModal,
    setShowRosterModal,
    setShowEnrollModal,
    setShowFeedbackModal,
    setRosterTab,
  } = setters;

  const handleRosterAddAttendeeClick = () => {
    setShowEnrollModal(true);
    setShowRosterModal(false);
  };

  const handleRosterAddAttendee = () => {
    setShowEnrollModal(false);
    setShowRosterModal(true);
  };

  const handleOpenRosterFromDetails = (tab) => {
    setShowViewDetailsModal(false);
    setShowRosterModal(true);
    setRosterTab(tab);
  };

  if (!session) {
    return null;
  }

  return (
    <React.Fragment>
      {showRosterModal && (
        <SessionRosterModal
          onAddAttendeeClick={handleRosterAddAttendeeClick}
          session={session}
          handleClose={() => {
            setShowRosterModal(false);
            onClose();
          }}
          forceTab={rosterTab}
        />
      )}

      {showEnrollModal && (
        <SessionAttendeeEnrollModal
          session={session}
          onFormSubmitFinish={handleRosterAddAttendee}
          handleClose={() => {
            setShowEnrollModal(false);
            onClose();
          }}
        />
      )}

      {showViewDetailsModal && (
        <SessionDetailsModal
          session={session}
          handleDropSession={handleDropSession}
          handleClose={() => {
            setShowViewDetailsModal(false);
            onClose();
          }}
          onOpenRoster={(tab) => handleOpenRosterFromDetails(tab)}
          userTimezone={currentUser.timezone}
        />
      )}

      {showFeedbackModal && (
        <SessionFeedbackModal
          readOnly={feedbackModalReadOnly}
          session={session}
          userEnrollment={getUserEnrollment(session, currentUser)}
          handleClose={() => setShowFeedbackModal(false)}
        />
      )}
    </React.Fragment>
  );
};

SessionModalManager.propTypes = {
  session: PropTypes.object,
  state: PropTypes.shape({
    showViewDetailsModal: PropTypes.bool,
    showRosterModal: PropTypes.bool,
    showEnrollModal: PropTypes.bool,
    showFeedbackModal: PropTypes.bool,
    feedbackModalReadOnly: PropTypes.bool,
    rosterTab: PropTypes.number,
  }),
  setters: PropTypes.shape({
    setShowViewDetailsModal: PropTypes.func,
    setShowRosterModal: PropTypes.func,
    setShowEnrollModal: PropTypes.func,
    setShowFeedbackModal: PropTypes.func,
    setRosterTab: PropTypes.func,
  }),
  handleDropSession: PropTypes.func,
  onClose: PropTypes.func,
};

SessionModalManager.defaultProps = {
  onClose: noop,
};

export default SessionModalManager;
