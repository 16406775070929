import PropTypes from 'prop-types';
import React from 'react';

import Modal, { ModalBody } from '~/app/shared/components/Modal';

import SocialSharingForm from './SocialSharingForm';

export const SocialSharingModal = ({ handleClose, service, serviceName, textMessage }) => {
  const onSubmitSuccess = () => {
    handleClose();
  };

  const formProps = {
    onSubmitSuccess,
    initialValues: {
      message: textMessage,
      service,
    },
  };

  return (
    <Modal title={`Share to ${serviceName}`} width={600} handleClose={handleClose}>
      <ModalBody>
        <SocialSharingForm {...formProps} />
      </ModalBody>
    </Modal>
  );
};

SocialSharingModal.propTypes = {
  service: PropTypes.string,
  serviceName: PropTypes.string,
  textMessage: PropTypes.string,

  show: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default SocialSharingModal;
