import { Modal } from '~/app/shared';
import InfoBox from '~/app/shared/components/InfoBox';
import Loading from '~/app/shared/components/Loading';
import { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import { isEmpty, map } from 'lodash-es';
import { List, ListItem } from '@mui/material';
import { useProfileEnrichmentData } from './hooks/useProfileEnrichmentData';

interface Props {
  handleClose?: (availableProfileSnippets?: any[]) => void;
  username: string;
}

const LinkedInPublicProfileDataDialogPreview = ({ handleClose, username }: Props) => {
  const { data: availableProfileSnippets, isLoading, isError } = useProfileEnrichmentData(username);

  const handleAddSelectedItemsToProfile = () => {
    handleClose?.(availableProfileSnippets);
  };
  return (
    <Modal
      title={`Preview of LinkedIn' public profile data from ${username}`}
      handleClose={() => handleClose?.()}
      width="820px"
      maxBodyHeight="600px"
      disableEscapeKeyDown
      disableCloseOnBackdropClick
      footer={() => (
        <ModalFooter variant="buttons">
          <ModalFooterButton onClick={() => handleClose?.()}>Cancel</ModalFooterButton>
          <ModalFooterButton
            color="success"
            onClick={handleAddSelectedItemsToProfile}
            disabled={isEmpty(availableProfileSnippets)}
            aria-label="Replace your description with this content"
          >
            Replace your description with this content
          </ModalFooterButton>
        </ModalFooter>
      )}
    >
      <ModalBody>
        {isLoading && <Loading />}
        {!isLoading && !isError && (
          <>
            <InfoBox content="This is the information available in your LinkedIn public profile. If you have any trouble fetching it or just recently updated your public profile, please try again in a few hours." />
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {map(availableProfileSnippets, (value, index) => {
                return (
                  <ListItem key={`available-profile-snippet-${username}-${index}`} disablePadding>
                    {value}
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        {!isLoading && isError && (
          <InfoBox
            type="error"
            content="It was not possible to fetch data from this profile. Please review the profile username and its settings, then try again later."
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default LinkedInPublicProfileDataDialogPreview;
