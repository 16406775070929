import styled from 'styled-components';

import MediaPoint from '~/app/shared/components/MediaPoint';

export const FilterPageContentWrapper = styled.div`
  max-width: 100%;
  margin: 24px 0;
  padding: 0 10px;

  ${MediaPoint.MobileLg} {
    padding: 0 15px;
  }

  ${MediaPoint.Tablet} {
    margin-left: auto;
    margin-right: auto;
    width: ${({ viewportWidthOptions }) => viewportWidthOptions.tablet || '648px'};
    padding: 0;
  }

  ${MediaPoint.DesktopLg} {
    width: ${({ viewportWidthOptions }) => viewportWidthOptions.desktopSm || '984px'};
  }

  ${MediaPoint.DesktopXl} {
    width: ${({ viewportWidthOptions }) => viewportWidthOptions.desktopLg || '1320px'};
  }

  ${MediaPoint.MobileOnly} {
    padding: 0 16px 0 16px;
    grid-area: 1 / 1 / 4 / 2;
  }
`;
