import { useQuery } from '@tanstack/react-query';
import { map } from 'lodash-es';

import { useRouterQueryUtils } from '~/app/shared/hooks';
import { DEFAULT_DETAIL_PAGE_STALE_TIME } from '~/common/api/constants';
import { queries } from '~/queries';

import { useComposableTrackCtx } from '../contexts/ComposableTrackCtx';
import { useTrackContentConsumptionCtx } from '../contexts/TrackContentConsumptionCtx';
import { TrackBreadcrumb } from '../types';

export function useTrackContentBreadcrumbs() {
  const { rootTrack, getSectionOrItemIndex } = useComposableTrackCtx();
  const { currentTrackItem, currentSection } = useTrackContentConsumptionCtx();

  const { getUrlWithQueryString, getUrlWithoutQueryString } = useRouterQueryUtils();

  const parentTrackPublicId =
    currentTrackItem != null ? currentTrackItem.track_id : currentSection?.track_id;

  const { data: breadcrumbs, status } = useQuery({
    ...queries.tracks.breadcrumbs(rootTrack.public_id, {
      parentTrackPublicId,
      itemPublicId: currentTrackItem?.content_item.public_id,
      sectionId: currentSection?.id,
    }),
    staleTime: DEFAULT_DETAIL_PAGE_STALE_TIME,
    select: (data) => data.breadcrumbs,
  });

  const handleGetBreadcrumbUrl = (crumb: TrackBreadcrumb) => {
    if (crumb.type === 'track') {
      return getUrlWithoutQueryString(['page']);
    }

    if (crumb.type === 'section' || crumb.type === 'item') {
      const page =
        getSectionOrItemIndex(crumb.parent_track ?? rootTrack.public_id, crumb.public_id) + 1;
      return getUrlWithQueryString({ page });
    }

    return '#';
  };

  if (status === 'loading' || status === 'error') {
    return [];
  }

  const mappedBreadcrumbs = map(breadcrumbs, (crumb) => ({
    label: crumb.name,
    image: crumb.cover && { src: crumb.cover, alt: crumb.name },
    link: handleGetBreadcrumbUrl(crumb),
  }));

  return mappedBreadcrumbs;
}
