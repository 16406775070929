import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ButtonLink from '~/app/shared/components/ButtonLink';
import { useEllipsisCheck } from '~/app/shared/hooks';

const Container = styled.div`
  position: relative;
`;

const ViewMoreContainer = styled.div`
  background: linear-gradient(
    270deg,
    ${({ backgroundFadeColor }) => backgroundFadeColor} 0%,
    ${({ backgroundFadeColor }) => backgroundFadeColor} 81.25%,
    rgba(255, 255, 255, 0) 100%
  );

  padding-left: 24px;

  position: absolute;
  right: 0;
  bottom: 0;
`;

/*
  Pass "nodeRef" to children using render props. Target "nodeRef" must implement ellipsis.
  If the ellipsis is multiline, pass "multipleLines" prop as true
 */
const ViewMoreCta = ({ children, onClick, backgroundFadeColor, multipleLines, size }) => {
  const { hasEllipsis, nodeRef } = useEllipsisCheck({ multipleLines });

  return (
    <Container>
      {children({ nodeRef })}
      {hasEllipsis && (
        <ViewMoreContainer backgroundFadeColor={backgroundFadeColor}>
          <ButtonLink onClick={onClick} size={size} display="block">
            View More
          </ButtonLink>
        </ViewMoreContainer>
      )}
    </Container>
  );
};

ViewMoreCta.propTypes = {
  children: PropTypes.func,
  backgroundFadeColor: PropTypes.string,
  multipleLines: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

export default ViewMoreCta;
