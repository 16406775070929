import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import ContentDescription from '~/app/stand-alone-shared/components/ContentDescription/ContentDescription';
import { removeLinks } from '~/common/utils/draftjs';
import colors from '~/services/colors';

export const Container = styled(Box)`
  position: relative;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.25;
  color: ${colors.neutral500};
  flex: 1;
  min-height: 0;

  margin-top: -0.5rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 1.5rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    z-index: 1;
  }
`;

type ContentItemCardDescriptionProps = {
  description: string;
};

export function ContentItemCardDescription(props: ContentItemCardDescriptionProps) {
  const { description } = props;

  const cleanedDescription = removeLinks(description);

  return (
    <Container>
      <ContentDescription description={cleanedDescription} noPadding />
    </Container>
  );
}
